import AsonicTableBody from "./asonic-table-body";
import AsonicTableContainer from "./asonic-table-container";
import AsonicTableHead from "./asonic-table-head";
import AsonicTableLayout from "./asonic-table-layout";
import { AutoSizer } from "react-virtualized";
import AsonicTableTitleBar, {
  IAsonicTableTitleBarProps
} from "./asonic-table-title-bar";
import AsonicRenderRow, {
  IAsonicRenderRowProps,
  IAsonicRow
} from "./asonic-render-row";
import { FixedSizeList as List } from "react-window";
import ReactLoading from "react-loading";
import styled from "styled-components";
import * as ReactTable from "react-table";
import { colors } from "../GlobalStyle/GlobalStyle";
import { FieldSort } from "../../__generated__/globalTypes";
import AsonicTableColumn, { TMoveColumn } from "./asonic-table-column";
import AsonicPageController, {
  IAsonicPageControllerProps
} from "./asonic-page-controller";
import { memo, useMemo } from "react";
import AsonicTableHeaderRow from "./asonic-table-header-row";

export interface IAsonicHeaderGroup<P extends {}>
  extends ReactTable.HeaderGroup<P extends object ? P : {}> {}

export interface IAsonicHeaderGroups<P extends {}>
  extends IAsonicHeaderGroup<P> {}

export interface IAsonicTableProps<P extends object>
  extends IAsonicTableTitleBarProps<P>,
    Partial<IAsonicPageControllerProps>,
    IAsonicRenderRowProps<P> {
  getTableProps: (
    propGetter?:
      | Partial<ReactTable.TableProps>
      | Partial<ReactTable.TableProps>[]
      | ((
          props: Partial<ReactTable.TableProps>,
          meta: ReactTable.MetaBase<any>
        ) => Partial<any> | Partial<any>[])
      | undefined
  ) => ReactTable.TableProps;
  headerGroups: IAsonicHeaderGroups<P>[];
  getTableBodyProps: () => ReactTable.TableBodyProps;
  rows: IAsonicRow<P>[];
  isLoading?: boolean;
  handleFieldSort?: (payload?: FieldSort) => void;
  fieldSort?: FieldSort;
  listOfColumnDisabled?: string[];
  moveColumn?: TMoveColumn;
  isTitleBar?: boolean;
  itemSize?: number;
  isLastFlex?: boolean;
  listOfFlexForHeader?: string[];
  maxWidthForHeader?: { name: string; $maxWidth: string }[]; // header 너비 조정
}

const Content = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};
  align-items: ${props => (props.isLoading ? "center" : "normal")};
  flex-direction: column;
`;

function AsonicTable<P extends object>({
  title,
  total,
  take,
  currentPage,
  handleCurrentPage,
  handleTake,
  downloadExcel,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  handleSelectRow,
  isLoading,
  listOfSearchType,
  handleSearch,
  handleContextMenu,
  selectedRow,
  handleDate,
  handleFieldSort,
  fieldSort,
  listOfColumnDisabled,
  columns,
  toggleHideColumn,
  moveColumn,
  flexIndex,
  isLastFlex,
  listOfFlexForHeader,
  isOneColumn,
  maxWidthForHeader,
  isTitleBar = true,
  itemSize = 30,
  children
}: IAsonicTableProps<P>) {
  const totalPage = useMemo(
    () => take && total && Math.ceil(total / take),
    [take, total]
  );
  return (
    <AsonicTableLayout>
      {isTitleBar && (
        <AsonicTableTitleBar
          total={total}
          title={title}
          handleTake={handleTake}
          take={take}
          downloadExcel={downloadExcel}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          handleDate={handleDate}
          columns={columns}
          toggleHideColumn={toggleHideColumn}
        >
          {children}
        </AsonicTableTitleBar>
      )}
      <Content isLoading={isLoading}>
        {isLoading ? (
          <ReactLoading
            type="spokes"
            color={colors.green}
            height={200}
            width={170}
          />
        ) : (
          <AsonicTableContainer {...getTableProps()}>
            <AsonicTableHead>
              {headerGroups.map(headerGroup => (
                <AsonicTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    let isFlex = flexIndex === index;
                    let $maxWidth = "";
                    if (column?.Header && maxWidthForHeader) {
                      maxWidthForHeader.forEach(item => {
                        if (item.name === column.Header) {
                          $maxWidth = item.$maxWidth;
                        }
                      });
                    }
                    if (listOfFlexForHeader && column?.Header) {
                      isFlex = listOfFlexForHeader.includes(
                        column.Header as string
                      );
                    }
                    return (
                      <AsonicTableColumn
                        key={column.id}
                        column={column}
                        index={index}
                        fieldSort={fieldSort}
                        handleFieldSort={handleFieldSort}
                        listOfColumnDisabled={listOfColumnDisabled}
                        moveColumn={moveColumn}
                        title={title}
                        isLastFlex={isLastFlex}
                        isFlex={isFlex}
                        $maxWidth={$maxWidth}
                      />
                    );
                  })}
                </AsonicTableHeaderRow>
              ))}
            </AsonicTableHead>
            <AsonicTableBody {...getTableBodyProps()}>
              <AutoSizer>
                {({ height, width }) => {
                  const totalHeight = itemSize * rows.length;
                  return (
                    <List
                      height={height}
                      itemCount={rows.length}
                      itemData={rows}
                      itemSize={itemSize}
                      width={width}
                    >
                      {AsonicRenderRow({
                        prepareRow: prepareRow,
                        handleSelectRow: handleSelectRow,
                        handleContextMenu: handleContextMenu,
                        selectedRow: selectedRow,
                        isLastFlex: isLastFlex,
                        flexIndex: flexIndex,
                        listOfFlexForHeader: listOfFlexForHeader,
                        isOverFlow: height <= totalHeight,
                        isOneColumn: isOneColumn
                      })}
                    </List>
                  );
                }}
              </AutoSizer>
            </AsonicTableBody>
          </AsonicTableContainer>
        )}
        {totalPage !== undefined &&
          currentPage &&
          handleCurrentPage &&
          take &&
          handleTake && (
            <AsonicPageController
              totalPage={totalPage}
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              take={take}
              handleTake={handleTake}
            />
          )}
      </Content>
    </AsonicTableLayout>
  );
}

export default memo(AsonicTable) as typeof AsonicTable;
