import * as React from "react";
import SuperAdminSettingsPagePresenter from "./super-admin-settings-page-presenter";
import useCheckAdmin from "../../hooks/use-check-admin";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import { MessageTypes } from "../toast-message/toast-message";

const SUPER_ADMIN = 1;

const SuperAdminSettingsPageContainer = () => {
  const { handleAfterLogin, isSuperAdmin } = useCheckAdmin();

  const {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const handleAfterLoginWithNoti = React.useCallback(
    auth => {
      handleAfterLogin(auth);
      if (auth !== SUPER_ADMIN) {
        handleToastMessageType(MessageTypes.WARNING);
        handleMessage("권한이 없는 사용자입니다.");
        handleIsOpen(true);
      }
    },
    [handleAfterLogin, handleToastMessageType, handleMessage, handleIsOpen]
  );

  return (
    <SuperAdminSettingsPagePresenter
      handleAfterLogin={handleAfterLoginWithNoti}
      isSuperAdmin={isSuperAdmin}
      messageTypes={toastMessageType}
      message={message}
      isOpen={isOpen}
      handleIsOpen={handleIsOpen}
      handleMessage={handleMessage}
      handleToastMessageType={handleToastMessageType}
    />
  );
};

export default SuperAdminSettingsPageContainer;
