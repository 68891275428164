import { GLOBAL_REDUCER_INIT_STATE } from "../../../../constValues";

// TYPE
export const GET_REQUEST_DOCS = "REQUEST_REDUCER/GET_REQUEST_DOCS";
export const GET_REQUEST_DOCS_FOR_CAL =
  "REQUEST_REDUCER/GET_REQUEST_DOCS_FOR_CAL";
export const SELECT_REQUEST_DOC = "REQUEST_REDUCER/SELECT_REQUEST_DOC";
export const MAKE_NEW_REQUEST = "REQUEST_REDUCER/MAKE_NEW_REQUEST";
export const SET_REQUEST_SEARCH_RESULT =
  "REQUEST_REDUCER/SET_REQUEST_SEARCH_RESULT";
export const SET_REQUEST_SEARCH_RESULT_FOR_CAL =
  "REQUEST_REDUCER/SET_REQUEST_SEARCH_RESULT_FOR_CAL";
export const SET_REQUEST_SEARCH_ARGS =
  "REQUEST_REDUCER/SET_REQUEST_SEARCH_ARGS";

let initialState = {
  startDate: "",
  endDate: "",
  requestSearchResults: undefined,
  requestSearchResultsForCal: [],
  selectedIndex: -1 // 선택한 결재
};

export const getRequestDocs = args => ({
  type: GET_REQUEST_DOCS,
  args
});

export const getRequestDocsForCal = args => ({
  type: GET_REQUEST_DOCS_FOR_CAL,
  args
});

export const setRequestSearchResult = searchResults => ({
  type: SET_REQUEST_SEARCH_RESULT,
  searchResults
});

export const setRequestSearchResultForCal = requestSearchResultsForCal => ({
  type: SET_REQUEST_SEARCH_RESULT_FOR_CAL,
  requestSearchResultsForCal
});

export const selectRequestDoc = selectedIndex => ({
  type: SELECT_REQUEST_DOC,
  selectedIndex
});

export const makeNewRequest = args => ({
  type: MAKE_NEW_REQUEST,
  args
});

export const requestActionCreator = {
  getRequestDocs,
  getRequestDocsForCal,
  setRequestSearchResult,
  setRequestSearchResultForCal,
  selectRequestDoc,
  makeNewRequest
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    // case SET_REQUEST_SEARCH_ARGS:
    //   return {
    //     ...state,
    //     ...action.args
    //   };
    case SET_REQUEST_SEARCH_RESULT:
      return {
        ...state,
        requestSearchResults: action.searchResults
      };
    case SET_REQUEST_SEARCH_RESULT_FOR_CAL:
      return {
        ...state,
        requestSearchResultsForCal: action.requestSearchResultsForCal
      };
    case SELECT_REQUEST_DOC:
      return {
        ...state,
        selectedIndex: action.selectedIndex
      };
    case GET_REQUEST_DOCS:
      return {
        ...state,
        startDate: action.args.startDate,
        endDate: action.args.endDate
      };
    case GLOBAL_REDUCER_INIT_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default requestReducer;
