import api from "../api";

const MASTER_WORK_MANAGEMENT_PAGE = `${api.api}/master-work-management`;
const RENEWAL_INFORMATION = `${MASTER_WORK_MANAGEMENT_PAGE}/renewal`;
const TODAY_WORKING_STATUS = `${MASTER_WORK_MANAGEMENT_PAGE}/today-working-status`;
const STATUS_NUMBER_EMPLOYEES_WORKING_HOUR = `${MASTER_WORK_MANAGEMENT_PAGE}/status-number-employees-working-hour`;
const STATUS_FULL_TIME_EMPLOYEES = `${MASTER_WORK_MANAGEMENT_PAGE}/status-full-time-employees`;
const STATUS_OVER_TIME_EMPLOYEES = `${MASTER_WORK_MANAGEMENT_PAGE}/status-over-time-employees`;
const TOTAL_WEEK_WORKING_TIME = `${MASTER_WORK_MANAGEMENT_PAGE}/total-week-working-time`;
const AVERAGE_WORKING_HOUR_DEPARTMENT = `${MASTER_WORK_MANAGEMENT_PAGE}/average-working-hour-department`;
const DOWNLOAD_EXCEL = `${MASTER_WORK_MANAGEMENT_PAGE}/download-excel`;

const masterWorkManagementPageApi = {
  renewalInformation: RENEWAL_INFORMATION,
  todayWorkingStatus: TODAY_WORKING_STATUS,
  statusNumberEMployeesWorkingHour: STATUS_NUMBER_EMPLOYEES_WORKING_HOUR,
  statusFullTimeEmployees: STATUS_FULL_TIME_EMPLOYEES,
  statusOverTimeEmployees: STATUS_OVER_TIME_EMPLOYEES,
  totalWeekWorkingTime: TOTAL_WEEK_WORKING_TIME,
  averageWorkingHoursDepartment: AVERAGE_WORKING_HOUR_DEPARTMENT,
  downloadExcel: DOWNLOAD_EXCEL
};

export default masterWorkManagementPageApi;
