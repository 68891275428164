import jwt from "jsonwebtoken";
import settings from "../../config/settings";

export const handleDecodedToken = (token: string) => {
  return jwt.verify(token, settings.secret, (error, decoded) => {
    if (error) {
      console.log(error);
    }
    return decoded;
  });
};

const tokenMaker = <T>(content: T) => {
  const payload = JSON.stringify(content);
  return jwt.sign(payload, settings.secret);
};

export default tokenMaker;
