import { Component } from "react";
import { TextFieldAlignDiv } from "./styled";
import TextField from "../TextField";
import uiString from "../string.json";
import Button from "../../../globalComponents/Button";
import CollapsiblePanel from "../../smallcomponents/CollapsiblePanel";
class UpdatePassword extends Component {
  render() {
    const {
      handleChangePassword,
      saveThePassword,
      currentPassword,
      newPassword,
      reEnteredNewPassword
    } = this.props;
    return (
      <CollapsiblePanel title={uiString.titles.changeCurrentPassword}>
        <TextFieldAlignDiv>
          <TextField
            label={uiString.textFields.currentPassword}
            placeholder={uiString.textFields.currentPasswordDesc}
            valueChange={handleChangePassword("currentPassword")}
            value={currentPassword}
            type="password"
          />
          <TextField
            type="password"
            label={uiString.textFields.newPassword}
            placeholder={uiString.textFields.newPasswordDesc}
            valueChange={handleChangePassword("newPassword")}
            value={newPassword}
          />
          <TextField
            type="password"
            label={uiString.textFields.reEndterNewPassword}
            placeholder={uiString.textFields.reEndterNewPasswordDesc}
            valueChange={handleChangePassword("reEnteredNewPassword")}
            value={reEnteredNewPassword}
          />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }} />
            <Button onClick={saveThePassword}>
              {uiString.buttons.confirm}
            </Button>
          </div>
        </TextFieldAlignDiv>
      </CollapsiblePanel>
    );
  }
}

export default UpdatePassword;
