import styled from "styled-components";

interface IProps {
  title: string;
  description?: string;
  flex?: number;
}

const Container = styled.div<{ flex?: number }>`
  display: flex;
  flex: ${props => props.flex ?? 1};
  align-items: center;
  text-align: center;
  gap: 5px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const Point = styled.span`
  font-size: 5px;
  font-weight: bold;
  color: ${props => props.theme.colors.green};
`;

function SubTitle({ title, description, flex }: IProps) {
  return (
    <Container flex={flex}>
      <Point>●</Point>
      <Title>{title}</Title>
      {description && <span>{description}</span>}
    </Container>
  );
}

export default SubTitle;
