import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  GetListOfCommuteGpsInfo,
  GetListOfCommuteGpsInfoVariables,
  GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list
} from "../../../../../__generated__/GetListOfCommuteGpsInfo";
import AsonicTable from "../../../../asonic-table/asonic-table";
import Button from "../../../../globalComponents/Button";
import * as ReactTable from "react-table";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import useDnd from "../../../../../hooks/use-dnd/use-dnd";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import { colors } from "../../../../GlobalStyle/GlobalStyle";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../types/reducers";
import { useForm } from "react-hook-form";
import { GetCommuteGpsAreaUseType } from "../../../../../__generated__/GetCommuteGpsAreaUseType";
import { CommuteAreaUseType } from "../../../../../__generated__/globalTypes";
import {
  AddCommuteGpsInfo,
  AddCommuteGpsInfoVariables
} from "../../../../../__generated__/AddCommuteGpsInfo";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  DeleteCommuteGpsInfo,
  DeleteCommuteGpsInfoVariables
} from "../../../../../__generated__/DeleteCommuteGpsInfo";
import {
  UpdateCommuteGpsInfo,
  UpdateCommuteGpsInfoVariables
} from "../../../../../__generated__/UpdateCommuteGpsInfo";
import {
  UpdateCommuteGpsAreaUseType,
  UpdateCommuteGpsAreaUseTypeVariables
} from "../../../../../__generated__/UpdateCommuteGpsAreaUseType";
import useConfirmDialog from "../../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ConfirmDialog from "../../../../confirm-dialog/confirm-dialog";
import CheckBoxUi from "../../../../globalComponents/CheckBoxUi";
import { Role } from "../../../../../user-types";
import FormRow from "../../../../shared/form-row/form-row";
import StyleInput from "../../../../inputs/style-input";

interface FieldValues {
  name: string;
  gps: string;
  radius: string;
  address: string;
}

enum SelectedButton {
  DELETE = "DELETE",
  ADD = "ADD",
  UPDATE = "UPDATE",
  UPDATE_TYPE_ON = "UPDATE_TYPE_ON",
  UPDATE_TYPE_OFF = "UPDATE_TYPE_OFF"
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex: 5;
  gap: 20px;
`;

const Form = styled.form`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  align-self: flex-end;
`;

const QUERY_GET_LIST_OF_COMMUTE_GPS_INFO = gql`
  query GetListOfCommuteGpsInfo(
    $page: Float
    $take: Float
    $fieldSort: FieldSort
  ) {
    getListOfCommuteGpsInfo(page: $page, take: $take, fieldSort: $fieldSort) {
      ok
      error
      list {
        areaIdx
        areaName
        gpsLatitude
        gpsLongitude
        gpsAddress
        radiusMiter
        customerSend
      }
      cacheId
    }
  }
`;

const QUERY_GET_COMMUTE_GPS_AREA_USE_TYPE = gql`
  query GetCommuteGpsAreaUseType {
    getCommuteGpsAreaUseType {
      ok
      error
      commuteAreaUseType
    }
  }
`;

const MUTATION_ADD_COMMUTE_GPS_INFO = gql`
  mutation AddCommuteGpsInfo(
    $name: String!
    $gps: String!
    $radius: Int!
    $address: String!
  ) {
    addCommuteGpsInfo(
      name: $name
      gps: $gps
      radius: $radius
      address: $address
    ) {
      ok
      error
      id
    }
  }
`;

const MUTATION_DELETE_COMMUTE_GPS_INFO = gql`
  mutation DeleteCommuteGpsInfo($areaIdx: Int!) {
    deleteCommuteGpsInfo(areaIdx: $areaIdx) {
      ok
      error
    }
  }
`;

const MUTATION_UPDATE_COMMUTE_GPS_INFO = gql`
  mutation UpdateCommuteGpsInfo(
    $name: String!
    $gps: String!
    $radius: Int!
    $address: String!
    $areaIdx: Int!
  ) {
    updateCommuteGpsInfo(
      name: $name
      gps: $gps
      radius: $radius
      address: $address
      areaIdx: $areaIdx
    ) {
      ok
      error
    }
  }
`;

const MUTATION_COMMUTE_AREA_USE_TYPE = gql`
  mutation UpdateCommuteGpsAreaUseType(
    $commuteAreaUseType: CommuteAreaUseType!
  ) {
    updateCommuteGpsAreaUseType(commuteAreaUseType: $commuteAreaUseType) {
      ok
      error
    }
  }
`;

function MapRegisterDialog() {
  const { register, setValue, getValues } = useForm<FieldValues>({
    defaultValues: {
      name: "",
      gps: "",
      radius: "",
      address: ""
    }
  });
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [selectedButton, setSelectedButton] = useState<SelectedButton>(
    SelectedButton.DELETE
  );
  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const { signInReducer } = useSelector((state: Reducers) => state);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const [getListOfCommuteGpsInfo, { data, loading }] = useLazyQuery<
    GetListOfCommuteGpsInfo,
    GetListOfCommuteGpsInfoVariables
  >(QUERY_GET_LIST_OF_COMMUTE_GPS_INFO, {
    onCompleted(data) {
      if (
        !data.getListOfCommuteGpsInfo.ok &&
        data.getListOfCommuteGpsInfo.error
      ) {
        handleMessage(data?.getListOfCommuteGpsInfo.error);
        handleToastMessageType(MessageTypes.ERROR);
        handleIsToastMessageOpen(true);
      }
    }
  });

  const [getCommuteGpsAreaUseType] = useLazyQuery<GetCommuteGpsAreaUseType>(
    QUERY_GET_COMMUTE_GPS_AREA_USE_TYPE,
    {
      fetchPolicy: "no-cache",
      onCompleted(data) {
        if (
          data.getCommuteGpsAreaUseType.ok &&
          data.getCommuteGpsAreaUseType.commuteAreaUseType
        ) {
          if (
            data.getCommuteGpsAreaUseType.commuteAreaUseType ===
            CommuteAreaUseType.ON
          ) {
            setIsChecked(true);
          }
        }
      }
    }
  );

  const [addCommuteGpsInfo, { client }] = useMutation<
    AddCommuteGpsInfo,
    AddCommuteGpsInfoVariables
  >(MUTATION_ADD_COMMUTE_GPS_INFO, {
    async update(_, { data }) {
      if (data?.addCommuteGpsInfo.ok) {
        const name = getValues("name");
        await client.resetStore();
        handleMessage(`${name}을 새로 추가하셨습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
        handleIsToastMessageOpen(true);
      } else if (!data?.addCommuteGpsInfo.ok && data?.addCommuteGpsInfo.error) {
        handleMessage(data?.addCommuteGpsInfo.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    },
    onError(error) {
      handleMessage(
        "출퇴근 정보를 전부 입력하지 않았거나 맞는 양식이 아닙니다."
      );
      handleToastMessageType(MessageTypes.WARNING);
      handleIsToastMessageOpen(true);
    }
  });

  const [deleteCommuteInfo] = useMutation<
    DeleteCommuteGpsInfo,
    DeleteCommuteGpsInfoVariables
  >(MUTATION_DELETE_COMMUTE_GPS_INFO, {
    update(cache, { data }, { variables }) {
      if (data?.deleteCommuteGpsInfo.ok) {
        cache.evict({
          id: `MapRegisterEntity:${variables?.areaIdx}`
        });
        handleToastMessageType(MessageTypes.SUCCESS);
        const name = getValues("name");
        handleMessage(`성공적으로 ${name} 출퇴근반경을 삭제하셨습니다.`);
      } else if (
        !data?.deleteCommuteGpsInfo.ok &&
        data?.deleteCommuteGpsInfo.error
      ) {
        handleToastMessageType(MessageTypes.ERROR);
        handleMessage(data.deleteCommuteGpsInfo.error);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [updateCommuteGpsInfo] = useMutation<
    UpdateCommuteGpsInfo,
    UpdateCommuteGpsInfoVariables
  >(MUTATION_UPDATE_COMMUTE_GPS_INFO, {
    update(cache, { data }, { variables }) {
      if (data?.updateCommuteGpsInfo.ok && variables) {
        const [gpsLat, gpsLong] = variables.gps.split(",");
        cache.modify({
          id: `MapRegisterEntity:${variables.areaIdx}`,
          fields: {
            areaName() {
              return variables.name;
            },
            gpsLatitude() {
              return gpsLat;
            },
            gpsLongitude() {
              return gpsLong;
            },
            radiusMiter() {
              return variables.radius;
            },
            gpsAddress() {
              return variables.address;
            }
          }
        });
        handleToastMessageType(MessageTypes.SUCCESS);
        handleMessage("성공적으로 선택된 출퇴근 반경 정보를 수정하셨습니다.");
      } else if (
        !data?.updateCommuteGpsInfo.ok &&
        data?.updateCommuteGpsInfo.error
      ) {
        handleToastMessageType(MessageTypes.ERROR);
        handleMessage(data?.updateCommuteGpsInfo.error);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [updateCommuteGpsAre] = useMutation<
    UpdateCommuteGpsAreaUseType,
    UpdateCommuteGpsAreaUseTypeVariables
  >(MUTATION_COMMUTE_AREA_USE_TYPE, {
    update(_, { data }, { variables }) {
      if (data?.updateCommuteGpsAreaUseType.ok) {
        handleToastMessageType(MessageTypes.SUCCESS);
        handleMessage(
          `출퇴근 반경설정을 ${variables?.commuteAreaUseType} 하셨습니다.`
        );
      } else if (
        data?.updateCommuteGpsAreaUseType.ok &&
        data?.updateCommuteGpsAreaUseType.error
      ) {
        handleToastMessageType(MessageTypes.ERROR);
        handleMessage(
          `출퇴근 반경설정을 ${variables?.commuteAreaUseType} 하셨습니다.`
        );
      }
      handleIsToastMessageOpen(true);
    }
  });

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (getValues("gps")) {
        if (event.target.checked) {
          setSelectedButton(SelectedButton.UPDATE_TYPE_ON);
          handleConfirmMessage({
            title: "출퇴근 반경 설정",
            p: `출퇴근 반경 설정을 ON 하시겠습니까?`,
            messageTypes: MessageTypes.WARNING
          });
          handleIsOpen(true);
        } else {
          setSelectedButton(SelectedButton.UPDATE_TYPE_OFF);
          handleConfirmMessage({
            title: "출퇴근 반경 설정",
            p: `출퇴근 반경 설정을 OFF 하시겠습니까?`,
            messageTypes: MessageTypes.WARNING
          });
        }
        handleIsOpen(true);
      } else {
        handleToastMessageType(MessageTypes.WARNING);
        handleMessage(`GPS 정보가 존재하지 않습니다.`);
        handleIsToastMessageOpen(true);
      }
    },
    [
      handleConfirmMessage,
      handleIsOpen,
      getValues,
      handleIsToastMessageOpen,
      handleMessage,
      handleToastMessageType
    ]
  );

  const handleAddCommuteGpsInfo = useCallback(() => {
    const { name, address, radius, gps } = getValues();
    if (name && address && radius && gps) {
      setSelectedButton(SelectedButton.ADD);
      handleConfirmMessage({
        title: "출퇴근 반경 정보 추가",
        p: `${name}을 새로 추가하시겠습니까?`,
        messageTypes: MessageTypes.INFO
      });
      handleIsOpen(true);
    }
  }, [handleIsOpen, handleConfirmMessage, getValues]);

  const handleAddress = useCallback(
    (
      cell: ReactTable.Cell<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>
    ) => {
      const addr =
          cell.row.original.gpsAddress.length > 10
            ? `${cell.row.original.gpsAddress.slice(0, 9)} ...`
            : cell.row.original.gpsAddress,
        lat = cell.row.original.gpsLatitude,
        long = cell.row.original.gpsLongitude;
      let isGps: boolean = false;
      let address = "https://www.google.com/maps";
      if (lat && long) {
        isGps = true;
        address = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      }

      return (
        <>
          {isGps ? (
            <a href={`${address}`} target="_blank" rel="noopener noreferrer">
              {addr}
            </a>
          ) : (
            <div>{addr}</div>
          )}
        </>
      );
    },
    []
  );

  const list: GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list[] =
    useMemo(() => {
      return data?.getListOfCommuteGpsInfo.list || [];
    }, [data]);

  const columns: ReactTable.Column<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>[] =
    useMemo(() => {
      const width = 180;
      return [
        { Header: "이름", accessor: "areaName", width },
        { Header: "허용 gps 위도", accessor: "gpsLatitude", width },
        {
          Header: "허용 gps 경도",
          accessor: "gpsLongitude",
          width
        },
        {
          Header: "반경(m)",
          accessor: "radiusMiter",
          width: 100
        },
        {
          Header: "주소",
          accessor: "gpsAddress",
          Cell: handleAddress,
          width: 200
        }
      ];
    }, [handleAddress]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    toggleHideColumn,
    columns: mapRegisterColumns,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } =
    useDnd<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>({
      columns: visibleColumns,
      setColumnOrder,
      title: `출퇴근-반경-정보`
    });

  const selectedRow:
    | ReactTable.Row<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const handleDeleteCommuteInfo = useCallback(() => {
    if (selectedRow) {
      setSelectedButton(SelectedButton.DELETE);
      handleConfirmMessage({
        title: `출퇴근 반경 정보 삭제`,
        p: `${selectedRow.original.areaName} 정보를 삭제하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
      handleIsOpen(true);
    }
  }, [handleIsOpen, handleConfirmMessage, selectedRow]);

  const handleUpdateGpsInfo = useCallback(() => {
    if (selectedRow) {
      setSelectedButton(SelectedButton.UPDATE);
      handleConfirmMessage({
        title: "출퇴근 반경 정보 수정",
        p: `${selectedRow.original.areaName} 정보를 수정 하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
      handleIsOpen(true);
    }
  }, [handleIsOpen, handleConfirmMessage, selectedRow]);

  const handleConfirm = useCallback(() => {
    const { name, gps, address, radius } = getValues();
    const [gpsLat, gpsLong] = gps?.trim().split(",");
    const [intGpsLat, decimalPointGpsLat] = gpsLat?.trim().split(".");
    const [intGpsLong, decimalPointGpsLong] = gpsLong?.trim().split(".");
    const newGps = `${intGpsLat?.trim()}.${decimalPointGpsLat
      ?.trim()
      .slice(0, 10)}, ${intGpsLong?.trim()}.${decimalPointGpsLong
      ?.trim()
      .slice(0, 10)}`;

    switch (selectedButton) {
      case SelectedButton.ADD:
        addCommuteGpsInfo({
          variables: {
            name,
            radius: parseInt(radius),
            gps: newGps,
            address
          }
        });
        break;
      case SelectedButton.UPDATE:
        if (selectedRow) {
          updateCommuteGpsInfo({
            variables: {
              areaIdx: selectedRow.original.areaIdx,
              name,
              gps: newGps,
              radius: parseInt(radius),
              address
            }
          });
        }
        break;
      case SelectedButton.DELETE:
        if (selectedRow) {
          deleteCommuteInfo({
            variables: { areaIdx: selectedRow.original.areaIdx }
          });
        }
        break;
      case SelectedButton.UPDATE_TYPE_ON:
        setIsChecked(true);
        updateCommuteGpsAre({
          variables: {
            commuteAreaUseType: CommuteAreaUseType.ON
          }
        });
        break;
      case SelectedButton.UPDATE_TYPE_OFF:
        setIsChecked(false);
        updateCommuteGpsAre({
          variables: {
            commuteAreaUseType: CommuteAreaUseType.OFF
          }
        });
        break;
    }
    handleIsOpen(false);
  }, [
    getValues,
    selectedButton,
    addCommuteGpsInfo,
    updateCommuteGpsInfo,
    deleteCommuteInfo,
    selectedRow,
    handleIsOpen,
    updateCommuteGpsAre
  ]);

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>
    ) => {},
    []
  );

  const handleOpenMap = useCallback(() => {
    let address = "https://www.google.com/maps";

    if (selectedRow?.original.gpsLatitude) {
      const lat = selectedRow.original.gpsLatitude,
        long = selectedRow.original.gpsLongitude;
      address = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
    }
    window.open(address, "_blank");
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      setValue("name", selectedRow.original.areaName);
      setValue(
        "gps",
        `${selectedRow.original.gpsLatitude},${selectedRow.original.gpsLongitude}`
      );
      setValue("radius", selectedRow.original.radiusMiter.toString());
      setValue("address", selectedRow.original.gpsAddress);
    } else {
      setValue("name", "");
      setValue("gps", "");
      setValue("radius", "");
      setValue("address", "");
    }
  }, [selectedRow, setValue]);

  useEffect(() => {
    getListOfCommuteGpsInfo({
      variables: {}
    });
  }, [getListOfCommuteGpsInfo]);

  useEffect(() => {
    getCommuteGpsAreaUseType();
  }, [getCommuteGpsAreaUseType]);

  return (
    <Container>
      <Section>
        <AsonicTable<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>
          title="출퇴근 반경 정보"
          handleSelectRow={handleSelectRow}
          isLoading={loading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          columns={
            mapRegisterColumns as TColumn<GetListOfCommuteGpsInfo_getListOfCommuteGpsInfo_list>[]
          }
          toggleHideColumn={toggleHideColumn}
          moveColumn={moveColumn}
          isTitleBar={false}
        />
        {signInReducer.loginIdInfo === Role.SUPER_ADMIN && (
          <Form>
            <FormRow title="이름" isRequired={true}>
              <StyleInput
                {...register("name", { required: true })}
                required={true}
                placeholder="이름"
              />
            </FormRow>
            <FormRow title="gps" isRequired={true}>
              <StyleInput
                {...register("gps", { required: true })}
                required={true}
                placeholder="gps"
              />
            </FormRow>
            <FormRow title="반경" isRequired={true}>
              <StyleInput
                {...register("radius", { required: true })}
                required={true}
                placeholder="반경"
              />
            </FormRow>
            <FormRow title="주소" isRequired>
              <StyleInput
                {...register("address", { required: true })}
                required={true}
                placeholder="주소"
              />
            </FormRow>
            <FormRow title="출퇴근 반경설정">
              <CheckBoxUi
                checked={isChecked}
                htmlFor="on-off"
                onChange={handleCheck}
                name="ON/OFF"
              />
            </FormRow>
          </Form>
        )}
      </Section>
      <ButtonContainer>
        <Button backgroundColor={colors.veryDarkBlue} onClick={handleOpenMap}>
          맵보기
        </Button>
        {signInReducer.loginIdInfo === Role.SUPER_ADMIN && (
          <Fragment>
            <Button onClick={handleDeleteCommuteInfo}>삭제</Button>
            <Button onClick={handleAddCommuteGpsInfo}>추가</Button>
            <Button onClick={handleUpdateGpsInfo}>수정</Button>
          </Fragment>
        )}
      </ButtonContainer>
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default MapRegisterDialog;
