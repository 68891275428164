import api from "../api";

const SIGN_IN = `/signin`,
  NO_AUTH = `/noauth/:userid`,
  FCM_TOKEN = `/fcmtoken`,
  ALIVE_CHECK = `/alivecheck`,
  IS_ADMIN = `${api.api}${SIGN_IN}/is_admin`;

const signInApi = {
  signin: SIGN_IN,
  noAuth: NO_AUTH,
  fcmToken: FCM_TOKEN,
  aliveCheck: ALIVE_CHECK,
  isAdmin: IS_ADMIN
};

export default signInApi;
