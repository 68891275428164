import styled from "styled-components";
import InfoIcon from "../../../../../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    margin: 0px;
    padding: 0px;
  }
`;

function UserInformationChangeHistoryHelp() {
  return (
    <InfoIcon
      minHeight="400"
      height="400"
      minWidth="400px"
      title="연차 변경 이력"
    >
      <HelpContainer>
        <span>{`개인 연차 일수 설정 변경 이력 조회를 할수 있도록 합니다.`}</span>
        <ul>
          <li>{`기본일수`}</li>
          <pre>{`연차 기본 일수로 인사 관리 체계에 따라 기본적으로 부여되는 연차
일수 입니다. 입사 기간 또는 회사 정책에 따라 기본 일수 부여 또는 월
만근시 월차 자동 생성일이 부여됩니다.`}</pre>
          <li>{`기본 조정`}</li>
          <pre>{`관리자에 의해 기본 일수의 추가 부여 또는 감소 되는 일수 입니다.
해당 설정은 인사 관리자가 설정합니다.`}</pre>
          <li>{`사용 조정`}</li>
          <pre>{`이미 확정되 연차 사용 일수를 특정 사유에 의해 조정해야 하는 경우
관리자의 승인 하에 사용일수를 조정 하기 위한 일수 입니다.
(증/감 설정)`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default UserInformationChangeHistoryHelp;
