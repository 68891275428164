const mColors = {
  grey: "#303636",
  lightGrey: "#C4DCDC",
  lightGreen: "#EAF3F3",
  yellow: "#FFDC27",
  lightYellow: "#fee661",
  darkYellow: "#E1C638",
  lightRed: "#FF275B"
};

export default mColors;
