import { UseFormRegisterReturn } from "react-hook-form";
import { forwardRef } from "react";
import styled from "styled-components";

interface IProps extends UseFormRegisterReturn {
  title?: string;
  labelColor?: string;
}

const CheckBoxContainer = styled.div`
  margin: 0px 2.5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  -webkit-app-region: no-drag;
`;

const SCheckBox = styled.input`
  width: 20px;
  height: 20px;
`;

const CheckBox = forwardRef((props: IProps, ref: any) => {
  return (
    <CheckBoxContainer>
      <SCheckBox type="checkbox" {...props} ref={ref} id={props.name} />
      <label htmlFor={props.name} style={{ color: props.labelColor }}>
        {props.title || props.name}
      </label>
    </CheckBoxContainer>
  );
});

export default CheckBox;
