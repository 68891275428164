import { Icon } from "@iconify/react";
import styled from "styled-components";
import mColors from "../../../../style-sheet/m_colors";
import {
  ApprovalTemplateEntity,
  UserEntity,
  useGetListOfApprovalTemplateLazyQuery
} from "../../../../generated/graphql";
import { AutoSizer } from "react-virtualized";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { colors } from "../../../GlobalStyle/GlobalStyle";
import { useRecoilState } from "recoil";
import mRAStore from "./m_r_a_store";

interface IProps {
  user?: UserEntity;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const DisplayFormContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 20;
`;

const Row = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${mColors.grey};
  background-color: ${props => (props?.isSelected ? mColors.lightGrey : "")};
  :active {
    background-color: ${mColors.lightGrey};
  }
  padding: 5px;
  svg {
    font-size: 24px;
  }
`;

const ListContainer = styled.div<{
  $height: number;
  $width: number;
  $loading?: boolean;
}>`
  display: flex;
  flex: 1;
  overflow: auto;
  height: ${props => (props.$height ? `${props.$height}px` : "")};
  width: ${props => (props.$width ? `${props.$width}px` : "")};
  flex-direction: column;
  will-change: scroll-position;
  justify-content: ${props => (props.$loading ? "center" : "")};
  align-items: ${props => (props.$loading ? "center" : "")};
  border-radius: 6px;
  background-color: ${mColors.lightGreen};
`;

const Span = styled.span<{ $isBold?: boolean }>`
  font-weight: ${props => (props.$isBold ? "bold" : "")};
`;

function MRequestApprovalFormList({ user }: IProps) {
  const [{ template }, setMRAState] = useRecoilState(mRAStore);
  const [list, setList] = useState<ApprovalTemplateEntity[]>([]);
  const [getListOfApprovalTemplate, { loading }] =
    useGetListOfApprovalTemplateLazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (
          data.getListOfApprovalTemplate.ok &&
          data.getListOfApprovalTemplate.list
        ) {
          setList(data.getListOfApprovalTemplate.list);
        } else if (data.getListOfApprovalTemplate.error) {
          console.log(data.getListOfApprovalTemplate.error);
        }
      }
    });

  useEffect(() => {
    if (user?.employeeId) {
      getListOfApprovalTemplate({
        variables: {
          employeeId: user?.employeeId,
          isAdmin: false // 결재신청 페이지에서 리스트를 요청할 때는 항상 false로 전달 (모바일)
        }
      });
    }
  }, [user?.employeeId, getListOfApprovalTemplate]);

  return (
    <Container>
      <DisplayFormContainer>
        <Span $isBold>{"기본 양식함"}</Span>
      </DisplayFormContainer>
      <ContentContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <ListContainer $height={height} $width={width} $loading={loading}>
                {loading && (
                  <ReactLoading
                    type="spokes"
                    color={colors.green}
                    height={200}
                    width={170}
                  />
                )}
                {!loading &&
                  list.map((item, index) => (
                    <Row
                      key={index}
                      onClick={() =>
                        setMRAState(pre => {
                          return { ...pre, template: item };
                        })
                      }
                      isSelected={
                        item.formTemplateIdx === template?.formTemplateIdx
                      }
                    >
                      <Icon icon="basil:document-solid" />
                      <span>{`${item.templateTitle}${
                        item.formName ? `/${item.formName}` : ""
                      }`}</span>
                    </Row>
                  ))}
              </ListContainer>
            );
          }}
        </AutoSizer>
      </ContentContainer>
    </Container>
  );
}

export default MRequestApprovalFormList;
