import styled from "styled-components";

type Props = {
  isHidingBorder?: boolean;
  isAuto?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 5px;
`;

export const Label = styled.label`
  display: flex;
  flex: 1;
  min-width: max-content;
  flex-direction: column;
  align-items: flex-end;
`;

export const Input = styled.input<Props>`
  display: flex;
  flex: 2;
  min-width: max-content;
  border: ${props => (props.isHidingBorder ? "none" : "1px solid black")};
  border-bottom: 1px solid black;
`;

export const TextArea = styled.textarea<Props>`
  display: flex;
  flex: 2;
  min-width: max-content;
`;
