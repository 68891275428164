import * as React from "react";
import { Container, Label, Input, TextArea } from "./styled";

type OnChange = React.ChangeEvent<HTMLInputElement>;
type TextAreaOnChange = React.ChangeEvent<HTMLTextAreaElement>;

type Props = {
  label: string;
  isHideLabel?: boolean;
  type?: string;
  placeholder?: string;
  isLong?: boolean;
  isAuto?: boolean;
  value: any;
  handleValue: (event: OnChange | TextAreaOnChange) => void;
  handleInvalid: (event: React.FormEvent<HTMLInputElement>) => void;
  isTextArea?: boolean;
  min?: number;
  max?: number;
  step?: number;
  minLength?: number;
  maxLength?: number;
  readOnly?: boolean;
  disabled?: boolean;
  isHidingBorder?: boolean;
  flex?: number;
};

const BasicEditor = <P extends Props>({
  label,
  isHideLabel,
  type,
  placeholder,
  isLong,
  value,
  handleValue,
  handleInvalid,
  isTextArea,
  min,
  max,
  minLength,
  maxLength,
  readOnly,
  disabled,
  isHidingBorder,
  isAuto,
  step
}: P) => {
  return (
    <Container>
      {!isHideLabel && <Label htmlFor={label}>{label}</Label>}
      {isTextArea ? (
        <TextArea
          name={label}
          id={label}
          rows={5}
          placeholder={placeholder}
          value={value}
          onChange={handleValue}
          readOnly={readOnly}
          disabled={disabled || false}
          isAuto={isAuto}
        />
      ) : (
        <Input
          type={type ?? "text"}
          name={label}
          id={label}
          placeholder={placeholder}
          value={value}
          onChange={handleValue}
          onInvalid={handleInvalid}
          required
          min={min}
          max={max}
          step={step}
          minLength={minLength}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled || false}
          isHidingBorder={isHidingBorder}
          isAuto={isAuto}
        />
      )}
    </Container>
  );
};

export default BasicEditor;
