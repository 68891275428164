import {
  Container,
  MonthlyAbsenteeismTardinessStatusContainer,
  VacationStatusContainer,
  MonthlyServiceStatusContainer,
  TableContainer,
  TableTitleBar,
  TableTitle,
  BigSection,
  SmallSection
} from "./styled";
import CurrentTimeBox from "./current-time-box";
import MyWorkStatusBox from "./my-work-status-box";
import StatusBox from "./status-box";
import StatusTable from "./status-table";
import useMonthlyWorkingChartStatus from "../../../hooks/personal-work-dashboard-page-hooks/use-monthly-working-chart-status";
import TotalWeeklyWorkingHoursChart from "../master-work-management-page/total-weekly-working-hours/total-weekly-working-hours-chart";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";
import useMonthlyAbsenteeismTardinessStatus from "../../../hooks/personal-work-dashboard-page-hooks/use-monthly-absenteeism-tardiness-status";
import useVacationStatus from "../../../hooks/personal-work-dashboard-page-hooks/use-vacation-status";
import WorkingInformationBoxContainer from "./working-information-box/working-information-box-container";
import Loader from "../WorkManagementInfo/Loader";
import { Fragment, useCallback, useState } from "react";
import moment from "moment";

const PersonalWorkDashboardPage = () => {
  const [selectedMonth, setSelectedMonth] = useState<number>(
    parseInt(moment().format("M"))
  );

  const handleCurrentMonth = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedMonth(parseInt(event.target.value));
    },
    []
  );

  const { employee_id: userId, menuList } = useSelector((state: Reducers) => {
    return state.signInReducer;
  });

  const { chartData, period, maxHours } = useMonthlyWorkingChartStatus({
    userId,
    selectedMonth
  });
  const monthlyAbsenteeismTardinessData = useMonthlyAbsenteeismTardinessStatus({
    userId
  });
  const vacationData = useVacationStatus({ userId });

  const isMiddleSection = Boolean(menuList[0]?.web_etc_flag & 512);
  return (
    <Fragment>
      {menuList[0]?.web_etc_flag === undefined ? (
        <Loader />
      ) : (
        <Container data-testid="personal-work-dashboard-page">
          <CurrentTimeBox isMiddleSection={isMiddleSection} />
          <MyWorkStatusBox />
          <WorkingInformationBoxContainer userId={userId} />
          {isMiddleSection && (
            <Fragment>
              <MonthlyAbsenteeismTardinessStatusContainer
                isMiddleSection={isMiddleSection}
              >
                <StatusBox
                  title="월간 근태현황"
                  subTitle={monthlyAbsenteeismTardinessData.period}
                >
                  <SmallSection>
                    <TableContainer>
                      {monthlyAbsenteeismTardinessData.mainData.map(
                        (item, index) => (
                          <StatusTable data={item} key={`${index} ${item}`} />
                        )
                      )}
                    </TableContainer>
                  </SmallSection>
                  <BigSection>
                    <TableContainer>
                      {monthlyAbsenteeismTardinessData.subData.map(
                        (item, index) => (
                          <StatusTable data={item} key={`${index} ${item}`} />
                        )
                      )}
                    </TableContainer>
                  </BigSection>
                </StatusBox>
              </MonthlyAbsenteeismTardinessStatusContainer>
              <VacationStatusContainer isMiddleSection={isMiddleSection}>
                <StatusBox title="휴가 현황" subTitle={vacationData.period}>
                  {vacationData.mainData && vacationData.mainData.length > 0 && (
                    <BigSection>
                      <TableTitleBar>
                        <TableTitle>• 연차휴가</TableTitle>
                      </TableTitleBar>
                      <TableContainer>
                        {vacationData.mainData.map((item, index) => (
                          <StatusTable data={item} key={`${index} ${item}`} />
                        ))}
                      </TableContainer>
                    </BigSection>
                  )}
                  {vacationData && vacationData.subData.length > 0 && (
                    <SmallSection>
                      <TableTitleBar>
                        <TableTitle>• 기타휴가</TableTitle>
                      </TableTitleBar>
                      <TableContainer>
                        {vacationData.subData.map((item, index) => (
                          <StatusTable data={item} key={`${index} ${item}`} />
                        ))}
                      </TableContainer>
                    </SmallSection>
                  )}
                </StatusBox>
              </VacationStatusContainer>
            </Fragment>
          )}
          <MonthlyServiceStatusContainer isMiddleSection={isMiddleSection}>
            <StatusBox
              title="월간 근무 현황"
              subTitle={`${period}`}
              selectedMonth={selectedMonth}
              handleCurrentMonth={handleCurrentMonth}
            >
              <TotalWeeklyWorkingHoursChart
                chartData={chartData}
                stacked={true}
                maxHours={maxHours}
              />
            </StatusBox>
          </MonthlyServiceStatusContainer>
        </Container>
      )}
    </Fragment>
  );
};

export default PersonalWorkDashboardPage;
