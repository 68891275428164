import styled from "styled-components";
import InfoIcon from "../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

const Emphasis = styled.span`
  font-weight: bold;
`;

const Description = styled.samp``;

function BasicInfoForAnnualLeave() {
  return (
    <InfoIcon
      minHeight="670px"
      height="670px"
      minWidth="400px"
      title="연차 설정(기본)"
    >
      <HelpContainer>
        <span>{`연차관련 기본 설정을 진행하며, 관리자만 연람 및 수정이 가능합니다.`}</span>
        <ul>
          <li>
            {`연차 발생기준 설정`}
            <ul>
              <li>
                <Emphasis>{`회계년도 기준`}</Emphasis>
              </li>
              <pre>{`관리자가 지정한 특정 회게일기준일을 기준으로 연차가 년 단위 연차
가 발생됩니다.`}</pre>
              <li>
                <Emphasis>{`입사일 기준`}</Emphasis>
              </li>
              <span>{`사원 별 입사일을 기준으로 년 단위 연차가 발생합니다.`}</span>
            </ul>
          </li>
          <li>{`회계 기준일`}</li>
          <pre>{`연차 발생 기준을 "회계년도 기준"으로 설정 하였을때만 적용되며
연차 발생 기준이 되는 회게 기준일을 설정합니다.
월 변경만 가능하며 일자는 1일로 고정 됩니다.`}</pre>
          <li>{`최대 연차 제한`}</li>
          <pre>{`기본 + 가산 + 이월 연차를 포함하여 귀속년도 내 개인이 가질수 있는 최대
연차 개수를 제한 합니다.`}</pre>
          <li>{`연차 차감 우선 순위`}</li>
          <pre>{`기본 연차 외 "가산연차","이월연차"등이 적용 되는 경우
적용된 연차의 차감 우선 순위를 설정 합니다`}</pre>
          <pre>
            <Description>{`예시 ) [이월연차] > [가산일수] > [기본일수] 로 설정 시
"이월 연차"가 최 우선 사용되고 모두 소진된 후에
다음 순서로 설정된 "가산 일수" 연차가 사용되며,
마지막으로 "기본일수" 연차가 사용됩니다.`}</Description>
          </pre>
          <li>{`연차 확정 설정`}</li>
          <pre>{`연차 발생 기준일 갱신 또는 신규 입사자 등록시의 연차 확정 방식
을 설정합니다.`}</pre>
          <ul>
            <li>{`전체 자동 확정`}</li>
            <pre>{`등록된 전 직원을 대상으로 '연차 환경 설정'의 설정에 따라 연차가
자동 확정됩니다.`}</pre>
            <li>{`근속 만 1년 이상 자만 자동 확정`}</li>
            <pre>{`근속 만 1년 이상인 대상자만 자동으로 확정됩니다.
그외 대상자는 관리자에 의해 개별 확정 됩니다.`}</pre>
            <li>{`입사 차기년도 부터 자동 확정`}</li>
            <pre>{`입사 이후 귀속년도가 바뀐 대상자는 자동 확정 됩니다.
근속 만 1년 미만자 포함이며, 그외 대상자는 관라자에 의해 개별 확
정 됩니다.`}</pre>
            <li>{`관라자에 의한 개별 확정`}</li>
            <span>{`모든 사원의 연차는 관라자의 개별 확정이 필요 합니다.`}</span>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default BasicInfoForAnnualLeave;
