import { useState, useContext } from "react";
import Store from "../../../../../components/main-view/WorkManagementInfo/Store";

const useSearchDepartment = term => {
  const { treeData } = useContext(Store);
  const [searchedDe, setSEarchedDe] = useState([]);

  const searchDepartment = (result, data, parentId = 0, parentName = "") => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentdepartment_id === parentId) {
        if (parentName.includes(term) && !result.includes(parentId)) {
          result.push(parentId);
        }
        const newArray = [...data];
        newArray.splice(i, 1);
        searchDepartment(result, newArray, data[i].department_id, data[i].name);
        if (data[i].name.includes(term)) {
          if (!result.includes(data[i].department_id)) {
            result.push(data[i].department_id);
          }
        }
      }
    }

    setSEarchedDe(result);
  };

  const updateSearchDe = event => {
    if (event.key === "Enter" && term.length === 0) {
      setSEarchedDe([]);
      return;
    } else if (event.key === "Enter") {
      setSEarchedDe([]);
      const result = [];
      searchDepartment(result, treeData);
    }
  };
  return { searchedDe, updateSearchDe };
};

export default useSearchDepartment;
