import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as ReactTable from "react-table";
import styled from "styled-components";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import useDnd from "../../../../../hooks/use-dnd/use-dnd";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import {
  CountListEmployeeAppliedToTheTemplate,
  CountListEmployeeAppliedToTheTemplateVariables
} from "../../../../../__generated__/CountListEmployeeAppliedToTheTemplate";
import {
  DownloadListOfEmployeeAppliedToTheTemplateExcel,
  DownloadListOfEmployeeAppliedToTheTemplateExcelVariables
} from "../../../../../__generated__/DownloadListOfEmployeeAppliedToTheTemplateExcel";
import {
  IsException,
  TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE
} from "../../../../../__generated__/globalTypes";
import {
  ListOfEmployeeAppliedToTheTemplate,
  ListOfEmployeeAppliedToTheTemplateVariables,
  ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate
} from "../../../../../__generated__/ListOfEmployeeAppliedToTheTemplate";
import { SearchFieldValues } from "../../../../asonic-table/asonic-search";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import { downloadFileFromServer } from "../../../statistics/Utils";
import ApprovalNotificationFooter from "./approval-notification-footer";
import ApprovalNotificationManagementDialogCtx from "./approval-notification-management-dialog.ctx";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import useNewSortBy from "../../../../../hooks/use-new-sort-by/use-new-sort-by";
import { useSticky } from "react-table-sticky";
import ApprovalNotificationEmployeeHeader from "./approval_notification_employee_header";
import TableLayoutContainer from "../../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../../table_v2/table_v2";
import TableLayoutFooter from "../../../../table_layout/table_layout_footer";
import PageController from "../../../../table/page_controller";

enum COLUMN_FOR_VALUE {
  name = "이름",
  employeeId = "아이디",
  departName = "부서명",
  alarmName = "결재 알림 정책",
  isExpect = "예외"
}

export type Columns =
  ReactTable.Column<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>[];

export const Container = styled.div`
  display: flex;
  flex: 14;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_LIST_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE = gql`
  query ListOfEmployeeAppliedToTheTemplate(
    $templateIdx: Float!
    $page: Float
    $take: Float
    $searchType: TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE
    $searchValue: String
    $fieldSort: FieldSort
  ) {
    listOfEmployeeAppliedToTheTemplate(
      templateIdx: $templateIdx
      page: $page
      take: $take
      searchType: $searchType
      searchValue: $searchValue
      fieldSort: $fieldSort
    ) {
      ok
      error
      cacheId
      listEmployeesAppliedToTheTemplate {
        templateIdx
        employeeId
        name
        departName
        alarmName
        isExpect
      }
    }
  }
`;

const QUERY_COUNT_LIST_EMPLOYEE_APPLIED_TO_THE_TEMPLATE = gql`
  query CountListEmployeeAppliedToTheTemplate(
    $templateIdx: Float!
    $searchType: TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE
    $searchValue: String
  ) {
    countListEmployeeAppliedToTheTemplate(
      templateIdx: $templateIdx
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE_EXCEL = gql`
  query DownloadListOfEmployeeAppliedToTheTemplateExcel(
    $templateIdx: Float!
    $searchType: TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE
    $searchValue: String
  ) {
    downloadListOfEmployeeAppliedToTheTemplateExcel(
      templateIdx: $templateIdx
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

const title = "정책적용직원";

function ApprovalNotificationEmployeeSection() {
  const { selectedTemplate } = useContext(
    ApprovalNotificationManagementDialogCtx
  );

  const { oldFieldSort, fieldSort, handleFieldSort } = useNewSortBy();

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName
  } = useFixedColumn();

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchType, setSearchType] =
    useState<TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE>(
      TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE.name
    );

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const [getListOfEmployeeAppliedToTheTemplate, { data, loading }] =
    useLazyQuery<
      ListOfEmployeeAppliedToTheTemplate,
      ListOfEmployeeAppliedToTheTemplateVariables
    >(QUERY_LIST_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE, {
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (
          !data.listOfEmployeeAppliedToTheTemplate.ok &&
          data.listOfEmployeeAppliedToTheTemplate.error
        ) {
          console.log(data.listOfEmployeeAppliedToTheTemplate.error);
        }
      }
    });

  const [countListEmployeeAppliedToTheTemplate, { data: totalData }] =
    useLazyQuery<
      CountListEmployeeAppliedToTheTemplate,
      CountListEmployeeAppliedToTheTemplateVariables
    >(QUERY_COUNT_LIST_EMPLOYEE_APPLIED_TO_THE_TEMPLATE, {
      fetchPolicy: "cache-and-network"
    });

  const {
    isOpen: isOpenToast,
    handleIsOpen: handleIsOpenToast,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const total: number = useMemo(() => {
    return totalData?.countListEmployeeAppliedToTheTemplate.total ?? 0;
  }, [totalData]);

  const [downloadListOfEmployeeAppliedToTheTemplateExcel] = useLazyQuery<
    DownloadListOfEmployeeAppliedToTheTemplateExcel,
    DownloadListOfEmployeeAppliedToTheTemplateExcelVariables
  >(QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfEmployeeAppliedToTheTemplateExcel.ok &&
        data.downloadListOfEmployeeAppliedToTheTemplateExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfEmployeeAppliedToTheTemplateExcel.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}-list-of-employee-applied-to-the-template.csv`
        );
      }
    }
  });

  const listOfSearchType = useMemo(() => {
    return Object.keys(COLUMN_FOR_VALUE).map(item => ({
      value: item,
      name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
    }));
  }, []);

  const downloadExcel = useCallback(() => {
    if (selectedTemplate) {
      downloadListOfEmployeeAppliedToTheTemplateExcel({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          searchType,
          searchValue
        }
      });
    }
  }, [
    selectedTemplate,
    searchType,
    searchValue,
    downloadListOfEmployeeAppliedToTheTemplateExcel
  ]);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type && selectedTemplate) {
        const INIT_PAGE = 1;
        setSearchType(data.type as TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE);
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.value ?? "");
        countListEmployeeAppliedToTheTemplate({
          variables: {
            templateIdx: selectedTemplate?.templateIdx,
            searchType: data.type as TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE,
            searchValue: data.value
          }
        });
      }
    },
    [countListEmployeeAppliedToTheTemplate, selectedTemplate, handleCurrentPage]
  );

  const columns: Columns = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    return listOfColumn.map(item => {
      let sticky = "";
      if (sFixedColumnNumber !== undefined) {
        if (fixedColumnName.length > 0) {
          fixedColumnName.forEach((fColumn, fColumnIndex) => {
            if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
              sticky = "left";
            }
          });
        }
      }

      return {
        Header:
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE],
        accessor: item as TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE,
        width: 120,
        sticky,
        Cell(
          cell: ReactTable.Cell<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>
        ) {
          if (cell.column.Header === "예외") {
            if (cell.value === IsException.EXCEPTION) {
              return "예외";
            }
            return "포함";
          }
          return cell.value;
        }
      };
    });
  }, [sFixedColumnNumber, fixedColumnName]);

  const list: ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate[] =
    useMemo(() => {
      return (
        data?.listOfEmployeeAppliedToTheTemplate
          .listEmployeesAppliedToTheTemplate ?? []
      );
    }, [data]);

  const table = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const { moveColumn } =
    useDnd<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>(
      {
        columns: table.visibleColumns,
        setColumnOrder: table.setColumnOrder,
        title: `${title}-for-column`,
        setFixedColumnName
      }
    );

  const selectedRow:
    | ReactTable.Row<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  useEffect(() => {
    if (selectedTemplate) {
      countListEmployeeAppliedToTheTemplate({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          searchType,
          searchValue
        }
      });
    }
  }, [
    selectedTemplate,
    countListEmployeeAppliedToTheTemplate,
    searchType,
    searchValue
  ]);

  useEffect(() => {
    if (selectedTemplate) {
      getListOfEmployeeAppliedToTheTemplate({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          page: currentPage,
          take,
          searchType,
          searchValue,
          fieldSort: oldFieldSort
        }
      });
    }
  }, [
    selectedTemplate,
    currentPage,
    take,
    getListOfEmployeeAppliedToTheTemplate,
    searchType,
    searchValue,
    oldFieldSort
  ]);

  useEffect(() => {
    if (
      !data?.listOfEmployeeAppliedToTheTemplate.ok &&
      data?.listOfEmployeeAppliedToTheTemplate.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.listOfEmployeeAppliedToTheTemplate.error);
      handleIsOpenToast(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsOpenToast]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  return (
    <Container>
      <ApprovalNotificationEmployeeHeader<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>
        columns={
          table.columns as TColumn<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>[]
        }
        table={table}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                loading={loading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      {/* <AsonicTable<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={() => {}}
        isLoading={loading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        fieldSort={fieldSort}
        handleFieldSort={handleFieldSort}
        columns={
          employeeColumns as TColumn<ListOfEmployeeAppliedToTheTemplate_listOfEmployeeAppliedToTheTemplate_listEmployeesAppliedToTheTemplate>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      /> */}
      <ApprovalNotificationFooter
        selectedTemplate={selectedTemplate}
        selectedUser={table.selectedFlatRows[table.selectedFlatRows.length - 1]}
      />
      <ToastMessage
        message={message}
        isOpen={isOpenToast}
        handleIsOpen={handleIsOpenToast}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ApprovalNotificationEmployeeSection;
