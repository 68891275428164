import styled from "styled-components";
import MRequestPageStatus from "./m_request_page_status";
import MRequestApprovalContent from "./m_request_approval_content";
import MRequestApprovalFooter from "./m_request_approval_footer";
import { useState } from "react";
import {
  ApprovalTemplateEntity,
  UserEntity
} from "../../../../generated/graphql";

interface IProps {
  setIsAdd: (value: React.SetStateAction<boolean>) => void;
  user?: UserEntity;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
  gap: 10px;
`;

function MRequestApproval({ setIsAdd, user }: IProps) {
  const [page, setPage] = useState<number>(1);
  const [isAddApproval, setIsAddApproval] = useState<boolean>(false);

  return (
    <Container>
      <MRequestPageStatus page={page} setPage={setPage} />
      <MRequestApprovalContent
        page={page}
        user={user}
        isAddApproval={isAddApproval}
      />
      <MRequestApprovalFooter
        page={page}
        setPage={setPage}
        setIsAdd={setIsAdd}
        isAddApproval={isAddApproval}
        setIsAddApproval={setIsAddApproval}
      />
    </Container>
  );
}

export default MRequestApproval;
