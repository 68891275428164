import api from "../api";

const REQUEST = `${api.api}/request`;
const COUNT_SEARCH_REQUEST_DOCS = `${REQUEST}/countSearchRequestDocs`;
const SEARCH_REQUEST_DOCS = `${REQUEST}/searchrequestdocs`;
const SET_ALTER_WORKTIME = `${REQUEST}/setalterworktime`;
const APPROVER = `${REQUEST}/approver/:employeeid`;
const REQUEST_DOWNLOAD_EXCEL = `${REQUEST}/download-excel`;

const requestApi = {
  countSearchRequestDocs: COUNT_SEARCH_REQUEST_DOCS,
  searchRequestDocs: SEARCH_REQUEST_DOCS,
  setAlterWorktime: SET_ALTER_WORKTIME,
  approver: APPROVER,
  requestDownloadExcel: REQUEST_DOWNLOAD_EXCEL
};

export default requestApi;
