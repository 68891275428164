import styled from "styled-components";
import {
  ApprovalEntity,
  ApprovalSearchType,
  ApprovalStatus,
  Approval_For_List_Type,
  useGetListOfApprovalLazyQuery
} from "../../generated/graphql";
import SummaryTable from "../summary-table/summary-table";
import * as ReactTable from "react-table";
import routes from "../../routes";
import { Icon } from "@iconify/react";
import ExternalLinkIcon from "@iconify-icons/eva/external-link-outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ItemForTitle,
  Item,
  IconContainer,
  Point,
  COLUMN_FOR_VALUE,
  TYPE_OF_AWAITING_APPROVAL,
  APPROVAL_STATUS
} from "./approval-awaiting-summary-table";
import { ApprovalType, IApprovalDetailMessage } from "./approval-detail-popup";
import EChannel from "../../Utils/EChannel/e-channel";
import listOfApolloVar from "../../apollo/apollo-var";
import { Cell } from "../../../types/@react-table/react-table/react-table";
import { useReactiveVar } from "@apollo/client";
import ApprovalReceiveReference from "./approval-list/approval-receive-reference";
import { APPROVAL_DASHBOARD_TYPE } from "./approval-dash-board";

const LIST_TYPE = {
  RECEIVE: "RECEIVE",
  REFERENCE: "REFERENCE"
} as const;

type LIST_TYPE = typeof LIST_TYPE[keyof typeof LIST_TYPE];

interface IApprovalEntityForReceiveAndReFerence extends ApprovalEntity {
  listType?: LIST_TYPE;
}

interface IProps {
  handleShowApprovalPopup: (value: boolean) => void;
  handleDashboardType: (value: APPROVAL_DASHBOARD_TYPE) => void;
  dashboardType: APPROVAL_DASHBOARD_TYPE;
  take: number;
  handleSelectedRowIndex: (index: number) => void;
  handleSetList: (payload: ApprovalEntity[]) => void;
}

const Container = styled.div<{ dashboardType: APPROVAL_DASHBOARD_TYPE }>`
  display: ${props =>
    props.dashboardType === APPROVAL_DASHBOARD_TYPE.ALL ||
    props.dashboardType === APPROVAL_DASHBOARD_TYPE.RECEIVE_REFERENCE
      ? "flex"
      : "none"};
  flex: 1;
`;

function ApprovalReceiveReferenceSummaryTable({
  dashboardType,
  handleShowApprovalPopup,
  handleDashboardType,
  take,
  handleSelectedRowIndex,
  handleSetList
}: IProps) {
  const [isAllList, setIsAllList] = useState<boolean>(false);
  const FORM_NAME = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [selectedRow, setSelectedRow] = useState<
    IApprovalEntityForReceiveAndReFerence | undefined
  >(undefined);

  const handleIsAllList = useCallback((isAll: boolean) => {
    setIsAllList(isAll);
    handleDashboardType(APPROVAL_DASHBOARD_TYPE.RECEIVE_REFERENCE);
  }, []);

  const bc = useMemo(() => {
    if (selectedRow) {
      return new BroadcastChannel(EChannel.APPROVAL);
    }
    return;
  }, [selectedRow]);

  const [getListOfAwaitingApproval, { data, loading }] =
    useGetListOfApprovalLazyQuery();

  const [
    getListOfReferenceApproval,
    { data: referenceData, loading: referenceLoading }
  ] = useGetListOfApprovalLazyQuery();

  const list: IApprovalEntityForReceiveAndReFerence[] = useMemo(() => {
    let newReceiveData: IApprovalEntityForReceiveAndReFerence[] = [];
    let newReferenceData: IApprovalEntityForReceiveAndReFerence[] = [];
    if (data?.getListOfApproval.list) {
      newReceiveData = data?.getListOfApproval.list.map(item => {
        return { ...item, listType: LIST_TYPE.RECEIVE };
      });
      newReceiveData = data?.getListOfApproval.list.map(item => ({
        ...item,
        listType: LIST_TYPE.REFERENCE
      }));
    }
    if (referenceData?.getListOfApproval.list) {
      newReferenceData = [...referenceData?.getListOfApproval.list];
    }
    return [...newReceiveData, ...newReferenceData];
  }, [data, referenceData]);

  const columns: ReactTable.Column<IApprovalEntityForReceiveAndReFerence>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let width = 140;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
          COLUMN_FOR_VALUE.approvalTitle
        ) {
          width = 350;
        }
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
          COLUMN_FOR_VALUE.docNumber
        ) {
          width = 200;
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL],
          accessor: item as TYPE_OF_AWAITING_APPROVAL,
          width,
          Cell(cell: Cell<IApprovalEntityForReceiveAndReFerence>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
              COLUMN_FOR_VALUE.docNumber
            ) {
              return (
                <Item>
                  <Point>●</Point>
                  {cell.value}
                </Item>
              );
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
              COLUMN_FOR_VALUE.formIdx
            ) {
              const formName = FORM_NAME.get(cell.value);
              return <Item>{`[${formName}]`}</Item>;
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
              COLUMN_FOR_VALUE.approvalStatus
            ) {
              let value = APPROVAL_STATUS.APPROVAL;
              switch (cell.value) {
                case ApprovalStatus.Return:
                  value = APPROVAL_STATUS.RETURN;
                  break;
                case ApprovalStatus.Waiting:
                  value = APPROVAL_STATUS.WAITING;
                  break;
                case ApprovalStatus.Outstanding:
                  value = APPROVAL_STATUS.OUTSTANDING;
                  break;
                case ApprovalStatus.InTheMiddleOfReturn:
                  value = APPROVAL_STATUS.IN_THE_MIDDLE_OF_RETURN;
                  break;
              }
              return <Item>{`[${value}]`}</Item>;
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
              COLUMN_FOR_VALUE.approvalTitle
            ) {
              return (
                <ItemForTitle>
                  <span
                    onClick={() => {
                      listOfApolloVar.approvalTypeVar(
                        ApprovalType.APPROVAL_RECEIVE_REFERENCE
                      );
                      handleSelectedRowIndex(cell?.row?.index);
                      listOfApolloVar.approvalDataVar(cell?.row?.original);
                      handleSetList(list);
                      handleShowApprovalPopup(true);
                    }}
                  >
                    {cell.value}
                  </span>
                  <IconContainer
                    onClick={() => {
                      if (cell.row.original.listType === LIST_TYPE.RECEIVE) {
                        listOfApolloVar.approvalTypeVar(ApprovalType.RECEIVE);
                      } else {
                        listOfApolloVar.approvalTypeVar(ApprovalType.REFERENCE);
                      }
                      listOfApolloVar.approvalDataVar(cell?.row?.original);
                      setSelectedRow(cell?.row?.original);
                      window.open(
                        routes.pageRoutes.approvalPopup,
                        "",
                        "width=800, height=880, scrollbars=yes"
                      );
                    }}
                  >
                    <Icon icon={ExternalLinkIcon} />
                  </IconContainer>
                </ItemForTitle>
              );
            }
            const name = cell.value;
            const departmentName = cell?.row?.original.departName || "";
            const date = cell?.row?.original.signUpDateTime || "";
            const value = `${departmentName} | ${name} | ${date}`;
            return <Item>{`[${value}]`}</Item>;
          }
        };
      });
    }, [handleShowApprovalPopup, handleSelectedRowIndex, handleSetList, list]);

  const {
    prepareRow,
    getTableProps,
    // headerGroups,
    getTableBodyProps,
    rows
  } = ReactTable.useTable<IApprovalEntityForReceiveAndReFerence>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  useEffect(() => {
    getListOfAwaitingApproval({
      variables: {
        approvalType: Approval_For_List_Type.Receive,
        searchType: ApprovalSearchType.All,
        searchValue: "",
        take
      }
    });
    getListOfReferenceApproval({
      variables: {
        approvalType: Approval_For_List_Type.Reference,
        searchType: ApprovalSearchType.All,
        searchValue: "",
        take
      }
    });
  }, [getListOfAwaitingApproval, take, getListOfReferenceApproval]);

  useEffect(() => {
    if (bc && selectedRow) {
      bc.onmessage = (event: any) => {
        if (selectedRow.listType === LIST_TYPE.RECEIVE) {
          const message: IApprovalDetailMessage = {
            type: ApprovalType.RECEIVE,
            data: selectedRow,
            isRequest: false
          };
          bc.postMessage(message);
        } else {
          const message: IApprovalDetailMessage = {
            type: ApprovalType.REFERENCE,
            data: selectedRow,
            isRequest: false
          };
          bc.postMessage(message);
        }
        setSelectedRow(undefined);
        bc.close();
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, selectedRow]);

  return (
    <Container dashboardType={dashboardType}>
      {isAllList ? (
        <ApprovalReceiveReference />
      ) : (
        <SummaryTable
          title="수신 및 참조"
          isLoading={loading || referenceLoading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          isTitleBar={false}
          handleIsAllList={handleIsAllList}
          flexIndex={1}
        />
      )}
    </Container>
  );
}

export default ApprovalReceiveReferenceSummaryTable;
