import styled from "styled-components";
import { Link } from "react-router-dom";

interface IProps {
  isPath: boolean;
}

export const NavContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 46px;
  background-color: #21212b;
  color: white;
`;

export const Item = styled.div<IProps>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  :active {
    background-color: #3b37ca;
  }
  :last-child {
    background-color: #494949;
    :active {
      background-color: #86b272;
    }
  }
  border-top: ${props => props.isPath && "3px solid #EA2027"};
`;

export const SLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: inherit;
  text-decoration: none;
`;
