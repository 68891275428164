import StyledFlexContainer from "../../../shared/styled_flex_container";
import PageSet from "../../../table/set_page";
import HideColumn, { IHideColumnProps } from "../../../table/hide_column";
import PointTitle from "../../../shared/point_title";
import styled from "styled-components";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../table_v2/fixed_column_dropbox";
import AsonicExcel from "../../../asonic-table/asonic-excel";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  count: number;
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;
function AttendanceDetailsHeader<P extends object>({
  columns,
  table,
  title,
  headerTitleList,
  count,
  downloadExcel,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN,
  setIsActiveFilter
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title={title} subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
          setIsActiveFilter={setIsActiveFilter}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <AsonicExcel downloadExcel={downloadExcel} />
      </LeftSection>
    </Container>
  );
}

export default AttendanceDetailsHeader;
