import { useCallback, useState } from "react";

const useSelectedEventList = () => {
  const [selectedEventList, setSelectedEventList] = useState<
    Partial<DayInfo>[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const handleSelectedDate = useCallback((fullDate: string) => {
    setSelectedDate(fullDate);
  }, []);

  const handleSelectedEventList = useCallback((payload: Partial<DayInfo>[]) => {
    setSelectedEventList(payload);
  }, []);
  return {
    selectedEventList,
    handleSelectedEventList,
    selectedDate,
    handleSelectedDate
  };
};

export default useSelectedEventList;
