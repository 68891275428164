import ContentContainer from "../../../shared/grid_content_container/grid_content_container";
import FormRow from "../../../shared/form-row/form-row";
import SSelect from "../../../shared/s_select/s_select";
import styled from "styled-components";
import StyleInput from "../../../inputs/style-input";
import TrashIcon from "@iconify-icons/bi/trash";
import { Icon } from "@iconify/react";
import useTimeList, {
  HOUR_TYPE
} from "../../../../hooks/use_time_list/use_time_list";
import {
  OtWorkingStandardHoursEntity,
  Day_Of_Week_Type
} from "../../../../generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { Work_Week_Type } from "../../../../generated/graphql";
import { Day_Type } from "../../../../generated/graphql";
import { Break_Time_Type } from "../../../../generated/graphql";
import useTimeControl from "../../../../hooks/use_time_control/use_time_control";
import RadioContainer from "../../../shared/radio_container/radio_container";

interface IRenderRow {
  data: OtWorkingStandardHoursEntity[];
  index: number;
  deleteExtraDaySettingList: (payload: OtWorkingStandardHoursEntity) => void;
  handleList: ({
    template,
    index
  }: {
    template: OtWorkingStandardHoursEntity;
    index: number;
  }) => void;
}

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin-bottom: 10px;
`;

const DeleteContainer = styled.div`
  display: flex;
  width: 50px;
  background-color: ${props => props.theme.colors.frameGrey};
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    font-size: 18px;
    :hover {
      color: ${props => props.theme.colors.darkGrey};
    }
    :active {
      transform: scale(0.95);
    }
  }
`;

function WDAdditionalRow({
  index,
  data,
  deleteExtraDaySettingList,
  handleList
}: IRenderRow) {
  const { displayHours, makeHoursType, makeHours } = useTimeControl();
  const { hourList, minList, hourTypeList } = useTimeList();
  const template = data[index];
  const [startTime, setStartTime] = useState<string>(template.startTime);
  const [endTime, setEndTime] = useState<string>(template.endTime);
  const [workWeekType, setWorkWeekType] = useState(template.workWeekType);
  const [dayOfWeekType, setDayOfWeekType] = useState(template.dayOfWeekType);
  const [dayOfWeekList, setDayOfWeekList] = useState<Day_Type[]>(
    template.dayOfWeek
  );
  const [workWeekSelectList, setWorkWeekSelectList] = useState<number[]>(
    template.workWeekSelect
  );
  const [startBreakTime, setStartBreakTime] = useState<string>(
    template.startBreakTime
  );
  const [endBreakTime, setEndBreakTime] = useState<string>(
    template.endBreakTime
  );
  const [timeSequence, setTimeSequence] = useState<number>(
    template.timeSequence
  );
  const [breakTimeType, setBreakTimeType] = useState<Break_Time_Type>(
    template.breakTimeType
  );
  const workingTemplateIdx = useMemo(() => {
    return data[index].workingTemplateIdx;
  }, [data[index].workingTemplateIdx]);

  useEffect(() => {
    const payload = {
      breakTimeType,
      dayOfWeek: dayOfWeekList,
      dayOfWeekType,
      endBreakTime,
      endTime,
      startBreakTime,
      startTime,
      workWeekSelect: workWeekSelectList,
      workWeekType,
      timeSequence,
      workingTemplateIdx
    };
    handleList({ template: payload, index });
  }, [
    handleList,
    index,
    breakTimeType,
    dayOfWeekList,
    dayOfWeekType,
    endBreakTime,
    endTime,
    startBreakTime,
    startTime,
    workWeekSelectList,
    workWeekType,
    timeSequence,
    workingTemplateIdx
  ]);

  useEffect(() => {
    switch (dayOfWeekType) {
      case Day_Of_Week_Type.All:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat,
          Day_Type.Sun
        ]);
        break;
      case Day_Of_Week_Type.MonToSat:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat
        ]);
        break;
      case Day_Of_Week_Type.MonToFri:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri
        ]);
        break;
    }
  }, [dayOfWeekType]);

  useEffect(() => {
    if (workWeekType === Work_Week_Type.All) {
      setWorkWeekSelectList([1, 2, 3, 4, 5]);
    }
  }, [workWeekType]);

  return (
    <ItemContainer>
      <ContentContainer>
        <FormRow title="출근 시간">
          <SSelect
            id={`startTimeType:${index}`}
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id={`startTimeHour:${index}`}
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startTime.split(":")[0])}
            onChange={event => {
              const hours = startTime.split(":")[0];
              const minute = startTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`startTimeHour:${index}`}>{`시`}</label>
          <SSelect
            id={`tartTimeMin:${index}`}
            minWidth="50px"
            maxWidth="50px"
            value={startTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`tartTimeMin:${index}`}>{`분`}</label>
        </FormRow>
        <FormRow title="퇴근 시간">
          <SSelect
            id={`endTimeType:${index}`}
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id={`endTimeHour:${index}`}
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endTime.split(":")[0])}
            onChange={event => {
              const hours = endTime.split(":")[0];
              const minute = endTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`endTimeHour:${index}`}>{`시`}</label>
          <SSelect
            id={`endTimeMin:${index}`}
            minWidth="50px"
            maxWidth="50px"
            value={endTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`endTimeMin:${index}`}>{`분`}</label>
        </FormRow>
        <FormRow title={`근무주차 설정`}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id={`additionalWorkWeekType:${Work_Week_Type.All}${index}`}
              minWidth="15px"
              maxWidth="15px"
              name={`additionalWorkWeekType:${index}`}
              checked={workWeekType === Work_Week_Type.All}
              onChange={event => {
                setWorkWeekType(Work_Week_Type.All);
              }}
            />
            <label
              htmlFor={`additionalWorkWeekType:${Work_Week_Type.All}${index}`}
            >{`모든 주`}</label>
            <StyleInput
              type="radio"
              id={`additionalWorkWeekType:${Work_Week_Type.InputMethod}${index}`}
              minWidth="15px"
              maxWidth="15px"
              name={`additionalWorkWeekType:${index}`}
              checked={workWeekType === Work_Week_Type.InputMethod}
              onChange={event => {
                setWorkWeekType(Work_Week_Type.InputMethod);
              }}
            />
            <label
              htmlFor={`additionalWorkWeekType:${Work_Week_Type.InputMethod}${index}`}
            >{`직접 설정`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무주차 선택"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id={`additionalSelectWeek:${index}_1주`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalSelectWeek:${index}`}
              value={1}
              checked={workWeekSelectList.includes(1)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(1)) {
                    return newList.filter(item => item !== 1);
                  } else {
                    newList.push(1);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={`additionalSelectWeek:${index}_1주`}>{`1주`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalSelectWeek:${index}_2주`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalSelectWeek:${index}_2주`}
              value={1}
              checked={workWeekSelectList.includes(2)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(2)) {
                    return newList.filter(item => item !== 2);
                  } else {
                    newList.push(2);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={`additionalSelectWeek:${index}_2주`}>{`2주`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalSelectWeek:${index}_3주`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalSelectWeek:${index}_3주`}
              value={3}
              checked={workWeekSelectList.includes(3)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(3)) {
                    return newList.filter(item => item !== 3);
                  } else {
                    newList.push(3);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={`additionalSelectWeek:${index}_3주`}>{`3주`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalSelectWeek:${index}_4주`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalSelectWeek:${index}_4주`}
              value={3}
              checked={workWeekSelectList.includes(4)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(4)) {
                    return newList.filter(item => item !== 4);
                  } else {
                    newList.push(4);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={`additionalSelectWeek:${index}_4주`}>{`4주`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalSelectWeek:${index}_5주`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalSelectWeek:${index}_5주`}
              value={3}
              checked={workWeekSelectList.includes(5)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(5)) {
                    return newList.filter(item => item !== 5);
                  } else {
                    newList.push(5);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={`additionalSelectWeek:${index}_5주`}>{`5주`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무요일 설정"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.All}`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.All}`}
              checked={dayOfWeekType === Day_Of_Week_Type.All}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.All);
              }}
            />
            <label
              htmlFor={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.All}`}
            >{`모든 요일`}</label>
            <StyleInput
              type="radio"
              id={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.MonToFri}`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.MonToFri}`}
              checked={dayOfWeekType === Day_Of_Week_Type.MonToFri}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.MonToFri);
              }}
            />
            <label
              htmlFor={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.MonToFri}`}
            >{`주중(월~금)`}</label>
            <StyleInput
              type="radio"
              id={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.MonToSat}`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.MonToSat}`}
              checked={dayOfWeekType === Day_Of_Week_Type.MonToSat}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.MonToSat);
              }}
            />
            <label
              htmlFor={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.MonToSat}`}
            >{`주중(월~토)`}</label>
            <StyleInput
              type="radio"
              id={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.InputMethod}`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.InputMethod}`}
              checked={dayOfWeekType === Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.InputMethod);
              }}
            />
            <label
              htmlFor={`additionalDayOfWeekType:${index}:${Day_Of_Week_Type.InputMethod}`}
            >{`직접선택`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무요일 선택"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Mon}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Mon}
              checked={dayOfWeekList.includes(Day_Type.Mon)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Mon)) {
                    return newList.filter(item => item !== Day_Type.Mon);
                  } else {
                    newList.push(Day_Type.Mon);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Mon}`}
            >{`월`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Tue}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Tue}
              checked={dayOfWeekList.includes(Day_Type.Tue)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Tue)) {
                    return newList.filter(item => item !== Day_Type.Tue);
                  } else {
                    newList.push(Day_Type.Tue);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Tue}`}
            >{`화`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Wed}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Wed}
              checked={dayOfWeekList.includes(Day_Type.Wed)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Wed)) {
                    return newList.filter(item => item !== Day_Type.Wed);
                  } else {
                    newList.push(Day_Type.Wed);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Wed}`}
            >{`수`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Thr}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Thr}
              checked={dayOfWeekList.includes(Day_Type.Thr)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Thr)) {
                    return newList.filter(item => item !== Day_Type.Thr);
                  } else {
                    newList.push(Day_Type.Thr);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Thr}`}
            >{`목`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Fri}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Fri}
              checked={dayOfWeekList.includes(Day_Type.Fri)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Fri)) {
                    return newList.filter(item => item !== Day_Type.Fri);
                  } else {
                    newList.push(Day_Type.Fri);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Fri}`}
            >{`금`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Sat}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Sat}
              checked={dayOfWeekList.includes(Day_Type.Sat)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Sat)) {
                    return newList.filter(item => item !== Day_Type.Sat);
                  } else {
                    newList.push(Day_Type.Sat);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Sat}`}
            >{`토`}</label>
            <StyleInput
              type="checkbox"
              id={`additionalDayType:${index}:${Day_Type.Sun}`}
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              value={Day_Type.Sun}
              checked={dayOfWeekList.includes(Day_Type.Sun)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Sun)) {
                    return newList.filter(item => item !== Day_Type.Sun);
                  } else {
                    newList.push(Day_Type.Sun);
                  }
                  return newList;
                });
              }}
            />
            <label
              htmlFor={`additionalDayType:${index}:${Day_Type.Sun}`}
            >{`일`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={`휴게시간 적용`}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id={`additionalBreakTimeTypeBasicBreakTime:${index}`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalBreakTimeType:${index}`}
              checked={breakTimeType === Break_Time_Type.Basic}
              onChange={() => {
                setBreakTimeType(Break_Time_Type.Basic);
              }}
            />
            <label
              htmlFor={`additionalBreakTimeTypeBasicBreakTime:${index}`}
            >{`기본 휴게시간 사용`}</label>
            <StyleInput
              type="radio"
              id={`additionalBreakTimeTypeSeparateSetting:${index}`}
              minWidth="20px"
              maxWidth="20px"
              name={`additionalBreakTimeType:${index}`}
              checked={breakTimeType === Break_Time_Type.SeparateSetting}
              onChange={() => {
                setBreakTimeType(Break_Time_Type.SeparateSetting);
              }}
            />
            <label
              htmlFor={`additionalBreakTimeTypeSeparateSetting:${index}`}
            >{`별도 설정`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="휴게시간 설정">
          <SSelect
            id={`additionalStartHourType:${index}`}
            minWidth="60px"
            maxWidth="60px"
            name={`additionalStartHourType:${index}`}
            value={
              parseInt(startBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartBreakTime(newTime);
            }}
            disabled={breakTimeType !== Break_Time_Type.SeparateSetting}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id={`additionalStartHour:${index}`}
            minWidth="50px"
            maxWidth="50px"
            name={`additionalStartHour:${index}`}
            value={displayHours(startBreakTime.split(":")[0])}
            onChange={event => {
              const hours = startBreakTime.split(":")[0];
              const minute = startBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartBreakTime(`${parsedHours}:${minute}`);
            }}
            disabled={breakTimeType !== Break_Time_Type.SeparateSetting}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`additionalStartHour:${index}`}>{`시`}</label>
          <SSelect
            id={`additionalStartBreakTimeMin:${index}`}
            minWidth="50px"
            maxWidth="50px"
            name={`additionalStartBreakTimeMin:${index}`}
            value={startBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            disabled={breakTimeType !== Break_Time_Type.SeparateSetting}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`additionalStartBreakTimeMin:${index}`}>{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            id={`additionalEndHourType:${index}`}
            minWidth="60px"
            maxWidth="60px"
            name={`additionalEndHourType:${index}`}
            value={
              parseInt(endBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndBreakTime(newTime);
            }}
            disabled={breakTimeType !== Break_Time_Type.SeparateSetting}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id={`additionalEndHour:${index}`}
            minWidth="50px"
            maxWidth="50px"
            name={`additionalEndHour:${index}`}
            value={displayHours(endBreakTime.split(":")[0])}
            onChange={event => {
              const hours = endBreakTime.split(":")[0];
              const minute = endBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndBreakTime(`${parsedHours}:${minute}`);
            }}
            disabled={breakTimeType !== Break_Time_Type.SeparateSetting}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`additionalEndHour:${index}`}>{`시`}</label>
          <SSelect
            id={`additionalEndBreakTimeMin:${index}`}
            minWidth="50px"
            maxWidth="50px"
            name={`additionalEndBreakTimeMin:${index}`}
            value={endBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            disabled={breakTimeType !== Break_Time_Type.SeparateSetting}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor={`additionalEndBreakTimeMin:${index}`}>{`분`}</label>
        </FormRow>
      </ContentContainer>
      <DeleteContainer
        onClick={() => {
          deleteExtraDaySettingList(data[index]);
        }}
      >
        <Icon icon={TrashIcon} />
      </DeleteContainer>
    </ItemContainer>
  );
}

export default WDAdditionalRow;
