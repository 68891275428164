import moment from "moment";
import uiString from "./string.json";

export const _findNameOfCategoryId = (value, categorySelectionReducer) => {
  if (!value.category_id) return;
  for (let i = 0; i < categorySelectionReducer.categories.length; i++) {
    if (
      categorySelectionReducer.categories[i].category_id === value.category_id
    ) {
      value.category_id = categorySelectionReducer.categories[i].name;
      return value;
    }
  }
  return value;
};

export const _resultTranslation = value => {
  if (value === 1 || value === 2) {
    return uiString.tables.columns.approved;
  } else if (value === 3) {
    return uiString.tables.columns.rejected;
  } else {
    return uiString.tables.columns.pending;
  }
};

export const _dateTranslation = (startDate, startTime, endDate, endTime) => {
  const start = moment(`${startDate} ${startTime}`).format(
    "YYYY년 MM월 DD일 HH:mm"
  );

  const end = moment(`${endDate} ${endTime}`).format("YYYY년 MM월 DD일 HH:mm");

  const resultTime = `${start} ~ ${end}`;
  return resultTime;
};
