import styled from "styled-components";

const BORDER_COLOR = "rgba(0, 0, 0, 0.2)";

export const Container = styled.div`
  border: 1px solid ${BORDER_COLOR};
  background-color: white;
  border-radius: 2px;
  grid-column: span 1;
  grid-row: 2 / span 2;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${BORDER_COLOR};
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
`;

export const SubTitle = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
`;

export const ChartContainer = styled.div`
  height: 80%;
  font-size: 12px;
`;
