import StyledFlexContainer from "../../../../../components/shared/styled_flex_container";
import PageSet from "../../../../../components/table/set_page";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import HideColumn, {
  IHideColumnProps
} from "../../../../../components/table/hide_column";
import PointTitle from "../../../../../components/shared/point_title";
import styled from "styled-components";
import StyledInput from "../../../../../components/shared/styled_input";
import IconContainer from "../../../../../components/shared/icon_container";
import { useCallback, useContext } from "react";
import * as Excel from "exceljs";
import {
  AddUserDailyWorkInfoType,
  useAddUserDailyWorkInfoMutation
} from "../../../../../generated/graphql";
import moment from "moment";
import { MessageTypes } from "../../../../../components/toast-message/toast-message";
import AsonicExcel from "../../../../../components/asonic-table/asonic-excel";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../../../components/table_v2/fixed_column_dropbox";
import MainViewContext from "../../../../../components/main-view/store";
import ExcelIcon from "@iconify/icons-fa-solid/file-excel";
import { colors } from "../../../../../components/GlobalStyle/GlobalStyle";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleToast: (message: string, type: MessageTypes) => void;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
  downloadWorkPlan?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

interface LabelProps {
  backgroundColor?: string;
  hoverColor?: string;
}

const Label = styled.label<LabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 5px;
  border-radius: 4px;
  background-color: ${props =>
    props.backgroundColor || props.theme.colors.green};
  cursor: pointer;
  height: max-content;
  padding: 6px;
  :hover {
    background-color: ${props =>
      props.hoverColor || props.theme.colors.lightGreen};
  }
`;

const InputFile = styled.input`
  display: none;
`;

function UserDailyWorkHeader<P extends object>({
  columns,
  table,
  title,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setIsSearch,
  headerTitleList,
  take,
  handleTake,
  count,
  handleToast,
  handleCurrentPage,
  downloadExcel,
  downloadWorkPlan,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN
}: IProps<P>) {
  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: {
      loginIdInfo: authCheck,
      checkWebAuthFn,
      employee_id: callEmployeeId
    }
  } = rootStore;
  const [addUserDailyWorkInfo, { client }] = useAddUserDailyWorkInfoMutation({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 선택된 일별 근무 계획을 추가하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.addUserDailyWorkInfo.ok) {
        handleToast(
          "성공적으로 선택된 일별 근무 계획을 추가 하였습니다",
          MessageTypes.SUCCESS
        );
        client.resetStore();
      } else if (data?.addUserDailyWorkInfo.error) {
        handleToast(data?.addUserDailyWorkInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const handleXlsx = useCallback((file: File) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      if (buffer) {
        const list: AddUserDailyWorkInfoType[] = [];
        wb.xlsx.load(buffer as ArrayBuffer).then(workbook => {
          let employeeIdIndex = 0;
          let startDateIndex = 0;
          let commuteIndex = 0;
          let leaveWork = 0;
          workbook.eachSheet((sheet, id) => {
            if (id === 1) {
              sheet.eachRow((row, rowIndex) => {
                const value = row.values as any[];
                if (rowIndex === 1) {
                  employeeIdIndex = value.findIndex(item => {
                    if (typeof item === "string") {
                      return item.includes("사번");
                    }
                    return false;
                  });
                  startDateIndex = value.findIndex(item => {
                    if (typeof item === "string") {
                      return item.includes("날짜");
                    }
                    return false;
                  });
                  commuteIndex = value.findIndex(item => {
                    if (typeof item === "string") {
                      return item.includes("출근");
                    }
                    return false;
                  });
                  leaveWork = value.findIndex(item => {
                    if (typeof item === "string") {
                      return item.includes("퇴근");
                    }
                    return false;
                  });
                }
                if (rowIndex > 1 && value[employeeIdIndex]) {
                  let date = "";
                  let startTime = "";
                  let endTime = "";
                  if (value[startDateIndex]) {
                    date = moment(value[startDateIndex]).format("YYYY-MM-DD");
                  }
                  if (value[commuteIndex]) {
                    startTime = moment
                      .utc(value[commuteIndex])
                      .utcOffset(0)
                      .format("HH:mm");
                  }
                  if (value[leaveWork]) {
                    endTime = moment
                      .utc(value[leaveWork])
                      .utcOffset(0)
                      .format("HH:mm");
                  }
                  let employeeId: string = "";
                  if (value[employeeIdIndex]) {
                    if (typeof value[employeeIdIndex] === "object") {
                      employeeId = value[employeeIdIndex].result;
                    } else {
                      employeeId = value[employeeIdIndex];
                    }
                  }
                  const payload: AddUserDailyWorkInfoType = {
                    employeeId: `${employeeId}`,
                    callEmployeeId,
                    date,
                    startTime,
                    endTime
                  };
                  list.push(payload);
                }
              });
            }
          });
          if (list.length > 0) {
            addUserDailyWorkInfo({
              variables: {
                info: list
              }
            });
          }
        });
      }
    };
  }, []);

  const handleCvs = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const csvData = reader.result as string;
      if (csvData) {
        const list: AddUserDailyWorkInfoType[] = [];
        const rows = csvData.split("\n");

        let employeeIdIndex = 0;
        let startDateIndex = 0;
        let commuteIndex = 0;
        let leaveWork = 0;

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const values = row.split(",");

          if (i === 0) {
            employeeIdIndex = values.findIndex(item => item.includes("사번"));
            startDateIndex = values.findIndex(item => item.includes("날짜"));
            commuteIndex = values.findIndex(item => item.includes("출근"));
            leaveWork = values.findIndex(item => item.includes("퇴근"));
          } else if (values[employeeIdIndex]) {
            let date = "";
            let startTime = "";
            let endTime = "";

            if (values[startDateIndex]) {
              date = moment(values[startDateIndex]).format("YYYY-MM-DD");
            }
            if (values[commuteIndex]) {
              startTime = moment
                .utc(values[commuteIndex])
                .utcOffset(0)
                .format("HH:mm");
            }
            if (values[leaveWork]) {
              endTime = moment
                .utc(values[leaveWork])
                .utcOffset(0)
                .format("HH:mm");
            }

            const employeeId =
              typeof values[employeeIdIndex] === "number"
                ? values[employeeIdIndex].toString()
                : values[employeeIdIndex];

            const payload: AddUserDailyWorkInfoType = {
              employeeId,
              callEmployeeId,
              date,
              startTime,
              endTime
            };

            list.push(payload);
          }
        }
        if (list.length > 0) {
          // 추가적인 처리나 업로드 로직을 여기에 추가할 수 있습니다.
          // addUserDailyWorkInfo({
          //   variables: {
          //     info: list,
          //   },
          // });
        }
      }
    };
  }, []);

  const handleSelectedFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      if (files && files[0]) {
        const file = files[0];
        if (file.type === "text/csv") {
          handleCvs(file);
        } else {
          handleXlsx(file);
        }
      }
      event.target.value = ""; // value 초기화
    },
    [handleXlsx, useCallback]
  );

  return (
    <Container>
      <LeftSection>
        <PointTitle title="검색결과" subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          <StyledInput
            type="date"
            value={startDate}
            onChange={event => {
              setStartDate(event.currentTarget.value);
            }}
          />
          <span>{`~`}</span>
          <StyledInput
            type="date"
            value={endDate}
            onChange={event => {
              setEndDate(event.currentTarget.value);
            }}
          />
          <IconContainer
            onClick={() => {
              setIsSearch(true);
              handleCurrentPage(1);
            }}
          >
            <Icon icon={MagnifyIcon} />
          </IconContainer>
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
      <FileContainer>
        <Label htmlFor="excelFile">{`excel 등록`}</Label>
        <InputFile
          onChange={handleSelectedFile}
          id="excelFile"
          type="file"
          accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          name="excel"
        />
        <Label
          backgroundColor={colors.darkBlue}
          hoverColor={colors.skyBlue}
          htmlFor="workHourExcelFile"
          onClick={e => {
            e.preventDefault(); // 기본 동작 방지
            if (downloadWorkPlan) {
              downloadWorkPlan();
            }
          }}
        >
          <Icon icon={ExcelIcon} />
          {`근태표`}
        </Label>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </FileContainer>
    </Container>
  );
}

export default UserDailyWorkHeader;
