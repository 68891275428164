import styled from "styled-components";
import colors from "../../../../../style-sheet/colors";

type Props = { backGroundColor?: string };

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleBar = styled.div``;

export const Title = styled.span`
  font-weight: bold;
  color: ${colors.personalDashBoard.todayWorkingHoursBox.childTitleColor};
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
`;

export const ChildTitleBar = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  align-items: center;
  width: 60%;
`;

export const ChildTitle = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
`;

export const PaintsTitle = styled.div<Props>`
  width: 10px;
  height: 10px;
  background-color: ${props => props.backGroundColor ?? "none"};
`;

export const TimeContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.backGroundColor ?? "red"};
  border-radius: 2px;
  padding: 4px;
`;

export const TimeDescription = styled.span`
  font-size: 18px;
`;
