import * as React from "react";
import masterWorkManagementPageApi from "../../../api/master-work-management-page-api";
import { downloadFileFromServer } from "../../../components/main-view/statistics/Utils";
import moment from "moment";

const useMasterWorkDashboardDownloadExcel = () => {
  const downloadExcel = React.useCallback(async () => {
    try {
      const { data } = await masterWorkManagementPageApi.downloadExcel({});
      if (data) {
        downloadFileFromServer(
          data,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-master-dashboard.csv`
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  return { downloadExcel };
};

export default useMasterWorkDashboardDownloadExcel;
