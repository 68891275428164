import * as React from "react";
import { workManagementAxios } from "../../../../../types/axios";

const USER_COUNT = "USER_COUNT";
const SELECTED_DEPARTMENT = "SELECTED_DEPARTMENT";

export type NumberOfPeopleFormat = {
  [section: string]: number;
};

export type WorkManagementContextList = {
  userCount: number;
  selectedDepartment: number;
  downloadUserInfosExcel: workManagementAxios.DownloadUserInfosExcel;
  getNumberofPeopleFromLocalStoreage: () => NumberOfPeopleFormat | undefined;
};

export const WorkManagementContext =
  React.createContext<WorkManagementContextList | undefined>(undefined);

export type Action = { type: string; newState: string | number };
export type WorkManagementDispatchType = React.Dispatch<Action>;

export const WorkManagementDispatch =
  React.createContext<WorkManagementDispatchType | undefined>(undefined);

export const WorkManagementReducer = (
  state: WorkManagementContextList,
  action: Action
): WorkManagementContextList => {
  switch (action.type) {
    case USER_COUNT:
      if (typeof action.newState === "number") {
        const newState = { ...state, userCount: action.newState };
        return newState;
      } else {
        return { ...state };
      }
    case SELECTED_DEPARTMENT:
      if (typeof action.newState === "number") {
        const newState = { ...state, selectedDepartment: action.newState };
        return newState;
      } else {
        return { ...state };
      }
    default:
      throw new Error("unhandled action");
  }
};

// reducer utils
export const useWorkmanagementState = () => {
  const state = React.useContext(WorkManagementContext);
  if (!state) throw new Error("WorkManagementContext provider not found");
  return state;
};

export const useWorkmanagementDispatch = () => {
  const dispatch = React.useContext(WorkManagementDispatch);
  if (!dispatch) throw new Error("WorkManagementDispatch provider not found");
  return dispatch;
};

export const handleUserCountDispatch = (
  dispatch: WorkManagementDispatchType,
  payload: number
) => {
  const action = { type: "USER_COUNT", newState: payload };
  dispatch(action);
};

export const handleSelectedDepartmentDispatch = (
  dispatch: WorkManagementDispatchType,
  payload: number
) => {
  const action = { type: SELECTED_DEPARTMENT, newState: payload };
  dispatch(action);
};
