import StyledFlexContainer from "../../../shared/styled_flex_container";
import PageSet from "../../../table/set_page";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import HideColumn, { IHideColumnProps } from "../../../table/hide_column";
import PointTitle from "../../../shared/point_title";
import styled from "styled-components";
import StyledInput from "../../../shared/styled_input";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../table_v2/fixed_column_dropbox";
import AsonicExcel from "../../../asonic-table/asonic-excel";
import IconContainer from "../../../shared/icon_container";
import Button from "../../../globalComponents/Button";
import moment from "moment";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  isAdmin?: boolean;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
  setIsDepartmentDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;
function StatisticsStatusHeader<P extends object>({
  columns,
  table,
  title,
  setIsSearch,
  headerTitleList,
  take,
  handleTake,
  count,
  downloadExcel,
  startDate,
  setStartDate,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN,
  isAdmin,
  setIsDepartmentDialogOpen,
  setIsActiveFilter
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title={title} subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
          setIsActiveFilter={setIsActiveFilter}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          <span>{"검색"}</span>
          <StyledInput
            type={"month"}
            value={moment(startDate).format("YYYY-MM")}
            onChange={event => {
              if (event.currentTarget.value) {
                setStartDate(`${event.currentTarget.value}-01`);
              }
            }}
          />
          {isAdmin && (
            <Button
              onClick={() => {
                setIsDepartmentDialogOpen(true);
              }}
            >
              부서 지정
            </Button>
          )}
          <Button
            onClick={() => {
              setIsSearch(true);
            }}
          >{`결과보기`}</Button>
          <AsonicExcel downloadExcel={downloadExcel} />
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
    </Container>
  );
}

export default StatisticsStatusHeader;
