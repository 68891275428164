import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import settingsApi from "../../../../api/settings-api";
import { OnChange } from "../../../../hooks/etc-settings-hook/use-get-data";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import { setUserInfo } from "../../../../redux/modules/sign-in/signInReducer";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import MenuCheckBoxList from "../MenuCheckBoxList";
import CheckBoxListContainer from "./checkbox-list-container";

// 1 : 정보관리 버튼 Show/Hide (사진옆 톱니바퀴)
// 2 : 출근/퇴근 버튼, 시간 Show/Hide
// 4 : 사용자 정보 관리 메뉴 Show/Hide

enum WIDGET_BUTTON_TITLE {
  Setting = "정보 관리 기능",
  CommuteButton = "출/퇴근 기능",
  UserSetting = "사용자 정보 관리 기능"
}

export enum WIDGET_BUTTON_LIST {
  Setting = 1,
  CommuteButton = 2,
  UserSetting = 4
}

const AsonicWidget = "A-Sonic 위젯";
const WebStandardWidgetFlag = "web_standard_widget_flag";
function AsonicWidgetSetting() {
  const {
    signInReducer: { menuList, mobileMenuList }
  } = useSelector((state: Reducers) => state);
  const [webStandardWidgetFlag, setWebStandardWidgetFlag] = useState<number>(
    menuList[0]?.web_standard_widget_flag || 0
  );

  const AsonicWidgetMenuList = useMemo(() => {
    return [
      {
        mainTitle: AsonicWidget,
        data: [
          {
            title: WIDGET_BUTTON_TITLE.Setting,
            key: WebStandardWidgetFlag,
            status: webStandardWidgetFlag,
            isCheck: Boolean(webStandardWidgetFlag & WIDGET_BUTTON_LIST.Setting)
          },
          {
            title: WIDGET_BUTTON_TITLE.CommuteButton,
            key: WebStandardWidgetFlag,
            status: webStandardWidgetFlag,
            isCheck: Boolean(
              webStandardWidgetFlag & WIDGET_BUTTON_LIST.CommuteButton
            )
          },
          {
            title: WIDGET_BUTTON_TITLE.UserSetting,
            key: WebStandardWidgetFlag,
            status: webStandardWidgetFlag,
            isCheck: Boolean(
              webStandardWidgetFlag & WIDGET_BUTTON_LIST.UserSetting
            )
          }
        ]
      }
    ];
  }, [webStandardWidgetFlag]);

  const handleOnChange = useCallback(
    () => (event: OnChange) => {
      const { title, value, checked } = event.target;
      const calculate = (status: number) => {
        let newValue = parseInt(value);
        if (checked) {
          newValue = newValue + status;
        } else {
          newValue = newValue - status;
        }

        return newValue;
      };
      switch (title) {
        case WIDGET_BUTTON_TITLE.Setting:
          setWebStandardWidgetFlag(calculate(WIDGET_BUTTON_LIST.Setting));
          break;
        case WIDGET_BUTTON_TITLE.CommuteButton:
          setWebStandardWidgetFlag(calculate(WIDGET_BUTTON_LIST.CommuteButton));
          break;
        case WIDGET_BUTTON_TITLE.UserSetting:
          setWebStandardWidgetFlag(calculate(WIDGET_BUTTON_LIST.UserSetting));
          break;
        default:
          break;
      }
    },
    []
  );

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const dispatch = useDispatch();
  const setUser = useCallback(
    userInfo => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );

  const updateMenuList = useCallback(() => {
    settingsApi
      .updateWidgetMenuList({ webStandardWidgetFlag })
      .then(({ data }) => {
        if (data.ok) {
          setUser({
            menuList: [
              {
                ...menuList[0],
                ...mobileMenuList[0],
                web_standard_widget_flag: webStandardWidgetFlag
              }
            ]
          });
          handleToast(
            "위젯 메뉴 리스트를 업데이트 했습니다.",
            MessageTypes.SUCCESS
          );
        } else {
          handleToast(
            "위젯 메뉴 리스트를 업데이트 하지 못했습니다.",
            MessageTypes.ERROR
          );
        }
      })
      .catch(error => {
        handleToast(
          "위젯 메뉴 리스트를 업데이트 했습니다.",
          MessageTypes.ERROR
        );
      });
  }, [webStandardWidgetFlag, handleToast, menuList, setUser, mobileMenuList]);

  return (
    <CheckBoxListContainer>
      <MenuCheckBoxList
        title={AsonicWidget}
        list={AsonicWidgetMenuList}
        handleOnChange={handleOnChange()}
        updateMenuList={updateMenuList}
      />
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </CheckBoxListContainer>
  );
}

export default AsonicWidgetSetting;
