import api from "../api";

const COMMUTE = `${api.api}/commute`;
const COMMUTE_USER = `${COMMUTE}/user`;

const commuteApi = {
  commute: COMMUTE,
  commuteUser: COMMUTE_USER
};

export default commuteApi;
