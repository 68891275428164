// TYPE
export const GET_CATEGORIES = "CATEGORY_SELECTION_REDUCER/GET_CATEGORIES";
export const SET_CATEGORIES = "CATEGORY_SELECTION_REDUCER/SET_CATEGORIES";

let initialState = {
  categories: []
};

const getCategories = () => ({
  type: GET_CATEGORIES
});

const setCategories = categories => ({
  type: SET_CATEGORIES,
  categories
});

export const categorySelectionActionCreator = {
  getCategories,
  setCategories
};

const approvalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    default:
      return state;
  }
};

export default approvalReducer;
