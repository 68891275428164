import { useCallback, useState } from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import RemoveIcon from "@iconify/icons-fa-solid/minus-circle";
import uiString from "./string.json";
import Button from "../../globalComponents/Button";
import { searchEmployees } from "../../../Utils/commonAxiosCall";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import SearchIcon from "@iconify/icons-mdi/magnify";
import AsonicIconButton from "../../shared/asonic-icon-button/asonic-icon-button";
import ProfileCard from "../../profile-card/profile-card";
import { colors } from "../../GlobalStyle/GlobalStyle";
import TextInput from "../../inputs/text-input";

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const RootDiv = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
  svg {
    font-size: 24px;
  }
`;

const ErrorMsgDiv = styled.div`
  font-weight: bold;
  color: #ff0000;
`;

const DialogContent = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  gap: 10px;
`;

const ListSection = styled.section`
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 100px;
  width: 300px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const RenderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmployeeSelectorDlg = props => {
  const [employeeName, updateEmployeeName] = useState("");
  const [searchResult, updateSearchResult] = useState([]);
  const [chosenEmployees, updateChosenEmployees] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const _handleKeyPress = event => {
    if (event.key === "Enter") {
      getEmployeesByName();
    }
  };

  const getEmployeesByName = () => {
    if (employeeName.length < 2) {
      setErrorMsg(uiString.notifications.nameLessThanTwoLetters);
      return;
    } else {
      setErrorMsg("");
    }

    searchEmployees(employeeName).then(res => {
      if (res.data) {
        updateSearchResult(res.data);
      }
    });
  };

  const addChip = useCallback(
    user => () => {
      let alreadyHave = -1;
      for (let i = 0; i < chosenEmployees.length; i++) {
        if (chosenEmployees[i].employee_id === user.employee_id) {
          alreadyHave = i;
          break;
        }
      }

      if (alreadyHave === -1) {
        user["key"] = user.employee_id;
        let newChosenList = [...chosenEmployees, user];
        updateChosenEmployees(newChosenList);
      }
    },
    [chosenEmployees]
  );

  const handleDeleteChosenUser = useCallback(
    user => () => {
      const chosenList = [...chosenEmployees];
      for (let i = 0; i < chosenList.length; i++) {
        if (chosenList[i].employee_id === user.employee_id) {
          chosenList.splice(i, 1);
          break;
        }
      }
      updateChosenEmployees(chosenList);
    },
    [chosenEmployees]
  );

  const handleUpdateEmployeeName = event => {
    updateEmployeeName(event.target.value);
  };

  const Row = useCallback(
    ({ index, style }) => {
      const user = searchResult[index];
      let base64String = "";
      if (user?.my_picture?.data && user.my_picture.data.length > 1) {
        const buf = Buffer.from(user.my_picture.data);
        base64String = buf.toString("base64");
      }

      const userInfo = {
        employeeId: user.employee_id,
        name: user.employee_name,
        department: user.department_name,
        picture: base64String
      };

      return (
        <RenderContainer style={style}>
          <ProfileCard userInfo={userInfo}>
            <div onClick={addChip(searchResult[index])}>
              <AsonicIconButton icon={AddIcon} />
            </div>
          </ProfileCard>
        </RenderContainer>
      );
    },
    [searchResult, addChip]
  );

  const ChoosenRow = useCallback(
    ({ index, style }) => {
      const user = chosenEmployees[index];
      let base64String = "";
      if (user?.my_picture?.data && user.my_picture.data.length > 1) {
        const buf = Buffer.from(user.my_picture.data);
        base64String = buf.toString("base64");
      }

      const userInfo = {
        employeeId: user.employee_id,
        name: user.employee_name,
        department: user.department_name,
        picture: base64String
      };

      return (
        <RenderContainer style={style}>
          <ProfileCard userInfo={userInfo}>
            <div onClick={handleDeleteChosenUser(chosenEmployees[index])}>
              <AsonicIconButton icon={RemoveIcon} color={colors.tomato} />
            </div>
          </ProfileCard>
        </RenderContainer>
      );
    },
    [chosenEmployees, handleDeleteChosenUser]
  );

  return (
    <AsonicDialog
      title={props.title}
      handleClose={() => props.handleClose()}
      height="460px"
      minHeight="460px"
      width="300px"
    >
      <DialogContent>
        <span>{props.description}</span>
        <RootDiv>
          <TextInput
            margin="normal"
            onChange={handleUpdateEmployeeName}
            onKeyPress={_handleKeyPress}
          />
          <AsonicIconButton icon={SearchIcon} onClick={getEmployeesByName} />
        </RootDiv>
        <ErrorMsgDiv>{errorMsg}</ErrorMsgDiv>
        <ListSection>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={searchResult.length}
                itemData={searchResult}
                itemSize={55}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <Divider />
        <h3>{"지정된 사용자 조건"}</h3>
        <ListSection>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={chosenEmployees.length}
                itemData={chosenEmployees}
                itemSize={55}
                width={width}
              >
                {ChoosenRow}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <ButtonContainer>
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            {uiString.buttons.cancel}
          </Button>
          <Button
            onClick={() => {
              props.handleStatisticsState({
                employees: chosenEmployees.map(user => user.employee_id)
              });

              props.handleClose();
            }}
            autoFocus
          >
            {uiString.buttons.confirm}
          </Button>
        </ButtonContainer>
      </DialogContent>
    </AsonicDialog>
  );
};

export default EmployeeSelectorDlg;
