import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  margin: 5px 0px 5px 0px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const TextInput = styled.input`
  border: 2px solid rgba(0, 0, 0, 0.2);
  width: 90%;
`;
