import SectionContainer from "../../../shared/section_container/section_container";
import TitleContainer from "../../../shared/title_container/title_container";
import SubTitle from "../../../shared/sub-title/sub-title";
import EachWeekWS from "./each_week_w_s/each_week_w_s";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import TotalWorkTime from "./each_week_w_s/total_work_time";
import ButtonContainer from "../../../shared/button_container/button_container";
import Button from "../../../globalComponents/Button";
import FlexibleWSHelp from "./flexible_w_s_help";
import {
  Day_Type,
  OtWorkingFlexibleHoursEntity,
  Work_Hours_Type,
  useGetFlexibleWorkingHourInfoLazyQuery
} from "../../../../generated/graphql";
import handleApolloLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import { Day_Of_Week_Type } from "../../../../generated/graphql";
import moment from "moment";
import { FlexibleWSStore } from "../work_time_tab";
import FlexibleWSBasic from "./flexible_w_s_basic/flexible_w_s_basic";
import { WS_STATE } from "../../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import { MessageTypes } from "../../../toast-message/toast-message";

function FlexibleWS() {
  const { handleSettleMentSectionHour, weekWH } = useContext(FlexibleWSStore);

  const wSState = useContext(WS_STATE);
  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const [dayOfWeekList, setDayOfWeekList] = useState<Day_Type[]>([]);
  const [extraList, setExtraList] = useState<OtWorkingFlexibleHoursEntity[]>(
    []
  );
  const [startTime, setStartTime] = useState<string>("09:00");
  const [endTime, setEndTime] = useState<string>("18:00");
  const [settlementSectionHour, setSettlementSectionHour] = useState<number>(1);
  const [workHoursType, setWorkHoursType] = useState<Work_Hours_Type>(
    Work_Hours_Type.EachWeekWorkTime
  );
  const [dayOfWeekType, setDayOfWeekType] = useState<Day_Of_Week_Type>(
    Day_Of_Week_Type.MonToFri
  );
  const [startDate, setStartDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [startBreakTime, setStartBreakTime] = useState("12:00");
  const [endBreakTime, setEndBreakTime] = useState("13:00");

  const handleDayOfWeekList = useCallback((day: Day_Type) => {
    setDayOfWeekList(list => {
      const newList: Day_Type[] = [...list];
      if (newList.includes(day)) {
        return newList.filter(item => item !== day);
      } else {
        newList.push(day);
      }
      return newList;
    });
  }, []);

  const [getFlexibleWorkingHourInfo] = useGetFlexibleWorkingHourInfoLazyQuery({
    onError(error) {
      handleApolloLog(error);
    },
    onCompleted(data) {
      if (data.getFlexibleWorkingHourInfo.basicInfo) {
        const {
          startTime,
          endTime,
          settlementSectionHour,
          workHoursType,
          dayOfWeekType,
          startDate,
          startBreakTime,
          endBreakTime
        } = data.getFlexibleWorkingHourInfo.basicInfo;
        setStartTime(startTime);
        setEndTime(endTime);
        handleSettleMentSectionHour(settlementSectionHour);
        /** 근무 편성 기간 설정(주)[1, 2, 3, 4] */
        setSettlementSectionHour(settlementSectionHour);

        /** 근무시간 설정방식 타입 */
        setWorkHoursType(workHoursType);

        /** 근무요일 설정 타입 */
        setDayOfWeekType(dayOfWeekType);

        setStartDate(startDate);

        setStartBreakTime(startBreakTime);
        setEndBreakTime(endBreakTime);
      }
      if (data.getFlexibleWorkingHourInfo.extraList) {
        setExtraList(
          data.getFlexibleWorkingHourInfo.extraList.map(item => {
            const newItem = {
              ...item,
              startTime: item.startTime.slice(0, 6),
              endTime: item.endTime.slice(0, 6),
              startBreakTime: item.startBreakTime.slice(0, 6),
              endBreakTime: item.endBreakTime.slice(0, 6)
            };
            return newItem;
          })
        );
      }
    }
  });

  useEffect(() => {
    const parsedSettlementSectionHour =
      typeof settlementSectionHour === "string"
        ? parseInt(settlementSectionHour)
        : settlementSectionHour;
    handleSettleMentSectionHour(parsedSettlementSectionHour);
  }, [settlementSectionHour, handleSettleMentSectionHour]);

  useEffect(() => {
    switch (dayOfWeekType) {
      case Day_Of_Week_Type.All:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat,
          Day_Type.Sun
        ]);
        break;
      case Day_Of_Week_Type.MonToSat:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat
        ]);
        break;
      case Day_Of_Week_Type.MonToFri:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri
        ]);
        break;
    }
  }, [dayOfWeekType]);

  useEffect(() => {
    if (wSState?.setOtWorkingFlexibleBasic && wSState?.workingTemplateIdx) {
      wSState?.setOtWorkingFlexibleBasic({
        dayOfWeek: dayOfWeekList,
        dayOfWeekType,
        endBreakTime,
        endTime,
        settlementSectionHour,
        startBreakTime,
        startDate,
        startTime,
        workHoursType,
        workingTemplateIdx: wSState?.workingTemplateIdx
      });
    }
  }, [
    wSState?.setOtWorkingFlexibleBasic,
    dayOfWeekList,
    dayOfWeekType,
    endBreakTime,
    endTime,
    settlementSectionHour,
    startBreakTime,
    startDate,
    startTime,
    workHoursType,
    wSState?.workingTemplateIdx
  ]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getFlexibleWorkingHourInfo({
        variables: {
          workingTemplateIdx: wSState?.workingTemplateIdx
        }
      });
    }
  }, [getFlexibleWorkingHourInfo, wSState?.workingTemplateIdx]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"기본 설정"} />
        <FlexibleWSHelp />
      </TitleContainer>
      <FlexibleWSBasic
        startTime={startTime}
        setStartTime={setStartTime}
        dayOfWeekList={dayOfWeekList}
        dayOfWeekType={dayOfWeekType}
        setDayOfWeekType={setDayOfWeekType}
        handleDayOfWeekList={handleDayOfWeekList}
        startDate={startDate}
        setStartDate={setStartDate}
        endTime={endTime}
        setEndTime={setEndTime}
        settlementSectionHour={settlementSectionHour}
        setSettlementSectionHour={setSettlementSectionHour}
        workHoursType={workHoursType}
        setWorkHoursType={setWorkHoursType}
        startBreakTime={startBreakTime}
        setStartBreakTime={setStartBreakTime}
        endBreakTime={endBreakTime}
        setEndBreakTime={setEndBreakTime}
      />
      <EachWeekWS
        extraList={extraList}
        dayOfWeekList={dayOfWeekList}
        workHoursType={workHoursType}
        startTime={startTime}
        endTime={endTime}
        startBreakTime={startBreakTime}
        endBreakTime={endBreakTime}
        settlementSectionHour={settlementSectionHour}
      />
      <TotalWorkTime />
      <ButtonContainer
        onClick={() => {
          if (wSState?.weekTotalWorkHour && weekWH.totalWorkHour) {
            if (
              weekWH.totalWorkHour <=
              wSState?.weekTotalWorkHour * dayOfWeekList.length
            ) {
              wSState?.handleIsSave(true);
            } else {
              handleConfirmMessage({
                title: "확인",
                p: `전체 근무시간은 정산 구간의 총 근무시간인  
[ ${weekWH.totalWorkHour} ] 시간을 초과하여 설정하실수 없습니다.`,
                messageTypes: MessageTypes.ERROR
              });
              handleIsOpen(true);
            }
          }
        }}
      >
        <Button customMinWidth="100%">{`저장`}</Button>
      </ButtonContainer>
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.ERROR}
          handleIsOpen={handleIsOpen}
          confirmButtonColor="#DA605B"
          isCancel
          handleConfirm={() => {
            handleIsOpen(false);
          }}
        />
      )}
    </SectionContainer>
  );
}

export default memo(FlexibleWS);
