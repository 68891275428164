import { colors } from "../GlobalStyle/GlobalStyle";
import { FixedSizeList as List } from "react-window";
import FolderIcon from "@iconify-icons/akar-icons/folder";
import {
  GetListOfDepartmentForChat,
  GetListOfDepartmentForChatVariables
} from "../../__generated__/GetListOfDepartmentForChat";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { Icon } from "@iconify/react";
import { useCallback, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";
import { ChatContext, ChatHandleContext, IDepartmentInfoForChat } from "./chat";
import { QUERY_GET_USER } from "../../apollo/common-gql";
import { GetUser, GetUserVariables } from "../../__generated__/GetUser";
import basicImg from "../../images/basicimage.jpg";
import GroupSlide from "./group-slide";
import listOfApolloVar from "../../apollo/apollo-var";

interface IProps {
  handleParentDepartment: (payload: IDepartmentInfoForChat) => void;
}

interface PhotoProps {
  photo?: string | null;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex: 9;
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};
  align-items: ${props => (props.isLoading ? "center" : "flex-start")};
`;

const ListContainer = styled.div<{ isUserThatExist?: boolean }>`
  cursor: ${props => {
    if (props.isUserThatExist) {
      return "not-allowed;";
    }
    return "pointer";
  }};
  background-color: ${props => {
    if (props.isUserThatExist) {
      return props.theme.colors.darkGrey;
    }
  }};
  :hover {
    background-color: ${props => {
      if (props.isUserThatExist) {
        return props.theme.colors.darkGrey;
      }
      return props.theme.colors.skyBlue;
    }};
    color: ${props => props.theme.colors.white};
  }
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    font-size: 40px;
    font-weight: bold;
  }
  span {
    :last-child {
      font-size: 18px;
      font-weight: bold;
      margin-left: auto;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 20px;
  padding: 10px;
`;

const ProfilePicture = styled.div<PhotoProps>`
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url(data:image/png+jpg;base64,${props.photo})`
      : `url(${basicImg})`};
`;

const PictureContainer = styled.div`
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
`;

const DEFAULT_PARENT_DEPARTMENT_ID = 0;

const QUERY_GET_LIST_DEPARTMENT_FOR_CHAT = gql`
  query GetListOfDepartmentForChat($parentDepartmentId: Int!) {
    getListOfDepartmentForChat(parentDepartmentId: $parentDepartmentId) {
      ok
      error
      list {
        departmentId
        parentDepartmentId
        name
        fullName
      }
    }
  }
`;

function ListForChat({ handleParentDepartment }: IProps) {
  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);
  const userToken = useReactiveVar(listOfApolloVar.userTokenVar);

  const [getUser, { data: userData, loading: userLoading }] = useLazyQuery<
    GetUser,
    GetUserVariables
  >(QUERY_GET_USER);

  const [getListDepartmentForChat, { data, loading }] = useLazyQuery<
    GetListOfDepartmentForChat,
    GetListOfDepartmentForChatVariables
  >(QUERY_GET_LIST_DEPARTMENT_FOR_CHAT, {
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (chatContext?.parentDepartmentId) {
        getUser({
          variables: {
            departmentId: chatContext?.parentDepartmentId
          }
        });
      }
    }
  });

  const calculateUserIndex = useCallback(
    (total: number, departmentLen: number): number => {
      return departmentLen !== 0 ? total - departmentLen : total;
    },
    []
  );

  const checkUserExistentInRoom = useCallback(
    (userId: string): boolean => {
      let userThatExist: boolean = false;
      if (chatContext?.listOfUserInRoom) {
        chatContext.listOfUserInRoom.forEach(user => {
          if (!userThatExist) {
            userThatExist = user.employee_id === userId;
          }
        });
      }
      return userThatExist;
    },
    [chatContext?.listOfUserInRoom]
  );

  const Row = useCallback(
    ({ index, style }: { index: number; style: object }) => {
      let name = "";
      let departmentId = DEFAULT_PARENT_DEPARTMENT_ID;
      if (data?.getListOfDepartmentForChat.list?.[index]) {
        name =
          data?.getListOfDepartmentForChat.list[index]?.name ||
          data?.getListOfDepartmentForChat.list[index]?.fullName ||
          "";
        departmentId =
          data?.getListOfDepartmentForChat.list[index].departmentId;
      }

      const departmentLength =
        data?.getListOfDepartmentForChat?.list?.length ?? 0;

      const userIndex = calculateUserIndex(index, departmentLength);

      if (
        userData?.getUser.ok &&
        !data?.getListOfDepartmentForChat?.list?.[index] &&
        userData?.getUser?.user?.[userIndex] &&
        chatContext?.parentDepartmentId !== DEFAULT_PARENT_DEPARTMENT_ID
      ) {
        const { name, myPicture, employeeId } =
          userData?.getUser?.user?.[userIndex];
        const isUserThatExist: boolean = checkUserExistentInRoom(employeeId);

        return (
          <ListContainer
            style={style}
            isUserThatExist={isUserThatExist}
            onClick={() => {
              if (userData?.getUser?.user?.[userIndex] && !isUserThatExist) {
                chatHandleContext?.handleSelectedUser(
                  userData.getUser.user[userIndex]
                );
              }
            }}
          >
            <Item>
              <PictureContainer>
                <ProfilePicture photo={myPicture} />
              </PictureContainer>
              <h4>{name}</h4>
            </Item>
          </ListContainer>
        );
      }
      return (
        <ListContainer
          style={style}
          onClick={() => {
            handleParentDepartment({
              parentDepartmentId: departmentId,
              selectedDepartmentName: name
            });
          }}
        >
          <Item>
            <Icon icon={FolderIcon} />
            <span>{name}</span>
            <span>{`>`}</span>
          </Item>
        </ListContainer>
      );
    },
    [
      chatHandleContext,
      data,
      handleParentDepartment,
      userData,
      chatContext?.parentDepartmentId,
      calculateUserIndex,
      checkUserExistentInRoom
    ]
  );

  const itemCount = useMemo(() => {
    const departmentLength = data?.getListOfDepartmentForChat.list?.length || 0;
    const userLength = userData?.getUser.user?.length || 0;
    let newItemCount: number = departmentLength;
    if (chatContext?.parentDepartmentId !== DEFAULT_PARENT_DEPARTMENT_ID) {
      newItemCount = departmentLength + userLength;
    }
    return newItemCount;
  }, [data, userData, chatContext?.parentDepartmentId]);

  useEffect(() => {
    if (
      chatContext?.parentDepartmentId !== undefined &&
      chatContext?.parentDepartmentId !== null
    ) {
      if (chatContext?.isElectron) {
        if (userToken) {
          getListDepartmentForChat({
            variables: {
              parentDepartmentId: chatContext.parentDepartmentId
            }
          });
        }
        return;
      }
      getListDepartmentForChat({
        variables: {
          parentDepartmentId: chatContext.parentDepartmentId
        }
      });
    }
  }, [
    getListDepartmentForChat,
    chatContext?.parentDepartmentId,
    chatContext?.isElectron,
    userToken
  ]);

  return (
    <Container>
      <GroupSlide isGroup={true} />
      <Content isLoading={loading || userLoading}>
        {loading || userLoading ? (
          <ReactLoading
            type="spokes"
            color={colors.darkBlue}
            height={200}
            width={170}
          />
        ) : (
          <List height={409} itemCount={itemCount} itemSize={50} width={400}>
            {Row}
          </List>
        )}
      </Content>
    </Container>
  );
}

export default ListForChat;
