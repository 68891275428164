import { call, put } from "redux-saga/effects";
import { getOvertimeCategories } from "../../../redux/sagas/restapi-calls/categoriesCalls";
import { SET_CATEGORIES } from "../../../redux/modules/main-view/categorySelectionReducer";

// const getCategorySelectionReducer = state => state.categorySelectionReducer;

export function* getCategoriesAsync(action: any) {
  const categories: any[] = yield call(getOvertimeCategories);
  yield put({
    type: SET_CATEGORIES,
    categories
  });
}
