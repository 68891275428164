import * as React from "react";
import personalApi from "../../../api/dashboard/personal-api";

type Props = {
  userId: string;
};
const useTodayWorkingInformationBox = <P extends Props>({ userId }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [workingTime, setWorkingTime] = React.useState<number>(0);
  const [maxWorkingTime, setMaxWorkingTime] = React.useState<number>(0);
  const [
    regularRemainingWorkingTime,
    setRegularRemainingWorkingTime
  ] = React.useState<number>(0);
  const [overWorkingTime, setOverWorkingTime] = React.useState<number>(0);
  const [
    overRemainingWorkingTime,
    setOverRemainingWorkingTime
  ] = React.useState<number>(0);

  const handleGetData = React.useCallback(async () => {
    const params = {
      userId
    };
    try {
      const { data } = await personalApi.getTodayWorkingInformationBox(params);
      const result = data[0];

      if (isMount.current && result) {
        setWorkingTime(result?.astotmin);
        setMaxWorkingTime(result?.aswork_maxmin);
        setOverWorkingTime(result?.asext_maxmin);
        setRegularRemainingWorkingTime(result?.aswork_leavemin);
        setOverRemainingWorkingTime(result?.asext_leavemin);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
      handleGetData();
    }
    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [handleGetData]);

  React.useEffect(() => {
    let timeId: NodeJS.Timeout;
    if (isMount.current) {
      timeId = setInterval(handleGetData, 300000);
    }
    return () => {
      clearInterval(timeId);
    };
  }, [handleGetData]);

  return {
    workingTime,
    maxWorkingTime,
    regularRemainingWorkingTime,
    overWorkingTime,
    overRemainingWorkingTime
  };
};

export default useTodayWorkingInformationBox;
