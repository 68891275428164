import styled from "styled-components";

const StyleInput = styled.input<{ minWidth?: string; maxWidth?: string }>`
  display: flex;
  width: max-content;
  min-width: ${props => props.minWidth ?? "163px"};
  max-width: ${props => props.maxWidth ?? "163px"};
`;

export default StyleInput;
