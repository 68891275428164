import styled from "styled-components";
import AsonicExcel, { IAsonicExcelProps } from "../asonic-excel";
import { IAsonicPageControllerProps } from "../asonic-page-controller";
import { IAsonicSearchWithDate } from "../asonic-search-with-date";
import AsonicHideColumn, {
  IAsonicHideColumnProps
} from "../asonic-hide-column";
import AsonicSearchByDateWithType, {
  ISearchByDateWithTypeProps
} from "./asonic-search-by-date-with-type";
import SubTitle from "../../shared/sub-title/sub-title";
export interface IAsonicTableTitleBarProps<P extends object>
  extends Partial<IAsonicPageControllerProps>,
    IAsonicExcelProps,
    IAsonicSearchWithDate,
    Partial<ISearchByDateWithTypeProps>,
    Partial<IAsonicHideColumnProps<P>> {
  title: string;
  total?: number;
  isPeople?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  span {
    width: max-content;
  }
  gap: 2%;
  padding: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const RightMenuContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
`;

function AsonicTableTitleBarForDashboard<P extends object>({
  title,
  total,
  isPeople = false,
  downloadExcel,
  columns,
  toggleHideColumn,
  handleDateWithType,
  searchType,
  handleSearchType,
  searchValue,
  handleSearchValue,
  searchValueForTime,
  handleSearchValueForTime,
  searchValueForTruthyFalsy,
  handleSearchValueForTruthyFalsy,
  isAdditionalSearchTimeValue
}: IAsonicTableTitleBarProps<P>) {
  return (
    <Container>
      <TitleContainer>
        <SubTitle title={title} />
        {total !== undefined && (
          <span>{`(전체 ${total}${isPeople ? "명" : "개"})`}</span>
        )}
      </TitleContainer>
      {columns && toggleHideColumn && (
        <AsonicHideColumn
          columns={columns}
          toggleHideColumn={toggleHideColumn}
          title={title}
        />
      )}
      {handleDateWithType &&
        searchType &&
        handleSearchType &&
        handleSearchValue &&
        searchValueForTime &&
        handleSearchValueForTime &&
        searchValueForTruthyFalsy &&
        handleSearchValueForTruthyFalsy && (
          <AsonicSearchByDateWithType
            handleDateWithType={handleDateWithType}
            searchType={searchType}
            handleSearchType={handleSearchType}
            searchValue={searchValue as string}
            handleSearchValue={handleSearchValue}
            searchValueForTime={searchValueForTime}
            handleSearchValueForTime={handleSearchValueForTime}
            searchValueForTruthyFalsy={searchValueForTruthyFalsy}
            handleSearchValueForTruthyFalsy={handleSearchValueForTruthyFalsy}
            isAdditionalSearchTimeValue={!!isAdditionalSearchTimeValue}
          />
        )}
      <RightMenuContainer>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </RightMenuContainer>
    </Container>
  );
}

export default AsonicTableTitleBarForDashboard;
