import styled from "styled-components";
import {
  ApprovalEntity,
  ApprovalSearchType,
  ApprovalStatus,
  Approval_For_List_Type,
  useGetListOfApprovalLazyQuery
} from "../../generated/graphql";
import SummaryTable from "../summary-table/summary-table";
import * as ReactTable from "react-table";
import routes from "../../routes";
import { Icon } from "@iconify/react";
import ExternalLinkIcon from "@iconify-icons/eva/external-link-outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import listOfApolloVar from "../../apollo/apollo-var";
import { ApprovalType, IApprovalDetailMessage } from "./approval-detail-popup";
import EChannel from "../../Utils/EChannel/e-channel";
import { Cell } from "../../../types/@react-table/react-table/react-table";
import { useReactiveVar } from "@apollo/client";
import ApprovalWaitingList from "./approval-list/approval-waiting-list";
import { APPROVAL_DASHBOARD_TYPE } from "./approval-dash-board";

interface IProps {
  handleShowApprovalPopup: (value: boolean) => void;
  handleDashboardType: (value: APPROVAL_DASHBOARD_TYPE) => void;
  dashboardType: APPROVAL_DASHBOARD_TYPE;
  take: number;
  handleSelectedRowIndex: (index: number) => void;
  handleSetList: (payload: ApprovalEntity[]) => void;
}

export enum COLUMN_FOR_VALUE {
  docNumber = "문서번호",
  approvalTitle = "타이틀",
  formIdx = "양식 종류",
  approvalStatus = "상태",
  name = "이름"
}

export enum APPROVAL_STATUS {
  RETURN = "반려",
  APPROVAL = "승인",
  WAITING = "결재진행중",
  IN_THE_MIDDLE_OF_RETURN = "중간 반려",
  OUTSTANDING = "미처리"
}

export type TYPE_OF_AWAITING_APPROVAL = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div<{ dashboardType: APPROVAL_DASHBOARD_TYPE }>`
  display: ${props =>
    props.dashboardType === APPROVAL_DASHBOARD_TYPE.ALL ||
    props.dashboardType === APPROVAL_DASHBOARD_TYPE.AWAITING
      ? "flex"
      : "none"};
  flex: 1;
`;

export const ItemForTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  span {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.98);
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.98);
    }
  }
  padding-right: 10px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  svg {
    font-size: 20px;
  }
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.95);
  }
`;

export const Point = styled.span`
  font-size: 1px;
  color: ${props => props.theme.colors.green};
  text-indent: 22px;
  text-align: center;
`;

function ApprovalAwaitingSummaryTable({
  handleShowApprovalPopup,
  dashboardType,
  handleDashboardType,
  take,
  handleSelectedRowIndex,
  handleSetList
}: IProps) {
  const [isAllList, setIsAllList] = useState<boolean>(false);
  const FORM_NAME = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [selectedRow, setSelectedRow] = useState<ApprovalEntity | undefined>(
    undefined
  );

  const handleIsAllList = useCallback((isAll: boolean) => {
    setIsAllList(isAll);
    handleDashboardType(APPROVAL_DASHBOARD_TYPE.AWAITING);
  }, []);

  const bc = useMemo(() => {
    if (selectedRow) {
      return new BroadcastChannel(EChannel.APPROVAL);
    }
    return;
  }, [selectedRow]);

  const [getListOfAwaitingApproval, { data, loading }] =
    useGetListOfApprovalLazyQuery();

  const list: ApprovalEntity[] = useMemo(() => {
    return data?.getListOfApproval.list || [];
  }, [data]);

  const columns: ReactTable.Column<ApprovalEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    return listOfColumn.map(item => {
      let width = 120;
      if (
        COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
        COLUMN_FOR_VALUE.approvalTitle
      ) {
        width = 350;
      }
      if (
        COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
        COLUMN_FOR_VALUE.docNumber
      ) {
        width = 200;
      }
      return {
        Header: COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL],
        accessor: item as TYPE_OF_AWAITING_APPROVAL,
        width,
        Cell(cell: Cell<ApprovalEntity>) {
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
            COLUMN_FOR_VALUE.docNumber
          ) {
            return (
              <Item>
                <Point>●</Point>
                {cell.value}
              </Item>
            );
          }
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
            COLUMN_FOR_VALUE.formIdx
          ) {
            const formName = FORM_NAME.get(cell.value);
            return <Item>{`[${formName}]`}</Item>;
          }
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
            COLUMN_FOR_VALUE.approvalStatus
          ) {
            let value = APPROVAL_STATUS.APPROVAL;
            switch (cell.value) {
              case ApprovalStatus.Return:
                value = APPROVAL_STATUS.RETURN;
                break;
              case ApprovalStatus.Waiting:
                value = APPROVAL_STATUS.WAITING;
                break;
              case ApprovalStatus.Outstanding:
                value = APPROVAL_STATUS.OUTSTANDING;
                break;
              case ApprovalStatus.InTheMiddleOfReturn:
                value = APPROVAL_STATUS.IN_THE_MIDDLE_OF_RETURN;
                break;
            }
            return <Item>{`[${value}]`}</Item>;
          }
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
            COLUMN_FOR_VALUE.approvalTitle
          ) {
            return (
              <ItemForTitle>
                <span
                  onClick={() => {
                    listOfApolloVar.approvalTypeVar(
                      ApprovalType.APPROVAL_WAITING_IN_LIST
                    );
                    handleSelectedRowIndex(cell?.row?.index);
                    listOfApolloVar.approvalDataVar(cell?.row?.original);
                    handleSetList(list);
                    handleShowApprovalPopup(true);
                  }}
                >
                  {cell.value}
                </span>
                <IconContainer
                  onClick={() => {
                    listOfApolloVar.approvalTypeVar(
                      ApprovalType.APPROVAL_WAITING_IN_LIST
                    );
                    listOfApolloVar.approvalDataVar(cell?.row?.original);
                    setSelectedRow(cell?.row?.original);
                    window.open(
                      routes.pageRoutes.approvalPopup,
                      "",
                      "width=800, height=880, scrollbars=yes"
                    );
                  }}
                >
                  <Icon icon={ExternalLinkIcon} />
                </IconContainer>
              </ItemForTitle>
            );
          }
          const name = cell.value;
          const departmentName = cell?.row?.original.departName || "";
          const date = cell?.row?.original.signUpDateTime || "";
          const value = `${departmentName} | ${name} | ${date}`;
          return <Item>{`[${value}]`}</Item>;
        }
      };
    });
  }, [handleShowApprovalPopup, handleSelectedRowIndex, handleSetList, list]);

  const {
    prepareRow,
    getTableProps,
    // headerGroups,
    getTableBodyProps,
    rows
  } = ReactTable.useTable<ApprovalEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  useEffect(() => {
    getListOfAwaitingApproval({
      variables: {
        approvalType: Approval_For_List_Type.Waiting,
        searchType: ApprovalSearchType.All,
        searchValue: "",
        take
      }
    });
  }, [getListOfAwaitingApproval, take]);

  useEffect(() => {
    if (bc && selectedRow) {
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = {
          type: ApprovalType.APPROVAL_WAITING_IN_LIST,
          data: selectedRow,
          isRequest: false
        };
        bc.postMessage(message);
        setSelectedRow(undefined);
        bc.close();
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, selectedRow]);

  return (
    <Container dashboardType={dashboardType}>
      {isAllList ? (
        <ApprovalWaitingList />
      ) : (
        <SummaryTable
          title="결재 대기"
          isLoading={loading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          isTitleBar={false}
          handleIsAllList={handleIsAllList}
          flexIndex={1}
        />
      )}
    </Container>
  );
}

export default ApprovalAwaitingSummaryTable;
