import styled from "styled-components";
import { COLOR_TYPE, typePalette } from "../time_graph_box/time_graph_box";

interface IProps {
  labelName: string;
  colorType?: COLOR_TYPE;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 10px;
`;

const LabelBox = styled.div<{ labelColor?: string }>`
  display: flex;
  width: 8px;
  height: 8px;
  background-color: ${props => props.labelColor ?? props.theme.colors.darkBlue};
`;

function TimeGraphLabel({
  labelName,
  colorType = COLOR_TYPE.OFF_DUTY_HOURS
}: IProps) {
  return (
    <Container>
      <LabelBox labelColor={typePalette[colorType]} />
      <span>{labelName}</span>
    </Container>
  );
}

export default TimeGraphLabel;
