import { electroneApi } from "../electron-route";

const SERVICE_STATUS = `${electroneApi}/service-status`;
const COMMUTING = `${SERVICE_STATUS}/commuting`;
const WORKING_HOUR = `${SERVICE_STATUS}/working-hour`;
const TOTAL_WORKING_HOUR = `${SERVICE_STATUS}/total-working-hour`;
const WEEKLY_TOTAL_WORKING_HOUR = `${SERVICE_STATUS}/weekly-total-working-hour`;

const serviceStatusRoute = {
  serviceStatus: SERVICE_STATUS,
  commuting: COMMUTING,
  workingHour: WORKING_HOUR,
  totalWorkingHour: TOTAL_WORKING_HOUR,
  weeklyTotalWorkingHour: WEEKLY_TOTAL_WORKING_HOUR
};

export default serviceStatusRoute;
