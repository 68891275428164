import { gql, useLazyQuery } from "@apollo/client";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  GetPublicTimeTemplate,
  GetPublicTimeTemplate_getPublicTimeTemplate_list
} from "../../../../../__generated__/GetPublicTimeTemplate";
import AsonicTable from "../../../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import TimeTemplateEditor from "./time-template-editor";
import {
  GetPersonalTimeTemplate,
  GetPersonalTimeTemplate_getPersonalTimeTemplate_list
} from "../../../../../__generated__/GetPersonalTimeTemplate";
import TimeTemplateSchedule from "./time-template-schedule";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../types/reducers";
import { Role } from "../../../../../user-types";

const Container = styled.div<{ userRole: boolean }>`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas: ${props =>
    props.userRole
      ? `"public-template editor editor"
    "public-template editor editor"
    "personal-template editor editor"
    "personal-template editor editor"`
      : `"editor editor editor"
    "editor editor editor"
    "editor editor editor"
    "editor editor editor"
    `};
  gap: 10px;
`;

const Section = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.borderColor};
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  :first-child {
    grid-area: public-template;
  }
  :nth-child(2) {
    grid-area: personal-template;
  }
  :last-child {
    grid-area: editor;
  }
`;

const QUERY_GET_PUBLIC_TIME_TEMPLATE = gql`
  query GetPublicTimeTemplate {
    getPublicTimeTemplate {
      ok
      error
      list {
        timeTemplateIdx
        timeName
      }
    }
  }
`;

const QUERY_PERSONAL_TIME_TEMPLATE = gql`
  query GetPersonalTimeTemplate($employeeId: String) {
    getPersonalTimeTemplate(employeeId: $employeeId) {
      ok
      error
      list {
        timeTemplateIdx
        employeeId
        employeeName
        departmentName
      }
    }
  }
`;

function WorkingTimeScheduleSettingDialog() {
  const {
    signInReducer: { loginIdInfo, employee_id }
  } = useSelector((state: Reducers) => state);
  const [selectedTimeTemplateIdx, setSelectedTimeTemplateIdx] = useState<
    number | undefined
  >();

  const [getPublicTimeTemplate, { data: publicTimeTemplateData, loading }] =
    useLazyQuery<GetPublicTimeTemplate>(QUERY_GET_PUBLIC_TIME_TEMPLATE, {
      fetchPolicy: "cache-and-network"
    });
  const [
    getPersonalTimeTemplate,
    {
      data: personalTimeTemplateData,
      loading: personalTimeTemplateLoading,
      client
    }
  ] = useLazyQuery<GetPersonalTimeTemplate>(QUERY_PERSONAL_TIME_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    async onCompleted(data) {
      if (
        data.getPersonalTimeTemplate.list &&
        data.getPersonalTimeTemplate.list.length > 0
      ) {
        if (
          loginIdInfo !== Role.SUPER_ADMIN &&
          data?.getPersonalTimeTemplate.list &&
          data?.getPersonalTimeTemplate.list.length > 0
        ) {
          setSelectedTimeTemplateIdx(
            data.getPersonalTimeTemplate.list[0].timeTemplateIdx
          );
        }
      }
    }
  });

  const originPersonalTimeTemplateColumns: ReactTable.Column<GetPersonalTimeTemplate_getPersonalTimeTemplate_list>[] =
    useMemo(() => {
      return [
        { Header: "성명", accessor: "employeeName", width: 90 },
        { Header: "아이디", accessor: "employeeId", width: 120 },
        { Header: "부서명", accessor: "departmentName", width: 150 }
      ];
    }, []);

  const personalList: GetPersonalTimeTemplate_getPersonalTimeTemplate_list[] =
    useMemo(() => {
      return personalTimeTemplateData?.getPersonalTimeTemplate.list || [];
    }, [personalTimeTemplateData]);

  const {
    prepareRow: personalPrepareRow,
    getTableProps: personalGetTableProps,
    headerGroups: personalHeaderGroups,
    getTableBodyProps: personalGetTableBodyProps,
    rows: personalRows,
    selectedFlatRows: personalSelectedFlatRows,
    columns: personalTimeTemplateColumns
  } = ReactTable.useTable<GetPersonalTimeTemplate_getPersonalTimeTemplate_list>(
    {
      columns: originPersonalTimeTemplateColumns,
      data: personalList
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const columns: ReactTable.Column<GetPublicTimeTemplate_getPublicTimeTemplate_list>[] =
    useMemo(() => {
      return [{ Header: "근무관리", accessor: "timeName" }];
    }, []);

  const list: GetPublicTimeTemplate_getPublicTimeTemplate_list[] =
    useMemo(() => {
      return publicTimeTemplateData?.getPublicTimeTemplate.list || [];
    }, [publicTimeTemplateData]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: publicTemplateColumns
  } = ReactTable.useTable<GetPublicTimeTemplate_getPublicTimeTemplate_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const selectedRow:
    | IAsonicRow<GetPublicTimeTemplate_getPublicTimeTemplate_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const personalSelectedRow:
    | IAsonicRow<GetPersonalTimeTemplate_getPersonalTimeTemplate_list>
    | undefined = useMemo(() => {
    if (personalSelectedFlatRows.length > 0) {
      return personalSelectedFlatRows[personalSelectedFlatRows.length - 1];
    }
    return;
  }, [personalSelectedFlatRows]);

  const handlePersonalSelectRow = useCallback(
    (
      row?: IAsonicRow<GetPersonalTimeTemplate_getPersonalTimeTemplate_list>
    ) => {
      if (row?.original.timeTemplateIdx) {
        setSelectedTimeTemplateIdx(row.original.timeTemplateIdx);
        if (selectedRow && selectedRow.toggleRowSelected) {
          selectedRow.toggleRowSelected(false);
        }
      }
    },
    [selectedRow]
  );

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<GetPublicTimeTemplate_getPublicTimeTemplate_list>) => {
      if (row?.original.timeTemplateIdx) {
        setSelectedTimeTemplateIdx(row.original.timeTemplateIdx);
        if (personalSelectedRow && personalSelectedRow.toggleRowSelected) {
          personalSelectedRow.toggleRowSelected(false);
        }
      }
    },
    [personalSelectedRow]
  );

  useEffect(() => {
    if (loginIdInfo === Role.SUPER_ADMIN) {
      getPersonalTimeTemplate();
    } else {
      getPersonalTimeTemplate({
        variables: {
          employeeId: employee_id
        }
      });
    }
  }, [getPersonalTimeTemplate, loginIdInfo, employee_id]);

  useEffect(() => {
    if (loginIdInfo === Role.SUPER_ADMIN) {
      getPublicTimeTemplate();
    }
    return () => {
      client.clearStore();
    };
  }, [getPublicTimeTemplate, loginIdInfo, client]);

  return (
    <Container userRole={loginIdInfo === Role.SUPER_ADMIN}>
      {loginIdInfo === Role.SUPER_ADMIN && (
        <Fragment>
          <Section>
            <span>근무계획 템플릿(공용)</span>
            <AsonicTable<GetPublicTimeTemplate_getPublicTimeTemplate_list>
              title="근무계획 템플릿(공용)"
              handleSelectRow={handleSelectRow}
              isLoading={loading}
              prepareRow={prepareRow}
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              selectedRow={selectedRow}
              columns={
                publicTemplateColumns as TColumn<GetPublicTimeTemplate_getPublicTimeTemplate_list>[]
              }
              isTitleBar={false}
            />
            <TimeTemplateEditor selectedRow={selectedRow} />
          </Section>
          <Section>
            <span>근무계획 템플릿(개인)</span>
            <AsonicTable<GetPersonalTimeTemplate_getPersonalTimeTemplate_list>
              title="근무계획 템플릿(개인)"
              handleSelectRow={handlePersonalSelectRow}
              isLoading={personalTimeTemplateLoading}
              prepareRow={personalPrepareRow}
              getTableProps={personalGetTableProps}
              headerGroups={personalHeaderGroups}
              getTableBodyProps={personalGetTableBodyProps}
              rows={personalRows}
              selectedRow={personalSelectedRow}
              columns={
                personalTimeTemplateColumns as TColumn<GetPersonalTimeTemplate_getPersonalTimeTemplate_list>[]
              }
              isTitleBar={false}
            />
          </Section>
        </Fragment>
      )}
      <Section>
        <TimeTemplateSchedule
          selectedTimeTemplateIdx={selectedTimeTemplateIdx}
        />
      </Section>
    </Container>
  );
}

export default WorkingTimeScheduleSettingDialog;
