import styled from "styled-components";
import { useMemo } from "react";
import { Work_Type } from "../../../generated/graphql";

export const COLOR_TYPE = {
  OFF_DUTY_HOURS: "OFF_DUTY_HOURS", // 근무외시간
  WORKING_HOURS: "WORKING_HOURS", // 근무시간
  BREAK_TIME: "BREAK_TIME", // 휴게시간
  OVERTIME_WORK: "OVERTIME_WORK", // 시간외근무
  MANDATORY_WORK_HOURS: "MANDATORY_WORK_HOURS", // 의무근무시간
  WORKER_AUTONOMY_SCHEDULE: "WORKER_AUTONOMY_SCHEDULE", // 근무자자율편성
  CONSECUTIVE_BREAK_TIME: "CONSECUTIVE_BREAK_TIME", // 연속휴게시간
  FLEXIBLE_WORK_HOURS: "FLEXIBLE_WORK_HOURS", // 자율출근시간
  QUITTING_TIME: "QUITTING_TIME", // 퇴근시간
  DAY_OFF: "DAY_OFF", // 휴일,
  WORK_DAY: "WORK_DAY", // 근무요일
  LEVEL0: "LEVEL0",
  LEVEL1: "LEVEL1",
  LEVEL2: "LEVEL2",
  LEVEL3: "LEVEL3",
  LEVEL4: "LEVEL4",
  LEVEL5: "LEVEL5",
  LEVEL6: "LEVEL6",
  LEVEL7: "LEVEL7",
  LEVEL8: "LEVEL8",
  LEVEL9: "LEVEL9",
  LEVEL10: "LEVEL10",
  LEVEL11: "LEVEL11"
} as const;

export type COLOR_TYPE = (typeof COLOR_TYPE)[keyof typeof COLOR_TYPE];

interface IProps {
  title?: string;
  min: number;
  type: Work_Type; // 근무 타입
  colorType: COLOR_TYPE; // 사각형 색 타입
  isDay: boolean; // 날짜 및 시간 타입 구분
  isSat?: boolean;
  isSun?: boolean;
  isDisabledTitle?: boolean;
  isToday?: boolean;
  selected?: boolean;
  handleSelect?: () => void;
}

interface IBox {
  startColor: string;
  endColor: string;
  lineWidth: string;
  isAutonomyAttendance: boolean;
  isToday?: boolean;
  selected?: boolean;
}

const Container = styled.div<{ isDay: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isDay ? "center" : "flex-start")};
`;

const Title = styled.span<{ isSat?: boolean; isSun?: boolean }>`
  font-weight: bold;
  color: ${props => {
    if (props.isSat) {
      return props.theme.colors.darkBlue;
    } else if (props.isSun) {
      return props.theme.colors.darkRed;
    }
    return props.theme.colors.grey;
  }};
`;

const Box = styled.div<IBox>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  -webkit-box-shadow: ${props =>
    props.selected
      ? `inset 0px 0px 0px 2px ${props.theme.colors.darkRed}`
      : ""};
  box-shadow: ${props =>
    props.selected
      ? `inset 0px 0px 0px 2px ${props.theme.colors.darkRed}`
      : ""};
  background: ${props => {
    return `linear-gradient(90deg, ${props.startColor} ${props.lineWidth}, ${props.endColor} ${props.lineWidth})`;
  }};
  color: ${props => props.theme.colors.white};
  ::before {
    display: ${props => (props.isToday ? "block" : "none")};
    position: absolute;
    top: 3px;
    right: 3px;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.darkRed};
  }
  ::after {
    display: ${props => (props.isAutonomyAttendance ? "block" : "none")};
    position: absolute;
    bottom: 3px;
    right: 3px;
    content: "";
    width: 5px;
    height: 5px;
    background-color: ${props => props.theme.colors.darkBlue};
  }
`;

export const typePalette: Record<COLOR_TYPE, string> = {
  [COLOR_TYPE.WORK_DAY]: "#1B75BB",
  [COLOR_TYPE.DAY_OFF]: "#D9D9D9",
  [COLOR_TYPE.OFF_DUTY_HOURS]: "#D9D9D9",
  [COLOR_TYPE.WORKING_HOURS]: "#55B1A2",
  [COLOR_TYPE.BREAK_TIME]: "#1B75BB",
  [COLOR_TYPE.OVERTIME_WORK]: "#4CAF50",
  [COLOR_TYPE.MANDATORY_WORK_HOURS]: "#F24E1E",
  [COLOR_TYPE.WORKER_AUTONOMY_SCHEDULE]: "#9747FF",
  [COLOR_TYPE.CONSECUTIVE_BREAK_TIME]: "#495E75",
  [COLOR_TYPE.FLEXIBLE_WORK_HOURS]: "#E9BDDD",
  [COLOR_TYPE.QUITTING_TIME]: "#85607A",
  [COLOR_TYPE.LEVEL0]: "#D9D9D9",
  [COLOR_TYPE.LEVEL1]: "#E3F3FD",
  [COLOR_TYPE.LEVEL2]: "#BBDEFB",
  [COLOR_TYPE.LEVEL3]: "#90CAF9",
  [COLOR_TYPE.LEVEL4]: "#90CAF9",
  [COLOR_TYPE.LEVEL5]: "#64B5F6",
  [COLOR_TYPE.LEVEL6]: "#42A5F5",
  [COLOR_TYPE.LEVEL7]: "#2196F3",
  [COLOR_TYPE.LEVEL8]: "#1E88E5",
  [COLOR_TYPE.LEVEL9]: "#1976D2",
  [COLOR_TYPE.LEVEL10]: "#1564C0",
  [COLOR_TYPE.LEVEL11]: "#1564C0"
};

function TimeGraphBox({
  title,
  min,
  type,
  colorType,
  isDay,
  isSat,
  isSun,
  isDisabledTitle,
  selected,
  handleSelect
}: IProps) {
  const value: number = useMemo(() => {
    return min / 60;
  }, [min]);

  const lineWidth = useMemo(() => {
    return Math.round(100 / 6) * value;
  }, [value]);

  // const hourPalette = useMemo(() => {
  //   return [
  //     "#D9D9D9", // 0
  //     "#E3F3FD", // 1
  //     "#BBDEFB", // 2
  //     "#90CAF9", // 3
  //     "#90CAF9", // 4
  //     "#64B5F6", // 5
  //     "#42A5F5", // 6
  //     "#2196F3", // 7
  //     "#1E88E5", // 8
  //     "#1976D2", // 9
  //     "#1564C0", // 10
  //     "#0D47A1" // 11
  //   ];
  // }, []);

  const { startColor, endColor } = useMemo(() => {
    return {
      startColor: typePalette[colorType],
      endColor: typePalette[colorType]
    };
  }, [colorType, typePalette]);

  return (
    <Container isDay={isDay}>
      {!isDisabledTitle && (
        <Title isSat={isSat} isSun={isSun}>
          {title ?? value}
        </Title>
      )}
      <Box
        title={`${value} H`}
        startColor={startColor}
        endColor={endColor}
        lineWidth={`${lineWidth}%`}
        isAutonomyAttendance={
          type === Work_Type.AutonomousCommutingPolicy &&
          colorType !== COLOR_TYPE.OFF_DUTY_HOURS
        }
        isToday={false}
        selected={selected}
        onClick={handleSelect}
      >{`${value.toFixed()}`}</Box>
    </Container>
  );
}

export default TimeGraphBox;
