import { AutoSizer } from "react-virtualized";
import { ListOnScrollProps, VariableSizeList as List } from "react-window";
import styled from "styled-components";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Message, { IDataForChat, MESSAGE_TYPE } from "./message";
import { ChatContext, ChatHandleContext } from "./chat";
import { Reducers } from "../../../types/reducers";
import { useSelector } from "react-redux";
import moment from "moment";
import AsonicIconButton from "../shared/asonic-icon-button/asonic-icon-button";
import DownIcon from "@iconify-icons/eva/arrow-down-fill";
import { colors } from "../GlobalStyle/GlobalStyle";
import { useLazyQuery } from "@apollo/client";
import {
  GetListOfChatImage,
  GetListOfChatImageVariables
} from "../../__generated__/GetListOfChatImage";
import { QUERY_GET_LIST_OF_CHAT_IMAGE } from "./chat-row";

enum MessageRequestType {
  RE_REQUEST = "RE_REQUEST",
  END_REQUEST = "END_REQUEST"
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.colors.cobaltBlue};
  border-top: 1px solid ${props => props.theme.colors.darkGrey};
`;

const BtnContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 20px;
  font-size: 44px;
`;

function ChatRoom() {
  const listRef: any = useRef();

  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);
  const [listOfDataForChat, setListOfDataForChat] = useState<IDataForChat[]>(
    []
  );
  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);

  const [listOfUserImage, setListOfUserImage] = useState<Map<string, string>>();

  const [getListOfChatImage] = useLazyQuery<
    GetListOfChatImage,
    GetListOfChatImageVariables
  >(QUERY_GET_LIST_OF_CHAT_IMAGE, {
    onCompleted(data) {
      if (data.getListOfChatImage.listOfImage && data.getListOfChatImage.ok) {
        const newListOfUserImage = new Map();
        data.getListOfChatImage.listOfImage.forEach(item => {
          newListOfUserImage.set(item.employee_id, item.myPicture);
        });
        setListOfUserImage(newListOfUserImage);
        setListOfDataForChat(preData => {
          if (preData.length > 0) {
            const addedImageToList = preData.map(item => {
              item.photo = newListOfUserImage.get(item.employeeId);
              return item;
            });
            return addedImageToList;
          }
          return preData;
        });
      }
    }
  });

  const [messageRequestType, setMessageRequestType] =
    useState<MessageRequestType>(MessageRequestType.RE_REQUEST);
  const [isDownBtn, setIsDownBtn] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(51);

  const getItemSize = useCallback(
    index => {
      let preEmployeeId: string = "";
      let postEmployeeId: string = "";
      // 한줄의 총 길이
      const standardOfRow = 33;
      const currentData = listOfDataForChat[index];
      const preData = listOfDataForChat[index - 1];
      const postData = listOfDataForChat[index + 1];
      const messageLength = currentData.message.length;
      const listOfLineBreak = currentData.message.match(/\n/g);

      let additionalValue = 60;

      if (preData) {
        preEmployeeId = preData.employeeId;
      }
      if (postData) {
        postEmployeeId = postData.employeeId;
      }
      if (
        currentData.employeeId === preEmployeeId &&
        currentData.dateTime === preData.dateTime
      ) {
        if (
          currentData.employeeId === postEmployeeId &&
          currentData.dateTime === postData.dateTime
        ) {
          additionalValue = 45;
        }
      }
      if (
        currentData.employeeId === postEmployeeId &&
        currentData.dateTime === postData.dateTime
      ) {
        additionalValue = 45;
      }
      if (
        (messageLength > standardOfRow || listOfLineBreak) &&
        currentData.messageType === MESSAGE_TYPE.USER
      ) {
        let theNumberOfRows = 0;
        if (listOfLineBreak) {
          theNumberOfRows = listOfLineBreak.length;
        } else {
          theNumberOfRows = Math.floor(messageLength / standardOfRow);
        }
        const itemSizeRate = currentData.employeeId === employee_id ? 14 : 18;
        return itemSizeRate * theNumberOfRows + additionalValue;
      }
      return additionalValue;
    },
    [listOfDataForChat, employee_id]
  );

  const handleScroll = useCallback(
    (scrollProps: ListOnScrollProps) => {
      const topPosition: number = 0;
      if (!isDownBtn && scrollProps.scrollDirection === "backward") {
        setIsDownBtn(true);
      }
      if (isDownBtn && scrollProps.scrollDirection === "forward") {
        setIsDownBtn(false);
      }
      if (
        scrollProps.scrollDirection === "backward" &&
        !scrollProps.scrollUpdateWasRequested &&
        scrollProps.scrollOffset === topPosition &&
        messageRequestType === MessageRequestType.RE_REQUEST
      ) {
        const payload = {
          type: "chatMsgReq",
          body: {
            roomId: chatContext?.selectedChatRoomIdx,
            employeeId: employee_id,
            limit: 50,
            offset
          }
        };
        chatHandleContext?.handleIsScrollInChatRoom(true);
        chatContext?.socket?.send(JSON.stringify(payload));
        setOffset(pre => {
          return pre + 50;
        });
      }
    },
    [
      isDownBtn,
      offset,
      messageRequestType,
      employee_id,
      chatContext?.selectedChatRoomIdx,
      chatContext?.socket,
      chatHandleContext
    ]
  );

  useEffect(() => {
    if (chatContext?.selectedChatRoomIdx && chatContext.listOfUserInRoom) {
      getListOfChatImage({
        variables: {
          chatroomIdx: chatContext.selectedChatRoomIdx,
          imageCount: chatContext.listOfUserInRoom.length
        }
      });
    }
  }, [chatContext, getListOfChatImage]);

  const checkFirstMessageDate = useCallback(
    (list: any[], index: number): boolean => {
      const preData = list[index - 1];
      const currentData = list[index];
      const preDataDate = moment(preData?.chatroom_message_datetime).format(
        "YYYY-MM-DD"
      );
      const currentDataDate = moment(
        currentData?.chatroom_message_datetime
      ).format("YYYY-MM-DD");
      return preDataDate !== currentDataDate;
    },
    []
  );

  useEffect(() => {
    if (chatContext?.socket) {
      chatContext.socket.onmessage = event => {
        if (event.data) {
          const { body } = JSON.parse(event.data);

          const listFromSocketServer: any[] = body;
          if (listFromSocketServer.length === 0) {
            setMessageRequestType(MessageRequestType.END_REQUEST);
          }

          const list: IDataForChat[] = [];
          listFromSocketServer.reverse().forEach((item, index) => {
            const isFirstMessageDate: boolean = checkFirstMessageDate(
              listFromSocketServer,
              index
            );
            const {
              name,
              chatroom_attendee_employeeid: employeeId,
              chatroom_message_data: message,
              chatroom_message_datetime: dateTime,
              chatroom_message_type: messageType
            } = item;
            if (isFirstMessageDate) {
              list.push({
                name,
                employeeId: employeeId,
                message: message,
                dateTime,
                messageType: 2,
                photo: listOfUserImage?.get(employeeId)
              });
            }
            list.push({
              name,
              employeeId: employeeId,
              message: message,
              dateTime,
              messageType,
              photo: listOfUserImage?.get(employeeId)
            });
          });

          if (list.length > 0) {
            setListOfDataForChat(preData => {
              if (preData.length > 0) {
                const preDateTime = moment(preData[0].dateTime);
                const postDateTime = list[list.length - 1].dateTime;
                const isPositive = moment
                  .duration(preDateTime.diff(postDateTime))
                  .asMilliseconds();
                if (isPositive > 0) {
                  // resetAfterIndex의 역할
                  // resetAfterIndex는 새로 데이터를 입력되면 기존의 style들을 초기화 시켜줘서
                  // 각기 다른 아이템 크기를 다시 계산해서 스타일을 만들게 해준다.
                  listRef?.current?.resetAfterIndex(0);
                  return [...list, ...preData];
                }
                return [...preData, ...list];
              }
              return list;
            });
          }
        }
      };
    }
  }, [chatContext?.socket, listOfUserImage, checkFirstMessageDate]);

  useEffect(() => {
    // 메시지 추가시 스크롤을 아래로 내려주기
    if (listOfDataForChat.length > 0) {
      if (!chatContext?.isScrollInChatRoom) {
        listRef?.current?.scrollToItem(listOfDataForChat.length + 1, "auto");
      }
    }
  }, [listOfDataForChat, employee_id, chatContext?.isScrollInChatRoom]);

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              height={height}
              itemCount={listOfDataForChat.length}
              itemData={listOfDataForChat}
              itemSize={getItemSize}
              onScroll={handleScroll}
              width={width}
              ref={listRef}
            >
              {Message}
            </List>
          );
        }}
      </AutoSizer>
      {isDownBtn && (
        <BtnContainer>
          <AsonicIconButton
            icon={DownIcon}
            color={colors.tomato}
            onClick={() => {
              listRef?.current?.scrollToItem(
                listOfDataForChat.length + 1,
                "auto"
              );
            }}
          />
        </BtnContainer>
      )}
    </Container>
  );
}

export default ChatRoom;
