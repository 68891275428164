import React from "react";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import { ApprovalSearchResult } from "../../../../../types/reducers";
import {
  Container,
  ItemContainer,
  MainTitle,
  DateTerm,
  Name,
  Category,
  Result,
  Item
} from "./styled";
import { _resultTranslation, _dateTranslation } from "./utils";

const APPROVAL_PAGE = "Approval";

interface IProps {
  data?: ApprovalSearchResult[];
  pageType: string;
  handleOpenDialog?: any;
  getDataWhenScrolling: (bottomPosition: number, clientHeight: number) => void;
  isLoading?: boolean;
}

const MobileTableContents = <P extends IProps>({
  data = [],
  pageType,
  handleOpenDialog,
  getDataWhenScrolling,
  isLoading
}: P) => {
  const listRef = React.useRef<HTMLDivElement | null>(null);

  const RenderRow = ({ index, style }: { index: number; style: object }) => {
    const row = data[index];
    return (
      <Item style={style}>
        <ItemContainer
          pageType={pageType}
          key={index}
          onClick={
            pageType === APPROVAL_PAGE && handleOpenDialog
              ? handleOpenDialog(index)
              : handleOpenDialog
          }
        >
          <React.Fragment>
            <MainTitle>
              <Name>{isLoading || row.name}</Name>
              <Category>{isLoading ? `Loading...` : row.category_id}</Category>
              <Result>
                {isLoading || _resultTranslation(row.result_value)}
              </Result>
            </MainTitle>
            <DateTerm>
              {isLoading ||
                _dateTranslation(
                  row.extend_sdate,
                  row.extend_sdatetime,
                  row.extend_edate,
                  row.extend_edatetime
                )}
            </DateTerm>
          </React.Fragment>
        </ItemContainer>
      </Item>
    );
  };
  return (
    <React.Fragment>
      <Container>
        {data.length > 0 && (
          <AutoSizer>
            {({ height, width }) => (
              <List
                initialScrollOffset={0}
                outerRef={listRef}
                onScroll={({ scrollOffset }) => {
                  if (listRef.current) {
                    const { scrollHeight, clientHeight } = listRef.current;
                    const bottomPosition = scrollHeight - scrollOffset;
                    getDataWhenScrolling(bottomPosition, clientHeight);
                  }
                }}
                height={height}
                itemCount={data.length}
                itemSize={50}
                width={width}
              >
                {RenderRow}
              </List>
            )}
          </AutoSizer>
        )}
      </Container>
    </React.Fragment>
  );
};

export default MobileTableContents;
