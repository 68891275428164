import * as React from "react";
import useCurrentTimeBox from "../../../../hooks/personal-work-dashboard-page-hooks/use-current-time-box";
import { faMobileAlt, faDesktop } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  BackgroundContainer,
  DateContainer,
  Date,
  CurrentTimeContainer,
  TimeTitle,
  WorkingTimeContainer,
  WorkingTimeTitle,
  WorkingTimeContent,
  CommutingInfoContainer,
  CommutingInfoSection,
  CommutingInfoTitle,
  CommutingInfoContent,
  Icon
} from "./styled";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import CurrentTime from "../current-time/current-time";

type Props = {
  isMiddleSection?: boolean;
  isMobile?: boolean;
};

const APP = 3;

const CurrentTimeBox = <P extends Props>({ isMiddleSection, isMobile }: P) => {
  const { employee_id: userId } = useSelector((state: Reducers) => {
    return state.signInReducer;
  });

  const { currentDate, workingTimeInfo, commutingInfo } = useCurrentTimeBox({
    userId
  });

  return (
    <Container data-testid="current-time-box" isMiddleSection={isMiddleSection}>
      {!isMobile && (
        <React.Fragment>
          <DateContainer>
            <Date data-testid="date">{currentDate}</Date>
          </DateContainer>
          <CurrentTimeContainer>
            <TimeTitle data-testid="current-time-title">현재시간</TimeTitle>
            <CurrentTime />
          </CurrentTimeContainer>
        </React.Fragment>
      )}
      <WorkingTimeContainer>
        <WorkingTimeTitle data-testid="wokring-time-title">
          정규 근무시간
        </WorkingTimeTitle>
        <WorkingTimeContent data-testid="wokring-time-content">
          {workingTimeInfo}
        </WorkingTimeContent>
      </WorkingTimeContainer>
      <CommutingInfoContainer>
        <CommutingInfoSection>
          <CommutingInfoTitle data-testid="commuting-start-title">
            출근정보
          </CommutingInfoTitle>
          <CommutingInfoContent
            data-testid="commuting-start-content"
            isTime={commutingInfo.start === "출근 전" ? false : true}
          >
            {commutingInfo.start}
          </CommutingInfoContent>
          {commutingInfo.startDeviceType &&
            commutingInfo.startDeviceType !== APP && (
              <Icon icon={faDesktop} size="lg" />
            )}
          {commutingInfo.startDeviceType === APP && (
            <Icon icon={faMobileAlt} size="lg" />
          )}
        </CommutingInfoSection>
        <CommutingInfoSection>
          <CommutingInfoTitle data-testid="commuting-end-title">
            퇴근정보
          </CommutingInfoTitle>
          <CommutingInfoContent
            data-testid="commuting-end-content"
            isTime={commutingInfo.end === "퇴근 전" ? false : true}
          >
            {commutingInfo.end}
          </CommutingInfoContent>
          {commutingInfo.endDeviceType &&
            commutingInfo.endDeviceType !== APP && (
              <Icon icon={faDesktop} size="lg" />
            )}
          {commutingInfo.endDeviceType === APP && (
            <Icon icon={faMobileAlt} size="lg" />
          )}
        </CommutingInfoSection>
      </CommutingInfoContainer>
      <BackgroundContainer />
    </Container>
  );
};

export default CurrentTimeBox;
