import Navbar from "../../../moComponents/main-view/NavBar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { categorySelectionActionCreator } from "../../../redux/modules/main-view/categorySelectionReducer";
import { mainViewActionCreator } from "../../../redux/modules/main-view/mainViewReducer";
import { requestActionCreator } from "../../../redux/modules/main-view/request/requestReducer";
import { signInActionCreator } from "../../../redux/modules/sign-in/signInReducer";

const mapStateToProps = state => ({
  mainViewReducer: state.mainViewReducer,
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...mainViewActionCreator,
      ...requestActionCreator,
      ...signInActionCreator,
      ...categorySelectionActionCreator
    },
    dispatch
  )
});

const NavBarContainer = props => {
  return <Navbar {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
