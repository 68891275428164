import styled from "styled-components";

export const TitleBar = styled.div<Partial<DayInfo>>`
  display: flex;
  justify-content: ${props => (props.title ? "center" : "space-between")};
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #b2bec3;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const Container = styled.div<Partial<DayInfo>>`
  background-color: ${props => (props.isCurrentMonth ? "white" : "#F8F9FB")};
  flex: 1;
  border: ${props => (props.today ? "3px solid #0984e3" : "1px solid #b2bec3")};
  box-sizing: border-box;
`;

export const Span = styled.span<Partial<DayInfo>>`
  color: ${props => (props.color ? props.color : "#636e72")};
  font-weight: bold;
`;

export const CellBody = styled.div<Partial<DayInfo>>`
  box-sizing: border-box;
`;

export const MainScheduleContainer = styled.div`
  padding-bottom: 2px;
`;
