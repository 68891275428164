import * as React from "react";
import SignInBox from "../SignInBox";
import { Container, SignInBoxContainer } from "./styled";
import MenuSettingsContainer from "../main-view/settings/MenuSettings/MenuSettingsContainer";
import AlarmPush from "../alarm-push";
import EtcSettings from "../etc-settings";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";

type Props = {
  handleAfterLogin: <T>(data: T) => void;
  isSuperAdmin: boolean;
  messageTypes: MessageTypes;
  message: string;
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  handleMessage: (value: string) => void;
  handleToastMessageType: (value: MessageTypes) => void;
};

const idLabel = "Super Admin ID";
const buttonLabel = "Super Admin 권한 휙득";

const SuperAdminSettingsPagePresenter = <P extends Props>({
  handleAfterLogin,
  isSuperAdmin,
  messageTypes,
  message,
  isOpen,
  handleIsOpen,
  handleMessage,
  handleToastMessageType
}: P) => {
  return (
    <Container>
      {isSuperAdmin && (
        <React.Fragment>
          <MenuSettingsContainer
            handleIsOpen={handleIsOpen}
            handleMessage={handleMessage}
            handleToastMessageType={handleToastMessageType}
          />
          <AlarmPush />
          <EtcSettings />
        </React.Fragment>
      )}
      {!isSuperAdmin && (
        <SignInBoxContainer>
          <SignInBox
            idLabel={idLabel}
            buttonLabel={buttonLabel}
            handleAfterLogin={handleAfterLogin}
            isAdminCheck={true}
          />
        </SignInBoxContainer>
      )}
      <ToastMessage
        messageTypes={messageTypes}
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
};

export default SuperAdminSettingsPagePresenter;
