import * as React from "react";
import { Container, Select, Option, Label } from "./styled";

type Data = {
  title: string;
  value: any;
};

type Props = {
  title: string;
  propFor: string;
  datas: Data[];
  handleSelectedItem: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: any;
  minWidth?: string;
  required?: boolean;
};

const NativeSelect = <P extends Props>({
  value,
  title,
  propFor,
  datas,
  handleSelectedItem,
  minWidth,
  required
}: P) => {
  return (
    <Container>
      <span style={{ visibility: required ? "visible" : "hidden" }}>*</span>
      <Label htmlFor={propFor}>{title}</Label>
      <Select
        name={title}
        id={propFor}
        onChange={handleSelectedItem}
        value={value}
        minWidth={minWidth}
        // className="align-start"
      >
        {datas.map((item, index) => (
          <Option value={item.value} key={Symbol(index).toString()}>
            {item.title}
          </Option>
        ))}
      </Select>
    </Container>
  );
};

export default NativeSelect;
