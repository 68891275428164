import api from "./api";

const BASE_URL = `${api.api}/menu`;
const WORKING_AUTH_WEB = `${BASE_URL}/working-auth-web`;
const WIDGET_BOX = `${BASE_URL}/widget-box`;

const menuSettingsRoutes = {
  workingAuthWeb: WORKING_AUTH_WEB,
  widgetBox: WIDGET_BOX
};

export default menuSettingsRoutes;
