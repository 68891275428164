import * as React from "react";
import styled from "styled-components";

export interface Data {
  data: { id: string; name: string };
  origin: any;
}

interface IProps {
  value?: string;
  data: Data[];
  selectItem: (event: SelectItemEvent) => void;
  isHidingBorder?: boolean;
}

interface ISelectProps {
  isHidingBorder?: boolean;
}

export type SelectItemEvent = React.ChangeEvent<HTMLSelectElement>;

const CustomSelect = styled.select<ISelectProps>`
  border: ${props => (props.isHidingBorder ? "none" : "1px solid black")};
  border-bottom: 1px solid black;
`;

const Option = styled.option``;

const Select = <P extends IProps>({
  value,
  data,
  selectItem,
  isHidingBorder
}: P) => {
  return (
    <CustomSelect
      onChange={selectItem}
      isHidingBorder={isHidingBorder}
      value={value}
    >
      {data &&
        data.map(item => (
          <Option key={`${item.data.name + item.data.id}`} value={item.data.id}>
            {item.data.name}
          </Option>
        ))}
    </CustomSelect>
  );
};

export default Select;
