import { Container, CategorySelect, Label, SearchContainer } from "./styled";
import uiString from "./string.json";
import { TextInput } from "../../../../components/main-view/smallcomponents/TitleBar/WorkingTimeScheduleSetting/WorkingTimeScheduleTemplatesList/WorkingTimeScheduleTemplateEditor/styled";

const MobileSearchSelection = ({ updateSearchType, updateSearchWord }) => {
  return (
    <Container>
      <Label>검색조건</Label>
      <SearchContainer>
        <CategorySelect name="searchType" onChange={updateSearchType}>
          <option value={1}>{uiString.selections.name}</option>
          <option value={2}>{uiString.selections.employeeId}</option>
        </CategorySelect>
        <TextInput
          id="textarea"
          autoFocus
          onChange={updateSearchWord}
          placeholder={uiString.textFields.searchWord}
        />
      </SearchContainer>
    </Container>
  );
};

export default MobileSearchSelection;
