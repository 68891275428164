import styled from "styled-components";
import { useCallback, useEffect } from "react";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import useOpenDialog from "../../../hooks/use-open-dialog";
import { useForm } from "react-hook-form";
import TextInputWithLabel from "../../inputs/text-input-with-label";
import Button from "../../globalComponents/Button";
import { gql, useMutation } from "@apollo/client";
import {
  AddVehicleInformation,
  AddVehicleInformationVariables
} from "../../../__generated__/AddVehicleInformation";
import { MessageTypes } from "../../toast-message/toast-message";
import { GetListOfVehicleInformation_getListOfVehicleInformation_list } from "../../../__generated__/GetListOfVehicleInformation";
import {
  DeleteVehicleInformation,
  DeleteVehicleInformationVariables
} from "../../../__generated__/DeleteVehicleInformation";
import * as ReactTable from "react-table";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import {
  UpdateVehicleInformation,
  UpdateVehicleInformationVariables
} from "../../../__generated__/UpdateVehicleInformation";
import ControllerWithIconButton from "../../controller-with-icon-button/controller-with-icon-button";

interface IProps {
  handleToast: (message: string, type: MessageTypes) => void;
  selectedRow?: ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>;
  handleIsDisableZIndex: (value: boolean) => void;
}

interface FieldValues {
  vehicleNumber: string;
  vehicleName: string;
}

const Container = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  right: 10px;
  bottom: 10px;
  min-width: max-content;
  min-height: max-content;
  gap: 5px;
  z-index: 2;
`;

const ControllerDialogContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const ButtonContainer = styled(Section)`
  align-self: flex-end;
`;

const MUTATION_ADD_VEHICLE_INFORMATION = gql`
  mutation AddVehicleInformation(
    $vehicleNumber: String!
    $vehicleName: String!
  ) {
    addVehicleInformation(
      vehicleNumber: $vehicleNumber
      vehicleName: $vehicleName
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_VEHICLE_INFORMATION = gql`
  mutation DeleteVehicleInformation($listOfVehicleNumber: [String!]!) {
    deleteVehicleInformation(listOfVehicleNumber: $listOfVehicleNumber) {
      ok
      error
    }
  }
`;

const MUTATION_UPDATE_VEHICLE_INFORMATION = gql`
  mutation UpdateVehicleInformation(
    $vehicleNumber: String!
    $updateVehicleNumber: String!
    $vehicleName: String!
  ) {
    updateVehicleInformation(
      vehicleNumber: $vehicleNumber
      updateVehicleNumber: $updateVehicleNumber
      vehicleName: $vehicleName
    ) {
      ok
      error
    }
  }
`;

function VehicleInformationController({
  handleToast,
  selectedRow,
  handleIsDisableZIndex
}: IProps) {
  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();
  const {
    isOpen: isOpenDialogForUpdate,
    handleOpenDialog: handleOpenDialogForUpdate
  } = useOpenDialog();
  const { register, handleSubmit, setValue } = useForm<FieldValues>({
    mode: "onSubmit"
  });

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isOpenConfirmDialog,
    handleIsOpen: handleIsOpenConfirmDialog,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const handleIsOpen = useCallback(
    (value: boolean) => {
      handleIsOpenConfirmDialog(value);
      handleIsDisableZIndex(value);
    },
    [handleIsOpenConfirmDialog, handleIsDisableZIndex]
  );

  const [updateVehicleInformation] = useMutation<
    UpdateVehicleInformation,
    UpdateVehicleInformationVariables
  >(MUTATION_UPDATE_VEHICLE_INFORMATION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    update(cache, { data }, { variables }) {
      if (data?.updateVehicleInformation.ok && variables) {
        cache.modify({
          id: `VehicleInformationEntity:${variables.vehicleNumber}`,
          fields: {
            vehicleNumber() {
              return variables?.updateVehicleNumber;
            },
            vehicleName() {
              return variables.vehicleName;
            }
          }
        });
        handleToast(
          `차량번호: ${variables.vehicleNumber} 차량을 성공적으로 수정하셨습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (
        !data?.updateVehicleInformation.ok &&
        data?.updateVehicleInformation.error
      ) {
        handleToast(data?.updateVehicleInformation.error, MessageTypes.ERROR);
      }
      handleOpenDialogForUpdate(false);
    }
  });

  const handleUpdateVehicleInformation = useCallback(
    (data: FieldValues) => {
      const { vehicleName, vehicleNumber } = data;
      if (vehicleName && vehicleNumber && selectedRow?.original.vehicleNumber) {
        updateVehicleInformation({
          variables: {
            vehicleNumber: selectedRow?.original.vehicleNumber,
            updateVehicleNumber: vehicleNumber,
            vehicleName
          }
        });
      }
    },
    [updateVehicleInformation, selectedRow]
  );

  const [deleteVehicleInformation] = useMutation<
    DeleteVehicleInformation,
    DeleteVehicleInformationVariables
  >(MUTATION_DELETE_VEHICLE_INFORMATION, {
    update(cache, { data }, { variables }) {
      if (data?.deleteVehicleInformation.ok && variables) {
        handleIsOpen(false);
        handleToast(
          `차량번호: ${variables?.listOfVehicleNumber[0]} 차량을 성공적으로 삭제하셨습니다.`,
          MessageTypes.SUCCESS
        );
        cache.evict({
          id: `VehicleInformationEntity:${variables.listOfVehicleNumber[0]}`
        });
        cache.modify({
          id: `GetTotalNumberOfVehicleInformationOutput`,
          fields: {
            total(prev) {
              if (prev) {
                return prev - 1;
              }
              return prev;
            }
          }
        });
      }
      if (
        !data?.deleteVehicleInformation.ok &&
        data?.deleteVehicleInformation.error
      ) {
        handleToast(data?.deleteVehicleInformation.error, MessageTypes.ERROR);
      }
    }
  });

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      deleteVehicleInformation({
        variables: {
          listOfVehicleNumber: [selectedRow.original.vehicleNumber]
        }
      });
    }
  }, [deleteVehicleInformation, selectedRow]);

  const [addVehicleInformation] = useMutation<
    AddVehicleInformation,
    AddVehicleInformationVariables
  >(MUTATION_ADD_VEHICLE_INFORMATION, {
    update(cache, { data }, { variables }) {
      if (data?.addVehicleInformation.ok && variables) {
        const newVehicle: GetListOfVehicleInformation_getListOfVehicleInformation_list =
          {
            vehicleNumber: variables.vehicleNumber,
            vehicleName: variables.vehicleName,
            __typename: "VehicleInformationEntity"
          };

        const newVehicleCache = cache.writeFragment({
          id: `VehicleInformationEntity:${variables.vehicleNumber}`,
          fragment: gql`
            fragment add on VehicleInformationEntity {
              __typename
              vehicleNumber
              vehicleName
            }
          `,
          data: {
            ...newVehicle
          }
        });
        cache.modify({
          id: `GetTotalNumberOfVehicleInformationOutput`,
          fields: {
            total(prev) {
              if (prev) {
                return prev + 1;
              }
              return 1;
            }
          }
        });
        cache.modify({
          id: `GetListOfVehicleInformationOutput`,
          fields: {
            list(prev) {
              if (prev) {
                return [...prev, newVehicleCache];
              }
              return [newVehicleCache];
            }
          }
        });
        handleToast(
          `차량번호: ${variables.vehicleNumber} 차량을 성공적으로 등록하셨습니다.`,
          MessageTypes.SUCCESS
        );
      }
      if (
        !data?.addVehicleInformation.ok &&
        data?.addVehicleInformation.error
      ) {
        handleToast(data?.addVehicleInformation.error, MessageTypes.ERROR);
      }
    }
  });

  const handleAddVehicleInformation = useCallback(
    (data: FieldValues) => {
      const { vehicleName, vehicleNumber } = data;
      if (vehicleName && vehicleNumber) {
        addVehicleInformation({
          variables: {
            vehicleNumber,
            vehicleName
          }
        });
      }
    },
    [addVehicleInformation]
  );

  useEffect(() => {
    if (
      selectedRow?.original.vehicleName &&
      selectedRow?.original.vehicleNumber
    ) {
      setValue("vehicleName", selectedRow.original.vehicleName);
      setValue("vehicleNumber", selectedRow.original.vehicleNumber);
    }
  }, [setValue, selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      handleConfirmMessage({
        title: `차량정보 삭제`,
        p: `차량번호: ${selectedRow.original.vehicleNumber} 차량을 삭제하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
    }
  }, [handleConfirmMessage, selectedRow]);

  return (
    <Container>
      <ControllerWithIconButton
        isAddActive={false}
        isEditActive={!selectedRow}
        isDeleteActive={!selectedRow}
        handleAddButton={() => {
          handleOpenDialog(true);
          handleIsDisableZIndex(true);
        }}
        handleEditButton={() => {
          handleOpenDialogForUpdate(true);
          handleIsDisableZIndex(true);
        }}
        handleDeleteButton={() => {
          handleIsOpen(true);
        }}
      />
      {isOpenConfirmDialog && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      {isOpenDialogForUpdate && (
        <AsonicDialog
          title="차량정보 수정"
          handleClose={(value: boolean) => {
            handleOpenDialogForUpdate(value);
            handleIsDisableZIndex(value);
          }}
          height="180px"
          minHeight="180px;"
          width="240px"
          minWidth="240px"
        >
          <ControllerDialogContainer
            onSubmit={handleSubmit(handleUpdateVehicleInformation)}
          >
            <Section>
              <TextInputWithLabel
                title="차량 번호"
                {...register("vehicleNumber")}
              />
              <TextInputWithLabel title="차명" {...register("vehicleName")} />
            </Section>
            <ButtonContainer>
              <Button>수정하기</Button>
            </ButtonContainer>
          </ControllerDialogContainer>
        </AsonicDialog>
      )}
      {isOpenDialog && (
        <AsonicDialog
          title="차량정보"
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
            handleIsDisableZIndex(value);
          }}
          height="180px"
          minHeight="180px;"
          width="240px"
          minWidth="240px"
        >
          <ControllerDialogContainer
            onSubmit={handleSubmit(handleAddVehicleInformation)}
          >
            <Section>
              <TextInputWithLabel
                title="차량 번호"
                {...register("vehicleNumber")}
              />
              <TextInputWithLabel title="차명" {...register("vehicleName")} />
            </Section>
            <ButtonContainer>
              <Button>등록하기</Button>
            </ButtonContainer>
          </ControllerDialogContainer>
        </AsonicDialog>
      )}
    </Container>
  );
}

export default VehicleInformationController;
