import * as React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { Chart as ChartJS, Plugin } from "chart.js";
import colors from "../../../../../style-sheet/colors";
import { GetRecognitionWorkTime_getRecognitionWorkTime_todayRecognitionWorkTime } from "../../../../../__generated__/GetRecognitionWorkTime";
import MakeTooltip from "../../../../../Utils/make-tooltip/make-tooltip";

type DataSets = {
  backgroundColor: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  data: number[];
  label: string;
};

type Props = {
  labels: string[];
  datasets: DataSets[];
  isToday: boolean;
  tooltipData?: GetRecognitionWorkTime_getRecognitionWorkTime_todayRecognitionWorkTime | null;
};

// alwaysShowTooltip plugin
const alwaysShowTooltip: Plugin = {
  id: "alwaysShowTooltip",
  afterDraw(
    chart,
    _,
    options: {
      realworkmin: string;
      realextmin: string;
    }
  ) {
    const { ctx } = chart;
    ctx.save();
    const { realworkmin, realextmin } = options;
    let regularXPosition = 0;
    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach(dataPoint => {
        const { x, y } = dataPoint;
        const label = dataset.label;
        const data = dataset.data[0] as number;

        const makeTooltip = new MakeTooltip(
          x,
          y,
          label,
          realworkmin,
          realextmin,
          data
        );
        let text = makeTooltip.makeText();
        const { xPosition } = makeTooltip.makeXPosition();
        if (label === "정규") {
          regularXPosition = x;
        }
        const { xPosition: overTimeXPosition } =
          makeTooltip.makeOverTimeXPosition(regularXPosition);

        if (text) {
          const textWidth = ctx.measureText(text).width;
          const { fillRect, fillText } = makeTooltip.makeEachXPosition({
            textWidth,
            regularXPosition
          });
          ctx.fillStyle = colors.sideBar.backGroundColor;
          let extraXPosition = 0;
          if (label === "정규" && xPosition < 20) {
            extraXPosition = 30;
          }

          if (label === "연장" && overTimeXPosition < 20) {
            extraXPosition = 38;
          }

          ctx.fillRect(
            fillRect.x + extraXPosition,
            fillRect.y,
            fillRect.textX,
            fillRect.textY
          );

          // text
          ctx.font = "12px Arial";

          ctx.fillStyle = colors.sideBar.color;
          ctx.fillText(text, fillText.x + extraXPosition, fillText.y);
          ctx.restore();

          // triangle
          ctx.fillStyle = colors.sideBar.backGroundColor;
          ctx.beginPath();
          if (label === "연장") {
            let extraXPosition = 0;
            if (overTimeXPosition < 20) {
              extraXPosition = fillText.x + 30;
            }
            ctx.moveTo(overTimeXPosition + extraXPosition, y + 10);
            ctx.lineTo(overTimeXPosition + extraXPosition - 5, y + 15);
            ctx.lineTo(overTimeXPosition + extraXPosition + 5, y + 15);
            ctx.fill();
            ctx.restore();
          } else {
            let extraXPosition = 0;
            if (xPosition < 20) {
              extraXPosition = fillText.x + 30;
            }

            ctx.moveTo(xPosition + extraXPosition, y - 10);
            ctx.lineTo(xPosition + extraXPosition - 5, y - 15);
            ctx.lineTo(xPosition + extraXPosition + 5, y - 15);
            ctx.fill();
            ctx.restore();
          }
        }
      });
    });
  }
};

const WorkingInformationChart = <P extends Props>({
  labels,
  datasets,
  isToday,
  tooltipData
}: P) => {
  const ref = React.useRef<any>(null);

  const options: any = React.useMemo(
    () => ({
      indexAxis: "y" as const,
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          formatter: function (value: any, context: any) {
            if (context.datasetIndex !== 2) {
              const hours = Math.floor(
                moment.duration(value, "minutes").asHours()
              );
              const min = value % 60;
              return `${hours}h ${min}m`;
            } else {
              return "";
            }
          },
          display: true,
          align: "center",
          anchor: "center",
          color: "white"
        },
        tooltip: {
          enabled: true
        },
        alwaysShowTooltip: {
          realworkmin: tooltipData?.realworkmin,
          realextmin: tooltipData?.realextmin
        }
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          display: false,
          stacked: true
        },
        x: {
          ticks: {
            stepSize: isToday ? 240 : 120,
            beginAtZero: true,
            callback: function (value: number) {
              if (value < 0) {
                return `0`;
              }
              const hours = Math.floor(
                moment.duration(value, "minutes").asHours()
              );
              return `${hours}`;
            }
          },
          stacked: true
        }
      }
    }),
    [isToday, tooltipData]
  );

  React.useEffect(() => {
    ref.current?.chartInstance?.update();
  }, [datasets]);

  return (
    <Bar
      ref={ref}
      data={{ labels, datasets }}
      width={10}
      height={20}
      options={options}
      plugins={[alwaysShowTooltip]}
    />
  );
};

export default WorkingInformationChart;
