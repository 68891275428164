import { ControllerRenderProps } from "react-hook-form";
import styled from "styled-components";
import Button from "../../../globalComponents/Button";
import StyleInput from "../../../inputs/style-input";
import FormRow from "../../../shared/form-row/form-row";
import SubTitle from "../../../shared/sub-title/sub-title";
import {
  SignType,
  useDeleteApprovalSignImageOrTextMutation
} from "../../../../generated/graphql";
import { useCallback, useMemo, useState } from "react";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import useOpenDialog from "../../../../hooks/use-open-dialog";
import { IApprovalSettingFormValue } from "./approval-management-setting";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";

interface IProps {
  previewURLForSave: string | null | undefined;
  handlePreviewURLForSave: (image: string) => void;
  signTextField: ControllerRenderProps<IApprovalSettingFormValue, "signText">;
  signTypeField: ControllerRenderProps<IApprovalSettingFormValue, "signType">;
  signText?: string;
  handleSignText: (text: string) => void;
  handleSelectFile: (file: File) => void;
}

const Container = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const RadioContainer = styled.div`
  display: flex;
  flex: 1;
  input {
    margin: 0px;
  }
  gap: 10px;
`;

const SignFormContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

const DisplaySign = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 24px;
`;

const Img = styled.img`
  display: flex;
  max-width: 120px;
`;

const InputFileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const InputFile = styled.input``;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;
function ApprovalSignSetting({
  signTextField,
  previewURLForSave,
  handlePreviewURLForSave,
  signTypeField,
  signText,
  handleSignText,
  handleSelectFile
}: IProps) {
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const [previewURL, setPreviewURL] = useState<string | null | undefined>();

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();
  const {
    confirmTitle,
    confirmParagraph,
    isOpen: confirmIsOpen,
    handleIsOpen: handleConfirmIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const signTypeTitle = useMemo(
    () =>
      signTypeField.value === SignType.Image ? "서명 이미지" : "서명 텍스트",
    [signTypeField]
  );

  const [deleteApprovalSignImageOrText, { client }] =
    useDeleteApprovalSignImageOrTextMutation({
      onError() {
        handleToast(
          `${signTypeTitle}를 삭제할 수 없습니다.`,
          MessageTypes.ERROR
        );
      },
      update(_, { data }) {
        if (data?.deleteApprovalSignImageOrText.ok) {
          handleToast(
            `${signTypeTitle}를 삭제하였습니다.`,
            MessageTypes.SUCCESS
          );
          client.resetStore();
        }
      }
    });

  const handleDelete = useCallback(() => {
    deleteApprovalSignImageOrText({
      variables: {
        employeeId: employee_id,
        deleteSignType: signTypeField.value
      }
    });
  }, [deleteApprovalSignImageOrText, employee_id, signTypeField]);

  const handleSelectedFile = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const maxSize = 1024 * 512;
      if (
        target.files &&
        target.files?.length > 0 &&
        target.files[0].size <= maxSize
      ) {
        const reader = new FileReader();
        const img = target.files[0];

        reader.onloadend = () => {
          handleSelectFile(img);
          setPreviewURL(reader.result as string);
        };
        reader.readAsDataURL(img);
      } else {
        handleToast(
          `최대 파일 크기는 500KB를 초과할 수 없습니다.`,
          MessageTypes.ERROR
        );
      }
    },
    [handleToast, handleSelectFile]
  );

  const handleSave = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (previewURL && signTypeField.value === SignType.Image) {
        handlePreviewURLForSave(previewURL);
        handleOpenDialog(false);
      }

      if (signTypeField.value === SignType.Text) {
        const text = signTextField.value;
        handleSignText(text);
        handleOpenDialog(false);
      }
    },
    [
      previewURL,
      handleOpenDialog,
      signTypeField,
      handlePreviewURLForSave,
      handleSignText,
      signTextField
    ]
  );

  const isDeleteButton = useMemo(() => {
    if (signTypeField.value === SignType.Image && previewURLForSave) {
      return false;
    }
    if (signTypeField.value === SignType.Text && signTextField.value) {
      return false;
    }
    return true;
  }, [signTypeField]);

  return (
    <Container>
      <SubTitle title="서명 설정" />
      <Content>
        <FormRow title="결재 서명 종류">
          <RadioContainer>
            <StyleInput
              {...signTypeField}
              type="radio"
              value={SignType.Image}
              id="image"
              minWidth="20px"
              checked={signTypeField.value === SignType.Image}
            />
            <label htmlFor="image">이미지</label>
          </RadioContainer>
          <RadioContainer>
            <StyleInput
              {...signTypeField}
              type="radio"
              value={SignType.Text}
              id="text"
              minWidth="20px"
              checked={signTypeField.value === SignType.Text}
            />
            <label htmlFor="text">텍스트</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="서명 등록" minHeight="150px">
          <SignFormContainer>
            {signTypeField.value === SignType.Text && (
              <DisplaySign>{signText}</DisplaySign>
            )}
            {signTypeField.value === SignType.Image && previewURLForSave && (
              <Img
                src={
                  previewURLForSave.includes("base64")
                    ? previewURLForSave
                    : `data:image/png;base64, ${previewURLForSave}`
                }
                alt="서명"
              />
            )}
            <ButtonContainer>
              <Button
                onClick={event => {
                  event.preventDefault();
                  handleOpenDialog(true);
                }}
              >
                변경
              </Button>
              <Button
                disabled={isDeleteButton}
                onClick={event => {
                  event.preventDefault();
                  handleConfirmMessage({
                    title: `${signTypeTitle} 삭제`,
                    p: `${signTypeTitle}를 삭제하시겠습니까?`,
                    messageTypes: MessageTypes.INFO
                  });
                  handleConfirmIsOpen(true);
                }}
              >
                삭제
              </Button>
            </ButtonContainer>
          </SignFormContainer>
        </FormRow>
      </Content>
      {isOpenDialog && (
        <AsonicDialog
          title={"서명 업로드"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
          width="420px"
          minWidth="420px"
          height={previewURL ? "234px" : "150px"}
          minHeight={previewURL ? "240px" : "150px"}
        >
          <InputFileContainer>
            {signTypeField.value === SignType.Text && (
              <FormRow title="서명">
                <StyleInput {...signTextField} />
              </FormRow>
            )}
            {signTypeField.value === SignType.Image && previewURL && (
              <Img src={previewURL} alt="서명" />
            )}
            {signTypeField.value === SignType.Image && (
              <InputFile
                onChange={handleSelectedFile}
                id="imageFile"
                type="file"
                accept="image/png, image/jpeg"
                name="sign"
              />
            )}
            <ButtonContainer>
              <Button onClick={handleSave} customMinWidth="50px">
                저장
              </Button>
              <Button
                onClick={() => {
                  handleOpenDialog(false);
                }}
                customMinWidth="50px"
              >
                닫기
              </Button>
            </ButtonContainer>
          </InputFileContainer>
        </AsonicDialog>
      )}
      {confirmIsOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleConfirmIsOpen}
          handleConfirm={handleDelete}
        />
      )}
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
}

export default ApprovalSignSetting;
