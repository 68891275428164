import { useState, useCallback } from "react";

const useIsOpenHook = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = useCallback(value => {
    setIsOpen(value);
  }, []);

  return { isOpen, handleIsOpen };
};

export default useIsOpenHook;
