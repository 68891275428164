import * as ReactTable from "react-table";
import styled from "styled-components";
import { colors } from "../GlobalStyle/GlobalStyle";

export interface ISummaryRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}

export interface ISummaryRowProps<P extends {}> {
  prepareRow: (row: ISummaryRow<P>) => void;
  handleSelectRow?: (row?: ISummaryRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: ISummaryRow<P>;
  flexIndex?: number;
}

interface IRenderRow<P extends {}> {
  data: ISummaryRow<P>[];
  index: number;
  style: object;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Content = styled.div<{ isFlex?: boolean; isLastFlex?: boolean }>`
  display: flex !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: ${props => (props.isFlex ? "1" : "none")};
  :last-child {
    flex: ${props => (props.isLastFlex ? "1" : "none")};
  }
`;

function SummaryRenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  flexIndex
}: ISummaryRowProps<P>) {
  return ({ index, data, style }: IRenderRow<P>) => {
    const row: ISummaryRow<P> = data[index];
    prepareRow(row);
    const backgroundColor = row.isSelected ? colors.green : "none";
    const color = row.isSelected ? colors.white : colors.black;
    const initStyle = { ...style, backgroundColor, color };
    const multiSelect = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const contextButton = 2;
      if (event.type === "contextmenu" || event.button === contextButton) {
        event.preventDefault();
      }
      if (row.toggleRowSelected) {
        if (
          row.isSelected &&
          (event.type !== "contextmenu" || event.button !== contextButton)
        ) {
          data.forEach(item => {
            if (item.isSelected && item.toggleRowSelected) {
              item.toggleRowSelected(false);
            }
          });
          row.toggleRowSelected(false);
        } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
          row.toggleRowSelected(true);
          if (
            (event.type === "contextmenu" || event.button === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
          }
        } else if (selectedRow && event.shiftKey) {
          data.forEach(item => {
            if (
              selectedRow.id < item.id &&
              item.id <= row.id &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              } else if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
            if (
              item.id < selectedRow.id &&
              row.id <= item.id &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              }
            }
            if (
              (event.type !== "contextmenu" ||
                event.buttons !== contextButton) &&
              handleSelectRow
            ) {
              handleSelectRow(row);
            }
          });
        } else {
          if (selectedRow) {
            data.forEach(item => {
              if (item.isSelected && item.toggleRowSelected) {
                item.toggleRowSelected(false);
              }
            });
          }
          if (
            (event.type !== "contextmenu" || event.buttons !== contextButton) &&
            handleSelectRow
          ) {
            row.toggleRowSelected(true);
            handleSelectRow(row);
          }
          if (
            (event.type === "contextmenu" || event.buttons === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
            row.toggleRowSelected(true);
          }
        }
      }
    };
    return (
      <Container {...row.getRowProps({ style: initStyle })}>
        {row.cells.map((cell, rowIndex) => (
          <Content
            {...cell.getCellProps()}
            key={`${index} ${Math.random() * 10}`}
            id={`${data[index].id}-context-menu`}
            onContextMenu={multiSelect}
            onClick={multiSelect}
            isFlex={flexIndex === rowIndex}
            isLastFlex={flexIndex && flexIndex >= 0 ? true : false}
          >
            {cell.render("Cell")}
          </Content>
        ))}
      </Container>
    );
  };
}

export default SummaryRenderRow;
