import HelpContainer from "../../../../shared/help_container/help_container";
import InfoIcon from "../../../../shared/info-icon/info-icon";

function CWorkTemplateHelp() {
  return (
    <InfoIcon
      minHeight="760px"
      height="760px"
      minWidth="400px"
      title="선택 근무시간제-근무템플릿"
    >
      <HelpContainer>
        <pre>{`선택 가능한 근무 템플릿 목록의 선택 및 근무 템플릿 편집을 할수 있습니다.`}</pre>
        <ul>
          <li>{`근무 템플릿 목록`}</li>
          <pre>{`현재 작성되어 있는 근무 템플릿의 목록을 확인 할수 있습니다.`}</pre>
          <li>{`근무 템플릿 적용 여부 체크`}</li>
          <pre>{`근무 템플릿의 맨 앞에 있는 체크박스를 활성화 하면 근무 템플릿이 
적용됩니다. 근무자는 적용된 근무템플릿만 선택이 가능합니다.`}</pre>
          <li>{`신규 근무 템플릿 추가`}</li>
          <pre>{`근무 템플릿 목록의 오른쪽 상단의 + 버튼을 누르면 신규 근무 템플
릿 작성이 가능합니다.`}</pre>
          <li>{`근무 템플릿 수정 및 삭제`}</li>
          <pre>{`목록의 원하는 근무 템플릿을 선택하면 하단에 선택된 근무 템플릿의 
정보가 보이며 편집이 가능하며 삭제 버튼을 눌럭 삭제 하실수 있습니
다.`}</pre>
          <li>{`근무 템플릿 설정 정보`}</li>
          <ul>
            <li>{`운영 기간 설정`}</li>
            <pre>{`해당 근무 템플릿을 사용할수 있는 기간을 설정 합니다. 설정된 운
영기간이 만료되면, 해당 근무템플릿은 적용되지 않으며 사전에 설
정된 기본 근무 템플릿으로 변경됩니다. 상시 적용시에는 만료되지 
않습니다.`}</pre>
            <li>{`근무 요일 설정`}</li>
            <pre>{`근무 요일을 지정하거나 근무자 자율 편성을 하도록 설정합니다.
근무요일 지정의 경우 근무자는 근무요일을 변경 할수 없습니다.`}</pre>
            <li>{`근무시간 설정`}</li>
            <pre>{`지정 시간 사용시 근무 템플릿에 적용된 근무시간이 적용되며 
근무자 자율 편성 선택시 근무자가 직접 근무 시간을 편성할수 
있으며, 자율 편성 가능 시간 대를 설정 하실수 있습니다.`}</pre>
            <li>{`의무 근무시간`}</li>
            <pre>{`근무시간 설정이 “근무자 자율 편성” 일때만 의무 근무시간 설정이 
가능하며, 사용시 근무자는 근무시간을 자율편성 할 때 해당 의무 
근무 시간을 포함하지 않는 경우 근무시간 자율 편성이 불가합니다.`}</pre>
            <li>{`휴게시간 설정`}</li>
            <pre>{`”지정 시간”으로 설정 하시는 경우 적용할 휴게시간을 지정 하실수 
있습니다. “근무자 자율편성”인 경우 근무자가 직접 휴게시간을 편
성하게 됩니다.`}</pre>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default CWorkTemplateHelp;
