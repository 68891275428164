import { combineReducers } from "@reduxjs/toolkit";
import signInReducer from "./sign-in/signInReducer";
import mainViewReducer from "./main-view/mainViewReducer";
import approvalReducer from "./main-view/approval/approvalReducer";
import requestReducer from "./main-view/request/requestReducer";
import categorySelectionReducer from "./main-view/categorySelectionReducer";

const reducer = combineReducers({
  signInReducer,
  mainViewReducer,
  approvalReducer,
  requestReducer,
  categorySelectionReducer
});

export default reducer;
