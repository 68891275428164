import styled from "styled-components";

interface IProps {
  pageType: string;
}

const APPROVAL_PAGE = "Approval";

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
`;

export const Item = styled.div`
  flex: 1;
`;

export const ItemContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  border: ${props =>
    props.pageType === APPROVAL_PAGE
      ? "2px solid #3d81e7"
      : "2px solid #55BF8C"};
  border-radius: 20px;
  -webkit-box-shadow: 0px 7px 9px -5px rgba(0, 0, 0, 0.86);
  -moz-box-shadow: 0px 7px 9px -5px rgba(0, 0, 0, 0.86);
  box-shadow: 0px 7px 9px -5px rgba(0, 0, 0, 0.86);
  :active {
    background-color: #30336b;
    color: white;
    border: 2px solid #686de0;
  }
`;

export const MainTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

export const Name = styled.span``;

export const Category = styled.span``;

export const Result = styled.span``;

export const DateTerm = styled.div`
  flex: 1;
  align-self: center;
  font-size: 12px;
`;
