import * as React from "react";
import AsonicCalendarWeekContainer from "../asonic-calendar-week";

interface IProps {
  weeks: DayInfo[][];
}

const AsonicListOfWeeks = <P extends IProps>({ weeks }: P) => {
  return (
    <React.Fragment>
      {weeks.map((week, index) => (
        <AsonicCalendarWeekContainer key={index} week={week} />
      ))}
    </React.Fragment>
  );
};

export default AsonicListOfWeeks;
