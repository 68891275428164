import { useCallback, useEffect, useMemo, useState } from "react";
import handleApolloErrorLog from "../../../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../../../Utils/handle_error_log/handle_error_log";
import {
  Break_Time_Type,
  Day_Type,
  Is_Use,
  Operating_Period_Type,
  OtSelfWorkingStandardHoursEntity,
  UserPolicySelectedInfoEntity,
  Work_Day_Of_Week,
  Work_Hours_Setting_Type,
  useGetUserPolicySelectedInfoLazyQuery,
  useSaveUserPolicySelectedInfoMutation
} from "../../../../../../generated/graphql";
import FormRow from "../../../../../shared/form-row/form-row";
import GridContentContainer from "../../../../../shared/grid_content_container/grid_content_container";
import SectionContainer from "../../../../../shared/section_container/section_container";
import SubTitle from "../../../../../shared/sub-title/sub-title";
import TitleContainer from "../../../../../shared/title_container/title_container";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../../types/reducers";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../../../../apollo/apollo-var";
import styled from "styled-components";
import Button from "../../../../../globalComponents/Button/Button";
import SelectTemplateDialog from "./select_template_dialog/select_template_dialog";
import AsonicDialog from "../../../../../asonic-dialog/asonic-dialog";
import useOpenDialog from "../../../../../../hooks/use-open-dialog/use-open-dialog";
import * as ReactTable from "react-table";
import RadioContainer from "../../../../../shared/radio_container/radio_container";
import StyleInput from "../../../../../inputs/style-input";
import SSelect from "../../../../../shared/s_select/s_select";
import useTimeList, {
  HOUR_TYPE
} from "../../../../../../hooks/use_time_list/use_time_list";
import useTimeControl from "../../../../../../hooks/use_time_control/use_time_control";
import ButtonContainer from "../../../../../shared/button_container/button_container";
import ToastMessage, {
  MessageTypes
} from "../../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../../hooks/toast-message-hook/use-open-toast-message";
import { Role } from "../../../../../../user-types";

const TemplateContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  align-items: center;
`;

function SelectedWorkingTemplateInfo() {
  const { isOpen, handleOpenDialog } = useOpenDialog();

  const {
    signInReducer: { loginIdInfo, employee_id }
  } = useSelector((state: Reducers) => state);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const { displayHours, makeHoursType, makeHours } = useTimeControl();
  const { hourList, minList, hourTypeList } = useTimeList();

  const [info, setInfo] = useState<UserPolicySelectedInfoEntity>();
  const [selectedTemplate, setSelectedTemplate] =
    useState<ReactTable.Row<OtSelfWorkingStandardHoursEntity>>();
  const [dayOfWeek, setDayOfWeek] = useState<Day_Type[]>([
    Day_Type.Mon,
    Day_Type.Tue,
    Day_Type.Wed,
    Day_Type.Thr,
    Day_Type.Fri
  ]);
  const [isDayOfWeekEnable, setIsDayOfWeekEnable] = useState<Is_Use>(
    Is_Use.UnUse
  );

  const [isWorkHoursEnable, setIsWorkHoursEnable] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [startTime, setStartTime] = useState<string>("09:00");
  const [endTime, setEndTime] = useState<string>("17:00");

  const [isBreakTimeTypeEnable, setIsBreakTimeTypeEnable] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [startBreakTime, setStartBreakTime] = useState<string>("12:00");
  const [endBreakTime, setEndBreakTime] = useState<string>("13:00");

  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const [saveUserPolicySelectedInfo] = useSaveUserPolicySelectedInfoMutation({
    onError(error) {
      handleApolloErrorLog(error);
      handleToast(
        "근무자 선택정보를 저장하지 못했습니다.",
        MessageTypes.WARNING
      );
    },
    update(_, { data }) {
      if (data?.saveUserPolicySelectedInfo.ok) {
        handleToast(
          "성공적으로 근무자 선택정보를 저장하였습니다.",
          MessageTypes.SUCCESS
        );
      }
      if (
        !data?.saveUserPolicySelectedInfo.ok &&
        data?.saveUserPolicySelectedInfo.error
      ) {
        handleErrorLog(data?.saveUserPolicySelectedInfo.error);
        handleToast(data.saveUserPolicySelectedInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const [getUserPolicySelectedInfo] = useGetUserPolicySelectedInfoLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getUserPolicySelectedInfo.ok &&
        data.getUserPolicySelectedInfo.info
      ) {
        setInfo(data.getUserPolicySelectedInfo.info);
      } else if (
        !data.getUserPolicySelectedInfo.ok &&
        data.getUserPolicySelectedInfo.error
      ) {
        handleErrorLog(data.getUserPolicySelectedInfo.error);
      }
    }
  });

  const operatingPeriodStartDate = useMemo(() => {
    if (
      selectedTemplate?.original.operatingPeriodType ===
      Operating_Period_Type.Always
    ) {
      return "상시적용";
    }

    return selectedTemplate?.original.operatingPeriodStartDate
      ? `${selectedTemplate?.original.operatingPeriodStartDate} ~ ${selectedTemplate?.original.operatingPeriodEndDate}`
      : info?.workingPeriod;
  }, [selectedTemplate]);

  const handleSave = useCallback(() => {
    const workingTemplateIdx = selectedTemplate?.original.workingTemplateIdx;
    const workingTimeName = selectedTemplate?.original.workingTimeName;
    if (employee_id && workingTemplateIdx && workingTimeName) {
      let employeeId = employee_id;
      if (selectedListOfEmployeeId.length > 0) {
        employeeId = selectedListOfEmployeeId.at(-1) as string;
      }
      saveUserPolicySelectedInfo({
        variables: {
          employeeId,
          workingTemplateIdx,
          workingTimeName,
          dayOfWeek,
          startTime,
          endTime,
          startBreakTime,
          endBreakTime
        }
      });
    }
  }, [
    saveUserPolicySelectedInfo,
    selectedTemplate,
    selectedListOfEmployeeId,
    loginIdInfo,
    employee_id,
    dayOfWeek,
    startTime,
    endTime,
    startBreakTime,
    endBreakTime
  ]);

  useEffect(() => {
    if (selectedTemplate) {
      setIsDayOfWeekEnable(
        selectedTemplate.original.dayOfWeekType ===
          Work_Day_Of_Week.SelfForWorker
          ? Is_Use.Use
          : Is_Use.UnUse
      );
      setIsWorkHoursEnable(
        selectedTemplate.original.workHoursType ===
          Work_Hours_Setting_Type.SelfForWorker
          ? Is_Use.Use
          : Is_Use.UnUse
      );
      setIsBreakTimeTypeEnable(
        selectedTemplate.original.breakTimeType ===
          Break_Time_Type.SeparateSetting
          ? Is_Use.Use
          : Is_Use.UnUse
      );
      setStartTime(selectedTemplate.original.startTime);
      setEndTime(selectedTemplate.original.endTime);

      setStartBreakTime(selectedTemplate.original.startBreakTime);
      setEndBreakTime(selectedTemplate.original.endBreakTime);
    }
    if (!selectedTemplate && info) {
      setIsDayOfWeekEnable(info.isDayOfWeekEnable);
      setIsWorkHoursEnable(info.isWorkHoursEnable);
      setIsBreakTimeTypeEnable(info.isBreakTimeTypeEnable);
      if (info.startTime && info.endTime) {
        setStartTime(info?.startTime);
        setEndTime(info.endTime);
      }
      if (info.startBreakTime && info.endBreakTime) {
        setStartBreakTime(info.startBreakTime);
        setEndBreakTime(info.endBreakTime);
      }
    }
  }, [info, selectedTemplate]);

  useEffect(() => {
    if (loginIdInfo === Role.USER || selectedListOfEmployeeId.length < 1) {
      getUserPolicySelectedInfo({
        variables: {
          employeeId: employee_id
        }
      });
    } else if (selectedListOfEmployeeId.length > 0) {
      const employeeId = selectedListOfEmployeeId.at(-1);
      if (employeeId) {
        getUserPolicySelectedInfo({
          variables: {
            employeeId
          }
        });
      }
    }
  }, [
    getUserPolicySelectedInfo,
    selectedListOfEmployeeId,
    loginIdInfo,
    employee_id
  ]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"근무자 선택정보"} />
      </TitleContainer>
      <GridContentContainer flex={0}>
        <FormRow title="근무시간 템플릿 명" isCenter>
          <TemplateContainer>
            <span>
              {selectedTemplate?.original.workingTimeName ??
                info?.workingTimeName}
            </span>
            {loginIdInfo !== Role.USER && (
              <Button
                customMinWidth="70px"
                maxWidth="70px"
                onClick={() => {
                  handleOpenDialog(true);
                }}
              >{`변경`}</Button>
            )}
          </TemplateContainer>
        </FormRow>
        <FormRow title="근무시간템플릿 운영기간" isCenter>
          <span>{operatingPeriodStartDate}</span>
        </FormRow>
        <FormRow title={"근무 요일"} isCenter>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="monday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Mon)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Mon)) {
                    return list.filter(day => day !== Day_Type.Mon);
                  }
                  return [...list, Day_Type.Mon];
                });
              }}
            />
            <label htmlFor="monday">{`월`}</label>
            <StyleInput
              type="checkbox"
              id="tuesday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Tue)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Tue)) {
                    return list.filter(day => day !== Day_Type.Tue);
                  }
                  return [...list, Day_Type.Tue];
                });
              }}
            />
            <label htmlFor="tuesday">{`화`}</label>
            <StyleInput
              type="checkbox"
              id="wednesday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Wed)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Wed)) {
                    return list.filter(day => day !== Day_Type.Wed);
                  }
                  return [...list, Day_Type.Wed];
                });
              }}
            />
            <label htmlFor="wednesday">{`수`}</label>
            <StyleInput
              type="checkbox"
              id="thursday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Thr)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Thr)) {
                    return list.filter(day => day !== Day_Type.Thr);
                  }
                  return [...list, Day_Type.Thr];
                });
              }}
            />
            <label htmlFor="thursday">{`목`}</label>
            <StyleInput
              type="checkbox"
              id="friday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Fri)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Fri)) {
                    return list.filter(day => day !== Day_Type.Fri);
                  }
                  return [...list, Day_Type.Fri];
                });
              }}
            />
            <label htmlFor="friday">{`금`}</label>
            <StyleInput
              type="checkbox"
              id="saturday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Sat)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Sat)) {
                    return list.filter(day => day !== Day_Type.Sat);
                  }
                  return [...list, Day_Type.Sat];
                });
              }}
            />
            <label htmlFor="saturday">{`토`}</label>
            <StyleInput
              type="checkbox"
              id="sunday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Sun)}
              disabled={isDayOfWeekEnable === Is_Use.UnUse}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Sun)) {
                    return list.filter(day => day !== Day_Type.Sun);
                  }
                  return [...list, Day_Type.Sun];
                });
              }}
            />
            <label htmlFor="sunday">{`일`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="근무시간" isCenter>
          <SSelect
            name="startHourType"
            id="startHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartTime(newTime);
            }}
            disabled={isWorkHoursEnable === Is_Use.UnUse}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="startHour"
            id="startHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startTime.split(":")[0])}
            onChange={event => {
              const hours = startTime.split(":")[0];
              const minute = startTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartTime(`${parsedHours}:${minute}`);
            }}
            disabled={isWorkHoursEnable === Is_Use.UnUse}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startHour">{`시`}</label>
          <SSelect
            name="startMin"
            id="startMin"
            minWidth="50px"
            maxWidth="50px"
            value={startTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            disabled={isWorkHoursEnable === Is_Use.UnUse}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="endHourType"
            id="endHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndTime(newTime);
            }}
            disabled={isWorkHoursEnable === Is_Use.UnUse}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="endHour"
            id="endHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endTime.split(":")[0])}
            onChange={event => {
              const hours = endTime.split(":")[0];
              const minute = endTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndTime(`${parsedHours}:${minute}`);
            }}
            disabled={isWorkHoursEnable === Is_Use.UnUse}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endHour">{`시`}</label>
          <SSelect
            name="endMin"
            id="endMin"
            minWidth="50px"
            maxWidth="50px"
            value={endTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            disabled={isWorkHoursEnable === Is_Use.UnUse}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endMin">{`분`}</label>
        </FormRow>
        <FormRow title="휴게시간" isCenter>
          <SSelect
            name="startBreakTimeType"
            id="startBreakTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartBreakTime(newTime);
            }}
            disabled={isBreakTimeTypeEnable === Is_Use.UnUse}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="startBreakTime"
            id="startBreakTime"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startBreakTime.split(":")[0])}
            onChange={event => {
              const hours = startBreakTime.split(":")[0];
              const minute = startBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartBreakTime(`${parsedHours}:${minute}`);
            }}
            disabled={isBreakTimeTypeEnable === Is_Use.UnUse}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startBreakTimeType">{`시`}</label>
          <SSelect
            name="startBreakTimeMinute"
            id="startBreakTimeMinute"
            minWidth="50px"
            maxWidth="50px"
            value={startBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            disabled={isBreakTimeTypeEnable === Is_Use.UnUse}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startBreakTimeMinute">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="endBreakTimeType"
            id="endBreakTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndBreakTime(newTime);
            }}
            disabled={isBreakTimeTypeEnable === Is_Use.UnUse}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="endBreakTime"
            id="endBreakTime"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endBreakTime.split(":")[0])}
            onChange={event => {
              const hours = endBreakTime.split(":")[0];
              const minute = endBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndBreakTime(`${parsedHours}:${minute}`);
            }}
            disabled={isBreakTimeTypeEnable === Is_Use.UnUse}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endBreakTime">{`시`}</label>
          <SSelect
            name="endBreakTimeMinute"
            id="endBreakTimeMinute"
            minWidth="50px"
            maxWidth="50px"
            value={endBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            disabled={isBreakTimeTypeEnable === Is_Use.UnUse}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endBreakTimeMinute">{`분`}</label>
        </FormRow>
        <FormRow title="" isCenter></FormRow>
      </GridContentContainer>
      {isOpen && (
        <AsonicDialog
          title={`선택 근무가능시간`}
          height={"400px"}
          minHeight={"400px"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
        >
          <SelectTemplateDialog
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </AsonicDialog>
      )}
      {loginIdInfo !== Role.USER && (
        <ButtonContainer>
          <Button
            onClick={handleSave}
            disabled={!info && !selectedTemplate}
          >{`저장`}</Button>
        </ButtonContainer>
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </SectionContainer>
  );
}

export default SelectedWorkingTemplateInfo;
