import { GLOBAL_REDUCER_INIT_STATE } from "../../../constValues";

// TYPE
export const SIGNIN_FAILED = "SIGNIN_STATE_REDUCER/SIGNIN_FAILED";
export const SIGNIN_SUCCEED = "SIGNIN_STATE_REDUCER/SIGNIN_SUCCEED";
export const SET_USER_INFO = "SIGNIN_STATE_REDUCER/SET_USER_INFO";
export const CHANGE_USER_PASSWORD = "SIGNIN_STATE_REDUCER/CHANGE_USER_PASSWORD";
export const SET_SMTP = "SIGNIN_STATE_REDUCER/SET_SMTP";

let initialState = {
  name: "",
  approval_define: 0,
  department_id: 0,
  department_name: "",
  employee_id: "",
  my_picture: 0,
  delegaterInfo: null,
  approverInfo: null,
  smtpSetup: null,
  cclist: [],
  loginIdInfo: null,
  menuList: [],
  webMenuList: [],
  mobileMenuList: []
};

export const setSmtp = smtp => ({
  type: SET_SMTP,
  smtp
});

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  userInfo
});

export const changeUserPassword = passwordSet => ({
  type: CHANGE_USER_PASSWORD,
  passwordSet
});

export const cleanReducers = () => ({
  type: GLOBAL_REDUCER_INIT_STATE
});

export const signInActionCreator = {
  setSmtp,
  setUserInfo,
  changeUserPassword,
  cleanReducers
};

const signInReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...action.userInfo
      };
    case SET_SMTP:
      return {
        ...state,
        ...action.smtp
      };
    case GLOBAL_REDUCER_INIT_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default signInReducer;
