import styled from "styled-components";

import { IApprovalLineManagementTabs } from "../approval-setting/approval-process-management/approval-process-management";
import PersonalApprovalProcess from "./personal-approval-process";
import TreeOfApproval, { ITreeOfApprovalProps } from "./tree-of-approval";

interface IProps extends ITreeOfApprovalProps {
  selectedTab: IApprovalLineManagementTabs;
  handleSelectTab: (payload: IApprovalLineManagementTabs) => void;
  hiddenGroupTab?: boolean;
  hiddenApprovalTab?: boolean;
}

interface ITab {
  isSelected?: boolean;
}

enum ITabs {
  ORGANIZATION_CHART = "조직도",
  PERSONAL_APPROVAL_PROCESS = "개인결재선"
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  gap: 8px;
  padding: 0px 10px;
`;

const Tab = styled.div<ITab>`
  display: flex;
  background-color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.white};
  padding: 7px 20px 7px 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid ${props => props.theme.colors.green};
  color: ${props =>
    props.isSelected ? props.theme.colors.white : props.theme.colors.black};
  font-weight: bold;
  :hover {
    color: white;
    background-color: ${props => props.theme.colors.lightGreen};
  }
  user-select: none;
  cursor: pointer;
  :active {
    transform: scale(0.98);
  }
`;

const Content = styled.div`
  display: flex;
  flex: 10;
  border: 1px solid ${props => props.theme.colors.grey};
  min-height: 250px;
  max-height: 250px;
`;

function TreeOfApprovalProcess({
  handleSelectedDepartment,
  selectedTab,
  handleSelectTab,
  hiddenGroupTab = false,
  hiddenApprovalTab = false
}: IProps) {
  return (
    <Container>
      <TabContainer>
        {!hiddenGroupTab && (
          <Tab
            isSelected={selectedTab === ITabs.ORGANIZATION_CHART}
            onClick={() => {
              handleSelectTab(ITabs.ORGANIZATION_CHART);
            }}
          >
            {ITabs.ORGANIZATION_CHART}
          </Tab>
        )}
        {!hiddenApprovalTab && (
          <Tab
            isSelected={selectedTab === ITabs.PERSONAL_APPROVAL_PROCESS}
            onClick={() => {
              handleSelectTab(ITabs.PERSONAL_APPROVAL_PROCESS);
            }}
          >
            {ITabs.PERSONAL_APPROVAL_PROCESS}
          </Tab>
        )}
      </TabContainer>
      <Content>
        {selectedTab === ITabs.ORGANIZATION_CHART ? (
          <TreeOfApproval handleSelectedDepartment={handleSelectedDepartment} />
        ) : (
          <PersonalApprovalProcess />
        )}
      </Content>
    </Container>
  );
}

export default TreeOfApprovalProcess;
