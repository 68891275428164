import * as React from "react";
import moment from "moment";
import colors from "../../../../../style-sheet/colors";
import {
  Container,
  Section,
  ChildTitleBar,
  ChildTitle,
  TimeContainer,
  TimeDescription,
  PaintsTitle
} from "./styled";

type Time = {
  minutes: number;
  color: string;
};

type Props = {
  regular: Partial<Time>;
  extension: Partial<Time>;
};

const RemainTimeInformation = <P extends Props>({ regular, extension }: P) => {
  let regularHours = 0;
  let regularMinutes = 0;
  let extensionHours = 0;
  let extensionMinutes = 0;
  if (regular.minutes) {
    regularHours = Math.floor(
      moment.duration(regular.minutes, "minutes").asHours()
    );
    regularMinutes = regular.minutes - regularHours * 60;
  }
  if (extension.minutes) {
    extensionHours = Math.floor(
      moment.duration(extension.minutes, "minutes").asHours()
    );
    extensionMinutes = extension.minutes - extensionHours * 60;
  }
  return (
    <Container data-testid="remain-time-information">
      <Section>
        <ChildTitleBar>
          <PaintsTitle backGroundColor={regular.color} />
          <ChildTitle data-testid="regular-title">정규</ChildTitle>
        </ChildTitleBar>
        <TimeContainer
          backGroundColor={
            colors.personalDashBoard.todayWorkingHoursBox.labelBackgroundColor
          }
        >
          <TimeDescription data-testid="regular-time">{`${regularHours}h ${regularMinutes}m`}</TimeDescription>
        </TimeContainer>
      </Section>
      <Section>
        <ChildTitleBar>
          <PaintsTitle backGroundColor={extension.color} />
          <ChildTitle data-testid="extension-title">연장</ChildTitle>
        </ChildTitleBar>
        <TimeContainer
          backGroundColor={
            colors.personalDashBoard.todayWorkingHoursBox.labelBackgroundColor
          }
        >
          <TimeDescription data-testid="extension-time">{`${extensionHours}h ${extensionMinutes}m`}</TimeDescription>
        </TimeContainer>
      </Section>
    </Container>
  );
};

export default RemainTimeInformation;
