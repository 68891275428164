import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../globalComponents/IconButton";
import { Container } from "./styled";
import ReactTooltip from "react-tooltip";
import { colors } from "../../../../GlobalStyle/GlobalStyle";

type Props = {
  handleGoSuperAdminPage: () => void;
};

const SuperAdminMenuPresenter = <P extends Props>({
  handleGoSuperAdminPage
}: P) => {
  return (
    <Container onClick={handleGoSuperAdminPage}>
      <ReactTooltip type="success" />
      <IconButton
        icon={faUserCog}
        color={colors.lightMidNightBlue}
        hoverColor={colors.lightGreen}
        data-tip="Super Admin 설정"
      />
    </Container>
  );
};

export default SuperAdminMenuPresenter;
