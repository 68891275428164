import * as React from "react";
import personalApi from "../../../api/dashboard/personal-api";

type Data = {
  title: string;
  data: string;
  type?: string;
};

type Props = {
  userId: string;
};

const fiveMinutes = 300000;

const useVacationStatus = <P extends Props>({ userId }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [period, setPeriod] = React.useState<string>("");
  const [mainData, setMainData] = React.useState<Data[]>([]);
  const [subData, setSubData] = React.useState<Data[]>([]);

  const handleData = React.useCallback(async () => {
    try {
      const params = {
        userId
      };
      const { data } = await personalApi.getVacationStatus(params);

      const result = data[0];
      setPeriod(result?.asperio);

      const newMainData = [
        { title: "총 발생일", data: result?.tot_day ?? "0", type: "" },
        { title: "사용일수", data: result?.tot_usecount_1 ?? "0", type: "" },
        { title: "잔여일수", data: result?.tot_usecount_2 ?? "0", type: "" },
        { title: "예정일수", data: result?.tot_usecount_3 ?? "0", type: "" }
      ];
      const newSubData = [
        { title: "사용일수", data: result?.tot_usecount_4 ?? "0", type: "" }
      ];
      setMainData(newMainData);
      setSubData(newSubData);
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  React.useEffect(() => {
    if (isMount.current === false) {
      isMount.current = true;
      handleData();
    }
    return () => {
      isMount.current = false;
    };
  }, [handleData]);

  React.useEffect(() => {
    let timeId: NodeJS.Timeout;
    if (isMount.current) {
      timeId = setInterval(handleData, fiveMinutes);
    }
    return () => {
      clearInterval(timeId);
    };
  }, [handleData]);

  return { mainData, subData, period };
};

export default useVacationStatus;
