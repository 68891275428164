import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import {
  CountListOfWorkManagement,
  CountListOfWorkManagementVariables
} from "../../../../__generated__/CountListOfWorkManagement";
import {
  DownloadListOfWorkManagementExcel,
  DownloadListOfWorkManagementExcelVariables
} from "../../../../__generated__/DownloadListOfWorkManagementExcel";
import {
  ListOfWorkManagement,
  ListOfWorkManagementVariables,
  ListOfWorkManagement_listOfWorkManagement_list
} from "../../../../__generated__/ListOfWorkManagement";
import { downloadFileFromServer } from "../../statistics/Utils";
import * as ReactTable from "react-table";
import { SLink } from "../Content/styled";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import listOfApolloVar from "../../../../apollo/apollo-var";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../table_v2/table_v2";
import useNewSortBy from "../../../../hooks/use-new-sort-by/use-new-sort-by";
import TableLayoutFooter from "../../../table_layout/table_layout_footer";
import PageController from "../../../table/page_controller";
import CommuteViewHeader from "../commute-view-section/commute_view_header";
import { useSticky } from "react-table-sticky";
import { useSearchDateRange } from "../hooks";
import { TABS_DB_SCHEME_OBJECT } from "../logics";

enum COLUMN_FOR_VALUE {
  employeeId = "아이디",
  name = "성명",
  departName = "부서명",
  categoryName = "근무타입",
  startDate = "시작날짜",
  endDate = "종료날짜",
  startTime = "시작시간",
  endTime = "종료시간",
  startAddress = "시작위치",
  endAddress = "종료위치",
  startImage = "근무시작사진",
  endImage = "근무종료사진"
}

type TYPE_OF_WORK_MANAGEMENT = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_LIST_OF_WORK_MANAGEMENT = gql`
  query ListOfWorkManagement(
    $employeeIds: [String!]!
    $startDate: String!
    $endDate: String!
    $page: Float
    $take: Float
    $fieldSort: FieldSort
  ) {
    listOfWorkManagement(
      employeeIds: $employeeIds
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      fieldSort: $fieldSort
    ) {
      ok
      error
      list {
        employeeId
        name
        departName
        startDate
        startTime
        endDate
        endTime
        startAddress
        startGpsLat
        startGpsLong
        endAddress
        endGpsLat
        endGpsLong
        categoryId
        categoryName
        startImage
        endImage
      }
    }
  }
`;

const QUERY_COUNT_LIST_OF_WORK_MANAGEMENT = gql`
  query CountListOfWorkManagement(
    $employeeIds: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    countListOfWorkManagement(
      employeeIds: $employeeIds
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_LIST_Of_WORK_MANAGEMENT_EXCEL = gql`
  query DownloadListOfWorkManagementExcel(
    $employeeIds: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    downloadListOfWorkManagementExcel(
      employeeIds: $employeeIds
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
      excel
    }
  }
`;

const ADDRESS_URL = "https://www.google.com/maps/search/?api=1&query=";

const title = "근무관리";
let prevEmployeeIdList: string[] = [];

function WorkViewSection() {
  const { signInReducer } = useSelector((state: Reducers) => state);
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName
  } = useFixedColumn();

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchStartDate,
    searchEndDate,
    saveSearchDate,
    retryRender,
    setRetryRender
  } = useSearchDateRange(TABS_DB_SCHEME_OBJECT.work, title);

  const {
    oldFieldSort,
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useNewSortBy();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const [getListOfWorkManagement, { data, loading }] = useLazyQuery<
    ListOfWorkManagement,
    ListOfWorkManagementVariables
  >(QUERY_LIST_OF_WORK_MANAGEMENT, {
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (!data.listOfWorkManagement.ok && data.listOfWorkManagement.error) {
        console.log(data.listOfWorkManagement.error);
      }
    }
  });

  const [countListOfWorkManagement, { data: countData }] = useLazyQuery<
    CountListOfWorkManagement,
    CountListOfWorkManagementVariables
  >(QUERY_COUNT_LIST_OF_WORK_MANAGEMENT);

  const fetchCountListOfWorkManagement = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      countListOfWorkManagement({
        variables: {
          employeeIds: selectedListOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate
        }
      });
    }
  }, [
    countListOfWorkManagement,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate
  ]);

  const fetchListOfWorkManagement = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getListOfWorkManagement({
        variables: {
          employeeIds: selectedListOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate,
          page: currentPage,
          take,
          fieldSort: oldFieldSort
        }
      });
    }
  }, [
    getListOfWorkManagement,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate,
    currentPage,
    take,
    oldFieldSort
  ]);

  if (
    prevEmployeeIdList.length !== selectedListOfEmployeeId.length ||
    prevEmployeeIdList.some(
      (id, index) => id !== selectedListOfEmployeeId[index]
    ) ||
    retryRender
  ) {
    prevEmployeeIdList = [...selectedListOfEmployeeId];
    fetchCountListOfWorkManagement();
    fetchListOfWorkManagement();
    if (retryRender) {
      setRetryRender(false);
    }
  }

  const [downloadListOfWorkManagement] = useLazyQuery<
    DownloadListOfWorkManagementExcel,
    DownloadListOfWorkManagementExcelVariables
  >(QUERY_DOWNLOAD_LIST_Of_WORK_MANAGEMENT_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfWorkManagementExcel.ok &&
        data.downloadListOfWorkManagementExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfWorkManagementExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-workManagement.csv`
        );
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId) {
      downloadListOfWorkManagement({
        variables: {
          employeeIds: selectedListOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate
        }
      });
    }
  }, [
    downloadListOfWorkManagement,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate
  ]);

  const handleAddress = useCallback(
    (cell: ReactTable.Cell<ListOfWorkManagement_listOfWorkManagement_list>) => {
      if (cell.column.Header === COLUMN_FOR_VALUE.startAddress) {
        const address = `${ADDRESS_URL}${cell.row.original.startGpsLat},${cell.row.original.startGpsLong}`;
        const title = "시작 위치 보기";
        return (
          <SLink href={`${address}`} target="_blank">
            {title}
          </SLink>
        );
      }
      if (cell.column.Header === COLUMN_FOR_VALUE.endAddress) {
        const address = `${ADDRESS_URL}${cell.row.original.endGpsLat},${cell.row.original.endGpsLong}`;
        const title = "종료 위치 보기";
        return (
          <SLink href={`${address}`} target="_blank">
            {title}
          </SLink>
        );
      }
    },
    []
  );

  const handlePictureAddress = useCallback(
    (cell: ReactTable.Cell<ListOfWorkManagement_listOfWorkManagement_list>) => {
      const IS_CLOUD = 1;
      const PROTOCOL = window.location.protocol;
      const HOST_NAME = window.location.hostname;
      let address = `${PROTOCOL}//${HOST_NAME}:`;
      let startImageAddress = cell.row.original.startImage ?? "";
      let endImageAddress = cell.row.original.endImage ?? "";

      const type =
        cell.column.Header === COLUMN_FOR_VALUE.startImage ? "start" : "end";
      if (signInReducer.isCloudServer.isCloudServer === IS_CLOUD) {
        address = `${PROTOCOL}//pic.${HOST_NAME}`;
        const findWords = "/uploads/";
        const isStartMatched = startImageAddress.match(findWords);
        const isEndMatched = endImageAddress.match(findWords);
        if (isStartMatched) {
          startImageAddress = startImageAddress.slice(isStartMatched["index"]);
        }
        if (isEndMatched) {
          endImageAddress = endImageAddress.slice(isEndMatched["index"]);
        }
      }
      let isAddress = false;
      let title = "사진이 없습니다";

      switch (type) {
        case "start":
          if (cell.row.original.startImage) {
            isAddress = true;
            title = "시작사진";
          }
          break;
        case "end":
          if (cell.row.original.endImage) {
            isAddress = true;
            title = "종료사진";
          }
          break;
        default:
          break;
      }

      switch (type) {
        case "start":
          address += startImageAddress;
          break;
        case "end":
          address += endImageAddress;
          break;
        default:
          break;
      }

      return (
        <>
          {isAddress ? (
            <SLink href={`${address}`} target="_blank">
              {title}
            </SLink>
          ) : (
            <div>{title}</div>
          )}
        </>
      );
    },
    [signInReducer]
  );

  const total = useMemo(() => {
    return countData?.countListOfWorkManagement.total ?? 0;
  }, [countData]);

  const columns: ReactTable.Column<ListOfWorkManagement_listOfWorkManagement_list>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let sticky = "";
        if (sFixedColumnNumber !== undefined) {
          if (fixedColumnName.length > 0) {
            fixedColumnName.forEach((fColumn, fColumnIndex) => {
              if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
                sticky = "left";
              }
            });
          }
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_WORK_MANAGEMENT],
          accessor: item as TYPE_OF_WORK_MANAGEMENT,
          width: 120,
          Cell(
            cell: ReactTable.Cell<ListOfWorkManagement_listOfWorkManagement_list>
          ) {
            if (
              cell.column.Header === COLUMN_FOR_VALUE.startAddress ||
              cell.column.Header === COLUMN_FOR_VALUE.endAddress
            ) {
              return handleAddress(cell);
            }
            if (
              cell.column.Header === COLUMN_FOR_VALUE.startImage ||
              cell.column.Header === COLUMN_FOR_VALUE.endImage
            ) {
              return handlePictureAddress(cell);
            }
            return cell.value;
          },
          sticky
        };
      });
    }, [
      handleAddress,
      handlePictureAddress,
      sFixedColumnNumber,
      fixedColumnName
    ]);

  const list: ListOfWorkManagement_listOfWorkManagement_list[] = useMemo(() => {
    return data?.listOfWorkManagement.list || [];
  }, [data]);

  const table = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const { moveColumn } = useDnd<ListOfWorkManagement_listOfWorkManagement_list>(
    {
      columns: table.visibleColumns,
      setColumnOrder: table.setColumnOrder,
      title: `${title}-for-ordering-column`,
      setFixedColumnName
    }
  );

  const selectedRow:
    | ReactTable.Row<ListOfWorkManagement_listOfWorkManagement_list>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  useEffect(() => {
    handleListOfColumnDisabled([
      "startImage",
      "endImage",
      "startAddress",
      "endAddress"
    ]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (!data?.listOfWorkManagement.ok && data?.listOfWorkManagement.error) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.listOfWorkManagement.error);
      handleIsToastMessageOpen(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsToastMessageOpen]);

  return (
    <Container>
      <CommuteViewHeader<ListOfWorkManagement_listOfWorkManagement_list>
        columns={
          table.columns as TColumn<ListOfWorkManagement_listOfWorkManagement_list>[]
        }
        table={table}
        setIsSearch={saveSearchDate}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                listOfColumnDisabled={listOfColumnDisabled}
                moveColumn={moveColumn}
                loading={loading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default WorkViewSection;
