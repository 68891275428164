import {
  ListItem,
  Title,
  Icon,
  ChildrenContainer,
  ItemChildren,
  ListContainer
} from "./styled";
import useCheckWebAuthFn from "../../../hooks/use-check-web-auth-fn";
import useLeftMenuList from "../../../hooks/use-left-menu-list";
import { useLocation } from "react-router-dom";
import { Icon as IconifyIcon } from "@iconify/react";
import PlusIcon from "@iconify/icons-mdi/plus-box";
import MinusIcon from "@iconify/icons-mdi/minus-box";
import styled from "styled-components";
import { useCallback, useState } from "react";

/*
- 작동방식 -
menuStatus와 workingAuthWeb값에 따라 메뉴가 보이고 안보이고 결정이 되어야 한다.
webMenuAuthList에서 비트연산 후 useLeftMenuList함수를 통해 
괸리자 메뉴중 webMenuAuthList의 값으로 새롭게 활성화될 메뉴를 만든다.
그후 menuStatus로 한번더 메뉴를 필터링해서 렌더링해준다.
*/

const IconContainer = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  :hover {
    color: white;
  }
  :active {
    transform: scale(0.94);
  }
`;

const LeftMenuList = props => {
  const { handleList, loginIdInfo, open, menuStatus, workingAuthWeb } = props;
  const [selectedMainMenu, setSelectedMainMenu] = useState();
  const { pathname } = useLocation();
  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: workingAuthWeb?.working_auth_web,
    webEtcFlag: menuStatus?.web_etc_flag
  });
  const { newMenu: listData } = useLeftMenuList({
    webMenuAuthList
  });

  const renderComponent = useCallback(
    (item, index) => (
      <ListContainer
        key={`${index} ${item.title}`}
        selected={item.title === selectedMainMenu}
        open={open}
      >
        <ListItem
          open={open}
          selected={
            item.pathname === pathname ||
            (item.children && item.children[0].pathname === pathname) ||
            (item.children && item.children[1].pathname === pathname) ||
            (item.children &&
              item.children[2] &&
              item.children[2].pathname === pathname)
          }
          onClick={event => {
            handleList(`${item.pathname}`)();
            if (item.title !== selectedMainMenu) {
              setSelectedMainMenu(item.title);
            } else {
              setSelectedMainMenu("");
            }
          }}
          title={item.title}
        >
          <Icon
            icon={item.icon}
            selected={item.pathname === pathname}
            size="lg"
          />
          {open && <Title>{item.title}</Title>}
          {open && item?.children && (
            <IconContainer
              onClick={event => {
                event.stopPropagation();
                if (item.title !== selectedMainMenu) {
                  setSelectedMainMenu(item.title);
                } else {
                  setSelectedMainMenu("");
                }
              }}
            >
              {selectedMainMenu === item.title ? (
                <IconifyIcon
                  icon={MinusIcon}
                  selected={item.pathname === pathname}
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <IconifyIcon
                  icon={PlusIcon}
                  selected={item.pathname === pathname}
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContainer>
          )}
        </ListItem>
        {item?.children && (
          <ChildrenContainer
            open={open}
            isOpen={item.title === selectedMainMenu}
          >
            {item.children &&
              item.children.map((child, number) => {
                if (
                  !child.isActive ||
                  (child.title === "관리자 통계" && !loginIdInfo) ||
                  (child.title === "DashBoard (팀장)" && !loginIdInfo)
                ) {
                  return "";
                }
                return (
                  <ItemChildren
                    key={`${child.title} ${number}`}
                    selected={child.pathname === pathname}
                    onClick={handleList(`${child.pathname}`)}
                  >
                    <Title>{`${child.title}`}</Title>
                  </ItemChildren>
                );
              })}
          </ChildrenContainer>
        )}
      </ListContainer>
    ),
    [handleList, loginIdInfo, open, pathname, selectedMainMenu]
  );

  const checkStatus = useCallback(
    (item, index) => {
      if (item.key && menuStatus[item.key] === 1 && item.isActive) {
        return renderComponent(item, index);
      } else if (!item.key && item.isActive) {
        return renderComponent(item, index);
      } else {
        // status 값이 2는 미사용이며, 다른 값은 통계 메뉴가 보여야함
        const isStatisticsMenu = item.key === "web_report_flag"; // 통계 메뉴인지 확인
        const isStatisticsMenuActive = menuStatus[item.key] !== 2; // 2는 미사용
        if (isStatisticsMenu && isStatisticsMenuActive) {
          return renderComponent(item, index);
        }
      }
    },
    [menuStatus, renderComponent]
  );

  return <>{menuStatus && listData.map(checkStatus)}</>;
};

export default LeftMenuList;
