import styled from "styled-components";
import InfoIcon from "../../../../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

function CBhelp() {
  return (
    <InfoIcon
      minHeight="200px"
      height="200px"
      minWidth="400px"
      title="선택 근무시간제-기본설정"
    >
      <HelpContainer>
        <pre>{`선택 근무제는 1주 40시 범위내에서 1일의 근무제를 자율적으로 조정하여 
근무 할수 있는 제도 이며, 근무자는 생성된 근무 템플릿의 설정 여부에 따라
근무 요일 및 근무 시간을 자율 선택 할수 있습니다.`}</pre>
        <ul>
          <li>{`휴게시간 설정`}</li>
          <pre>{`휴게시간은 최대 3개까지 사용 가능하며, 근무 템플릿에 지정 휴게시간
으로 적용하실수 있습니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default CBhelp;
