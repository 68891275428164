import * as React from "react";

export type Event = {
  summary: string;
  location?: string;
  description?: string;
  start: {
    date?: string;
    dateTime?: string;
    timeZone: string;
  };
  end: {
    date?: string;
    dateTime?: string;
    timeZone: string;
  };
  recurrence?: string[];
  reminders?: {
    useDefault?: boolean;
    overrides?: [
      { method: string; minutes: number },
      { method: string; minutes: number }
    ];
  };
};

interface Props {
  calendarId: string;
  eventData?: Event;
  eventId?: string;
  handleClose: (value: boolean) => void;
  handleIsRequestData?: (value: boolean) => void;
}

const useHandleEvent = <P extends Props>({
  calendarId,
  eventId,
  eventData,
  handleClose,
  handleIsRequestData
}: P) => {
  const handleInsertEvent = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const request = window.gapi.client.calendar.events.insert({
        calendarId,
        resource: eventData
      });
      request.execute(function (event: any) {
        console.log(`Event created: ${event.htmlLink}`);
      });
      handleClose(false);
      handleIsRequestData?.(true);
    },
    [calendarId, eventData, handleClose, handleIsRequestData]
  );

  const handleUpdateEvent = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const request = window.gapi.client.calendar.events.update({
        calendarId,
        eventId,
        resource: eventData
      });
      request.execute(function (event: any) {
        console.log(`Event updated: ${event.htmlLink}`);
      });
      handleClose(false);
      handleIsRequestData?.(true);
    },
    [calendarId, eventId, eventData, handleClose, handleIsRequestData]
  );

  const handleDeleteEvent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      const request = window.gapi.client.calendar.events.delete({
        calendarId,
        eventId
      });
      request.execute(function (event: any) {
        console.log(`Event deleted: ${event.htmlLink}`);
      });
      handleClose(false);
      handleIsRequestData?.(true);
    },
    [calendarId, eventId, handleClose, handleIsRequestData]
  );

  return { handleInsertEvent, handleUpdateEvent, handleDeleteEvent };
};

export default useHandleEvent;
