import routes from "../../routes";
import API from "../api";

export interface IUpdateWebMenuListParams {
  web_approval_flag: number;
  web_commutegps_flag: number;
  web_otmng_flag: number;
  web_report_flag: number;
}

export interface IUpdateAppMenuListParams {
  app_commute_flag: number;
  app_realtime_flag: number;
  app_webview_flag: number;
}
export interface IUpdateMenuListParams
  extends IUpdateWebMenuListParams,
    IUpdateAppMenuListParams {}

export type AlaramEventStatusParams = {
  alarmUse: number;
};

type DeleteAlaramEventParams = { settingIndex: number };

type AddAlarmEventParams = {
  targetDevice?: number;
  eventType: number;
  pushMin: number;
  title: string;
  descr: string;
  linkUrl?: string;
};

type UpdateAlarmEventPrams = DeleteAlaramEventParams & AddAlarmEventParams;

type UpdatePublicDeviceListParams = {
  employeeApptokenPublicAllow: number;
};

const updateMenuList = (params: IUpdateMenuListParams) =>
  API().put(`${routes.apiRoutes.settings.menuSet}`, params);

const updateWebMenuList = (params: IUpdateWebMenuListParams) =>
  API().put(`${routes.apiRoutes.settings.webMenuSet}`, params);

const updateAppMenuList = (params: IUpdateAppMenuListParams) =>
  API().put(`${routes.apiRoutes.settings.appMenuSet}`, params);

const updateWidgetMenuList = (params: { webStandardWidgetFlag: number }) =>
  API().put(`${routes.apiRoutes.settings.widget}`, params);

const getAlarmEventStatus = () =>
  API().get(routes.apiRoutes.settings.alarmEventStatus);

const updateAlarmEventStatus = (params: AlaramEventStatusParams) =>
  API().put(routes.apiRoutes.settings.alarmEventStatus, params);

const getAlarmEventData = () => API().get(routes.apiRoutes.settings.alarmEvent);

const addAlarmEvent = (params: AddAlarmEventParams) =>
  API().post(routes.apiRoutes.settings.alarmEvent, params);

const deleteAlarmEvent = (params: DeleteAlaramEventParams) =>
  API().delete(routes.apiRoutes.settings.alarmEvent, { data: params });

const updateAlarmEvent = (params: UpdateAlarmEventPrams) =>
  API().put(routes.apiRoutes.settings.alarmEvent, params);

const getPublicDeviceList = () =>
  API().get(routes.apiRoutes.settings.publicDevice);

const updatePublicDeviceList = (params: UpdatePublicDeviceListParams) =>
  API().put(routes.apiRoutes.settings.publicDevice, params);

const initPassword = (params: { selectedEmployeeId: string }) =>
  API().put(routes.apiRoutes.settings.initPassword, params);

const downloadLogAsZip = () =>
  API().get(routes.apiRoutes.settings.zip, {
    responseType: "arraybuffer"
  });

const settingsApi = {
  updateMenuList,
  updateWebMenuList,
  updateAppMenuList,
  updateWidgetMenuList,
  getAlarmEventStatus,
  updateAlarmEventStatus,
  getAlarmEventData,
  addAlarmEvent,
  deleteAlarmEvent,
  updateAlarmEvent,
  getPublicDeviceList,
  updatePublicDeviceList,
  initPassword,
  downloadLogAsZip
};
export default settingsApi;
