import uiString from "./string.json";

export const initialQueryContent = {
  title: uiString.textFields.noSelection,
  description: uiString.textFields.noSelection,
  base_query: uiString.textFields.noSelection,
  employees: [],
  departments: [],
  approval_result: "",
  start_date: "",
  end_date: "",
  publish: 0
};

export const queryContentReducer = (state, action) => {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.title
      };
    case "description":
      return {
        ...state,
        description: action.description
      };
    case "base_query":
      return {
        ...state,
        base_query: action.base_query
      };
    case "employees":
      return {
        ...state,
        employees: action.employees
      };
    case "departments":
      return {
        ...state,
        departments: action.departments
      };
    case "approval_result":
      return {
        ...state,
        approval_result: action.approval_result
      };
    case "start_date":
      return {
        ...state,
        start_date: action.start_date
      };
    case "end_date":
      return {
        ...state,
        end_date: action.end_date
      };
    case "publish":
      return {
        ...state,
        publish: action.publish
      };
    default:
      throw new Error();
  }
};

export const downloadFileFromServer = (csvData, filename) => {
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=UTF-8"
  });
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    let a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
    a.download = filename;
    document.body.appendChild(a);
    // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    a.click();
    document.body.removeChild(a);
  }
};
