import { useContext } from "react";
import styled from "styled-components";
import useCalendarEventList from "../../../../../hooks/calendar-hooks/use-calendar-event-list/use-calendar-event-list";
import MiniCalendarContext from "../../mini-calendar-context/mini-calendar-context";

interface IProps {
  cell: DayInfo;
  isFirstWeek: boolean;
}

const Container = styled.div<{ selectedDate: boolean }>`
  display: flex;
  flex: 1;
  font-weight: bold;
  flex-direction: column;
  position: relative;
  border: ${props => (props.selectedDate ? "1px solid #0984e3" : "none")};
  :active {
    border: 1px solid #0984e3;
  }
`;

const TitleBarContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span<{ today: boolean; isCurrentMonth: boolean }>`
  display: flex;
  color: ${props => {
    if (props.today) {
      return "white";
    } else if (!props.isCurrentMonth) {
      return "rgba(0,0,0,0.3)";
    }
    return "black";
  }};
  background-color: ${props => (props.today ? "#0984e3" : "none")};
  border-radius: ${props => (props.today ? "50%" : "none")};
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
`;

const ShowCircleCheckedEvent = styled.div`
  border-radius: 50%;
  background-color: #eb4d4b;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 8%;
  left: 46%;
`;

const MiniCalendarCell = <P extends IProps>({ cell, isFirstWeek }: P) => {
  const {
    googleEventOfList,
    googleCalendarlist,
    listOfGoogleColor,
    currentMoment,
    handleSelectedEventList,
    selectedDate,
    handleSelectedDate
  } = useContext(MiniCalendarContext);
  const { eventList } = useCalendarEventList({
    googleCalendarlist,
    googleEventOfList,
    listOfGoogleColor,
    cellData: cell,
    currentMoment
  });
  return (
    <Container
      selectedDate={selectedDate === cell.fullDate}
      onClick={() => {
        if (handleSelectedEventList && handleSelectedDate) {
          handleSelectedEventList(eventList);
          handleSelectedDate(cell.fullDate);
        }
      }}
    >
      <TitleBarContainer>
        <TitleContainer>
          <Title today={cell.today} isCurrentMonth={cell.isCurrentMonth}>
            {cell.date}
          </Title>
        </TitleContainer>
      </TitleBarContainer>
      {eventList.length > 0 && <ShowCircleCheckedEvent />}
    </Container>
  );
};

export default MiniCalendarCell;
