import HelpContainer from "../../shared/help_container/help_container";
import InfoIcon from "../../shared/info-icon/info-icon";

function AbsenceManagementHelp() {
  return (
    <InfoIcon
      minHeight="490px"
      height="490px"
      minWidth="400px"
      title="이석관리"
    >
      <HelpContainer>
        <span>{`해당 근무제의 자리비움(이석) 기능을 설정합니다.`}</span>
        <ul>
          <li>{`이석(자리비움) 관리`}</li>
          <ul>
            <li>{`사유입력 설정`}</li>
            <ul>
              <li>
                <span>{`블랙스크린 적용 : 이석사유 입력시 블랙 스크린이 발생합니다.`}</span>
              </li>
              <li>
                <span>{`이동장소 입력 : 이석 사유 입력시 이동 장소도 입력 합니다.`}</span>
              </li>
              <li>
                <pre>{`참여자(동행자) 입력 : 이석 사유 입력시 이석 발생시 동행했던 
참여자를 입력합니다.`}</pre>
              </li>
            </ul>
          </ul>
          <li>{`자동 이석(자리비움) 관리`}</li>
          <ul>
            <li>{`자동 이석 감지 사용`}</li>
            <pre>{`사용시 이석 기준 시간 동안 사용자의 PC사용 이력이 없는 경우 
이석으로 자동 감지 합니다.`}</pre>
            <li>{`이석 기준시간`}</li>
            <span>{`이석으로 판단하는 기준 시간을 설정합니다.`}</span>
            <li>{`이석 해제 시 사유 입력`}</li>
            <span>{`이석 해제 시, 해당 근로자로부터 이석 발생 사유를 입력 받습니다.`}</span>
            <li>{`연장 근무시 이석`}</li>
            <pre>{`마지막 이석 발생 시작 시간을 연장 근무 종료 시간 시간으로 적용
하여 자동 퇴근 처리하는 기능의 사용 여부를 선택 합니다.`}</pre>
            <li>{`근무시간 이석 적용 여부`}</li>
            <pre>{`근무시간 중 이석을 적용할지를 선택합니다. 
사용 안함으로 설정하는 경우 시간외 근무에만 이석이 적용됩니다.`}</pre>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default AbsenceManagementHelp;
