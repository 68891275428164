import * as ReactTable from "react-table";
import styled from "styled-components";
import {
  AttendanceDetailsEntity,
  StatisticsConditionEntity,
  useGetAttendanceDetailsListExcelLazyQuery,
  useGetAttendanceDetailsListLazyQuery
} from "../../../../generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import { Cell } from "../../../../../types/@react-table/react-table/react-table";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import { IAsonicRow } from "../../../asonic-table/asonic-render-row";
import useNewSortBy from "../../../../hooks/use-new-sort-by/use-new-sort-by";
import { downloadFileFromServer } from "../Utils";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import AttendanceDetailsHeader from "./attendance_details_header";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../table_v2/table_v2";
import useDnd from "../../../../hooks/use-dnd/use-dnd";

export interface AttendanceDetailsEntityColumn extends AttendanceDetailsEntity {
  category?: string;
  attendancePlan?: string;
  attendanceRecognition?: string;
}

interface IProps {
  selectedRow: ReactTable.Row<StatisticsConditionEntity> | undefined;
}

const COLUMN_FOR_VALUE = {
  periodInformation: "기간",
  dateValue: "날짜",
  dayOfWeek: "요일",
  departName: "부서",
  employeeId: "사번",
  empName: "이름",
  workingTemplateName: "근무제",
  totPossibleMin: "소정",
  totWorkPossibleMin: "소정근무",
  useWorkMin: "소정근무",
  totWorkPossibleLeaveMin: "소정근무",
  totExtPossibleMin: "시간외",
  useExtMin: "시간외",
  totExtPossibleLeaveMin: "시간외",
  workStartTime: "출근시간",
  workEndTime: "퇴근시간",
  restMin: "휴게시간"
} as const;

type COLUMN_FOR_VALUE =
  (typeof COLUMN_FOR_VALUE)[keyof typeof COLUMN_FOR_VALUE];

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  flex-direction: column;
`;

const title = "근태상세";

function AttendanceDetails({ selectedRow }: IProps) {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useNewSortBy();

  const [getAttendanceDetailsList, { loading, data }] =
    useGetAttendanceDetailsListLazyQuery({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (data.getAttendanceDetailsList.error) {
          console.log(data.getAttendanceDetailsList.error);
        }
      }
    });

  const [getAttendanceDetailsListExcel] =
    useGetAttendanceDetailsListExcelLazyQuery({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log("=======근태상세 엑셀 에러");
        console.log(error);
      },
      onCompleted(data) {
        if (
          data.getAttendanceDetailsListExcel.ok &&
          data.getAttendanceDetailsListExcel.excel
        ) {
          downloadFileFromServer(
            data.getAttendanceDetailsListExcel.excel,
            `${moment().format(
              "YYYY-MM-DD-hh-mm-ss"
            )}_list_of_attendance_detail.csv`
          );
        }
      }
    });

  const downloadExcel = useCallback(() => {
    if (selectedRow) {
      const { periodInformation } = selectedRow.original;
      const startDate = moment(periodInformation.split("~")[0]).format(
        "YYYY-MM-01"
      );
      if (startDate) {
        getAttendanceDetailsListExcel({
          variables: {
            startDate,
            listOfDepartmentId: [],
            employeeId: selectedRow.original.employeeId
          }
        });
      }
    }
  }, [getAttendanceDetailsListExcel, selectedRow]);

  const columns: ReactTable.Column<AttendanceDetailsEntityColumn>[] =
    useMemo(() => {
      let newFixedColumnName: string[] = [];
      if (!isNaN(Number(selectedFixedColumnNumber))) {
        newFixedColumnName = fixedColumnName.slice(
          0,
          Number(selectedFixedColumnNumber)
        );
      }
      return [
        {
          Header: "기간",
          accessor: "periodInformation",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          width: 200,
          sticky: newFixedColumnName.includes("periodInformation") ? "left" : ""
        },
        {
          Header: "날짜",
          accessor: "dateValue",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("dateValue") ? "left" : ""
        },
        {
          Header: "요일",
          accessor: "dayOfWeek",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          width: 70,
          sticky: newFixedColumnName.includes("dayOfWeek") ? "left" : ""
        },
        {
          Header: "부서",
          accessor: "departName",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("departName") ? "left" : ""
        },
        {
          Header: "사번(ID)",
          accessor: "employeeId",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("employeeId") ? "left" : ""
        },
        {
          Header: "이름",
          accessor: "empName",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("empName") ? "left" : ""
        },
        {
          Header: "근무제",
          accessor: "workingTemplateName",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("workingTemplateName")
            ? "left"
            : ""
        },
        {
          Header: "소정 + 시간외(가능시간)",
          accessor: "totPossibleMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("totPossibleMin") ? "left" : ""
        },
        {
          Header: "소정근무(가능시간)",
          accessor: "totWorkPossibleMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("totWorkPossibleMin")
            ? "left"
            : ""
        },
        {
          Header: "소정근무(인정시간)",
          accessor: "useWorkMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("useWorkMin") ? "left" : ""
        },
        {
          Header: "소정근무(잔여시간)",
          accessor: "totWorkPossibleLeaveMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("totWorkPossibleLeaveMin")
            ? "left"
            : ""
        },
        {
          Header: "시간외(가능시간)",
          accessor: "totExtPossibleMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("totExtPossibleMin") ? "left" : ""
        },
        {
          Header: "시간외(인정시간)",
          accessor: "useExtMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("useExtMin") ? "left" : ""
        },
        {
          Header: "시간외(잔여시간)",
          accessor: "totExtPossibleLeaveMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("totExtPossibleLeaveMin")
            ? "left"
            : ""
        },
        {
          Header: "출근시간",
          accessor: "workStartTime",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("workStartTime") ? "left" : ""
        },
        {
          Header: "퇴근시간",
          accessor: "workEndTime",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("workEndTime") ? "left" : ""
        },
        {
          Header: "휴게시간",
          accessor: "restMin",
          Cell(cell: Cell<AttendanceDetailsEntity>) {
            return cell.value;
          },
          sticky: newFixedColumnName.includes("restMin") ? "left" : ""
        }
      ];
    }, [sFixedColumnNumber, fixedColumnName, fixedColumnNumber]);

  const list: AttendanceDetailsEntityColumn[] = useMemo(() => {
    return data?.getAttendanceDetailsList.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<AttendanceDetailsEntityColumn>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useColumnOrder,
    ReactTable.useRowSelect,
    useSticky
  );

  const { moveColumn } = useDnd<AttendanceDetailsEntityColumn>({
    columns: table.visibleColumns,
    setColumnOrder: table.setColumnOrder,
    title: `${title}-for-ordering-column`,
    setFixedColumnName
  });

  useEffect(() => {
    handleListOfColumnDisabled([
      "category",
      "attendancePlan",
      "attendanceRecognition"
    ]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    if (selectedRow) {
      const { periodInformation } = selectedRow.original;
      const startDate = moment(periodInformation.split("~")[0]).format(
        "YYYY-MM-01"
      );
      if (startDate) {
        getAttendanceDetailsList({
          variables: {
            startDate,
            listOfDepartmentId: [],
            employeeId: selectedRow.original.employeeId,
            fieldSort
          }
        });
      }
    }
  }, [selectedRow, fieldSort]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);

  return (
    <Container>
      <AttendanceDetailsHeader<AttendanceDetailsEntityColumn>
        columns={table.columns as TColumn<AttendanceDetailsEntityColumn>[]}
        table={table}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        count={list.length}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        setIsActiveFilter={setIsActiveFilter}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                listOfColumnDisabled={listOfColumnDisabled}
                moveColumn={moveColumn}
                loading={loading}
                isGroupColor
                // listOfFlexForHeader={["구분", "기간"]}
                // isFlexStart={true}
                // isLastFlex={false}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      {/* <GroupTable<AttendanceDetailsEntityColumn>
        title={"근태상세"}
        handleSelectRow={handleSelectRow}
        isLoading={loading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={chosenRow}
        handleFieldSort={handleFieldSort}
        fieldSort={fieldSort}
        columns={statisticsColumns as TColumn<AttendanceDetailsEntity>[]}
        toggleHideColumn={toggleHideColumn}
        listOfFlexForHeader={["기간"]}
        listOfColumnDisabled={listOfColumnDisabled}
        isFlexStart={true}
        isLastFlex={false}
        isGroupColor
        isGroup
      >
        <AsonicExcel downloadExcel={downloadExcel} />
      </GroupTable> */}
    </Container>
  );
}

export default AttendanceDetails;
