import {
  BasicInformation,
  WorkInformation
} from "../../../hooks/additional-information-management-hooks/use-additional-information-management-data";
import routes from "../../../routes";
import API from "../../api";

type getParams = {
  employeeId: string;
  applyYear: string;
};

type UpdateBasicInformationManagement = {} & Omit<BasicInformation, "name">;
type UpdateWorkInformationManagement = {} & Omit<
  WorkInformation,
  "useDay" | "leaveDay"
>;

const getAdditionalInformationManagement = (params: getParams) =>
  API().get(
    routes.apiRoutes.additionalInformationManagement.additionalInformationManagement(
      params.employeeId
    ),
    {
      params: {
        applyYear: params.applyYear
      }
    }
  );

const updateBasicInformationManagement = (
  params: UpdateBasicInformationManagement
) =>
  API().put(
    routes.apiRoutes.additionalInformationManagement
      .additionalInformationManagementOfBaseInfo,
    params
  );

const updateWorkInformationManagement = (
  params: UpdateWorkInformationManagement
) =>
  API().put(
    routes.apiRoutes.additionalInformationManagement
      .additionalInformationManagementOfWorkInfo,
    params
  );

const additionalInformationMangementApi = {
  getAdditionalInformationManagement,
  updateBasicInformationManagement,
  updateWorkInformationManagement
};

export default additionalInformationMangementApi;
