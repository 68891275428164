import styled from "styled-components";
import ListOfCompanies from "../components/annual-setting/list-of-companies";
import { CompanyEntity } from "../generated/graphql";
import { useCallback, useState } from "react";
import BasicAnnualLeaveSetting from "../components/annual-setting/baisc-annual-leave-setting";
import InfoMoreThanAYearSetting from "../components/annual-setting/info-more-than-a-year-setting";
import InfoLessThanAYearSetting from "../components/annual-setting/info-less-than-a-year-setting";
import InfoNextYearSetting from "../components/annual-setting/info-next-year-setting";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 15;
  padding: 20px;
  flex-direction: column;
`;

function AnnualSettingScreen() {
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyEntity | undefined
  >();
  const handleSelectedCompany = useCallback((company: CompanyEntity) => {
    setSelectedCompany(company);
  }, []);

  return (
    <Container>
      <ListOfCompanies handleSelectedCompany={handleSelectedCompany} />
      <Content>
        <BasicAnnualLeaveSetting selectedCompany={selectedCompany} />
        <InfoMoreThanAYearSetting selectedCompany={selectedCompany} />
        <InfoLessThanAYearSetting selectedCompany={selectedCompany} />
        <InfoNextYearSetting selectedCompany={selectedCompany} />
      </Content>
    </Container>
  );
}

export default AnnualSettingScreen;
