import styled from "styled-components";
import MRequestApprovalFormList from "./m_request_approval_form_list";
import { UserEntity } from "../../../../generated/graphql";
import MRequestApprovalDetail from "./m_request_approval_detail";
import MRequestApprovalLine from "./m_request_approval_line";
import MRequestApprovalRequest from "./m_request_approval_result";

interface IProps {
  page: number;
  user?: UserEntity;
  isAddApproval: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 10;
`;

function MRequestApprovalContent({ page, user, isAddApproval }: IProps) {
  return (
    <Container>
      {page === 1 && <MRequestApprovalFormList user={user} />}
      {page === 2 && <MRequestApprovalDetail />}
      {page === 3 && !isAddApproval && <MRequestApprovalLine />}
      {isAddApproval && <MRequestApprovalRequest />}
    </Container>
  );
}

export default MRequestApprovalContent;
