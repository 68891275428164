import styled from "styled-components";
import ControllerWithIconButton from "../controller-with-icon-button/controller-with-icon-button";
import * as ReactTable from "react-table";
import { GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list } from "../../__generated__/GetListOfVehicleDrivingInformation";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  DeleteVehicleDrivingInformation,
  DeleteVehicleDrivingInformationVariables
} from "../../__generated__/DeleteVehicleDrivingInformation";
import useConfirmDialog from "../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import { MessageTypes } from "../toast-message/toast-message";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddVehicleDrivingInformation,
  AddVehicleDrivingInformationVariables
} from "../../__generated__/AddVehicleDrivingInformation";
import AsonicDialog from "../asonic-dialog/asonic-dialog";
import useOpenDialog from "../../hooks/use-open-dialog";
import { useForm } from "react-hook-form";
import TextInputWithLabel from "../inputs/text-input-with-label";
import Button from "../globalComponents/Button";
import { GetListOfVehicleInformation_getListOfVehicleInformation_list } from "../../__generated__/GetListOfVehicleInformation";
import NativeSelect from "../native-select";
import { GetListOfOtTimeTypeCategoryForVehicle } from "../../__generated__/GetListOfOtTimeTypeCategoryForVehicle";
import moment from "moment";
import {
  GetUser,
  GetUserVariables,
  GetUser_getUser_user
} from "../../__generated__/GetUser";
import SearchInput from "../inputs/search-input";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import ProfileCard from "../profile-card/profile-card";
import {
  UpdateVehicleDrivingInformation,
  UpdateVehicleDrivingInformationVariables
} from "../../__generated__/UpdateVehicleDrivingInformation";
import ReactLoading from "react-loading";
import { colors } from "../GlobalStyle/GlobalStyle";
import { QUERY_GET_USER } from "../../apollo/common-gql";

enum START_OR_END {
  START,
  END
}
interface IProps {
  handleToast: (message: string, type: MessageTypes) => void;
  selectedRow?: ReactTable.Row<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>;
  selectedVehicle?: ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>;
  isDisableZIndex: boolean;
}

interface FieldValues {
  startEmployeeName: string;
  endEmployeeName: string;
  vehicleName: string;
  vehicleNumber: string;
  startEmployeeId: string;
  endEmployeeId: string;
  startKm: string;
  endKm: string;
  startDateTime: string;
  endDateTime: string;
  startGpsAddress: string;
  endGpsAddress: string;
  categoryId: string;
}

const Container = styled.div<{ isDisableZIndex: boolean }>`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  right: 10px;
  bottom: 10px;
  min-width: max-content;
  min-height: max-content;
  gap: 10px;
  z-index: ${props => (props.isDisableZIndex ? "0" : "2")};
`;

const ControllerDialogContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex: 3;
  gap: 20px;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  gap: 10px;
`;

const FormSection = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  div {
    flex: none;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 25px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const ListContainer = styled.div`
  display: flex;
  flex: 3;
  min-height: 200px;
  box-shadow: 0px 0px 0px 1px ${props => props.theme.borderColor};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  flex: 1;
`;

const RenderContainer = styled.div<{ isSelected: boolean; type: START_OR_END }>`
  display: flex;
  align-items: center;
  div {
    margin: 0px 10px;
    cursor: pointer;
    background-color: ${props => {
      if (props.isSelected) {
        if (props.type === START_OR_END.START) {
          return props.theme.colors.skyBlue;
        }
        return props.theme.colors.tomato;
      }
      return props.theme.colors.white;
    }};
    :hover {
      border-color: ${props => {
        if (props.type === START_OR_END.START) {
          return props.theme.colors.veryDarkBlue;
        }
        return props.theme.colors.darkRed;
      }};
    }
  }
`;

const MUTATION_DELETE_VEHICLE_DRIVING_INFORMATION = gql`
  mutation DeleteVehicleDrivingInformation($listOfLogIdx: [Int!]!) {
    deleteVehicleDrivingInformation(listOfLogIdx: $listOfLogIdx) {
      ok
      error
    }
  }
`;

const MUTATION_ADD_VEHICLE_DRIVING_INFORMATION = gql`
  mutation AddVehicleDrivingInformation(
    $vehicleNumber: String!
    $startEmployeeId: String!
    $endEmployeeId: String!
    $startKm: Int!
    $endKm: Int!
    $startDateTime: String!
    $endDateTime: String!
    $startGpsAddress: String!
    $endGpsAddress: String!
    $categoryId: Int!
  ) {
    addVehicleDrivingInformation(
      vehicleNumber: $vehicleNumber
      startEmployeeId: $startEmployeeId
      endEmployeeId: $endEmployeeId
      startKm: $startKm
      endKm: $endKm
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      startGpsAddress: $startGpsAddress
      endGpsAddress: $endGpsAddress
      categoryId: $categoryId
    ) {
      ok
      error
      logIdx
    }
  }
`;

const QUERY_GET_LIST_OF_OT_TIME_CATEGORY_FOR_VEHICLE = gql`
  query GetListOfOtTimeTypeCategoryForVehicle {
    getListOfOtTimeTypeCategoryForVehicle {
      ok
      error
      list {
        categoryId
        name
      }
    }
  }
`;

const MUTATION_UPDATE_VEHICLE_DRIVING_INFORMATION = gql`
  mutation UpdateVehicleDrivingInformation(
    $logIdx: Int!
    $startEmployeeId: String!
    $endEmployeeId: String!
    $startKm: Int!
    $endKm: Int!
    $startDateTime: String!
    $endDateTime: String!
    $startGpsAddress: String!
    $endGpsAddress: String!
    $categoryId: Int!
  ) {
    updateVehicleDrivingInformation(
      logIdx: $logIdx
      startEmployeeId: $startEmployeeId
      endEmployeeId: $endEmployeeId
      startKm: $startKm
      endKm: $endKm
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      startGpsAddress: $startGpsAddress
      endGpsAddress: $endGpsAddress
      categoryId: $categoryId
    ) {
      ok
      error
    }
  }
`;

function DrivingInformationController({
  selectedRow,
  selectedVehicle,
  handleToast,
  isDisableZIndex
}: IProps) {
  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();
  const {
    isOpen: isOpenUpdateDialog,
    handleOpenDialog: handleOpenDialogForUpdate
  } = useOpenDialog();
  const { register, handleSubmit, setValue, watch, getValues } =
    useForm<FieldValues>({
      mode: "onSubmit",
      defaultValues: {
        startDateTime: moment().format("YYYY-MM-DD[T]HH:mm"),
        endDateTime: moment().format("YYYY-MM-DD[T]HH:mm"),
        startKm: "0",
        endKm: "0"
      }
    });

  const [listOfStartUser, setListOfStartUser] = useState<
    GetUser_getUser_user[]
  >([]);

  const [listOfEndUser, setListOfEndUser] = useState<GetUser_getUser_user[]>(
    []
  );

  const [
    getStartUser,
    { data: startUserData, variables, loading: startLoading }
  ] = useLazyQuery<GetUser, GetUserVariables>(QUERY_GET_USER, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data.getUser.ok && data.getUser.user) {
        setListOfStartUser(data.getUser.user);
        if (data.getUser.user.length < 1) {
          handleToast(
            "검색하신 사용자가 존재하지 않습니다.",
            MessageTypes.WARNING
          );
        }
        if (variables?.getUserId && data.getUser.user[0].name) {
          setValue("startEmployeeId", variables.getUserId);
          setValue("startEmployeeName", data.getUser.user[0].name);
        }
      }
    }
  });

  const [
    getEndUser,
    { data: endUserData, variables: endVariables, loading: endLoading }
  ] = useLazyQuery<GetUser, GetUserVariables>(QUERY_GET_USER, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
    onCompleted(data) {
      if (data.getUser.ok && data.getUser.user) {
        setListOfEndUser(data.getUser.user);
        if (data.getUser.user.length < 1) {
          handleToast(
            "검색하신 사용자가 존재하지 않습니다.",
            MessageTypes.WARNING
          );
        }
        if (endVariables?.getUserId && data.getUser.user[0].name) {
          setValue("endEmployeeId", endVariables.getUserId);
          setValue("endEmployeeName", data.getUser.user[0].name);
        }
      }
    }
  });

  const handleSearchStartUser = useCallback(() => {
    const userName = getValues("startEmployeeName");
    if (userName.length < 2) {
      handleToast(
        "사용자 이름은 최소 2글자 이상 입력해주세요",
        MessageTypes.WARNING
      );
      return;
    }
    getStartUser({
      variables: {
        name: userName
      }
    }).then(({ data }) => {
      if (data?.getUser.ok && data.getUser.user) {
        setListOfStartUser(data.getUser.user);
      }
    });
  }, [getValues, getStartUser, handleToast]);

  const handleSearchEndUser = useCallback(() => {
    const userName = getValues("endEmployeeName");
    if (userName.length < 2) {
      handleToast(
        "사용자 이름은 최소 2글자 이상 입력해주세요",
        MessageTypes.WARNING
      );
      return;
    }
    getEndUser({
      variables: {
        name: userName
      }
    }).then(({ data }) => {
      if (data?.getUser.ok && data.getUser.user) {
        setListOfEndUser(data.getUser.user);
      }
    });
  }, [getValues, getEndUser, handleToast]);

  const { data: listOfCategoryData } =
    useQuery<GetListOfOtTimeTypeCategoryForVehicle>(
      QUERY_GET_LIST_OF_OT_TIME_CATEGORY_FOR_VEHICLE
    );

  const [updateVehicleDrivingInformation] = useMutation<
    UpdateVehicleDrivingInformation,
    UpdateVehicleDrivingInformationVariables
  >(MUTATION_UPDATE_VEHICLE_DRIVING_INFORMATION, {
    update(cache, { data }, { variables }) {
      if (data?.updateVehicleDrivingInformation.ok && variables) {
        const { startEmployeeName, endEmployeeName } = getValues();
        cache.modify({
          id: `VehicleDrivingInformationEntity:${variables.logIdx}`,
          fields: {
            startEmployeeName() {
              return startEmployeeName;
            },
            endEmployeeName() {
              return endEmployeeName;
            },
            startEmployeeId() {
              return variables.startEmployeeId;
            },
            endEmployeeId() {
              return variables.endEmployeeId;
            },
            startKm() {
              return variables.startKm;
            },
            endKm() {
              return variables.endKm;
            },
            startDateTime() {
              return moment(variables.startDateTime).format("YYYY-MM-DD HH:mm");
            },
            endDateTime() {
              return moment(variables.endDateTime).format("YYYY-MM-DD HH:mm");
            },
            startGpsAddress() {
              return variables.startGpsAddress;
            },
            endGpsAddress() {
              return variables.endGpsAddress;
            },
            categoryId() {
              return variables.categoryId;
            }
          }
        });
        handleToast(
          `차량번호: 차량을 성공적으로 수정하셨습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (data?.updateVehicleDrivingInformation.error) {
        handleToast(
          data?.updateVehicleDrivingInformation.error,
          MessageTypes.ERROR
        );
      }
      handleOpenDialogForUpdate(false);
    }
  });

  const handleUpdateVehicleDrivingInformation = useCallback(
    (data: FieldValues) => {
      const {
        startEmployeeId,
        endEmployeeId,
        startKm,
        endKm,
        startDateTime,
        endDateTime,
        startGpsAddress,
        endGpsAddress,
        categoryId
      } = getValues();

      const parseStartKm = parseInt(startKm);
      const parseEndKm = parseInt(endKm);
      if (parseStartKm < parseEndKm && selectedRow) {
        updateVehicleDrivingInformation({
          variables: {
            logIdx: selectedRow.original.logIdx,
            startEmployeeId,
            endEmployeeId,
            startKm: parseStartKm,
            endKm: parseEndKm,
            startDateTime,
            endDateTime,
            startGpsAddress,
            endGpsAddress,
            categoryId: parseInt(categoryId)
          }
        });
      }
    },
    [updateVehicleDrivingInformation, getValues, selectedRow]
  );

  const [addVehicleDrivingInformation] = useMutation<
    AddVehicleDrivingInformation,
    AddVehicleDrivingInformationVariables
  >(MUTATION_ADD_VEHICLE_DRIVING_INFORMATION, {
    update(cache, { data }, { variables }) {
      if (
        data?.addVehicleDrivingInformation.ok &&
        variables &&
        selectedVehicle &&
        data.addVehicleDrivingInformation.logIdx
      ) {
        const { startEmployeeName, endEmployeeName } = getValues();
        const [selectedCategory] = listOfCategory.filter(item => {
          return item.value === variables.categoryId;
        });

        const newVehicleDrivingInformation: GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list =
          {
            __typename: "VehicleDrivingInformationEntity",
            logIdx: data.addVehicleDrivingInformation.logIdx,
            vehicleName: selectedVehicle.original.vehicleName,
            startEmployeeName,
            endEmployeeName,
            categoryName: selectedCategory.title,
            ...variables
          };
        const newVehicleDrivingInformationCache = cache.writeFragment({
          id: `VehicleDrivingInformationEntity:${data.addVehicleDrivingInformation.logIdx}`,
          fragment: gql`
            fragment add on VehicleDrivingInformationEntity {
              __typename
              logIdx
              vehicleNumber
              vehicleName
              startEmployeeId
              startEmployeeName
              endEmployeeId
              endEmployeeName
              startKm
              endKm
              startDateTime
              endDateTime
              startGpsAddress
              endGpsAddress
              categoryName
              categoryId
            }
          `,
          data: {
            ...newVehicleDrivingInformation
          }
        });
        cache.modify({
          id: `GetTotalNumberOfVehicleDrivingInformationOutput`,
          fields: {
            total(prev) {
              if (prev) {
                return prev + 1;
              }
              return 1;
            }
          }
        });
        cache.modify({
          id: `GetListOfVehicleDrivingInformationOutput`,
          fields: {
            list(prev) {
              if (prev) {
                return [...prev, newVehicleDrivingInformationCache];
              }
              return [newVehicleDrivingInformationCache];
            }
          }
        });
        handleToast(
          `차량번호: ${variables.vehicleNumber}의 운행정보를 성공적으로 추가 하셨습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (data?.addVehicleDrivingInformation.error) {
        handleToast(
          data?.addVehicleDrivingInformation.error,
          MessageTypes.ERROR
        );
      }
    }
  });

  const [deleteVehicleDrivingInformation] = useMutation<
    DeleteVehicleDrivingInformation,
    DeleteVehicleDrivingInformationVariables
  >(MUTATION_DELETE_VEHICLE_DRIVING_INFORMATION, {
    update(cache, { data }, { variables }) {
      if (
        data?.deleteVehicleDrivingInformation.ok &&
        variables &&
        selectedRow
      ) {
        handleIsOpen(false);
        handleToast(
          `차량번호: ${selectedRow.original.vehicleNumber}의 운행정보를 성공적으로 삭제 하셨습니다.`,
          MessageTypes.SUCCESS
        );
        cache.evict({
          id: `VehicleDrivingInformationEntity:${variables.listOfLogIdx[0]}`
        });
        cache.modify({
          id: `GetTotalNumberOfVehicleDrivingInformationOutput`,
          fields: {
            total(prev) {
              if (prev) {
                return prev - 1;
              }
              return prev;
            }
          }
        });
      }
    }
  });

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isOpenConfirmDialog,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const handleAddVehicleDrivingInformation = useCallback(
    (data: FieldValues) => {
      const {
        vehicleNumber,
        startEmployeeId,
        endEmployeeId,
        startKm,
        endKm,
        startDateTime,
        endDateTime,
        startGpsAddress,
        endGpsAddress,
        categoryId
      } = getValues();

      const parseStartKm = parseInt(startKm);
      const parseEndKm = parseInt(endKm);
      if (parseStartKm < parseEndKm) {
        addVehicleDrivingInformation({
          variables: {
            vehicleNumber,
            startEmployeeId,
            endEmployeeId,
            startKm: parseStartKm,
            endKm: parseEndKm,
            startDateTime,
            endDateTime,
            startGpsAddress,
            endGpsAddress,
            categoryId: parseInt(categoryId)
          }
        });
      }
    },
    [addVehicleDrivingInformation, getValues]
  );

  const handleConfirm = useCallback(() => {
    if (selectedRow?.original.logIdx) {
      deleteVehicleDrivingInformation({
        variables: {
          listOfLogIdx: [selectedRow.original.logIdx]
        }
      });
    }
  }, [selectedRow, deleteVehicleDrivingInformation]);

  const { categoryId, startEmployeeId, endEmployeeId } = watch();

  const listOfCategory: {
    title: string;
    value: any;
  }[] = useMemo(() => {
    if (
      listOfCategoryData?.getListOfOtTimeTypeCategoryForVehicle.ok &&
      listOfCategoryData.getListOfOtTimeTypeCategoryForVehicle.list
    ) {
      return listOfCategoryData.getListOfOtTimeTypeCategoryForVehicle.list.map(
        item => ({ title: item.name || "", value: item.categoryId })
      );
    }
    return [];
  }, [listOfCategoryData]);

  const Row = useCallback(
    (type: START_OR_END) =>
      ({ index, style }: { index: number; style: object }) => {
        let user = listOfStartUser[index];
        if (type === START_OR_END.END) {
          user = listOfEndUser[index];
        }
        const userInfo = {
          employeeId: user.employeeId,
          name: user.name || "",
          department: user.fullDepartmentName || "",
          picture: user.myPicture || ""
        };
        return (
          <RenderContainer
            type={type}
            style={style}
            isSelected={
              (type === START_OR_END.START
                ? startEmployeeId
                : endEmployeeId) === user.employeeId
            }
          >
            <ProfileCard
              userInfo={userInfo}
              onClick={() => {
                if (type === START_OR_END.START) {
                  setValue("startEmployeeName", user.name || "");
                  setValue("startEmployeeId", user.employeeId);
                } else {
                  setValue("endEmployeeName", user.name || "");
                  setValue("endEmployeeId", user.employeeId);
                }
              }}
            ></ProfileCard>
          </RenderContainer>
        );
      },
    [listOfStartUser, startEmployeeId, endEmployeeId, setValue, listOfEndUser]
  );

  useEffect(() => {
    if (listOfCategory.length > 0) {
      setValue("categoryId", listOfCategory[0].value.toString());
    }
  }, [listOfCategory, setValue]);

  useEffect(() => {
    if (selectedRow && isOpenUpdateDialog) {
      getStartUser({
        variables: {
          getUserId: selectedRow.original.startEmployeeId
        }
      });
      if (selectedRow.original.endEmployeeId) {
        getEndUser({
          variables: {
            getUserId: selectedRow.original.endEmployeeId
          }
        });
      }
      setValue("startGpsAddress", selectedRow?.original?.startGpsAddress || "");
      setValue("startKm", selectedRow?.original?.startKm?.toString() || "");
      setValue(
        "startDateTime",
        moment(
          selectedRow?.original?.startDateTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD[T]HH:mm") || ""
      );
      setValue("endGpsAddress", selectedRow?.original?.endGpsAddress || "");
      setValue("endKm", selectedRow?.original?.endKm?.toString() || "");
      setValue(
        "endDateTime",
        moment(
          selectedRow?.original?.endDateTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD[T]HH:mm") || ""
      );
    }
  }, [selectedRow, isOpenUpdateDialog, getEndUser, getStartUser, setValue]);

  useEffect(() => {
    if (selectedVehicle) {
      handleConfirmMessage({
        title: `운행정보 삭제`,
        p: `차량번호: ${selectedVehicle.original.vehicleNumber}의 선택된 운행정보를 삭제하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
      setValue("vehicleName", selectedVehicle?.original.vehicleName || "");
      setValue("vehicleNumber", selectedVehicle?.original.vehicleNumber || "");
    }
  }, [handleConfirmMessage, selectedVehicle, setValue]);

  useEffect(() => {
    if (
      startUserData?.getUser.ok &&
      startUserData?.getUser.user &&
      listOfStartUser.length < 1 &&
      isOpenUpdateDialog
    ) {
      setListOfStartUser(startUserData?.getUser.user);
    }
  }, [startUserData, listOfStartUser, isOpenUpdateDialog]);

  useEffect(() => {
    if (
      endUserData?.getUser.ok &&
      endUserData.getUser.user &&
      listOfEndUser.length < 1 &&
      selectedRow &&
      isOpenUpdateDialog
    ) {
      if (selectedRow.original.endEmployeeId) {
        setListOfEndUser(endUserData?.getUser.user);
      }
    }
  }, [endUserData, listOfEndUser, isOpenUpdateDialog, selectedRow]);

  return (
    <Container isDisableZIndex={isDisableZIndex}>
      <ControllerWithIconButton
        isAddActive={!selectedVehicle}
        isEditActive={!selectedRow}
        isDeleteActive={!selectedRow}
        handleAddButton={() => {
          handleOpenDialog(true);
        }}
        handleEditButton={() => {
          handleOpenDialogForUpdate(true);
        }}
        handleDeleteButton={() => {
          handleIsOpen(true);
        }}
      />
      {(isOpenDialog || isOpenUpdateDialog) && (
        <AsonicDialog
          title={isOpenDialog ? "주행정보등록" : "주행정보수정"}
          handleClose={(value: boolean) => {
            setListOfStartUser([]);
            setListOfEndUser([]);
            if (isOpenDialog) {
              handleOpenDialog(value);
            } else if (isOpenUpdateDialog) {
              handleOpenDialogForUpdate(value);
            }
          }}
          height="500px"
          minHeight="500px;"
          width="900px"
          minWidth="900px"
        >
          <ControllerDialogContainer
            onSubmit={handleSubmit(
              isOpenDialog
                ? handleAddVehicleDrivingInformation
                : handleUpdateVehicleDrivingInformation
            )}
          >
            <Section>
              <ContentSection>
                <SearchContainer>
                  <SearchInput
                    title="출발 운전자 검색"
                    {...register("startEmployeeName")}
                    handleSearch={handleSearchStartUser}
                  />
                </SearchContainer>
                {startLoading ? (
                  <LoadingContainer>
                    <ReactLoading
                      type="spokes"
                      color={colors.darkBlue}
                      height={200}
                      width={170}
                    />
                  </LoadingContainer>
                ) : (
                  <ListContainer>
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={listOfStartUser.length}
                          itemData={listOfStartUser}
                          itemSize={75}
                          width={width}
                        >
                          {Row(START_OR_END.START)}
                        </List>
                      )}
                    </AutoSizer>
                  </ListContainer>
                )}

                <FormSection>
                  <TextInputWithLabel
                    required={true}
                    title="차량 번호"
                    {...register("vehicleNumber")}
                    minWidth="290px"
                    disabled={true}
                  />
                  <TextInputWithLabel
                    required={true}
                    title="출발지"
                    {...register("startGpsAddress")}
                    minWidth="290px"
                  />
                  <TextInputWithLabel
                    required={true}
                    title="출발 전 거리(KM)"
                    type="number"
                    {...register("startKm", {
                      min: 0,
                      required: true
                    })}
                    minWidth="290px"
                  />
                  <TextInputWithLabel
                    required={true}
                    title="출발 시간"
                    type="datetime-local"
                    {...register("startDateTime")}
                    minWidth="290px"
                  />
                  <NativeSelect
                    required={true}
                    title="사용 목적"
                    value={categoryId}
                    propFor="1"
                    datas={listOfCategory}
                    handleSelectedItem={event => {
                      setValue("categoryId", event.currentTarget.value);
                    }}
                    minWidth="298px"
                  />
                </FormSection>
              </ContentSection>
              <ContentSection>
                <SearchContainer>
                  <SearchInput
                    title="도착 운전자 검색"
                    {...register("endEmployeeName")}
                    handleSearch={handleSearchEndUser}
                  />
                </SearchContainer>
                {endLoading ? (
                  <LoadingContainer>
                    <ReactLoading
                      type="spokes"
                      color={colors.darkBlue}
                      height={200}
                      width={170}
                    />
                  </LoadingContainer>
                ) : (
                  <ListContainer>
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={listOfEndUser.length}
                          itemData={listOfEndUser}
                          itemSize={75}
                          width={width}
                        >
                          {Row(START_OR_END.END)}
                        </List>
                      )}
                    </AutoSizer>
                  </ListContainer>
                )}
                <FormSection>
                  <TextInputWithLabel
                    title="차량 명"
                    disabled={true}
                    required={true}
                    {...register("vehicleName")}
                    minWidth="290px"
                  />
                  <TextInputWithLabel
                    title="도착지"
                    required={true}
                    {...register("endGpsAddress")}
                    minWidth="290px"
                  />
                  <TextInputWithLabel
                    title="도착 후 거리(KM)"
                    required={true}
                    type="number"
                    {...register("endKm", {
                      min: 0,
                      required: true
                    })}
                    minWidth="290px"
                  />
                  <TextInputWithLabel
                    title="도착 시간"
                    required={true}
                    type="datetime-local"
                    {...register("endDateTime")}
                    minWidth="290px"
                  />
                </FormSection>
              </ContentSection>
            </Section>
            <ButtonContainer>
              <Button>{isOpenDialog ? "등록하기" : "수정하기"}</Button>
            </ButtonContainer>
          </ControllerDialogContainer>
        </AsonicDialog>
      )}
      {isOpenConfirmDialog && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
    </Container>
  );
}

export default DrivingInformationController;
