import { call, put } from "redux-saga/effects";
import {
  getRequestSearchDocs,
  makeNewRequest
} from "../../../redux/sagas/restapi-calls/requestCalls";

import { SET_REQUEST_SEARCH_RESULT } from "../../../redux/modules/main-view/request/requestReducer";
import { GLOBAL_NOTIFICATION_ON } from "../../../redux/modules/main-view/mainViewReducer";
import { SET_REQUEST_SEARCH_RESULT_FOR_CAL } from "../../modules/main-view/request/requestReducer";

export function* getRequestDocsAsync(action: any) {
  const searchResults: any[] = yield call(getRequestSearchDocs, action.args);
  yield put({
    type: SET_REQUEST_SEARCH_RESULT,
    searchResults
  });

  if (searchResults.length < 1) {
    yield put({
      type: GLOBAL_NOTIFICATION_ON,
      notibarConf: {
        notiOpen: true,
        message: "검색된 데이터가 없습니다."
      }
    });
  }
}

export function* getRequestDocsForCalAsync(action: any) {
  const requestSearchResultsForCal: any[] = yield call(
    getRequestSearchDocs,
    action.args
  );
  yield put({
    type: SET_REQUEST_SEARCH_RESULT_FOR_CAL,
    requestSearchResultsForCal
  });

  //   if (requestSearchResultsForCal.length < 1) {
  //     yield put({
  //       type: GLOBAL_NOTIFICATION_ON,
  //       notibarConf: {
  //         notiOpen: true,
  //         message: "검색된 데이터가 없습니다."
  //       }
  //     });
  //   }
}

export function* makeNewRequestAsync(action: any) {
  const ret: boolean | number = yield call(makeNewRequest, action.args);
  let message = "결재를 성공적으로 상신하였습니다.";
  if (ret === false) {
    message = "결재를 상신하지 못하였습니다.";
  } else if (ret === 500) {
    message = "연장 할 수 있는 근무시간을 초과했습니다.";
  }
  yield put({
    type: GLOBAL_NOTIFICATION_ON,
    notibarConf: {
      notiOpen: true,
      message
    }
  });
}
