// 이 부분은 import로 변환하면 안됨 (typescript가 인지 불가)
import cookies from "js-cookie";

export enum OptType {
  openBrowser = 1001,
  resizeElectronWindowSize = 1002
}

export interface IPreloadConfig {
  buttonClose?: number;
  buttonMin?: number;
  buttonMax?: number;
  trans?: number;
  frame?: number;
  pageNum?: number;
  mousePassThrough?: number;
  winUniqueId?: string;
}

export interface ICommonRequest {
  type: number;
  data: any;
}

class Communication {
  private static instance?: Communication;
  private ipcRenderer: any;
  private logger = window.glog;
  private winUniqueId: string;

  private constructor(winId: string = "") {
    const { ipcRenderer } = window.require("electron");
    this.ipcRenderer = ipcRenderer;
    // 대륜은 계속 쿠키를 사용하므로 제거하면 안됨
    // 그대신 새로운 위젯은 winId를 url에서 parsing해서 사용
    this.winUniqueId = cookies.get("winUniqueId") ?? winId;
    // let buttonOpt = cookies.get("buttonOption") ?? "";
    this.logger(`UniqueId = ${this.winUniqueId}`);
    // this.logger(`buttonOption = ${buttonOpt}`);

    let buttonOpt = this.getButtonOption();
    if (buttonOpt?.buttonClose === 0) {
      // 이 부분을 실행하고 닫으면 안닫힘 (alt + f4 방지용)
      window.onbeforeunload = (e: any) => {
        e.returnValue = false;
      };
    }
  }

  static getInstance(winId: string = ""): Communication | undefined {
    if (!Communication.instance) {
      let isElectron: boolean = false;
      if (
        typeof window !== "undefined" &&
        typeof window.process === "object" &&
        window.process?.type === "renderer"
      ) {
        isElectron = true;
      }

      // Main process
      if (
        typeof process !== "undefined" &&
        typeof process.versions === "object" &&
        !!process.versions.electron
      ) {
        isElectron = true;
      }

      // Detect the user agent when the `nodeIntegration` option is set to true
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        isElectron = true;
      }
      if (isElectron) {
        Communication.instance = new Communication(winId);
      } else {
        console.log("This is not electron version");
      }
    }
    return Communication.instance;
  }

  showDialog() {
    this.ipcRenderer.invoke(
      `showDialog-${this.winUniqueId}`,
      "Show me the money!"
    );
  }

  getButtonOption(): IPreloadConfig | undefined {
    let btnOptionString = cookies.get("buttonOption") ?? undefined;

    if (btnOptionString) {
      try {
        return JSON.parse(btnOptionString);
      } catch (e) {
        console.log("PARSE ERROR");
      }
    }

    return undefined;
  }

  getToken(): string | undefined {
    return cookies.get("token") ?? undefined;
  }

  deleteToken() {
    cookies.remove("token");
  }

  closeWindow() {
    // 이 부분을 실행하고 닫으면 닫힘 (alt + f4 방지용)
    window.onbeforeunload = (e: any) => {
      delete e["returnValue"];
    };
    this.ipcRenderer.invoke(`close-${this.winUniqueId}`);
  }

  maximizeWindow() {
    this.ipcRenderer.invoke(`maximize-${this.winUniqueId}`);
  }

  minimizeWindow() {
    this.ipcRenderer.invoke(`minimize-${this.winUniqueId}`);
  }

  mouseEventPassThrough(passthrough: boolean) {
    this.ipcRenderer.invoke(
      `mouse-pass-through-${this.winUniqueId}`,
      JSON.stringify({ passthrough })
    );
  }

  mouseEventPassThroughToggle(passthroughFlag: boolean) {
    this.ipcRenderer.invoke(
      `mouse-pass-through-toggle-${this.winUniqueId}`,
      JSON.stringify({ passthroughFlag })
    );
  }

  // 제어하지 않는 url을 열어주는 역할
  // 단순 브라우져 처럼 기능하는 역할
  // 근무관리 웹을 열어주기 위한 기능
  // 기본으로 frame 값을 1로 설정 됨
  openUrlInElectron(url: string, token?: string) {
    this.ipcRenderer.invoke(
      `open-url-in-electron-${this.winUniqueId}`,
      JSON.stringify({ url, token })
    );
  }

  sendCommonRequest(request: ICommonRequest) {
    console.log(request);
    this.ipcRenderer.invoke(
      `common-request-${this.winUniqueId}`,
      JSON.stringify(request)
    );
  }
}

export default Communication;
