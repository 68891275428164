import React from "react";
import stateManager from "./stateManager";
import MobileCategorySelectionContainer from "../../../../moContainer/main-view/mobile-category-selection-container";
import uiString from "./string.json";
import {
  Container,
  BackBtnContainer,
  MainContainer,
  SteppersContainer
} from "./styled";
import {
  titleCreator,
  dateCreator,
  timeCreator,
  _handleKeyPress,
  _getDateFormatter,
  _getTimeFormatter
} from "./utils";
import MobileSearchApprover from "../MobileSearchApprover";
import MobileRequestAlert from "../MobileRequestAlert/MobileRequestAlert";
import useDate from "../../../../hooks/detail-view-hook/place-free-management-hook/use-date";
import ToastMessage, {
  MessageTypes
} from "../../../../components/toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import { Input } from "../../../../components/shared";
import Button from "../../../../components/globalComponents/Button";
const MobileRequestDialogView = props => {
  const {
    openDialog,
    handleOpenDialog,
    departmentId,
    approvers,
    makeNewRequest,
    signInReducer
  } = props;
  const { isOpen, handleIsOpen, message, handleToast, toastMessageType } =
    useOpenToastMessage();

  const {
    activeStep,
    handleNextActiveStep,
    handleBackActiveStep,
    categoryId,
    updateCategoryId,
    startTime,
    handleStartTime,
    endTime,
    handleEndTime,
    comment,
    handleComment,
    alterStartDate,
    handleAlterStartDate,
    alterEndDate,
    handleAlterEndDate,
    alterStartTime,
    handleAlterStartTime,
    alterEndTime,
    handleAlterEndTime,
    nameOfVoter,
    handleNameOfVoter,
    searchResult,
    handleSearchResult,
    selectedVoter,
    handleAddVoter,
    handleDeleteVoter,
    setSelectedVoter,
    handleSelectedVoterPosition,
    handleInitial,
    alertOpen,
    handleAlertOpen,
    setActiveStep
  } = stateManager({ handleToast });
  const { startDate, endDate, handleDate } = useDate();
  const handleSave = React.useCallback(() => {
    makeNewRequest({
      employeeId: signInReducer.employee_id,
      overtimeCategoryId: categoryId,
      startDayOffDate: _getDateFormatter(startDate),
      endDayOffDate: _getDateFormatter(endDate),
      startDayOffTime: _getTimeFormatter(startTime),
      endDayOffTime: _getTimeFormatter(endTime),
      comment: comment,
      startAlterWorkDate:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getDateFormatter(alterStartDate)
          : "",
      endAlterWorkDate:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getDateFormatter(alterEndDate)
          : "",
      startAlterWorkTime:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getTimeFormatter(alterStartTime)
          : "",
      endAlterWorkTime:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getTimeFormatter(alterEndTime)
          : "",
      approverId: selectedVoter.map(voter => voter.employee_id)
    });
    handleToast("결재 신청이 되었습니다", MessageTypes.SUCCESS);
    handleInitial();
    handleAlertOpen();
  }, [
    alterEndDate,
    alterEndTime,
    alterStartDate,
    alterStartTime,
    categoryId,
    comment,
    endDate,
    endTime,
    handleAlertOpen,
    handleInitial,
    makeNewRequest,
    selectedVoter,
    signInReducer.employee_id,
    startDate,
    startTime,
    handleToast
  ]);

  return (
    <>
      <Container openDialog={openDialog}>
        <BackBtnContainer
          onClick={() => {
            handleOpenDialog();
            setActiveStep(0);
            handleComment();
          }}
        >
          <span>{uiString.dialog.title.cancle}</span>
        </BackBtnContainer>
        <MainContainer>
          {titleCreator(uiString, activeStep, categoryId)}
          {activeStep === 0 && (
            <MobileCategorySelectionContainer
              isRequestDialogPage={true}
              isCategory={true}
              categoryId={categoryId}
              updateCategoryId={updateCategoryId}
            />
          )}
          {activeStep === 0 &&
            dateCreator(
              startDate,
              endDate,
              handleDate("start"),
              handleDate("end")
            )}
          {categoryId === uiString.dialog.category.alternativeWorkId &&
            activeStep === 1 &&
            dateCreator(
              alterStartDate,
              alterEndDate,
              handleAlterStartDate,
              handleAlterEndDate
            )}
          {activeStep === 0 &&
            timeCreator(
              startTime,
              endTime,
              handleStartTime,
              handleEndTime,
              activeStep
            )}
          {categoryId === uiString.dialog.category.alternativeWorkId &&
            activeStep === 1 &&
            timeCreator(
              alterStartTime,
              alterEndTime,
              handleAlterStartTime,
              handleAlterEndTime,
              activeStep,
              categoryId
            )}
          {categoryId === uiString.dialog.category.alternativeWorkId
            ? activeStep === 1 && (
                <Input
                  id="textarea"
                  value={comment}
                  label={uiString.dialog.reasonForRequest}
                  placeholder={uiString.dialog.reasonForRequestPlaceholder}
                  multiline
                  onChange={handleComment}
                  margin="normal"
                />
              )
            : activeStep === 0 && (
                <Input
                  id="textarea"
                  value={comment}
                  label={uiString.dialog.reasonForRequest}
                  placeholder={uiString.dialog.reasonForRequestPlaceholder}
                  multiline
                  onChange={handleComment}
                  margin="normal"
                />
              )}
          {categoryId === uiString.dialog.category.alternativeWorkId
            ? activeStep === 2 &&
              comment && (
                <MobileSearchApprover
                  handleAlertOpen={handleAlertOpen}
                  handleSelectedVoterPosition={handleSelectedVoterPosition}
                  setSelectedVoter={setSelectedVoter}
                  approvers={approvers}
                  handleDeleteVoter={handleDeleteVoter}
                  selectedVoter={selectedVoter}
                  handleAddVoter={handleAddVoter}
                  _handleKeyPress={_handleKeyPress}
                  departmentId={departmentId}
                  handleSearchResult={handleSearchResult}
                  handleNameOfVoter={handleNameOfVoter}
                  nameOfVoter={nameOfVoter}
                  uiString={uiString}
                  searchResult={searchResult}
                  setActiveStep={setActiveStep}
                />
              )
            : activeStep === 1 &&
              comment && (
                <MobileSearchApprover
                  handleAlertOpen={handleAlertOpen}
                  handleSelectedVoterPosition={handleSelectedVoterPosition}
                  setSelectedVoter={setSelectedVoter}
                  approvers={approvers}
                  handleDeleteVoter={handleDeleteVoter}
                  selectedVoter={selectedVoter}
                  handleAddVoter={handleAddVoter}
                  _handleKeyPress={_handleKeyPress}
                  departmentId={departmentId}
                  handleSearchResult={handleSearchResult}
                  handleNameOfVoter={handleNameOfVoter}
                  nameOfVoter={nameOfVoter}
                  uiString={uiString}
                  searchResult={searchResult}
                  setActiveStep={setActiveStep}
                />
              )}
        </MainContainer>
        <SteppersContainer>
          <Button
            onClick={handleBackActiveStep}
            disabled={activeStep === 0}
            size="small"
          >
            {`<  Back`}
          </Button>
          <Button
            onClick={handleNextActiveStep(
              categoryId === uiString.dialog.category.alternativeWorkId,
              comment
            )}
            disabled={
              categoryId === uiString.dialog.category.alternativeWorkId
                ? activeStep === 2
                : activeStep === 1
            }
            size="small"
          >
            {`Next >`}
          </Button>
        </SteppersContainer>
        {alertOpen && (
          <MobileRequestAlert
            alertOpen={alertOpen}
            handleAlertOpen={handleAlertOpen}
            handleSave={handleSave}
          />
        )}
      </Container>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
        widget={true}
      />
    </>
  );
};

export default MobileRequestDialogView;
