import TitleContainer from "../../shared/title_container/title_container";
import SubTitle from "../../shared/sub-title/sub-title";
import MainContainer from "../../shared/main_container/main_container";
import ContentContainer from "../../shared/grid_content_container/grid_content_container";
import FormRow from "../../shared/form-row/form-row";
import { WtGraphBasicEntity } from "../../../generated/graphql";

interface IProps {
  basicInfo?: WtGraphBasicEntity;
}

function AutonomyBasicInfo({ basicInfo }: IProps) {
  return (
    <MainContainer gap={"10px"}>
      <TitleContainer>
        <SubTitle title="기본정보" />
      </TitleContainer>
      <ContentContainer>
        <FormRow title="관리 이름">
          <span>{basicInfo?.name ?? ""}</span>
        </FormRow>
        <FormRow title="근무 유형">
          <span>{basicInfo?.workType ?? `자율출근제`}</span>
        </FormRow>
        <FormRow title="근무시간(일)">
          <span>{`${basicInfo?.dayMaxWorkHour ?? ""} 시간`}</span>
        </FormRow>
        <FormRow title="근무시간(주)">
          <span>{`${basicInfo?.weekMaxWorkHour ?? ""} 시간`}</span>
        </FormRow>
      </ContentContainer>
    </MainContainer>
  );
}

export default AutonomyBasicInfo;
