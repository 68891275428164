import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  GetListOfReservationType,
  GetListOfReservationType_getListOfReservationType_list
} from "../../__generated__/GetListOfReservationType";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import AsonicTable from "../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import ReservationTypeEditor from "./reservation-type-editor";
import {
  GetListOfReservationCategory,
  GetListOfReservationCategoryVariables,
  GetListOfReservationCategory_getListOfReservationCategory_list
} from "../../__generated__/GetListOfReservationCategory";
import ReservationCategoryEditor from "./reservation-category-editor";

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "reservation-type reservation-information reservation-information"
    "reservation-type reservation-information reservation-information"
    "reservation-type reservation-information reservation-information"
    "reservation-type reservation-information reservation-information";
  gap: 10px;
`;

const Section = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.borderColor};
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  :first-child {
    grid-area: reservation-type;
  }
  :last-child {
    grid-area: reservation-information;
  }
  span {
    font-size: 14px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex: 8;
`;

const QUERY_GET_LIST_OF_RESERVATION_TYPE = gql`
  query GetListOfReservationType {
    getListOfReservationType {
      ok
      error
      list {
        groupIdx
        groupName
        parentGroupIdx
      }
    }
  }
`;

const QUERY_GET_LIST_OF_RESERVATION_CATEGORY = gql`
  query GetListOfReservationCategory($groupIdx: Int!) {
    getListOfReservationCategory(groupIdx: $groupIdx) {
      ok
      error
      list {
        categoryIdx
        groupIdx
        categoryName
        categoryDescription
      }
    }
  }
`;

function ReservationInformationController() {
  const { data, loading } = useQuery<GetListOfReservationType>(
    QUERY_GET_LIST_OF_RESERVATION_TYPE
  );

  const [getListOfReservationCategory, { data: reservationCategoryData }] =
    useLazyQuery<
      GetListOfReservationCategory,
      GetListOfReservationCategoryVariables
    >(QUERY_GET_LIST_OF_RESERVATION_CATEGORY);

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfReservationType_getListOfReservationType_list>
    ) => {},
    []
  );

  const columns: ReactTable.Column<GetListOfReservationType_getListOfReservationType_list>[] =
    useMemo(() => {
      return [{ Header: "근무관리", accessor: "groupName" }];
    }, []);

  const list: GetListOfReservationType_getListOfReservationType_list[] =
    useMemo(() => {
      return data?.getListOfReservationType.list || [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: reservationTypeColumns
  } = ReactTable.useTable<GetListOfReservationType_getListOfReservationType_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const selectedRow:
    | IAsonicRow<GetListOfReservationType_getListOfReservationType_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  // rc as reservationCategory
  const rcColumns: ReactTable.Column<GetListOfReservationCategory_getListOfReservationCategory_list>[] =
    useMemo(() => {
      return [
        { Header: "예약 상세 항목", accessor: "categoryName" },
        { Header: "상세 설명", accessor: "categoryDescription" }
      ];
    }, []);

  const rcList: GetListOfReservationCategory_getListOfReservationCategory_list[] =
    useMemo(() => {
      return reservationCategoryData?.getListOfReservationCategory.list || [];
    }, [reservationCategoryData]);

  const {
    prepareRow: rcPrepareRow,
    getTableProps: rcGetTableProps,
    headerGroups: rcHeaderGroups,
    getTableBodyProps: rcGetTableBodyProps,
    rows: rcRows,
    selectedFlatRows: rcSelectedFlatRows,
    columns: reservationCategoryColumns
  } = ReactTable.useTable<GetListOfReservationCategory_getListOfReservationCategory_list>(
    {
      columns: rcColumns,
      data: rcList
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const selectedRowForReservationCategoryTable:
    | IAsonicRow<GetListOfReservationCategory_getListOfReservationCategory_list>
    | undefined = useMemo(() => {
    if (rcSelectedFlatRows.length > 0) {
      return rcSelectedFlatRows[rcSelectedFlatRows.length - 1];
    }
    return;
  }, [rcSelectedFlatRows]);

  const handleSelectRowForRc = useCallback(
    (
      row?: IAsonicRow<GetListOfReservationCategory_getListOfReservationCategory_list>
    ) => {},
    []
  );

  useEffect(() => {
    if (selectedRow?.original?.groupIdx) {
      getListOfReservationCategory({
        variables: {
          groupIdx: selectedRow?.original?.groupIdx
        }
      });
    }
  }, [getListOfReservationCategory, selectedRow?.original?.groupIdx]);

  return (
    <Container>
      <Section>
        <span>예약종류</span>
        <TableContainer>
          <AsonicTable<GetListOfReservationType_getListOfReservationType_list>
            title="예약종류"
            handleSelectRow={handleSelectRow}
            isLoading={loading}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            columns={
              reservationTypeColumns as TColumn<GetListOfReservationType_getListOfReservationType_list>[]
            }
            isTitleBar={false}
          />
        </TableContainer>
        <ReservationTypeEditor selectedRow={selectedRow} />
      </Section>
      <Section>
        <span>예약상세항목</span>
        <TableContainer>
          <AsonicTable<GetListOfReservationCategory_getListOfReservationCategory_list>
            title="예약상세항목"
            handleSelectRow={handleSelectRowForRc}
            isLoading={loading}
            prepareRow={rcPrepareRow}
            getTableProps={rcGetTableProps}
            headerGroups={rcHeaderGroups}
            getTableBodyProps={rcGetTableBodyProps}
            rows={rcRows}
            selectedRow={selectedRowForReservationCategoryTable}
            columns={
              reservationCategoryColumns as TColumn<GetListOfReservationCategory_getListOfReservationCategory_list>[]
            }
            isTitleBar={false}
          />
        </TableContainer>
        <ReservationCategoryEditor
          selectedRow={selectedRowForReservationCategoryTable}
          selectedGroupIdx={selectedRow?.original?.groupIdx}
        />
      </Section>
    </Container>
  );
}

export default ReservationInformationController;
