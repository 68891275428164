import routes from "../routes";
import API from "./api";

interface UpdateWorkingAuthWebParams {
  readonly workingAuthWeb: number;
}

const updateWorkingAuthWeb = (params: UpdateWorkingAuthWebParams) =>
  API().post(routes.apiRoutes.menuSettingsRoutes.workingAuthWeb, params);

const getIsWidgetBox = () =>
  API().get(routes.apiRoutes.menuSettingsRoutes.widgetBox);

const menuSettingsApi = {
  updateWorkingAuthWeb,
  getIsWidgetBox
};

export default menuSettingsApi;
