import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IIcon {
  open?: boolean;
}

export const Container = styled.div`
  margin: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  .Collapsible {
    width: 100%;
  }
  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    padding: 12px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .Collapsible__contentInner {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px;
  }
`;

export const Trigger = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TriggerTitle = styled.div``;

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.54);
  transition: all 200ms ease-out;
  transform: ${(props: IIcon) => (props.open ? "rotate(0.5turn)" : "")};
`;
