import { useState, useEffect, useRef } from "react";
import { getTreeData } from "../../../../../Utils/commonAxiosCall";

const useIsUpdatedTreeDataState = (employee_id, setTreeData) => {
  const isMounted = useRef(false);
  const [isUpdateTreeData, setIsUpdateTreeData] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    if (isUpdateTreeData) {
      getTreeData(employee_id)
        .then(({ data: { ok, data, error } }) => {
          if (ok && isMounted.current) {
            setTreeData(data);
          } else {
            console.log(error);
          }
        })
        .catch(e => console.log(e));
      if (isMounted.current) {
        setIsUpdateTreeData(false);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [isUpdateTreeData, employee_id, setTreeData]);

  return { isUpdateTreeData, setIsUpdateTreeData };
};

export default useIsUpdatedTreeDataState;
