import api from "../api";

const STATISTICS = `${api.api}/statistics`;
const QUERY_LIST = `${STATISTICS}/querylist`;
const USER_QUERY_LIST = `${STATISTICS}/userquerylist`;
const SAVE_QUERY = `${STATISTICS}/savequery`;
const EXE_QUERY = `${STATISTICS}/exequery`;
const DEL_QUERY = `${STATISTICS}/delquery`;
const PUBLICS_QUERY = `${STATISTICS}/publishquery`;
const DOWNLOAD_CSV = `${STATISTICS}/downloadcsv`;

const statisticsApi = {
  queryList: QUERY_LIST,
  userQueryList: USER_QUERY_LIST,
  saveQuery: SAVE_QUERY,
  exeQuery: EXE_QUERY,
  delQuery: DEL_QUERY,
  publicsQuery: PUBLICS_QUERY,
  downloadCsv: DOWNLOAD_CSV
};

export default statisticsApi;
