import ReactLoading from "react-loading";
import { colors } from "../GlobalStyle/GlobalStyle";
import StyledFlexContainer from "./styled_flex_container";
import styled from "styled-components";

const Container = styled(StyledFlexContainer)`
  justify-content: center;
`;

function Spinner() {
  return (
    <Container>
      <ReactLoading
        type="spokes"
        color={colors.green}
        height={200}
        width={170}
      />
    </Container>
  );
}

export default Spinner;
