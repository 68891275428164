import {
  Container,
  Title,
  SubTitle,
  Icon,
  IconContainer,
  Arrow
} from "./styled";
import ExcelContainer from "../../../WorkManagementInfo/ExcelContainer";
import { faDiceD20, faTh } from "@fortawesome/free-solid-svg-icons";
import { Icon as IconIconify } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import ReactTooltip from "react-tooltip";
import useExe, {
  MenuList
} from "../../../../../hooks/use-page-title-of-title-bar/use-exe";
import useMasterWorkDashboardDownloadExcel from "../../../../../hooks/master-work-management-page-hooks/use-master-work-dashboard-download-excel";
import usePersonalScheduleSetting from "../../../../../hooks/use-page-title-of-title-bar/use-personal-schedule-setting";

type Props = {
  mainTitle: string;
  subTitle?: string;
  menuList?: MenuList;
  employeeId?: string;
  handleOpenDashboardSearchDialog: (value: boolean) => void;
};

enum AUTHORITY {
  MASTER = "팀장",
  PERSONAL = "개인"
}

const PageTitleOfTitleBar = <P extends Props>({
  mainTitle,
  subTitle,
  menuList,
  employeeId,
  handleOpenDashboardSearchDialog
}: P) => {
  const { downloadExcel } = useMasterWorkDashboardDownloadExcel();
  const { isElectron, exeTitle, runExeFile } = useExe({ menuList, employeeId });
  const { handleIsOpenDialog, personalTemplateId } = usePersonalScheduleSetting(
    {
      employeeId,
      mainTitle
    }
  );
  return (
    <Container>
      <ReactTooltip type="success" />
      <Title subTitle={subTitle}>{mainTitle}</Title>
      {subTitle && <Arrow>{`>`}</Arrow>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {subTitle === AUTHORITY.MASTER && (
        <ExcelContainer downloadExcel={downloadExcel} />
      )}
      {subTitle === AUTHORITY.MASTER && (
        <IconContainer
          onClick={() => {
            handleOpenDashboardSearchDialog(true);
          }}
        >
          <IconIconify icon={SearchIcon} />
        </IconContainer>
      )}
      {mainTitle === "달력" && personalTemplateId && (
        <Icon
          icon={faTh}
          size="lg"
          color="#ff6b81"
          data-tip="개인 근무시간 계획설정"
          onClick={handleIsOpenDialog(true)}
        />
      )}
      {subTitle === AUTHORITY.PERSONAL && isElectron && (
        <Icon
          icon={faDiceD20}
          size="lg"
          color="#0051cb"
          data-tip={exeTitle}
          onClick={runExeFile}
        />
      )}
    </Container>
  );
};

export default PageTitleOfTitleBar;
