import { useCallback, useEffect, useRef, useState } from "react";
import * as ReactTable from "react-table";
import { IHideColumnProps } from "../../components/table/hide_column";

export interface TColumn<P extends object> extends ReactTable.HeaderGroup<P> {}

interface IProps<P extends object> extends IHideColumnProps<P> {}

function useHideTableColumns<P extends object>({
  columns,
  table,
  title
}: IProps<P>) {
  const isMount = useRef<boolean>(false);
  const [countItem, setCountItem] = useState<number>(0);

  const handleCountItem = useCallback((value: number) => {
    setCountItem(value);
  }, []);

  useEffect(() => {
    if (!isMount.current && columns.length > 0) {
      isMount.current = true;
      const storedColumn = localStorage.getItem(title);
      let count = columns.length;
      if (storedColumn) {
        const parsedColumn: TColumn<P>[] = JSON.parse(storedColumn);
        parsedColumn.forEach(item => {
          if (!item.isVisible) {
            count = count - 1;
            table.toggleHideColumn(item.id, true);
          }
        });
      }
      setCountItem(count);
    }
    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [table, title, columns]);

  return { countItem, handleCountItem };
}

export default useHideTableColumns;
