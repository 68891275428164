import { useEffect } from "react";
import { RootDiv, MobileRootDiv } from "./mainViewStyled/styled";
import { ENTIRE_CATEGORY, APPROVED_REQUEST } from "../../constValues";
import NavBarContainer from "../../moContainer/main-view/NavBarContainer";
import moveToPage from "./moveToPage";
import { useNavigate, Outlet } from "react-router-dom";

const MainView = props => {
  const {
    getCategories,
    getRequestDocsForCal,
    getWorkingHours,
    signInReducer,
    setUserInfo
  } = props;
  const navigate = useNavigate();
  const _getDateFormatter = date => {
    const newDate = new Date(date);
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${newDate.getFullYear()}-${month}-${day}`;
  };

  useEffect(() => {
    moveToPage(navigate, signInReducer, setUserInfo);
    let mDate = new Date();
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), 1);
    let lastDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 0);
    getCategories();

    getWorkingHours({
      startDate: _getDateFormatter(newDate),
      endDate: _getDateFormatter(lastDate),
      employeeId: signInReducer.employeeId
    });

    getRequestDocsForCal({
      startDate: _getDateFormatter(newDate),
      endDate: _getDateFormatter(lastDate),
      categoryId: ENTIRE_CATEGORY,
      resultValue: APPROVED_REQUEST,
      employeeId: signInReducer.employeeId
    });
  }, [
    getCategories,
    getRequestDocsForCal,
    getWorkingHours,
    setUserInfo,
    signInReducer,
    navigate
  ]);
  return (
    <MobileRootDiv>
      <RootDiv>
        <Outlet />
      </RootDiv>
      <NavBarContainer />
    </MobileRootDiv>
  );
};

export default MainView;
