import styled from "styled-components";

export const Input = styled.input<{ hasError: boolean }>`
  all: unset;
  width: 100%;
  border-radius: 3px;
  padding: 7px;
  background-color: #fafafa;
  border: 0.5px solid ${props => (props.hasError ? "tomato" : "rgb(0,0,0.4)")};
  box-sizing: border-box;
  :read-only {
    background-color: #cecdcd;
  }
  &::placeholder {
    font-size: 12px;
  }
  &:focus {
    border-color: ${props => (props.hasError ? "tomato" : "rgb(38, 38, 38)")};
  }
`;
