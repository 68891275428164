import { useContext } from "react";
import styled from "styled-components";
import { ChatHandleContext, DEFAULT_PARENT_DEPARTMENT_ID } from "./chat";
import ListForChat from "./list-for-chat";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  z-index: 1;
  border: none;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleBar = styled.div`
  display: flex;
  padding: 10px;
`;

const Main = styled.section`
  display: flex;
  flex: 14;
`;

const Button = styled.aside`
  display: flex;
  cursor: pointer;
  :first-child {
    justify-self: flex-start;
    font-size: 18px;
  }
  font-weight: bold;
  user-select: none;
  align-items: center;
  :hover {
    color: ${props => props.theme.colors.skyBlue};
  }
  :active {
    transform: scale(0.98);
  }
`;

function UserInvitation() {
  const chatHandleContext = useContext(ChatHandleContext);
  return (
    <Container>
      <Content>
        <TitleBar>
          <Button
            onClick={() => {
              chatHandleContext?.handleIsUserInvitation(false);
              chatHandleContext?.handleParentDepartment({
                parentDepartmentId: DEFAULT_PARENT_DEPARTMENT_ID,
                selectedDepartmentName: "전체"
              });
              chatHandleContext?.handleSelectedUser(undefined);
            }}
          >{`<`}</Button>
        </TitleBar>
        <Main>
          <ListForChat
            handleParentDepartment={chatHandleContext!.handleParentDepartment}
          />
        </Main>
      </Content>
    </Container>
  );
}

export default UserInvitation;
