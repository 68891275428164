import styled from "styled-components";
import CompactTable from "../../compact-table/compact-table";
import { IEmployeeInformationForReceiveAndReference } from "./list-of-selected-approval-user-for-receive-and-reference-form";
import * as ReactTable from "react-table";
import { useMemo } from "react";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import ApprovalReceiveReferenceSelect from "./approval-receive-reference-select";
import { ICompactRow } from "../../compact-table/compact-table-render-row";

const COLUMN_FOR_VALUE = {
  checked: "선택된 수신/참조",
  type: "구분",
  name: "수신/참조"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

interface IProps {
  listOfUser: IEmployeeInformationForReceiveAndReference[];
  listOfAddedUser: string[];
  handleAllAddedUser: (users: string[]) => void;
  handleChangeUserInfoInListOfApprovalUser: (
    payload: IEmployeeInformationForReceiveAndReference
  ) => void;
  handleAddedUser: (user: string) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  border-right: 1px solid ${props => props.theme.colors.grey};
`;

function ListOfReceiveAndReferenceUser({
  listOfUser,
  listOfAddedUser,
  handleAllAddedUser,
  handleChangeUserInfoInListOfApprovalUser,
  handleAddedUser
}: IProps) {
  const columns: ReactTable.Column<IEmployeeInformationForReceiveAndReference>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      const newListOfColumn: ReactTable.Column<IEmployeeInformationForReceiveAndReference>[] =
        listOfColumn.map(item => {
          let width = 50;
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] === COLUMN_FOR_VALUE.name
          ) {
            width = 150;
          }
          return {
            Header(header) {
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.checked
              ) {
                return (
                  <CheckBoxUi
                    checked={
                      header.data.length > 0 &&
                      header.data.length === listOfAddedUser.length
                    }
                    htmlFor={COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE]}
                    onChange={() => {
                      const newData: string[] = header.data.map(
                        (item: IEmployeeInformationForReceiveAndReference) =>
                          item.employeeId
                      );
                      handleAllAddedUser(newData);
                    }}
                  />
                );
              }
              return COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE];
            },
            accessor: item as TYPE_OF_EMPLOYEE,
            Cell(cell: Cell<IEmployeeInformationForReceiveAndReference>) {
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.name
              ) {
                const { name, userPosition, department } = cell.row.original;
                let result = `${name} / ${department}`;
                if (userPosition) {
                  result = `${name} / ${userPosition} / ${department}`;
                }
                return result;
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.type
              ) {
                return (
                  <ApprovalReceiveReferenceSelect
                    cell={cell}
                    handleChangeUserInfoInListOfApprovalUser={
                      handleChangeUserInfoInListOfApprovalUser
                    }
                  />
                );
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.checked
              ) {
                return (
                  <CheckBoxUi
                    checked={
                      !!listOfAddedUser.find(
                        item => item === cell.row.original.employeeId
                      )
                    }
                    htmlFor={cell.value}
                    onChange={() => {
                      if (cell.row.original.employeeId) {
                        handleAddedUser(cell.row.original.employeeId);
                      }
                    }}
                    name={cell.value}
                  />
                );
              }
            },
            width
          };
        });
      return newListOfColumn;
    }, [
      handleChangeUserInfoInListOfApprovalUser,
      listOfAddedUser,
      handleAddedUser,
      handleAllAddedUser
    ]);

  const userData: IEmployeeInformationForReceiveAndReference[] = useMemo(() => {
    return listOfUser.map((item, index) => {
      return {
        employeeId: item.employeeId,
        type: item.type,
        name: item.name,
        departmentId: item.departmentId,
        department: item.department,
        userPosition: item?.userPosition ?? ""
      };
    });
  }, [listOfUser]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<IEmployeeInformationForReceiveAndReference>(
    {
      columns,
      data: userData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow:
    | ICompactRow<IEmployeeInformationForReceiveAndReference>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  return (
    <Container>
      <CompactTable
        title="수신 및 참조자 정보 목록"
        subTitle="수신/참조 대상 목록"
        isLoading={false}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        isTitleBar={false}
      />
    </Container>
  );
}

export default ListOfReceiveAndReferenceUser;
