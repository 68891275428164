import React, { useEffect, useState, useCallback } from "react";
import AsonicCalendarWeekPresenter from "./asonic-calendar-week-presenter";
import { useSelector } from "react-redux";
import { getCalendarWeekelyTotal } from "../../../Utils/calendar-axios-call";
import { Reducers } from "../../../../types/reducers";
import moment from "moment";

export type Props = {
  week: Partial<DayInfo>[];
};

const AsonicCalendarWeekContainer = <P extends Props>(props: P) => {
  const isMount = React.useRef<boolean>(false);
  const { signInReducer } = useSelector((state: Reducers) => state);
  const [newWeek, setNewWeek] = useState<Partial<DayInfo>[]>([]);

  const getTotalTime = useCallback(
    async (startDate?: string, endDate?: string) => {
      if (signInReducer.employee_id && startDate && endDate) {
        let newWeek: Partial<DayInfo>[] = [];
        try {
          if (isMount.current) {
            const params = {
              employeeId: signInReducer.employee_id,
              startDate,
              endDate
            };
            const { data } = await getCalendarWeekelyTotal(params);
            const noneDataTitle = `주간합계${moment(startDate).format(
              "MM/DD"
            )}~${moment(endDate).format("MM/DD")}`;
            newWeek = [
              ...props.week,
              {
                titleColor: "#2FBAF3",
                title: data[0]?.titletext ?? noneDataTitle,
                totalTime: data[0]?.tottext ?? 0,
                totalUsingTime: data[0]?.totusetext ?? 0
              }
            ];
          }
        } catch (error) {
          console.log(error);
        } finally {
          if (isMount.current) {
            setNewWeek(newWeek);
          }
        }
      }
    },
    [props, signInReducer]
  );

  useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    const startDate: string | undefined = props.week[0].fullDate;
    const endDate: string | undefined =
      props.week[props.week.length - 1].fullDate;
    getTotalTime(startDate, endDate);
    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [props, getTotalTime]);
  return <AsonicCalendarWeekPresenter {...props} newWeek={newWeek} />;
};

export default AsonicCalendarWeekContainer;
