import RightMenuButton from "../RightMenuButton";

interface IProps {
  handleOpenEmergencyKeyDialog: (isTrue: boolean) => void;
}

const EmergencyKeyIssuance = <P extends IProps>({
  handleOpenEmergencyKeyDialog
}: P) => {
  return (
    <RightMenuButton
      title="긴급키 발급"
      handleClick={() => handleOpenEmergencyKeyDialog(true)}
    />
  );
};

export default EmergencyKeyIssuance;
