import styled from "styled-components";

interface IProps {
  children: React.ReactNode;
  handleClose: (value: boolean) => void;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  title?: string;
}

interface IDialogProps {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const DialogContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${(props: IDialogProps) => props.height ?? "max-content"};
  width: ${(props: IDialogProps) => props.width ?? "max-content"};
  min-height: ${(props: IDialogProps) => props.minHeight ?? "max-content"};
  min-width: ${(props: IDialogProps) => props.minWidth ?? "max-content"};
  background-color: white;
  border-radius: 4px;
  z-index: 9;
`;

const TitleBarContainer = styled.div<{ isTitle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isTitle ? "space-between" : "flex-end")};
  background-color: ${props => props.theme.colors.green};
  color: white;
  padding: 5px 20px 5px 20px;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const XIcon = styled.div`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.tomato};
  }
  user-select: none;
  :active {
    transform: scale(0.98);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  padding: 10px 20px 10px 20px;
  overflow: auto;
`;

function AsonicDialog(props: IProps) {
  return (
    <Container>
      <DialogContainer {...props}>
        <TitleBarContainer isTitle={!!props.title}>
          {props.title && <span>{props.title}</span>}
          <XIcon
            onClick={() => {
              props.handleClose(false);
            }}
          >
            X
          </XIcon>
        </TitleBarContainer>
        <Content>{props.children}</Content>
      </DialogContainer>
    </Container>
  );
}

export default AsonicDialog;
