import styled from "styled-components";

export type MultiTabType = { name: string; isSelected: boolean };
interface IProps {
  list: MultiTabType[];
  handleSelected: (payload: MultiTabType) => void;
}

interface IStyled {
  selected: boolean;
}

const Container = styled.div`
  display: flex;
`;

const Tab = styled.div<IStyled>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.selected ? props.theme.colors.green : props.theme.colors.grey};
  font-weight: bold;
  font-size: 14px;
  border-bottom: 4px solid
    ${props => (props.selected ? props.theme.colors.green : "")};
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.lightGreen};
    border-bottom: 4px solid ${props => props.theme.colors.lightGreen};
  }
  padding: 0px 10px 0px 10px;
`;

const MultiTab = <P extends IProps>({ list, handleSelected }: P) => {
  return (
    <Container>
      {list.map((item, index) => (
        <Tab
          key={`${item} ${index}`}
          selected={item.isSelected}
          onClick={event => {
            const newItem = {
              ...item,
              isSelected: true
            };
            handleSelected(newItem);
          }}
        >
          {item.name}
        </Tab>
      ))}
    </Container>
  );
};

export default MultiTab;
