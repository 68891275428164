import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list } from "../../../../../__generated__/GetListOfTemplateScheduleInfo";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import CheckBoxUi from "../../../../globalComponents/CheckBoxUi";
import AsonicIconButton from "../../../../shared/asonic-icon-button/asonic-icon-button";
import StartAndEndTime from "../../../../StartAndEndTime/StartAndEndTime";
import Date from "../../../Date/date";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import EditIcon from "@iconify/icons-fa-solid/edit";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import { gql, useMutation } from "@apollo/client";
import {
  AddTemplateScheduleInfo,
  AddTemplateScheduleInfoVariables
} from "../../../../../__generated__/AddTemplateScheduleInfo";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  UpdateTemplateScheduleInfo,
  UpdateTemplateScheduleInfoVariables
} from "../../../../../__generated__/UpdateTemplateScheduleInfo";
import {
  DeleteTemplateScheduleInfo,
  DeleteTemplateScheduleInfoVariables
} from "../../../../../__generated__/DeleteTemplateScheduleInfo";
import FormRow from "../../../../shared/form-row/form-row";

interface IProps {
  selectedRow?: IAsonicRow<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>;
  selectedTimeTemplateIdx?: number;
}

enum SelectedDay {
  NOT_SELECTED,
  SELECTED
}

interface IDays {
  key:
    | "isMonday"
    | "isTuesDay"
    | "isWednesday"
    | "isThursday"
    | "isFriday"
    | "isSaturday"
    | "isSunday";
  name: string;
  selected: SelectedDay;
}

interface IDaysProps {
  selected: SelectedDay;
}

type TIME = "startTime" | "endTime";

const Container = styled.div`
  border-top: 1px solid ${props => props.theme.borderColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  font-size: 18px;
  margin-top: 20px;
`;

const Week = styled.div`
  display: flex;
  gap: 10px;
`;

const Day = styled.div<IDaysProps>`
  display: flex;
  cursor: pointer;
  background-color: ${props =>
    props.selected !== 0
      ? props.theme.colors.green
      : props.theme.colors.softGreen};
  justify-content: center;
  align-items: center;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
  }
`;

const Select = styled.select``;

const Option = styled.option``;

const START_TIME = "startTime",
  END_TIME = "endTime";

const MUTATION_ADD_TEMPLATE_SCHEDULE_INFO = gql`
  mutation AddTemplateScheduleInfo(
    $timeTemplateIdx: Int!
    $periodDateStart: String!
    $startTime: String!
    $endTime: String!
    $categoryId: Int
    $isMonday: Int
    $isTuesDay: Int
    $isWednesday: Int
    $isThursday: Int
    $isFriday: Int
    $isSaturday: Int
    $isSunday: Int
    $periodDateEnd: String
  ) {
    addTemplateScheduleInfo(
      timeTemplateIdx: $timeTemplateIdx
      periodDateStart: $periodDateStart
      startTime: $startTime
      endTime: $endTime
      categoryId: $categoryId
      isMonday: $isMonday
      isTuesDay: $isTuesDay
      isWednesday: $isWednesday
      isThursday: $isThursday
      isFriday: $isFriday
      isSaturday: $isSaturday
      isSunday: $isSunday
      periodDateEnd: $periodDateEnd
    ) {
      ok
      error
      id
    }
  }
`;

const MUTATION_UPDATE_TEMPLATE_SCHEDULE_INFO = gql`
  mutation UpdateTemplateScheduleInfo(
    $timeIdx: Int!
    $periodDateStart: String!
    $startTime: String!
    $endTime: String!
    $categoryId: Int
    $isMonday: Int
    $isTuesDay: Int
    $isWednesday: Int
    $isThursday: Int
    $isFriday: Int
    $isSaturday: Int
    $isSunday: Int
    $periodDateEnd: String
  ) {
    updateTemplateScheduleInfo(
      timeIdx: $timeIdx
      periodDateStart: $periodDateStart
      startTime: $startTime
      endTime: $endTime
      categoryId: $categoryId
      isMonday: $isMonday
      isTuesDay: $isTuesDay
      isWednesday: $isWednesday
      isThursday: $isThursday
      isFriday: $isFriday
      isSaturday: $isSaturday
      isSunday: $isSunday
      periodDateEnd: $periodDateEnd
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_TEMPLATE_SCHEDULE_INFO = gql`
  mutation DeleteTemplateScheduleInfo($timeIdx: Int!) {
    deleteTemplateScheduleInfo(timeIdx: $timeIdx) {
      ok
      error
    }
  }
`;

function TimeTemplateScheduleEditor({
  selectedRow,
  selectedTimeTemplateIdx
}: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();
  const [addTemplateScheduleInfo] = useMutation<
    AddTemplateScheduleInfo,
    AddTemplateScheduleInfoVariables
  >(MUTATION_ADD_TEMPLATE_SCHEDULE_INFO, {
    update(cache, { data }, { variables }) {
      if (
        data?.addTemplateScheduleInfo.ok &&
        data.addTemplateScheduleInfo.id &&
        variables
      ) {
        const newTemplateScheduleInfo: GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list =
          {
            __typename: "TemplateTimeScheduleInfo",
            timeIdx: data.addTemplateScheduleInfo.id,
            ...variables,
            isMonday: variables.isMonday as number | null,
            isTuesDay: variables.isTuesDay as number | null,
            isWednesday: variables.isWednesday as number | null,
            isThursday: variables.isThursday as number | null,
            isFriday: variables.isFriday as number | null,
            isSaturday: variables.isSaturday as number | null,
            isSunday: variables.isSunday as number | null,
            periodDateEnd: variables.periodDateEnd as string | null,
            categoryId: (variables.categoryId as number | null) || null
          };
        const newCacheTemplateScheduleInfo = cache.writeFragment({
          id: `TemplateTimeScheduleInfo:${data.addTemplateScheduleInfo.id}`,
          fragment: gql`
            fragment add on TemplateTimeScheduleInfo {
              __typename
              timeTemplateIdx
              timeIdx
              isMonday
              isTuesDay
              isWednesday
              isThursday
              isFriday
              isSaturday
              isSunday
              periodDateStart
              periodDateEnd
              categoryId
              startTime
              endTime
            }
          `,
          data: {
            ...newTemplateScheduleInfo
          }
        });
        cache.modify({
          id: `GetListOfTemplateScheduleInfoOutput`,
          fields: {
            list(prev) {
              if (Array.isArray(prev) && prev.length > 0) {
                return [...prev, newCacheTemplateScheduleInfo];
              }
              return [newCacheTemplateScheduleInfo];
            }
          }
        });
        handleMessage(`성공적으로 근무계획을 추가 하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.addTemplateScheduleInfo.ok &&
        data?.addTemplateScheduleInfo.error
      ) {
        handleMessage(data.addTemplateScheduleInfo.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    },
    onError(error) {
      console.log(error);
    }
  });

  const [updateTemplateScheduleInfo] = useMutation<
    UpdateTemplateScheduleInfo,
    UpdateTemplateScheduleInfoVariables
  >(MUTATION_UPDATE_TEMPLATE_SCHEDULE_INFO, {
    update(cache, { data }, { variables }) {
      if (
        data?.updateTemplateScheduleInfo.ok &&
        selectedRow?.original.timeIdx
      ) {
        cache.modify({
          id: `TemplateTimeScheduleInfo:${selectedRow?.original.timeIdx}`,
          fields: {
            periodDateStart() {
              return variables?.periodDateStart;
            },
            startTime() {
              return variables?.startTime;
            },
            endTime() {
              return variables?.endTime;
            },
            categoryId() {
              return variables?.categoryId;
            },
            isMonday() {
              return variables?.isMonday;
            },
            isTuesDay() {
              return variables?.isTuesDay;
            },
            isWednesday() {
              return variables?.isWednesday;
            },
            isThursday() {
              return variables?.isThursday;
            },
            isFriday() {
              return variables?.isFriday;
            },
            isSaturday() {
              return variables?.isSaturday;
            },
            isSunday() {
              return variables?.isSunday;
            },
            periodDateEnd() {
              return variables?.periodDateEnd;
            }
          }
        });
        handleMessage(`성공적으로 근무계획을 수정 하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.updateTemplateScheduleInfo.ok &&
        data?.updateTemplateScheduleInfo.error
      ) {
        handleMessage(data.updateTemplateScheduleInfo.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [deleteTemplateScheduleInfo] = useMutation<
    DeleteTemplateScheduleInfo,
    DeleteTemplateScheduleInfoVariables
  >(MUTATION_DELETE_TEMPLATE_SCHEDULE_INFO, {
    update(cache, { data }, { variables }) {
      if (data?.deleteTemplateScheduleInfo.ok) {
        cache.evict({
          id: `TemplateTimeScheduleInfo:${variables?.timeIdx}`
        });
        handleMessage(`성공적으로 근무계획을 삭제 하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.deleteTemplateScheduleInfo.ok &&
        data?.deleteTemplateScheduleInfo.error
      ) {
        handleMessage(data?.deleteTemplateScheduleInfo.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const handleDeleteTemplateScheduleInfo = useCallback(() => {
    if (selectedRow?.original.timeIdx) {
      deleteTemplateScheduleInfo({
        variables: {
          timeIdx: selectedRow.original.timeIdx
        }
      });
    }
  }, [selectedRow?.original.timeIdx, deleteTemplateScheduleInfo]);

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState<string>(moment().format("HH:mm"));
  const [endTime, setEndTime] = useState<string>(moment().format("HH:mm"));

  const [isAlways, setIsAlways] = useState<boolean>(false);
  const initWeek = useMemo<IDays[]>(
    () => [
      { key: "isMonday", selected: SelectedDay.NOT_SELECTED, name: "월" },
      { key: "isTuesDay", selected: SelectedDay.NOT_SELECTED, name: "화" },
      { key: "isWednesday", selected: SelectedDay.NOT_SELECTED, name: "수" },
      { key: "isThursday", selected: SelectedDay.NOT_SELECTED, name: "목" },
      { key: "isFriday", selected: SelectedDay.NOT_SELECTED, name: "금" },
      { key: "isSaturday", selected: SelectedDay.NOT_SELECTED, name: "토" },
      { key: "isSunday", selected: SelectedDay.NOT_SELECTED, name: "일" }
    ],
    []
  );
  const [week, setWeek] = useState<IDays[]>(initWeek);

  const handleAddTemplateScheduleInfo = useCallback(() => {
    if (selectedTimeTemplateIdx) {
      const newWeek = {
        isMonday: SelectedDay.NOT_SELECTED,
        isTuesDay: SelectedDay.NOT_SELECTED,
        isWednesday: SelectedDay.NOT_SELECTED,
        isThursday: SelectedDay.NOT_SELECTED,
        isFriday: SelectedDay.NOT_SELECTED,
        isSaturday: SelectedDay.NOT_SELECTED,
        isSunday: SelectedDay.NOT_SELECTED
      };
      week.forEach(item => {
        newWeek[item.key] = item.selected;
      });

      addTemplateScheduleInfo({
        variables: {
          timeTemplateIdx: selectedTimeTemplateIdx,
          periodDateStart: startDate,
          periodDateEnd: isAlways ? null : endDate,
          startTime,
          endTime,
          ...newWeek
        }
      });
    }
  }, [
    selectedTimeTemplateIdx,
    addTemplateScheduleInfo,
    startDate,
    endDate,
    startTime,
    endTime,
    week,
    isAlways
  ]);

  const handleUpdateTemplateScheduleInfo = useCallback(() => {
    if (selectedRow?.original.timeIdx) {
      const newWeek = {
        isMonday: SelectedDay.NOT_SELECTED,
        isTuesDay: SelectedDay.NOT_SELECTED,
        isWednesday: SelectedDay.NOT_SELECTED,
        isThursday: SelectedDay.NOT_SELECTED,
        isFriday: SelectedDay.NOT_SELECTED,
        isSaturday: SelectedDay.NOT_SELECTED,
        isSunday: SelectedDay.NOT_SELECTED
      };
      week.forEach(item => {
        newWeek[item.key] = item.selected;
      });
      updateTemplateScheduleInfo({
        variables: {
          timeIdx: selectedRow.original.timeIdx,
          periodDateStart: startDate,
          periodDateEnd: isAlways ? null : endDate,
          startTime,
          endTime,
          ...newWeek
        }
      });
    }
  }, [
    selectedRow?.original.timeIdx,
    updateTemplateScheduleInfo,
    startDate,
    endDate,
    startTime,
    endTime,
    week,
    isAlways
  ]);

  const handleChangeTime =
    (name: TIME) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const time = event.target.value;
      if (name === START_TIME) {
        setStartTime(time);
      } else if (name === END_TIME) {
        setEndTime(time);
      }
    };

  const handleStartDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStartDate(event.target.value);
    },
    []
  );
  const handleEndDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEndDate(event.target.value);
    },
    []
  );

  const handleIsAlways = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsAlways(event.target.checked);
    },
    []
  );

  const handleSelectWeek = useCallback(
    (index: number) => {
      const newWeek = [...week];
      const selectedDay = newWeek[index];
      if (selectedDay.selected === SelectedDay.NOT_SELECTED) {
        selectedDay.selected = SelectedDay.SELECTED;
      } else {
        selectedDay.selected = SelectedDay.NOT_SELECTED;
      }
      setWeek(newWeek);
    },
    [week]
  );

  useEffect(() => {
    if (selectedRow) {
      const newWeek = initWeek.map(item => {
        const selected =
          selectedRow.original[item.key] || SelectedDay.NOT_SELECTED;
        return {
          ...item,
          selected
        };
      });
      setWeek(newWeek);
      setStartDate(selectedRow.original.periodDateStart);
      setEndDate(
        selectedRow.original.periodDateEnd ?? moment().format("YYYY-MM-DD")
      );
      setIsAlways(selectedRow.original.periodDateEnd ? false : true);
      setStartTime(selectedRow.original.startTime);
      setEndTime(selectedRow.original.endTime);
    }
  }, [selectedRow, initWeek]);

  return (
    <Container>
      <FormRow title="요일 선택">
        <Week>
          {week.map((item, index) => (
            <Day
              key={item.key}
              selected={item.selected}
              onClick={() => {
                handleSelectWeek(index);
              }}
            >
              {item.name}
            </Day>
          ))}
        </Week>
      </FormRow>
      <FormRow title="운영 기간">
        <Date
          startDate={startDate}
          endDate={endDate}
          startOnChange={handleStartDate}
          endOnChange={handleEndDate}
        />
        <CheckBoxUi
          checked={isAlways}
          htmlFor="always"
          onChange={handleIsAlways}
          title="상시"
        />
      </FormRow>
      <FormRow title="시간">
        <Select disabled={true}>
          <Option value="">출/퇴근</Option>
        </Select>
        <StartAndEndTime
          startTime={startTime}
          endTime={endTime}
          handleChangeTime={handleChangeTime}
        />
      </FormRow>
      <Content>
        <AsonicIconButton
          icon={AddIcon}
          disabled={!selectedTimeTemplateIdx}
          onClick={handleAddTemplateScheduleInfo}
        />
        <AsonicIconButton
          icon={EditIcon}
          disabled={!selectedRow ? true : false}
          onClick={handleUpdateTemplateScheduleInfo}
        />
        <AsonicIconButton
          icon={DeleteIcon}
          disabled={!selectedRow ? true : false}
          onClick={handleDeleteTemplateScheduleInfo}
        />
      </Content>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default TimeTemplateScheduleEditor;
