import styled from "styled-components";

export const LoadingContainer = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingChild = styled.div`
  top: 35%;
  left: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 71px;
  height: 71px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #e74c3c transparent transparent transparent;

  :nth-child(1) {
    animation-delay: -0.45s;
  }

  :nth-child(2) {
    animation-delay: -0.3s;
  }

  :nth-child(3) {
    animation-delay: -0.15s;
  }
`;
