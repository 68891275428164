import * as React from "react";

interface IProps {
  searchData: (offset: number) => void;
  isLoading: boolean;
}

const useMobilePagenation = <P extends IProps>({
  searchData,
  isLoading
}: P) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const handleOffset = React.useCallback((value: number) => {
    setOffset(value);
  }, []);

  const getDataWhenScrolling = React.useCallback(
    (bottomPosition: number, clientHeight: number) => {
      if (bottomPosition === clientHeight && !isLoading) {
        const newOffset = offset + 10;
        handleOffset(newOffset);
        setIsSearch(true);
      }
    },
    [offset, isLoading, handleOffset]
  );

  React.useEffect(() => {
    if (offset > 0 && isSearch) {
      searchData(offset);
      setIsSearch(false);
    }
  }, [offset, searchData, isSearch]);

  return { getDataWhenScrolling, handleOffset };
};

export default useMobilePagenation;
