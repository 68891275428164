import { AutoSizer } from "react-virtualized";
import styled from "styled-components";
import mColors from "../../../../style-sheet/m_colors";
import { useRecoilState } from "recoil";
import mRAStore from "./m_r_a_store";
import { Icon } from "@iconify/react";
import { Approval_User_Type } from "../../../../generated/graphql";
import { colors } from "../../../GlobalStyle/GlobalStyle";
import { useMemo } from "react";
import { IMApprovalUser } from "./m_request_approval_line";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const ListContainer = styled.div<{
  $height: number;
  $width: number;
  $loading?: boolean;
}>`
  position: relative;
  display: inline-block;
  overflow: auto;
  height: ${props => (props.$height ? `${props.$height}px` : "")};
  width: ${props => (props.$width ? `${props.$width}px` : "")};
  flex-direction: column;
  will-change: scroll-position;
  justify-content: ${props => (props.$loading ? "center" : "")};
  align-items: ${props => (props.$loading ? "center" : "")};
  border-radius: 6px;
  background-color: ${mColors.lightGreen};
`;

const ItemContainer = styled.div`
  position: relative;
  padding-left: 12px;
  font-size: 20px;
`;

const Item = styled.div<{ isSelected?: boolean }>`
  display: inline-block;
  gap: 10px;

  align-items: center;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  :active {
    color: ${props => props.theme.colors.lightGreen};
  }
  color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.black};
  user-select: none;
`;

const IconContainer = styled.div<{ $fontSize?: string }>`
  display: inline-block;
  svg {
    vertical-align: middle;
    font-size: ${props => props.$fontSize ?? "24px"};
  }
`;

const UserProfileContainer = styled.div`
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  span {
    display: block;
  }
`;

const DisplayDepartment = styled.span`
  @media only screen and (max-width: 390px) {
    width: 120px;
  }
  text-wrap: wrap;
  overflow-wrap: break-word;
  color: ${props => props.theme.colors.darkGrey};
`;

const ButtonsContainer = styled.div`
  position: absolute;
  display: inline-block;
  right: 10px;
  bottom: 8px;
`;

const BtnContainer = styled(IconContainer)`
  :active {
    transform: scale(0.95);
  }
  padding: 0px 2px;
`;

const SelectContainer = styled.div`
  display: inline-block;
  select {
    background-color: ${mColors.lightGreen};
    border: 1px solid ${props => props.theme.colors.black};
    padding: 5px;
  }
`;

function MSelectedApprovalUserList() {
  const [{ approvalLineUser }, setMRAState] = useRecoilState(mRAStore);

  const selectOptionList = useMemo(() => {
    return [
      {
        name: "결재",
        type: Approval_User_Type.Approval
      },
      {
        name: "합의자",
        type: Approval_User_Type.Agreement
      }
    ];
  }, []);
  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <ListContainer $height={height} $width={width}>
              {approvalLineUser.map(item => (
                <ItemContainer key={`${item.employeeId}-selected`}>
                  <Item>
                    <IconContainer
                      $fontSize="35px"
                      onClick={() => {
                        setMRAState(pre => {
                          const newList = pre.approvalLineUser.filter(
                            user => user.employeeId !== item.employeeId
                          );
                          return { ...pre, approvalLineUser: newList };
                        });
                      }}
                    >
                      <Icon
                        icon={"basil:trash-alt-solid"}
                        color={colors.darkRed}
                      />
                    </IconContainer>
                    <IconContainer $fontSize="45px">
                      <Icon icon={"mdi:user"} />
                    </IconContainer>
                    <UserProfileContainer>
                      <span>{item.name}</span>
                      <DisplayDepartment>{item.department}</DisplayDepartment>
                    </UserProfileContainer>
                    <ButtonsContainer>
                      <SelectContainer>
                        <select
                          name="approvalType"
                          value={item.approvalType}
                          onChange={event => {
                            setMRAState(pre => {
                              const newList: IMApprovalUser[] =
                                pre.approvalLineUser.map(user => {
                                  if (user.employeeId !== item.employeeId) {
                                    return user;
                                  } else {
                                    return {
                                      ...user,
                                      approvalType: event.currentTarget
                                        .value as Approval_User_Type
                                    };
                                  }
                                });
                              return { ...pre, approvalLineUser: newList };
                            });
                          }}
                        >
                          {selectOptionList.map(approvalTypeItem => (
                            <option
                              value={approvalTypeItem.type}
                              key={approvalTypeItem.type}
                            >
                              {approvalTypeItem.name}
                            </option>
                          ))}
                        </select>
                      </SelectContainer>
                      <BtnContainer
                        $fontSize="20px"
                        onClick={() => {
                          setMRAState(pre => {
                            let newList = [...pre.approvalLineUser];
                            const foundUserIndex = newList.findIndex(
                              newUser => newUser.employeeId === item.employeeId
                            );
                            const foundPreUser = newList[foundUserIndex - 1];
                            if (foundUserIndex >= 1) {
                              newList.splice(foundUserIndex - 1, 1, item);
                              newList.splice(foundUserIndex, 1, foundPreUser);
                            }
                            return { ...pre, approvalLineUser: newList };
                          });
                        }}
                      >
                        <Icon icon={"ep:arrow-up-bold"} />
                      </BtnContainer>
                      <BtnContainer
                        $fontSize="20px"
                        onClick={() => {
                          setMRAState(pre => {
                            let newList = [...pre.approvalLineUser];
                            const foundUserIndex = newList.findIndex(
                              newUser => newUser.employeeId === item.employeeId
                            );
                            const foundPreUser = newList[foundUserIndex + 1];
                            if (foundUserIndex < newList.length - 1) {
                              newList.splice(foundUserIndex + 1, 1, item);
                              newList.splice(foundUserIndex, 1, foundPreUser);
                            }
                            return { ...pre, approvalLineUser: newList };
                          });
                        }}
                      >
                        <Icon icon={"ep:arrow-down-bold"} />
                      </BtnContainer>
                    </ButtonsContainer>
                  </Item>
                </ItemContainer>
              ))}
            </ListContainer>
          );
        }}
      </AutoSizer>
    </Container>
  );
}

export default MSelectedApprovalUserList;
