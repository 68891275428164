import api from "../api";

const WORK_HOUR = `${api.api}/workhour`;
const COMMUTE = `${WORK_HOUR}/commute`;
const CALENDAR = `/calendar`;
const CALENDAR_INFORMATION = `${WORK_HOUR}${CALENDAR}/information`;
const CALENDAR_COUNT = `${WORK_HOUR}${CALENDAR}/count`;
const TABLE_COMMUTE = `${WORK_HOUR}/table/commute`;
const TABLE_COMMUTE_COUNT = `${TABLE_COMMUTE}/count`;
const LIMITED_TABLE_COMMUTE = `${TABLE_COMMUTE}/limited`;
const GET_WORK_HOURS_FOR_IME_PERIOD = `${WORK_HOUR}/getworkhoursfortimeperiod`;
const GET_WORK_HOUR_FOR_LIMITED_IME_PERIOD = `${GET_WORK_HOURS_FOR_IME_PERIOD}/limited`;
const GET_WORK_HOUR_FOR_IME_PERIOD_COUNT = `${GET_WORK_HOURS_FOR_IME_PERIOD}/count`;
const GET_WORK_HOUR_RECORDS_FOR_TODAY = `${WORK_HOUR}/getworkhourrecordsfortoday`;
const WORK_TYPES = `${WORK_HOUR}/worktypes`;
const RECORD_WORK_HOUR = `${WORK_HOUR}/recordworkhour`;
const LAST_WORK_HOUR_USER = (employeeid: string) =>
  `${WORK_HOUR}/lastworkhour/${employeeid}`;
const DEPARTMENTS_USER = (employeeid: string) =>
  `${WORK_HOUR}/departments/${employeeid}`;
const END_POINT_OF_EMPLOYEE = `${WORK_HOUR}/endpointofemployee`;
const GET_PUBLIC_ENDPOINT_COUNT = `${END_POINT_OF_EMPLOYEE}/public/count`;
const GET_LIMITED_PUBLIC_ENDPOINT_INFO = `${END_POINT_OF_EMPLOYEE}/public/limit`;
const GET_PC_COUNT = `${END_POINT_OF_EMPLOYEE}/count`;
const GET_LIMITED_PC_INFO = `${END_POINT_OF_EMPLOYEE}/limit`;
const EMPLOYEE_RESOURCE = `${WORK_HOUR}/employeeresource`;
const EMPLOYEE_RESOURCE_COUNT = `${WORK_HOUR}/employeeresource/count`;
const GET_LIMITED_EMPLOYEE_INFO = `${WORK_HOUR}/limited/employeeresource`;
const MY_WORK_EVALUATION = `${WORK_HOUR}/my/evaluation`;

const workHourApi = {
  commute: COMMUTE,
  calendar: CALENDAR,
  calendarInformation: CALENDAR_INFORMATION,
  calendarCount: CALENDAR_COUNT,
  tableCommute: TABLE_COMMUTE,
  tableCommuteCount: TABLE_COMMUTE_COUNT,
  tableCommuteLimited: LIMITED_TABLE_COMMUTE,
  getWorkHoursForImePeriod: GET_WORK_HOURS_FOR_IME_PERIOD,
  getWorkHourForLimitedImePeriod: GET_WORK_HOUR_FOR_LIMITED_IME_PERIOD,
  getWorkHoursForImePeriodCount: GET_WORK_HOUR_FOR_IME_PERIOD_COUNT,
  getWorkHourRecordsForToday: GET_WORK_HOUR_RECORDS_FOR_TODAY,
  workTypes: WORK_TYPES,
  recordWorkHour: RECORD_WORK_HOUR,
  lastWorkHourUser: LAST_WORK_HOUR_USER,
  departmentsUser: DEPARTMENTS_USER,
  getPublicEndpointCount: GET_PUBLIC_ENDPOINT_COUNT,
  getLimitedPublicEndpointInfo: GET_LIMITED_PUBLIC_ENDPOINT_INFO,
  getPcCount: GET_PC_COUNT,
  getLimitedPcInfo: GET_LIMITED_PC_INFO,
  endPointOfEmployee: END_POINT_OF_EMPLOYEE,
  employeeResource: EMPLOYEE_RESOURCE,
  employeeResourceCount: EMPLOYEE_RESOURCE_COUNT,
  getLimitedEmployeeInfo: GET_LIMITED_EMPLOYEE_INFO,
  myWorkEvaluation: MY_WORK_EVALUATION
};

export default workHourApi;
