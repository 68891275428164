import { useState, useEffect, useRef } from "react";
import getWorkScheduleState from "./states/getWorkScheduleState";
import isUpdateTreeDataState from "./states/isUpdateTreeDataState";
import treeDataState from "./states/treeDataState";
import createEmergencyKey from "./states/createEmergencyKey";

const StateManager = props => {
  const {
    store: { selectedDepartment }
  } = props;
  const { treeData, setTreeData } = treeDataState(props.treeData),
    [totalUserInfo, setTotalUserInfo] = useState([]),
    [userInfo, setUserInfo] = useState([]),
    [fullDepartmentName, setFullDepartmentName] = useState(""),
    [fullDepartmentNameList, setFullDepartmentNameList] = useState({}),
    [departmentNames, setDepartmentName] = useState({}),
    [pcEmployeeIds, setPcEmployeeIds] = useState([]),
    [userLoading, setUserLoading] = useState(false),
    [treeWidth, setTreeWidth] = useState(""),
    [isTreeWindowClick, setIsTreeWindowClick] = useState(false),
    [userInfoHeight, setUserInfoHeight] = useState(""),
    [isUserInfoWindowClicked, setUserINfoWIndowClicked] = useState(false),
    [checkUserInfoForMulti, setCheckUserInfoForMulti] = useState({}),
    [firstSelected, setFirstClicked] = useState(null),
    [pcLoading, setPcLoading] = useState(false),
    { workingTemplate } = getWorkScheduleState(),
    { setIsUpdateTreeData } = isUpdateTreeDataState(
      props.signInReducer.employee_id,
      setTreeData
    ),
    emergencyKey = createEmergencyKey();
  const isMounted = useRef(false);

  const handlePcLoading = loading => {
    setPcLoading(loading);
  };
  // userWindowHandler
  const userWindowMouseDown = () => {
    setUserINfoWIndowClicked(true);
  };

  const changeUserInfoSize = event => {
    setUserInfoHeight(`${event.clientY - 55}`);
  };

  const userInfoWindowMouseMove = event => {
    changeUserInfoSize(event);
  };

  // treeWindowhandler
  const changeTreeSize = event => {
    setTreeWidth(`${event.clientX}`);
  };

  const treeWindowMouseDown = () => {
    setIsTreeWindowClick(true);
  };

  const treeWindowMouseMove = event => {
    changeTreeSize(event);
  };

  const windowMouseMove = event => {
    if (isTreeWindowClick) {
      treeWindowMouseMove(event);
    } else if (isUserInfoWindowClicked) {
      userInfoWindowMouseMove(event);
    }
  };

  const WindowMouseUp = () => {
    setIsTreeWindowClick(false);
    setUserINfoWIndowClicked(false);
  };

  const handleUserLoading = loading => {
    setUserLoading(loading);
  };

  // UserInfo
  const updatePcEmployeeIds = id => event => {
    // MultiClick
    if (pcEmployeeIds.length > 0 && event.shiftKey) {
      let firstItem = firstSelected;
      if (firstItem === null) {
        firstItem =
          checkUserInfoForMulti[pcEmployeeIds[pcEmployeeIds.length - 1]];
        setFirstClicked(firstItem);
      }
      const nextSelected = checkUserInfoForMulti[id];

      const findIndex = pcEmployeeIds.indexOf(
        userInfo[nextSelected].employee_id
      );
      if (firstItem === nextSelected) {
        const filtering = pcEmployeeIds.slice(0, findIndex);
        setPcEmployeeIds([...filtering, id]);
        return;
      }

      if (firstItem === nextSelected) {
        return;
      }
      handlePcLoading(true);
      let multiUsers;

      if (firstItem < nextSelected) {
        multiUsers = userInfo.slice(firstItem, nextSelected).map(item => {
          return item.employee_id;
        });
        if (findIndex > 0) {
          const filtering = pcEmployeeIds.slice(0, findIndex);
          setPcEmployeeIds([...filtering, id]);
          return;
        }
        const newPc = multiUsers.filter(item => {
          return !pcEmployeeIds.includes(item);
        });
        setPcEmployeeIds([...pcEmployeeIds, ...newPc, id]);
      } else if (firstItem > nextSelected) {
        multiUsers = userInfo.slice(nextSelected, firstItem).map(item => {
          return item.employee_id;
        });

        if (findIndex > 0) {
          const filtering = pcEmployeeIds.slice(0, findIndex);
          setPcEmployeeIds([...filtering, id]);
          return;
        }

        const newPc = multiUsers.filter(item => {
          return !pcEmployeeIds.includes(item);
        });
        setPcEmployeeIds([...pcEmployeeIds, ...newPc.reverse()]);
      }
      return;
    }
    if (
      pcEmployeeIds.length > 0 &&
      !pcEmployeeIds.includes(id) &&
      event.ctrlKey
    ) {
      handlePcLoading(true);
      setFirstClicked(checkUserInfoForMulti[id]);
      setPcEmployeeIds([...pcEmployeeIds, id]);
    } else if (
      pcEmployeeIds.length > 0 &&
      pcEmployeeIds.includes(id) &&
      event.ctrlKey
    ) {
      handlePcLoading(true);
      setPcEmployeeIds(pcEmployeeIds.filter(item => item !== id));
    } else if (event.button === 2 && Array.isArray(id) && id.length > 1) {
      setPcEmployeeIds([...id]);
    } else if (event.button === 2) {
      setPcEmployeeIds([id]);
    } else if (pcEmployeeIds.length === 1 && pcEmployeeIds.includes(id)) {
      handlePcLoading(true);
      setPcEmployeeIds([]);
      setFirstClicked(null);
    } else {
      handlePcLoading(true);
      setPcEmployeeIds([id]);
      setFirstClicked(checkUserInfoForMulti[id]);
    }
  };

  const updateDepartmentName = newDepartmentNames => {
    setDepartmentName(newDepartmentNames);
  };

  const updateFullDepartmentName = fullName => {
    setFullDepartmentName(fullName);
  };

  const updateUserInfo = data => {
    setUserInfo(data);
  };

  if (treeData.length > 0 && Object.keys(departmentNames).length === 0) {
    const newDepartmentNames = {};
    const newFullDepartmentNameList = {};
    for (let i = 0; i < treeData.length; i++) {
      newDepartmentNames[treeData[i].department_id] = treeData[i].name;
      newFullDepartmentNameList[treeData[i].department_id] =
        treeData[i].FullGName;
    }
    updateDepartmentName(newDepartmentNames);
    setFullDepartmentNameList(newFullDepartmentNameList);
  }

  useEffect(() => {
    isMounted.current = true;
    if (userInfo?.length > 0) {
      const result = {};
      for (let i = 0; i < userInfo.length; i++) {
        result[userInfo[i].employee_id] = i;
      }
      if (isMounted.current) {
        setCheckUserInfoForMulti(result);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [userInfo]);

  useEffect(() => {
    if (selectedDepartment) {
      setPcEmployeeIds([]);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    document.addEventListener("mouseup", WindowMouseUp);
    return () => {
      document.removeEventListener("mouseup", WindowMouseUp);
    };
  }, []);

  return {
    treeData,
    userInfo,
    updateUserInfo,
    fullDepartmentName,
    updateFullDepartmentName,
    departmentNames,
    updateDepartmentName,
    pcEmployeeIds,
    updatePcEmployeeIds,
    setPcEmployeeIds,
    fullDepartmentNameList,
    pcLoading,
    handlePcLoading,
    userLoading,
    handleUserLoading,
    treeWidth,
    changeTreeSize,
    isTreeWindowClick,
    setIsTreeWindowClick,
    treeWindowMouseDown,
    userWindowMouseDown,
    windowMouseMove,
    userInfoHeight,
    isUserInfoWindowClicked,
    workingTemplate,
    setIsUpdateTreeData,
    ...emergencyKey,
    totalUserInfo,
    setTotalUserInfo
  };
};

export default StateManager;
