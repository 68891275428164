import HelpContainer from "../../../shared/help_container/help_container";
import InfoIcon from "../../../shared/info-icon/info-icon";

function WDAdditionalSettingHelp() {
  return (
    <InfoIcon
      minHeight="280px"
      height="280px"
      minWidth="300px"
      title="시차출퇴근제 - 근무일 추가 설정"
    >
      <HelpContainer>
        <span>{`시차 출퇴근제 사용시 특정일에 다른 근무시간을 적용합니다.`}</span>
        <ul>
          <li>{`근무일 추가 설정`}</li>
          <pre>{`특정일에 대하여 별도의 근무시간을 적용하는 경우 사용합니다.
근무일 추가 설정은 일단위로 반영되며, 기본 근무시간보다 우선 적용
됩니다. 근무일 추가 설정 일은 중복 일자로 적용하실수 없습니다.`}</pre>
          <li>{`근무일 추가 설정의 휴게시간 사용 설정`}</li>
          <pre>{`근무일 추가 설정에서 휴게 시간을 별도로 적용 하시는 경우 사용하며,
“기본 휴게시간 사용”을 사용하시는 경우 기본 근무시간 설정의 휴게 시
간을 사용합니다. “별도설정” 을 사용 하시는 경우 별도의 휴게 시간 설
정이 가능하며  설정된 휴게 시간은 해당 추가설정 근무일에만 반영됩
니다. `}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default WDAdditionalSettingHelp;
