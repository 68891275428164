import { useCallback, useState } from "react";
import { ContentContainer, TextContainer } from "./styled";
import ToastMessage, {
  MessageTypes
} from "../../../../components/toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import { WEB_SERVER_ADDR } from "../../../../constValues";
import jwt from "jsonwebtoken";
import axios from "axios";
import Auth from "../../../../Auth/Auth";
import settings from "../../../../config/settings";
import TextField from "../../../../components/main-view/settings/TextField";
import CollapsiblePanel from "../../../../components/main-view/smallcomponents/CollapsiblePanel";
import Button from "../../../../components/globalComponents/Button";
import styled from "styled-components";

const CURRENT_PASSWORD = 1;
const NEW_PASSWORD = 2;
const RE_ENTER_NEW_PASSWORD = 3;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  div {
    :first-child {
      min-width: 230px;
    }
  }
`;

const SettingForm = ({ props }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnteredNewPassword, setReEnteredNewPassword] = useState("");

  const handleChange = useCallback(
    id => event => {
      if (id === 1) {
        setCurrentPassword(event.target.value);
      } else if (id === 2) {
        setNewPassword(event.target.value);
      } else if (id === 3) {
        setReEnteredNewPassword(event.target.value);
      }
    },
    []
  );
  const { signInReducer } = props;

  const { isOpen, handleIsOpen, message, handleToast, toastMessageType } =
    useOpenToastMessage();

  const saveThePassword = useCallback(async () => {
    if (newPassword !== reEnteredNewPassword) {
      handleToast(
        "새로 입력하신 패스워드가 일치하지 않습니다.",
        MessageTypes.WARNING
      );
      return;
    }

    let restApiUrl = `${WEB_SERVER_ADDR}/api/settings/Password`;

    let secretToken = jwt.sign(
      {
        userId: signInReducer.employee_id,
        currentPassword,
        newPassword
      },
      settings.secret
    );

    try {
      const ret = await axios.post(
        restApiUrl,
        {
          secretToken
        },
        {
          headers: {
            token: Auth.getUserToken()
          }
        }
      );
      if (ret.status !== 200) {
        handleToast(
          "현재 패스워드를 잘못 입력하셨습니다.",
          MessageTypes.WARNING
        );
      } else {
        handleToast(
          "패스워드를 성공적으로 변경하였습니다.",
          MessageTypes.SUCCESS
        );
      }
    } catch (error) {
      handleToast("현재 패스워드를 잘못 입력하셨습니다.", MessageTypes.WARNING);
    }
  }, [
    currentPassword,
    newPassword,
    reEnteredNewPassword,
    signInReducer.employee_id,
    handleToast
  ]);

  return (
    <Container>
      <CollapsiblePanel title="패스워드 변경">
        <ContentContainer>
          <TextContainer>
            <TextField
              value={currentPassword}
              valueChange={handleChange(CURRENT_PASSWORD)}
              placeholder="현재 패스워드를 입력하세요"
              label="현재 패스워드"
              type="password"
            />
          </TextContainer>
          <TextContainer>
            <TextField
              value={newPassword}
              valueChange={handleChange(NEW_PASSWORD)}
              placeholder="변경할 패스워드를 입력하세요"
              label="새로운 패스워드"
              type="password"
            />
          </TextContainer>
          <TextContainer>
            <TextField
              value={reEnteredNewPassword}
              valueChange={handleChange(RE_ENTER_NEW_PASSWORD)}
              placeholder="변경할 패스워드를 한번더 입력하세요"
              label="새로운 패스워드 확인"
              type="password"
            />
          </TextContainer>
          <Button onClick={saveThePassword}>변경 하기</Button>
        </ContentContainer>
        <ToastMessage
          message={message}
          isOpen={isOpen}
          handleIsOpen={handleIsOpen}
          messageTypes={toastMessageType}
          widget={true}
        />
      </CollapsiblePanel>
    </Container>
  );
};

export default SettingForm;
