import styled from "styled-components";

interface IPopupControllerProps {
  handleShowApprovalPopup: (value: boolean) => void;
  handleSelectForm?: (isNext: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const PageControllerContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0px 10px;
  button {
    min-width: 50px;
  }
  background-color: ${props => props.theme.colors.grey};
`;

const PageControlButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

const PageButton = styled.button`
  font-weight: bold;
  height: 30px;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  border: none;
  outline: none;
  padding: 6px;
  min-width: 100px;
  background-color: transparent;
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.98);
  }
  font-family: "Gothic", sans-serif;
  opacity: ${props => (props.disabled ? "0.2" : "1")};
`;
function PopupController({
  handleShowApprovalPopup,
  handleSelectForm
}: IPopupControllerProps) {
  return (
    <Container>
      <PageControllerContainer>
        <PageControlButtonContainer>
          <PageButton
            onClick={() => {
              if (handleSelectForm) {
                handleSelectForm(false);
              }
            }}
          >{`< 이전`}</PageButton>
          <PageButton
            onClick={() => {
              if (handleSelectForm) {
                handleSelectForm(true);
              }
            }}
          >{`다음 >`}</PageButton>
        </PageControlButtonContainer>
        <PageButton
          onClick={() => {
            handleShowApprovalPopup(false);
          }}
        >{`목록 보기`}</PageButton>
      </PageControllerContainer>
    </Container>
  );
}

export default PopupController;
