import searchTerm from "./searchTerm";
import searchDepartment from "./searchDepartment";
import searchIndex from "./searchIndex";

const searchStateManager = () => {
  const { term, updateTerm } = searchTerm();
  const { searchedDe, updateSearchDe } = searchDepartment(term);
  const { searchSelectedIndex, upItem, downItem } = searchIndex(searchedDe);

  return {
    term,
    updateTerm,
    updateSearchDe,
    searchedDe,
    upItem,
    downItem,
    searchSelectedIndex
  };
};

export default searchStateManager;
