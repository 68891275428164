import styled from "styled-components";
import Button from "../../../globalComponents/Button";

export const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 12;
  justify-content: center;
  align-items: center;
  background-color: white;
  ${Button} {
    margin-top: 10px;
    align-self: flex-end;
  }
`;

export const NumberContentsContainer = styled.div``;
