import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  subTitle: string;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 20px;
  gap: 10px;
  font-size: 14px;
  user-select: none;
  min-height: 40px;
  max-height: 41px;
  height: 41px;
  svg {
    cursor: pointer;
    :active {
      font-size: 18px;
    }
  }
`;

export const Title = styled.span<Partial<Props>>`
  color: ${props => props.theme.colors.black};
  font-weight: bold;
  margin: 0;
`;

export const SubTitle = styled.span`
  font-weight: bold;
  margin: 0;
`;

export const Arrow = styled.h5`
  color: #7f8c8d;
`;

export const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.green};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.9);
  }
  cursor: pointer;
`;
