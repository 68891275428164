import { useCallback, useEffect, useMemo, useState } from "react";
import masterWorkManagementPageApi from "../../../api/master-work-management-page-api";

type DataFromServer = {
  asbasicmin: number;
  astotmin: number;
  astotmin_ext: number;
  astotmin_work: number;
  name: string;
};

type Props = { isRenewal: boolean; isOnlyDepartmentName: boolean };

type Datasets = {
  type: string;
  backgroundColor: string;
  borderColor: string;
  data: number[];
  label?: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  pointRadius?: number;
  tension: number;
  datalabels: {
    display: boolean;
    clamp?: boolean;
  };
};

type DataType =
  | typeof REGULAR_WORK
  | typeof OVER_TIME_WORK
  | typeof TOTAL_WORK
  | typeof STANDARD_WORK;

const BAR = "bar",
  LINE = "line";

const REGULAR_WORK = "정규근무",
  OVER_TIME_WORK = "연장근무",
  TOTAL_WORK = "총근무",
  STANDARD_WORK = "기준 근로시간";

const useAverageWorkingHoursDepartmentChart = <P extends Props>({
  isRenewal,
  isOnlyDepartmentName
}: Partial<P>) => {
  const [regularWorkData, setRegularWorkData] = useState<number[]>([]);
  const [overTimeWorkData, setOverTimeWorkData] = useState<number[]>([]);
  const [standardWorkData, setStandardWorkData] = useState<number[]>([]);
  const [totalWorkData, setTotalWorkData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const makeDataSets = useCallback(
    (dataType: DataType) => {
      let data: number[] = [];
      let label = "";
      let type = BAR;
      let color = "#4C8AC8";
      let datalabels = {
        display: false
      };
      if (dataType === OVER_TIME_WORK || dataType === REGULAR_WORK) {
        if (dataType === REGULAR_WORK) {
          data = regularWorkData;
          label = REGULAR_WORK;
        } else {
          color = "#053D80";
          data = overTimeWorkData;
          label = OVER_TIME_WORK;
        }
      } else if (dataType === TOTAL_WORK || dataType === STANDARD_WORK) {
        if (dataType === TOTAL_WORK) {
          datalabels.display = true;
          color = "#F73763";
          data = totalWorkData;
          label = TOTAL_WORK;
        } else {
          color = "#32BADB";
          data = standardWorkData;
          label = STANDARD_WORK;
        }
        type = LINE;
      }
      const dataSet: Datasets = {
        type: type,
        backgroundColor: color,
        borderColor: color,
        data,
        label,
        barThickness: 18,
        maxBarThickness: 20,
        minBarLength: 2,
        pointRadius: 1,
        tension: 0.2,
        datalabels
      };
      return dataSet;
    },
    [regularWorkData, standardWorkData, overTimeWorkData, totalWorkData]
  );

  const datasets = useMemo(() => {
    const regularWorkDataSets = makeDataSets(REGULAR_WORK);
    const overTimeWorkDataSets = makeDataSets(OVER_TIME_WORK);
    const totalTimeWorkDataSets = makeDataSets(TOTAL_WORK);
    const standardWorkDataSets = makeDataSets(STANDARD_WORK);

    return [
      standardWorkDataSets,
      totalTimeWorkDataSets,
      regularWorkDataSets,
      overTimeWorkDataSets
    ];
  }, [makeDataSets]);

  const chartData = useMemo(() => {
    let newLabels = labels;
    if (isOnlyDepartmentName) {
      newLabels = newLabels.map(item => {
        const name = item.split(">");
        const newLabel = name[name.length - 1];
        return newLabel;
      });
    }

    const chartData = {
      labels: newLabels,
      datasets
    };
    return chartData;
  }, [labels, datasets, isOnlyDepartmentName]);

  const handleData = useCallback(async () => {
    try {
      if (isRenewal) {
        const params = {};
        const { data } =
          await masterWorkManagementPageApi.averageWorkingHoursDepartment(
            params
          );

        const newRegularWorkData: number[] = [],
          newOverTimeWorkData: number[] = [],
          newStandardWorkDAta: number[] = [],
          newTotalWorkData: number[] = [],
          newLabels: string[] = [];
        if (data && data.length > 0) {
          data.forEach((item: DataFromServer) => {
            newLabels.push(item.name);
            newRegularWorkData.push(Math.floor(item.astotmin_work / 60));
            newOverTimeWorkData.push(Math.floor(item.astotmin_ext / 60));
            newStandardWorkDAta.push(Math.floor(item.asbasicmin / 60));
            newTotalWorkData.push(Math.floor(item.astotmin / 60));
          });
          setLabels(newLabels);
          setRegularWorkData(newRegularWorkData);
          setOverTimeWorkData(newOverTimeWorkData);
          setStandardWorkData(newStandardWorkDAta);
          setTotalWorkData(newTotalWorkData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [isRenewal]);

  useEffect(() => {
    handleData();
  }, [handleData]);

  return { chartData };
};

export default useAverageWorkingHoursDepartmentChart;
