import { useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { IPageControllerFields } from "./asonic-page-controller";
import { ITEMS_PER_PAGE } from "../../screens/constants";

export interface IAsonicPageSetProps {
  take: number;
  handleTake: (value: string) => void;
}

const SelectContainer = styled.div`
  display: flex;
  gap: 5px;
  label {
    display: flex;
    align-items: center;
    text-align: center;
    min-width: max-content;
  }
`;

function AsonicPageSet({ take, handleTake }: IAsonicPageSetProps) {
  const { register, getValues } = useForm<IPageControllerFields>({
    mode: "onSubmit",
    defaultValues: {
      take: take.toString()
    }
  });
  const takeRegister = register("take", { required: true });

  const rows = useMemo(() => ITEMS_PER_PAGE, []);
  return (
    <SelectContainer>
      <label htmlFor="row">페이지당 수: </label>
      <select
        id="row"
        {...takeRegister}
        onChange={event => {
          takeRegister.onChange(event);
          const take = getValues("take");
          if (take) {
            handleTake(take);
          }
        }}
      >
        {rows.map((item, index) => (
          <option key={index} value={item}>
            {item}개
          </option>
        ))}
      </select>
    </SelectContainer>
  );
}

export default AsonicPageSet;
