import styled from "styled-components";

interface IProps {
  title: string;
  subTitle?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

const CirclePoint = styled.span`
  margin-top: 2.5px;
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.green};
`;

const Title = styled.span`
  display: flex;
  flex: 1;
  user-select: none;
  display: flex;
  font-weight: bold;
  min-width: max-content;
  font-size: 14px;
`;

const SubTitle = styled.span`
  display: flex;
  flex: 1;
  user-select: none;
  display: flex;
  min-width: max-content;
`;

function PointTitle({ title, subTitle }: IProps) {
  return (
    <Container>
      <CirclePoint />
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Container>
  );
}

export default PointTitle;
