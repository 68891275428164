import styled from "styled-components";

export const CategorySelect = styled.select`
  && {
    padding: 0 20px 0 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.veryDarkBlue};
  font-weight: bold;
`;
