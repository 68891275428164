import styled from "styled-components";
import useAuthenticateGoogleUser from "../../../hooks/link-google-hooks/use-authenticate-google-user";
import useGoogleCalendar from "../../../hooks/link-google-hooks/use-google-calendar";
import MiniCaledarTitleBar from "./mini-calendar-title-bar/mini-calendar-title-bar";
import useCalendar from "../../../hooks/calendar-hooks/use-calendar/use-calendar";
import MiniCalendar from "./mini-calendar/mini-calendar";
import MiniCalendarContext from "./mini-calendar-context/mini-calendar-context";
import useSelectedEventList from "../../../hooks/calendar-hooks/use-selected-event-list/use-selected-event-lits";
import MiniCalendarEventList from "./mini-calendar-event-list/mini-calendar-event-list";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TitleBar = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
  display: flex;
  flex: 1;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex: 6;
`;

const CalendarContainerList = styled.div`
  display: flex;
  flex: 4;
`;

const CalendarInWidget = () => {
  const {
    currentMoment,
    handleCurrentMoment,
    handleMoveMonth,
    weeks
  } = useCalendar();

  const {
    authorize,
    handleSignoutClick,
    isLogin
  } = useAuthenticateGoogleUser();

  const {
    googleCalendarlist,
    handleCalendarList,
    googleEventOfList,
    listOfGoogleColor
  } = useGoogleCalendar({
    isLogin,
    currentMoment
  });

  const {
    selectedEventList,
    handleSelectedEventList,
    selectedDate,
    handleSelectedDate
  } = useSelectedEventList();

  return (
    <MiniCalendarContext.Provider
      value={{
        googleEventOfList,
        googleCalendarlist,
        listOfGoogleColor,
        currentMoment,
        selectedEventList,
        handleSelectedEventList,
        selectedDate,
        handleSelectedDate
      }}
    >
      <Container>
        <TitleBar>
          <MiniCaledarTitleBar
            currentMoment={currentMoment}
            handleCurrentMoment={handleCurrentMoment}
            handleMoveMonth={handleMoveMonth}
            authorize={authorize}
            handleSignoutClick={handleSignoutClick}
            isLogin={isLogin}
            handleCalendarList={handleCalendarList}
            googleCalendarlist={googleCalendarlist}
            listOfGoogleColor={listOfGoogleColor}
          />
        </TitleBar>
        <CalendarContainer>
          <MiniCalendar weeks={weeks} />
        </CalendarContainer>
        <CalendarContainerList>
          <MiniCalendarEventList data={selectedEventList} />
        </CalendarContainerList>
      </Container>
    </MiniCalendarContext.Provider>
  );
};

export default CalendarInWidget;
