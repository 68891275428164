import CollapsiblePanel from "../main-view/smallcomponents/CollapsiblePanel";
import MenuCheckBoxList from "../main-view/settings/MenuCheckBoxList";
import { ContentContainer, DivideContentContainer } from "./styled";
import useGetData from "../../hooks/etc-settings-hook/use-get-data";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage from "../toast-message/toast-message";

const EtcSettings = () => {
  const {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();
  const getData = useGetData({
    handleIsOpen,
    handleMessage,
    handleToastMessageType
  });
  return (
    <CollapsiblePanel title="기타설정">
      <ContentContainer>
        <DivideContentContainer>
          <MenuCheckBoxList title="Public Device 설정" {...getData} />
        </DivideContentContainer>
      </ContentContainer>
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </CollapsiblePanel>
  );
};

export default EtcSettings;
