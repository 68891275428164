import {
  // call,
  // take,
  // select,
  // all,
  // race,
  takeEvery
  // fork,
  // cancel
} from "redux-saga/effects";

import {
  GET_APPROVAL_DOCS,
  UPDATE_APPROVAL_DOC
} from "../../redux/modules/main-view/approval/approvalReducer";
import {
  GET_REQUEST_DOCS,
  GET_REQUEST_DOCS_FOR_CAL,
  MAKE_NEW_REQUEST
} from "../../redux/modules/main-view/request/requestReducer";
import { GET_CATEGORIES } from "../../redux/modules/main-view/categorySelectionReducer";
import {
  getApprovalDocsAsync,
  updateApprovalDocAsync
} from "./operations/approvalOperations";
import {
  getRequestDocsAsync,
  getRequestDocsForCalAsync,
  makeNewRequestAsync
} from "./operations/requestOperations";
import { getCategoriesAsync } from "./operations/categoryOperations";
import { getWorkingHoursAsync } from "./operations/mainViewOperations";
import { GET_WORKING_HOURS } from "../modules/main-view/mainViewReducer";

export function* watchGetApprovalDocs() {
  yield takeEvery(GET_APPROVAL_DOCS, getApprovalDocsAsync);
}

export function* watchUpdateApprovalDoc() {
  yield takeEvery(UPDATE_APPROVAL_DOC, updateApprovalDocAsync);
}

export function* watchGetRequestDocs() {
  yield takeEvery(GET_REQUEST_DOCS, getRequestDocsAsync);
}

export function* watchGetRequestDocsForCal() {
  yield takeEvery(GET_REQUEST_DOCS_FOR_CAL, getRequestDocsForCalAsync);
}

export function* watchGetWorkingHours() {
  yield takeEvery(GET_WORKING_HOURS, getWorkingHoursAsync);
}

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES, getCategoriesAsync);
}

export function* watchMakeNewRequest() {
  yield takeEvery(MAKE_NEW_REQUEST, makeNewRequestAsync);
}
