import { useCallback, useEffect, useState } from "react";
import {
  Approval_User_Type,
  EmployeeInformation,
  GroupEntity,
  UserOfSearchedApprovalLineEntity
} from "../../../../generated/graphql";
import { ICompactRow } from "../../../compact-table/compact-table-render-row";
import { IEmployeeInformation } from "../../approval-process/list-of-selected-approval-user";
import ApprovalProcessView from "../approval-process-management/approval-process-view";
import { Container, Content, ButtonContainer } from "./StyledComponents";
import ApprovalSelectAddRemoveButtons from "./approval-select-add-remove-buttons";
import ListOfSelectedUsersForForm from "./list-of-selected-users-for-form";

interface IProps {
  handleSetListOfUser?: (list: IEmployeeInformation[]) => void;
  listOfUserFromParent: IEmployeeInformation[];
}

export interface IUserOfSearchedApprovalLineEntity
  extends Omit<UserOfSearchedApprovalLineEntity, "__typename"> {}

// 결재관리 - 결재설정 - 관리자설정 - 결재 양식 관리 - 사용자 설정
function ApprovalSelectUsersTreeAndListView({
  handleSetListOfUser,
  listOfUserFromParent
}: IProps) {
  // 왼쪽 리스트에서 선택된 사용자를 관리하기 위한 state
  const [selectedUser, setSelectedUser] =
    useState<ICompactRow<EmployeeInformation>>();
  // 오른쪽 리스트에 추가된 사용자들
  const [listOfUser, setListOfUser] =
    useState<IEmployeeInformation[]>(listOfUserFromParent);
  // 오른쪽 리스트에 추가된 사용자 중 체크된 사용자들
  const [authUsersCheckedList, setAuthUsersCheckedList] = useState<string[]>(
    []
  );
  const [selectedDepartment, setSelectedDepartment] = useState<GroupEntity>();

  const handleSelectedDepartment = useCallback((department: GroupEntity) => {
    setSelectedDepartment(department);
  }, []);

  const handleAddUser = useCallback(
    (user?: ICompactRow<EmployeeInformation>) => {
      setSelectedUser(user);
    },
    []
  );

  const toggleCheckedUser = useCallback((employeeId: string) => {
    setAuthUsersCheckedList(prev => {
      // 만약 값이 있으면 list에서 삭제
      if (prev.includes(employeeId)) {
        return prev.filter(id => id !== employeeId);
      }
      // 만약 값이 없으면 list에 추가
      return [...prev, employeeId];
    });
  }, []);

  const addUserToList = useCallback(
    (
      selectedUser: ICompactRow<EmployeeInformation> | undefined,
      setListOfUser: React.Dispatch<
        React.SetStateAction<IEmployeeInformation[]>
      >
    ) => {
      if (!selectedUser) return;
      setListOfUser(list => {
        if (list.length === 0) {
          return [
            {
              approvalType: Approval_User_Type.Approval,
              name: selectedUser.original.name,
              department: selectedUser.original.departName,
              userPosition: selectedUser.original.userPosition ?? "",
              employeeId: selectedUser.original.employeeId
            }
          ];
        }

        // 만약 같은 employeeId가 있으면 아무것도 하지 않고 list를 리턴한다
        const isSameEmployeeId = list.some(item => {
          return item.employeeId === selectedUser.original.employeeId;
        });
        if (isSameEmployeeId) return list;

        return [
          ...list,
          {
            approvalType: Approval_User_Type.Approval,
            name: selectedUser.original.name,
            department: selectedUser.original.departName,
            userPosition: selectedUser.original.userPosition ?? "",
            employeeId: selectedUser.original.employeeId
          }
        ];
      });
    },
    []
  );

  useEffect(() => {
    if (handleSetListOfUser) {
      handleSetListOfUser(listOfUser);
    }
  }, [listOfUser]);

  useEffect(() => {
    setListOfUser(listOfUserFromParent);
  }, [listOfUserFromParent]);

  return (
    <Container>
      <Content>
        <ApprovalProcessView
          handleSelectedDepartment={handleSelectedDepartment}
          selectedDepartment={selectedDepartment?.departmentId}
          handleAddUser={handleAddUser}
          selectedTab={"조직도"}
          handleSelectTab={() => {}}
          hiddenGroupTab={false}
          hiddenApprovalTab={true}
        />
        <ButtonContainer>
          <ApprovalSelectAddRemoveButtons
            addUser={() => addUserToList(selectedUser, setListOfUser)}
            // authUsersCheckedList에 있는 employeeId와 listOfUser의 각 item의 employeeId가 일치하면 listOfUser에서 삭제
            // listOfUser에서 삭제 후 authUsersCheckedList에서도 삭제
            deleteUsers={() => {
              setListOfUser(list => {
                return list.filter(
                  user => !authUsersCheckedList.includes(user.employeeId)
                );
              });
              setAuthUsersCheckedList([]);
            }}
          />
        </ButtonContainer>
        <ListOfSelectedUsersForForm
          listOfUser={listOfUser}
          handleCheckedUser={(employeeId: string) =>
            toggleCheckedUser(employeeId)
          }
          listOfCheckedUser={authUsersCheckedList}
          handleAllCheckedUser={(employeeIds: string[]) => {
            setAuthUsersCheckedList(employeeIds);
          }}
        />
      </Content>
    </Container>
  );
}

export default ApprovalSelectUsersTreeAndListView;
