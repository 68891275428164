import React, { useCallback } from "react";
import {
  ItemList,
  Item,
  ItemContent,
  ContentTitle,
  IconContainer
} from "../../../../components/main-view/WorkManagementInfo/Tree/styled";
import IsOpened from "./IsOpened";
import {
  useWorkmanagementDispatch,
  handleSelectedDepartmentDispatch
} from "../../WorkManagementInfoContainer/WorkViewContainer/WorkManagementContext";
import listOfApolloVar from "../../../../apollo/apollo-var";
import { Icon } from "@iconify/react";
import FolderIcon from "@iconify/icons-fa-solid/folder";
import FolderOpenIcon from "@iconify/icons-fa-solid/folder-open";
import FileIcon from "@iconify/icons-fa-solid/file-alt";

const StateManager = handleContextMenu => {
  const dispatch = useWorkmanagementDispatch();
  const getUsers = useCallback(
    (data, setSelectedTreeItem) => () => {
      const { department_id } = data;
      setSelectedTreeItem(department_id);
      handleSelectedDepartmentDispatch(dispatch, department_id);
    },
    [dispatch]
  );
  const drawTreeFromData = (
    data,
    parentId,
    fullDepartmentNameList,
    searchedDe,
    searchSelectedIndex,
    selectedTreeItem,
    setSelectedTreeItem
  ) => {
    const accumulator = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentdepartment_id === parentId) {
        const item = data[i];
        const { isOpened, handleOpenItem } = IsOpened(item, parentId);
        const newArray = [...data];
        newArray.splice(i, 1);
        const list = drawTreeFromData(
          newArray,
          data[i].department_id,
          fullDepartmentNameList,
          searchedDe,
          searchSelectedIndex,
          selectedTreeItem,
          setSelectedTreeItem
        );
        accumulator.push(
          <React.Fragment key={i}>
            <Item
              onClick={event => {
                getUsers(data[i], setSelectedTreeItem)(event);
                listOfApolloVar.selectedDepartmentInfoVar(data[i]);
                listOfApolloVar.selectedDepartmentVar(
                  data[i].department_id.toString()
                );
              }}
              onContextMenu={event => {
                handleContextMenu([data[i].department_id])(event);
                getUsers(data[i], setSelectedTreeItem)(event);
                listOfApolloVar.selectedDepartmentVar(
                  data[i].department_id.toString()
                );
              }}
            >
              {list.length !== 0 ? (
                isOpened ||
                (searchedDe.length > 0 &&
                  searchedDe[searchSelectedIndex] &&
                  fullDepartmentNameList[
                    searchedDe[searchSelectedIndex]
                  ].includes(data[i].name)) ? (
                  <IconContainer onClick={handleOpenItem}>
                    <Icon icon={FolderOpenIcon} />
                  </IconContainer>
                ) : (
                  <IconContainer onClick={handleOpenItem}>
                    <Icon icon={FolderIcon} />
                  </IconContainer>
                )
              ) : (
                <IconContainer>
                  <Icon icon={FileIcon} />
                </IconContainer>
              )}
              <ItemContent>
                <ContentTitle
                  searched={
                    searchedDe &&
                    data[i].department_id === searchedDe[searchSelectedIndex]
                  }
                  selectedTreeItem={selectedTreeItem === data[i].department_id}
                  root={data[i].parentdepartment_id === 0}
                >
                  {data[i].WorkingName
                    ? `${data[i].name} [${data[i].WorkingName}]`
                    : data[i].name}
                </ContentTitle>
              </ItemContent>
            </Item>
            <ItemList
              isOpened={
                isOpened ||
                (searchedDe.length > 0 &&
                  fullDepartmentNameList &&
                  searchedDe[searchSelectedIndex] &&
                  fullDepartmentNameList[
                    searchedDe[searchSelectedIndex]
                  ].includes(data[i].name))
              }
            >
              {list.length !== 0 && list}
            </ItemList>
          </React.Fragment>
        );
      }
    }
    return accumulator;
  };
  return { drawTreeFromData };
};

export default StateManager;
