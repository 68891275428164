import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import AsonicDialog from "../../../../asonic-dialog/asonic-dialog";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import AsonicTable from "../../../../asonic-table/asonic-table";
import { GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list } from "../../../../../__generated__/GetListOfApprovalNotificationTemplate";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import * as ReactTable from "react-table";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import TextInputWithLabel from "../../../../inputs/text-input-with-label";
import AsonicIconButton from "../../../../shared/asonic-icon-button/asonic-icon-button";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import EditIcon from "@iconify/icons-fa-solid/edit";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import {
  AddApprovalNotificationTemplate,
  AddApprovalNotificationTemplateVariables
} from "../../../../../__generated__/AddApprovalNotificationTemplate";
import {
  DeleteApprovalNotificationTemplate,
  DeleteApprovalNotificationTemplateVariables
} from "../../../../../__generated__/DeleteApprovalNotificationTemplate";
import {
  UpdateApprovalNotificationTemplate,
  UpdateApprovalNotificationTemplateVariables
} from "../../../../../__generated__/UpdateApprovalNotificationTemplate";
import {
  GetListOfApprovalNotificationScheduleByTemplate,
  GetListOfApprovalNotificationScheduleByTemplateVariables,
  GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list
} from "../../../../../__generated__/GetListOfApprovalNotificationScheduleByTemplate";
import {
  ApprovalTimeType,
  IsUsingNotification
} from "../../../../../__generated__/globalTypes";
import ApprovalNotificationScheduleEditor from "./approval-notification-schedule-editor";
import { Reducers } from "../../../../../../types/reducers";
import { useSelector } from "react-redux";

interface IProps {
  handleOpenDialog: (value: boolean) => void;
  handleSelectRow: (
    row?:
      | IAsonicRow<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
      | undefined
  ) => void;
  selectedTemplate?: GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list;
  loading: boolean;
  prepareRow: (
    row: ReactTable.Row<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
  ) => void;
  getTableProps: (
    propGetter?:
      | ReactTable.TablePropGetter<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
      | undefined
  ) => ReactTable.TableProps;
  headerGroups: ReactTable.HeaderGroup<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[];
  getTableBodyProps: (
    propGetter?:
      | ReactTable.TableBodyPropGetter<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
      | undefined
  ) => ReactTable.TableBodyProps;
  rows: ReactTable.Row<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[];
  selectedRow?: IAsonicRow<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>;
  approvalNotificationTemplateColumns: TColumn<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[];
}

interface IFormInputs {
  alarmName: string;
  templateIdx?: number;
}

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
`;

const Section = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.borderColor};
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  :first-child {
    grid-column: span 1;
    grid-row: 1 / span 4;
  }
  :last-child {
    grid-column: 2 / span 4;
    grid-row: 1 / span 4;
  }
`;

const ListTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const ControllerSection = styled.div`
  display: flex;
  font-size: 18px;
  gap: 5px;
  align-items: center;
`;

const ControlContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.borderColor};
  padding: 10px;
`;

const MUTATION_ADD_APPROVAL_NOTIFICATION_TEMPLATE = gql`
  mutation AddApprovalNotificationTemplate($alarmName: String!) {
    addApprovalNotificationTemplate(alarmName: $alarmName) {
      ok
      error
      id
    }
  }
`;

const MUTATION_DELETE_APPROVAL_NOTIFICATION_TEMPLATE = gql`
  mutation DeleteApprovalNotificationTemplate($templateIdx: Int!) {
    deleteApprovalNotificationTemplate(templateIdx: $templateIdx) {
      ok
      error
    }
  }
`;

const MUTATION_UPDATE_APPROVAL_NOTIFICATION_TEMPLATE = gql`
  mutation UpdateApprovalNotificationTemplate(
    $templateIdx: Int!
    $alarmName: String!
  ) {
    updateApprovalNotificationTemplate(
      templateIdx: $templateIdx
      alarmName: $alarmName
    ) {
      ok
      error
    }
  }
`;

const QUERY_GET_LIST_OF_APPROVAL_NOTIFICATION_SCHEDULE_BY_TEMPLATE = gql`
  query GetListOfApprovalNotificationScheduleByTemplate($templateIdx: Int!) {
    getListOfApprovalNotificationScheduleByTemplate(templateIdx: $templateIdx) {
      ok
      error
      list {
        scheduleIdx
        templateIdx
        categoryId
        isApp
        isMail
        approvalTimeType
        dayCount
        dayType
        minCount
        minType
      }
    }
  }
`;

const ApprovalNotificationDialog = ({
  handleOpenDialog,
  selectedTemplate,
  handleSelectRow,
  loading,
  prepareRow,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  selectedRow,
  approvalNotificationTemplateColumns
}: IProps) => {
  const {
    isOpen,
    handleIsOpen,
    message,
    toastMessageType,
    handleMessage,
    handleToastMessageType
  } = useOpenToastMessage();
  const {
    categorySelectionReducer: { categories }
  } = useSelector((state: Reducers) => state);

  const [
    getListOfApprovalNotificationShceduleByTemplate,
    { data, loading: scheduleLoading }
  ] = useLazyQuery<
    GetListOfApprovalNotificationScheduleByTemplate,
    GetListOfApprovalNotificationScheduleByTemplateVariables
  >(QUERY_GET_LIST_OF_APPROVAL_NOTIFICATION_SCHEDULE_BY_TEMPLATE);

  const [addApprovalNotificationTemplate] = useMutation<
    AddApprovalNotificationTemplate,
    AddApprovalNotificationTemplateVariables
  >(MUTATION_ADD_APPROVAL_NOTIFICATION_TEMPLATE, {
    update(cache, { data }, { variables }) {
      if (
        data?.addApprovalNotificationTemplate.ok &&
        data?.addApprovalNotificationTemplate.id &&
        variables?.alarmName
      ) {
        const newTemplate: GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list =
          {
            templateIdx: data.addApprovalNotificationTemplate.id,
            alarmName: variables.alarmName,
            description: "",
            __typename: "ApprovalNotificationTemplate"
          };
        const newCacheTemplate = cache.writeFragment({
          id: `ApprovalNotificationTemplate:${data.addApprovalNotificationTemplate.id}`,
          fragment: gql`
            fragment add on ApprovalNotificationTemplate {
              __typename
              templateIdx
              alarmName
              description
            }
          `,
          data: {
            ...newTemplate
          }
        });
        cache.modify({
          id: `GetListOfApprovalNotificationTemplateOutput`,
          fields: {
            list(prev) {
              return [...prev, newCacheTemplate];
            }
          }
        });
        handleMessage(
          `성공적으로 ${variables?.alarmName} 템플릿을 추가하셨습니다.`
        );
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.addApprovalNotificationTemplate.ok &&
        data?.addApprovalNotificationTemplate.error
      ) {
        handleMessage(data?.addApprovalNotificationTemplate.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpen(true);
    }
  });

  const [deleteApprovalNotificationTemplate] = useMutation<
    DeleteApprovalNotificationTemplate,
    DeleteApprovalNotificationTemplateVariables
  >(MUTATION_DELETE_APPROVAL_NOTIFICATION_TEMPLATE, {
    update(cache, { data }, { variables }) {
      if (
        data?.deleteApprovalNotificationTemplate.ok &&
        variables?.templateIdx
      ) {
        cache.evict({
          id: `ApprovalNotificationTemplate:${variables.templateIdx}`
        });
        handleMessage(`성공적으로 템플릿을 삭제하셨습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.deleteApprovalNotificationTemplate.ok &&
        data?.deleteApprovalNotificationTemplate.error
      ) {
        handleMessage(data?.deleteApprovalNotificationTemplate.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpen(true);
    }
  });

  const { register, setValue, watch, getValues } = useForm<IFormInputs>({
    mode: "onChange"
  });

  const [updateApprovalNotificationTemplate] = useMutation<
    UpdateApprovalNotificationTemplate,
    UpdateApprovalNotificationTemplateVariables
  >(MUTATION_UPDATE_APPROVAL_NOTIFICATION_TEMPLATE, {
    update(cache, { data }, { variables }) {
      if (
        data?.updateApprovalNotificationTemplate.ok &&
        variables?.templateIdx
      ) {
        cache.modify({
          id: `ApprovalNotificationTemplate:${variables.templateIdx}`,
          fields: {
            alarmName(prev) {
              return variables.alarmName;
            }
          }
        });
        handleMessage(`성공적으로 선택한 템플릿을 수정 하셨습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.updateApprovalNotificationTemplate.ok &&
        data?.updateApprovalNotificationTemplate.error
      ) {
        handleMessage(data?.updateApprovalNotificationTemplate.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpen(true);
    }
  });

  const handleUpdateNotificationTemplate = useCallback(() => {
    const { alarmName } = getValues();
    if (alarmName && selectedRow?.original.templateIdx) {
      updateApprovalNotificationTemplate({
        variables: {
          templateIdx: selectedRow.original.templateIdx,
          alarmName
        }
      });
    }
  }, [
    updateApprovalNotificationTemplate,
    getValues,
    selectedRow?.original.templateIdx
  ]);

  const handleDeleteNotificationTemplate = useCallback(() => {
    if (selectedRow?.original.templateIdx) {
      deleteApprovalNotificationTemplate({
        variables: {
          templateIdx: selectedRow.original.templateIdx
        }
      });
    }
  }, [deleteApprovalNotificationTemplate, selectedRow?.original.templateIdx]);

  const handleAddApprovalNotificationTemplate = useCallback(() => {
    const alarmName = getValues("alarmName");
    addApprovalNotificationTemplate({
      variables: {
        alarmName
      }
    });
  }, [getValues, addApprovalNotificationTemplate]);

  const isTimeName = watch("alarmName");

  const columns: ReactTable.Column<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>[] =
    useMemo(() => {
      const width = 120;
      return [
        {
          Header: "구분",
          accessor(data) {
            const [{ name }] = categories.filter(
              item => item.category_id === data.categoryId
            );
            return name;
          }
        },
        {
          Header: "요청시간",
          accessor(data) {
            if (data.approvalTimeType === ApprovalTimeType.START) {
              return "시작시간";
            }
            return "종료시간";
          },
          width
        },
        {
          Header: "알림 날짜",
          accessor(data) {
            if (data.dayType === ApprovalTimeType.START) {
              return `${data.dayCount}일 전`;
            }
            if (data.dayType === ApprovalTimeType.END) {
              return `${data.dayCount}일 후`;
            }
            return "";
          },
          width
        },
        {
          Header: "분",
          accessor(data) {
            "minCount";
            if (data.minType === ApprovalTimeType.START) {
              return `${data.minCount}분 전`;
            }
            if (data.minType === ApprovalTimeType.END) {
              return `${data.minCount}분 후`;
            }
            return "";
          },
          width
        },
        {
          Header: "대상",
          accessor(data) {
            let target = "";
            if (data.isApp === IsUsingNotification.TRUE) {
              target = "App";
            }
            if (data.isMail === IsUsingNotification.TRUE) {
              if (target.length > 0) {
                target += "/Mail";
              } else {
                target = "Mail";
              }
            }
            return target;
          },
          width: 120
        }
      ];
    }, [categories]);

  const list: GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list[] =
    useMemo(() => {
      return data?.getListOfApprovalNotificationScheduleByTemplate.list || [];
    }, [data]);

  const {
    prepareRow: schedulePrepareRow,
    getTableProps: scheduleGetTableProps,
    headerGroups: scheduleHeaderGroups,
    getTableBodyProps: scheduleGetTableBodyProps,
    rows: scheduleRows,
    selectedFlatRows,
    columns: approvalNotificationScheduleColumns
  } = ReactTable.useTable<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const scheduleSelectedRow:
    | IAsonicRow<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const handleScheduleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>
    ) => {},
    []
  );

  useEffect(() => {
    if (selectedTemplate) {
      setValue("alarmName", selectedTemplate.alarmName);
      setValue("templateIdx", selectedTemplate.templateIdx);
    }
  }, [setValue, selectedTemplate]);

  useEffect(() => {
    if (selectedRow?.original.templateIdx) {
      getListOfApprovalNotificationShceduleByTemplate({
        variables: {
          templateIdx: selectedRow.original.templateIdx
        }
      });
    }
  }, [
    getListOfApprovalNotificationShceduleByTemplate,
    selectedRow?.original.templateIdx
  ]);

  return (
    <AsonicDialog
      title="결재정보 알림 템플릿 관리"
      handleClose={handleOpenDialog}
      width="1000px"
      minWidth="1000px"
      height="400px"
      minHeight="400px"
    >
      <Container>
        <Section>
          <ListTitle>결재알림 템플릿</ListTitle>
          <AsonicTable<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
            title="결재알림 계획"
            handleSelectRow={handleSelectRow}
            isLoading={loading}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            columns={
              approvalNotificationTemplateColumns as TColumn<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[]
            }
            isTitleBar={false}
          />

          <ControllerSection>
            <TextInputWithLabel
              title="템플릿 이름"
              {...register("alarmName")}
            />
            <AsonicIconButton
              icon={AddIcon}
              disabled={!isTimeName ? true : false}
              onClick={handleAddApprovalNotificationTemplate}
            />
            <AsonicIconButton
              icon={EditIcon}
              disabled={!isTimeName || !selectedRow ? true : false}
              onClick={handleUpdateNotificationTemplate}
            />
            <AsonicIconButton
              icon={DeleteIcon}
              disabled={!selectedRow ? true : false}
              onClick={handleDeleteNotificationTemplate}
            />
          </ControllerSection>
        </Section>
        <Section>
          <ListTitle>결재알림 계획</ListTitle>
          <AsonicTable<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>
            title="결재알림 템플릿"
            handleSelectRow={handleScheduleSelectRow}
            isLoading={scheduleLoading}
            prepareRow={schedulePrepareRow}
            getTableProps={scheduleGetTableProps}
            headerGroups={scheduleHeaderGroups}
            getTableBodyProps={scheduleGetTableBodyProps}
            rows={scheduleRows}
            selectedRow={scheduleSelectedRow}
            columns={
              approvalNotificationScheduleColumns as TColumn<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>[]
            }
            isTitleBar={false}
          />
          <ControlContainer>
            <ApprovalNotificationScheduleEditor
              selectedFlatRows={selectedFlatRows}
            />
          </ControlContainer>
        </Section>
      </Container>
      <ToastMessage
        messageTypes={toastMessageType}
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </AsonicDialog>
  );
};

export default ApprovalNotificationDialog;
