import React from "react";
import { Span, ScheduleSection, ScheduleContent } from "./styled";

export type Props = {
  scheduleName: string;
  totalTime: number | string;
  isExtra?: boolean;
  backgroundColor?: string;
  labelColor?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const CellSchduleSection = ({
  scheduleName,
  totalTime,
  isExtra,
  backgroundColor,
  labelColor,
  onClick
}: Partial<Props>) => {
  return (
    <ScheduleSection
      isExtra={isExtra}
      backgroundColor={backgroundColor}
      labelColor={labelColor}
      onClick={onClick}
    >
      <ScheduleContent>
        <Span color="#2196f3">{scheduleName}</Span>
      </ScheduleContent>
      <ScheduleContent>
        <Span color="#2196f3">{totalTime}</Span>
      </ScheduleContent>
    </ScheduleSection>
  );
};

export default CellSchduleSection;
