import StyledFlexContainer from "../../../../shared/styled_flex_container";
import PageSet from "../../../../table/set_page";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import HideColumn, { IHideColumnProps } from "../../../../table/hide_column";
import PointTitle from "../../../../shared/point_title";
import styled from "styled-components";
import StyledInput from "../../../../shared/styled_input";
import IconContainer from "../../../../shared/icon_container";
import AsonicExcel from "../../../../asonic-table/asonic-excel";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../../table_v2/fixed_column_dropbox";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

function PlaceFreeManagementHeader<P extends object>({
  columns,
  table,
  title,
  setIsSearch,
  headerTitleList,
  take,
  handleTake,
  count,
  handleCurrentPage,
  downloadExcel,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title="검색결과" subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          <StyledInput
            type="date"
            value={startDate}
            onChange={event => {
              setStartDate(event.currentTarget.value);
            }}
          />
          <span>{`~`}</span>
          <StyledInput
            type="date"
            value={endDate}
            onChange={event => {
              setEndDate(event.currentTarget.value);
            }}
          />
          <IconContainer
            onClick={() => {
              setIsSearch(true);
              handleCurrentPage(1);
            }}
          >
            <Icon icon={MagnifyIcon} />
          </IconContainer>
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
      <FileContainer>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </FileContainer>
    </Container>
  );
}

export default PlaceFreeManagementHeader;
