import * as React from "react";
import personalApi from "../../../api/dashboard/personal-api";

type Props = {
  userId: string;
};

const useMyWorkStatusBox = <P extends Props>({ userId }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [workingPolicy, setWorkingPolicy] = React.useState<string>("");
  const [jobGroup, setJobGroup] = React.useState<string>("");
  const [department, setDepartment] = React.useState<string>("");
  const [workingStatus, setWorkingStatus] = React.useState<string>("");

  const getData = React.useCallback(async () => {
    try {
      const params = {
        userId
      };
      const { data } = await personalApi.getMyWorkStatusBox(params);

      if (data.length > 0 && isMount.current) {
        const {
          aspolicyname: workingPolicy,
          asjobgroup: jobGroup,
          name: department,
          asstatus: workingStatus
        } = data[0];
        setWorkingPolicy(workingPolicy);
        setJobGroup(jobGroup);
        setDepartment(department);
        setWorkingStatus(workingStatus);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    getData();
    return () => {
      isMount.current = false;
    };
  }, [getData]);

  return { workingPolicy, jobGroup, department, workingStatus };
};

export default useMyWorkStatusBox;
