import HelpContainer from "../../shared/help_container/help_container";
import InfoIcon from "../../shared/info-icon/info-icon";

function AutoAbsenceManagementHelp() {
  return (
    <InfoIcon
      minHeight="220px"
      height="220px"
      minWidth="400px"
      title="이석관리"
    >
      <HelpContainer>
        <span>{`해당 근무제의 자리비움(이석) 기능을 설정합니다.`}</span>
        <ul>
          <li>{`이석(자리비움) 관리`}</li>
          <ul>
            <li>{`사유입력 설정`}</li>
            <ul>
              <li>
                <span>{`블랙스크린 적용 : 이석사유 입력시 블랙 스크린이 발생합니다.`}</span>
              </li>
              <li>
                <span>{`이동장소 입력 : 이석 사유 입력시 이동 장소도 입력 합니다.`}</span>
              </li>
              <li>
                <span>{`참여자(동행자) 입력 : 이석 사유 입력시 이석 발생시 동행했던 참여자를 입력합니다.`}</span>
              </li>
            </ul>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default AutoAbsenceManagementHelp;
