import styled from "styled-components";
import InfoIcon from "../../../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

function FlexibleWSHelp() {
  return (
    <InfoIcon
      minHeight="610px"
      height="610px"
      minWidth="400px"
      title="탄력근무시간제"
    >
      <HelpContainer>
        <pre>{`일이 많은 주의 근무시간을 늘리는 대신, 일이 적은 주의 근무시간을 줄여 
평균 주 40시간의 근무시간을 맞추는 제도 입니다.`}</pre>
        <ul>
          <li>{`기본 출퇴근 시간`}</li>
          <pre>{`기본 출퇴근 시간을 설정합니다. 주별/일별 근무시간으로만 설정을 하
는 경우 기본 시간이 적용 됩니다.`}</pre>
          <li>{`근무 편성 기간 설정`}</li>
          <pre>{`근무 시간을 편성하는 구간을 주 단위로 설정합니다. 설정한 기간 내에
서 근무시간을 줄이거나 늘려서 설정 가능하며 설정 기간의 평균 근무
시간이 기본 설정된 근무시간(주)을 초과하지 않는 경우 자유롭게 근무
시간 편성이 가능합니다.`}</pre>
          <li>{`근무시간 설정 방식`}</li>
          <ul>
            <li>{`주별 근무시간`}</li>
            <pre>{`주 단위로 총 근무시간만 설정 합니다. 출 퇴근 시간은 기본 출/퇴근 
시간이 적용되며, 기본 출퇴근 시간과 설정 시간이 다른 경우, 퇴근 
시간만 변경 됩니다.`}</pre>
            <li>{`주별 출/퇴근시간`}</li>
            <pre>{`주 단위로 출/퇴근 시간 설정이 가능하며, 설정한 근무시간에 맞는 
휴게 시간의 설정도 가능합니다. 변경하지 않는 경우 기본 근무시간
과 휴게 시간이 적용됩니다.`}</pre>
            <li>{`일별 근무시간`}</li>
            <pre>{`일 단위로 총 근무시간만 설정 합니다. 그외의 설정은 “주별 근무시
간” 설정과 동일 합니다.`}</pre>
            <li>{`일별 출/퇴근 시간`}</li>
            <pre>{`일 단위로 출/퇴근 시간 설정이 가능하며, 그외의 설정은 “주별 출/
퇴근시간” 설정과 동일 합니다.`}</pre>
            <li>{`시작주차 설정`}</li>
            <pre>{`탄력근무제의 근무 정산 주기(근무편성기간)가 시작하는 일자를 선
택합니다. 선택한 일자가 포함된 주의 근무제 시작 요일이 시작
일 연산되어 적용 시작일로 표시 됩니다.
`}</pre>
          </ul>
          <li>{`휴게시간 설정`}</li>
          <pre>{`기본 휴게 시간을 설정합니다. 주별, 일별 출/퇴근 설정을 하시는 경우 
기본값으로 적용 됩니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default FlexibleWSHelp;
