import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Approval_Admin_Setting_Preview_Type,
  useGetApprovalAdminSettingLazyQuery,
  useEditApprovalAdminSettingMutation,
  EditApprovalAdminSettingMutationVariables
} from "../../../generated/graphql";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import Button from "../../globalComponents/Button";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import FormRow from "../../shared/form-row/form-row";
import SubTitle from "../../shared/sub-title/sub-title";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import ListOfApprovalTemplate from "./list-of-approval-template";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const BasicSettingContainer = styled.form`
  display: flex;
  flex: 1;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  max-width: 50%;
  min-width: 983px;
`;
const ApprovalFormManagementContainer = styled(BasicSettingContainer)`
  flex: 5;
`;

const BasicContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
  label {
    color: ${props => props.theme.colors.black};
    font-weight: normal;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

function AdministratorSetting() {
  const [isPreview, togglePreview] = useState<boolean>(false);
  const listOfSelectData: number[] = useMemo((): number[] => {
    return [5, 10, 15, 20];
  }, []);

  const [selectedData, setSelectedData] = useState<number>(listOfSelectData[0]);

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const [getApprovalAdminSetting] = useGetApprovalAdminSettingLazyQuery({
    fetchPolicy: "cache-and-network",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (
        data.getApprovalAdminSetting.ok &&
        data.getApprovalAdminSetting.preview &&
        data.getApprovalAdminSetting.dashboardListCount
      ) {
        if (
          data.getApprovalAdminSetting.preview ===
          Approval_Admin_Setting_Preview_Type.Used
        ) {
          togglePreview(true);
        }
        setSelectedData(data.getApprovalAdminSetting.dashboardListCount);
      } else if (data.getApprovalAdminSetting.error) {
        console.log(data.getApprovalAdminSetting.error);
      }
    }
  });

  const [editApprovalAdminSetting, { client }] =
    useEditApprovalAdminSettingMutation({
      onError(error) {
        console.log(error.message);
        handleToast("기본설정을 저장하지 못했습니다.", MessageTypes.ERROR);
      },
      update(_, { data }) {
        if (data?.editApprovalAdminSetting.ok) {
          handleToast(
            "성공적으로 기본설정을 저장하였습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.editApprovalAdminSetting.error) {
          handleToast(data.editApprovalAdminSetting.error, MessageTypes.ERROR);
        }
      }
    });

  const handleSetSelectedData = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        const count = parseInt(event.target.value);
        setSelectedData(count);
      }
    },
    []
  );

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        togglePreview(true);
      } else {
        togglePreview(false);
      }
    },
    []
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload: EditApprovalAdminSettingMutationVariables = {
        preview: isPreview
          ? Approval_Admin_Setting_Preview_Type.Used
          : Approval_Admin_Setting_Preview_Type.UnUsed,
        count: selectedData
      };
      editApprovalAdminSetting({
        variables: payload
      });
    },
    [isPreview, selectedData]
  );

  useEffect(() => {
    getApprovalAdminSetting();
  }, [getApprovalAdminSetting]);

  return (
    <Container>
      <BasicSettingContainer onSubmit={handleSubmit}>
        <SubTitle title="기본 설정" />
        <BasicContent>
          <FormRow title="미리보기">
            <CheckBoxUi
              checked={isPreview}
              htmlFor="approval-preview"
              onChange={handleCheck}
              name="목록에서 본문 미리보기를 사용합니다."
            />
          </FormRow>
          <FormRow title="대시보드 목록 개수">
            <select
              name="결재목록"
              id="approvalAdminSetting"
              onChange={handleSetSelectedData}
              value={selectedData}
            >
              {listOfSelectData.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <label htmlFor="approvalAdminSetting">{`대시 보드에서 표기하는 미리보기 항목 갯수를 설정합니다.`}</label>
          </FormRow>
        </BasicContent>
        <ButtonContainer>
          <Button>저장</Button>
        </ButtonContainer>
      </BasicSettingContainer>
      <ApprovalFormManagementContainer>
        <SubTitle title="결재 양식 관리" />
        <ListOfApprovalTemplate />
      </ApprovalFormManagementContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default AdministratorSetting;
