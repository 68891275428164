import api from "../api";

const SEARCH = `${api.api}/search`;
const SEARCH_EMPLOYEE_TABLE = `${SEARCH}/employee`;
const SEARCH_EMPLOYEE_TABLE_COUNT = `${SEARCH}/employee/count`;
const SEARCH_ENDPOINT_OF_EMPLOYEE = `${SEARCH}/endpointofemployee`;
const SEARCH_ENDPOINT_OF_EMPLOYEE_COUNT = `${SEARCH_ENDPOINT_OF_EMPLOYEE}/count`;
const SEARCHED_PUBLIC_ENDPOINT_COUNT = `${SEARCH_ENDPOINT_OF_EMPLOYEE}/public/count`;
const SEARCHED_PUBLIC_ENDPOINT_INFO = `${SEARCH_ENDPOINT_OF_EMPLOYEE}/public/limit`;

const searchApi = {
  employee: SEARCH_EMPLOYEE_TABLE,
  count: SEARCH_EMPLOYEE_TABLE_COUNT,
  endpointOfemployee: SEARCH_ENDPOINT_OF_EMPLOYEE,
  endpointOfEmployeeCount: SEARCH_ENDPOINT_OF_EMPLOYEE_COUNT,
  publicEndpointOfEmployeeCount: SEARCHED_PUBLIC_ENDPOINT_COUNT,
  publicEndpointOfEmployeeInfo: SEARCHED_PUBLIC_ENDPOINT_INFO
};

export default searchApi;
