import { ITotalProps, ID, PASSWORD } from "../SignInBoxContainer";
import {
  Container,
  InputContainer,
  InputLabel,
  TextContainer,
  Input,
  Icon
} from "./styled";
import { faUserCircle, faLock } from "@fortawesome/free-solid-svg-icons";

import Button from "../../globalComponents/Button";
import ToastMessage from "../../toast-message/toast-message";

const SignInBox = (props: ITotalProps) => {
  const {
    userId,
    password,
    handleIdOrPassword,
    color,
    onSignIn,
    idLabel,
    buttonLabel,
    isOpen,
    handleIsOpen,
    message,
    toastMessageType
  } = props;
  return (
    <Container onSubmit={onSignIn}>
      <InputContainer>
        <InputLabel customcolor={color ?? "black"} htmlFor="id">
          {idLabel}
        </InputLabel>
        <TextContainer>
          <Icon icon={faUserCircle} size="lg" customcolor={color ?? "black"} />
          <Input
            id="id"
            value={userId}
            onChange={handleIdOrPassword(ID)}
            autoComplete="username"
            required
          />
        </TextContainer>
      </InputContainer>
      <InputContainer>
        <InputLabel customcolor={color ?? "black"} htmlFor="password">
          비밀번호
        </InputLabel>
        <TextContainer>
          <Icon icon={faLock} size="lg" customcolor={color ?? "black"} />
          <Input
            id="password"
            type="password"
            value={password}
            onChange={handleIdOrPassword(PASSWORD)}
            required
            autoComplete="current-password"
          />
        </TextContainer>
      </InputContainer>
      <Button>{buttonLabel}</Button>
      <ToastMessage
        messageTypes={toastMessageType}
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
};

export default SignInBox;
