import moment from "moment";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Label = styled.div`
  color: ${props => props.theme.colors.veryDarkBlue};
  font-weight: bold;
  min-width: max-content;
`;

const MobileRequestTime = ({
  label,
  startTime,
  endTime,
  isStart,
  onChange
}) => {
  const updateDate = event => {
    onChange(event);
  };
  return (
    <Container>
      <Label>{label}</Label>
      <input
        onChange={updateDate}
        defaultValue={
          isStart
            ? `${
                typeof startTime === "object"
                  ? moment(startTime).format("HH:mm")
                  : startTime
              }`
            : `${
                typeof endTime === "object"
                  ? moment(endTime).format("HH:mm")
                  : endTime
              }`
        }
        id={label}
        type="time"
      />
    </Container>
  );
};

export default MobileRequestTime;
