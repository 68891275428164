import * as React from "react";
import SuperAdminMenuPresenter from "./super-admin-menu-presenter";
import { useNavigate } from "react-router-dom";
import routes from "../../../../../routes";

type Props = {};

const SuperAdminMenuContainer = <P extends Props>(props: P) => {
  const navigate = useNavigate();
  const handleGoSuperAdminPage = React.useCallback(() => {
    navigate(routes.pageRoutes.superAdminSettings);
  }, [navigate]);

  return (
    <SuperAdminMenuPresenter handleGoSuperAdminPage={handleGoSuperAdminPage} />
  );
};

export default SuperAdminMenuContainer;
