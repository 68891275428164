import styled from "styled-components";

const GridContentContainer = styled.div<{ flex?: number }>`
  display: grid;
  flex: ${props => props.flex ?? 1};
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
`;

export default GridContentContainer;
