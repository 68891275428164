import * as React from "react";
import moment from "moment";
import { Container } from "./styled";

const CurrentTime = () => {
  const [currentTime, setCurrentTime] = React.useState("");
  const isMount = React.useRef(false);

  const handleCurrenTime = React.useCallback(() => {
    const newTime = moment().locale("en").format("A hh:mm");
    if (isMount.current) {
      setCurrentTime(newTime);
    }
  }, []);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    const timerID = window.setInterval(handleCurrenTime, 1000);
    return () => {
      if (timerID) {
        clearInterval(timerID);
        isMount.current = false;
      }
    };
  }, [handleCurrenTime]);

  return <Container>{currentTime}</Container>;
};

export default CurrentTime;
