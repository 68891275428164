const WHITE = "#ffffff";
const PERSONAL_DASHBOARD_TITLE_COLOR = "#d9eff4";
const MAIN_BACKGROUND_COLOR = "#f3f5f5";

const sideBar = {
  titleBackgroundColor: "#161d20",
  titleColor: WHITE,
  backGroundColor: "#263238",
  color: WHITE,
  border: "#d7dee4"
};

const titleBar = {
  color: "#444444",
  border: "#e7e9e9",
  logout: "#999999"
};

const personalDashBoard = {
  personalDashboardTitleColor: PERSONAL_DASHBOARD_TITLE_COLOR,
  Info: WHITE,
  currentTimeBox: {
    topBackgroundColor: "#4AB4CE",
    bottomBackgroundColor: "#389FBB",
    icon: "#1B6E85"
  },
  myWorkingCurrentSituationBox: {
    BackgroundColor: "#3E89C9"
  },
  todayWorkingHoursBox: {
    title: "#235384",
    backGroundColor: "#CCD5E2",
    timeBackgroundColor: "#47729D",
    timeColor: WHITE,
    childTitleColor: "#586164",
    remainingTimeColor: "#4a5355",
    regularTimeColor: "#3d78b1",
    extendTimeColor: "#043571",
    labelBackgroundColor: "#E5EEF1"
  },
  weekWorkingHoursBox: {
    title: "#0f7794",
    backGroundColor: "#DAE2CC",
    timeBackgroundColor: "#478A9D",
    timeColor: WHITE,
    childTitleColor: "#586164",
    remainingTimeColor: "#4a5355",
    regularTimeColor: "#36a382",
    extendTimeColor: "#036453",
    labelBackgroundColor: "#E5F1EE"
  },
  monthlyWithVacationStatusBox: {
    backGroundColor: WHITE,
    title: "#5f5f5f",
    subTitle: "#767676",
    tableTitle: "#262d31",
    tableTitleBackground: "#CAD3D6",
    tableContent: "#525252",
    tableTotalWorkingDaysBackgroundColor: "#3AAAC7",
    tableVacationDaysBackgroundColor: "#6FB955"
  },
  monthlyWorkStatusBox: {
    title: "#5f5f5f",
    subTitle: "#767676",
    regularWorking: "#4a83bb",
    extendsWorking: "#063b79",
    workingSchedule: "#e6375c",
    totalWorking: "#e6375c"
  }
};

const colors = {
  mainBackgroundColor: MAIN_BACKGROUND_COLOR,
  sideBar,
  titleBar,
  personalDashBoard
};

export default colors;
