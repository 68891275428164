import * as React from "react";
import TodaySWorkStatusBox from "./today-s-work-status-box";
import {
  Container,
  Section,
  BoxContainer,
  TitleBar,
  Title,
  ChartBoxContainer
} from "./styled";
import PersonalStatusBox from "./personal-status-box";
import masterWorkManagementPageApi from "../../../api/master-work-management-page-api";
import TotalWeeklyWorkingHours from "./total-weekly-working-hours";
import useTodayWorkingStatus from "../../../hooks/master-work-management-page-hooks/use-today-working-status";
import useStore from "../../../hooks/master-work-management-page-hooks/use-store";
import MainViewContext from "../store";
export const Store = React.createContext({});

const MasterWorkManagementPage = () => {
  const { isTreeData } = React.useContext(MainViewContext);
  const { store } = useStore({ isTreeData });
  const { data } = useTodayWorkingStatus({
    store
  });

  return (
    <Store.Provider value={store}>
      <Container data-testid="master-work-management-page">
        <Section>
          <TitleBar>
            <Title>금일 근무 현황</Title>
          </TitleBar>
          <BoxContainer>
            <TodaySWorkStatusBox {...data[0]} />
            <TodaySWorkStatusBox {...data[1]} />
            <TodaySWorkStatusBox {...data[2]} />
            <TodaySWorkStatusBox {...data[3]} />
            <TodaySWorkStatusBox {...data[4]} />
          </BoxContainer>
        </Section>
        <PersonalStatusBox
          title="근로시간별"
          subTitle="부제목"
          callData={
            masterWorkManagementPageApi.statusNumberEmployeesWorkingHour
          }
          color="#40AAB2"
        />
        <PersonalStatusBox
          title="정규근무"
          subTitle="부제목"
          callData={masterWorkManagementPageApi.statusFullTimeEmployees}
          color="#6862B3"
        />
        <PersonalStatusBox
          title="연장근무"
          subTitle="부제목"
          callData={masterWorkManagementPageApi.statusOverTimeEmployees}
          color="#FFC01F"
        />
        <ChartBoxContainer>
          <TotalWeeklyWorkingHours />
        </ChartBoxContainer>
      </Container>
    </Store.Provider>
  );
};

export default MasterWorkManagementPage;
