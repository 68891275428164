import styled from "styled-components";
import StyledInput from "../../../shared/styled_input";
import {
  ApprovalLineEntity,
  Approval_User_Type,
  Is_Default_Approval_Line,
  UserEntity,
  useGetListOfApprovalLineLazyQuery,
  useGetListOfSearchedApprovalLineUserLazyQuery,
  useGetUserLazyQuery
} from "../../../../generated/graphql";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { AutoSizer } from "react-virtualized";
import mRAStore from "./m_r_a_store";
import { Icon } from "@iconify/react";
import mColors from "../../../../style-sheet/m_colors";
import ReactLoading from "react-loading";
import { colors } from "../../../GlobalStyle/GlobalStyle";
import { IUserOfSearchedApprovalLineEntity } from "../../../approval/approval-setting/approval-process-management/approval-process-management";
import MSelectedApprovalUserList from "./m_selected_approval_user_list";

export interface IMApprovalUser
  extends Partial<IUserOfSearchedApprovalLineEntity> {
  name: string;
  userPosition: string;
  department: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const SearchBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex: 7;
`;

const ListContainer = styled.div<{
  $height: number;
  $width: number;
  $loading?: boolean;
}>`
  display: inline-block;
  overflow: auto;
  height: ${props => (props.$height ? `${props.$height}px` : "")};
  width: ${props => (props.$width ? `${props.$width}px` : "")};
  flex-direction: column;
  will-change: scroll-position;
  justify-content: ${props => (props.$loading ? "center" : "")};
  align-items: ${props => (props.$loading ? "center" : "")};
  border-radius: 6px;
  background-color: ${mColors.lightGreen};
`;

const ItemContainer = styled.div<{ $padding?: string }>`
  position: relative;
  padding-left: ${props => props.$padding ?? "12px"};
  font-size: 20px;
`;

const Item = styled.div<{ isSelected?: boolean }>`
  display: inline-block;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  :active {
    color: ${props => props.theme.colors.lightGreen};
  }
  color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.black};
  user-select: none;
`;

const IconContainer = styled.div<{ $fontSize?: string }>`
  display: inline-block;
  svg {
    vertical-align: middle;
    font-size: ${props => props.$fontSize ?? "24px"};
  }
  margin-right: 10px;
`;

const SearchIconContainer = styled.div`
  display: flex;
  height: 25px;
  width: 25px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 24px;
  }
  background-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.95);
  }
`;

const ApprovalLineTitle = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const UserProfileContainer = styled.div`
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  span {
    display: block;
  }
`;

const DisplayDepartment = styled.span`
  color: ${props => props.theme.colors.darkGrey};
`;

const BtnContainer = styled(IconContainer)`
  position: absolute;
  right: 10px;
  :active {
    transform: scale(0.95);
  }
`;

const SearchInput = styled.input`
  display: flex;
  flex: 5;
  border-radius: 15px;
  background-color: ${mColors.lightGreen};
  min-height: 25px;
  :focus {
    border-color: ${props => props.theme.colors.green};
  }
`;

function MRequestApprovalLine() {
  const [{ user, approvalLine }, setMRAState] = useRecoilState(mRAStore);
  const [sUserList, setSUserList] = useState<UserEntity[]>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [getUser] = useGetUserLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getUser.ok) {
        if (data.getUser.user) {
          setSUserList(data.getUser.user);
        } else {
          setSUserList([]);
        }
      } else if (data.getUser.error) {
        console.log(data.getUser.error);
      }
    }
  });

  const [getListOfApprovalLine, { data, loading }] =
    useGetListOfApprovalLineLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const [
    getListOfSearchedApprovalLineUser,
    { data: userData, loading: userLoading }
  ] = useGetListOfSearchedApprovalLineUserLazyQuery();

  const tree = useMemo((): ApprovalLineEntity[] => {
    return data?.getListOfApprovalLine.list ?? [];
  }, [data]);

  const handleAddUser = useCallback(
    (user: IMApprovalUser, approvalType: Approval_User_Type) => {
      setMRAState(pre => {
        if (user.employeeId) {
          let newUser = { ...user, approvalType: approvalType };
          const newApprovalLineUser = [...pre.approvalLineUser];
          const isUser = newApprovalLineUser.find(
            item => item.employeeId === user.employeeId
          );
          if (isUser) {
            return {
              ...pre
            };
          }
          newApprovalLineUser.push(newUser);
          return { ...pre, approvalLineUser: newApprovalLineUser };
        }
        return pre;
      });
    },
    []
  );

  const userList: IMApprovalUser[] = useMemo(() => {
    let newData: IMApprovalUser[] = [];
    if (userData?.getListOfSearchedApprovalLineUser.list) {
      newData = userData?.getListOfSearchedApprovalLineUser.list.map(item => {
        let name = "";
        let userPosition = "";
        let department = "";
        if (item.approvalInfo) {
          const approvalInfo = item.approvalInfo?.split("/");
          name = approvalInfo[0];
          userPosition = approvalInfo[1];
          department = approvalInfo[2];
        }

        return { name, userPosition, department, ...item };
      });
    }
    return newData.reverse();
  }, [userData]);

  useEffect(() => {
    if (user?.employeeId) {
      getListOfApprovalLine({
        variables: {
          employeeId: user.employeeId
        }
      });
    }
  }, [user?.employeeId, getListOfApprovalLine]);

  useEffect(() => {
    if (approvalLine && user?.employeeId) {
      getListOfSearchedApprovalLineUser({
        variables: {
          employeeId: user?.employeeId,
          lineIdx: approvalLine.lineIdx
        }
      });
    }
  }, [getListOfSearchedApprovalLineUser, approvalLine, user?.employeeId]);

  useEffect(() => {
    if (searchValue && searchValue.length >= 2) {
      getUser({
        variables: {
          name: searchValue
        }
      });
    }
  }, [getUser, searchValue]);

  return (
    <Container>
      <SearchBar>
        <StyledInput
          type="checkbox"
          checked={isSearch}
          onChange={event => setIsSearch(event.currentTarget.checked)}
          id="search-employee"
        />
        <label htmlFor="search-employee">{`직원 검색`}</label>
        {isSearch && (
          <SearchInput
            placeholder="이름을 입력해주세요(두글자 이상)"
            value={value}
            onChange={event => {
              setValue(event.currentTarget.value ?? "");
            }}
            onKeyDown={event => {
              if (event.key === "Enter") {
                setSearchValue(value);
              }
            }}
          />
        )}
        {isSearch && (
          <SearchIconContainer
            onClick={() => {
              setSearchValue(value);
            }}
          >
            <Icon icon="mdi:magnify" />
          </SearchIconContainer>
        )}
      </SearchBar>
      <SearchBar>
        {`개인결재선 ${approvalLine?.newTitle ? ">" : ""}`}
        <ApprovalLineTitle>
          {approvalLine ? `${approvalLine?.newTitle}` : ""}
        </ApprovalLineTitle>
      </SearchBar>
      <Section>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <ListContainer $height={height} $width={width} $loading={loading}>
                {loading && (
                  <ReactLoading
                    type="spokes"
                    color={colors.green}
                    height={200}
                    width={170}
                  />
                )}
                {isSearch && (
                  <Fragment>
                    {sUserList.map(item => {
                      const fullDepartmentName = item.fullDepartmentName ?? "";
                      const department =
                        fullDepartmentName.split(">").at(-1) ?? "";
                      return (
                        <ItemContainer
                          key={`${item.employeeId}-approval-user-${
                            Math.random() * 1000
                          }`}
                        >
                          <Item>
                            <IconContainer $fontSize="45px">
                              <Icon icon={"mdi:user"} />
                            </IconContainer>
                            <UserProfileContainer>
                              <span>{item.name}</span>
                              <DisplayDepartment>
                                {department}
                              </DisplayDepartment>
                            </UserProfileContainer>
                            <BtnContainer
                              $fontSize="40px"
                              onClick={() => {
                                const newUser: IMApprovalUser = {
                                  name: item.name ?? "",
                                  userPosition: item.userPosition ?? "",
                                  department: department,
                                  approvalInfo: "",
                                  approvalOrder: 1,
                                  approvalType: Approval_User_Type.Approval,
                                  employeeId: item.employeeId,
                                  lineIdx: 1
                                };
                                handleAddUser(
                                  newUser,
                                  Approval_User_Type.Approval
                                );
                              }}
                            >
                              <Icon icon={"ic:baseline-plus"} />
                            </BtnContainer>
                          </Item>
                        </ItemContainer>
                      );
                    })}
                  </Fragment>
                )}
                {!loading &&
                  !isSearch &&
                  !approvalLine &&
                  tree.map(brunch => (
                    <ItemContainer
                      key={`${brunch.lineIdx}-${Math.random() * 1000}`}
                    >
                      <Item
                        isSelected={false}
                        onClick={() => {
                          setMRAState(pre => ({
                            ...pre,
                            approvalLine: brunch
                          }));
                        }}
                      >
                        <IconContainer>
                          <Icon icon={"material-symbols:folder"} />
                        </IconContainer>
                        <span>{`${brunch.newTitle}${
                          brunch.basicLine === Is_Default_Approval_Line.Used
                            ? "[기본결재선]"
                            : ""
                        }`}</span>
                      </Item>
                    </ItemContainer>
                  ))}
                {!loading && approvalLine && !isSearch && (
                  <Fragment>
                    <ItemContainer
                      $padding={"18px"}
                      onClick={() => {
                        setMRAState(pre => ({
                          ...pre,
                          approvalLine: undefined
                        }));
                      }}
                    >
                      <Item>
                        <IconContainer $fontSize="35px">
                          <Icon icon={"material-symbols:folder"} />
                        </IconContainer>
                        <span>{`..`}</span>
                      </Item>
                    </ItemContainer>
                    {userList.map(item => (
                      <ItemContainer
                        key={`${item.employeeId}-approval-user-${
                          Math.random() * 1000
                        }`}
                      >
                        <Item>
                          <IconContainer $fontSize="45px">
                            <Icon icon={"mdi:user"} />
                          </IconContainer>
                          <UserProfileContainer>
                            <span>{item.name}</span>
                            <DisplayDepartment>
                              {item.department}
                            </DisplayDepartment>
                          </UserProfileContainer>
                        </Item>
                        <BtnContainer
                          $fontSize="40px"
                          onClick={() => {
                            handleAddUser(item, Approval_User_Type.Approval);
                          }}
                        >
                          <Icon icon={"ic:baseline-plus"} />
                        </BtnContainer>
                      </ItemContainer>
                    ))}
                  </Fragment>
                )}
              </ListContainer>
            );
          }}
        </AutoSizer>
      </Section>
      <Section>
        <MSelectedApprovalUserList />
      </Section>
    </Container>
  );
}

export default MRequestApprovalLine;
