import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import useCheckHttps from "../../../../hooks/calendar-hooks/use-check-https";
import {
  ListOfGoogleColor,
  SerializeItem
} from "../../../../hooks/link-google-hooks/use-google-calendar";
import ScrollingList from "../../../scrolling-list/scrolling-list";
import useOpenDialog from "../../../../hooks/use-open-dialog";
import CalendarHeaderInput from "../../../asonic-calendar/calendar-header-input";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
registerLocale("ko", ko);

enum CalendarDirection {
  PRE = "PRE",
  NEXT = "NEXT"
}

enum Colors {
  SKY_BLUE = "#2196f3",
  ON_CLICK_COLOR = "#0652DD"
}

interface IProps {
  currentMoment: string;
  handleCurrentMoment: (date: Date) => void;
  handleMoveMonth: (direction: CalendarDirection) => () => void;
  authorize: () => void;
  handleSignoutClick: () => void;
  isLogin: boolean;
  googleCalendarlist: SerializeItem[];
  handleCalendarList: (event: React.ChangeEvent<HTMLInputElement>) => void;
  listOfGoogleColor: ListOfGoogleColor;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

// const ImgIcon = styled.div`
//   flex: 1;
//   max-width: 32px;
//   background-size: 18px 18px;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg");
//   cursor: pointer;
//   :hover {
//     background-color: #74b9ff;
//   }
//   :active {
//     transform: scale(0.96);
//   }
// `;

const MonthTitleBar = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)``;

const ArrowButton = styled.button`
  display: flex;
  background-color: white;
  outline: none;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #b2bec3;
  padding: 5px;
  border-radius: 2px;
  :hover {
    cursor: pointer;
    border-color: ${Colors.SKY_BLUE};
    ${Icon} {
      color: ${Colors.SKY_BLUE};
    }
  }
  :active {
    border-color: ${Colors.ON_CLICK_COLOR};
    ${Icon} {
      color: ${Colors.ON_CLICK_COLOR};
    }
  }
`;

const MiniCaledarTitleBar = <P extends IProps>({
  currentMoment,
  handleCurrentMoment,
  handleMoveMonth,
  googleCalendarlist,
  handleCalendarList,
  listOfGoogleColor
}: P) => {
  const { isGoogle } = useCheckHttps();
  const { isOpen, handleOpenDialog } = useOpenDialog();

  return (
    <Container>
      <MonthTitleBar>
        <ArrowButton onClick={handleMoveMonth(CalendarDirection.PRE)}>
          <Icon icon={faChevronLeft} />
        </ArrowButton>
        <DatePicker
          todayButton="오늘로 가기"
          customInput={<CalendarHeaderInput />}
          selected={moment(currentMoment).toDate()}
          onChange={handleCurrentMoment}
          dateFormat="yyyy년 MM월"
          showMonthYearPicker
          locale="ko"
        />
        {/* <MonthTitle
          type="month"
          aria-readonly={true}
          value={currentMoment}
          onChange={handleCurrentMoment}
        /> */}
        <ArrowButton onClick={handleMoveMonth(CalendarDirection.NEXT)}>
          <Icon icon={faChevronRight} />
        </ArrowButton>
      </MonthTitleBar>
      {isOpen && isGoogle && (
        <AsonicDialog
          title="달력 리스트"
          width="60%"
          height="50%"
          minHeight="20%"
          handleClose={(value: boolean) => () => {
            handleOpenDialog(value);
          }}
        >
          <ScrollingList
            data={googleCalendarlist}
            handleOnChange={handleCalendarList}
            listOfGoogleColor={listOfGoogleColor}
          />
        </AsonicDialog>
      )}
    </Container>
  );
};

export default MiniCaledarTitleBar;
