import * as React from "react";
import moment from "moment";

import { SignInReducer } from "../../../../../../types/reducers";
type Props = {
  signInReducer: SignInReducer;
};

const useProfile = <P extends Props>({ signInReducer }: P) => {
  const [photo, setPhoto] = React.useState<string>("");

  const makeTime = React.useCallback(() => {
    const isAm = moment().format("A") === "오전" ? "AM" : "PM";
    const time = moment().format(`h:mm`);
    return `${isAm} ${time}`;
  }, []);

  const [time, setTime] = React.useState(makeTime());

  const date = React.useMemo(
    () => moment().format("YYYY년 MM월 DD일 dddd"),
    []
  );

  React.useEffect(() => {
    const timeId = setInterval(() => {
      setTime(makeTime());
    }, 60000);

    return () => {
      clearInterval(timeId);
    };
  }, [makeTime]);

  React.useEffect(() => {
    if (
      signInReducer.my_picture &&
      signInReducer.my_picture.data.length !== 1
    ) {
      const buf = Buffer.from(signInReducer.my_picture.data);
      const base64String = buf.toString("base64");
      setPhoto(base64String);
    }
  }, [signInReducer]);
  return { photo, time, date };
};

export default useProfile;
