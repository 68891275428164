import * as React from "react";
import { useSelector } from "react-redux";
import { Reducers } from "../../../types/reducers";

type Props = {
  workingAuthWeb?: number;
  webEtcFlag?: number;
};

enum IsShow {
  SHOW = 1,
  HIDE
}

// Ctx = Context menu
// logininfo workingAuthWeb(관리자 권한), web_app_menuset(메뉴설정)
export type WebMenuAuthList = {
  unUsed: boolean;
  approvalMenu: boolean;
  managerStatistics: boolean;
  gpsBtn: boolean;
  workingTimePlanSetting: boolean;
  calendarBtn: boolean;
  dashBoard: boolean;
  serviceManagementInCtx: boolean;
  showGraph: boolean;
  phoneNumber: boolean;
  emergencyKeyIssuance: boolean;
  workingHourPlanningPolicyInCtx: boolean;
  changeCommutingRecord: boolean;
  awayInformationChange: boolean;
  initPassword: boolean;
  isUpdateBasicInformationManagementButton: boolean;
  isUpdateWorkInformationManagementButton: boolean;
  vehicleDrivingManagementMenu: boolean;
  reservationManagementMenu: boolean;
  chatMenu: boolean;
  annualSetting: boolean;
  workingSystem: boolean;
};

export enum LoginIdInfo {
  WorkingAuthWeb = "working_auth_web"
}

export enum WorkingAuthWeb {
  isUpdateBasicInformationManagementButton = 131072,
  isUpdateWorkInformationManagementButton = 262144,
  isActiveAnnualSetting = 2097152, // 연차 설정 메뉴 활성화 값(working_auth_web from loginid_info)
  isWorkingSystem = 4194304 //  왼쪽 사이드바 환경설정의 근무제 설정 메뉴 (working_auth_web비트연산 4194304)
}

export interface ILoginIdInfo {
  working_auth_web: WorkingAuthWeb;
}

export enum WebMenuAuthValueList {
  unUsed = 0,
  approvalMenu = 1,
  managerStatistics = 2,
  gpsBtn = 16,
  workingTimePlanSetting = 32,
  calendarBtn = 64,
  dashBoard = 256,
  serviceManagementInCtx = 512,
  showGraph = 1024,
  phoneNumber = 2048,
  emergencyKeyIssuance = 4096,
  workingHourPlanningPolicyInCtx = 8192,
  changeCommutingRecord = 16384,
  webChatEtcFlag = 8192,
  annualSettingEtcFlag = 16384, // 연차 설정 활성화값 (web_etc_flag from web_app_menuset)
  awayInformationChange = 32768,
  initPassword = 65536,
  workingSystemEtcFlag = 2097152 // 왼쪽 사이드바 환경설정의 근무제 설정 메뉴(web_etc_flag 비트연산 2097152) AND (web_etc_flag 비트연산 32768)
}

enum WebMenuList {
  WEB_APPROVAL_MENU_FLAG = 1024,
  WEB_VEHICLE_FLAG = 2048,
  WEB_RESERVE_FLAG = 4096
}

const useCheckWebAuthFn = <P extends Props>({
  workingAuthWeb,
  webEtcFlag
}: P) => {
  const {
    signInReducer: { webMenuList }
  } = useSelector((state: Reducers) => state);
  const [webMenuAuthList, setWebMenuAuthList] = React.useState<WebMenuAuthList>(
    {
      unUsed: true, //  기능 미사용 = 0
      approvalMenu: false, //  (메인 왼쪽 메뉴)  결재관리(메인메뉴) =1 web_app_menuset테이블 web_etc_flag 필드값 비트연산 1024
      managerStatistics: true, //  (메인 왼쪽 메뉴)  통계(관리자통계 메뉴) = 2
      gpsBtn: true, //  (로그아웃 왼쪽) 출퇴근gps설정 = 16
      workingTimePlanSetting: false, //  (로그아웃 왼쪽) 근무시간계획설정 = 32
      calendarBtn: true, //  (로그아웃 왼쪽) 달력 = 64
      dashBoard: false, // (메인 왼쪽 메뉴) 대쉬보드 256 (webEtcFlag)
      serviceManagementInCtx: true, //  (직원,pc 정보화면 context 메뉴) 근무관리 = 512
      showGraph: true, //  (직원정보화면 context메뉴) 그래프보기 = 1024
      phoneNumber: true, //  (직원정보화면 context메뉴) 전화번호 = 2048
      emergencyKeyIssuance: true, // (직원정보화면 context메뉴) 긴급키발급 = 4096
      workingHourPlanningPolicyInCtx: true, //  (직원, pc 정보화면 context 메뉴) 근무시간계획정책 = 8192
      changeCommutingRecord: true, //  (출/퇴근정보화면 마우스 메뉴) 출퇴근기록변경 = 16384
      awayInformationChange: true, //  (자리비운(이석)정보화면 마우스 메뉴) 자리비움(이석)정보 변경 = 32768
      initPassword: false, // (직원정보화면 마우스 메뉴) 패스워드 초기화 = 65536
      isUpdateBasicInformationManagementButton: false, // 추가관리정보 기본정보 수정버튼 = 131072
      isUpdateWorkInformationManagementButton: false, // 추가관리정보 기본정보 수정버튼 = 262144
      workingSystem: false, // 근무제 설정 메뉴(왼쪽 사이드바 설정 메뉴 안)
      vehicleDrivingManagementMenu: false, // 차량주행관리 메뉴(왼쪽 사이바 메뉴) = 2048
      reservationManagementMenu: false, // 예약관리 메뉴(왼쪽 사이드바 메뉴) = 4096
      chatMenu: false, // 채팅 메뉴(위에 메뉴) 활성화 기준 web_etc_flag 비트연산 8192, web__chat_flag =1
      annualSetting: false // 연차 설정 web_etc_flag = 16384 , working_auth_web = 2097152
    }
  );

  const calculateWebMenu = React.useCallback(
    (specificWebFlag: number, specificEtcFlag: number) => {
      const etcFlag = Boolean((webEtcFlag ?? 0) & specificEtcFlag);
      const authFlag = specificWebFlag === IsShow.SHOW ? true : false;
      return etcFlag && authFlag;
    },
    [webEtcFlag]
  );

  const calculateMenu = React.useCallback(() => {
    workingAuthWeb = workingAuthWeb === undefined ? 0 : workingAuthWeb;
    if (webEtcFlag !== undefined && webMenuList.length > 0) {
      const newWebMenuAuthList = {
        unUsed: Boolean(workingAuthWeb & WebMenuAuthValueList.unUsed),
        approvalMenu: calculateWebMenu(
          webMenuList[0]?.web_approval_flag,
          WebMenuList.WEB_APPROVAL_MENU_FLAG
        ),
        managerStatistics: Boolean(
          workingAuthWeb & WebMenuAuthValueList.managerStatistics
        ),
        gpsBtn: Boolean(workingAuthWeb & WebMenuAuthValueList.gpsBtn),
        workingTimePlanSetting: Boolean(
          workingAuthWeb & WebMenuAuthValueList.workingTimePlanSetting
        ),
        calendarBtn: Boolean(workingAuthWeb & WebMenuAuthValueList.calendarBtn),
        dashBoard: Boolean(webEtcFlag & WebMenuAuthValueList.dashBoard),
        serviceManagementInCtx: Boolean(
          workingAuthWeb & WebMenuAuthValueList.serviceManagementInCtx
        ),
        showGraph: Boolean(workingAuthWeb & WebMenuAuthValueList.showGraph),
        phoneNumber: Boolean(workingAuthWeb & WebMenuAuthValueList.phoneNumber),
        emergencyKeyIssuance: Boolean(
          workingAuthWeb & WebMenuAuthValueList.emergencyKeyIssuance
        ),
        workingHourPlanningPolicyInCtx: Boolean(
          workingAuthWeb & WebMenuAuthValueList.workingHourPlanningPolicyInCtx
        ),
        changeCommutingRecord: Boolean(
          workingAuthWeb & WebMenuAuthValueList.changeCommutingRecord
        ),
        awayInformationChange: Boolean(
          workingAuthWeb & WebMenuAuthValueList.awayInformationChange
        ),
        initPassword: Boolean(
          workingAuthWeb & WebMenuAuthValueList.initPassword
        ),
        isUpdateBasicInformationManagementButton: Boolean(
          workingAuthWeb &
            WorkingAuthWeb.isUpdateBasicInformationManagementButton
        ),
        isUpdateWorkInformationManagementButton: Boolean(
          workingAuthWeb &
            WorkingAuthWeb.isUpdateWorkInformationManagementButton
        ),

        vehicleDrivingManagementMenu: calculateWebMenu(
          webMenuList[0]?.web_vehicle_flag,
          WebMenuList.WEB_VEHICLE_FLAG
        ),
        reservationManagementMenu: calculateWebMenu(
          webMenuList[0]?.web_reserve_flag,
          WebMenuList.WEB_RESERVE_FLAG
        ),
        chatMenu: calculateWebMenu(
          webMenuList[0]?.web_chat_flag,
          WebMenuAuthValueList.webChatEtcFlag
        ),
        annualSetting:
          Boolean(workingAuthWeb & WorkingAuthWeb.isActiveAnnualSetting) &&
          Boolean(webEtcFlag & WebMenuAuthValueList.annualSettingEtcFlag),
        workingSystem:
          Boolean(workingAuthWeb & WorkingAuthWeb.isWorkingSystem) &&
          Boolean(webEtcFlag & WebMenuAuthValueList.workingSystemEtcFlag) &&
          Boolean(webEtcFlag & WebMenuAuthValueList.awayInformationChange)
      };
      setWebMenuAuthList(newWebMenuAuthList);
    }
  }, [workingAuthWeb, webEtcFlag, webMenuList, calculateWebMenu]);

  React.useEffect(() => {
    calculateMenu();
  }, [calculateMenu]);
  return { webMenuAuthList };
};

export default useCheckWebAuthFn;
