import styled from "styled-components";

const TLayout = styled.div<{ $height: number; $width: number }>`
  display: flex;
  flex: 5;
  overflow: auto;
  height: ${props => `${props.$height}px`};
  width: ${props => `${props.$width}px`};
  .table {
    .th,
    .td {
      overflow: hidden;
    }
    &.sticky {
      .header {
        position: sticky;
        top: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 0px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 0px #ccc;
      }
    }
  }
`;

export default TLayout;
