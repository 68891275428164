import { useEffect, useState } from "react";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import {
  WtGraphBasicEntity,
  WtGraphWeekEntity,
  useGetWtGraphLazyQuery
} from "../../../generated/graphql";
import SectionContainer from "../../shared/section_container/section_container";
import AutonomyBasicInfo from "./autonomy_basic_info";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import AutonomyDayGraph from "./autonomy_day_graph";

interface IProps {
  workingTemplateIdx?: number;
}

function AutonomyWorkGraph({ workingTemplateIdx }: IProps) {
  const [basicInfo, setBasicInfo] = useState<WtGraphBasicEntity>();
  const [weekDayOfWeekInfo, setWeekDayOfWeekInfo] = useState<
    WtGraphWeekEntity[]
  >([]);

  const [getWTGraph] = useGetWtGraphLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getWTGraph.ok) {
        if (data.getWTGraph.basicInfo) {
          setBasicInfo(data.getWTGraph.basicInfo);
        }
        if (data.getWTGraph.weekDayOfWeekInfo) {
          setWeekDayOfWeekInfo(data.getWTGraph.weekDayOfWeekInfo);
        }
      } else if (!data.getWTGraph.ok && data.getWTGraph.error) {
        handleErrorLog(data.getWTGraph.error);
      }
    }
  });

  useEffect(() => {
    if (workingTemplateIdx) {
      getWTGraph({
        variables: {
          workingTemplateIdx
        }
      });
    }
  }, [workingTemplateIdx]);

  return (
    <SectionContainer flex={0}>
      <AutonomyBasicInfo basicInfo={basicInfo} />
      <AutonomyDayGraph
        weekDayOfWeekInfo={weekDayOfWeekInfo}
        workingTemplateIdx={workingTemplateIdx}
      />
      {/* <AutonomyBasicGraph /> */}
    </SectionContainer>
  );
}

export default AutonomyWorkGraph;
