import {
  CountListOfPcInformation,
  CountListOfPcInformationVariables
} from "../../../../__generated__/CountListOfPcInformation";
import {
  DownloadListOfPcInformationExcel,
  DownloadListOfPcInformationExcelVariables
} from "../../../../__generated__/DownloadListOfPcInformationExcel";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useContextMenu from "../../../../hooks/use-context-menu/use-context-menu";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import {
  GetPcInformation,
  GetPcInformationVariables,
  GetPcInformation_getPcInformation_listOfPcInformation
} from "../../../../__generated__/GetPcInformation";
import {
  IsONOff,
  TYPE_OF_PC_INFORMATION
} from "../../../../__generated__/globalTypes";
import { SearchFieldValues } from "../../../asonic-table/asonic-search";
import * as ReactTable from "react-table";
import { downloadFileFromServer } from "../../statistics/Utils";
import moment from "moment";
import ContextMenu from "../../../context-menu/context-menu";
import WorkingTemplateManagementMenu from "../../../Rightmenu/working-template-management-menu/working-template-management-menu";
import useCheckWebAuthFn from "../../../../hooks/use-check-web-auth-fn";
import useWorkingTemplate, {
  IWorkingTemplate
} from "../../../../hooks/use-working-template/use-working-template";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import listOfApolloVar from "../../../../apollo/apollo-var";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";
import { useUpdateWorkingTemplateMutation } from "../../../../generated/graphql";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import { Role } from "../../../../user-types";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../table_v2/table_v2";
import TableLayoutFooter from "../../../table_layout/table_layout_footer";
import PageController from "../../../table/page_controller";
import useNewSortBy from "../../../../hooks/use-new-sort-by/use-new-sort-by";
import PcInfoSectionHeader from "./pc_info_section_header";

enum COLUMN_FOR_VALUE {
  isOnOff = "접속정보",
  name = "성명",
  departName = "부서명",
  workingName = "근무정책",
  deviceName = "Endpoint 구분",
  computerName = "컴퓨터명"
}

const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_GET_PC_INFORMATION = gql`
  query GetPcInformation(
    $selectedDepartmentId: String!
    $page: Float
    $take: Float
    $listOfSelectedUserId: [String!]
    $searchType: TYPE_OF_PC_INFORMATION
    $searchValue: String
    $fieldSort: FieldSort
  ) {
    getPcInformation(
      page: $page
      take: $take
      selectedDepartmentId: $selectedDepartmentId
      listOfSelectedUserId: $listOfSelectedUserId
      searchType: $searchType
      searchValue: $searchValue
      fieldSort: $fieldSort
    ) {
      ok
      error
      listOfPcInformation {
        name
        employeeId
        isOnOff
        departName
        workingName
        deviceName
        endpointId
        departmentId
        controlFlag
        deviceType
        platformType
        newDataTime
        lastBootTime
        fullName
        description
        osName
        osBit
        installDate
        memo1
        memo2
        localIp
        localIp2
        localIp3
        localMac
        localMac2
        localMac3
        computerName
        workGroup
        selfProtectFlag
        redirectSignFlag
        autoUpdateFlag
        newData
        workingTemplateIdx
        verDiffCount
      }
    }
  }
`;

const QUERY_COUNT_LIST_OF_PC_INFORMATION = gql`
  query CountListOfPcInformation(
    $selectedDepartmentId: String!
    $searchType: TYPE_OF_PC_INFORMATION
    $searchValue: String
    $listOfSelectedUserId: [String!]
  ) {
    countListOfPcInformation(
      selectedDepartmentId: $selectedDepartmentId
      searchType: $searchType
      searchValue: $searchValue
      listOfSelectedUserId: $listOfSelectedUserId
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_PC_INFORMATION = gql`
  query DownloadListOfPcInformationExcel(
    $selectedDepartmentId: String!
    $listOfSelectedUserId: [String!]
    $searchType: TYPE_OF_PC_INFORMATION
    $searchValue: String
  ) {
    downloadListOfPcInformationExcel(
      selectedDepartmentId: $selectedDepartmentId
      listOfSelectedUserId: $listOfSelectedUserId
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

const title = "PC 정보";

function PcInfoSection() {
  const selectedDepartmentId = useReactiveVar(
    listOfApolloVar.selectedDepartmentVar
  );
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [currentSearchType, setCurrentSearchType] =
    useState<TYPE_OF_PC_INFORMATION>(TYPE_OF_PC_INFORMATION.isOnOff);

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName
  } = useFixedColumn();

  const [isSearch, setIsSearch] = useState<boolean>(true);

  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchType, setSearchType] = useState<TYPE_OF_PC_INFORMATION>(
    TYPE_OF_PC_INFORMATION.isOnOff
  );

  const {
    listOfWorkingTemplate,
    selectedWorkingTemplate,
    handleSelectWorkingTemplate
  } = useWorkingTemplate();

  const {
    signInReducer: {
      checkWebAuthFn,
      webMenuList,
      loginIdInfo,
      employee_id,
      department_id
    }
  } = useSelector((state: Reducers) => state);

  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: webMenuList[0]?.web_etc_flag
  });
  const { oldFieldSort, fieldSort, handleFieldSort } = useNewSortBy();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const selectTemplate = useCallback(
    (temp: IWorkingTemplate) => () => {
      handleSelectWorkingTemplate(temp);
      handleConfirmMessage({
        title: `근무정책변경`,
        p: `${temp.name}으로 변경하시겠습니까?`,
        messageTypes: MessageTypes.INFO
      });
      handleIsOpen(true);
    },
    [handleSelectWorkingTemplate, handleConfirmMessage, handleIsOpen]
  );

  const [updateWorkingTemplate] = useUpdateWorkingTemplateMutation({
    onError(error) {
      handleApolloErrorLog(error);
    },
    update(cache, { data }, { variables }) {
      if (data?.updateWorkingTemplate.ok && variables?.employeeIdList) {
        handleToastMessageType(MessageTypes.SUCCESS);
        handleMessage(
          `${selectedWorkingTemplate?.name || "미사용"}으로 변경하였습니다.`
        );
        if (Array.isArray(variables?.employeeIdList)) {
          variables?.employeeIdList.forEach(item => {
            cache.modify({
              id: `EmployeeInformation:${item}`,
              fields: {
                workingName() {
                  return selectedWorkingTemplate?.name;
                }
              }
            });
          });
        }
      } else if (
        !data?.updateWorkingTemplate.ok &&
        data?.updateWorkingTemplate.error
      ) {
        handleErrorLog(data?.updateWorkingTemplate.error);
        handleToastMessageType(MessageTypes.WARNING);
        handleMessage(
          `${
            selectedWorkingTemplate?.name || "미사용"
          }으로 변경하지 못했습니다.`
        );
      }
      handleIsOpen(false);
      handleIsToastMessageOpen(true);
    }
  });

  const [getPcInformation, { data, loading }] = useLazyQuery<
    GetPcInformation,
    GetPcInformationVariables
  >(QUERY_GET_PC_INFORMATION, {
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      setIsSearch(false);
      if (!data.getPcInformation.ok && data.getPcInformation.error) {
        console.log(data.getPcInformation.error);
      }
    }
  });

  const [countListOfPcInformation, { data: totalData }] = useLazyQuery<
    CountListOfPcInformation,
    CountListOfPcInformationVariables
  >(QUERY_COUNT_LIST_OF_PC_INFORMATION);

  const [downloadListOfPcInformationExcel] = useLazyQuery<
    DownloadListOfPcInformationExcel,
    DownloadListOfPcInformationExcelVariables
  >(QUERY_DOWNLOAD_LIST_OF_PC_INFORMATION, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfPcInformationExcel.ok &&
        data.downloadListOfPcInformationExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfPcInformationExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-list-of-pc.csv`
        );
      }
    }
  });

  const columns: ReactTable.Column<GetPcInformation_getPcInformation_listOfPcInformation>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let width = 120;
        if (
          item === TYPE_OF_PC_INFORMATION.departName ||
          item === TYPE_OF_PC_INFORMATION.workingName ||
          item === TYPE_OF_PC_INFORMATION.computerName
        ) {
          width = 220;
        }
        let sticky = "";
        if (sFixedColumnNumber !== undefined) {
          if (fixedColumnName.length > 0) {
            fixedColumnName.forEach((fColumn, fColumnIndex) => {
              if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
                sticky = "left";
              }
            });
          }
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_PC_INFORMATION],
          accessor: item as TYPE_OF_PC_INFORMATION,
          width,
          sticky,
          Cell(
            cell: ReactTable.Cell<GetPcInformation_getPcInformation_listOfPcInformation>
          ) {
            if (cell.column.Header === "근무정책" && !cell.value) {
              return "미사용";
            }
            if (cell.column.Header === "접속정보") {
              if (cell.value === IsONOff.ON) {
                return "온라인";
              }
              return "오프라인";
            }
            return cell.value;
          }
        };
      });
    }, [sFixedColumnNumber, fixedColumnName]);

  const list: GetPcInformation_getPcInformation_listOfPcInformation[] =
    useMemo(() => {
      return data?.getPcInformation.listOfPcInformation || [];
    }, [data]);

  const table = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const { moveColumn } =
    useDnd<GetPcInformation_getPcInformation_listOfPcInformation>({
      columns: table.visibleColumns,
      setColumnOrder: table.setColumnOrder,
      title: `${title}-for-ordering-column`,
      setFixedColumnName
    });

  const selectedRow:
    | ReactTable.Row<GetPcInformation_getPcInformation_listOfPcInformation>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  const handleConfirm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let listOfPcId: string[] = [];
      listOfPcId = table.selectedFlatRows.map(item => {
        return item.original.employeeId as string;
      }, []);
      updateWorkingTemplate({
        variables: {
          employeeIdList: listOfPcId as string[],
          workingTemplateIdx: selectedWorkingTemplate?.working_templete_idx
        }
      });
    },
    [table.selectedFlatRows, selectedWorkingTemplate, updateWorkingTemplate]
  );

  const { handleContextMenu, isContextMenu, ctxMenuPosition } =
    useContextMenu();

  const downloadExcel = useCallback(() => {
    if (selectedDepartmentId) {
      downloadListOfPcInformationExcel({
        variables: {
          selectedDepartmentId,
          searchType,
          searchValue
        }
      });
    }
  }, [
    selectedDepartmentId,
    downloadListOfPcInformationExcel,
    searchType,
    searchValue
  ]);

  const total = useMemo(() => {
    if (
      totalData?.countListOfPcInformation.ok &&
      totalData?.countListOfPcInformation.total
    ) {
      return totalData?.countListOfPcInformation.total;
    }
    return 0;
  }, [totalData]);

  const listOfSearchType = useMemo(() => {
    return Object.keys(COLUMN_FOR_VALUE).map(item => ({
      value: item,
      name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
    }));
  }, []);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type) {
        const INIT_PAGE = 1;
        setCurrentSearchType(data.type as TYPE_OF_PC_INFORMATION);
        handleCurrentPage(INIT_PAGE);
        setCurrentSearchValue(data.value as string);
        setIsSearch(true);
        if (loginIdInfo === Role.USER && department_id && employee_id) {
          countListOfPcInformation({
            variables: {
              selectedDepartmentId: department_id.toString(),
              listOfSelectedUserId: [employee_id],
              searchType: data.type as TYPE_OF_PC_INFORMATION,
              searchValue: data.value
            }
          });
        } else {
          countListOfPcInformation({
            variables: {
              selectedDepartmentId,
              searchType: data.type as TYPE_OF_PC_INFORMATION,
              searchValue: data.value
            }
          });
        }
      }
    },
    [
      countListOfPcInformation,
      selectedDepartmentId,
      handleCurrentPage,
      department_id,
      employee_id,
      loginIdInfo
    ]
  );

  useEffect(() => {
    // 사용자 권한이 허용되지 않을때
    if (loginIdInfo === Role.USER && department_id) {
      countListOfPcInformation({
        variables: {
          selectedDepartmentId: department_id.toString(),
          listOfSelectedUserId: [employee_id],
          searchType,
          searchValue: currentSearchValue
        }
      });
    }
    if (
      loginIdInfo !== Role.USER &&
      selectedDepartmentId &&
      selectedListOfEmployeeId.length === 0
    ) {
      countListOfPcInformation({
        variables: {
          selectedDepartmentId,
          searchType,
          searchValue,
          listOfSelectedUserId: []
        }
      });
    }
    if (
      loginIdInfo !== -1 &&
      selectedDepartmentId &&
      selectedListOfEmployeeId.length > 0
    ) {
      countListOfPcInformation({
        variables: {
          selectedDepartmentId,
          listOfSelectedUserId: selectedListOfEmployeeId,
          searchType,
          searchValue
        }
      });
    }
  }, [
    countListOfPcInformation,
    selectedDepartmentId,
    selectedListOfEmployeeId,
    searchType,
    searchValue,
    department_id,
    employee_id,
    loginIdInfo
  ]);

  useEffect(() => {
    // 사용자 권한이 허용되지 않을때
    if (loginIdInfo === Role.USER && department_id && employee_id) {
      getPcInformation({
        variables: {
          page: currentPage,
          take: take,
          selectedDepartmentId: department_id.toString(),
          listOfSelectedUserId: [employee_id],
          searchType,
          searchValue,
          fieldSort: oldFieldSort
        }
      });
    }
    if (selectedDepartmentId && selectedListOfEmployeeId.length === 0) {
      getPcInformation({
        variables: {
          page: currentPage,
          take: take,
          selectedDepartmentId,
          listOfSelectedUserId: [],
          searchType,
          searchValue,
          fieldSort: oldFieldSort
        }
      });
    }
    if (selectedDepartmentId && selectedListOfEmployeeId.length > 0) {
      getPcInformation({
        variables: {
          page: currentPage,
          take: take,
          selectedDepartmentId,
          listOfSelectedUserId: selectedListOfEmployeeId,
          searchType,
          searchValue,
          fieldSort: oldFieldSort
        }
      });
    }
  }, [
    currentPage,
    take,
    getPcInformation,
    countListOfPcInformation,
    selectedDepartmentId,
    selectedListOfEmployeeId,
    searchType,
    searchValue,
    fieldSort,
    department_id,
    employee_id,
    loginIdInfo
  ]);

  useEffect(() => {
    if (!data?.getPcInformation.ok && data?.getPcInformation.error) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.getPcInformation.error);
      handleIsToastMessageOpen(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsToastMessageOpen]);

  useEffect(() => {
    if (isSearch) {
      setSearchValue(currentSearchValue);
      setSearchType(currentSearchType);
    }
  }, [isSearch, currentSearchValue, currentSearchType]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  return (
    <Container>
      <PcInfoSectionHeader<GetPcInformation_getPcInformation_listOfPcInformation>
        columns={
          table.columns as TColumn<GetPcInformation_getPcInformation_listOfPcInformation>[]
        }
        table={table}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                handleContextMenu={handleContextMenu}
                loading={loading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      <ContextMenu isContextMenu={isContextMenu} {...ctxMenuPosition}>
        {webMenuAuthList.serviceManagementInCtx && (
          <WorkingTemplateManagementMenu
            workingTemplate={listOfWorkingTemplate}
            selectTemplate={selectTemplate}
          />
        )}
      </ContextMenu>
      {selectedWorkingTemplate && isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default PcInfoSection;
