import styled from "styled-components";

const StyledInput = styled.input<{ $isFlex?: number; $width?: string }>`
  display: ${props => (props.$isFlex ? "flex" : "")};
  flex: ${props => props.$isFlex ?? ""};
  width: ${props => props.$width ?? ""};
  max-width: ${props => props.$width ?? ""};
  min-width: ${props => props.$width ?? ""};
  &:focus {
    border-color: ${props => props.theme.colors.green};
  }
`;

export default StyledInput;
