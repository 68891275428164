import moment from "moment";
import * as React from "react";
import personalApi from "../../../api/dashboard/personal-api";

type DataFromServer = {
  asexttime: number;
  asperio: string;
  astottime: number;
  asworktime: number;
  selected_date: string;
};

type Datasets = {
  type: string;
  backgroundColor: string;
  borderColor: string;
  data: number[];
  label?: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  pointRadius?: number;
  tension: number;
  datalabels: {
    display: boolean;
    clamp?: boolean;
  };
};

export type Data = {
  labels: string[];
  datasets: Datasets[];
};

type DataType = typeof REGULAR_WORK | typeof OVER_TIME_WORK | typeof TOTAL_WORK;

const REGULAR_WORK = "정규 근무",
  OVER_TIME_WORK = "연장 근무",
  TOTAL_WORK = "총 근무";

const BAR = "bar",
  LINE = "line";

type Props = {
  userId: string;
  selectedMonth: number;
};

const useMonthlyWorkingChartStatus = <P extends Props>({
  userId,
  selectedMonth
}: P) => {
  const isMounted = React.useRef<boolean>(false);
  const [period, setPeriod] = React.useState<string>("");
  const [regularWorkData, setRegularWorkData] = React.useState<number[]>([]);
  const [overTimeWorkData, setOverTimeWorkData] = React.useState<number[]>([]);
  const [maxHours, setMaxHours] = React.useState<number>(0);

  const [totalWorkData, setTotalWorkData] = React.useState<number[]>([]);
  const [labels, setLabels] = React.useState<string[]>([]);

  const makeDataSets = React.useCallback(
    (dataType: DataType) => {
      let data: number[] = [];
      let label = "";
      let type = BAR;
      let color = "#4C8AC8";
      let datalabels = {
        display: false
      };

      if (dataType === REGULAR_WORK || dataType === OVER_TIME_WORK) {
        if (dataType === REGULAR_WORK) {
          data = regularWorkData;
          label = REGULAR_WORK;
        } else {
          data = overTimeWorkData;
          label = OVER_TIME_WORK;
          color = "#053D80";
        }
      } else if (dataType === TOTAL_WORK) {
        datalabels.display = true;
        data = totalWorkData;
        label = TOTAL_WORK;
        color = "#F73763";
        type = LINE;
      }
      const dataSet: Datasets = {
        type: type,
        backgroundColor: color,
        borderColor: color,
        data,
        label,
        barThickness: 18,
        maxBarThickness: 20,
        minBarLength: 2,
        pointRadius: 1,
        tension: 0.2,
        datalabels
      };
      return dataSet;
    },
    [regularWorkData, overTimeWorkData, totalWorkData]
  );

  const datasets = React.useMemo(() => {
    const regularWorkDataSets = makeDataSets(REGULAR_WORK);
    const overTimeWorkDataSets = makeDataSets(OVER_TIME_WORK);
    const totalTimeWorkDataSets = makeDataSets(TOTAL_WORK);

    return [totalTimeWorkDataSets, regularWorkDataSets, overTimeWorkDataSets];
  }, [makeDataSets]);
  const chartData = React.useMemo(() => {
    const chartData = {
      labels,
      datasets
    };

    return chartData;
  }, [labels, datasets]);

  const handleData = React.useCallback(async () => {
    try {
      if (isMounted.current) {
        let newMaxHours = 0;
        let month = 0;
        const currentMonth = parseInt(moment().format("M"));
        if (selectedMonth !== currentMonth) {
          month = currentMonth - selectedMonth;
        }
        const { data } = await personalApi.monthlyWorkingStatus({
          userId,
          month
        });
        const newRegularWorkData: number[] = [];
        const newOverTimeWorkData: number[] = [];
        const newTotalWorkData: number[] = [];
        const newLabels: string[] = [];
        if (data?.length > 0) {
          setPeriod(data[0].asperio);
          data.forEach((item: DataFromServer) => {
            newLabels.push(
              item.selected_date.split("-")[
                item.selected_date.split("-").length - 1
              ]
            );

            newRegularWorkData.push(item.asworktime);
            newOverTimeWorkData.push(item.asexttime);
            newTotalWorkData.push(item.astottime);
            if (newMaxHours < item.astottime + 120) {
              newMaxHours = item.astottime + 120;
            }
          });
        }
        setLabels(newLabels);
        setRegularWorkData(newRegularWorkData);
        setOverTimeWorkData(newOverTimeWorkData);
        setTotalWorkData(newTotalWorkData);
        setMaxHours(newMaxHours);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId, selectedMonth]);

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
    handleData();
    return () => {
      isMounted.current = false;
    };
  }, [handleData]);

  React.useEffect(() => {
    let timeId: NodeJS.Timeout;
    if (isMounted.current) {
      timeId = setInterval(handleData, 300000);
    }
    return () => {
      clearInterval(timeId);
    };
  }, [handleData]);

  return { chartData, period, maxHours };
};

export default useMonthlyWorkingChartStatus;
