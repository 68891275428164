import styled from "styled-components";
import { EmployeeInformation, GroupEntity } from "../../../generated/graphql";
import useAsonicTab from "../../../hooks/hooks-shared/use-asonic-tab";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import AsonicTab from "../../shared/asonic-tab";
import ListOfEmployeeInGroups from "../approval-process/list-of-employee-in-groups";
import TreeOfApproval from "../approval-process/tree-of-approval";

const ETabs = {
  ORGANIZATION_CHART: "조직도"
} as const;

type ETabs = typeof ETabs;

type EKTabs = keyof typeof ETabs;

const Container = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 10px;
  min-width: 500px;
`;

const Content = styled.div`
  display: flex;
  flex: 10;
  border: 1px solid ${props => props.theme.colors.grey};
`;

interface IProps {
  selectedDepartment?: number;
  handleSelectedDepartment: (department: GroupEntity) => void;
  handleAddUser: (user?: ICompactRow<EmployeeInformation>) => void;
}

function ApprovalReceiveAndReferenceList({
  selectedDepartment,
  handleSelectedDepartment,
  handleAddUser
}: IProps) {
  const { list, selectedTab, handleSelectTab } = useAsonicTab<ETabs, EKTabs>({
    tabs: ETabs
  });

  return (
    <Container>
      <AsonicTab<EKTabs>
        list={list}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      <Content>
        <TreeOfApproval handleSelectedDepartment={handleSelectedDepartment} />
      </Content>
      <ListOfEmployeeInGroups
        selectedDepartment={selectedDepartment}
        handleAddUser={handleAddUser}
      />
    </Container>
  );
}

export default ApprovalReceiveAndReferenceList;
