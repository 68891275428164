import styled from "styled-components";

interface RadioContainerProps {
  alignItems?: string;
}

const RadioContainer = styled.div<RadioContainerProps>`
  display: flex;
  flex: 1;
  align-items: ${({ alignItems = "center" }) => alignItems};
  gap: 5px;
  label {
    display: flex;
    min-width: max-content;
  }
`;

export default RadioContainer;
