import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  delQueryFromServer,
  publishQuery,
  saveQueryToServer
} from "../../../../Utils/commonAxiosCall";
import CheckBoxUi from "../../../globalComponents/CheckBoxUi";
import {
  ICustomQuery,
  IStatisticsState
} from "../admin-statistics/admin-statistics";
import { Readonly, IsPublish } from "../list-of-statistics/list-of-statistics";
import {
  IAlertOpen,
  IMainViewContext,
  IQueryList
} from "../StatsContainer/StatsTypes";
import TextInputWithUnderline from "../../../inputs/text-input-with-underline";
import { useForm } from "react-hook-form";
import Button from "../../../globalComponents/Button";
import EmployeeSelectorDlg from "../../smallcomponents/EmployeeSelectorDlg";
import DepartmentSelectorDlg from "../../smallcomponents/DepartmentSelectorDlg";
import ResultCategory from "../ResultCategory";
import MainViewContext from "../../store";
import Date from "../../Date/date";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
export interface IStatisticsProps {
  selectedQuery?: ICustomQuery;
  statisticsState?: Partial<IStatisticsState>;
  handleCustomQueryList: (data: IQueryList[]) => void;
  handleSelectedQuery: (payload?: ICustomQuery) => void;
  handleStatisticsState: (payload: Partial<IStatisticsState>) => void;
  refreshData: () => void;
  startDate: string;
  endDate: string;
  handleStartDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleShowResult: (value: boolean) => void;
  isAdmin?: boolean;
}

interface IStatisticsDetail {
  title: string;
  description: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 8px;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-self: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 3;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${ButtonContainer} {
    align-self: flex-start;
  }
`;

const RW_ADMIN = 2;

function StatisticsDetail({
  selectedQuery,
  statisticsState,
  handleCustomQueryList,
  handleSelectedQuery,
  handleStatisticsState,
  refreshData,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  handleShowResult,
  isAdmin
}: IStatisticsProps) {
  const rootStore: IMainViewContext = useContext(MainViewContext);
  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const handleApprovalResult = useCallback(
    (value: string) => {
      // 999 : 전체
      // 1 : 처리
      // 3 : 미처리
      const option = parseInt(value, 10);
      const approval_result = option === 1 || option === 3 ? option : undefined;
      handleStatisticsState({
        approval_result
      });
    },
    [handleStatisticsState]
  );
  const [isUserDialogOpen, setIsUserDialogOpen] = useState<boolean>(false);
  const [isSaveQuery, setIsSaveQuery] = useState<boolean>(false);
  const handleCloseUserDialog = useCallback(() => {
    setIsUserDialogOpen(false);
  }, []);

  const [isDepartmentDialogOpen, setIsDepartmentDialogOpen] =
    useState<boolean>(false);
  const handleCloseDepartmentDialog = useCallback(() => {
    setIsDepartmentDialogOpen(false);
  }, []);

  const { register, setValue, getValues, watch } = useForm<IStatisticsDetail>({
    mode: "onSubmit"
  });

  const titleRegister = register("title");
  const descriptionRegister = register("description");

  const handleCheckPublish = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedQuery && handleSelectedQuery) {
        const isPublish =
          selectedQuery.publish === IsPublish.PUBLISH
            ? IsPublish.NOT_ALLOWED
            : IsPublish.PUBLISH;
        const newSelectedQuery = { ...selectedQuery };
        newSelectedQuery.publish = isPublish;
        publishQuery(selectedQuery.index, isPublish).then(ret => {
          if (ret.status === 200 && ret.data) {
            handleSelectedQuery(newSelectedQuery);
            handleCustomQueryList(ret.data);
          }
        });
      }
    },
    [selectedQuery, handleCustomQueryList, handleSelectedQuery]
  );

  const saveQuery = useCallback(() => {
    setIsSaveQuery(true);
    handleConfirmMessage({
      title: "저장",
      p: "해당 변경사항을 저장하시겠습니까?",
      messageTypes: MessageTypes.INFO
    });
    handleIsOpen(true);
  }, []);

  const delQuery = useCallback(() => {
    setIsSaveQuery(false);
    handleShowResult(false);
    handleConfirmMessage({
      title: "쿼리 삭제",
      p: "해당 쿼리를 삭제하시겠습니까?",
      messageTypes: MessageTypes.WARNING
    });
    handleIsOpen(true);
  }, [handleShowResult]);

  const alertDialogClose = useCallback(() => {
    if (selectedQuery && statisticsState) {
      if (isSaveQuery) {
        const { title, description } = getValues();
        const saveQuery = {
          index: selectedQuery.index,
          title,
          description,
          base_query: statisticsState.base_query,
          saveas: selectedQuery.readonly, // 다른이름으로 저장
          employees: statisticsState.employees,
          departments: statisticsState.departments,
          approval_result: statisticsState.approval_result,
          start_date: startDate,
          end_date: endDate
        };
        saveQueryToServer(saveQuery)
          .then(response => {
            if (response.status === 200) {
              handleToastMessageType(MessageTypes.SUCCESS);
              handleMessage(`${title} 쿼리를 추가 했습니다.`);
              refreshData();
            }
          })
          .catch(e => {
            handleToastMessageType(MessageTypes.ERROR);
            handleMessage(`${title} 쿼리를 추가 하지 못 했습니다.`);
          });
      } else if (!isSaveQuery) {
        delQueryFromServer(selectedQuery.index)
          .then(response => {
            if (response.status === 200) {
              handleToastMessageType(MessageTypes.SUCCESS);
              handleMessage(`${selectedQuery.title} 쿼리를 삭제 했습니다.`);
              refreshData();
            }
          })
          .catch(e => {
            handleToastMessageType(MessageTypes.WARNING);
            handleMessage(`${selectedQuery.title} 쿼리를 삭제하지 못했습니다.`);
          });
      }
      handleIsToastMessageOpen(true);
    }
    handleIsOpen(false);
  }, [
    isSaveQuery,
    handleIsOpen,
    selectedQuery,
    statisticsState,
    refreshData,
    getValues,
    startDate,
    endDate,
    handleIsToastMessageOpen,
    handleToastMessageType,
    handleMessage
  ]);

  useEffect(() => {
    if (selectedQuery) {
      setValue("title", selectedQuery.title);
      setValue("description", selectedQuery.description);
    }
  }, [selectedQuery, setValue]);

  return (
    <Container>
      {selectedQuery &&
        selectedQuery.base_query &&
        selectedQuery.readonly === Readonly.READ &&
        isAdmin && (
          <CheckBoxContainer>
            <CheckBoxUi
              checked={selectedQuery.publish === IsPublish.PUBLISH}
              onChange={handleCheckPublish}
              name="해당 쿼리를 일반 사용자들과 공유합니다."
            />
          </CheckBoxContainer>
        )}
      <TextContainer>
        <TextInputWithUnderline
          title="검색할 통계"
          placeholder="검색할 통계를 입력해주세요"
          {...titleRegister}
          value={watch("title")}
        />
        <TextInputWithUnderline
          title="상세 설명"
          placeholder="검색할 통계를 입력해주세요"
          {...descriptionRegister}
          value={watch("description")}
        />
        {selectedQuery?.where_date && (
          <ButtonContainer>
            <Date
              title="기간"
              startDate={startDate}
              endDate={endDate}
              startOnChange={handleStartDate}
              endOnChange={handleEndDate}
            />
          </ButtonContainer>
        )}
      </TextContainer>
      <ButtonContainer>
        {selectedQuery?.where_process_result && (
          <ResultCategory handleApprovalResult={handleApprovalResult} />
        )}
        {isAdmin && selectedQuery?.where_employee_id && (
          <Button
            onClick={() => {
              setIsUserDialogOpen(true);
            }}
          >
            사용자 지정
          </Button>
        )}
        {selectedQuery?.where_department_id && (
          <Button
            onClick={() => {
              setIsDepartmentDialogOpen(true);
            }}
          >
            부서 지정
          </Button>
        )}
        {rootStore.signInReducer.loginIdInfo === RW_ADMIN && isAdmin && (
          <Button onClick={saveQuery}>
            {selectedQuery?.readonly === Readonly.READ
              ? "다른이름저장"
              : "저장"}
          </Button>
        )}
        <Button
          onClick={() => {
            handleShowResult(true);
          }}
        >
          결과보기
        </Button>
        {selectedQuery && selectedQuery.readonly !== 1 && (
          <Button onClick={delQuery}>쿼리삭제</Button>
        )}
      </ButtonContainer>
      {isUserDialogOpen && (
        <EmployeeSelectorDlg
          title="사용자 지정"
          description="조건에 들어갈 사원을 검색합니다. (두글자 이상 입력해주세요)"
          handleClose={handleCloseUserDialog}
          handleStatisticsState={handleStatisticsState}
        />
      )}
      {isDepartmentDialogOpen && (
        <DepartmentSelectorDlg
          title="부서 지정"
          description="조건에 들어갈 부서를 검색합니다. (두글자 이상 입력해주세요)"
          handleClose={handleCloseDepartmentDialog}
          handleStatisticsState={handleStatisticsState}
        />
      )}
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={alertDialogClose}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default StatisticsDetail;
