import { useCallback, useState } from "react";
import TextField from "../TextField";
import uiString from "../string.json";
import { getVoters, addDelegater } from "../../../../Utils/commonAxiosCall";
import Button from "../../../globalComponents/Button";
import Date from "../../Date/date";
import moment from "moment";
import { MessageTypes } from "../../../toast-message/toast-message";
import CollapsiblePanel from "../../smallcomponents/CollapsiblePanel";
import styled from "styled-components";
import ProfileCard from "../../../profile-card/profile-card";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import RemoveIcon from "@iconify/icons-fa-solid/minus-circle";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import AsonicIconButton from "../../../shared/asonic-icon-button/asonic-icon-button";
import SearchIcon from "@iconify/icons-mdi/magnify";
import { colors } from "../../../GlobalStyle/GlobalStyle";

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  svg {
    font-size: 18px;
  }
`;

const ListSection = styled.section`
  display: flex;
  height: ${props => (props.isHeight ? "90px" : "0px")};
`;

const RenderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin: 0px 10px;
  }
`;

const SearchSection = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  svg {
    font-size: 24px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;
`;

function RenderRow(onClick, isSelected) {
  return ({ data, index, style }) => {
    const user = data[index];
    let base64String = "";
    if (user?.my_picture?.data && user.my_picture.data.length > 1) {
      const buf = Buffer.from(user.my_picture.data);
      base64String = buf.toString("base64");
    }
    const etc = user?.startDate
      ? `결재 가능 기간 - ${user.startDate.format(
          "YYYY-MM-DD"
        )} ~ ${user.endDate.format("YYYY-MM-DD")}`
      : "";
    const userInfo = {
      employeeId: user.employee_id,
      name: user.employee_name,
      department: user.department_name,
      picture: base64String,
      telephone: user?.telno || "",
      email: user?.user_email || "",
      etc
    };

    return (
      <RenderContainer style={style}>
        <ProfileCard userInfo={userInfo}>
          <AsonicIconButton
            icon={isSelected ? RemoveIcon : AddIcon}
            onClick={onClick(user)}
            color={isSelected ? colors.tomato : ""}
          />
        </ProfileCard>
      </RenderContainer>
    );
  };
}

function Delegate(props) {
  const [name, setName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [chosenDelegaters, setChosenDelegaters] = useState(
    props.delegaterInfo ? [props.delegaterInfo] : []
  );

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const handleStartDate = useCallback(event => {
    setStartDate(event.target.value);
  }, []);

  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const handleEndDate = useCallback(event => {
    setEndDate(event.target.value);
  }, []);

  // 기존 선택된 대리 결재자를 표시하기 위해 props를 변경해야함
  // 그 이유로 getDerivedStateFromProps를 써야하는가???
  //   static getDerivedStateFromProps(nextProps, prevState) {
  //     if (nextProps.delegaterInfo && prevState.chosenDelegaters.length < 1) {
  //       let choseList = [];
  //       choseList.push({
  //         ...nextProps.delegaterInfo,
  //         key: nextProps.delegaterInfo.employee_id,
  //         startDate: DateTime.fromJSDate(
  //           new Date(nextProps.delegaterInfo.approval_start_date)
  //         ),
  //         endDate: DateTime.fromJSDate(
  //           new Date(nextProps.delegaterInfo.approval_end_date)
  //         )
  //       });
  //       return { chosenDelegaters: choseList };
  //     }
  //     return null;
  //   }

  const handleAdd = useCallback(
    data => () => {
      if (chosenDelegaters.length > 0) {
        const { handleIsOpen, handleMessage, handleToastMessageType } = props;
        handleIsOpen(true);
        handleMessage(uiString.notiBars.alreadyHaveDelegater);
        handleToastMessageType(MessageTypes.WARNING);
        return;
      }

      const chosenList = [...chosenDelegaters];

      let alreadyHave = -1;
      for (let i = 0; i < chosenList.length; i++) {
        if (chosenList[i].employee_id === data.employee_id) {
          alreadyHave = i;
          break;
        }
      }

      if (alreadyHave === -1) {
        chosenList.push({
          ...data,
          startDate: moment(startDate),
          endDate: moment(endDate)
        });
      }

      setChosenDelegaters(chosenList);
    },
    [props, chosenDelegaters, endDate, startDate]
  );

  const handleDeleteDelegater = useCallback(
    chosenUser => () => {
      const chosenList = [...chosenDelegaters];
      for (let i = 0; i < chosenList.length; i++) {
        if (chosenList[i].employee_id === chosenUser.employee_id) {
          chosenList.splice(i, 1);
          break;
        }
      }
      setChosenDelegaters(chosenList);
    },
    [chosenDelegaters]
  );

  const handleSearchDelegate = useCallback(() => {
    const {
      departmentId,
      handleIsOpen,
      handleMessage,
      handleToastMessageType
    } = props;

    if (name.length < 2) {
      handleIsOpen(true);
      handleMessage(uiString.notiBars.nameLessThanTwoLetters);
      handleToastMessageType(MessageTypes.WARNING);
    } else {
      getVoters(departmentId, name)
        .then(voters => {
          if (!voters.data) {
            handleIsOpen(true);
            handleMessage("대리 결재자가 존재하지 않습니다.");
            handleToastMessageType(MessageTypes.WARNING);
          } else {
            let newSearchResult = [];
            for (let user of voters.data) {
              user["key"] = user.employee_id;
              newSearchResult.push(user);
            }
            setSearchResult(newSearchResult);
          }
        })
        .catch(error => {
          handleIsOpen(true);
          handleMessage("대리 결재자를 가져오지 못했습니다.");
          handleToastMessageType(MessageTypes.ERROR);
        });
    }
  }, [name, props]);

  const handleNameUpdate = useCallback(event => {
    setName(event.target.value);
  }, []);

  const _handleKeyPress = useCallback(
    event => {
      if (event.key === "Enter") {
        handleSearchDelegate();
      }
    },
    [handleSearchDelegate]
  );

  const handleSaveDelegate = useCallback(async () => {
    const result = await addDelegater(props.employeeId, chosenDelegaters);
    const { handleIsOpen, handleMessage, handleToastMessageType } = props;
    if (result) {
      handleIsOpen(true);
      handleMessage(uiString.notiBars.updateDelegaterSuccess);
      handleToastMessageType(MessageTypes.SUCCESS);
    } else {
      handleIsOpen(true);
      handleMessage(uiString.notiBars.updateDelegaterFailure);
      handleToastMessageType(MessageTypes.ERROR);
    }
  }, [props, chosenDelegaters]);

  return (
    <CollapsiblePanel title={uiString.titles.delegate}>
      <Container>
        <SearchSection>
          <Date
            startDate={startDate}
            endDate={endDate}
            startOnChange={handleStartDate}
            endOnChange={handleEndDate}
            title={uiString.textFields.periodForApproval}
          />
          <TextContainer>
            <TextField
              label={uiString.textFields.nameOfDelegate}
              placeholder={uiString.textFields.nameOfDelegate}
              type="text"
              valueChange={handleNameUpdate}
              onKeyPress={_handleKeyPress}
              value={name}
            />
            <AsonicIconButton
              icon={SearchIcon}
              onClick={handleSearchDelegate}
            />
          </TextContainer>
        </SearchSection>
        <ListSection isHeight={searchResult.length > 0}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={searchResult.length}
                itemData={searchResult}
                itemSize={330}
                width={width}
                layout="horizontal"
              >
                {RenderRow(handleAdd, false)}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <Divider style={{ margin: "30px 0px 30px 0px" }} />
        <h3>{uiString.titles.chosenDelegater}</h3>
        <ListSection isHeight={chosenDelegaters.length > 0}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={chosenDelegaters.length}
                itemData={chosenDelegaters}
                itemSize={330}
                width={width}
                layout="horizontal"
              >
                {RenderRow(handleDeleteDelegater, true)}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }} />
            <Button onClick={handleSaveDelegate}>
              {uiString.buttons.confirm}
            </Button>
          </div>
        </div>
      </Container>
    </CollapsiblePanel>
  );
}

export default Delegate;
