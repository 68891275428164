import styled from "styled-components";
import { Icon } from "@iconify/react";
import ArrowLeftIcon from "@iconify-icons/cil/arrow-circle-left";
import ArrowRightIcon from "@iconify-icons/cil/arrow-circle-right";
import Button from "../../../globalComponents/Button";
import { BUTTON_COLOR } from "../../../../screens/constants";

interface IProps {
  deleteUsers: () => void;
  addUser: () => void;
}

// Container 스타일 정의
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-weight: bold;

  // SVG 아이콘 스타일
  svg {
    font-size: 18px;
  }

  // 버튼 스타일
  button {
    display: flex;
    gap: 10px;
    padding-left: 18px;
    align-items: center;
  }
`;

// Name 스타일 정의
const Name = styled.span`
  display: flex;
  flex: 1;
  min-width: max-content;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

function ApprovalSelectAddRemoveButtons({ deleteUsers, addUser }: IProps) {
  return (
    <Container>
      <Button onClick={addUser}>
        <Icon icon={ArrowRightIcon} />
        <Name>{`추가`}</Name>
      </Button>
      <Button
        backgroundColor={BUTTON_COLOR.CANCEL}
        hoverBackgroundColor={BUTTON_COLOR.CANCEL_HOVER}
        onClick={deleteUsers}
      >
        <Icon icon={ArrowLeftIcon} />
        <Name>{`삭제`}</Name>
      </Button>
    </Container>
  );
}

export default ApprovalSelectAddRemoveButtons;
