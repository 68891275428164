import * as React from "react";

const useSaveDebuggingFile = () => {
  const makeJsonFile = React.useCallback(() => {
    let data = "{";
    for (let key in window.sessionStorage) {
      let value = window.sessionStorage[key];

      let debugMsg = `"${key}": ${value},\n`;
      data += debugMsg;
    }
    data += "}";
    const blob = new Blob([data], { type: "application/json" });
    return URL.createObjectURL(blob);
  }, []);

  const saveJsonFile = React.useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const a = document.createElement("a");
      a.href = makeJsonFile();
      a.download = "debugging";
      a.click();
    },
    [makeJsonFile]
  );

  return { saveJsonFile };
};

export default useSaveDebuggingFile;
