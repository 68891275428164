import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { rrulestr } from "rrule";
import {
  SerializeItem,
  GoogleEvent,
  ListOfGoogleColor
} from "../../link-google-hooks/use-google-calendar";

interface IProps {
  googleCalendarlist: SerializeItem[];
  googleEventOfList: GoogleEvent[];
  listOfGoogleColor: ListOfGoogleColor;
  cellData: Partial<DayInfo>;
  currentMoment?: string;
}

const useCalendarEventList = <P extends IProps>({
  googleCalendarlist,
  googleEventOfList,
  listOfGoogleColor,
  cellData,
  currentMoment
}: P) => {
  const [eventList, setEventList] = useState<Partial<DayInfo>[]>([]);

  const checkCreator = useCallback(
    (googleCalendarlist: SerializeItem[], event: GoogleEvent) => {
      const filteringEvent = googleCalendarlist.filter(item => {
        return item.origin.id === event.organizer.email;
      });
      return filteringEvent[0];
    },
    []
  );

  const checkIsBetween = useCallback(
    (
      startDate: string,
      endDate: string,
      comparisonTarget: string = ""
    ): boolean => {
      let toBelongToTheStartingAndEndingDates = false;
      toBelongToTheStartingAndEndingDates = moment(comparisonTarget).isBetween(
        startDate,
        endDate,
        undefined,
        "[)"
      );
      if (startDate === endDate) {
        toBelongToTheStartingAndEndingDates = moment(
          comparisonTarget
        ).isBetween(startDate, endDate, undefined, "[]");
      }
      return toBelongToTheStartingAndEndingDates;
    },
    []
  );

  const checkIsRecurrence = useCallback(
    (
      startDate: string,
      recurrenceRule: string,
      convertStartDate: string,
      convertEndDate: string,
      comparisonTarget: string = ""
    ): boolean => {
      let isRecurrence: boolean = false;
      const newRecurrenceRule = rrulestr(recurrenceRule);
      newRecurrenceRule.options.dtstart = moment(
        startDate,
        "YYYY-MM-DD"
      ).toDate();

      if (recurrenceRule === "RRULE:FREQ=YEARLY") {
        newRecurrenceRule.options.bymonthday = [
          parseInt(moment(startDate, "YYYY-MM-DD").format("D"))
        ];
      }
      const recurrenceEventList = newRecurrenceRule.between(
        moment(convertStartDate, "YYYY-MM-DD").toDate(),
        moment(convertEndDate, "YYYY-MM-DD").add(1, "day").toDate()
      );

      recurrenceEventList.forEach(item => {
        if (comparisonTarget === moment(item).format("YYYY-MM-DD")) {
          isRecurrence = true;
        }
      });

      return isRecurrence;
    },
    []
  );

  const getColorInListOfGoogleColor = useCallback(
    (
      listOfGoogleColor: ListOfGoogleColor,
      event: GoogleEvent,
      calendarColorId: string
    ) => {
      if (event.colorId) {
        const colors = listOfGoogleColor.event[event.colorId];
        return colors;
      }
      return listOfGoogleColor.calendar[calendarColorId];
    },
    []
  );

  const makeList = useCallback(() => {
    let newListOfExtraData: Partial<DayInfo>[] = [];
    if (cellData.extraData) {
      newListOfExtraData = [...cellData.extraData];
    }
    const convertStartDate = moment(currentMoment, "YYYY-MM-DD")
      .startOf("month")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");

    const convertEndDate = moment(currentMoment, "YYYY-MM-DD")
      .endOf("month")
      .endOf("isoWeek")
      .format("YYYY-MM-DD");
    googleEventOfList.forEach(item => {
      if (!item.start?.date && !item.start?.dateTime) {
        return;
      }

      const startDate = moment(item.start?.date || item.start.dateTime).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(item.end?.date || item.end.dateTime).format(
        "YYYY-MM-DD"
      );

      const toBelongToTheStartingAndEndingDates = checkIsBetween(
        startDate,
        endDate,
        cellData.fullDate
      );

      let isRecurrence = false;
      if (item.recurrence && item.recurrence[0]) {
        isRecurrence = checkIsRecurrence(
          startDate,
          item.recurrence[0],
          convertStartDate,
          convertEndDate,
          cellData.fullDate
        );
      }

      if (toBelongToTheStartingAndEndingDates || isRecurrence) {
        const filteringCalendar = checkCreator(googleCalendarlist, item);
        const eventColors = getColorInListOfGoogleColor(
          listOfGoogleColor,
          item,
          filteringCalendar.origin.colorId
        );
        const newExtraData = {
          date: cellData.date,
          day: cellData.day,
          fullDate: cellData.fullDate,
          isCurrentMonth: cellData.isCurrentMonth,
          scheduleName: "",
          today: cellData.today,
          totalTime: `${item.summary}`,
          backgroundColor: eventColors?.background,
          labelColor: filteringCalendar.origin.backgroundColor,
          original: item
        };
        newListOfExtraData.push(newExtraData);
      }
    });
    setEventList(newListOfExtraData);
  }, [
    checkCreator,
    checkIsBetween,
    checkIsRecurrence,
    getColorInListOfGoogleColor,
    googleCalendarlist,
    googleEventOfList,
    listOfGoogleColor,
    cellData,
    currentMoment
  ]);

  useEffect(() => {
    makeList();
  }, [makeList]);

  return { eventList };
};

export default useCalendarEventList;
