import React, { Component } from "react";
import { ProfileDiv, UserInfoDiv, UserPhoto, Title, SubTitle } from "./styled";

class UserProfile extends Component {
  render() {
    const { signInReducer, open } = this.props;
    let photo;
    if (
      signInReducer.my_picture &&
      signInReducer.my_picture.data.length !== 1
    ) {
      const buf = Buffer.from(signInReducer.my_picture.data);
      const base64String = buf.toString("base64");
      photo = base64String;
    }

    return (
      <ProfileDiv>
        <UserPhoto open={open} image={photo} />
        {open && (
          <UserInfoDiv>
            <Title>{signInReducer.name} 님</Title>
            <SubTitle>{signInReducer.department_name}</SubTitle>
          </UserInfoDiv>
        )}
      </ProfileDiv>
    );
  }
}

export default UserProfile;
