import * as React from "react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { MapButton } from "./styled";
import ReactTooltip from "react-tooltip";
interface Props {
  handleOpenDialog: (value: boolean) => void;
}

const MapRegister = (props: Props) => {
  const { handleOpenDialog } = props;
  return (
    <>
      <ReactTooltip type="success" />
      <MapButton
        onClick={() => handleOpenDialog(true)}
        data-tip="출퇴근gps 설정"
        icon={faMapMarkerAlt}
        size="lg"
      />
    </>
  );
};

export default MapRegister;
