export enum ESocketChannel {
  APPROVAL_USER_SELECTOR_CHANNEL = "APPROVAL_USER_SELECTOR_CHANNEL"
}

export enum EApprovalUserSelectorPacketSource {
  // 결재관리 - 결재설정 - 관리자설정 - 결재양식설정 - 사용자설정 - new window
  APPROVAL_USER_SELECTOR_VIEW = "APPROVAL_USER_SELECTOR_VIEW"
}

export interface IApprovalUserSelectorPacket {
  source: EApprovalUserSelectorPacketSource;
  formTemplateIdx?: string;
  list: string[];
}
