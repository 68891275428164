import styled from "styled-components";
import {
  CompanyEntity,
  useGetInfoLessThanAYearLazyQuery,
  InfoLessThanAYearEntity,
  Annual_Accrual_Type_For_Less_Than_A_Year,
  Minus_Annual_Type_For_Less_Than_A_Year,
  Month_Accrual_Type,
  useUpdateInfoLessThanYearMutation,
  UpdateInfoLessThanYearMutationVariables
} from "../../generated/graphql";
import SubTitle from "../shared/sub-title/sub-title";
import FormRow from "../shared/form-row/form-row";
import StyleInput from "../inputs/style-input";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import Button from "../globalComponents/Button";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import InfoLessThanAYearHelp from "./info-less-than-a-year-help";

interface IProps {
  selectedCompany?: CompanyEntity;
}

const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 1200px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  min-width: 900px;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 460px;
  min-width: 460px;
  :first-child {
    select {
      min-width: 244px;
    }
    span {
      width: max-content;
    }
  }
  :nth-child(2) {
    select {
      min-width: 200px;
    }
  }
`;

function InfoLessThanAYearSetting({ selectedCompany }: IProps) {
  const { register, setValue, handleSubmit, control } =
    useForm<InfoLessThanAYearEntity>({
      defaultValues: {
        annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year.Monthly,
        fixCount: 1,
        minusAnnualDayCount: 1,
        minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year.Apply,
        monthAccrualType: Month_Accrual_Type.AFullMonthsEmployment
      }
    });

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const listOfAnnualAccrualType = useMemo(
    () => [
      {
        title: "월별 발생",
        value: Annual_Accrual_Type_For_Less_Than_A_Year.Monthly
      },
      {
        title: "고정일 부여",
        value: Annual_Accrual_Type_For_Less_Than_A_Year.FixDate
      }
    ],
    []
  );

  const listOfMonthAccrualType = useMemo(
    () => [
      {
        title: "입사 당월",
        value: Month_Accrual_Type.TheMonthOfEmployment
      },
      {
        title: "입사 기준일",
        value: Month_Accrual_Type.TheBaseDateForJoining
      },
      {
        title: "입사 1개월 만근일",
        value: Month_Accrual_Type.AFullMonthsEmployment
      }
    ],
    []
  );

  const listOfMinusAnnualType = useMemo(
    () => [
      {
        title: "사용",
        value: Minus_Annual_Type_For_Less_Than_A_Year.Apply
      },
      {
        title: "미사용",
        value: Minus_Annual_Type_For_Less_Than_A_Year.NotApplied
      }
    ],
    []
  );

  const [getInfoLessThanAYear] = useGetInfoLessThanAYearLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getInfoLessThanAYear.ok && data.getInfoLessThanAYear.info) {
        const {
          annualAccrualType,
          fixCount,
          minusAnnualDayCount,
          minusAnnualType,
          monthAccrualType
        } = data.getInfoLessThanAYear.info;
        setValue("annualAccrualType", annualAccrualType);
        setValue("fixCount", fixCount);
        setValue("minusAnnualDayCount", minusAnnualDayCount);
        setValue("minusAnnualType", minusAnnualType);
        setValue("monthAccrualType", monthAccrualType);
      }
    }
  });

  const [updateInfoLessThanYear] = useUpdateInfoLessThanYearMutation({
    onError(error) {
      console.log(error);
      handleToast(
        "만 1년 미만 (입사년도) 설정을 저장하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.updateInfoLessThanYear.ok) {
        handleToast(
          "성공적으로 만 1년 미만 (입사년도) 설정을 저장하였습니다.",
          MessageTypes.SUCCESS
        );
      } else if (data?.updateInfoLessThanYear.error) {
        handleToast(data.updateInfoLessThanYear.error, MessageTypes.ERROR);
      }
    }
  });

  const handleUpdate = useCallback(
    (data: InfoLessThanAYearEntity) => {
      if (selectedCompany) {
        const payload: UpdateInfoLessThanYearMutationVariables = {
          companyCode: selectedCompany.companyCode,
          annualAccrualType: data.annualAccrualType,
          monthAccrualType: data.monthAccrualType,
          fixCount: parseInt(`${data.fixCount}`),
          minusAnnualType: data.minusAnnualType,
          minusAnnualDayCount: parseInt(`${data.minusAnnualDayCount}`)
        };
        updateInfoLessThanYear({ variables: payload });
      }
    },
    [updateInfoLessThanYear, selectedCompany]
  );

  const annualAccrualType = useWatch({
    control,
    name: "annualAccrualType" // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const minusAnnualType = useWatch({
    control,
    name: "minusAnnualType"
  });

  useEffect(() => {
    if (selectedCompany) {
      getInfoLessThanAYear({
        variables: {
          companyCode: selectedCompany.companyCode
        }
      });
    }
  }, [selectedCompany, getInfoLessThanAYear]);

  return (
    <Container onSubmit={handleSubmit(handleUpdate)}>
      <TitleBar>
        <SubTitle title="만 1년 미만 (입사년도)" />
        <InfoLessThanAYearHelp />
      </TitleBar>
      <Body>
        <Section>
          <FormRow title="연차 발생 방식 설정">
            <select {...register("annualAccrualType")}>
              {listOfAnnualAccrualType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
          <FormRow title="월별 발생 기준시점">
            <select
              {...register("monthAccrualType")}
              disabled={
                annualAccrualType !==
                Annual_Accrual_Type_For_Less_Than_A_Year.Monthly
              }
            >
              {listOfMonthAccrualType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
          <FormRow title="마이너스 연차 사용 설정">
            <select {...register("minusAnnualType")}>
              {listOfMinusAnnualType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
        </Section>
        <Section>
          <FormRow title=""></FormRow>
          <FormRow title="고정일 부여 일자">
            <StyleInput
              type="number"
              min={0}
              minWidth="190px"
              {...register("fixCount")}
              disabled={
                annualAccrualType !==
                Annual_Accrual_Type_For_Less_Than_A_Year.FixDate
              }
            />
            <span>{`일`}</span>
          </FormRow>
          <FormRow title="마이너스연차 허용일">
            <StyleInput
              type="number"
              min={0}
              minWidth="190px"
              {...register("minusAnnualDayCount")}
              disabled={
                minusAnnualType ===
                Minus_Annual_Type_For_Less_Than_A_Year.NotApplied
              }
            />
            <span>{`일`}</span>
          </FormRow>
        </Section>
      </Body>
      <ButtonContainer>
        <Button>저장</Button>
      </ButtonContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default InfoLessThanAYearSetting;
