import moment from "moment";
import { useCallback } from "react";
import { HOUR_TYPE } from "../use_time_list/use_time_list";

interface ITimeController {
  time: string;
}

interface IAddTime {
  startTime: string;
  startBreakTime: string;
  endBreakTime: string;
  workHour: number;
}

function useTimeControl() {
  const timeController = useCallback(({ time }: ITimeController) => {
    const pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/; // 시:분 형식의 정규표현식
    if (!pattern.test(time)) {
      return "00:00"; // 시간 형식이 아닌 경우
    }
    // const [hour, minute] = time.split(":");
    // const parsedMinute = moment(
    //   Math.floor(parseInt(minute) / 10) * 10,
    //   "m"
    // ).format("mm");
    return time;
  }, []);

  const makeDuration = useCallback(
    (
      startTime: string,
      endTime: string,
      startBreakTime: string,
      endBreakTime: string
    ) => {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");

      const startBreak = moment(startBreakTime, "HH:mm");
      const endBreak = moment(endBreakTime, "HH:mm");

      let workDuration = moment.duration(end.diff(start));

      if (end.isBefore(start)) {
        workDuration = workDuration.add(24, "hours");
      }
      let netDuration = workDuration;

      // 휴식시간이 출퇴근시간 안에 포함되는지 비교
      if (start.isBefore(startBreak) && end.isAfter(endBreak)) {
        const breakDuration = moment.duration(endBreak.diff(startBreak));
        netDuration = moment.duration(workDuration.subtract(breakDuration));
      }

      return netDuration;
    },
    []
  );

  const makeTotalHourForWeek = useCallback(
    (
      startTime: string,
      endTime: string,
      startBreakTime: string,
      endBreakTime: string
    ) => {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");

      const startBreak = moment(startBreakTime, "HH:mm");
      const endBreak = moment(endBreakTime, "HH:mm");

      let workDuration = moment.duration(end.diff(start));

      if (end.isBefore(start)) {
        workDuration = workDuration.add(24, "hours");
      }
      let netDuration = workDuration;

      // 휴식시간이 출퇴근시간 안에 포함되는지 비교
      if (start.isBefore(startBreak) && end.isAfter(endBreak)) {
        const breakDuration = moment.duration(endBreak.diff(startBreak));
        netDuration = moment.duration(workDuration.subtract(breakDuration));
      }

      const hours = Math.floor(netDuration.asHours());
      const minutes = netDuration.minutes();
      return `${hours}시간 ${minutes}분`;
    },
    []
  );

  const handleWorkHour = useCallback(
    (
      startTime: string,
      endTime: string,
      startBreakTime: string,
      endBreakTime: string
    ) => {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");

      const startBreak = moment(startBreakTime, "HH:mm");
      const endBreak = moment(endBreakTime, "HH:mm");

      let workDuration = moment.duration(end.diff(start));

      if (end.isBefore(start)) {
        workDuration = workDuration.add(24, "hours");
      }
      let netDuration = workDuration;

      // 휴식시간이 출퇴근시간 안에 포함되는지 비교
      if (start.isBefore(startBreak) && end.isAfter(endBreak)) {
        const breakDuration = moment.duration(endBreak.diff(startBreak));
        netDuration = moment.duration(workDuration.subtract(breakDuration));
      }

      return Math.floor(netDuration.asHours());
    },
    []
  );

  const addTime = useCallback(
    ({ startTime, startBreakTime, endBreakTime, workHour }: IAddTime) => {
      const start = moment(startBreakTime, "HH:mm");
      const end = moment(endBreakTime, "HH:mm");

      const originTime = moment(startTime, "HH:mm");
      const addedTime = originTime.add(workHour, "hours");

      // 휴게시간이 포함되는지 확인하기 위한 휴게시간의 중간시간 구하기
      const startBreak = moment(startBreakTime, "HH:mm");
      const endBreak = moment(endBreakTime, "HH:mm");

      const diff = moment.duration(endBreak.diff(startBreak));
      const halfDiff = moment.duration(diff.asMilliseconds() / 2);
      const halfBreakTime = startBreak.add(halfDiff);

      // 휴게시간 포함시 휴게시간만큼 근무시간을 추가하기
      if (halfBreakTime.isBetween(start, addedTime)) {
        const breakTime = Math.abs(end.diff(start, "hours"));
        addedTime.add(breakTime, "hours");
      }
      return addedTime.format("HH:mm");
    },
    []
  );

  const displayHours = useCallback((hours: string) => {
    const parsedHours = parseInt(hours);
    if (parsedHours > 12) {
      return parsedHours - 12;
    }
    if (parsedHours === 0) {
      return 12;
    }
    return parsedHours;
  }, []);

  const makeHoursType = useCallback(
    ({ originH, newType }: { originH: string; newType: HOUR_TYPE }): string => {
      const hours = originH.split(":")[0];
      const minute = originH.split(":")[1];
      if (newType === HOUR_TYPE.AM) {
        const parsedHours =
          parseInt(hours) < 12
            ? String(hours).padStart(2, "0")
            : String(parseInt(hours) - 12).padStart(2, "0");
        return `${parsedHours}:${minute}`;
      } else {
        const parsedHours = parseInt(hours) + 12;
        return `${parsedHours}:${minute}`;
      }
    },
    []
  );

  const makeHours = useCallback(
    ({ originH, newH }: { originH: string; newH: string }): string => {
      const hours = parseInt(originH.split(":")[0]);
      const newHours = parseInt(newH);
      let parsedHours = String(newHours).padStart(2, "0");
      if (hours >= 12) {
        if (newHours !== 12) {
          parsedHours = String(newHours + 12);
        }
      } else {
        if (newHours === 12) {
          parsedHours = "00";
        }
      }
      return parsedHours;
    },
    []
  );

  const handleMinute = useCallback((value: string) => {
    let minute = value;
    if (value.length < 2) {
      minute = value.padStart(2, "0");
    } else if (minute.length > 2) {
      minute = value.slice(1);
    }
    return minute;
  }, []);

  return {
    timeController,
    handleWorkHour,
    addTime,
    displayHours,
    makeHoursType,
    makeHours,
    makeDuration,
    makeTotalHourForWeek,
    handleMinute
  };
}

export default useTimeControl;
