import styled from "styled-components";
import AsonicTable from "../../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IAsonicRow } from "../../../asonic-table/asonic-render-row";
import {
  Allowed_Flag_Type,
  MainTokenInfoEntity,
  useDeleteMainTokenInfoMutation,
  useGetMainTokenInfoLazyQuery,
  useUpdateMainTokenAllowFlagMutation
} from "../../../../generated/graphql";
import { S_A_PERMISSION_SEARCH_TYPE } from "./super_admin_permission_setting";
import { Cell } from "../../../../../types/@react-table/react-table/react-table";
import { Icon } from "@iconify/react";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";

const COLUMN_FOR_VALUE = {
  tokenField: "토큰",
  allowFlag: "공용 설정",
  deleteButton: "삭제"
} as const;

type COLUMN_FOR_VALUE =
  (typeof COLUMN_FOR_VALUE)[keyof typeof COLUMN_FOR_VALUE];

interface IMainTokenInfoEntity extends MainTokenInfoEntity {
  deleteButton?: "";
}

interface IProps {
  searchType: S_A_PERMISSION_SEARCH_TYPE;
  searchValue: string;
  setSelectedMainTokenField: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Input = styled.input``;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
    color: ${props => props.theme.colors.tomato};
    :hover {
      color: ${props => props.theme.colors.darkRed};
    }
    :active {
      transform: scale(0.9);
    }
  }
`;

function MainTokenTable({
  searchType,
  searchValue,
  setSelectedMainTokenField
}: IProps) {
  const [getMainTokenInfo, { data, loading }] = useGetMainTokenInfoLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getMainTokenInfo.ok && data.getMainTokenInfo.info) {
      }
    }
  });

  const [updateMainTokenAllowFlag, { client }] =
    useUpdateMainTokenAllowFlagMutation({
      onError(error) {
        console.log(error);
        handleToast(
          "성공적으로 토큰의 공용 설정을 수정 하였습니다.",
          MessageTypes.SUCCESS
        );
      },
      update(_, { data }) {
        if (data?.updateMainTokenAllowFlag.ok) {
          client.resetStore();
          handleToast(
            "성공적으로 토큰의 공용 설정을 수정 하였습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.updateMainTokenAllowFlag.error) {
          handleToast(data?.updateMainTokenAllowFlag.error, MessageTypes.ERROR);
        }
      }
    });

  const [deleteMainToken] = useDeleteMainTokenInfoMutation({
    onError(error) {
      console.log(error);
    },
    update(_, { data }) {
      if (data?.deleteMainTokenInfo.ok) {
        client.resetStore();
        handleToast("성공적으로 토큰을 삭제 하였습니다.", MessageTypes.SUCCESS);
      } else if (data?.deleteMainTokenInfo.error) {
        handleToast(data?.deleteMainTokenInfo.error, MessageTypes.ERROR);
      }
      handleIsOpen(false);
    }
  });

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const columns: ReactTable.Column<IMainTokenInfoEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const newColumn = listOfColumn.map(item => {
      return {
        Header: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE],
        accessor: item as keyof typeof COLUMN_FOR_VALUE,
        Cell(cell: Cell<IMainTokenInfoEntity>) {
          switch (cell.column.Header) {
            case "토큰":
              return cell.value.slice(0, 8);
            case "공용 설정":
              return (
                <Input
                  type="checkbox"
                  onChange={event => {
                    event.stopPropagation();
                    updateMainTokenAllowFlag({
                      variables: {
                        tokenField: cell.row.original.tokenField,
                        allowedFlag: event.currentTarget.checked
                          ? Allowed_Flag_Type.Allow
                          : Allowed_Flag_Type.NotAllow
                      }
                    });
                  }}
                  checked={cell.value === Allowed_Flag_Type.Allow}
                />
              );
            default:
              return (
                <IconContainer
                  onClick={event => {
                    handleConfirmMessage({
                      title: "토큰 정보 삭제",
                      p: "선택된 토큰 정보를 삭제 하시겠습니까?",
                      messageTypes: MessageTypes.WARNING
                    });
                    handleIsOpen(true);
                  }}
                >
                  <Icon icon={DeleteIcon} />
                </IconContainer>
              );
          }
        }
      };
    });
    return newColumn;
  }, []);

  const list = useMemo(() => {
    return data?.getMainTokenInfo.info || [];
  }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<IMainTokenInfoEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<IMainTokenInfoEntity>) => {},
    []
  );

  const selectedRow: ReactTable.Row<IMainTokenInfoEntity> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return;
    }, [selectedFlatRows]);

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      deleteMainToken({
        variables: {
          tokenField: selectedRow.original.tokenField
        }
      });
    }
  }, [deleteMainToken, selectedRow]);

  useEffect(() => {
    setSelectedMainTokenField(selectedRow?.original.tokenField);
  }, [selectedRow]);

  useEffect(() => {
    if (searchType === S_A_PERMISSION_SEARCH_TYPE.TOKEN) {
      getMainTokenInfo({
        variables: {
          tokenField: searchValue
        }
      });
    } else if (searchType === S_A_PERMISSION_SEARCH_TYPE.USER_ID) {
      getMainTokenInfo({
        variables: {
          employeeId: searchValue
        }
      });
    }
  }, [getMainTokenInfo, searchType, searchValue]);

  return (
    <Container>
      <AsonicTable<IMainTokenInfoEntity>
        title={"메인 토큰"}
        isTitleBar={false}
        handleSelectRow={handleSelectRow}
        isLoading={loading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
      />
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default memo(MainTokenTable, (pre, next) => {
  if (pre === next) {
    return true;
  }
  return false;
});
