import { useCallback, useState } from "react";
import styled from "styled-components";
import ReservationDetailInformation from "../components/reservation-detail-information/reservation-detail-information";
import ReservationInformation from "../components/reservation-information/reservation-information";
import { GetListOfReservationInformation_getListOfReservationInformation_list } from "../__generated__/GetListOfReservationInformation";
import * as ReactTable from "react-table";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  padding: 10px;
  :first-child {
    border-bottom: 2px solid ${props => props.theme.colors.black};
  }
`;

function ReservationManagementScreen() {
  const [selectedReservationInfo, setSelectedReservationInfo] = useState<
    | ReactTable.Row<GetListOfReservationInformation_getListOfReservationInformation_list>
    | undefined
  >();

  const handleSelectedReservationInfo = useCallback(
    (
      row:
        | ReactTable.Row<GetListOfReservationInformation_getListOfReservationInformation_list>
        | undefined
    ) => {
      setSelectedReservationInfo(row);
    },
    []
  );

  return (
    <Container>
      <Section>
        <ReservationInformation
          handleSelectedReservationInfo={handleSelectedReservationInfo}
        />
      </Section>
      <Section>
        <ReservationDetailInformation
          selectedReservationInfo={selectedReservationInfo}
        />
      </Section>
    </Container>
  );
}

export default ReservationManagementScreen;
