import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f5f5;
  flex: 1;
  padding: 20px;
  overflow: auto;
`;

export const CalendarContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #e4e9ee;
`;

export const MonthTitleBarContainer = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: 4fr 1fr 4fr;
`;
