import styled from "styled-components";

const SSelect = styled.select<{ minWidth?: string; maxWidth?: string }>`
  display: flex;
  flex: 1;
  min-width: ${props => props.minWidth ?? ""};
  max-width: ${props => props.maxWidth ?? ""};
`;

export default SSelect;
