import * as React from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { approvalActionCreator } from "../../../redux/modules/main-view/approval/approvalReducer";
import MobileApprovalDialogView from "../../../moComponents/main-view/smallComponents/MobileApprovalDialogView";
import { ApprovalReducer, Reducers } from "../../../../types/reducers";

interface IProps {}

const MobileApprovalDialogContainer = <P extends IProps>(props: P) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      ...approvalActionCreator
    },
    dispatch
  );
  const approvalReducer: ApprovalReducer = useSelector(
    (state: Reducers) => state.approvalReducer
  );

  return (
    <MobileApprovalDialogView
      {...props}
      approvalReducer={approvalReducer}
      {...actions}
    />
  );
};

export default MobileApprovalDialogContainer;
