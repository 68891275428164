import AsonicTable from "../../../asonic-table/asonic-table";
import MainContainer from "../../../shared/main_container/main_container";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo } from "react";
import { IAsonicRow } from "../../../asonic-table/asonic-render-row";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import styled from "styled-components";
import {
  Day_Type,
  Is_Use,
  Operating_Period_Type,
  OtSelfWorkingStandardHoursEntity,
  Work_Day_Of_Week,
  Work_Hours_Setting_Type
} from "../../../../generated/graphql";
import { Cell } from "../../../../../types/@react-table/react-table/react-table";
import moment from "moment";

interface IProps {
  otWSHoursList: OtSelfWorkingStandardHoursEntity[];
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<
      ReactTable.Row<OtSelfWorkingStandardHoursEntity> | undefined
    >
  >;
}

const TableContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 200px;
`;

const title = "근무시간 템플릿";

function ChoiceBasicTemplate({ otWSHoursList, setSelectedTemplate }: IProps) {
  const handleDays = useCallback((dayList: Day_Type[]) => {
    const parsedDayList = dayList.map(item => {
      switch (item) {
        case Day_Type.Mon:
          return "월";
        case Day_Type.Tue:
          return "화";
        case Day_Type.Wed:
          return "수";
        case Day_Type.Thr:
          return "목";
        case Day_Type.Fri:
          return "금";
        case Day_Type.Sat:
          return "토";
        case Day_Type.Sun:
          return "일";
      }
    });
    return parsedDayList.join(",");
  }, []);

  const columns: ReactTable.Column<OtSelfWorkingStandardHoursEntity>[] =
    useMemo(() => {
      const width = 100;
      return [
        {
          Header: "근무시간 템플릿 명",
          accessor: "workingTimeName"
        },
        {
          Header: "운영기간",
          accessor: "operatingPeriodStartDate",
          width: 200,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            let operatingPeriodDate = `${cell.row.original.operatingPeriodStartDate} ~ ${cell.row.original.operatingPeriodEndDate}`;
            if (
              cell.row.original.operatingPeriodType ===
              Operating_Period_Type.Always
            ) {
              operatingPeriodDate = `상시적용`;
            }
            return <span>{operatingPeriodDate}</span>;
          }
        },
        {
          Header: "근무요일",
          accessor: "dayOfWeek",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            if (
              cell.row.original.dayOfWeekType === Work_Day_Of_Week.SelfForWorker
            ) {
              return "근무자 자율편성";
            }
            return <span>{handleDays(cell.row.original.dayOfWeek)}</span>;
          }
        },
        {
          Header: "근무시간",
          accessor: "startTime",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            if (
              cell.row.original.workHoursType ===
              Work_Hours_Setting_Type.SelfForWorker
            ) {
              return "근무자 자율편성";
            }
            const startTime = moment(
              cell.row.original.startTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const endTime = moment(
              cell.row.original.endTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const time = startTime && endTime ? `${startTime}~${endTime}` : "";
            return <span>{time}</span>;
          }
        },
        {
          Header: "의무근무시간",
          accessor: "dutyStartTime",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            if (cell.row.original.dutyWorkHoursType === Is_Use.UnUse) {
              return "-";
            }
            const dutyStartTime = moment(
              cell.row.original.dutyStartTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const dutyEndTime = moment(
              cell.row.original.dutyEndTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const time =
              dutyStartTime && dutyEndTime
                ? `${dutyStartTime}~${dutyEndTime}`
                : "없음";
            return <span>{time}</span>;
          }
        },
        {
          Header: "휴게시간",
          accessor: "startBreakTime",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            const startBreakTime = moment(
              cell.row.original.startBreakTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const endBreakTime = moment(
              cell.row.original.endBreakTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const breakTime =
              startBreakTime && endBreakTime
                ? `${startBreakTime}~${endBreakTime}`
                : "";
            return <span>{breakTime}</span>;
          }
        }
      ];
    }, []);

  const list: OtSelfWorkingStandardHoursEntity[] = useMemo(() => {
    return otWSHoursList ?? [];
  }, [otWSHoursList]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: choiceColumns
  } = ReactTable.useTable<OtSelfWorkingStandardHoursEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useColumnOrder,
    ReactTable.useRowSelect
  );

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<OtSelfWorkingStandardHoursEntity>) => {},
    []
  );

  const selectedRow:
    | ReactTable.Row<OtSelfWorkingStandardHoursEntity>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  useEffect(() => {
    if (selectedRow) {
      setSelectedTemplate(selectedRow);
    }
  }, [setSelectedTemplate, selectedRow]);

  return (
    <MainContainer>
      <TableContainer>
        <AsonicTable<OtSelfWorkingStandardHoursEntity>
          title={title}
          handleSelectRow={handleSelectRow}
          total={list.length}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          columns={choiceColumns as TColumn<OtSelfWorkingStandardHoursEntity>[]}
          listOfFlexForHeader={["근무시간 템플릿 명", "운영기간", "근무요일"]}
          isLastFlex={false}
        />
      </TableContainer>
    </MainContainer>
  );
}

export default ChoiceBasicTemplate;
