import * as React from "react";
import { AxiosResponse } from "axios";
import { Params } from "../../../api/master-work-management-page-api";
import { colors } from "../../../components/GlobalStyle/GlobalStyle";

type Props = {
  title: string;
  callData: (params: Params) => Promise<AxiosResponse<any>>;
  color: string;
  handlePerio: (value: string) => void;
  isRenewal: boolean;
};

type Datasets = {
  backgroundColor: string;
  borderColor: string;
  data: number[];
  label?: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  hoverBackgroundColor: string;
};

export interface Data {
  labels: string[];
  datasets: Datasets[];
}

const usePersonalStatusChart = <P extends Props>({
  title,
  callData,
  color,
  handlePerio,
  isRenewal
}: P) => {
  const isMount = React.useRef<boolean>(false);
  const [data, setData] = React.useState<number[]>([]);
  const [labels, setlabels] = React.useState<string[]>([]);

  const datasets: Datasets[] = React.useMemo(() => {
    const dataSet: Datasets = {
      backgroundColor: color,
      borderColor: color,
      data,
      label: title,
      barThickness: 24,
      maxBarThickness: 24,
      minBarLength: 2,
      hoverBackgroundColor: colors.purpleBlue
    };
    return [dataSet];
  }, [title, data, color]);

  const chartData = React.useMemo(() => {
    const chartData = {
      labels,
      datasets
    };
    return chartData;
  }, [labels, datasets]);

  const makeData = React.useCallback(
    (data: string[]) => {
      const newData: number[] = [];
      data.forEach(item => {
        if (typeof item === "number") {
          newData.push(item);
        } else {
          handlePerio(item);
        }
      });
      return newData;
    },
    [handlePerio]
  );

  const makeLabels = React.useCallback((labels: string[]) => {
    const newLabels: string[] = [];
    labels.forEach((item, index) => {
      let label = ``;
      if (index !== 0) {
        const [newItem] = item.split("h");
        if (newItem.length > 2) {
          label = `${newItem.slice(0, 2)}~${newItem.slice(2, 4)}시간`;
        } else {
          if (index === 1) {
            label = `${newItem}시간초과`;
          } else {
            label = `${newItem}시간미만`;
          }
        }
        newLabels.push(label);
      }
    });
    return newLabels;
  }, []);

  const getData = React.useCallback(async () => {
    try {
      if (isRenewal) {
        const params = {};
        const { data } = await callData(params);
        if (data && data.length > 0 && isMount.current) {
          setData(makeData(Object.values(data[0])));
          setlabels(makeLabels(Object.keys(data[0])));
        } else {
          throw new Error("데이터가 존재하지 않습니다");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [callData, makeData, makeLabels, isRenewal]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    getData();
    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [getData]);

  React.useEffect(() => {
    let timeId: any;
    if (isMount.current) {
      timeId = setInterval(getData, 300000);
    }
    return () => {
      clearInterval(timeId);
    };
  }, [getData]);

  return { chartData };
};

export default usePersonalStatusChart;
