import * as React from "react";
import settingsApi from "../../api/settings-api";
import { MessageTypes } from "../../components/toast-message/toast-message";

interface IProps {
  handleMessage: (newMessage: string) => void;
  handleIsToastMessageOpen: (value: boolean) => void;
  handleToastMessageType: (value: MessageTypes) => void;
  selectedEmployeeId: string;
}

const useInitPassword = <P extends IProps>({
  handleMessage,
  handleIsToastMessageOpen,
  handleToastMessageType,
  selectedEmployeeId
}: P) => {
  const handleInitPassword = React.useCallback(async () => {
    try {
      await settingsApi.initPassword({ selectedEmployeeId });
      handleToastMessageType(MessageTypes.SUCCESS);
      handleMessage("패스워드가 초기화 되었습니다.");
    } catch (error) {
      console.log(error);
      handleToastMessageType(MessageTypes.ERROR);
      handleMessage("패스워드 초기화가 실패되었습니다.");
    } finally {
      handleIsToastMessageOpen(true);
    }
  }, [
    handleMessage,
    handleIsToastMessageOpen,
    handleToastMessageType,
    selectedEmployeeId
  ]);
  return { handleInitPassword };
};

export default useInitPassword;
