import styled from "styled-components";
import { Icon } from "@iconify/react";
import ExcelIcon from "@iconify/icons-fa-solid/file-excel";

export interface IAsonicExcelProps {
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.colors.green};
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  color: ${props => props.theme.colors.green};
  align-items: center;
  :hover {
    border: 2px solid ${props => props.theme.colors.lightGreen};
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.94);
    border: 2px solid ${props => props.theme.colors.navy};
    color: ${props => props.theme.colors.navy};
  }
`;

function AsonicExcel({ downloadExcel }: IAsonicExcelProps) {
  return (
    <Container onClick={downloadExcel}>
      <Icon icon={ExcelIcon} />
    </Container>
  );
}

export default AsonicExcel;
