import styled from "styled-components";
import InfoIcon from "../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

const Emphasis = styled.span`
  font-weight: bold;
`;

function InfoLessThanAYearHelp() {
  return (
    <InfoIcon
      minHeight="590px"
      height="590px"
      minWidth="400px"
      title="만 1년 미만(입사년도) 연차 설정"
    >
      <HelpContainer>
        <span>{`근속 만 1년 미 근무자에 대한 연차를 설정입니다.`}</span>
        <ul>
          <li>{`연차 발생방식 설정`}</li>
          <ul>
            <li>
              <Emphasis>월별 발생</Emphasis>
            </li>
            <span>{`설정된 월별 발생 기준시점 마다 1일씩 시스템이 자동 부여 합니다.`}</span>
            <li>
              <Emphasis>고정일 부여</Emphasis>
            </li>
            <span>{`관리자가 입력한 고정일이 연차 기본일로 부여 됩니다.`}</span>
          </ul>
          <li>{`월별 발생 기준시점`}</li>
          <span>{`연차 발생 방식이 '월별발생'일 때만 적용됩니다.`}</span>
          <ul>
            <li>{`입사 당월`}</li>
            <span>{`입사월 기준으로 차기 월 부터 매월 1 일씩 발생`}</span>
            <li>{`입사 기준일`}</li>
            <span>{`입사 다음월 기준으로 차기 월 부터 매월 1일씩 발생`}</span>
            <li>{`입사일 기준`}</li>
            <span>{`입사일을 기준으로 1개월 단위로 만근시 마다 1일씩 발생`}</span>
          </ul>
          <li>{`고정일 부여 일자`}</li>
          <span>{`연차 발생 방식이'고정일 부여'일 때만 적용됩니다.`}</span>
          <li>{`마이너스 연차 사용 설정`}</li>
          <span>{`당해 입사자에 대해 마이너스 연차 사용 방식을 설정합니다.`}</span>
          <ul>
            <li>{`제한 없음`}</li>
            <span>{`일수 제한 없이 마이너스 연차를 사용 가능 하게 합니다.`}</span>
            <li>{`기본일수 + 마이너스연차 허용 일수로 제한`}</li>
            <pre>{`대상자가 부여 받은 기본일수와 관리자가 입력한"마이너스연차 허용
일수"의 합 만큼 만 사용 가능합니다.`}</pre>
            <li>{`마이너스 연차 허용 일수로 제한`}</li>
            <span>{`관리자가 입력한'마이너스연차 허용일수'의 만큼 사용 가능합니다.`}</span>
            <li>{`미사용`}</li>
            <span>{`당해 입사자에 대해 마이너스 연차 사용을 불허 합니다.`}</span>
          </ul>
          <li>{`마이너스 연차 허용일`}</li>
          <span>{`마이너스 연차 허용일을 입력합니다.`}</span>
          <pre>{`마이너스 연차 사용 설정이 제한 없음이나, 미사용인 경우 적용되지 않습
니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default InfoLessThanAYearHelp;
