import * as React from "react";
import useMyWorkStatusBox from "../../../../hooks/personal-work-dashboard-page-hooks/use-my-work-status-box";
import {
  Container,
  TitleBar,
  Title,
  WorkingPolicyContainer,
  WorkingPolicyTitle,
  WorkingPolicyContent,
  JobGroupContainer,
  JobGroupTitle,
  JobGroupContent,
  DepartmentContainer,
  DepartmentTitle,
  DepartmentContent,
  WorkingStatusContainer,
  WorkingStatusTitle,
  WorkingStatusContent,
  Divider
} from "./styled";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";

const MyWorkStatusBox = () => {
  const { employee_id: userId } = useSelector((state: Reducers) => {
    return state.signInReducer;
  });
  const {
    workingPolicy,
    jobGroup,
    department,
    workingStatus
  } = useMyWorkStatusBox({ userId });
  return (
    <Container data-testid="my-work-status-box">
      <TitleBar>
        <Title data-testid="title">나의 근무현황</Title>
      </TitleBar>
      <Divider />
      <WorkingPolicyContainer>
        <WorkingPolicyTitle data-testid="working-policy-title">
          근무정책
        </WorkingPolicyTitle>
        <WorkingPolicyContent data-testid="working-policy-content">
          {workingPolicy}
        </WorkingPolicyContent>
      </WorkingPolicyContainer>
      <Divider />
      <JobGroupContainer>
        <JobGroupTitle data-testid="job-group-title">직군</JobGroupTitle>
        <JobGroupContent data-testid="job-group-content">
          {jobGroup}
        </JobGroupContent>
      </JobGroupContainer>
      <Divider />
      <DepartmentContainer>
        <DepartmentTitle data-testid="department-title">부서</DepartmentTitle>
        <DepartmentContent data-testid="department-content">
          {department}
        </DepartmentContent>
      </DepartmentContainer>
      <Divider />
      <WorkingStatusContainer>
        <WorkingStatusTitle data-testid="working-status-title">
          근무상태
        </WorkingStatusTitle>
        <WorkingStatusContent data-testid="working-status-content">
          {workingStatus}
        </WorkingStatusContent>
      </WorkingStatusContainer>
    </Container>
  );
};

export default MyWorkStatusBox;
