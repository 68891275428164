import { useCallback, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  ApprovalLineControlType,
  ApprovalLineUserEntity,
  Approval_User_Type,
  DeleteApprovalLineMutationVariables,
  EditApprovalLineMutationVariables,
  Is_Default_Approval_Line,
  useDeleteApprovalLineMutation,
  useEditApprovalLineMutation,
  useGetListOfSearchedApprovalLineUserLazyQuery,
  UserOfSearchedApprovalLineEntity
} from "../../../generated/graphql";
import useConfirmDialog from "../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import useOpenDialog from "../../../hooks/use-open-dialog";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";
import Button from "../../globalComponents/Button";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import EditApprovalLineNameDialog from "../approval-process/edit-approval-line-name-dialog";
import ReactLoading from "react-loading";
import { colors } from "../../GlobalStyle/GlobalStyle";
import CompactTable from "../../compact-table/compact-table";
import * as ReactTable from "react-table";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import ApprovalLineContext from "./context/approval-line-context";
import { IEmployeeInformation } from "../approval-process/list-of-selected-approval-user";
import { TOKEN } from "../../../apollo/apollo";
import { handleDecodedToken } from "../../../Utils/tokenMaker";
import { useLocation } from "react-router-dom";
import routes from "../../../routes";

interface IUserOfSearchedApprovalLineEntity
  extends UserOfSearchedApprovalLineEntity {
  department?: string;
  name?: string;
  userPosition?: string;
}

const COLUMN_FOR_VALUE = {
  approvalOrder: "결재순서",
  approvalType: "구분",
  department: "부서",
  name: "결재자정보",
  userPosition: "직책"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const ListContainer = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

function ListOfPersonalApprovalLineUser() {
  const location = useLocation();

  const store = useContext(ApprovalLineContext);

  const employeeId: string | undefined = useMemo(() => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData?.employee_id;
    }
    return;
  }, []);

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();
  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();
  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isConfirmDialogOpen,
    handleIsOpen: handleIsConfirmDialogOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const [getListOfSearchedApprovalLineUser, { data, loading }] =
    useGetListOfSearchedApprovalLineUserLazyQuery();

  const [editApprovalLine, { client }] = useEditApprovalLineMutation({
    onError(error) {
      handleToast(
        "선택된 결재선 이름을 변경하지 못했습니다.",
        MessageTypes.ERROR
      );
      handleOpenDialog(false);
    },
    update(_, { data }, { variables }) {
      if (data?.editApprovalLine.ok && variables) {
        client.resetStore();
        handleToast(
          `선택된 결재선의 이름을 ${variables.approvalLineName}으로 변경하였습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (data?.editApprovalLine.error) {
        handleToast(data?.editApprovalLine.error, MessageTypes.ERROR);
      }
      handleOpenDialog(false);
    }
  });

  const handleEditApprovalLine = useCallback(
    (form: {
      title: string;
      isDefaultApprovalLine: Is_Default_Approval_Line;
    }) => {
      if (
        store?.selectedApprovalLine &&
        data?.getListOfSearchedApprovalLineUser.ok &&
        data?.getListOfSearchedApprovalLineUser.list &&
        employeeId
      ) {
        const { list } = data.getListOfSearchedApprovalLineUser;

        const newList: ApprovalLineUserEntity[] = list?.map(item => {
          return {
            employeeid: item.employeeId,
            order: item.approvalOrder,
            type: item.approvalType
          };
        });
        const title = `${form.title}`;
        const payload: EditApprovalLineMutationVariables = {
          approvalLineControlType: ApprovalLineControlType.Edit,
          user: employeeId,
          approvalLineName: title,
          isDefaultApprovalLine:
            form.isDefaultApprovalLine ?? store?.selectedApprovalLine.basicLine,
          count: newList.length,
          list: newList,
          lineIdx: store?.selectedApprovalLine.lineIdx
        };

        editApprovalLine({ variables: payload });
      }
    },
    [editApprovalLine, store?.selectedApprovalLine, data, employeeId]
  );

  const [deleteApprovalLine] = useDeleteApprovalLineMutation({
    onError(error) {
      console.log(error);
      handleToast("선택된 결재선을 삭제하지 못했습니다.", MessageTypes.ERROR);
    },
    update(_, { data }, { variables }) {
      if (data?.deleteApprovalLine.ok && variables) {
        client.resetStore();
        handleToast("선택된 결재선을 삭제했습니다.", MessageTypes.SUCCESS);
      } else if (data?.deleteApprovalLine.error) {
        handleToast(data?.deleteApprovalLine.error, MessageTypes.ERROR);
      }
    }
  });

  const handleDeleteApprovalLine = useCallback(() => {
    if (store?.selectedApprovalLine && employeeId) {
      const payload: DeleteApprovalLineMutationVariables = {
        lineIdx: store?.selectedApprovalLine.lineIdx,
        user: employeeId
      };
      deleteApprovalLine({
        variables: payload
      });
      handleIsConfirmDialogOpen(false);
    }
  }, [
    deleteApprovalLine,
    store?.selectedApprovalLine,
    employeeId,
    handleIsConfirmDialogOpen
  ]);

  const columns: ReactTable.Column<IUserOfSearchedApprovalLineEntity>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      const newListOfColumn = listOfColumn.map(item => {
        let width = 100;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
          COLUMN_FOR_VALUE.approvalOrder
        ) {
          width = 60;
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE],
          accessor: item as TYPE_OF_EMPLOYEE,
          width,
          Cell(cell: Cell<IUserOfSearchedApprovalLineEntity>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.approvalType
            ) {
              if (cell.value === Approval_User_Type.Agreement) {
                return "합의";
              } else if (cell.value === Approval_User_Type.Approval) {
                return "결재";
              }
            }
            return cell.value || "";
          }
        };
      });

      return newListOfColumn;
    }, []);

  const userData = useMemo(() => {
    let newData: IUserOfSearchedApprovalLineEntity[] = [];
    if (data?.getListOfSearchedApprovalLineUser.list) {
      newData = data?.getListOfSearchedApprovalLineUser.list.map(item => {
        let name = "";
        let userPosition = "";
        let department = "";
        if (item.approvalInfo) {
          const approvalInfo = item.approvalInfo?.split("/");
          name = approvalInfo[0];
          userPosition = approvalInfo[1];
          department = approvalInfo[2];
        }

        return { name, userPosition, department, ...item };
      });
    }
    return newData.reverse();
  }, [data]);

  const { prepareRow, getTableProps, headerGroups, getTableBodyProps, rows } =
    ReactTable.useTable<IUserOfSearchedApprovalLineEntity>(
      {
        columns,
        data: userData
      },
      ReactTable.useBlockLayout,
      ReactTable.useRowSelect,
      ReactTable.useColumnOrder
    );

  const handleLoad = useCallback(() => {
    if (store?.handleLoadListOfPersonalApprovalLine && userData) {
      const newList: IEmployeeInformation[] = userData.map(item => {
        return {
          employeeId: item.employeeId,
          name: item.name ?? "",
          userPosition: item.userPosition ?? "",
          department: item.department ?? "",
          approvalType: item.approvalType,
          checked: false
        };
      });
      store?.handleLoadListOfPersonalApprovalLine(newList);
    }
  }, [store?.handleLoadListOfPersonalApprovalLine, userData]);

  useEffect(() => {
    if (store?.selectedApprovalLine && employeeId) {
      getListOfSearchedApprovalLineUser({
        variables: {
          employeeId,
          lineIdx: store?.selectedApprovalLine.lineIdx
        }
      });
    }
  }, [
    getListOfSearchedApprovalLineUser,
    store?.selectedApprovalLine,
    employeeId
  ]);

  return (
    <Container>
      <ListContainer>
        {loading ? (
          <ReactLoading
            type="spokes"
            color={colors.green}
            height={100}
            width={50}
          />
        ) : (
          <CompactTable
            title="개인결재선 결재자 목록"
            isLoading={false}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            isTitleBar={false}
          />
        )}
      </ListContainer>
      <ButtonContainer>
        {location.pathname === routes.pageRoutes.approvalSetting && (
          <Button
            disabled={!store?.selectedApprovalLine}
            onClick={() => {
              handleOpenDialog(true);
            }}
          >
            {`이름변경`}
          </Button>
        )}

        <Button
          disabled={!store?.selectedApprovalLine}
          onClick={handleLoad}
        >{`불러오기`}</Button>
        {location.pathname === routes.pageRoutes.approvalSetting && (
          <Button
            disabled={!store?.selectedApprovalLine}
            onClick={() => {
              handleConfirmMessage({
                title: "결재선 삭제",
                p: "선택한 개인 결재선을 삭제하시겠습니까?",
                messageTypes: MessageTypes.WARNING
              });
              handleIsConfirmDialogOpen(true);
            }}
          >
            {`삭제`}
          </Button>
        )}
      </ButtonContainer>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.WARNING}
          handleIsOpen={handleIsConfirmDialogOpen}
          handleConfirm={handleDeleteApprovalLine}
        />
      )}
      {isOpenDialog && (
        <AsonicDialog
          title={"개인결재선"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
          width="420px"
          minWidth="420px"
          height="200px"
          minHeight="200px"
        >
          <EditApprovalLineNameDialog
            selectedItem={store?.selectedApprovalLine}
            handleOpenDialog={handleOpenDialog}
            handleEditApprovalLine={handleEditApprovalLine}
          />
        </AsonicDialog>
      )}
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ListOfPersonalApprovalLineUser;
