import { createGlobalStyle, DefaultTheme } from "styled-components";

export const colors = {
  white: "#ffffff",
  darkWhite: "#f8f8f8",
  lightGrey: "#F9F9F9",
  littleLightGrey: "#F1F2F4",
  grey: "#C9C9C9",
  darkGrey: "#7c7c7c",
  frameGrey: "#E9EBEF",
  black: "rgb(27, 25, 25)",
  veryDarkBlue: "#0066B3",
  midNightBlue: "#354254",
  lightMidNightBlue: "#445F76",
  darkMidNightBlue: "#263238",
  lightBlue: "#dae5f7",
  darkBlue: "#0984e3",
  skyBlue: "#74b9ff",
  cobaltBlue: "#B1C2EE",
  darkCobaltBlue: "#8ba7f0",
  purpleBlue: "#3742fa",
  lightSkyBlue: "#F3F6FC",
  navy: "#354254",
  tomato: "#ff7675",
  darkRed: "#eb3b5a",
  lightRed: "#ec5b76",
  green: "#21B5A2",
  transparentGreen: "#20B5A34D",
  auroraGreen: "#78e08f",
  softGreen: "#E1EAEE",
  darkGreen: "#079992",
  lightGreen: "#24CEBA",
  yellow: "#FFFCDE",
  veryYellow: "#fff78a",
  darkYellow: "#ff9f43",
  lemon: "lemonchiffon"
};

export const lightTheme: DefaultTheme = {
  colors,
  bgColor: colors.darkWhite,
  fontColor: colors.black,
  titleBarColor: colors.veryDarkBlue,
  borderColor: colors.darkGrey
};

export const darkTheme: DefaultTheme = {
  colors,
  bgColor: colors.black,
  fontColor: colors.darkWhite,
  titleBarColor: colors.veryDarkBlue,
  borderColor: colors.darkGrey
};

const GlobalStyle = createGlobalStyle`
  input, textarea, select {
    padding: 2px;
    resize: none;
  }
  * {
  font-family: "Helvetica Neue", "Lucida Grande", Helvetica, Arial, sans-serif;
  }
  body {
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
    font-size:12px;
    svg {
      outline: none;
    }
    input[type="radio"], input[type="checkbox"] {
      border: none;      
      margin: 0px 0px 0px 0px;
    }
    input:disabled+label {
      color: #ccc;
    }
    input {
      outline: unset;
      border: 1px solid ${colors.frameGrey};
      padding: 4px;
      border-radius: 4px;
    }
    select:disabled+label {
      color: #ccc;
    }
    select {
      padding-top: 4px;
      padding-bottom: 4px;
      outline: unset;
      border: 1px solid ${colors.frameGrey};
      border-radius: 4px;
    }
  }
`;

export default GlobalStyle;
