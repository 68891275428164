import moment from "moment";
import { useCallback, useMemo } from "react";
import ContentContainer from "../../../shared/content_container/content_container";
import { IDay } from "../../../time_graph/display_month_graph/display_month_graph";
import DisplayWeekGraph from "../../../time_graph/display_week_graph/display_week_graph";
import { COLOR_TYPE } from "../../../time_graph/time_graph_box/time_graph_box";
import {
  OtSelfWorkingStandardHoursEntity,
  SelfDeterminedWeekGraphEntity,
  Work_Hours_Setting_Type,
  Work_Type
} from "../../../../generated/graphql";
import * as ReactTable from "react-table";

interface IProps {
  weekDayOfWeekInfo: SelfDeterminedWeekGraphEntity[];
  selectedTemplate?: ReactTable.Row<OtSelfWorkingStandardHoursEntity>;
  selectedDayName?: string;
  setSelectedDayName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function ChoiceDayGraph({
  weekDayOfWeekInfo,
  selectedTemplate,
  selectedDayName,
  setSelectedDayName
}: IProps) {
  const checkDayAndRtn = useCallback(
    (date: moment.Moment): IDay => {
      let colorType: COLOR_TYPE = COLOR_TYPE.OFF_DUTY_HOURS;
      if (
        selectedTemplate?.original.workHoursType ===
        Work_Hours_Setting_Type.SelfForWorker
      ) {
        colorType = COLOR_TYPE.WORKER_AUTONOMY_SCHEDULE;
      }
      const day = date.locale("ko").format("ddd");
      let selected = false;
      if (selectedDayName) {
        selected = !!(selectedDayName === day);
      }
      let newDay: IDay = {
        title: day,
        min: 0,
        isSat: day === "토",
        isSun: day === "일",
        colorType,
        type: Work_Type.FlexibleSchedulePolicy,
        selected
      };

      weekDayOfWeekInfo.forEach(item => {
        if (
          item.dayOfWeek === date.locale("en").format("ddd").toUpperCase() ||
          (date.locale("en").format("ddd").toUpperCase() === "THU" &&
            item.dayOfWeek === "THR")
        ) {
          colorType = COLOR_TYPE.WORK_DAY;
          newDay = {
            title: day,
            min:
              typeof item.totHour === "number"
                ? 60 * Math.abs(item.totHour)
                : 0,
            isSat: day === "토",
            isSun: day === "일",
            colorType,
            type: Work_Type.FlexibleSchedulePolicy,
            selected
          };
        }
      });
      return newDay;
    },
    [weekDayOfWeekInfo, selectedTemplate, selectedDayName]
  );

  const weeks: IDay[] = useMemo(() => {
    const newWeek: IDay[] = [];
    for (let i = 0; i < 7; i++) {
      const date = moment().day(i);
      const newDay = checkDayAndRtn(date);
      newWeek.push(newDay);
    }
    return newWeek;
  }, [checkDayAndRtn]);

  const labelList = useMemo(() => {
    return [
      {
        labelName: "휴일",
        colorType: COLOR_TYPE.DAY_OFF
      },
      {
        labelName: "근무요일",
        colorType: COLOR_TYPE.WORK_DAY
      },
      {
        labelName: "근무자율편성",
        colorType: COLOR_TYPE.WORKER_AUTONOMY_SCHEDULE
      }
    ];
  }, []);

  return (
    <ContentContainer>
      <DisplayWeekGraph
        weeks={weeks}
        labelList={labelList}
        setSelectedDayName={setSelectedDayName}
      />
    </ContentContainer>
  );
}

export default ChoiceDayGraph;
