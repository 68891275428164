import styled from "styled-components";

export const Title = styled.h3`
  color: white;
  font-family: "Gothic", sans-serif;
`;

export const SignInRootDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gothic", sans-serif;
  height: 100%;
  width: 100%;
  background-image: ${props => props.backgroundImg};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

export const SignInContentsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GitHash = styled.span`
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: #636e72;
`;
