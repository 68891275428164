import { Icon } from "@iconify/react";
import styled from "styled-components";
import ChatDots from "@iconify-icons/akar-icons/chat-dots";
import listOfApolloVar, { CHAT_OPEN } from "../../apollo/apollo-var";

const Container = styled.div`
  display: flex;
  color: ${props => props.theme.colors.lightMidNightBlue};
  cursor: pointer;
  :hover {
    transform: scale(1.4);
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    border: none;
  }
  font-size: 18px;
`;

function ChatButton() {
  return (
    <Container
      onClick={() => {
        listOfApolloVar.chatOpenVar(CHAT_OPEN.OPEN);
      }}
    >
      <Icon icon={ChatDots} data-tip="Chat" />
    </Container>
  );
}

export default ChatButton;
