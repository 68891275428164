import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import {
  GetDashBoard,
  GetDashBoardVariables,
  GetDashBoard_getDashBoard_listOfDashBoard
} from "../../../__generated__/GetDashBoard";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  GetDashBoardTotal,
  GetDashBoardTotalVariables
} from "../../../__generated__/GetDashBoardToTal";
import moment from "moment";
import {
  IS_TRUTHY_FALSY,
  SEARCH_DATE_TYPE_FOR_DASH_BOARD,
  SEARCH_DB_TYPE_FOR_DASH_BOARD
} from "../../../__generated__/globalTypes";
import {
  DashBoardExcel,
  DashBoardExcelVariables
} from "../../../__generated__/DashBoardExcel";
import { downloadFileFromServer } from "../../main-view/statistics/Utils";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import usePageControl from "../../../hooks/use-page-control/use-page-control";
import { IAsonicRow } from "../../asonic-table/asonic-render-row";
import useDnd from "../../../hooks/use-dnd/use-dnd";
import useSortBy from "../../../hooks/use-sort-by/use-sort-by";
import { TColumn } from "../../../hooks/use-hide-columns/use-hide-columns";
import { IFieldSearchByDateWithType } from "../../asonic-table/asonic-table-for-dash-board/asonic-search-by-date-with-type";
import AsonicTableForDashBoard from "../../asonic-table/asonic-table-for-dash-board/asonic-table-for-dash-board";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";

interface IProps {
  handleClose: (value: boolean) => void;
  additionalSearchType?: SEARCH_DB_TYPE_FOR_DASH_BOARD;
  additionalSearchValueForTruthyFalsy?: IS_TRUTHY_FALSY;
  additionalSearchTimeValue?: SEARCH_DATE_TYPE_FOR_DASH_BOARD;
  initSearchDbTypeForDashBoard?: SEARCH_DB_TYPE_FOR_DASH_BOARD;
}

enum COLUMN_FOR_VALUE {
  empName = "성명",
  empId = "아이디",
  departName = "부서명",
  dataPerio = "기간",
  otTotTimeSection = "근로시간구간",
  otTotTime = "근로시간",
  otWorkTimeSection = "정규시간구간",
  otWorkTime = "정규시간",
  otExtTimeSection = "연장시간구간",
  otExtTime = "연장시간",
  workOx = "정규근무",
  nameOne = "연장근무",
  nameTwo = "휴가",
  nameThree = "기타"
}

export type SEARCH_TYPE_FOR_DASH_BOARD_TABLE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10;
`;

const QUERY_GET_DASH_BOARD = gql`
  query GetDashBoard(
    $date: String
    $departmentId: String!
    $employeeId: String!
    $searchType: SEARCH_DB_TYPE_FOR_DASH_BOARD
    $searchValue: String
    $searchValueForTime: SEARCH_DATE_TYPE_FOR_DASH_BOARD
    $searchValueForTruthyFalsy: IS_TRUTHY_FALSY
    $page: Float = 1
    $take: Float = 10
    $fieldSort: FieldSort
  ) {
    getDashBoard(
      date: $date
      departmentId: $departmentId
      employeeId: $employeeId
      searchType: $searchType
      searchValue: $searchValue
      searchValueForTime: $searchValueForTime
      searchValueForTruthyFalsy: $searchValueForTruthyFalsy
      page: $page
      take: $take
      fieldSort: $fieldSort
    ) {
      ok
      error
      listOfDashBoard {
        empName
        empId
        departName
        dataPerio
        otTotTimeSection
        otTotTime
        otWorkTimeSection
        otWorkTime
        otExtTimeSection
        otExtTime
        workOx
        nameOne
        nameTwo
        nameThree
      }
    }
  }
`;

const QUERY_GET_DASH_BOARD_TOTAL = gql`
  query GetDashBoardTotal(
    $employeeId: String!
    $departmentId: String!
    $date: String
    $searchType: SEARCH_DB_TYPE_FOR_DASH_BOARD
    $searchValue: String
    $searchValueForTime: SEARCH_DATE_TYPE_FOR_DASH_BOARD
    $searchValueForTruthyFalsy: IS_TRUTHY_FALSY
  ) {
    getDashBoardTotal(
      employeeId: $employeeId
      departmentId: $departmentId
      date: $date
      searchType: $searchType
      searchValue: $searchValue
      searchValueForTime: $searchValueForTime
      searchValueForTruthyFalsy: $searchValueForTruthyFalsy
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DASH_BOARD_EXCEL = gql`
  query DashBoardExcel(
    $date: String
    $searchType: SEARCH_DB_TYPE_FOR_DASH_BOARD
    $searchValue: String
    $searchValueForTime: SEARCH_DATE_TYPE_FOR_DASH_BOARD
    $searchValueForTruthyFalsy: IS_TRUTHY_FALSY
  ) {
    dashBoardExcel(
      date: $date
      searchType: $searchType
      searchValue: $searchValue
      searchValueForTime: $searchValueForTime
      searchValueForTruthyFalsy: $searchValueForTruthyFalsy
    ) {
      ok
      error
      excel
    }
  }
`;

function SearchDashBoard({
  handleClose,
  additionalSearchType,
  additionalSearchValueForTruthyFalsy,
  additionalSearchTimeValue,
  initSearchDbTypeForDashBoard
}: IProps) {
  const isMounted = useRef(false);

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const {
    signInReducer: { employee_id, department_id }
  } = useSelector((state: Reducers) => state);

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const [searchValueForTruthyFalsy, setSearchValueForTruthyFalsy] =
    useState<IS_TRUTHY_FALSY>(IS_TRUTHY_FALSY.TRUTHY);

  const searchValueForTruthyFalsyForSearch = useRef<IS_TRUTHY_FALSY>(
    searchValueForTruthyFalsy
  );

  const handleSearchValueForTruthyFalsy = useCallback(
    (value: IS_TRUTHY_FALSY) => {
      searchValueForTruthyFalsyForSearch.current = value;
    },
    []
  );
  const { fieldSort, handleFieldSort } = useSortBy();

  const [searchValueForTime, setSearchValueForTime] =
    useState<SEARCH_DATE_TYPE_FOR_DASH_BOARD>(
      SEARCH_DATE_TYPE_FOR_DASH_BOARD.LESS_THAN_34_HOURS
    );
  const searchValueForTimeForSearch =
    useRef<SEARCH_DATE_TYPE_FOR_DASH_BOARD>(searchValueForTime);
  const handleSearchValueForTime = useCallback(
    (value: SEARCH_DATE_TYPE_FOR_DASH_BOARD) => {
      searchValueForTimeForSearch.current = value;
    },
    []
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const searchValueForSearch = useRef<string>(searchValue);
  const handleSearchValue = useCallback((value: string) => {
    searchValueForSearch.current = value;
    setSearchValue(value);
  }, []);
  const searchTypeForSearch = useRef<SEARCH_DB_TYPE_FOR_DASH_BOARD>(
    SEARCH_DB_TYPE_FOR_DASH_BOARD.empName
  );
  const [searchType, setSearchType] = useState<SEARCH_DB_TYPE_FOR_DASH_BOARD>();

  const handleSearchType = useCallback(
    (value: SEARCH_DB_TYPE_FOR_DASH_BOARD) => {
      searchTypeForSearch.current = value;
    },
    []
  );

  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [isReady, setIsReady] = useState<boolean>(false);

  const [getTotal, { data: totalData, loading: totalLoading }] = useLazyQuery<
    GetDashBoardTotal,
    GetDashBoardTotalVariables
  >(QUERY_GET_DASH_BOARD_TOTAL, {
    onCompleted() {
      setIsReady(false);
    }
  });

  const [getDashBoardData, { data, loading }] = useLazyQuery<
    GetDashBoard,
    GetDashBoardVariables
  >(QUERY_GET_DASH_BOARD, {
    onError(error) {
      console.log(error);
      handleToast("직원정보를 가져오지 못했습니다.", MessageTypes.ERROR);
    },
    onCompleted(data) {
      if (!data.getDashBoard.ok && data.getDashBoard.error) {
        handleToast(data.getDashBoard.error, MessageTypes.WARNING);
        handleIsOpen(true);
      }
    }
  });

  const [excelDownload, { data: excelData }] = useLazyQuery<
    DashBoardExcel,
    DashBoardExcelVariables
  >(QUERY_DASH_BOARD_EXCEL);

  const handleSearch = useCallback(
    (data: IFieldSearchByDateWithType) => {
      if (data.type) {
        isMounted.current = true;
        const INIT_PAGE = 1;
        setSearchType(data.searchType);
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.searchValue);
        setDate(data.date);
        setSearchValueForTime(data.searchValueForTime);
        setSearchValueForTruthyFalsy(data.searchValueForTruthyFalsy);
        getDashBoardData({
          variables: {
            page: INIT_PAGE,
            take,
            departmentId: department_id.toString(),
            employeeId: employee_id,
            date: data.date,
            searchType: data.searchType,
            searchValue: data.searchValue,
            searchValueForTime: data.searchValueForTime,
            searchValueForTruthyFalsy: data.searchValueForTruthyFalsy,
            fieldSort
          }
        }).then(() => {
          setIsReady(true);
          isMounted.current = false;
        });
      }
    },
    [
      handleCurrentPage,
      fieldSort,
      take,
      getDashBoardData,
      department_id,
      employee_id
    ]
  );

  const handleExcelDownload = useCallback(() => {
    excelDownload({
      variables: {
        date,
        searchType,
        searchValue,
        searchValueForTime,
        searchValueForTruthyFalsy
      }
    });
  }, [
    date,
    searchType,
    searchValue,
    searchValueForTime,
    searchValueForTruthyFalsy,
    excelDownload
  ]);

  const columns: ReactTable.Column<GetDashBoard_getDashBoard_listOfDashBoard>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      const newColumns: ReactTable.Column<GetDashBoard_getDashBoard_listOfDashBoard>[] =
        [];
      const calculateWidth = (item: COLUMN_FOR_VALUE) => {
        if (
          item === COLUMN_FOR_VALUE.workOx ||
          item === COLUMN_FOR_VALUE.nameOne ||
          item === COLUMN_FOR_VALUE.nameTwo ||
          item === COLUMN_FOR_VALUE.nameThree
        ) {
          return 70;
        }
        return 120;
      };
      listOfColumn.forEach(item => {
        newColumns.push({
          Header: COLUMN_FOR_VALUE[item as SEARCH_TYPE_FOR_DASH_BOARD_TABLE],
          accessor: item as SEARCH_TYPE_FOR_DASH_BOARD_TABLE,
          width: calculateWidth(
            COLUMN_FOR_VALUE[item as SEARCH_TYPE_FOR_DASH_BOARD_TABLE]
          )
        });
      });

      return newColumns;
    }, []);

  const list: GetDashBoard_getDashBoard_listOfDashBoard[] = useMemo(() => {
    return data?.getDashBoard.listOfDashBoard || [];
  }, [data]);

  const total: number = useMemo(() => {
    return totalData?.getDashBoardTotal.total || 0;
  }, [totalData]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    toggleHideColumn,
    columns: searchDashBoardColumns,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<GetDashBoard_getDashBoard_listOfDashBoard>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<GetDashBoard_getDashBoard_listOfDashBoard>) => {},
    []
  );

  const selectedRow:
    | ReactTable.Row<GetDashBoard_getDashBoard_listOfDashBoard>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const { moveColumn } = useDnd<GetDashBoard_getDashBoard_listOfDashBoard>({
    columns: visibleColumns,
    setColumnOrder,
    title: `대쉬보드 검색-for-ordering-column`
  });

  useEffect(() => {
    if (additionalSearchType) {
      setSearchType(additionalSearchType);
    }
    if (
      additionalSearchValueForTruthyFalsy === IS_TRUTHY_FALSY.TRUTHY ||
      additionalSearchValueForTruthyFalsy === IS_TRUTHY_FALSY.FALSY
    ) {
      setSearchValueForTruthyFalsy(additionalSearchValueForTruthyFalsy);
    }
    if (additionalSearchTimeValue) {
      setSearchValueForTime(additionalSearchTimeValue);
    }
  }, [
    additionalSearchType,
    additionalSearchValueForTruthyFalsy,
    additionalSearchTimeValue
  ]);

  useEffect(() => {
    if (searchType && !isMounted.current) {
      getDashBoardData({
        variables: {
          page: currentPage,
          take,
          departmentId: department_id.toString(),
          employeeId: employee_id,
          date,
          searchType,
          searchValue,
          searchValueForTime,
          searchValueForTruthyFalsy,
          fieldSort
        }
      }).then(() => {
        setIsReady(true);
      });
    }
  }, [
    currentPage,
    take,
    getDashBoardData,
    department_id,
    employee_id,
    date,
    searchType,
    searchValue,
    searchValueForTime,
    searchValueForTruthyFalsy,
    fieldSort
  ]);

  useEffect(() => {
    if (isReady) {
      getTotal({
        variables: {
          date,
          departmentId: department_id.toString(),
          employeeId: employee_id,
          searchType,
          searchValue,
          searchValueForTime,
          searchValueForTruthyFalsy
        }
      });
    }
  }, [
    department_id,
    employee_id,
    isReady,
    getTotal,
    date,
    searchType,
    searchValue,
    searchValueForTime,
    searchValueForTruthyFalsy
  ]);

  useEffect(() => {
    if (excelData?.dashBoardExcel.ok && excelData.dashBoardExcel.excel) {
      downloadFileFromServer(
        excelData.dashBoardExcel.excel,
        `${moment().format("YYYY-MM-DD-hh-mm-ss")}-dashboard.csv`
      );
    }
  }, [excelData]);

  useEffect(() => {
    if (initSearchDbTypeForDashBoard) {
      setSearchType(initSearchDbTypeForDashBoard);
    }
  }, [initSearchDbTypeForDashBoard]);
  return (
    <AsonicDialog
      handleClose={handleClose}
      title="대쉬보드 검색"
      minWidth="1530px"
      width="1530px"
      height="500px"
      minHeight="500px"
    >
      <Container>
        <AsonicTableForDashBoard<GetDashBoard_getDashBoard_listOfDashBoard>
          title="직원정보"
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          take={take}
          handleTake={handleTake}
          total={total}
          totalPage={Math.ceil(total / take)}
          downloadExcel={handleExcelDownload}
          handleSelectRow={handleSelectRow}
          isLoading={loading || totalLoading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          handleFieldSort={handleFieldSort}
          fieldSort={fieldSort}
          columns={
            searchDashBoardColumns as TColumn<GetDashBoard_getDashBoard_listOfDashBoard>[]
          }
          toggleHideColumn={toggleHideColumn}
          moveColumn={moveColumn}
          handleDateWithType={handleSearch}
          searchType={searchType}
          handleSearchType={handleSearchType}
          searchValue={searchValue}
          handleSearchValue={handleSearchValue}
          searchValueForTime={searchValueForTime}
          handleSearchValueForTime={handleSearchValueForTime}
          searchValueForTruthyFalsy={searchValueForTruthyFalsy}
          handleSearchValueForTruthyFalsy={handleSearchValueForTruthyFalsy}
          isAdditionalSearchTimeValue={!!additionalSearchTimeValue}
        />
      </Container>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </AsonicDialog>
  );
}

export default SearchDashBoard;
