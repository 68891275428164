import moment from "moment";
import { useMemo } from "react";
import styled from "styled-components";
import {
  Container,
  TitleBar,
  Title,
  SubTitle,
  ContentContainer
} from "./styled";

type Props = {
  title: string;
  subTitle: string;
  children?: React.ReactNode;
  selectedMonth?: number;
  handleCurrentMonth?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const ComboBoxContainer = styled.select`
  flex: 2;
`;
const ComboBox = styled.option``;

const StatusBox = <P extends Props>({
  title,
  subTitle,
  children,
  selectedMonth,
  handleCurrentMonth
}: P) => {
  const monthArray = useMemo(() => {
    const currentMonth = parseInt(moment().format("M"));
    const monthArray = [];
    for (let i = 1; i <= currentMonth; i++) {
      monthArray.push(i);
    }
    return monthArray.reverse();
  }, []);

  return (
    <Container data-testid="status-box">
      <TitleBar>
        <Title data-testid="title">
          <span>{title}</span>
          {title === "월간 근무 현황" && (
            <ComboBoxContainer
              value={selectedMonth}
              onChange={handleCurrentMonth}
            >
              {monthArray.map((item, index) => (
                <ComboBox value={item} key={index}>{`${item}월`}</ComboBox>
              ))}
            </ComboBoxContainer>
          )}
        </Title>
        <SubTitle data-testid="sub-title">{subTitle}</SubTitle>
      </TitleBar>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

export default StatusBox;
