import * as React from "react";
import Communication from "../../../components/electron/communication";

export type MenuList = {
  app_commute_flag: number;
  app_realtime_flag: number;
  app_webview_flag: number;
  electron_fileexecute_arg: string;
  electron_fileexecute_path: string;
  electron_fileexecute_text: string;
  menu_index: number;
  web_approval_flag: number;
  web_commutegps_flag: number;
  web_etc_flag: number;
  web_otmng_flag: number;
  web_report_flag: number;
};

type Props = { menuList?: MenuList; employeeId?: string };

const useExe = <P extends Props>({ menuList, employeeId }: P) => {
  const [exeTitle, setExeTitle] = React.useState<string>("exe");

  const makePath = React.useCallback(() => {
    let payload = {
      type: 1000,
      data: {
        path: menuList?.electron_fileexecute_path
      }
    };
    if (menuList?.electron_fileexecute_arg && employeeId) {
      if (menuList.electron_fileexecute_arg.includes("employee")) {
        const electronFileExcuteArg = menuList.electron_fileexecute_arg.replace(
          "employee",
          employeeId
        );
        payload = {
          ...payload,
          data: {
            path: `${menuList.electron_fileexecute_path} ${electronFileExcuteArg}`
          }
        };
      } else {
        payload = {
          ...payload,
          data: {
            path: `${menuList?.electron_fileexecute_path} ${menuList.electron_fileexecute_arg}`
          }
        };
      }
    }
    return payload;
  }, [menuList, employeeId]);

  const runExeFile = React.useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      Communication.getInstance()?.sendCommonRequest(makePath());
    },
    [makePath]
  );

  const isElectron = React.useMemo(() => {
    if (Communication.getInstance() && menuList?.electron_fileexecute_path) {
      return true;
    } else {
      return false;
    }
  }, [menuList]);

  React.useEffect(() => {
    setExeTitle(menuList?.electron_fileexecute_text || "exe");
  }, [menuList]);

  return { exeTitle, runExeFile, isElectron };
};

export default useExe;
