import API from "../api";
import routes from "../../routes";
import { SaveApprovalPasswordAndSignSettingMutationVariables } from "../../generated/graphql";

type DownloadExcelOfApprovalProcessingInquiryParams = {
  employeeId: string;
  categoryId: string;
  searchStartDate: moment.Moment;
  searchEndDate: moment.Moment;
  searchType?: number;
  searchWord?: string;
  resultValue: string;
};

type DownloadExcelOfApprovalApplicationInquiryParams = {
  employeeId: string;
  categoryId: string;
  searchStartDate: moment.Moment;
  searchEndDate: moment.Moment;
  resultValue: string;
};

interface ISaveApprovalSignImage {
  employeeId: string;
  signImage: FormData;
}

const downloadExcelOfApprovalProcessingInquiry = <
  P extends DownloadExcelOfApprovalProcessingInquiryParams
>(
  params: P
) => API().post(routes.apiRoutes.approval.approvalDownloadExcel, params);

const downloadExcelOfApprovalApplicationInquiry = <
  P extends DownloadExcelOfApprovalApplicationInquiryParams
>(
  params: P
) => API().post(routes.apiRoutes.request.requestDownloadExcel, params);

const approvalSaveSignImage = (params: ISaveApprovalSignImage) =>
  API().post(
    routes.apiRoutes.approval.approvalSaveSignImage,
    params.signImage,
    {
      headers: {
        employeeId: params.employeeId
      }
    }
  );

const approvalManagementApi = {
  downloadExcelOfApprovalProcessingInquiry,
  downloadExcelOfApprovalApplicationInquiry,
  approvalSaveSignImage
};

export default approvalManagementApi;
