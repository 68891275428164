import HelpContainer from "../../shared/help_container/help_container";
import InfoIcon from "../../shared/info-icon/info-icon";

function RecordingTimeHelp() {
  return (
    <InfoIcon
      minHeight="470px"
      height="470px"
      minWidth="400px"
      title="출퇴근 기록 설정"
    >
      <HelpContainer>
        <span>{`출근 및 퇴근 기록 방식을 설정합니다.`}</span>
        <ul>
          <li>{`출/퇴근 기록방식`}</li>
          <pre>{`근무제 방식에 따라 자동 결정되며, 정해진 시간에만 출/퇴근 시간이 고
정적인 경우와 자율출퇴근제와 같이 출/퇴근 시간이 고정적이지 않은
경우에 따라 자동 변경 됩니다.`}</pre>
          <li>{`출/퇴근 기록 장치`}</li>
          <span>{`출/퇴근을 기록하는 장치를 선택 합니다.`}</span>
          <ul>
            <li>{`PC`}</li>
            <span>{`사용자의 PC의(Windows, MAC)에서 출/퇴근 기록을 사용합니다.`}</span>
            <li>{`모바일 앱`}</li>
            <pre>{`사용자의 모바일 장치에 앱을 통해 출퇴근 기록을 사용합니다.
(A-Sonic 앱을 설치 필요)`}</pre>
          </ul>
          <li>{`출근/퇴근 체크 알림`}</li>
          <pre>{`출근 및 퇴근 체크 알림창이 발생하는 시간을 설정합니다. 출근 또는 퇴
근 시간 으로 부터 “이전(-분)” 또는 “이후(+분)”로 설정 가능하며, 설정
된 시간부터 5분 간격으로 알림이 발생합니다.
해당 알림은 출근 또는 퇴근 체크시 까지 반복되며, 체크시 종료됩니다.`}</pre>
          <li>{`연장근무시간 연결처리`}</li>
          <pre>{`신청된 연장근무(시간외 근무) 시간이 퇴근시간 직후로 연결되는 경우, 
퇴근 체크 알림 창이 발생하는 시간을 연장 근무 종료 시간으로 자동 변
경 되는 기능입니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default RecordingTimeHelp;
