import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";
import { WebMenuAuthValueList } from "../../use-check-web-auth-fn";

type AimMultiTabType = {
  name: string;
  isSelected: boolean;
};

interface IProps {
  menuList: AimMultiTabType[];
}

const useAimMultiTab = ({ menuList }: IProps) => {
  const {
    signInReducer: { webMenuList }
  } = useSelector((state: Reducers) => state);
  const [list, setList] = useState<AimMultiTabType[]>(menuList);

  const handleSelected = useCallback(
    (payload: AimMultiTabType) => {
      const newList = list.map(item => {
        if (item.name === payload.name) {
          return payload;
        } else {
          const newItem: AimMultiTabType = { ...item, isSelected: false };
          return newItem;
        }
      });
      setList(newList);
    },
    [list]
  );

  const selectedItem = useMemo(() => {
    const item = list.find(item => item.isSelected === true);
    return item;
  }, [list]);

  return { list, setList, selectedItem, handleSelected };
};

export default useAimMultiTab;
