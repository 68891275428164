import MainContainer from "../../../shared/main_container/main_container";
import DisplayTimeGraph, {
  ITime
} from "../../../time_graph/display_time_graph/display_time_graph";
import { useEffect, useMemo, useState } from "react";
import { COLOR_TYPE } from "../../../time_graph/time_graph_box/time_graph_box";
import {
  DayGraphEntity,
  Day_Type,
  Work_Type,
  useGetWtDayGraphLazyQuery
} from "../../../../generated/graphql";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";
import moment from "moment";

interface IProps {
  selectedDay?: {
    workWeekSelect: number;
    dayOfWeek: Day_Type;
    workingTemplateIdx: number;
  };
}

function FBWorkHourGraph({ selectedDay }: IProps) {
  const [dayGraph, setDayGraph] = useState<DayGraphEntity>();
  const [startHour, setStartHour] = useState<string>("00:00");
  const [endHour, setEndHour] = useState<string>("00:00");

  const [startBreakHour, setStartBreakHour] = useState<string>("00:00");
  const [endBreakHour, setEndBreakHour] = useState<string>("00:00");

  const [getWTDayGraph] = useGetWtDayGraphLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getWTDayGraph.ok && data.getWTDayGraph.dayGraph) {
        const { dayGraph } = data.getWTDayGraph;
        setDayGraph(dayGraph);
        const start = moment(dayGraph.startTime, "HH:mm");
        const end = moment(dayGraph.endTime, "HH:mm");
        setStartHour(start.format("HH:mm"));
        setEndHour(end.format("HH:mm"));

        const startBreak = moment(dayGraph.startBreakTime, "HH:mm");
        const endBreak = moment(dayGraph.endBreakTime, "HH:mm");
        setStartBreakHour(startBreak.format("HH:mm"));
        setEndBreakHour(endBreak.format("HH:mm"));
      } else if (!data.getWTDayGraph.ok && data.getWTDayGraph.error) {
        handleErrorLog(data.getWTDayGraph.error);
      }
    }
  });
  const list: ITime[] = useMemo(() => {
    let startHour = 0;
    let endHour = 0;
    let startBreakHour = 0;
    let endBreakHour = 0;
    if (dayGraph) {
      if (dayGraph.startTime) {
        startHour = Number(dayGraph.startTime?.split(":")[0]);
      }
      if (dayGraph.endTime) {
        endHour = Number(dayGraph.endTime?.split(":")[0]);
      }
      startBreakHour = Number(dayGraph.startBreakTime.split(":")[0]);
      endBreakHour = Number(dayGraph.endBreakTime.split(":")[0]);
    }
    const newList: ITime[] = [];
    for (let i = 0; i < 24; i++) {
      const min = i === 0 ? 60 * 12 : i * 60;
      const type = Work_Type.SFCommutingPolicy;
      let colorType: COLOR_TYPE = COLOR_TYPE.OFF_DUTY_HOURS;

      // 시간이 다음 날로 넘어가는 경우 처리
      if (startHour > endHour) {
        if (i >= startHour || i < endHour) {
          colorType = COLOR_TYPE.WORKING_HOURS;
        }
      } else {
        if (i >= startHour && i < endHour) {
          colorType = COLOR_TYPE.WORKING_HOURS;
        }
      }

      if (i >= startBreakHour && i < endBreakHour) {
        colorType = COLOR_TYPE.BREAK_TIME;
      }
      newList.push({
        title: `${i}`,
        min,
        type,
        colorType
      });
    }
    return newList;
  }, [dayGraph]);

  const labelList = useMemo(() => {
    return [
      {
        labelName: "근무외시간",
        colorType: COLOR_TYPE.OFF_DUTY_HOURS
      },
      {
        labelName: "근무시간",
        colorType: COLOR_TYPE.WORKING_HOURS
      },
      {
        labelName: "휴게시간",
        colorType: COLOR_TYPE.BREAK_TIME
      }
    ];
  }, []);

  const description = useMemo(() => {
    return `출근가능시간 : ${startHour} ~ ${endHour}, 휴게시간 : ${startBreakHour} ~ ${endBreakHour}`;
  }, [startHour, endHour, startBreakHour, endBreakHour]);

  useEffect(() => {
    if (selectedDay) {
      getWTDayGraph({
        variables: selectedDay
      });
    }
  }, [getWTDayGraph, selectedDay]);

  return (
    <MainContainer gap={"10px"}>
      <DisplayTimeGraph
        list={list}
        labelList={labelList}
        description={{
          description
        }}
      />
    </MainContainer>
  );
}

export default FBWorkHourGraph;
