import { useRef, useState, useCallback, useEffect } from "react";
import { getWorkingTemplatePolicy } from "../../Utils/commonAxiosCall";

export interface IWorkingTemplate {
  working_templete_idx: number | null;
  name: string;
}

const useWorkingTemplate = () => {
  const isMounted = useRef(false);
  const [listOfWorkingTemplate, setListOfWorkingTemplate] = useState<
    IWorkingTemplate[]
  >([]);
  const [selectedWorkingTemplate, setSelectedWorkingTemplate] =
    useState<IWorkingTemplate>();
  const handleSelectWorkingTemplate = useCallback((temp: IWorkingTemplate) => {
    setSelectedWorkingTemplate(temp);
  }, []);

  const handleWorkingTemplate = useCallback(async () => {
    const { data } = await getWorkingTemplatePolicy();
    if (isMounted.current) {
      data.push({ working_templete_idx: null, name: "미사용" });

      setListOfWorkingTemplate(data);
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    handleWorkingTemplate().catch(error => console.log(error));

    return () => {
      isMounted.current = false;
    };
  }, [handleWorkingTemplate]);

  return {
    listOfWorkingTemplate,
    selectedWorkingTemplate,
    handleSelectWorkingTemplate
  };
};

export default useWorkingTemplate;

// 1. 템플릿 선택
// 2. dialog 띄워주기
// 3. 확인버튼 클릭시 템플릿 변경및 닫기
// 4. 템플릿 변경후 메시지 띄워주기
