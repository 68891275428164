import AddIcon from "@iconify/icons-clarity/plus-circle-solid";
import { colors } from "../GlobalStyle/GlobalStyle";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import EditIcon from "@iconify/icons-clarity/edit-solid";
import FolderIcon from "@iconify/icons-clarity/folder-open-solid";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import { useCallback, useState } from "react";

interface IProps {
  isAddActive?: boolean;
  isEditActive?: boolean;
  isDeleteActive?: boolean;
  handleAddButton: () => void;
  handleEditButton: () => void;
  handleDeleteButton: () => void;
}

const Container = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  right: 10px;
  bottom: 10px;
  min-width: max-content;
  min-height: max-content;
  gap: 5px;
  visibility: visible;
`;

const IconContainer = styled.div<{
  color: string;
  disabled?: boolean;
  isOpen: boolean;
}>`
  display: flex;
  position: absolute;
  right: 10px;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  background-color: ${props =>
    props.disabled ? `${props.theme.colors.darkGrey}` : `${props.color}`};
  cursor: pointer;
  :active {
    transform: ${props => (props.disabled ? "" : "scale(0.95)")};
  }
  svg {
    color: ${props => props.theme.colors.white};
    font-size: 20px;
  }
  :first-child {
    bottom: ${props => (props.isOpen ? "105px" : "0px")};
    transition: all 100ms ease-out;
  }
  :nth-child(2) {
    bottom: ${props => (props.isOpen ? "70px" : "0px")};
    transition: all 150ms ease-out;
  }
  :nth-child(3) {
    bottom: ${props => (props.isOpen ? "35px" : "0px")};
    transition: all 200ms ease-out;
  }
  :not(:last-child) {
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  }
  :last-child {
    svg {
      color: ${props => props.theme.colors.black};
    }
  }
`;

function ControllerWithIconButton({
  isAddActive,
  isEditActive,
  isDeleteActive,
  handleAddButton,
  handleEditButton,
  handleDeleteButton
}: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpenFolder = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isOpen]);

  return (
    <Container>
      <IconContainer
        color={colors.tomato}
        isOpen={isOpen}
        disabled={isDeleteActive}
        onClick={() => {
          if (!isDeleteActive) {
            handleDeleteButton();
          }
        }}
      >
        <Icon icon={DeleteIcon} />
      </IconContainer>
      <IconContainer
        color={colors.darkGreen}
        isOpen={isOpen}
        disabled={isEditActive}
        onClick={() => {
          if (!isEditActive) {
            handleEditButton();
          }
        }}
      >
        <Icon icon={EditIcon} />
      </IconContainer>
      <IconContainer
        color={colors.darkBlue}
        isOpen={isOpen}
        disabled={isAddActive}
        onClick={() => {
          if (!isAddActive) {
            handleAddButton();
          }
        }}
      >
        <Icon icon={AddIcon} />
      </IconContainer>
      <IconContainer
        color={colors.yellow}
        onClick={handleIsOpenFolder}
        isOpen={isOpen}
      >
        <Icon icon={FolderIcon} />
      </IconContainer>
    </Container>
  );
}

export default ControllerWithIconButton;
