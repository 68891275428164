import { useState } from "react";
import styled from "styled-components";
import { GroupEntity } from "../../../generated/graphql";
import { Icon } from "@iconify/react";
import CloseFolderIcon from "@iconify/icons-fa-solid/folder";
import OpenFolderIcon from "@iconify/icons-fa-solid/folder-open";
import ChildrenFolderIcon from "@iconify-icons/ph/play-bold";
import OpenChildrenFolderIcon from "@iconify-icons/ph/play-fill";

interface ITreeItemProps {
  branch: GroupEntity;
  children?: React.ReactNode;
  selectedItem?: number;
  handleSelectedItem: (payload: GroupEntity) => void;
  isChildren: boolean;
}

const ItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`;

const Item = styled.div<{ isOpen: boolean; isSelected: boolean }>`
  display: flex;
  flex: 1;
  gap: 10px;
  svg {
    font-size: 14px;
  }
  align-items: center;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  :active {
    color: ${props => props.theme.colors.lightGreen};
  }
  color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.black};
  user-select: none;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 3px;
`;

const IconContainer = styled.div<{ isChildren: boolean; isOpen: boolean }>`
  display: flex;
  padding-left: ${props => (props.isChildren ? "none" : "17px")};
`;

const ChildrenIconContainer = styled.div<{
  isChildren: boolean;
  isOpen: boolean;
}>`
  display: flex;
  transform: ${props => (props.isOpen ? "rotate(45deg)" : "none")};
`;

function TreeItem({
  branch,
  selectedItem,
  handleSelectedItem,
  isChildren,
  children
}: ITreeItemProps) {
  const [isOpen, toggleItemOpen] = useState<boolean>(false);

  return (
    <ItemContainer key={branch.departmentId}>
      <Item
        isOpen={isOpen}
        isSelected={selectedItem === branch.departmentId}
        onClick={() => {
          handleSelectedItem(branch);
        }}
      >
        <IconsContainer>
          {isChildren && (
            <ChildrenIconContainer
              onClick={() => {
                toggleItemOpen(!isOpen);
              }}
              isChildren={isChildren}
              isOpen={isOpen}
            >
              <Icon
                icon={isOpen ? OpenChildrenFolderIcon : ChildrenFolderIcon}
              />
            </ChildrenIconContainer>
          )}
          <IconContainer
            onClick={() => {
              toggleItemOpen(!isOpen);
            }}
            isChildren={isChildren}
            isOpen={isOpen}
          >
            <Icon icon={isOpen ? OpenFolderIcon : CloseFolderIcon} />
          </IconContainer>
        </IconsContainer>
        <span>{branch.name}</span>
      </Item>
      {isOpen && children}
    </ItemContainer>
  );
}

export default TreeItem;
