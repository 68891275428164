import styled from "styled-components";
import AsonicTableData from "./asonic-table-data";

const AsonicTableHeaderRow = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 2px solid ${props => props.theme.colors.grey};
  :hover {
    ${AsonicTableData} {
      color: ${props => props.theme.colors.black};
    }
  }
`;

export default AsonicTableHeaderRow;
