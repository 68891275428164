import { useCallback, useMemo } from "react";
import moment from "moment";

export const HOUR_TYPE = {
  AM: "AM",
  PM: "PM"
} as const;

export type HOUR_TYPE = (typeof HOUR_TYPE)[keyof typeof HOUR_TYPE];

export const OperatorType = {
  MINUS: "MINUS",
  PLUS: "PLUS"
} as const;

export type OperatorType = (typeof OperatorType)[keyof typeof OperatorType];

function useTimeList() {
  const breakTimeList = useMemo(() => {
    let newList = [];
    for (let i = 0; i < 24; i++) {
      const startType = i < 12 ? "오전" : "오후";
      const endType = i + 1 < 12 ? "오전" : "오후";
      const sTime = moment(i, "H").format("H");
      const eTime = moment(i + 1, "H").format("H");
      newList.push({
        name: `${startType} ${sTime}:00 ~ ${endType} ${eTime}:00`,
        value: `${startType} ${sTime}:00 ~ ${endType} ${eTime}:00`
      });
    }
    return newList;
  }, []);

  const weekList = useMemo(() => {
    return [
      { name: "01", value: 1 },
      { name: "02", value: 2 },
      { name: "03", value: 3 },
      { name: "04", value: 4 },
      { name: "05", value: 5 }
    ];
  }, []);

  const hourTypeList: { name: string; value: HOUR_TYPE }[] = useMemo(() => {
    return [
      { name: "오전", value: HOUR_TYPE.AM },
      { name: "오후", value: HOUR_TYPE.PM }
    ];
  }, []);

  const listOfOperator: { name: string; value: OperatorType }[] =
    useMemo(() => {
      return [
        { name: "-", value: OperatorType.MINUS },
        { name: "+", value: OperatorType.PLUS }
      ];
    }, []);

  const hourList: string[] = useMemo(() => {
    const newHours = [];
    for (let i = 1; i <= 12; i++) {
      const hour = moment(i, "H").format("H");
      newHours.push(hour);
    }
    return newHours;
  }, []);

  const minList: string[] = useMemo(() => {
    const newHours = [];
    for (let i = 0; i <= 50; i += 10) {
      let min = "";
      if (i === 0) {
        min = "00";
      } else {
        min = `${i}`;
      }
      newHours.push(min);
    }
    return newHours;
  }, []);

  const everyMins: any[] = useMemo(() => {
    const mins = [];
    for (let i = 0; i <= 59; i++) {
      mins.push({ value: i, name: `${i.toString().padStart(2, "0")}` });
    }
    return mins;
  }, []);

  const minListForAttendance: string[] = useMemo(() => {
    const newHours = [];
    for (let i = 0; i <= 60; i += 5) {
      let min = String(i).padStart(2, "0");
      newHours.push(min);
    }
    return newHours;
  }, []);

  const workHourList: string[] = useMemo(() => {
    const newHours = [];
    for (let i = 1; i <= 24; i++) {
      const hour = moment(i, "H").format("H");
      newHours.push(hour);
    }
    return newHours;
  }, []);

  const time24List = useMemo(() => {
    let times = [];
    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        let time =
          hour.toString().padStart(2, "0") +
          ":" +
          minute.toString().padStart(2, "0");
        times.push(time);
      }
    }
    return times;
  }, []);

  const convert12HourTo24Hour = useCallback(
    (hourStr: string, minuteStr: string, type: HOUR_TYPE): string => {
      let hour = parseInt(hourStr);
      const minute = parseInt(minuteStr);

      if (type === HOUR_TYPE.PM && hour !== 12) {
        hour += 12;
      } else if (type === HOUR_TYPE.AM && hour === 12) {
        hour = 0;
      }

      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    },
    []
  );

  return {
    minListForAttendance,
    listOfOperator,
    hourList,
    minList,
    everyMins,
    hourTypeList,
    weekList,
    breakTimeList,
    workHourList,
    time24List,
    convert12HourTo24Hour
  };
}

export default useTimeList;
