import React from "react";
import styled from "styled-components";
import HeaderTitle from "../smallComponents/HeaderTitle";
import SettingForm from "../MobileSettingView/SettingFrom";

const Container = styled.div`
  display: flex;
`;

const MobileSettingView = props => {
  return (
    <React.Fragment>
      <HeaderTitle category="settings" />
      <Container>
        <SettingForm props={props} />
      </Container>
    </React.Fragment>
  );
};

export default MobileSettingView;
