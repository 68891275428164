import { useForm } from "react-hook-form";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import { Approval_User_Type } from "../../../generated/graphql";
import { IEmployeeInformation } from "./list-of-selected-approval-user";

interface IProps {
  cell: Cell<IEmployeeInformation>;
  handleChangeUserInfoInListOfApprovalUser: (
    payload: IEmployeeInformation
  ) => void;
}

const RECEIVE_AND_REFERENCE = {
  RECEIVE: "RECEIVE",
  REFERENCE: "REFERENCE"
} as const;

type RECEIVE_AND_REFERENCE =
  typeof RECEIVE_AND_REFERENCE[keyof typeof RECEIVE_AND_REFERENCE];

function ApprovalProcessSelect({
  cell,
  handleChangeUserInfoInListOfApprovalUser
}: IProps) {
  const { register } = useForm<{
    approvalUserType: Approval_User_Type | RECEIVE_AND_REFERENCE;
  }>({
    mode: "onSubmit",
    defaultValues: {
      approvalUserType: cell.value
    }
  });
  const selectRegister = register("approvalUserType", {
    required: true
  });
  return (
    <select
      {...selectRegister}
      onChange={event => {
        selectRegister.onChange(event);
        const newUser: IEmployeeInformation = {
          ...cell.row.original,
          approvalType: event.target.value as Approval_User_Type
        };
        const payload: IEmployeeInformation = newUser;
        handleChangeUserInfoInListOfApprovalUser(payload);
      }}
    >
      <option value={Approval_User_Type.Approval}>결재</option>
      <option value={Approval_User_Type.Agreement}>합의</option>
    </select>
  );
}

export default ApprovalProcessSelect;
