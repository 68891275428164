import styled from "styled-components";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import { Icon } from "@iconify/react";
import CheckBoxUi from "../globalComponents/CheckBoxUi";
import useHideColumns, {
  TColumn
} from "../../hooks/use-hide-columns/use-hide-columns";
import FilterIcon from "@iconify-icons/fa/filter";

interface IRenderRow<P extends object> {
  index: number;
  style: object;
  data: TColumn<P>[];
}

export interface IAsonicHideColumnProps<P extends object> {
  columns: TColumn<P>[];
  toggleHideColumn: (columnId: string, value?: boolean | undefined) => void;
  title: string;
  setIsActiveFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListContainer = styled.div`
  position: absolute;
  top: 26px;
  left: -80px;
  border: 1px solid ${props => props.theme.colors.green};
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  height: 140px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 180px;
  padding: 4px;
`;

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  cursor: pointer;
  ${ListContainer} {
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  }
  z-index: 2;
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  color: ${props => props.theme.colors.green};
  border: 1px solid ${props => props.theme.colors.green};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  :hover {
    color: ${props => props.theme.colors.lightGreen};
    border: 1px solid ${props => props.theme.colors.lightGreen};
  }
`;

function Row<P extends object>(
  toggleHideColumn: (columnId: string, value?: boolean | undefined) => void,
  title: string,
  countItem: number,
  handleCountItem: (value: number) => void,
  setIsActiveFilter?: React.Dispatch<React.SetStateAction<boolean>>
) {
  return ({ index, style, data }: IRenderRow<P>) => {
    const name: string = data[index].Header as string;
    return (
      <ItemContainer key={index} style={style}>
        <CheckBoxUi
          checked={data[index].isVisible}
          onChange={() => {
            if (setIsActiveFilter) {
              setIsActiveFilter(true);
            }
            const isChecked = data[index].isVisible;
            if (isChecked && countItem > 1) {
              handleCountItem(countItem - 1);
              toggleHideColumn(data[index].id, data[index].isVisible);
              data[index].toggleHidden(true);
              const newData = data.map(item => {
                let newItem = { ...item };
                if (newItem.parent) {
                  newItem.parent = undefined;
                }
                return newItem;
              });
              newData[index].isVisible = !isChecked;
              localStorage.setItem(title, JSON.stringify(newData));
            } else if (!isChecked) {
              handleCountItem(countItem + 1);
              toggleHideColumn(data[index].id, data[index].isVisible);
              const newData = data.map(item => {
                let newItem = { ...item };
                if (newItem.parent) {
                  newItem.parent = undefined;
                }
                return newItem;
              });
              newData[index].isVisible = !isChecked;
              localStorage.setItem(title, JSON.stringify(newData));
            }
          }}
          name={name}
        />
      </ItemContainer>
    );
  };
}

function AsonicHideColumn<P extends object>({
  columns,
  toggleHideColumn,
  title,
  setIsActiveFilter
}: IAsonicHideColumnProps<P>) {
  const { countItem, handleCountItem, isOpen, handleIsOpen } =
    useHideColumns<P>({
      columns,
      toggleHideColumn,
      title: `${title} for column`
    });

  return (
    <Container isOpen={isOpen} onClick={handleIsOpen}>
      <IconContainer>
        <Icon icon={FilterIcon} />
      </IconContainer>
      <ListContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                height={height}
                itemCount={columns.length}
                itemSize={30}
                width={width}
                itemData={columns}
              >
                {Row(
                  toggleHideColumn,
                  `${title} for column`,
                  countItem,
                  handleCountItem,
                  setIsActiveFilter
                )}
              </List>
            );
          }}
        </AutoSizer>
      </ListContainer>
    </Container>
  );
}

export default AsonicHideColumn;
