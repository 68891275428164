import styled from "styled-components";
import { ICustomQuery } from "../admin-statistics/admin-statistics";
import { Icon } from "@iconify/react";
import EyeIcon from "@iconify/icons-fa-solid/eye";
import LockIcon from "@iconify/icons-fa-solid/lock";
import LockOpenIcon from "@iconify/icons-fa-solid/lock-open";
import { Fragment } from "react";

export enum Readonly {
  NOT_ALLOWED,
  READ
}

export enum IsPublish {
  NOT_ALLOWED,
  PUBLISH
}

interface IProps {
  selectedQuery?: Partial<ICustomQuery>;
  handleSelectedQuery: (payload?: ICustomQuery) => void;
}
interface IPropsForRender {
  data: ICustomQuery[];
  index: number;
  style: object;
}

const Container = styled.div<{ selectedQuery: boolean }>`
  display: flex;
  flex: 1;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
    color: ${props => props.theme.colors.white};
  }
  background-color: ${props =>
    props.selectedQuery ? props.theme.colors.auroraGreen : null};
  color: ${props =>
    props.selectedQuery ? props.theme.colors.white : props.theme.colors.black};
  cursor: pointer;
`;

const Item = styled.div`
  display: flex;
  padding: 10px;
  gap: 5px;
`;

function ListOfStatistics({ selectedQuery, handleSelectedQuery }: IProps) {
  return ({ data, index, style }: IPropsForRender) => {
    const item = data[index];
    const isReadonly = item.readonly;
    const isPublish = item.publish;
    return (
      <Container
        style={style}
        selectedQuery={selectedQuery?.index === item.index}
        onClick={() => {
          if (selectedQuery) {
            if (item.index === selectedQuery.index) {
              // handleSelectedQuery();
            } else if (item.index !== selectedQuery.index) {
              handleSelectedQuery(item);
            }
          } else {
            handleSelectedQuery(item);
          }
        }}
      >
        <Item>
          {isReadonly === Readonly.READ && (
            <Fragment>
              {isPublish === IsPublish.PUBLISH && <Icon icon={EyeIcon} />}
              <Icon icon={LockIcon} />
            </Fragment>
          )}
          {isReadonly === Readonly.NOT_ALLOWED && <Icon icon={LockOpenIcon} />}
          {item.title}
        </Item>
      </Container>
    );
  };
}

export default ListOfStatistics;
