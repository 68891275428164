import styled from "styled-components";

const StyleTextarea = styled.textarea`
  display: flex;
  flex: 2;
  resize: none;
  outline: unset;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  &:focus {
    border-color: ${props => props.theme.colors.green};
  }
`;

export default StyleTextarea;
