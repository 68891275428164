import styled from "styled-components";

const IconContainer = styled.div`
  display: flex;
  flex: 1;
  color: ${props => props.theme.colors.green};
  font-size: 25px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  &:active {
    transform: scale(0.98);
  }
`;

export default IconContainer;
