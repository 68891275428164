import React from "react";
import HeaderTitle from "../smallComponents/HeaderTitle";
import MobileRequestSearchBar from "../smallComponents/MobileRequestSearchBar";
import {
  SignInReducer,
  RequestReducer,
  CategorySelectionReducer
} from "../../../../types/reducers";
interface IProps {
  signInReducer: SignInReducer;
  categorySelectionReducer: CategorySelectionReducer;
  requestReducer: RequestReducer;
  selectRequestDoc: any;
  getRequestDocs: any;
}

const MobileRequestView = <P extends IProps>(props: P) => {
  return (
    <React.Fragment>
      <HeaderTitle category="request" />
      <MobileRequestSearchBar {...props} />
    </React.Fragment>
  );
};

export default MobileRequestView;
