import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  GroupEntity,
  useGetGroupsForAllLazyQuery
} from "../../../generated/graphql";
import TreeItem from "./treeItem";

export interface ITreeOfApprovalProps {
  handleSelectedDepartment: (department: GroupEntity) => void;
}

interface ITreeProps {
  tree: GroupEntity[];
  branch: GroupEntity;
  parentId: number;
}

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px;
`;

function TreeOfApproval({ handleSelectedDepartment }: ITreeOfApprovalProps) {
  const [selectedItem, setSelectedItem] = useState<number>();
  const [getGroups, { data }] = useGetGroupsForAllLazyQuery({
    onError(error) {
      console.log(error);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network"
  });
  const group = useMemo(() => {
    return data?.getGroupsForAll.listOfGroup ?? [];
  }, [data]);
  const root = useMemo((): number => 0, []);

  const handleSelectedItem = useCallback(
    (payload: GroupEntity) => {
      setSelectedItem(payload.departmentId);
      handleSelectedDepartment(payload);
    },
    [handleSelectedDepartment]
  );

  const createTree = useCallback(
    ({ tree, branch, parentId }: ITreeProps) => {
      if (branch.parentDepartmentId === parentId) {
        let isChildren = false;
        tree.forEach(item => {
          if (item.parentDepartmentId === branch.departmentId && !isChildren) {
            isChildren = true;
          }
        });
        return (
          <TreeItem
            branch={branch}
            selectedItem={selectedItem}
            handleSelectedItem={handleSelectedItem}
            key={branch.departmentId}
            isChildren={isChildren}
          >
            {tree.map(newBranch => {
              const newTree = tree.filter(item => {
                return item.departmentId !== branch.departmentId;
              });
              return createTree({
                tree: newTree,
                branch: newBranch,
                parentId: branch.departmentId
              });
            })}
          </TreeItem>
        );
      }
    },
    [selectedItem, handleSelectedItem]
  );

  useEffect(() => {
    getGroups();
  }, [getGroups]);
  return (
    <Container>
      {group?.map(branch => {
        const tree = [...group];
        return createTree({
          tree,
          branch,
          parentId: root
        });
      })}
    </Container>
  );
}

export default TreeOfApproval;
