import styled from "styled-components";

export const CategorySelect = styled.select``;

export const Label = styled.label`
  color: ${props => props.theme.colors.veryDarkBlue};
  font-size: 12px;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const SearchContainer = styled.div`
  display: flex;
`;
