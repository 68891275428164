import styled from "styled-components";

export interface ITab {
  isSelected?: boolean;
}

const STab = styled.div<ITab>`
  display: flex;
  background-color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.white};
  padding: 7px 20px 7px 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid ${props => props.theme.colors.green};
  color: ${props =>
    props.isSelected ? props.theme.colors.white : props.theme.colors.black};
  font-weight: bold;
  :hover {
    color: white;
    background-color: ${props => props.theme.colors.lightGreen};
  }
  user-select: none;
  cursor: pointer;
  :active {
    transform: scale(0.98);
  }
`;

export default STab;
