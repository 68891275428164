import { Fragment, useCallback, useMemo } from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import styled from "styled-components";
import {
  Type_Control_Approval_Form,
  useControlApprovalFormMutation,
  useDeleteApprovalTemplateFormMutation,
  Approval_Sort
} from "../../../generated/graphql";
import useConfirmDialog from "../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";
import Button from "../../globalComponents/Button";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import { IApprovalTemplate } from "./approval-template-detail";
import { IApprovalTemplateEntity } from "./list-of-approval-template";
import { Row } from "./list-of-approval-template";
import { BUTTON_TEXT, CONFIRM_DIALOG_TEXT } from "./constants";
import { BUTTON_COLOR } from "../../../screens/constants";
interface IProps {
  checkedRows: Row<IApprovalTemplateEntity>[];
  controlType: Type_Control_Approval_Form;
  handleControlType: (type: Type_Control_Approval_Form) => void;
  handleSubmit: UseFormHandleSubmit<IApprovalTemplate>;
  selectedRow?: ICompactRow<IApprovalTemplateEntity>;
  handleInitCheckedRows: () => void;
  approvalPermissions: number;
  approvalSharing: number;
  approvedEmployeeIds: string[];
  deleteApprovedEmployeeId: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  gap: 10px;
`;

function ApprovalSettingButtons({
  checkedRows,
  controlType,
  handleControlType,
  handleSubmit,
  selectedRow,
  handleInitCheckedRows,
  approvalPermissions,
  approvalSharing,
  approvedEmployeeIds,
  deleteApprovedEmployeeId
}: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();
  const listOfFormTemplateIdx: number[] = useMemo(() => {
    return checkedRows.map(template => template.original.formTemplateIdx);
  }, [checkedRows]);

  const [deleteApprovalTemplateForm] = useDeleteApprovalTemplateFormMutation({
    onError(error) {
      console.log(error);
      handleMessage(CONFIRM_DIALOG_TEXT.ERROR_DELETE);
      handleToastMessageType(MessageTypes.ERROR);
      handleIsToastMessageOpen(true);
    },
    update(cache, { data }, { variables }) {
      if (data?.deleteApprovalTemplateForm.ok) {
        if (Array.isArray(variables?.listOfFormTemplateIdx)) {
          variables?.listOfFormTemplateIdx.forEach(templateIdx => {
            cache.evict({ id: `ApprovalTemplateEntity:${templateIdx}` });
          });
        }
        handleMessage(CONFIRM_DIALOG_TEXT.SUCCESS_DELETE);
        handleToastMessageType(MessageTypes.SUCCESS);
        handleInitCheckedRows();
      } else if (data?.deleteApprovalTemplateForm.error) {
        handleMessage(data.deleteApprovalTemplateForm.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [controlApprovalForm, { client }] = useControlApprovalFormMutation({
    onError(error) {
      console.log(error);
      handleMessage(CONFIRM_DIALOG_TEXT.ERROR_ADD);
      handleToastMessageType(MessageTypes.ERROR);
      handleIsToastMessageOpen(true);
    },
    update(_, { data }, { variables }) {
      if (data?.controlApprovalForm.ok && variables) {
        if (
          variables.controlApprovalFormType === Type_Control_Approval_Form.Edit
        ) {
          handleMessage(CONFIRM_DIALOG_TEXT.SUCCESS_EDIT);
        } else {
          handleMessage(CONFIRM_DIALOG_TEXT.SUCCESS_ADD);
        }
        client.resetStore();
        handleToastMessageType(MessageTypes.SUCCESS);
        handleIsToastMessageOpen(true);
        deleteApprovedEmployeeId();
      } else if (data?.controlApprovalForm.error) {
        handleMessage(data?.controlApprovalForm.error);
        handleToastMessageType(MessageTypes.ERROR);
        handleIsToastMessageOpen(true);
      }
    }
  });

  const handleConfirm = useCallback(() => {
    deleteApprovalTemplateForm({
      variables: {
        listOfFormTemplateIdx
      }
    });
    handleIsOpen(false);
  }, [handleIsOpen, listOfFormTemplateIdx, deleteApprovalTemplateForm]);

  const handleEdit = useCallback(
    (data: IApprovalTemplate) => {
      if (selectedRow) {
        const categoryId =
          typeof data.otTimeTypeCategoryId === "string"
            ? parseInt(data.otTimeTypeCategoryId)
            : data.otTimeTypeCategoryId;
        const formIdx =
          typeof data.formIdx === "string"
            ? parseInt(data.formIdx)
            : data.formIdx;
        controlApprovalForm({
          variables: {
            controlApprovalFormType: Type_Control_Approval_Form.Edit,
            templateName: data.templateTitle,
            categoryId,
            approvalSort: data.approvalType as Approval_Sort,
            formTemplateIdx: selectedRow.original.formTemplateIdx,
            formIdx,
            usePermission: approvalPermissions,
            stringifyJsonEmployeeIdsForPermission:
              JSON.stringify(approvedEmployeeIds),
            shareApproval: approvalSharing,
            description: data.description
          }
        });
        handleControlType(Type_Control_Approval_Form.Delete);
      }
    },
    [
      handleControlType,
      selectedRow,
      controlApprovalForm,
      approvalPermissions,
      approvalSharing,
      approvedEmployeeIds
    ]
  );

  const handleAdd = useCallback(
    (data: IApprovalTemplate) => {
      const categoryId =
        typeof data.otTimeTypeCategoryId === "string"
          ? parseInt(data.otTimeTypeCategoryId)
          : data.otTimeTypeCategoryId;
      const formIdx =
        typeof data.formIdx === "string"
          ? parseInt(data.formIdx)
          : data.formIdx;
      controlApprovalForm({
        variables: {
          controlApprovalFormType: Type_Control_Approval_Form.Add,
          templateName: data.templateTitle,
          categoryId,
          approvalSort: data.approvalType as Approval_Sort,
          formIdx,
          usePermission: approvalPermissions,
          stringifyJsonEmployeeIdsForPermission:
            JSON.stringify(approvedEmployeeIds),
          shareApproval: approvalSharing,
          description: data.description
        }
      });
      handleControlType(Type_Control_Approval_Form.Delete);
    },
    [handleControlType, controlApprovalForm, approvedEmployeeIds]
  );

  return (
    <Container>
      {controlType === Type_Control_Approval_Form.Delete && (
        <Fragment>
          <Button
            onClick={() => {
              handleControlType(Type_Control_Approval_Form.Add);
            }}
          >
            추가
          </Button>
          <Button
            onClick={() => {
              handleControlType(Type_Control_Approval_Form.Edit);
            }}
            disabled={!selectedRow?.original.formIdx}
          >
            수정
          </Button>
          <Button
            disabled={!selectedRow?.original.formIdx}
            backgroundColor={BUTTON_COLOR.DELETE}
            hoverBackgroundColor={BUTTON_COLOR.DELETE_HOVER}
            onClick={event => {
              event.preventDefault();
              handleConfirmMessage({
                title: CONFIRM_DIALOG_TEXT.TITLE,
                p: CONFIRM_DIALOG_TEXT.PARAGRAPH,
                messageTypes: MessageTypes.WARNING
              });
              handleIsOpen(true);
            }}
          >
            삭제
          </Button>
        </Fragment>
      )}
      {controlType === Type_Control_Approval_Form.Add && (
        <Button onClick={handleSubmit(handleAdd)}>{BUTTON_TEXT.SAVE}</Button>
      )}
      {controlType === Type_Control_Approval_Form.Edit && (
        <Button
          onClick={handleSubmit(handleEdit)}
          disabled={!selectedRow?.original.formIdx}
        >
          {BUTTON_TEXT.SAVE}
        </Button>
      )}
      {controlType !== Type_Control_Approval_Form.Delete && (
        <Button
          backgroundColor={BUTTON_COLOR.CANCEL}
          hoverBackgroundColor={BUTTON_COLOR.CANCEL_HOVER}
          onClick={() => {
            handleControlType(Type_Control_Approval_Form.Delete);
          }}
        >
          {BUTTON_TEXT.CANCEL}
        </Button>
      )}
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.WARNING}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ApprovalSettingButtons;
