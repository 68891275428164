import styled from "styled-components";

export const Container = styled.div`
  margin-right: 2px;
  button {
    :hover {
      transform: scale(1.5);
    }
  }
`;
