import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App";
import GlobalStyle from "./components/GlobalStyle";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "./redux/modules";
import rootSaga from "./redux/sagas";
import createSagaMiddleware from "redux-saga";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/apollo";
import AsonicDndContainer from "./components/asonic-table/asonic-dnd-container";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <AsonicDndContainer>
        <App />
      </AsonicDndContainer>
      <GlobalStyle />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
