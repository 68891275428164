import styled from "styled-components";
import mColors from "../../../../style-sheet/m_colors";
import { useMemo } from "react";

interface IProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  gap: 5px;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const BadgeNumber = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props =>
    props.isSelected ? props.theme.colors.green : mColors.darkYellow};
  height: 25px;
  width: 25px;
  color: ${props => props.theme.colors.white};
`;

const Divider = styled.div`
  display: flex;
  flex: 2;
  border-bottom: 2px solid ${props => props.theme.colors.frameGrey};
`;

function MRequestPageStatus({ page, setPage }: IProps) {
  const title = useMemo(() => {
    let newTitle = "결재 양식 선택";
    switch (page) {
      case 2:
        newTitle = "기간 및 내용 입력";
        break;
      case 3:
        newTitle = "결재자 선택";
        break;
    }
    return newTitle;
  }, [page]);
  return (
    <Container>
      <ContentContainer>
        <BadgeNumber isSelected onClick={() => setPage(1)}>{`1`}</BadgeNumber>
        <Divider />
        <BadgeNumber
          isSelected={page > 1}
          onClick={() => setPage(2)}
        >{`2`}</BadgeNumber>
        <Divider />
        <BadgeNumber
          isSelected={page === 3}
          onClick={() => setPage(3)}
        >{`3`}</BadgeNumber>
      </ContentContainer>
      <ContentContainer>
        <span>{title}</span>
      </ContentContainer>
    </Container>
  );
}
export default MRequestPageStatus;
