import { useState, useCallback } from "react";

const useSelectedItemHook = value => {
  const [selectedItem, setSelectedItem] = useState(value || "");

  const handleSelectedItem = useCallback(
    value => {
      setSelectedItem(value);
    },
    [setSelectedItem]
  );

  return { selectedItem, handleSelectedItem };
};

export default useSelectedItemHook;
