import * as React from "react";
import moment from "moment";

const useDateTime = () => {
  const [startDateTime, setStartDateTime] = React.useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const [endDateTime, setEndDateTime] = React.useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );

  const handleDateTime = React.useCallback(
    (type: string) => (value: string) => {
      if (type === "start") {
        setStartDateTime(value);
      } else {
        setEndDateTime(value);
      }
    },
    []
  );

  return { startDateTime, endDateTime, handleDateTime };
};

export default useDateTime;
