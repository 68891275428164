import styled from "styled-components";

const THead = styled.div`
  display: flex;
  white-space: nowrap;
  font-weight: bold;
  z-index: 1;
  svg {
    color: ${props => props.theme.colors.green};
  }
  font-family: sans-serif;
`;

export default THead;
