import React from "react";
import { SignInRootDiv, SignInContentsDiv, Title, GitHash } from "./styled";
import PropTypes from "prop-types";
import SignInBoxContainer from "../SignInBox/SignInBoxContainer";

const SignIn = props => {
  const { onSignIn, imgUrl } = props;
  return (
    <SignInRootDiv backgroundImg={imgUrl}>
      <SignInContentsDiv>
        <Title>A-Sonic 근무관리</Title>
        <SignInBoxContainer
          idLabel="사원 ID"
          buttonLabel="로그인"
          handleAfterLogin={onSignIn}
          color="white"
        />
      </SignInContentsDiv>
      <GitHash>{process.env.REACT_APP_GITHEAD}</GitHash>
    </SignInRootDiv>
  );
};

SignIn.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  imgUrl: PropTypes.string.isRequired
};

export default SignIn;
