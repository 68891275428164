import * as React from "react";

const useCheckHttps = () => {
  // const [isGoogle, setIsGoogle] = React.useState<boolean>(false);
  const URL = React.useMemo(() => window.location.href, []);

  React.useEffect(() => {
    if (URL) {
      if (URL.includes("localhost") || URL.includes("https")) {
        // google api 호출 방지
        // setIsGoogle(true);
      }
    }
  }, [URL]);
  return { isGoogle: false };
};

export default useCheckHttps;
