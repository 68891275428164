import { Container } from "./styled";
import { Doughnut } from "react-chartjs-2";

export type Options = {
  maintainAspectRatio: boolean;
  plugins: {
    legend: {
      display: boolean;
    };
    datalabels: any;
    tooltip: any;
    title: any;
  };
};

export type Datasets = [
  {
    data: number[];
    backgroundColor: string[];
    borderWidth?: number;
  }
];

export type Labels = string[];

export type Data = {
  datasets: Datasets;
  labels?: Labels;
};

type Props = {
  data: Data;
  options?: Options;
};

const CellChartsectionPresenter = <P extends Props>({ data, options }: P) => {
  return (
    <Container>
      <Doughnut
        data={data}
        options={{
          ...options
        }}
        width={1}
        height={120}
      />
    </Container>
  );
};

export default CellChartsectionPresenter;
