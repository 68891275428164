import { forwardRef } from "react";
import styled from "styled-components";

const MTitle = styled.span`
  display: flex;
  font-family: "Jua", sans-serif;
  min-height: 37px;
  font-size: 13.5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  :after {
    margin-left: 4px;
    content: "📅"; //your image or whateaver
    display: block;
    font-size: 14px;
    color: STEELBLUE;
    background: none;
    cursor: pointer;
  }
  margin: 0px 15px 0px 15px;
`;

const CalendarHeaderInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <MTitle onClick={onClick} ref={ref}>
    {value}
  </MTitle>
));

export default CalendarHeaderInput;
