import { useReactiveVar } from "@apollo/client";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../types/reducers";
import listOfApolloVar, { CHAT_OPEN } from "../../apollo/apollo-var";
import useSocket from "../../hooks/use-socket/use-socket";
import routes from "../../routes";
import { GetListOfChatImage_getListOfChatImage_listOfImage } from "../../__generated__/GetListOfChatImage";
import { GetUser_getUser_user } from "../../__generated__/GetUser";
import ChatMenu from "./chat-menu";
import ChatRoom from "./chat-room";
import ChatTitleBar from "./chat-title-bar";
import ChatWrite from "./chat-write";
import ListForChat from "./list-for-chat";
import ListOfChat from "./list-of-chat";
import UserInfoInChat from "./user-info-in-chat";
import UserInvitation from "./user-invitation";

export interface IDepartmentInfoForChat {
  parentDepartmentId: number;
  selectedDepartmentName: string;
}

export enum LIST_OF_CHAT_MENU {
  CHAT = "CHAT",
  GROUP = "GROUP",
  CHAT_ROOM = "CHAT_ROOM"
}

export interface IChatContext extends IDepartmentInfoForChat {
  selectedChatRoomIdx?: number;
  socket?: WebSocket;
  selectedChatRoomName?: string;
  isRoomSetting: boolean;
  isUserInvitation: boolean;
  listOfUserInRoom: GetListOfChatImage_getListOfChatImage_listOfImage[];
  isElectron: boolean;
  menu: LIST_OF_CHAT_MENU;
  totalPageForListOfChat: number;
  isScrollInChatRoom: boolean;
}

export interface IChatHandleContext {
  handleParentDepartment: (payload: IDepartmentInfoForChat) => void;
  handleSelectedUser: (user?: GetUser_getUser_user) => void;
  handleMenu: (payload: LIST_OF_CHAT_MENU) => void;
  handleSelectedChatRoom: (roomIdx: number) => void;
  handleSelectedChatRoomName: (roomName: string) => void;
  handleIsRoomSetting: (value: boolean) => void;
  handleIsUserInvitation: (value: boolean) => void;
  handleListOfUserInRoom: (
    users: GetListOfChatImage_getListOfChatImage_listOfImage[]
  ) => void;
  handleTotalPageForListOfChat: (totalPage: number) => void;
  handleIsScrollInChatRoom: (payload: boolean) => void;
}

const Container = styled.div<{
  chatOpen: CHAT_OPEN;
  isElectron: boolean;
  top: string;
  left: string;
}>`
  position: ${props => (props.isElectron ? "relative" : "fixed")};
  display: ${props => {
    if (props.chatOpen === CHAT_OPEN.OPEN) {
      return "flex";
    }
    return "none";
  }};
  flex-direction: column;
  flex: 1;
  width: ${props => {
    if (props.isElectron) {
      return;
    }
    return "400px";
  }};
  height: ${props => {
    if (props.isElectron) {
      return;
    }
    return props.chatOpen === CHAT_OPEN.OPEN ? "600px" : "200px";
  }};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.darkGrey};
  top: ${props => {
    const { chatOpen } = props;
    if (chatOpen === CHAT_OPEN.OPEN) {
      return props.top;
    }
    return "0vh";
  }};
  left: ${props => {
    const { isElectron, left } = props;
    if (isElectron) {
      return "";
    }
    return left;
  }};
  z-index: 9;
  border-radius: ${props => (props.isElectron ? "0px" : "14px")};
  opacity: ${props => {
    if (props.isElectron) {
      return "1";
    }
    return props.chatOpen === CHAT_OPEN.OPEN ? "1" : "0";
  }};
  visibility: ${props => {
    if (props.isElectron) {
      return "visible";
    }
    return props.chatOpen === CHAT_OPEN.OPEN ? "visible" : "hidden";
  }};
  transition: all 300ms ease-out;
`;

const Main = styled.main`
  display: flex;
  flex: 14;
`;

const Footer = styled.footer`
  display: flex;
  position: relative;
  flex: 2;
  border-top: 1px solid ${props => props.theme.borderColor};
`;

export const DEFAULT_PARENT_DEPARTMENT_ID: number = 0;

export const ChatContext = createContext<IChatContext | undefined>(undefined);
export const ChatHandleContext = createContext<IChatHandleContext | undefined>(
  undefined
);

export const initChatRoomName = "채팅방";

function Chat() {
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);
  const chatOpen = useReactiveVar(listOfApolloVar.chatOpenVar);
  const chatPosition = useReactiveVar(listOfApolloVar.chatPosition);
  const [menu, setMenu] = useState<LIST_OF_CHAT_MENU>(LIST_OF_CHAT_MENU.GROUP);
  const [selectedChatRoomIdx, setSelectedChatRoomIdx] = useState<number>();
  const [selectedChatRoomName, setSelectedChatRoomName] =
    useState<string>(initChatRoomName);
  const [isRoomSetting, setIsRoomSetting] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<GetUser_getUser_user>();
  const [isUserInvitation, setIsUserInvitation] = useState<boolean>(false);
  const [listOfUserInRoom, setListOfUserRoom] = useState<
    GetListOfChatImage_getListOfChatImage_listOfImage[]
  >([]);
  const [isScrollInChatRoom, setIsScrollInChatRoom] = useState<boolean>(false);

  const [totalPageForListOfChat, setTotalPageForListOfChat] =
    useState<number>(1);

  const [, dragRef] = useDrag(
    () => ({
      type: initChatRoomName,
      item: chatPosition
    }),
    [chatPosition]
  );

  const handleIsScrollInChatRoom = useCallback((payload: boolean) => {
    setIsScrollInChatRoom(payload);
  }, []);

  const handleTotalPageForListOfChat = useCallback((totalPage: number) => {
    setTotalPageForListOfChat(totalPage);
  }, []);

  const isElectron: boolean = useMemo(() => {
    return routes.pageRoutes.electronChat === window.location.pathname;
  }, []);

  const handleListOfUserInRoom = useCallback(
    (users: GetListOfChatImage_getListOfChatImage_listOfImage[]) => {
      setListOfUserRoom(users);
    },
    []
  );

  const { socket } = useSocket({
    employeeId: employee_id,
    roomIdx: selectedChatRoomIdx,
    isConnecting: menu === LIST_OF_CHAT_MENU.CHAT_ROOM
  });

  const handleIsUserInvitation = useCallback((value: boolean) => {
    setIsUserInvitation(value);
  }, []);

  const handleIsRoomSetting = useCallback((value: boolean) => {
    setIsRoomSetting(value);
  }, []);

  const handleSelectedChatRoomName = useCallback((roomName: string) => {
    setSelectedChatRoomName(roomName);
  }, []);

  const handleSelectedChatRoom = useCallback((roomIdx: number) => {
    setSelectedChatRoomIdx(roomIdx);
  }, []);

  const handleSelectedUser = useCallback((user?: GetUser_getUser_user) => {
    setSelectedUser(user);
  }, []);

  const handleMenu = useCallback((payload: LIST_OF_CHAT_MENU) => {
    setMenu(payload);
  }, []);

  const [parentDepartment, setParentDepartment] =
    useState<IDepartmentInfoForChat>({
      parentDepartmentId: DEFAULT_PARENT_DEPARTMENT_ID,
      selectedDepartmentName: "전체"
    });

  const handleParentDepartment = useCallback(
    (payload: IDepartmentInfoForChat) => {
      setParentDepartment(payload);
    },
    []
  );

  useEffect(() => {
    if (menu !== LIST_OF_CHAT_MENU.GROUP) {
      handleParentDepartment({
        parentDepartmentId: DEFAULT_PARENT_DEPARTMENT_ID,
        selectedDepartmentName: "전체"
      });
    }
  }, [menu, handleParentDepartment]);

  return (
    <ChatContext.Provider
      value={{
        ...parentDepartment,
        selectedChatRoomIdx,
        socket,
        selectedChatRoomName,
        isRoomSetting,
        isUserInvitation,
        listOfUserInRoom,
        isElectron,
        menu,
        isScrollInChatRoom,
        totalPageForListOfChat
      }}
    >
      <ChatHandleContext.Provider
        value={{
          handleParentDepartment,
          handleSelectedUser,
          handleMenu,
          handleSelectedChatRoom,
          handleSelectedChatRoomName,
          handleIsRoomSetting,
          handleIsUserInvitation,
          handleListOfUserInRoom,
          handleTotalPageForListOfChat,
          handleIsScrollInChatRoom
        }}
      >
        <Container
          chatOpen={chatOpen}
          isElectron={isElectron}
          ref={dragRef}
          top={chatPosition.top}
          left={chatPosition.left}
        >
          {isUserInvitation && <UserInvitation />}
          <ChatTitleBar
            handleParentDepartment={handleParentDepartment}
            menu={menu}
            isElectron={isElectron}
          />
          <Main>
            {menu === LIST_OF_CHAT_MENU.CHAT && <ListOfChat />}
            {menu === LIST_OF_CHAT_MENU.GROUP && (
              <ListForChat handleParentDepartment={handleParentDepartment} />
            )}
            {menu === LIST_OF_CHAT_MENU.CHAT_ROOM && <ChatRoom />}
          </Main>
          <Footer>
            {menu !== LIST_OF_CHAT_MENU.CHAT_ROOM && (
              <ChatMenu menu={menu} handleMenu={handleMenu} />
            )}
            {menu === LIST_OF_CHAT_MENU.CHAT_ROOM && <ChatWrite />}
            {selectedUser && <UserInfoInChat selectedUser={selectedUser} />}
          </Footer>
        </Container>
      </ChatHandleContext.Provider>
    </ChatContext.Provider>
  );
}

export default Chat;
