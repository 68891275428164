import { Doughnut } from "react-chartjs-2";
import { Container } from "./styled";
import moment from "moment";
import { useMemo } from "react";
import { TooltipItem, TooltipModel } from "chart.js";
import { Chart as ChartJS, Plugin } from "chart.js";
import { colors } from "../../../../GlobalStyle/GlobalStyle";
type Props = {
  chartData?: any;
  remainTime?: string;
};

const CenterTextPlugin: Plugin = {
  id: "centerText",
  afterDatasetsDraw(
    chart,
    _,
    options: {
      remainTime: string;
    }
  ) {
    const remainTime: string = options.remainTime;
    if (remainTime) {
      const {
        ctx,
        chartArea: { width, height }
      } = chart;

      ctx.font = "12px Arial";

      const mainText = "잔여시간",
        textX = width / 2,
        textY = height - 36;

      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = colors.darkGrey;
      ctx.fillText(mainText, textX, textY);
      ctx.restore();

      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.font = "15px Arial";
      ctx.fillStyle = colors.darkBlue;
      ctx.fillText(remainTime, textX, textY + 16);
      ctx.restore();
    }
  }
};

// 차트에서 사용해줄 기능 등록하기
ChartJS.register(CenterTextPlugin);

const WorkingStatusChart = <P extends Props>({ chartData, remainTime }: P) => {
  const options = useMemo(
    () => ({
      legend: false,
      cutout: "90%",
      maintainAspectRatio: false,
      cutoutPercentage: 95,
      circumference: 180,
      rotation: -90,
      plugins: {
        CenterTextPlugin,
        legend: {
          display: false
        },
        centerText: {
          remainTime
        },
        tooltip: {
          callbacks: {
            label: function (
              this: TooltipModel<"doughnut">,
              tooltipItem: TooltipItem<"doughnut">
            ) {
              const { label, parsed } = tooltipItem;
              let hours = parsed;
              if (hours < 0) {
                return `0h`;
              }
              hours = Math.floor(moment.duration(parsed, "minutes").asHours());
              const min = parsed % 60;
              return `${label}: ${hours}h ${min}m`;
            }
          }
        },
        datalabels: {
          display: false
        }
      }
    }),
    [remainTime]
  );
  return (
    <Container>
      <Doughnut data={chartData} options={options} />
    </Container>
  );
};

export default WorkingStatusChart;
