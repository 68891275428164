import * as React from "react";
import moment from "moment";

interface SelectOption {
  id: string;
  name: string;
}
interface GoogleCalendarList {
  accessRole: string;
  backgroundColor: string;
  colorId: string;
  conferenceProperties: Object;
  defaultReminders: [];
  description: string;
  etag: string;
  foregroundColor: string;
  id: string;
  kind: string;
  selected: true;
  summary: string;
  summaryOverride: string;
  timeZone: string;
}

export interface GoogleColors {
  background: string;
  foreground: string;
}

interface ListOfColor {
  [key: string]: GoogleColors;
}

export type ListOfGoogleColor = {
  calendar: ListOfColor;
  event: ListOfColor;
};
export interface GoogleEvent {
  colorId?: string;
  created: string;
  creator: { email: string; displayName: string; self: boolean };
  description?: string;
  end: { date: string; dateTime: string };
  etag: string;
  eventType: string;
  htmlLink: string;
  iCalUID: string;
  id: string;
  kind: string;
  location?: string;
  organizer: { email: string; displayName: string; self: boolean };
  sequence: number;
  start: { date: string; dateTime: string };
  status: string;
  summary: string;
  transparency: string;
  updated: string;
  visibility: string;
  recurrence?: string[];
  reminders: {
    useDefault: boolean;
    overrides?: { method: string; minutes: number }[];
  };
}
export interface SerializeItem {
  data: SelectOption;
  origin: GoogleCalendarList;
  isChecked: boolean;
}

interface IProps {
  isLogin: boolean;
  currentMoment: string;
}

const useGoogleCalendar = <P extends IProps>({ isLogin, currentMoment }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [isRequestingData, setIsRequestingData] = React.useState<boolean>(true);
  const [googleCalendarlist, setGoogleCalendarList] = React.useState<
    SerializeItem[]
  >([]);
  const [listOfGoogleColor, setListOfGoogleColor] =
    React.useState<ListOfGoogleColor>({ calendar: {}, event: {} });

  const handleListOfCalendarStatus = React.useCallback(list => {
    const getedListOfCalendarStatus = localStorage.getItem(
      "listOfCalendarStatus"
    );
    if (getedListOfCalendarStatus) {
      const newList = { ...list };
      const parsedList = JSON.parse(getedListOfCalendarStatus);
      for (const item in parsedList) {
        if (newList[item]) {
          newList[item] = parsedList[item];
        }
      }
      localStorage.setItem("listOfCalendarStatus", JSON.stringify(newList));
      return newList;
    } else {
      localStorage.setItem("listOfCalendarStatus", JSON.stringify(list));
      return list;
    }
  }, []);

  const checkListOfCalendarStatus = React.useCallback((key: string) => {
    const getListOfCalendarKeyFromLocalstorage = localStorage.getItem(
      "listOfCalendarStatus"
    );
    if (getListOfCalendarKeyFromLocalstorage) {
      const serializationList = JSON.parse(
        getListOfCalendarKeyFromLocalstorage
      );
      const value = serializationList[key];
      if (value !== undefined) {
        return value;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const handleIsRequestData = React.useCallback((value: boolean) => {
    setIsRequestingData(value);
  }, []);

  const timeMaxAndMin = React.useMemo(() => {
    const timeMin = moment(currentMoment, "YYYY-MM")
      .startOf("month")
      .startOf("isoWeek")
      .format();
    const timeMax = moment(currentMoment, "YYYY-MM")
      .endOf("month")
      .endOf("isoWeek")
      .format();
    return {
      timeMin,
      timeMax
    };
  }, [currentMoment]);

  const [googleEventOfList, setGoogleEventOfList] = React.useState<
    GoogleEvent[]
  >([]);

  const getCalendarList = React.useCallback(async () => {
    try {
      const {
        result: { items }
      } = await window.gapi.client.calendar.calendarList.list({});
      if (items?.length > 0) {
        const newCalendarListOfKey: { [key: string]: boolean } = {};
        const serializeItem: SerializeItem[] = items.map(
          (item: GoogleCalendarList) => {
            newCalendarListOfKey[item.id] = true;
            return {
              data: {
                id: item.id,
                name: item.summary
              },
              origine: item,
              isChecked: checkListOfCalendarStatus(item.id)
            };
          }
        );
        handleListOfCalendarStatus(newCalendarListOfKey);
        setGoogleCalendarList(serializeItem);
      }
    } catch (error) {
      const {
        result: {
          error: { message }
        }
      } = error;
      console.log(message);
    } finally {
      handleIsRequestData(false);
    }
  }, [
    handleIsRequestData,
    handleListOfCalendarStatus,
    checkListOfCalendarStatus
  ]);

  const handleCalendarList = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (googleCalendarlist.length > 0) {
        const newList = googleCalendarlist.map(item => {
          if (item.data.id === event.target.id) {
            const getListOfCalendarKeyFromLocalstorage = localStorage.getItem(
              "listOfCalendarStatus"
            );
            if (getListOfCalendarKeyFromLocalstorage) {
              let parseList = JSON.parse(getListOfCalendarKeyFromLocalstorage);
              if (parseList[item.data.id] !== undefined) {
                parseList[item.data.id] = !item.isChecked;
                localStorage.setItem(
                  "listOfCalendarStatus",
                  JSON.stringify(parseList)
                );
              }
            }
            const newItem = {
              ...item,
              isChecked: !item.isChecked
            };
            return newItem;
          } else {
            return item;
          }
        });
        setGoogleCalendarList(newList);
      }
    },
    [googleCalendarlist]
  );

  const getCalendarEvents = React.useCallback(() => {
    if (googleCalendarlist?.length > 0) {
      const newEventResultPromise: Array<Promise<any>> = [];
      googleCalendarlist.forEach((item, index) => {
        if (item.isChecked) {
          if (isMount.current) {
            let result = window.gapi.client.calendar.events.list({
              calendarId: `${item.data.id}`,
              timeMax: timeMaxAndMin.timeMax,
              timeMin: timeMaxAndMin.timeMin
            });
            newEventResultPromise.push(result);
          }
        }
      });

      Promise.all(newEventResultPromise)
        .then(results => {
          if (isMount.current) {
            let newEventOfList: GoogleEvent[] = [];
            results.forEach(event => {
              newEventOfList.push(...event.result.items);
            });
            setGoogleEventOfList(newEventOfList);
          }
        })
        .catch(error => console.log(error.message));
    }
  }, [googleCalendarlist, timeMaxAndMin]);

  const getListOfGoogleCalendarColor = React.useCallback(async () => {
    try {
      const {
        result: { calendar, event }
      } = await window.gapi.client.calendar.colors.get({});
      setListOfGoogleColor({ calendar, event });
    } catch (error) {
      console.log(error);
      return { calendar: [], events: [] };
    }
  }, []);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    getCalendarEvents();
    return () => {
      isMount.current = false;
    };
  }, [getCalendarEvents]);

  React.useEffect(() => {
    if (isLogin && isRequestingData) {
      getCalendarList();
      getListOfGoogleCalendarColor();
    }
  }, [
    getCalendarList,
    isLogin,
    getListOfGoogleCalendarColor,
    isRequestingData
  ]);

  return {
    googleCalendarlist,
    getCalendarList,
    handleCalendarList,
    googleEventOfList,
    listOfGoogleColor,
    handleIsRequestData
  };
};

export default useGoogleCalendar;
