import { Fragment } from "react";
import RightMenuButton from "../RightMenuButton";
interface IProps {
  workingTemplate: any[];
  selectTemplate: (temp: any) => () => void;
}

const WorkingTemplateManagementMenu = <P extends IProps>({
  workingTemplate,
  selectTemplate
}: P) => {
  return (
    <Fragment>
      <RightMenuButton
        title="근무관리"
        list={workingTemplate}
        listHandleClick={selectTemplate}
      />
    </Fragment>
  );
};

export default WorkingTemplateManagementMenu;
