import { bindActionCreators } from "redux";
import { categorySelectionActionCreator } from "../../../redux/modules/main-view/categorySelectionReducer";
import MobileCategorySelectionView from "../../../moComponents/main-view/smallComponents/MobileCategorySelectionView";
import { Reducers, CategorySelectionReducer } from "../../../../types/reducers";
import { useDispatch, useSelector } from "react-redux";

interface IProps {
  updateCategoryId?: () => void;
  isCategory: boolean;
  updateRequestResult?: () => void;
  categoryId?: string;
  requestResult?: string;
  isRequestDialogPage: boolean;
}

const CategorySelectionContainer = <P extends IProps>(props: P) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      ...categorySelectionActionCreator
    },
    dispatch
  );

  const categorySelectionReducer: CategorySelectionReducer = useSelector(
    (state: Reducers) => state.categorySelectionReducer
  );
  return (
    <MobileCategorySelectionView
      {...props}
      categorySelectionReducer={categorySelectionReducer}
      {...actions}
    />
  );
};

export default CategorySelectionContainer;
