import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MobileApprobalView from "../../../moComponents/main-view/MobileApprovalView";
import { approvalActionCreator } from "../../../redux/modules/main-view/approval/approvalReducer";

const mapStateToProps = state => ({
  approvalReducer: state.approvalReducer,
  categorySelectionReducer: state.categorySelectionReducer,
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...approvalActionCreator
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileApprobalView);
