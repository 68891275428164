import { useCallback, useEffect, useState } from "react";
import { MessageTypes } from "../../components/toast-message/toast-message";

const useOpenToastMessage = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [toastMessageType, setToastMessageType] = useState<MessageTypes>(
    MessageTypes.INFO
  );

  const handleToastMessageType = useCallback((value: MessageTypes) => {
    setToastMessageType(value);
  }, []);

  const handleIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  const handleMessage = useCallback((value: string) => {
    // 이미 열려있는 상태면 메시지를 변경하지 않음
    if (isOpen === false) {
      setMessage(value);
    }
  }, []);

  const handleToast = useCallback(
    (message: string, type: MessageTypes) => {
      handleMessage(message);
      handleToastMessageType(type);
      handleIsOpen(true);
    },
    [handleMessage, handleIsOpen, handleToastMessageType]
  );

  useEffect(() => {
    let timeId: NodeJS.Timeout;
    if (isOpen) {
      timeId = setTimeout(() => {
        handleIsOpen(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [isOpen, handleIsOpen]);

  return {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType,
    handleToast
  };
};

export default useOpenToastMessage;
