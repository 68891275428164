export enum WorkHourTypeForTable {
  DAY = 1,
  WEEK = 2
}

export enum ConfirmType {
  ALARM_POPUP = "ALARM_POPUP",
  ADD = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}
