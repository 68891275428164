import * as React from "react";

const SUPER_ADMIN = 1;

const useCheckAdmin = () => {
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false);

  const isMount = React.useRef<boolean>(false);
  const handleIsSuperAdmin = React.useCallback((value: boolean) => {
    setIsSuperAdmin(value);
  }, []);

  const handleAfterLogin = React.useCallback(
    auth => {
      if (auth === SUPER_ADMIN && isMount.current && handleIsSuperAdmin) {
        handleIsSuperAdmin(true);
      }
    },
    [handleIsSuperAdmin]
  );

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    return () => {
      isMount.current = false;
    };
  }, []);

  return { handleAfterLogin, isSuperAdmin };
};

export default useCheckAdmin;
