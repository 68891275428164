import axios from "axios";
import { WEB_SERVER_ADDR } from "../constValues";
import Auth from "../Auth/Auth";
import routes from "../routes";

const changeWorkingTemplateApi = axios.create({
  baseURL: `${WEB_SERVER_ADDR}`,
  headers: {
    token: Auth.getUserToken()
  }
});

export const updateDepartmentWorkingTmplate = (departmentIds, workTempIdx) =>
  changeWorkingTemplateApi.post(`/api/settings/departments/workingtemplate`, {
    departmentIds,
    workTempIdx
  });

export const updateUserWorkingTemplate = (employeeIds, workTempIdx) =>
  changeWorkingTemplateApi.post(routes.apiRoutes.settings.workingTemplate, {
    employeeIds,
    workTempIdx
  });

export const updatePcWorkingTemplate = (endpointIds, workTempIdx) =>
  changeWorkingTemplateApi.post(
    routes.apiRoutes.settings.endpointsWorkingTemplate,
    {
      endpointIds,
      workTempIdx
    }
  );
