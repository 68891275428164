import api from "../api";

const CALENDAR = `${api.api}/calendar`;
const CALENDAR_WEEKLY_TOTAL = `${CALENDAR}/weekly-total`;

export const calendarApi = {
  calendar: CALENDAR,
  weeklyTotal: CALENDAR_WEEKLY_TOTAL
};

export default calendarApi;
