import styled from "styled-components";

export const RootDiv = styled.div`
  height: calc(100% - 46px);
  width: 100%;
  background-color: #f7f3ee;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const MobileRootDiv = styled.div`
  @media screen and (max-width: 1024px) {
    display: block;
  }
  display: none;
  height: 100%;
  width: 100%;
`;
