import { memo, forwardRef } from "react";
import { ItemList } from "./styled";

const List = forwardRef(
  (
    {
      treeData,
      drawTreeFromData,
      fullDepartmentNameList,
      searchedDe,
      searchSelectedIndex,
      selectedTreeItem,
      setSelectedTreeItem
    },
    ref
  ) => {
    const root = 0;
    return (
      <ItemList isOpened={true} ref={ref}>
        {treeData &&
          drawTreeFromData(
            treeData,
            root,
            fullDepartmentNameList,
            searchedDe,
            searchSelectedIndex,
            selectedTreeItem,
            setSelectedTreeItem
          )}
      </ItemList>
    );
  }
);

const reRender = (currProps, nextProps) => {
  const { treeData, searchedDe, searchSelectedIndex, selectedTreeItem } =
    currProps;
  const {
    treeData: treeDataN,
    searchedDe: searchedDeN,
    searchSelectedIndex: searchSelectedIndexN,
    selectedTreeItem: selectedTreeItemN
  } = nextProps;
  if (
    treeData !== treeDataN ||
    searchedDe !== searchedDeN ||
    searchSelectedIndex !== searchSelectedIndexN ||
    selectedTreeItem !== selectedTreeItemN
  ) {
    return false;
  } else {
    return true;
  }
};

export default memo(List, reRender);
