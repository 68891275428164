import api from "../api";

const OVER_TIME_CATEGORIES = `${api.api}/overtimecategories`;
const CATEGORIES = `${OVER_TIME_CATEGORIES}/categories`;

const overTimeCategoriesApi = {
  categories: CATEGORIES
};

export default overTimeCategoriesApi;
