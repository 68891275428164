import styled from "styled-components";
import Button from "../../../../../globalComponents/Button";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import {
  PlaceFreeManagementForm,
  PlaceFreeManagementFormVariables,
  PlaceFreeManagementForm_placeFreeManagementForm_listOfFormType
} from "../../../../../../__generated__/PlaceFreeManagementForm";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  UpdatePlaceFreeManagement,
  UpdatePlaceFreeManagementVariables
} from "../../../../../../__generated__/UpdatePlaceFreeManagement";
import { useCallback } from "react";
import { MessageTypes } from "../../../../../toast-message/toast-message";
import AsonicDialog from "../../../../../asonic-dialog/asonic-dialog";

interface IFormValues {
  categoryId: string;
  startDateTime: string;
  endDateTime: string;
  logPlace: string;
  logAttendee: string | null;
  logDesc: string | null;
}

type Props = {
  employeeId: string;
  handleOpenDialog: (value: boolean) => void;
  leftSeatLogIdx?: string;
  handleToastMessageType: (value: MessageTypes) => void;
  handleMessage: (value: string) => void;
  handleIsOpenToast: (value: boolean) => void;
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 14;
  padding: 10px;
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Section = styled.section`
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 4px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.h3`
  font-weight: bold;
`;

const Label = styled.label`
  display: flex;
  flex: 1;
  min-width: max-content;
  justify-content: flex-end;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

const Input = styled.input`
  display: flex;
  flex: 4;
`;

const Select = styled.select`
  display: flex;
  flex: 4;
`;

const Option = styled.option``;

const Textarea = styled.textarea`
  display: flex;
  flex: 4;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

const QUERY_PLACE_FREE_MANAGEMENT_FORM = gql`
  query PlaceFreeManagementForm($leftSeatLogIdx: String!) {
    placeFreeManagementForm(leftSeatLogIdx: $leftSeatLogIdx) {
      ok
      error
      listOfFormType {
        categoryId
        categoryName
      }
      formData {
        leftSeatLogIdx
        startDateTime
        endDateTime
        categoryId
        logPlace
        logAttendee
        logDesc
      }
    }
  }
`;

const MUTATION_UPDATE_PlACE_FREE_MANAGEMENT = gql`
  mutation UpdatePlaceFreeManagement(
    $leftSeatLogIdx: Float!
    $employeeId: String!
    $startDateTime: String!
    $endDateTime: String!
    $logPlace: String!
    $categoryId: Float!
    $logAttendee: String
    $logDesc: String
  ) {
    updatePlaceFreeManagement(
      leftSeatLogIdx: $leftSeatLogIdx
      employeeId: $employeeId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      logPlace: $logPlace
      categoryId: $categoryId
      logAttendee: $logAttendee
      logDesc: $logDesc
    ) {
      ok
      error
    }
  }
`;

const PFMForm = <P extends Props>({
  employeeId,
  handleOpenDialog,
  leftSeatLogIdx,
  handleToastMessageType,
  handleMessage,
  handleIsOpenToast
}: P) => {
  const { register, setValue, handleSubmit } = useForm<IFormValues>({});

  const { data } = useQuery<
    PlaceFreeManagementForm,
    PlaceFreeManagementFormVariables
  >(QUERY_PLACE_FREE_MANAGEMENT_FORM, {
    onCompleted(data) {
      if (
        data.placeFreeManagementForm.ok &&
        data.placeFreeManagementForm.formData
      ) {
        setValue(
          "startDateTime",
          moment(
            data.placeFreeManagementForm.formData.startDateTime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD[T]HH:mm")
        );
        setValue(
          "endDateTime",
          moment(
            data.placeFreeManagementForm.formData.endDateTime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD[T]HH:mm")
        );
        setValue(
          "categoryId",
          data.placeFreeManagementForm.formData.categoryId.toString()
        );
        setValue("logPlace", data.placeFreeManagementForm.formData.logPlace);
        setValue("logDesc", data.placeFreeManagementForm.formData.logDesc);
        setValue(
          "logAttendee",
          data.placeFreeManagementForm.formData.logAttendee
        );
      }
    },
    variables: {
      leftSeatLogIdx: leftSeatLogIdx ?? ""
    }
  });

  const [updatePlaceFreeManagement] = useMutation<
    UpdatePlaceFreeManagement,
    UpdatePlaceFreeManagementVariables
  >(MUTATION_UPDATE_PlACE_FREE_MANAGEMENT);

  const handleUpdatePlaceFreeManagement = useCallback(
    (payload: IFormValues) => {
      let categoryObj:
        | PlaceFreeManagementForm_placeFreeManagementForm_listOfFormType
        | undefined;
      if (data?.placeFreeManagementForm.listOfFormType) {
        categoryObj = data?.placeFreeManagementForm.listOfFormType.find(
          item => item.categoryId.toString() === payload.categoryId
        );
      }
      updatePlaceFreeManagement({
        update(cache, { data: result }) {
          const toastMessage =
            result?.updatePlaceFreeManagement.error ??
            "이석관리정보를 업데이트 했습니다.";
          if (result?.updatePlaceFreeManagement.ok) {
            handleToastMessageType(MessageTypes.SUCCESS);
            handleMessage(toastMessage);
            cache.modify({
              id: `PlaceFreeManagement:${leftSeatLogIdx}`,
              fields: {
                startDateTimeModify() {
                  return moment(
                    payload.startDateTime,
                    "YYYY-MM-DD[T]HH:mm"
                  ).format("YYYY-MM-DD HH:mm:ss");
                },
                endDateTimeModify() {
                  return moment(
                    payload.endDateTime,
                    "YYYY-MM-DD[T]HH:mm"
                  ).format("YYYY-MM-DD HH:mm:ss");
                },
                logPlaceModify() {
                  return payload.logPlace;
                },
                logAttendeeModify() {
                  return payload.logAttendee;
                },
                logDescModify() {
                  return payload.logDesc;
                },
                categoryNameModify() {
                  return categoryObj?.categoryName;
                }
              }
            });
          } else if (!result?.updatePlaceFreeManagement.ok) {
            handleToastMessageType(MessageTypes.ERROR);
            handleMessage(toastMessage);
          }
          handleIsOpenToast(true);
        },
        variables: {
          employeeId,
          leftSeatLogIdx: parseInt(leftSeatLogIdx ?? ""),
          startDateTime: payload.startDateTime,
          endDateTime: payload.endDateTime,
          logPlace: payload.logPlace,
          logAttendee: payload.logAttendee,
          logDesc: payload.logDesc,
          categoryId: parseInt(payload.categoryId)
        }
      });
      handleOpenDialog(false);
    },
    [
      employeeId,
      updatePlaceFreeManagement,
      handleOpenDialog,
      leftSeatLogIdx,
      data?.placeFreeManagementForm.listOfFormType,
      handleIsOpenToast,
      handleMessage,
      handleToastMessageType
    ]
  );

  return (
    <AsonicDialog
      title="자리비움(이석)정보 변경"
      width="440px"
      minHeight="440px"
      height="440px"
      handleClose={handleOpenDialog}
    >
      <Container
        data-testid="pfm-form"
        onSubmit={handleSubmit(handleUpdatePlaceFreeManagement)}
      >
        <MainContainer>
          <Section data-testid="date">
            <TitleContainer>
              <Title>일시</Title>
            </TitleContainer>
            <ContentContainer data-testid="start-time">
              <Label>이석시작:</Label>
              <Input type="datetime-local" {...register("startDateTime")} />
            </ContentContainer>
            <ContentContainer data-testid="end-time">
              <Label>이석종료:</Label>
              <Input type="datetime-local" {...register("endDateTime")} />
            </ContentContainer>
          </Section>
          <Section data-testid="info">
            <TitleContainer>
              <Title>정보</Title>
            </TitleContainer>
            <ContentContainer data-testid="type">
              <Label>사유구분:</Label>
              <Select {...register("categoryId")}>
                {data?.placeFreeManagementForm.listOfFormType?.map(item => (
                  <Option
                    value={item.categoryId}
                    key={`${item.categoryId} ${item.categoryName}`}
                  >
                    {item.categoryName}
                  </Option>
                ))}
              </Select>
            </ContentContainer>
            <ContentContainer data-testid="place">
              <Label>* 이동장소:</Label>
              <Input type="text" {...register("logPlace")} />
            </ContentContainer>
            <ContentContainer data-testid="attendee">
              <Label>* 참여자:</Label>
              <Input type="text" {...register("logAttendee")} />
            </ContentContainer>
          </Section>
          <Section data-testid="reason">
            <ContentContainer data-testid="reason-textarea">
              <Label>사유</Label>
              <Textarea {...register("logDesc")} />
            </ContentContainer>
          </Section>
          <ButtonContainer>
            <Button>확인</Button>
          </ButtonContainer>
        </MainContainer>
      </Container>
    </AsonicDialog>
  );
};

export default PFMForm;
