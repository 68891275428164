import routes from "../../../routes";
import API from "../../api";

type Params = {
  employeeId: string;
};

type UpdateCommutingParams = {
  type: typeof UPDATE_START | typeof UPDATE_END;
} & Params;

const UPDATE_START = 1,
  UPDATE_END = 2;

const getCommuting = (params: Params) =>
  API().get(routes.apiRoutes.electron.serviceStatusRoute.commuting, {
    params
  });

const updateCommuting = (params: UpdateCommutingParams) =>
  API().post(routes.apiRoutes.electron.serviceStatusRoute.commuting, params);

const getWorkingHour = (params: Params) =>
  API().get(routes.apiRoutes.electron.serviceStatusRoute.workingHour, {
    params
  });

const getTotalWorkingHour = (params: Params) =>
  API().get(routes.apiRoutes.electron.serviceStatusRoute.totalWorkingHour, {
    params
  });

const weeklyTotalWorkingHour = (params: Params) =>
  API().get(
    routes.apiRoutes.electron.serviceStatusRoute.weeklyTotalWorkingHour,
    {
      params
    }
  );

  const serviceStatusApi = {
    getCommuting,
    updateCommuting,
    getWorkingHour,
    getTotalWorkingHour,
    weeklyTotalWorkingHour
  };
  
  
export default serviceStatusApi