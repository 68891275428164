import styled from "styled-components";
import basicImg from "../../../../images/basicimage.jpg";

export const ProfileDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.frameGrey};
  height: 80px;
`;

export const UserInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const UserPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "007ACC";
  border-radius: 50%;
  background-size: cover;
  width: ${props => (props.open ? "68px" : "35px")};
  height: ${props => (props.open ? "68px" : "35px")};
  margin: 10px;
  background-image: ${props =>
    props.image
      ? `url( data:image/png+jpg;base64,${props.image})`
      : `url(${basicImg})`};
`;

export const Title = styled.span`
  color: white;
  font-size: 15px;
  font-weight: 500;
`;

export const SubTitle = styled.span`
  color: white;
`;
