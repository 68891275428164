import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

export interface ITextInputProps extends UseFormRegisterReturn {
  title: string;
  type?: string;
  minWidth?: string;
  labelWidth?: string;
  labelTextAlign?: string;
  id?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const Input = styled.input<{ minWidth?: string }>`
  display: flex;
  flex: 6;
  min-width: ${props => props.minWidth || ""};
`;

const Label = styled.label<{ labelWidth?: string; labelTextAlign?: string }>`
  font-size: 12px;
  color: ${props => props.theme.colors.darkGrey};
  flex: 2;
  min-width: max-content;
`;

function CTextInputWithLabel(props: ITextInputProps, ref: any) {
  return (
    <Container>
      <span style={{ visibility: props.required ? "visible" : "hidden" }}>
        *
      </span>
      <Label htmlFor={props.title} labelWidth={props.labelWidth}>
        {props.title}
      </Label>
      <Input
        placeholder={props.title}
        {...props}
        ref={ref}
        id={props.id || props.title}
        type={props.type ?? "text"}
        minWidth={props.minWidth}
      />
    </Container>
  );
}

const TextInputWithLabel = forwardRef(CTextInputWithLabel);
export default TextInputWithLabel;
