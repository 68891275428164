import { AutoSizer } from "react-virtualized";
import styled from "styled-components";
import * as ReactTable from "react-table";
import CompactTableRenderRow, {
  ICompactTableRowProps,
  ICompactRow
} from "./compact-table-render-row";
import CompactTableColumn, { TMoveColumn } from "./compact-table-column";
import { FieldSort } from "../../generated/graphql";
import { FixedSizeList as List } from "react-window";
import SubTitle from "../shared/sub-title/sub-title";

interface ICompactTHeaderGroup<P extends {}>
  extends ReactTable.HeaderGroup<P extends object ? P : {}> {}

interface ICompactHeaderGroups<P extends {}> extends ICompactTHeaderGroup<P> {}

interface IProps<P extends object> extends ICompactTableRowProps<P> {
  title: string;
  subTitle?: string;
  headerGroups: ICompactHeaderGroups<P>[];
  getTableProps: (
    propGetter?:
      | Partial<ReactTable.TableProps>
      | Partial<ReactTable.TableProps>[]
      | ((
          props: Partial<ReactTable.TableProps>,
          meta: ReactTable.MetaBase<any>
        ) => Partial<any> | Partial<any>[])
      | undefined
  ) => ReactTable.TableProps;
  getTableBodyProps: () => ReactTable.TableBodyProps;
  rows: ICompactRow<P>[];
  isLoading?: boolean;
  listOfColumnDisabled?: string[];
  isTitleBar?: boolean;
  itemSize?: number;
  fieldSort?: FieldSort;
  moveColumn?: TMoveColumn;
  handleFieldSort?: (payload?: FieldSort) => void;
}

const Container = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  border-spacing: 0;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const CompactTableHead = styled.div`
  display: flex;
  white-space: nowrap;
  background-color: ${props => props.theme.colors.green};
  color: white;
  font-size: 14px;
  svg {
    color: ${props => props.theme.colors.green};
  }
`;

const CompactTableHeaderRow = styled.div`
  display: flex;
  flex: 1;
`;

const TableBody = styled.div`
  display: flex;
  flex: 1;
`;

const TableTitle = styled.div`
  display: flex;
  padding: 10px;
  border-top: 2px solid ${props => props.theme.colors.frameGrey};
  justify-content: space-between;
`;

function CompactTable<P extends object>({
  title,
  subTitle,
  headerGroups,
  getTableProps,
  getTableBodyProps,
  rows,
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  fieldSort,
  handleFieldSort,
  listOfColumnDisabled,
  moveColumn,
  itemSize = 30
}: IProps<P>) {
  return (
    <Container {...getTableProps()}>
      {subTitle && (
        <TableTitle>
          <SubTitle title={subTitle} />
        </TableTitle>
      )}
      <CompactTableHead>
        {headerGroups.map(headerGroup => (
          <CompactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <CompactTableColumn
                key={column.id}
                column={column}
                index={index}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                listOfColumnDisabled={listOfColumnDisabled}
                moveColumn={moveColumn}
                title={title}
              />
            ))}
          </CompactTableHeaderRow>
        ))}
      </CompactTableHead>
      <TableBody {...getTableBodyProps()}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                height={height}
                itemCount={rows.length}
                itemData={rows}
                itemSize={itemSize}
                width={width}
              >
                {CompactTableRenderRow({
                  prepareRow: prepareRow,
                  handleSelectRow: handleSelectRow,
                  handleContextMenu: handleContextMenu,
                  selectedRow: selectedRow
                })}
              </List>
            );
          }}
        </AutoSizer>
      </TableBody>
    </Container>
  );
}

export default CompactTable;
