import { Props as ContainerProps } from "./asonic-calendar-week-container";
import { Container } from "./styled";
import AsonicCalendarCell from "../asonic-calendar-cell";

type Props = {
  newWeek: Partial<DayInfo>[];
} & ContainerProps;

const AsonicCalendarWeekPresenter = ({ newWeek, week }: Props) => {
  return (
    <Container>
      {newWeek.map((item, index) => (
        <AsonicCalendarCell key={`${index}-${item.fullDate}`} {...item} />
      ))}
    </Container>
  );
};

export default AsonicCalendarWeekPresenter;
