import { Container, Icon } from "./styled";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
type Props = {
  goCalendar: () => void;
};

const AsonicCalendarPresenter = <P extends Props>({ goCalendar }: P) => {
  return (
    <Container>
      <ReactTooltip type="success" />
      <Icon
        onClick={goCalendar}
        icon={faCalendarAlt}
        size="lg"
        data-tip="달력"
      />
    </Container>
  );
};

export default AsonicCalendarPresenter;
