import { bindActionCreators } from "redux";
import MobileRequestDialogView from "../../../moComponents/main-view/smallComponents/MobileRequestDialogView";
import { requestActionCreator } from "../../../redux/modules/main-view/request/requestReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  Reducers,
  RequestReducer,
  SignInReducer
} from "../../../../types/reducers";

interface IProps {}

const RequestDialogContainer = <P extends IProps>(props: P) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      ...requestActionCreator
    },
    dispatch
  );
  const requestReducer: RequestReducer = useSelector(
    (state: Reducers) => state.requestReducer
  );
  const signInReducer: SignInReducer = useSelector(
    (state: Reducers) => state.signInReducer
  );
  return (
    <MobileRequestDialogView
      {...props}
      requestReducer={requestReducer}
      signInReducer={signInReducer}
      {...actions}
    />
  );
};

export default RequestDialogContainer;
