import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IconProps {
  customcolor: string;
}

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 255, 255, 0.3);
`;

export const InputContainer = styled.div`
  border-bottom: solid black 1px;
  margin-bottom: 10px;
`;

export const InputLabel = styled.label<IconProps>`
  font-size: 12px;
  color: ${(props: IconProps) => props.customcolor};
`;

export const TextContainer = styled.div`
  padding-bottom: 10px;
`;

export const Input = styled.input`
  background-color: transparent;
  font-size: 16px;
  border: none;
  outline: none;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${(props: IconProps) => props.customcolor};
  padding: 10px 10px 0 0;
`;
