import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Settings from "../../../components/main-view/settings/Settings";
import { signInActionCreator } from "../../../redux/modules/sign-in/signInReducer";

const mapStateToProps = state => ({
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...signInActionCreator
    },
    dispatch
  )
});

const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);

export default SettingsContainer;
