import styled from "styled-components";

const AsonicTableHead = styled.div`
  display: flex;
  white-space: nowrap;
  font-weight: bold;
  background-color: ${props => props.theme.colors.lightGrey};
  border-top: 2px solid ${props => props.theme.colors.darkGrey};
  font-weight: bold;
  svg {
    color: ${props => props.theme.colors.green};
  }
  font-family: sans-serif;
`;

export default AsonicTableHead;
