import routes from "../routes";
import API from "./API";

type Params = {
  employeeId: string;
  startDate: string;
  endDate: string;
};

export const getCalendar = (params: Params) =>
  API().get(routes.apiRoutes.calendar.calendar, { params });

export const getCalendarWeekelyTotal = (params: Params) =>
  API().get(routes.apiRoutes.calendar.weeklyTotal, { params });
