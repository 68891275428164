import * as React from "react";
import { bindActionCreators } from "redux";
import MobileTableContents from "../../../moComponents/main-view/smallComponents/MobileTableContents";
import { approvalActionCreator } from "../../../redux/modules/main-view/approval/approvalReducer";
import { requestActionCreator } from "../../../redux/modules/main-view/request/requestReducer";
import {
  Reducers,
  ApprovalReducer,
  RequestReducer,
  CategorySelectionReducer,
  ApprovalSearchResult
} from "../../../../types/reducers";
import { useDispatch, useSelector } from "react-redux";

interface IProps {
  data?: ApprovalSearchResult[];
  pageType: string;
  handleOpenDialog?: any;
  getDataWhenScrolling: (bottomPosition: number, clientHeight: number) => void;
}

const MobileContentsContainer = <P extends IProps>(props: P) => {
  const dispatch = useDispatch();
  const actions = {
    ...bindActionCreators(
      {
        ...approvalActionCreator,
        ...requestActionCreator
      },
      dispatch
    )
  };
  const approvalReducer: ApprovalReducer = useSelector(
    (state: Reducers) => state.approvalReducer
  );
  const requestReducer: RequestReducer = useSelector(
    (state: Reducers) => state.requestReducer
  );
  const categorySelectionReducer: CategorySelectionReducer = useSelector(
    (state: Reducers) => state.categorySelectionReducer
  );
  return (
    <MobileTableContents
      {...props}
      approvalReducer={approvalReducer}
      requestReducer={requestReducer}
      categorySelectionReducer={categorySelectionReducer}
      {...actions}
    />
  );
};

export default MobileContentsContainer;
