import axios from "axios";
import Auth from "../../../Auth/Auth";
import { WEB_SERVER_ADDR } from "../../../constValues";

export async function getRequestSearchDocs(searchArgs) {
  let restUri = `${WEB_SERVER_ADDR}/api/request/searchrequestdocs`;

  const {
    startDate,
    endDate,
    categoryId,
    employeeId,
    resultValue,
    limit,
    offset
  } = searchArgs;
  try {
    const response = await axios.post(
      restUri,
      {
        employeeId,
        overtimeCategoryId: categoryId,
        searchStartDate: startDate,
        searchEndDate: endDate,
        resultValue,
        limit,
        offset
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    );
    if (!response.data) {
      return [];
    }
    return response.data;
  } catch (err) {
    console.warn(err);
  }
  return [];
}

export async function makeNewRequest(args) {
  let restUri = `${WEB_SERVER_ADDR}/api/request/setalterworktime`;
  try {
    const response = await axios.post(
      restUri,
      {
        ...args
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    );
    if (!response.data) {
      return false;
    }
    return true;
  } catch (err) {
    console.warn(err);
    if (err.response.status === 500) {
      return 500;
    }
    return false;
  }
}
