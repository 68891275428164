import { useState, useEffect } from "react";

const useIsRightMenuState = () => {
  const [isRightMenu, setIsRightMenu] = useState(false);

  const handleRightMenut = event => {
    if (event.target.innerText !== "근무관리") {
      setIsRightMenu(false);
    } else if (event.target.innerText === "근무관리" && event.which === 3) {
      setIsRightMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleRightMenut);
    return () => {
      document.removeEventListener("mouseup", handleRightMenut);
    };
  }, []);

  return { isRightMenu, setIsRightMenu };
};

export default useIsRightMenuState;
