import styled from "styled-components";
import AsonicTable from "../../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo } from "react";
import { IAsonicRow } from "../../../asonic-table/asonic-render-row";
import {
  Allowed_Flag_Type,
  SubTokenInfoEntity,
  useDeleteSubTokenInfoMutation,
  useGetSubTokenInfoLazyQuery,
  useUpdateSubTokenAllowedFlagMutation
} from "../../../../generated/graphql";
import { Cell } from "../../../../../types/@react-table/react-table/react-table";
import { Icon } from "@iconify/react";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";

const COLUMN_FOR_VALUE = {
  employeeId: "사번",
  name: "이름",
  departmentName: "부서",
  tokenField: "토큰",
  allowFlag: "허용",
  deleteButton: "삭제"
} as const;

type COLUMN_FOR_VALUE =
  (typeof COLUMN_FOR_VALUE)[keyof typeof COLUMN_FOR_VALUE];

interface ISubTokenInfoEntity extends SubTokenInfoEntity {
  deleteButton?: string;
}
interface IProps {
  selectedMainTokenField?: string;
}

const Container = styled.div`
  display: flex;
  flex: 2;
`;

const Input = styled.input``;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
    color: ${props => props.theme.colors.tomato};
    :hover {
      color: ${props => props.theme.colors.darkRed};
    }
    :active {
      transform: scale(0.9);
    }
  }
`;

function SubTokenTable({ selectedMainTokenField }: IProps) {
  const [getSubTokenInfo, { data, loading }] = useGetSubTokenInfoLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {}
  });

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const [updateSubTokenAllowedFlag, { client }] =
    useUpdateSubTokenAllowedFlagMutation({
      onError(error) {
        console.log(error);
        handleToast(
          "성공적으로 토큰의 개인 설정을 수정 하였습니다.",
          MessageTypes.SUCCESS
        );
      },
      update(_, { data }) {
        if (data?.updateSubTokenAllowedFlag.ok) {
          client.resetStore();
          handleToast(
            "성공적으로 토큰의 개인 설정을 수정 하였습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.updateSubTokenAllowedFlag.error) {
          handleToast(
            data?.updateSubTokenAllowedFlag.error,
            MessageTypes.ERROR
          );
        }
      }
    });

  const [deleteSubTokenInfo] = useDeleteSubTokenInfoMutation({
    onError(error) {
      console.log(error);
    },
    update(_, { data }) {
      console.log(data);
      if (data?.deleteSubTokenInfo.ok) {
        client.resetStore();
        handleToast("성공적으로 토큰을 삭제 하였습니다.", MessageTypes.SUCCESS);
      } else if (data?.deleteSubTokenInfo.error) {
        handleToast(data?.deleteSubTokenInfo.error, MessageTypes.ERROR);
      }
      handleIsOpen(false);
    }
  });

  const columns: ReactTable.Column<ISubTokenInfoEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const newColumn = listOfColumn.map(item => {
      return {
        Header: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE],
        accessor: item as keyof typeof COLUMN_FOR_VALUE,
        Cell(cell: Cell<ISubTokenInfoEntity>) {
          switch (cell.column.Header) {
            case "토큰":
              return cell.value.slice(0, 8);
            case "허용":
              return (
                <Input
                  type="checkbox"
                  onChange={event => {
                    event.stopPropagation();
                    updateSubTokenAllowedFlag({
                      variables: {
                        employeeId: cell.row.original.employeeId,
                        tokenField: cell.row.original.tokenField,
                        allowedFlag: event.currentTarget.checked
                          ? Allowed_Flag_Type.Allow
                          : Allowed_Flag_Type.NotAllow
                      }
                    });
                  }}
                  checked={cell.value === Allowed_Flag_Type.Allow}
                />
              );
            case "삭제":
              return (
                <IconContainer
                  onClick={event => {
                    event.stopPropagation();
                    if (!cell.row.isSelected) {
                      cell.row.toggleRowSelected(true);
                    }
                    handleConfirmMessage({
                      title: "토큰 정보 삭제",
                      p: "선택된 토큰 정보를 삭제 하시겠습니까?",
                      messageTypes: MessageTypes.WARNING
                    });
                    handleIsOpen(true);
                  }}
                >
                  <Icon icon={DeleteIcon} />
                </IconContainer>
              );
            default:
              return cell.value;
          }
        }
      };
    });
    return newColumn;
  }, []);

  const list: ISubTokenInfoEntity[] = useMemo(() => {
    return data?.getSubTokenInfo.info ?? [];
  }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<ISubTokenInfoEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<ISubTokenInfoEntity>) => {},
    []
  );

  const selectedRow: ReactTable.Row<ISubTokenInfoEntity> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return;
    }, [selectedFlatRows]);

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      deleteSubTokenInfo({
        variables: {
          employeeId: selectedRow.original.employeeId,
          tokenField: selectedRow.original.tokenField
        }
      });
    }
  }, [deleteSubTokenInfo, selectedRow]);

  useEffect(() => {
    if (selectedMainTokenField) {
      getSubTokenInfo({
        variables: {
          tokenFiled: selectedMainTokenField
        }
      });
    }
  }, [getSubTokenInfo, selectedMainTokenField]);

  return (
    <Container>
      <AsonicTable<ISubTokenInfoEntity>
        title={"서브 토큰"}
        isTitleBar={false}
        handleSelectRow={handleSelectRow}
        isLoading={loading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
      />
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default SubTokenTable;
