import * as React from "react";
import Communication from "../../../components/electron/communication";

const useAuthenticateGoogleUser = () => {
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const clientId = React.useMemo(
    () => process.env.REACT_APP_GOOGLE_CLIENT_ID,
    []
  );

  const googleApiKey = React.useMemo(
    () => process.env.REACT_APP_GOOGLE_API_KEY,
    []
  );
  const DISCOVERY_DOCS = React.useMemo(
    () => ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
    []
  );

  const SCOPES = React.useMemo(
    () =>
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    []
  );

  // 구글 연동 확인하기
  const updateSigninStatus = React.useCallback((isSignedIn: boolean) => {
    setIsLogin(isSignedIn);
  }, []);
  const initClient = React.useCallback(async () => {
    await window.gapi.client.init({
      apiKey: googleApiKey,
      clientId: clientId,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    });

    await window.gapi.auth2
      .getAuthInstance()
      .isSignedIn.listen(updateSigninStatus);
    updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
  }, [googleApiKey, clientId, DISCOVERY_DOCS, SCOPES, updateSigninStatus]);

  // 구글 연동 비활성화
  const handleSignoutClick = React.useCallback(() => {
    window.gapi.auth2.getAuthInstance().signOut();
  }, []);

  // 사용자키 셋팅하고 불러오기
  const loadClient = React.useCallback(async () => {
    try {
      await window.gapi.client.setApiKey(googleApiKey);
      await window.gapi.client.load(
        "https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest"
      );
      console.log("GAPI client loaded for API");
    } catch (error) {
      console.error("Error loading GAPI client for API", error);
    }
  }, [googleApiKey]);

  // 구글 연동하기
  const authorize = React.useCallback(() => {
    window.gapi.auth2.getAuthInstance().signIn({ scope: SCOPES });
    loadClient().catch(error => {
      console.log(error);
    });
  }, [loadClient, SCOPES]);

  React.useEffect(() => {
    // 클라이언트 초기화 해주기
    // electron일때는 구글 달력을 요청하지 않게 변경

    if (!Communication.getInstance()) {
      // google api 호출 방지
      // window.gapi.load("client:auth2", initClient);
    }
  }, [initClient]);

  return { authorize, handleSignoutClick, isLogin };
};

export default useAuthenticateGoogleUser;
