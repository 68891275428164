import styled from "styled-components";

type Props = {
  colors: string[];
};

const whiteColor = "white";

export const BackgroundContainer = styled.div<Props>`
  background-color: ${props => props.colors[1]};
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    21% 45%,
    37% 51%,
    69% 29%,
    82% 50%,
    100% 28%,
    100% 100%,
    0 100%,
    0 59%
  );
`;

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin: 20px;
  background: ${props => {
    const color = props.colors[0];
    return color;
  }};
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.skyBlue};
    ${BackgroundContainer} {
      background-color: ${props => props.theme.colors.skyBlue};
    }
  }
`;

export const TitleBar = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const Title = styled.span`
  margin: 0;
  color: ${whiteColor};
`;

export const ContentBox = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: ${whiteColor};
`;
