import styled from "styled-components";

const MainContainer = styled.div<{ flex?: number; gap?: string }>`
  display: flex;
  flex: ${props => props.flex ?? 1};
  flex-direction: column;
  gap: ${props => props.gap ?? ""};
`;

export default MainContainer;
