import styled from "styled-components";
import SubTitle from "../../../shared/sub-title/sub-title";
import ContentContainer from "../../../shared/grid_content_container/grid_content_container";
import FormRow from "../../../shared/form-row/form-row";
import StyleInput from "../../../inputs/style-input";
import TitleContainer from "../../../shared/title_container/title_container";
import EtcSettingHelp from "./etc_setting_help";
import RadioContainer from "../../../shared/radio_container/radio_container";
import { Day_Type } from "../../../../generated/graphql";

interface IProps {
  dayOfWeek: Day_Type[];
  handleDayOfWeek: (newDay: Day_Type) => void;
}

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
`;

function EtcSetting({ dayOfWeek, handleDayOfWeek }: IProps) {
  return (
    <>
      <TitleContainer flex={0}>
        <SubTitle title={"기타 설정"} />
        <EtcSettingHelp />
      </TitleContainer>
      <ItemContainer>
        <ContentContainer>
          <FormRow
            title={`상시 출퇴근 
허용 요일 선택`}
          >
            <RadioContainer>
              <StyleInput
                type="checkbox"
                id="autonomy_monday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Mon)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Mon);
                }}
              />
              <label htmlFor="autonomy_monday">{`월`}</label>
              <StyleInput
                type="checkbox"
                id="autonomy_tuesday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Tue)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Tue);
                }}
              />
              <label htmlFor="autonomy_tuesday">{`화`}</label>
              <StyleInput
                type="checkbox"
                id="autonomy_wednesday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Wed)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Wed);
                }}
              />
              <label htmlFor="autonomy_wednesday">{`수`}</label>
              <StyleInput
                type="checkbox"
                id="autonomy_thursday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Thr)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Thr);
                }}
              />
              <label htmlFor="autonomy_thursday">{`목`}</label>
              <StyleInput
                type="checkbox"
                id="autonomy_friday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Fri)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Fri);
                }}
              />
              <label htmlFor="autonomy_friday">{`금`}</label>
              <StyleInput
                type="checkbox"
                id="autonomy_saturday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Sat)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Sat);
                }}
              />
              <label htmlFor="autonomy_saturday">{`토`}</label>
              <StyleInput
                type="checkbox"
                id="autonomy_sunday"
                minWidth="15px"
                maxWidth="15px"
                name="selectDay"
                checked={dayOfWeek.includes(Day_Type.Sun)}
                onChange={() => {
                  handleDayOfWeek(Day_Type.Sun);
                }}
              />
              <label htmlFor="autonomy_sunday">{`일`}</label>
            </RadioContainer>
          </FormRow>
        </ContentContainer>
      </ItemContainer>
    </>
  );
}

export default EtcSetting;
