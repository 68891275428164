import styled from "styled-components";

interface IProps {
  isExtra?: boolean;
  backgroundColor?: string;
  labelColor?: string;
}

export const Span = styled.span`
  font-family: "Nanum Gothic Coding", monospace;
  font-size: 11px;
`;

export const ScheduleSection = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  -webkit-box-shadow: ${props =>
    props.isExtra
      ? `inset 6px 0px 0px 0px ${props.labelColor || "#EDBF4F"}`
      : `inset 6px 0px 0px 0px rgba(51, 101, 253, 1)`};
  -moz-box-shadow: ${props =>
    props.isExtra
      ? `inset 6px 0px 0px 0px ${props.labelColor || "#EDBF4F"}`
      : `inset 6px 0px 0px 0px rgba(51, 101, 253, 1)`};
  box-shadow: ${props =>
    props.isExtra
      ? `inset 6px 0px 0px 0px ${props.labelColor || "#EDBF4F"}`
      : `inset 6px 0px 0px 0px rgba(51, 101, 253, 1)`};
  background-color: ${props => props.backgroundColor || "#cde6f7"};
  padding: 8px 8px 8px 12px;
  flex: 1;
  border: ${props => (props.isExtra ? "2px solid white" : "none")};
`;

export const ScheduleContent = styled.div`
  flex: 1;
  display: flex;
`;
