import routes from "../routes";
import API from "../Utils/API";

interface ISignInParams {
  token: string;
}

export const signIn = (params: ISignInParams) =>
  API().post(`${routes.apiRoutes.signIn.signin}`, params);

export const isAdmin = (params: ISignInParams) =>
  API().post(`${routes.apiRoutes.signIn.isAdmin}`, params);
