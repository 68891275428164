import axios from "axios";
import { WEB_SERVER_ADDR } from "../../constValues";
import Auth from "../../Auth/Auth";

const API = () =>
  axios.create({
    baseURL: `${WEB_SERVER_ADDR}`,
    headers: {
      token: Auth.getUserToken(),
      Pragma: "no-cache"
    }
  });

export default API;
