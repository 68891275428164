import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { categorySelectionActionCreator } from "../../redux/modules/main-view/categorySelectionReducer";
import backgroundImage from "../../images/business-wallpaper.jpg";
import signInHook from "../../hooks/signInHook";
import Communication from "../../components/electron/communication";
import Auth from "../../Auth/Auth";
import routes from "../../routes";
import SignIn from "../../components/sign-in/SignIn";
import { setUserInfo } from "../../redux/modules/sign-in/signInReducer";
import { useNavigate } from "react-router-dom";

const SignInContainer = props => {
  const navigate = useNavigate();
  const imgUrl = `url(${backgroundImage})`;
  const dispatch = useDispatch();
  const setUser = React.useCallback(
    userInfo => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );
  const getCategories = React.useCallback(
    () => dispatch(categorySelectionActionCreator.getCategories()),
    [dispatch]
  );
  const signInInfo = useSelector(state => state.signInReducer);
  const isMaster =
    signInInfo?.loginIdInfo && signInInfo?.loginIdInfo > 0 ? true : false;

  const signInHooks = signInHook(setUser, getCategories, isMaster);

  React.useEffect(() => {
    const token = Communication.getInstance()?.getToken() ?? Auth.getToken();

    if (token) {
      navigate(routes.pageRoutes.personal);
      // if (window.screen.width <= 1024) {
      //   navigate(`/m${routes.pageRoutes.home}`);
      // } else {
      //   navigate(routes.pageRoutes.personal);
      // }
    }
  }, [signInInfo, navigate]);

  return (
    <SignIn
      {...props}
      signInInfo={signInInfo}
      {...signInHooks}
      imgUrl={imgUrl}
    />
  );
};

export default SignInContainer;
