import { gql } from "@apollo/client";

export const QUERY_GET_USER = gql`
  query GetUser($getUserId: String, $name: String, $departmentId: Int) {
    getUser(id: $getUserId, name: $name, departmentId: $departmentId) {
      ok
      error
      user {
        employeeId
        departmentId
        fullDepartmentName
        name
        myPicture
      }
    }
  }
`;
