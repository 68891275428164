export const TITLES = {
  ADDITIONAL_SETTINGS: "추가설정",
  ALARM_SETTINGS: "기본설정 근무 시간 기준 알람 설정",
  WORK_SCHEDULE: "미성년자 근무표",
  START_TIME: "하루 시작 시간",
  END_TIME: "하루 종료 시간",
  NIGHT_START_TIME: "야간 시작 시간",
  NIGHT_END_TIME: "야간 종료 시간",
  MONTHLY_WORK_HOURS: "월 단위 근무시간",
  WEEKLY_HOLIDAY_ALLOWANCE: "주휴 수당"
};

export const LABELS = {
  HOUR: "시",
  HOUR_TIME: "시간",
  MINUTE: "분",
  AUTO_CALCULATION: "자동 계산",
  ADMIN_INPUT: "관리자 입력",
  USE: "사용",
  NOT_USE: "사용안함",
  NOT_USE_KO: "미사용"
};

export const MESSAGES = {
  ADD_ALARM_EVENT: "알람 설정 추가",
  ADD_ALARM_EVENT_CONFIRM: "알람 설정을 추가 하시겠습니까?",
  ADD_ALARM_ERROR: "알람 설정을 추가하지 못했습니다.",
  ADD_ALARM_SUCCESS: "성공적으로 알람을 추가했습니다.",
  EDIT_ALARM_EVENT: "알람 설정 수정",
  EDIT_ALARM_EVENT_CONFIRM: "알람 설정을 수정 하시겠습니까?",
  EDIT_ALARM_ERROR: "알람 설정을 수정하지 못했습니다.",
  EDIT_ALARM_SUCCESS: "성공적으로 알람을 수정했습니다.",
  DELETE_ALARM_EVENT: "알람 설정 삭제",
  DELETE_ALARM_EVENT_CONFIRM: "알람 설정을 삭제 하시겠습니까?",
  DELETE_ALARM_SUCCESS: "성공적으로 알람을 삭제했습니다.",
  DELETE_ALARM_ERROR: "알람 설정을 삭제하지 못했습니다."
};

export const TABLE_HEADER = {
  ALARM_TYPE: "알람종류",
  ALARM_TIME: "알람시간(분)",
  TITLE: "타이틀",
  CONTENT: "내용"
};

export const TABLE_ROW = {
  DAY_WORK_HOUR: "근무시간(일)",
  WEEK_WORK_HOUR: "근무시간(주)"
};
