import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Reducers } from "../../../types/reducers";

export interface ITabs {
  [key: string]: string;
}

export type KTabs = string;

interface IUseAsonicTabProps<P> {
  tabs: P;
}

export interface ITab<T> {
  key: T;
  value: string;
}

function useAsonicTab<P extends {}, T extends string>({
  tabs
}: IUseAsonicTabProps<P>) {
  const {
    signInReducer: { webMenuList }
  } = useSelector((state: Reducers) => state);

  const list: ITab<T>[] = useMemo((): ITab<T>[] => {
    const whichMenuActive = webMenuList?.[0]?.web_report_flag;

    // 관리자 통계, 사용자 통계의 2개의 탭 숨김 기능
    // 3은 근무현황, 4는 통계목록
    // 통계목록 탭이 2개일 때만 통계목록 탭을 조정함
    const isStatisticsTabs = Object.entries(tabs).every(
      ([_, value]) =>
        Object.keys(tabs).length === 2 &&
        (value === "근무현황" || value === "통계목록")
    );
    const workStatus = 3;
    const statisticsList = 4;

    const isOneTabActive =
      whichMenuActive === workStatus || whichMenuActive === statisticsList;
    if (isStatisticsTabs && isOneTabActive) {
      return Object.entries(tabs).reduce((acc, [key, value]) => {
        if (whichMenuActive === workStatus && key === "WORK_STATUS") {
          acc.push({
            key: key as T,
            value: value as string
          });
        }
        if (whichMenuActive === statisticsList && key === "STATISTICS_LIST") {
          acc.push({
            key: key as T,
            value: value as string
          });
        }
        return acc;
      }, [] as ITab<T>[]);
    }
    // 미사용 또는 전체사용이면 여기를 탄다
    return Object.entries(tabs).map(([key, value]) => {
      return {
        key: key as T,
        value: value as string
      };
    });
  }, [tabs]);

  const [selectedTab, setSelectedTab] = useState<T>(list[0].key);
  const handleSelectTab = useCallback((payload: T) => {
    setSelectedTab(payload);
  }, []);
  return { list, selectedTab, handleSelectTab };
}

export default useAsonicTab;
