import styled from "styled-components";
import AsonicTableHeader from "./asonic-table-header";

const AsonicTableData = styled(AsonicTableHeader)<{ isOverFlow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :last-child {
    flex: ${props => (props.isLastFlex ? "1" : "none")};
    margin-right: 17px;
  }
`;

export default AsonicTableData;
