import * as React from "react";
import additionalInformationManagementApi from "../../../api/detail-api/additional-information-management-api";
import moment from "moment";

interface IProps {
  employeeId: string;
  handleIsLoading: (value: boolean) => void;
  isUpdateBasic: boolean;
  isUpdateWork: boolean;
}

export interface BasicInformation {
  selectedEmployeeId: string;
  email: string;
  joinDate: string;
  memo: string;
  name: string;
  officialResponsibilities: string;
  phoneNumber: string;
  position: string;
  telephoneNumber: string;
}

export interface WorkInformation {
  selectedEmployeeId: string;
  allocateDay: string;
  applyYear: number;
  leaveDay: number;
  modifyReason: string;
  periodateEnd: string;
  periodateStart: string;
  useDay: number;
}

const useAdditionalInformationManagementData = <P extends IProps>({
  employeeId,
  handleIsLoading,
  isUpdateBasic,
  isUpdateWork
}: P) => {
  const [basicInformation, setBasicInformation] =
    React.useState<BasicInformation>();
  const [workInformation, setWorkInformation] =
    React.useState<WorkInformation>();

  const getAdditionalInformationManagementData = React.useCallback(
    async (applyYear: string) => {
      try {
        handleIsLoading(true);
        if (employeeId) {
          const { data } =
            await additionalInformationManagementApi.getAdditionalInformationManagement(
              { employeeId, applyYear }
            );

          if (data.basicInformation && data.workInformation) {
            setBasicInformation(data.basicInformation);
            setWorkInformation(data.workInformation);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        handleIsLoading(false);
      }
    },
    [employeeId, handleIsLoading]
  );

  const updateBasicInformationManagementData = React.useCallback(
    async (params: Omit<BasicInformation, "name">) => {
      console.log(params);
      if (isUpdateBasic) {
        try {
          await additionalInformationManagementApi.updateBasicInformationManagement(
            params
          );
        } catch (error) {
          console.log(error);
        } finally {
          getAdditionalInformationManagementData(moment().format("YYYY"));
        }
      }
    },
    [isUpdateBasic, getAdditionalInformationManagementData]
  );

  const updateWorkInformationManagementData = React.useCallback(
    async (
      params: Omit<WorkInformation, "useDay" | "leaveDay" | "employeeId">
    ) => {
      if (isUpdateWork) {
        const newParams = { ...params };
        try {
          await additionalInformationManagementApi.updateWorkInformationManagement(
            newParams
          );
        } catch (error) {
          console.log(error);
        } finally {
          getAdditionalInformationManagementData(params.applyYear.toString());
        }
      }
    },
    [getAdditionalInformationManagementData, isUpdateWork]
  );

  React.useEffect(() => {
    getAdditionalInformationManagementData(moment().format("YYYY"));
  }, [getAdditionalInformationManagementData]);

  return {
    getAdditionalInformationManagementData,
    basicInformation,
    workInformation,
    updateBasicInformationManagementData,
    updateWorkInformationManagementData
  };
};

export default useAdditionalInformationManagementData;
