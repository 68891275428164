import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  isOpen: boolean;
};

export const LogOutBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 10px;
  background-color: white;
  font-size: inherit;
  color: ${props => props.theme.colors.lightMidNightBlue};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
    svg {
      color: ${props => props.theme.colors.lightGreen};
    }
  }
  margin-right: 5px;
  :active {
    transform: scale(0.98);
  }
`;

export const LogOutIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${props => props.theme.colors.lightMidNightBlue};
  padding-right: 2px;
`;

export const GoMobileBtn = styled.button`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 18px;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  display: none;
  background-color: #007acc;
  font-weight: bold;
  padding: 10px 30px 10px 30px;
  margin-right: 5px;
  color: white;
  :hover {
    background-color: #74b9ff;
  }
`;

export const MainPanel = styled.div<Props>`
  display: ${props => (props.isOpen ? "block" : "none")};
  position: absolute;
  min-width: 130px;
  margin-top: 14px;
  background-color: #f9fbfd;
  border-radius: 3px;
  -webkit-box-shadow: 0px 13px 21px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 13px 21px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 13px 21px 2px rgba(0, 0, 0, 0.75);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Panel = styled.div`
  margin-right: 12px;
  display: none;
`;

export const AdminPanel = styled.div`
  cursor: pointer;
  color: white;
  text-align: center;
  padding: 8px;
  min-width: 110px;
  background-color: #007acc;
  border-radius: 3px;
  :hover {
    background-color: #5783f8;
  }
`;

export const AdminLabel = styled.label``;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  min-height: 40px;
`;

export const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${props => props.theme.colors.lightMidNightBlue};
  margin-right: 10px;
  :hover {
    color: ${props => props.theme.colors.lightGreen};
    transform: scale(1.5);
  }
  border: none;
  outline: none;
`;

export const IconButton = styled.div<Props>`
  display: ${props => (props.isOpen ? "none" : "block")};
  padding-left: 10px;
`;

export const Arrow = styled.div`
  position: absolute;
  top: -7px;
  margin-left: -40px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 9px solid #f9fbfd;
`;

export const Row = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #cfd3db;
    color: #5783f8;
    ${Arrow} {
      border-bottom: 9px solid #cfd3db;
    }
  }
`;

export const RowTitle = styled.span`
  padding: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
