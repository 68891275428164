import RightMenuButton from "../../../../../Rightmenu/RightMenuButton";

interface IProps {
  handleIsOpen: (value: any) => void;
}

export const AddCommuteHistoryMenu = ({ handleIsOpen }: IProps) => {
  return (
    <RightMenuButton
      title="출퇴근 기록 추가"
      handleClick={() => handleIsOpen(true)}
    />
  );
};
