import React from "react";
import HeaderTitle from "../smallComponents/HeaderTitle";
import SearchBar from "./mobile-approval-searchbar";

const MobileApprobalView = props => {
  return (
    <React.Fragment>
      <HeaderTitle category="approval" />
      <SearchBar {...props} />
    </React.Fragment>
  );
};

export default MobileApprobalView;
