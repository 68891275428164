import routes from "../../routes";
import API from "../api";

export type Params = {};

const renewalInformation = (params: Params) =>
  API().get(routes.apiRoutes.masterWorkManagementPage.renewalInformation, {
    params
  });

const statusNumberEmployeesWorkingHour = (params: Params) =>
  API().get(
    routes.apiRoutes.masterWorkManagementPage.statusNumberEMployeesWorkingHour,
    {
      params
    }
  );

const todayWorkingStatus = (params: Params) =>
  API().get(routes.apiRoutes.masterWorkManagementPage.todayWorkingStatus, {
    params
  });

const statusFullTimeEmployees = (params: Params) =>
  API().get(routes.apiRoutes.masterWorkManagementPage.statusFullTimeEmployees, {
    params
  });

const statusOverTimeEmployees = (params: Params) =>
  API().get(routes.apiRoutes.masterWorkManagementPage.statusOverTimeEmployees, {
    params
  });
const totalWeekWorkingTime = (params: Params) =>
  API().get(routes.apiRoutes.masterWorkManagementPage.totalWeekWorkingTime, {
    params
  });

const averageWorkingHoursDepartment = (params: Params) =>
  API().get(
    routes.apiRoutes.masterWorkManagementPage.averageWorkingHoursDepartment,
    {
      params
    }
  );

const downloadExcel = (params: {}) =>
  API().post(routes.apiRoutes.masterWorkManagementPage.downloadExcel);

const masterWorkManagementPageApi = {
  renewalInformation,
  statusNumberEmployeesWorkingHour,
  todayWorkingStatus,
  statusFullTimeEmployees,
  statusOverTimeEmployees,
  totalWeekWorkingTime,
  averageWorkingHoursDepartment,
  downloadExcel
};

export default masterWorkManagementPageApi;
