import { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import listOfApolloVar from "../../../apollo/apollo-var";
import { ApprovalEntity } from "../../../generated/graphql";
import ApprovalReceiveReference from "./approval-receive-reference";
import ApprovalWaitingList from "./approval-waiting-list";
import ListOfCompletedApproval from "./list-of-complleted-approval";
import ListOfMyApproval from "./list-of-my-approval";
import STab from "../../shared/s_tab/s_tab";
import STabContainer from "../../shared/s_tab_container/s_tab_container";

enum ITabs {
  LIST_WAITING = "대기목록",
  LIST_COMPLETED = "진행/완료",
  LIST_RECEIVE_WITH_REFERENCE = "수신/참조",
  LIST_OF_MY_APPROVAL = "나의결재"
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div<{ showApprovalPopup: boolean; selectedTab?: ITabs }>`
  display: flex;
  height: ${props => {
    if (props.selectedTab === ITabs.LIST_RECEIVE_WITH_REFERENCE) {
      return "100%";
    }
    return "none";
  }};
  padding: 10px;
  flex: 1;
  min-width: 790px;
`;

const PopUpContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 15;
`;

function ApprovalList() {
  const { state } = useLocation();

  const [list, setList] = useState<ApprovalEntity[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<
    number | undefined
  >();

  const handleSelectedRowIndex = useCallback((index: number) => {
    setSelectedRowIndex(index);
  }, []);

  const handleSelectForm = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        if (
          typeof selectedRowIndex === "number" &&
          selectedRowIndex < list.length - 1
        ) {
          const nextIndex = selectedRowIndex + 1;
          const nextRow = list[nextIndex];
          listOfApolloVar.approvalDataVar(nextRow);
          handleSelectedRowIndex(nextIndex);
        }
      } else {
        if (typeof selectedRowIndex === "number" && selectedRowIndex > 0) {
          const preIndex = selectedRowIndex - 1;
          const preRow = list[preIndex];
          listOfApolloVar.approvalDataVar(preRow);
          handleSelectedRowIndex(preIndex);
        }
      }
    },
    [selectedRowIndex, list, handleSelectedRowIndex]
  );

  const [selectedTab, setSelectedTab] = useState(ITabs.LIST_WAITING);
  const [showApprovalPopup, setShowApprovalPopup] = useState<boolean>(false);

  const handleSelectTab = useCallback((payload: ITabs) => {
    setShowApprovalPopup(false);
    setSelectedTab(payload);
  }, []);

  useEffect(() => {
    if (state?.type === "receiveAndReference") {
      setSelectedTab(ITabs.LIST_RECEIVE_WITH_REFERENCE);
    } else if (state?.type === "listWaiting") {
      setSelectedTab(ITabs.LIST_WAITING);
    }
  }, [state]);

  return (
    <Container>
      <STabContainer>
        <STab
          isSelected={selectedTab === ITabs.LIST_WAITING}
          onClick={() => {
            handleSelectTab(ITabs.LIST_WAITING);
          }}
        >
          {ITabs.LIST_WAITING}
        </STab>
        <STab
          isSelected={selectedTab === ITabs.LIST_COMPLETED}
          onClick={() => {
            handleSelectTab(ITabs.LIST_COMPLETED);
          }}
        >
          {ITabs.LIST_COMPLETED}
        </STab>
        <STab
          isSelected={selectedTab === ITabs.LIST_RECEIVE_WITH_REFERENCE}
          onClick={() => {
            handleSelectTab(ITabs.LIST_RECEIVE_WITH_REFERENCE);
          }}
        >
          {ITabs.LIST_RECEIVE_WITH_REFERENCE}
        </STab>
        <STab
          isSelected={selectedTab === ITabs.LIST_OF_MY_APPROVAL}
          onClick={() => {
            handleSelectTab(ITabs.LIST_OF_MY_APPROVAL);
          }}
        >
          {ITabs.LIST_OF_MY_APPROVAL}
        </STab>
      </STabContainer>
      <ContentContainer>
        <Content
          showApprovalPopup={showApprovalPopup}
          selectedTab={selectedTab}
        >
          <Fragment>
            {selectedTab === ITabs.LIST_WAITING && <ApprovalWaitingList />}
            {selectedTab === ITabs.LIST_COMPLETED && (
              <ListOfCompletedApproval />
            )}
            {selectedTab === ITabs.LIST_RECEIVE_WITH_REFERENCE && (
              <ApprovalReceiveReference />
            )}
            {selectedTab === ITabs.LIST_OF_MY_APPROVAL && <ListOfMyApproval />}
          </Fragment>
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default ApprovalList;
