import { useCallback, useContext, useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import {
  ChatContext,
  ChatHandleContext,
  DEFAULT_PARENT_DEPARTMENT_ID
} from "./chat";

interface IProps {
  isGroup: boolean;
}

interface IList {
  name: string;
  departmentId: number;
}

const Container = styled.section<{ isGroup: boolean }>`
  display: flex;
  flex: 1;
  padding: 0px 10px 10px 10px;
  visibility: ${props => (props.isGroup ? "visible" : "hidden")};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  div {
    div {
      display: flex;
      gap: 10px;
    }
  }
`;

const GroupName = styled.span`
  cursor: pointer;
  padding: 6px;
  border-radius: 8px;
  min-width: max-content;
  background-color: ${props => props.theme.colors.lemon};
  :hover {
    background-color: ${props => props.theme.colors.yellow};
  }
`;

const GroupContainer = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.span``;

function GroupSlide({ isGroup }: IProps) {
  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);
  const [list, setList] = useState<IList[]>([
    {
      name: chatContext?.selectedDepartmentName || "",
      departmentId:
        chatContext?.parentDepartmentId || DEFAULT_PARENT_DEPARTMENT_ID
    }
  ]);

  const Row = useCallback(
    ({ index }: { index: number }) => {
      return (
        <GroupContainer>
          <GroupName
            onClick={() => {
              let findSelectedItem: boolean = false;
              const newList: IList[] = [];
              list.forEach((item, itemIndex) => {
                if (item.departmentId === list[index].departmentId) {
                  findSelectedItem = true;
                }
                if (findSelectedItem && itemIndex === 0) {
                  newList.push(item);
                }
                if (!findSelectedItem) {
                  newList.push(item);
                }
              });
              setList(newList);
              if (chatHandleContext) {
                chatHandleContext.handleParentDepartment({
                  parentDepartmentId: list[index].departmentId,
                  selectedDepartmentName: list[index].name
                });
              }
            }}
          >
            {list[index].name}
          </GroupName>
          {list.length - 1 !== index && <Divider>{`>`}</Divider>}
        </GroupContainer>
      );
    },
    [list, chatHandleContext]
  );

  useEffect(() => {
    if (chatContext) {
      setList(preList => {
        if (chatContext.parentDepartmentId !== DEFAULT_PARENT_DEPARTMENT_ID) {
          const filteredList = preList.filter(item => {
            return item.departmentId !== chatContext.parentDepartmentId;
          });

          return [
            ...filteredList,
            {
              name: chatContext.selectedDepartmentName,
              departmentId: chatContext.parentDepartmentId
            }
          ];
        }
        return [preList[0]];
      });
    }
  }, [chatContext]);

  return (
    <Container isGroup={isGroup}>
      <List
        height={46}
        itemCount={list.length}
        itemData={list}
        itemSize={100}
        layout="horizontal"
        width={380}
      >
        {Row}
      </List>
    </Container>
  );
}

export default GroupSlide;
