import StyledFlexContainer from "../../../shared/styled_flex_container";
import PageSet from "../../../table/set_page";
import HideColumn, { IHideColumnProps } from "../../../table/hide_column";
import PointTitle from "../../../shared/point_title";
import styled from "styled-components";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../table_v2/fixed_column_dropbox";
import AsonicExcel from "../../../asonic-table/asonic-excel";
import { ISearchProps } from "../../../asonic-table/asonic-search";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

function AdminStatisticsHeader<P extends object>({
  columns,
  table,
  title,
  headerTitleList,
  take,
  handleTake,
  count,
  downloadExcel,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN,
  setIsActiveFilter
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title={`${title}`} subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
          setIsActiveFilter={setIsActiveFilter}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
      <FileContainer>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </FileContainer>
    </Container>
  );
}

export default AdminStatisticsHeader;
