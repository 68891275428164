import * as React from "react";
import masterWorkManagementPageApi from "../../../api/master-work-management-page-api";

type Data = {
  title: string;
  data: number;
  colors: string[];
};

type Props = {
  store: {
    isRenewal: boolean;
  };
};

const useTodayWorkingStatus = <P extends Props>({
  store: { isRenewal }
}: P) => {
  const [data, setData] = React.useState<Data[]>([
    {
      title: "직원수",
      data: 0,
      colors: ["#4D6D7F", "#3F5A68"]
    },
    {
      title: "정규근무",
      data: 0,
      colors: ["#4380C3", "#376FAD"]
    },
    {
      title: "연장근무",
      data: 0,
      colors: ["#FF9D24", "#FF8F0A"]
    },
    {
      title: "휴가",
      data: 0,
      colors: ["#71CC66", "#62C556"]
    },
    {
      title: "기타",
      data: 0,
      colors: ["#6E5FA4", "#584B94"]
    }
  ]);

  const handleData = React.useCallback(async () => {
    try {
      if (isRenewal) {
        const parmas = {};
        const { data } = await masterWorkManagementPageApi.todayWorkingStatus(
          parmas
        );

        if (data) {
          setData([
            {
              title: data[0]?.asname1 ?? "직원수",
              data: data[0]?.ascount1,
              colors: ["#4D6D7F", "#3F5A68"]
            },
            {
              title: data[0]?.asname2 ?? "정규근무",
              data: data[0]?.ascount2,
              colors: ["#4380C3", "#376FAD"]
            },
            {
              title: data[0]?.asname3 ?? "연장근무",
              data: data[0]?.ascount3,
              colors: ["#FF9D24", "#FF8F0A"]
            },
            {
              title: data[0]?.asname4 ?? "휴가",
              data: data[0]?.ascount4,
              colors: ["#71CC66", "#62C556"]
            },
            {
              title: data[0]?.asname5 ?? "기타",
              data: data[0]?.ascount5,
              colors: ["#6E5FA4", "#584B94"]
            }
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [isRenewal]);

  React.useEffect(() => {
    const timeId = setInterval(() => {
      handleData();
    }, 300000);
    handleData();
    return () => {
      clearInterval(timeId);
    };
  }, [handleData]);

  return { data };
};

export default useTodayWorkingStatus;
