import { useState, useCallback } from "react";
import Auth from "../../../../Auth/Auth";
import ApproverStorage from "../../../../Utils/Approver";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import { cleanReducers } from "../../../../redux/modules/sign-in/signInReducer";
import isOpenHook from "../../../../hooks/isOpenHook";
import TitleBarPresenter from "./title-bar-presenter";
import useCheckIsDebugging from "../../../../hooks/use-check-is-debugging";
import Communication from "../../../electron/communication";
import { useApolloClient } from "@apollo/client";

interface IProps {}

const TitleBarContainer = <P extends IProps>(props: P) => {
  const client = useApolloClient();
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const { isDebugging } = useCheckIsDebugging({});
  const handleOpenDialog = useCallback((value: boolean) => {
    setIsOpenDialog(value);
  }, []);

  const dispatch = useDispatch();
  const signInReducer = useSelector((state: Reducers) => state.signInReducer);
  const navigate = useNavigate();
  const { isOpen, handleIsOpen } = isOpenHook();
  const clearnReducerDispatch = useCallback(() => {
    dispatch(cleanReducers());
  }, [dispatch]);

  const handleMobile = useCallback(() => {
    navigate("/m/mainview/home");
  }, [navigate]);

  const handleLogout = useCallback(() => {
    client.clearStore();
    clearnReducerDispatch();
    Auth.deauthenticateUser();
    ApproverStorage.deleteLocalApprover();
    Communication.getInstance()?.deleteToken();

    navigate("/");
  }, [clearnReducerDispatch, navigate, client]);
  return (
    <TitleBarPresenter
      {...props}
      isOpenDialog={isOpenDialog}
      handleOpenDialog={handleOpenDialog}
      signInReducer={signInReducer}
      isOpen={isOpen}
      handleIsOpen={handleIsOpen}
      handleMobile={handleMobile}
      handleLogout={handleLogout}
      isDebugging={isDebugging}
    />
  );
};

export default TitleBarContainer;
