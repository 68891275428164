import React from "react";
import ReactDOM from "react-dom";
import loadingGif from "../../images/loading.gif";
import { useLoading } from "./loading-context";

export const LoadingSpinner: React.FC = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  const spinnerElement = (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999
      }}
    >
      <img src={loadingGif} alt="로딩 중" />
    </div>
  );

  return ReactDOM.createPortal(spinnerElement, document.body);
};
