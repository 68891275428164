import styled from "styled-components";

const BACKGROUND_COLOR = "#f3f5f5";

export const Container = styled.div`
  width: 100%;
  display: grid;
  background-color: ${BACKGROUND_COLOR};
  padding: 20px;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  font-size: 20px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 2px;
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
`;

export const TitleBar = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h3`
  color: rgba(0, 0, 0, 0.7);
  margin: 10px;
`;

export const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

export const ChartBoxContainer = styled.div`
  grid-column: 1 / span 3;
  grid-row: 4 / span 2;
`;
