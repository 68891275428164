import styled from "styled-components";

export const ApprovalRemarksContainer = styled.div`
  display: flex;
  max-height: 200px;
  flex-direction: column;

  overflow: scroll;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${props => (props.openDialog ? "flex" : "none")};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  border-radius: 20px;
  border: 2px solid #3d81e7;
  background-color: white;
  padding: 10px;
`;

export const ApplyReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ApplyTitle = styled.h1`
  font-weight: bold;
  font-size: 16px;
  align-self: flex-start;
`;

export const ApplyReasonContents = styled.span`
  background-color: #f7f3ee;
  border-radius: 15px 15px 15px 0;
  padding: 10px;
  width: 80%;
  height: 50%;
  border: 1px solid #3d81e7;
`;

export const ApprovalListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const ApprovalListTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
`;
