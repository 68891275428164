import styled from "styled-components";
import SubTitle from "../shared/sub-title/sub-title";
import { Fragment, useCallback, useEffect, useState } from "react";
import ApprovalDetailPopup from "./approval-detail-popup";
import ApprovalAwaitingSummaryTable from "./approval-awaiting-summary-table";
import ApprovalReceiveReferenceSummaryTable from "./approval-receive-reference-summary-table";
import MyApprovalSummaryTable from "./my-approval-summary-table";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../apollo/apollo-var";
import {
  ApprovalEntity,
  useGetApprovalAdminSettingLazyQuery
} from "../../generated/graphql";

export const APPROVAL_DASHBOARD_TYPE = {
  ALL: "ALL",
  AWAITING: "AWAITING",
  RECEIVE_REFERENCE: "RECEIVE_REFERENCE",
  MY: "MY"
} as const;

export type APPROVAL_DASHBOARD_TYPE =
  typeof APPROVAL_DASHBOARD_TYPE[keyof typeof APPROVAL_DASHBOARD_TYPE];

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;

const Title = styled.div`
  display: flex;
  flex: 1;
  span {
    font-size: 16px;
    font-weight: normal;
  }
`;

const Content = styled.section`
  display: flex;
  flex: 15;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  min-width: 790px;
`;

const PopUpContainer = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
`;

function ApprovalDashBoard() {
  const [take, setTake] = useState<number>(5);
  const approvalData = useReactiveVar(listOfApolloVar.approvalDataVar);
  const approvalType = useReactiveVar(listOfApolloVar.approvalTypeVar);
  const [dashboardType, setDashboardType] = useState<APPROVAL_DASHBOARD_TYPE>(
    APPROVAL_DASHBOARD_TYPE.ALL
  );

  const [list, setList] = useState<ApprovalEntity[]>([]);

  const [selectedRowIndex, setSelectedRowIndex] = useState<
    number | undefined
  >();

  const handleSetList = useCallback((payload: ApprovalEntity[]) => {
    setList(payload);
  }, []);

  const handleSelectedRowIndex = useCallback((index: number) => {
    setSelectedRowIndex(index);
  }, []);

  const handleSelectForm = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        if (
          typeof selectedRowIndex === "number" &&
          selectedRowIndex < list.length - 1
        ) {
          const nextIndex = selectedRowIndex + 1;
          const nextRow = list[nextIndex];
          listOfApolloVar.approvalDataVar(nextRow);
          setSelectedRowIndex(nextIndex);
        }
      } else {
        if (typeof selectedRowIndex === "number" && selectedRowIndex > 0) {
          const preIndex = selectedRowIndex - 1;
          const preRow = list[preIndex];
          listOfApolloVar.approvalDataVar(preRow);
          setSelectedRowIndex(preIndex);
        }
      }
    },
    [selectedRowIndex, list]
  );

  const handleDashboardType = useCallback((value: APPROVAL_DASHBOARD_TYPE) => {
    setDashboardType(value);
  }, []);

  const [showApprovalPopup, setShowApprovalPopup] = useState<boolean>(false);

  const handleShowApprovalPopup = useCallback((value: boolean) => {
    setShowApprovalPopup(value);
  }, []);

  const [getApprovalAdminSetting] = useGetApprovalAdminSettingLazyQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (
        data.getApprovalAdminSetting.ok &&
        data.getApprovalAdminSetting.preview &&
        data.getApprovalAdminSetting.dashboardListCount
      ) {
        setTake(data.getApprovalAdminSetting.dashboardListCount);
      } else if (data.getApprovalAdminSetting.error) {
        console.log(data.getApprovalAdminSetting.error);
      }
    }
  });

  useEffect(() => {
    getApprovalAdminSetting();
  }, [getApprovalAdminSetting]);

  return (
    <Container>
      <Title>
        <SubTitle title="결재 현황" />
      </Title>
      <Content>
        {showApprovalPopup ? (
          <PopUpContainer>
            <ApprovalDetailPopup
              approvalData={approvalData}
              handleShowApprovalPopup={handleShowApprovalPopup}
              newApprovalType={approvalType}
              handleSelectForm={handleSelectForm}
            />
          </PopUpContainer>
        ) : (
          <Fragment>
            <ApprovalAwaitingSummaryTable
              take={take}
              handleShowApprovalPopup={handleShowApprovalPopup}
              dashboardType={dashboardType}
              handleDashboardType={handleDashboardType}
              handleSelectedRowIndex={handleSelectedRowIndex}
              handleSetList={handleSetList}
            />
            <ApprovalReceiveReferenceSummaryTable
              take={take}
              handleShowApprovalPopup={handleShowApprovalPopup}
              dashboardType={dashboardType}
              handleDashboardType={handleDashboardType}
              handleSelectedRowIndex={handleSelectedRowIndex}
              handleSetList={handleSetList}
            />
            <MyApprovalSummaryTable
              take={take}
              handleShowApprovalPopup={handleShowApprovalPopup}
              handleDashboardType={handleDashboardType}
              dashboardType={dashboardType}
              handleSelectedRowIndex={handleSelectedRowIndex}
              handleSetList={handleSetList}
            />
          </Fragment>
        )}
      </Content>
    </Container>
  );
}

export default ApprovalDashBoard;
