import * as React from "react";
import { Container, Time, Term } from "./styled";

type OnChange = React.ChangeEvent<HTMLInputElement>;

export interface ITotalProps {
  startTime?: string;
  endTime?: string;
  handleChangeTime: (name: TIME) => (event: OnChange) => void;
  handleTime?: (name: TIME) => (time: string) => void;
}

type TIME = "startTime" | "endTime";

const START_TIME = "startTime",
  END_TIME = "endTime";

const StartAndEndTime = (props: ITotalProps) => {
  const { startTime, endTime, handleChangeTime } = props;
  return (
    <Container>
      <Time
        type="time"
        value={startTime}
        onChange={handleChangeTime(START_TIME)}
      />
      <Term>~</Term>
      <Time type="time" value={endTime} onChange={handleChangeTime(END_TIME)} />
    </Container>
  );
};

export default StartAndEndTime;
