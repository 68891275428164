import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`;

export const DivideContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border: 1px solid rgb(0, 0, 0, 0.4);
`;
