import styled from "styled-components";
import { GetListOfChatRoom_getListOfChatRoom_list } from "../../__generated__/GetListOfChatRoom";
import basicImg from "../../images/basicimage.jpg";
import { gql, useQuery } from "@apollo/client";
import {
  GetListOfChatImage,
  GetListOfChatImageVariables
} from "../../__generated__/GetListOfChatImage";
import { useCallback, useContext, useMemo } from "react";
import { Reducers } from "../../../types/reducers";
import { useSelector } from "react-redux";
import { ChatHandleContext, LIST_OF_CHAT_MENU } from "./chat";

interface IRow {
  data: GetListOfChatRoom_getListOfChatRoom_list[];
  index: number;
  style: object;
}

interface PhotoProps {
  photo?: string | null;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.skyBlue};
    color: ${props => props.theme.colors.white};
  }
`;

const Title = styled.span`
  font-size: 14px;
`;

const Message = styled.span`
  color: ${props => props.theme.colors.darkGrey};
`;

const ImageContainer = styled.div<{ imageCount: number }>`
  width: 40px;
  height: 40px;
  display: grid;
  grid-template-columns: ${props =>
    props.imageCount > 2 ? "repeat(2, 1fr)" : "1fr"};
  grid-auto-rows: ${props => (props.imageCount === 3 ? "20px" : "1fr")};
  padding: 5px;
  gap: 2px;
`;

const Image = styled.div<PhotoProps>`
  display: flex;
  flex: 1;
  border-radius: 50%;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url(data:image/png+jpg;base64,${props.photo})`
      : `url(${basicImg})`};
`;

const Content = styled.div`
  display: flex;
  flex: 6;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const QUERY_GET_LIST_OF_CHAT_IMAGE = gql`
  query GetListOfChatImage($chatroomIdx: Int!, $imageCount: Float) {
    getListOfChatImage(chatroom_idx: $chatroomIdx, imageCount: $imageCount) {
      ok
      error
      listOfImage {
        employee_id
        name
        myPicture
      }
    }
  }
`;

function ChatRow({ data, index, style }: IRow) {
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const chatHandleContext = useContext(ChatHandleContext);

  const room = data[index];

  const { data: queryData } = useQuery<
    GetListOfChatImage,
    GetListOfChatImageVariables
  >(QUERY_GET_LIST_OF_CHAT_IMAGE, {
    variables: {
      chatroomIdx: room.chatroom_idx,
      imageCount: 5
    }
  });

  const listOfImage = useMemo((): string[] => {
    let list: string[] = [];
    if (queryData?.getListOfChatImage?.listOfImage) {
      queryData.getListOfChatImage.listOfImage.forEach((item, index) => {
        if (item.employee_id !== employee_id && index < 4) {
          list.push(item.myPicture || "");
        }
      });
    }
    return list;
  }, [queryData?.getListOfChatImage?.listOfImage, employee_id]);

  const handleOpenChatRoom = useCallback(() => {
    chatHandleContext?.handleSelectedChatRoom(room.chatroom_idx);
    chatHandleContext?.handleSelectedChatRoomName(room.roomname);
    chatHandleContext?.handleMenu(LIST_OF_CHAT_MENU.CHAT_ROOM);
    if (queryData?.getListOfChatImage?.listOfImage) {
      chatHandleContext?.handleListOfUserInRoom(
        queryData.getListOfChatImage.listOfImage
      );
    }
  }, [chatHandleContext, room, queryData?.getListOfChatImage?.listOfImage]);

  const message = useMemo(() => {
    const messageLength: number = room?.chatroom_message_data?.length || 0;
    if (messageLength > 20) {
      const newMessage = `${room?.chatroom_message_data?.slice(0, 20)}...`;
      return newMessage;
    }
    return room.chatroom_message_data || "";
  }, [room.chatroom_message_data]);

  return (
    <Container style={style} onClick={handleOpenChatRoom}>
      <ImageContainer
        imageCount={queryData?.getListOfChatImage?.listOfImage?.length || 0}
      >
        {listOfImage?.map((item, index) => {
          return <Image key={index} photo={item} />;
        })}
      </ImageContainer>
      <Content>
        <Title>{room.roomname}</Title>
        <Message>{message}</Message>
      </Content>
    </Container>
  );
}

export default ChatRow;
