import ApproverStorage from "../../../../Utils/Approver";
import Auth from "../../../../Auth/Auth";

const handleLogout = cleanReducers => {
  cleanReducers();
  Auth.deauthenticateUser();
  ApproverStorage.deleteLocalApprover();
};

export default handleLogout;
