import SectionContainer from "../../shared/section_container/section_container";
import SubTitle from "../../shared/sub-title/sub-title";
import ContentContainer from "../../shared/grid_content_container/grid_content_container";
import FormRow from "../../shared/form-row/form-row";
import StyleInput from "../../inputs/style-input";
import SSelect from "../../shared/s_select/s_select";
import useTimeList, {
  HOUR_TYPE
} from "../../../hooks/use_time_list/use_time_list";
import WDAdditionalSetting from "./w_d_additional_setting/w_d_additional_setting";
import TitleContainer from "../../shared/title_container/title_container";
import TDTypeHelp from "./t_d_type_help";
import ButtonContainer from "../../shared/button_container/button_container";
import Button from "../../globalComponents/Button";
import RadioContainer from "../../shared/radio_container/radio_container";
import {
  useGetStandardWtBasicInfoLazyQuery,
  Work_Week_Type,
  Day_Of_Week_Type,
  Day_Type,
  OtWorkingStandardHoursEntity,
  Break_Time_Type
} from "../../../generated/graphql";
import { useCallback, useContext, useEffect, useState } from "react";
import handleApolloLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import { WS_STATE } from "../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import useTimeControl from "../../../hooks/use_time_control/use_time_control";

function TDType() {
  const wsState = useContext(WS_STATE);
  const [workWeekSelectList, setWorkWeekSelectList] = useState<number[]>([]);
  const [dayOfWeekList, setDayOfWeekList] = useState<Day_Type[]>([]);
  const [list, setList] = useState<OtWorkingStandardHoursEntity[]>([]);
  const [dayOfWeekType, setDayOfWeekType] = useState<Day_Of_Week_Type>(
    Day_Of_Week_Type.MonToFri
  );
  const handleList = useCallback(
    ({
      template,
      index
    }: {
      template: OtWorkingStandardHoursEntity;
      index: number;
    }) => {
      setList(list => {
        const newList = [...list];
        newList.splice(index, 1, template);
        return newList;
      });
    },
    []
  );

  const [startTime, setStartTime] = useState<string>("09:00");
  const [endTime, setEndTime] = useState<string>("09:00");
  const [startBreakTime, setStartBreakTime] = useState<string>("12:00");
  const [endBreakTime, setEndBreakTime] = useState<string>("13:00");

  const [workWeekType, setWorkWeekType] = useState<Work_Week_Type>(
    Work_Week_Type.All
  );

  const { hourList, minList, hourTypeList } = useTimeList();
  const { displayHours, makeHoursType, makeHours } = useTimeControl();

  const addExtraDaySettingList = useCallback(() => {
    setList(list => {
      const newList = [...list];
      const timeSequence = newList.length + 1;
      let workWeekSelect = [1, 2, 3, 4, 5];
      newList.push({
        startTime: "09:00",
        endTime: "18:00",
        workWeekType: Work_Week_Type.All,
        dayOfWeek: [
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri
        ],
        dayOfWeekType: Day_Of_Week_Type.MonToFri,
        startBreakTime: "12:00",
        endBreakTime: "13:00",
        breakTimeType: Break_Time_Type.Basic,
        timeSequence,
        workWeekSelect
      });
      return newList;
    });
  }, []);

  const deleteExtraDaySettingList = useCallback(
    (payload: OtWorkingStandardHoursEntity) => {
      setList(list => {
        let newList = list.filter(item => {
          return item.timeSequence !== payload.timeSequence;
        });
        newList = newList.map((item, index) => {
          const newItem = { ...item };
          newItem.timeSequence = index + 1;
          return newItem;
        });
        return newList;
      });
    },
    []
  );

  const [getStandardWTBasicInfo] = useGetStandardWtBasicInfoLazyQuery({
    onError(error) {
      handleApolloLog(error);
    },
    onCompleted(data) {
      if (data.getStandardWTBasicInfo.extraDaySettingList) {
        setList(data.getStandardWTBasicInfo.extraDaySettingList);
      }
      if (data.getStandardWTBasicInfo.basicInfo) {
        const {
          startTime,
          endTime,
          workWeekType,
          dayOfWeekType,
          workWeekSelect,
          dayOfWeek,
          startBreakTime,
          endBreakTime
        } = data.getStandardWTBasicInfo.basicInfo;
        setWorkWeekType(workWeekType);
        setStartTime(startTime);
        setEndTime(endTime);
        setDayOfWeekType(dayOfWeekType);
        setDayOfWeekList(dayOfWeek);
        setWorkWeekSelectList(workWeekSelect);
        setStartBreakTime(startBreakTime);
        setEndBreakTime(endBreakTime);
      }
    }
  });

  useEffect(() => {
    if (wsState?.handleOtWorkingStandardHours && wsState?.workingTemplateIdx) {
      wsState?.handleOtWorkingStandardHours({
        startTime,
        endTime,
        workWeekType,
        dayOfWeekType,
        workWeekSelect: workWeekSelectList,
        dayOfWeek: dayOfWeekList,
        startBreakTime,
        endBreakTime,
        breakTimeType: Break_Time_Type.Basic,
        timeSequence: 1,
        workingTemplateIdx: wsState?.workingTemplateIdx
      });
    }
  }, [
    startTime,
    endTime,
    workWeekType,
    dayOfWeekType,
    workWeekSelectList,
    dayOfWeekList,
    startBreakTime,
    endBreakTime,
    wsState?.handleOtWorkingStandardHours,
    wsState?.workingTemplateIdx
  ]);

  useEffect(() => {
    switch (dayOfWeekType) {
      case Day_Of_Week_Type.All:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat,
          Day_Type.Sun
        ]);
        break;
      case Day_Of_Week_Type.MonToSat:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat
        ]);
        break;
      case Day_Of_Week_Type.MonToFri:
        setDayOfWeekList([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri
        ]);
        break;
    }
  }, [dayOfWeekType]);

  useEffect(() => {
    if (workWeekType === Work_Week_Type.All) {
      setWorkWeekSelectList([1, 2, 3, 4, 5]);
    }
  }, [workWeekType]);

  useEffect(() => {
    if (wsState?.workingTemplateIdx) {
      getStandardWTBasicInfo({
        variables: {
          workingTemplateIdx: wsState?.workingTemplateIdx
        }
      });
    }
  }, [wsState?.workingTemplateIdx, getStandardWTBasicInfo]);

  useEffect(() => {
    if (wsState?.handleOtWorkingStandardHourList) {
      wsState?.handleOtWorkingStandardHourList(list);
    }
  }, [list, wsState?.handleOtWorkingStandardHourList]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"기본 근무시간 설정"} />
        <TDTypeHelp />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title="출근 시간">
          <SSelect
            id="startTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id="startTimeHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startTime.split(":")[0])}
            onChange={event => {
              const hours = startTime.split(":")[0];
              const minute = startTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startTimeHour">{`시`}</label>
          <SSelect
            id="startTimeMin"
            minWidth="50px"
            maxWidth="50px"
            value={startTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startTimeMin">{`분`}</label>
        </FormRow>
        <FormRow title="퇴근 시간">
          <SSelect
            id="endTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id="endTimeHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endTime.split(":")[0])}
            onChange={event => {
              const hours = endTime.split(":")[0];
              const minute = endTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endTimeHour">{`시`}</label>
          <SSelect
            id="endTimeMin"
            minWidth="50px"
            maxWidth="50px"
            value={endTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endTimeMin">{`분`}</label>
        </FormRow>
        <FormRow title={"근무주차 설정"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id={`workWeekType:${Work_Week_Type.All}`}
              minWidth="15px"
              maxWidth="15px"
              checked={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekType(Work_Week_Type.All);
              }}
            />
            <label
              htmlFor={`workWeekType:${Work_Week_Type.All}`}
            >{`모든 주`}</label>
            <StyleInput
              type="radio"
              id={`workWeekType:${Work_Week_Type.InputMethod}`}
              minWidth="15px"
              maxWidth="15px"
              checked={workWeekType === Work_Week_Type.InputMethod}
              onChange={() => {
                setWorkWeekType(Work_Week_Type.InputMethod);
              }}
            />
            <label
              htmlFor={`workWeekType:${Work_Week_Type.InputMethod}`}
            >{`직접선택`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무주차 선택"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="selectWeek:1"
              minWidth="15px"
              maxWidth="15px"
              name="selectWeek:1"
              checked={workWeekSelectList.includes(1)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(1)) {
                    return newList.filter(item => item !== 1);
                  } else {
                    newList.push(1);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor="selectWeek:1">{`1주`}</label>
            <StyleInput
              type="checkbox"
              id="selectWeek:2"
              minWidth="15px"
              maxWidth="15px"
              name="selectWeek:2"
              checked={workWeekSelectList.includes(2)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(2)) {
                    return newList.filter(item => item !== 2);
                  } else {
                    newList.push(2);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor="selectWeek:2">{`2주`}</label>
            <StyleInput
              type="checkbox"
              id="selectWeek:3"
              minWidth="15px"
              maxWidth="15px"
              name="selectWeek:3"
              checked={workWeekSelectList.includes(3)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(3)) {
                    return newList.filter(item => item !== 3);
                  } else {
                    newList.push(3);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor="selectWeek:3">{`3주`}</label>
            <StyleInput
              type="checkbox"
              id="selectWeek:4"
              minWidth="15px"
              maxWidth="15px"
              name="selectWeek:4"
              checked={workWeekSelectList.includes(4)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(4)) {
                    return newList.filter(item => item !== 4);
                  } else {
                    newList.push(4);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor="selectWeek:4">{`4주`}</label>
            <StyleInput
              type="checkbox"
              id="selectWeek:5"
              minWidth="15px"
              maxWidth="15px"
              name="selectWeek:5"
              checked={workWeekSelectList.includes(5)}
              disabled={workWeekType === Work_Week_Type.All}
              onChange={() => {
                setWorkWeekSelectList(list => {
                  const newList: number[] = [...list];
                  if (newList.includes(5)) {
                    return newList.filter(item => item !== 5);
                  } else {
                    newList.push(5);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor="selectWeek:5">{`5주`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무요일 설정"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="dayOfWeekType:ALL"
              minWidth="15px"
              maxWidth="15px"
              checked={dayOfWeekType === Day_Of_Week_Type.All}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.All);
              }}
            />
            <label htmlFor="dayOfWeekType:ALL">{`모든 요일`}</label>
            <StyleInput
              type="radio"
              id="dayOfWeekType:monToFri"
              minWidth="15px"
              maxWidth="15px"
              checked={dayOfWeekType === Day_Of_Week_Type.MonToFri}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.MonToFri);
              }}
            />
            <label htmlFor="dayOfWeekType:monToFri">{`주중(월~금)`}</label>
            <StyleInput
              type="radio"
              id="dayOfWeekType:monToSat"
              minWidth="15px"
              maxWidth="15px"
              checked={dayOfWeekType === Day_Of_Week_Type.MonToSat}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.MonToSat);
              }}
            />
            <label htmlFor="dayOfWeekType:monToSat">{`주중(월~토)`}</label>
            <StyleInput
              type="radio"
              id="dayOfWeekType:self"
              minWidth="15px"
              maxWidth="15px"
              checked={dayOfWeekType === Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.InputMethod);
              }}
            />
            <label htmlFor="dayOfWeekType:self">{`직접선택`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무요일 선택"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id={Day_Type.Mon}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Mon}
              checked={dayOfWeekList.includes(Day_Type.Mon)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Mon)) {
                    return newList.filter(item => item !== Day_Type.Mon);
                  } else {
                    newList.push(Day_Type.Mon);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Mon}>{`월`}</label>
            <StyleInput
              type="checkbox"
              id={Day_Type.Tue}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Tue}
              checked={dayOfWeekList.includes(Day_Type.Tue)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Tue)) {
                    return newList.filter(item => item !== Day_Type.Tue);
                  } else {
                    newList.push(Day_Type.Tue);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Tue}>{`화`}</label>
            <StyleInput
              type="checkbox"
              id={Day_Type.Wed}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Wed}
              checked={dayOfWeekList.includes(Day_Type.Wed)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Wed)) {
                    return newList.filter(item => item !== Day_Type.Wed);
                  } else {
                    newList.push(Day_Type.Wed);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Wed}>{`수`}</label>
            <StyleInput
              type="checkbox"
              id={Day_Type.Thr}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Thr}
              checked={dayOfWeekList.includes(Day_Type.Thr)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Thr)) {
                    return newList.filter(item => item !== Day_Type.Thr);
                  } else {
                    newList.push(Day_Type.Thr);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Thr}>{`목`}</label>
            <StyleInput
              type="checkbox"
              id={Day_Type.Fri}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Fri}
              checked={dayOfWeekList.includes(Day_Type.Fri)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Fri)) {
                    return newList.filter(item => item !== Day_Type.Fri);
                  } else {
                    newList.push(Day_Type.Fri);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Fri}>{`금`}</label>
            <StyleInput
              type="checkbox"
              id={Day_Type.Sat}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Sat}
              checked={dayOfWeekList.includes(Day_Type.Sat)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Sat)) {
                    return newList.filter(item => item !== Day_Type.Sat);
                  } else {
                    newList.push(Day_Type.Sat);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Sat}>{`토`}</label>
            <StyleInput
              type="checkbox"
              id={Day_Type.Sun}
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              value={Day_Type.Sun}
              checked={dayOfWeekList.includes(Day_Type.Sun)}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekList(list => {
                  const newList: Day_Type[] = [...list];
                  if (newList.includes(Day_Type.Sun)) {
                    return newList.filter(item => item !== Day_Type.Sun);
                  } else {
                    newList.push(Day_Type.Sun);
                  }
                  return newList;
                });
              }}
            />
            <label htmlFor={Day_Type.Sun}>{`일`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="휴게시간 설정">
          <SSelect
            id="startBreakTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id="startBreakTimeHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startBreakTime.split(":")[0])}
            onChange={event => {
              const hours = startBreakTime.split(":")[0];
              const minute = startBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startBreakTimeHour">{`시`}</label>
          <SSelect
            id="startBreakTimeMin"
            minWidth="50px"
            maxWidth="50px"
            value={startBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startBreakTimeMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            id="endHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            id="endBreakTimeHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endBreakTime.split(":")[0])}
            onChange={event => {
              const hours = endBreakTime.split(":")[0];
              const minute = endBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endBreakTimeHour">{`시`}</label>
          <SSelect
            id="endBreakTimeMin"
            minWidth="50px"
            maxWidth="50px"
            value={endBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endBreakTimeMin">{`분`}</label>
        </FormRow>
        <FormRow title=""></FormRow>
      </ContentContainer>
      <WDAdditionalSetting
        list={list}
        addExtraDaySettingList={addExtraDaySettingList}
        deleteExtraDaySettingList={deleteExtraDaySettingList}
        handleList={handleList}
      />
      <ButtonContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          onClick={() => {
            wsState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </ButtonContainer>
    </SectionContainer>
  );
}

export default TDType;
