import * as React from "react";

const useAllDay = () => {
  const [isAllDay, setIsAllDay] = React.useState<boolean>(true);

  const handleIsAllDay = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsAllDay(event.target.checked);
    },
    []
  );

  return { isAllDay, handleIsAllDay, setIsAllDay };
};

export default useAllDay;
