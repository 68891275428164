import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import { useCallback } from "react";

interface IProps {
  data: Partial<DayInfo>[];
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const ItemContainer = styled.div<{
  backgroundColor?: string;
  labelColor?: string;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  -webkit-box-shadow: ${props =>
    props.labelColor ? `inset 6px 0px 0px 0px ${props.labelColor}` : ``};
  -moz-box-shadow: ${props =>
    props.labelColor ? `inset 6px 0px 0px 0px ${props.labelColor}` : ``};
  box-shadow: ${props =>
    props.labelColor ? `inset 6px 0px 0px 0px ${props.labelColor}` : ``};
`;

const Item = styled.div`
  display: flex;
  flex: 1;
  padding-left: 7px;
  align-items: center;
`;

const MiniCalendarEventList = <P extends IProps>({ data }: P) => {
  const Row = useCallback(
    ({ index, style }: IRenderRow) => {
      return (
        <ItemContainer
          style={style}
          backgroundColor={data[index].backgroundColor}
          labelColor={data[index].labelColor}
        >
          <Item>{data[index].totalTime}</Item>
        </ItemContainer>
      );
    },
    [data]
  );

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              height={height}
              itemCount={data.length}
              itemSize={25}
              width={width}
            >
              {Row}
            </List>
          );
        }}
      </AutoSizer>
    </Container>
  );
};

export default MiniCalendarEventList;
