import styled from "styled-components";
import StyledColumnContainer from "../../shared/styled_column_container";
import {
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import Spinner from "../../shared/spinner";
import * as ReactTable from "react-table";
import {
  Is_Active,
  UserDailyWorkPlanEntity,
  UserMonthlyWorkPlanEntity,
  useGetUserDailyWorkPlanLazyQuery
} from "../../../generated/graphql";
import MUserDailyWorkHeader from "./m_user_daily_work_header";
import StyledInput from "../../shared/styled_input";
import { Cell } from "../../../../types/@react-table/react-table/react-table";

const Table = lazy(() => import("../../table/table"));

interface IProps {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  user: Partial<UserMonthlyWorkPlanEntity> | undefined;
}

const COLUMN_FOR_VALUE = {
  isCheckStatus: "확인유무",
  startDate: "날짜",
  weekDayText: "요일",
  startTime: "출근(계획)",
  endTime: "퇴근(계획)",
  inCheckDatetime: "출근(인정)",
  outCheckDatetime: "퇴근(인정)",
  restAdmitMin: "휴게시간(인정)",
  recognitionAdmitTime: "근무시간(인정)",
  payTime: "급여시간"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

export interface IUserDailyWorkPlanEntity
  extends Partial<UserDailyWorkPlanEntity> {}

const ContentContainer = styled(StyledColumnContainer)`
  flex: 20;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const Container = styled(StyledColumnContainer)`
  overflow-x: hidden;
  width: 0px;
`;
const TableContainer = styled.div`
  display: flex;
  flex: 10;
  overflow-y: hidden;
  overflow-x: auto;
  will-change: scroll-position;
`;

const Content = styled.div`
  display: flex;
  flex: 5;
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  white-space: pre-line;
  align-items: center;
  justify-content: center;
`;

const UserDetail = styled.ul`
  flex: 1;
`;

function MMonthlyWorkInfo({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  user
}: IProps) {
  const [isSearch, setIsSearch] = useState<boolean>(true);
  const [getUserDailyWorkPlan, { data }] = useGetUserDailyWorkPlanLazyQuery({
    onError(error) {
      console.log(error);
      setIsSearch(false);
    },
    onCompleted() {
      setIsSearch(false);
    }
  });

  const columns: ReactTable.Column<IUserDailyWorkPlanEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const smallWidth = 70;
    let newListOfColumn: ReactTable.Column<Partial<UserDailyWorkPlanEntity>>[] =
      listOfColumn.map(item => {
        let width: number = 120;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
          COLUMN_FOR_VALUE.isCheckStatus
        ) {
          width = smallWidth;
        }
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
          COLUMN_FOR_VALUE.weekDayText
        ) {
          width = 30;
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE],
          accessor: item as TYPE_OF_EMPLOYEE,
          Cell(cell: Cell<IUserDailyWorkPlanEntity>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.isCheckStatus
            ) {
              return (
                <StyledInput
                  type="checkbox"
                  checked={cell.row.isSelected}
                  onChange={event => {
                    event.stopPropagation();
                    if (cell.row.isSelected) {
                      cell.row.toggleRowSelected(false);
                    } else {
                      cell.row.toggleRowSelected(true);
                    }
                  }}
                />
              );
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.payTime
            ) {
              return cell.value ?? "(협의중)";
            }
            return cell.value ?? "";
          },
          width
        };
      });
    return newListOfColumn;
  }, []);

  const list = useMemo(() => {
    return data?.getUserDailyWorkPlan.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<IUserDailyWorkPlanEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ReactTable.Row<IUserDailyWorkPlanEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  useEffect(() => {
    if (user?.employeeId && isSearch) {
      getUserDailyWorkPlan({
        variables: {
          callEmployeeId: user.employeeId,
          employeeIdList: [user.employeeId],
          startDate,
          endDate,
          take: 31
        }
      });
    }
  }, [getUserDailyWorkPlan, startDate, endDate, user?.employeeId, isSearch]);

  const recognitionPlanTime: string = useMemo(() => {
    if (user?.recognitionPlanTime) {
      const hours = user?.recognitionPlanTime.split(":")[0];
      const minutes = user?.recognitionPlanTime.split(":")[1];
      return `${hours}시간 ${minutes}분`;
    }
    return "";
  }, [user?.recognitionPlanTime]);

  const recognitionAdmitTime: string = useMemo(() => {
    if (user?.recognitionAdmitTime) {
      const hours = user?.recognitionAdmitTime.split(":")[0];
      const minutes = user?.recognitionAdmitTime.split(":")[1];
      return `${hours}시간 ${minutes}분`;
    }
    return "";
  }, [user?.recognitionAdmitTime]);

  useLayoutEffect(() => {
    if (list.length > 0) {
      table.rows.forEach(item => {
        if (item.original.isCheckStatus === Is_Active.Active) {
          table.toggleRowSelected(item.id, true);
        }
      });
    }
  }, [list, table]);

  return (
    <ContentContainer>
      <Description>{`근로자의 개인사정 또는 회사의 업무상 필요에 의하여
아래와 같이 소정근로시간을 변경함에 동의 합니다.`}</Description>
      <UserDetail>
        <li>{`부서: ${user?.departmentName ?? ""}`}</li>
        <li>{`사번: ${user?.employeeId ?? ""}`}</li>
        <li>{`이름: ${user?.employeeName ?? ""}`}</li>
        <li>{`근무(계획): ${
          recognitionPlanTime ? `${recognitionPlanTime}` : ""
        }`}</li>
        <li>{`근무(인정): ${
          recognitionAdmitTime ? `${recognitionAdmitTime}` : ""
        }`}</li>
      </UserDetail>
      <Content>
        <Container>
          <MUserDailyWorkHeader
            startDate={startDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setIsSearch={setIsSearch}
            table={table}
            employeeId={user?.employeeId}
          />
          <Suspense fallback={<Spinner />}>
            <TableContainer>
              <Table
                table={table}
                title="월간 근무"
                selectedRow={selectedRow}
                isCustomSelect
              />
            </TableContainer>
          </Suspense>
        </Container>
      </Content>
    </ContentContainer>
  );
}

export default MMonthlyWorkInfo;
