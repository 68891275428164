import moment from "moment";

interface IMakeXPositionOutput {
  xPosition: number;
}

interface IMakeEachXPosition {
  textWidth: number;
  regularXPosition: number;
}

interface IMakeEachXPositionOutput {
  fillRect: {
    x: number;
    y: number;
    textX: number;
    textY: number;
  };
  fillText: {
    x: number;
    y: number;
  };
}

class MakeTooltip {
  constructor(
    protected x: number,
    protected y: number,
    protected label?: string,
    protected realworkmin?: string,
    protected realextmin?: string,
    protected data?: number | null
  ) {}
  makeText(): string {
    const label = this.label;
    const realworkmin = this.realworkmin;
    const realextmin = this.realextmin;
    let text = "";

    if (!(label === "정규" || label === "연장")) {
      return text;
    }

    let value = parseInt(realworkmin ?? "0");
    if (label === "연장") {
      value = parseInt(realextmin ?? "0");
    }
    if (value > -1 && typeof value === "number") {
      const hours = Math.floor(moment.duration(value, "minutes").asHours());
      const min = value % 60;
      text = `${hours}h ${min}m`;
    }
    return text;
  }

  makeXPosition(): IMakeXPositionOutput {
    const data = this.data ?? 0;
    const label = this.label;
    const x = this.x;
    const realworkmin = this.realworkmin ? parseInt(this.realworkmin) : 0;
    const realextmin = this.realextmin ? parseInt(this.realextmin) : 0;

    let xPosition = 0;
    if ((label === "정규" || label === "연장") && data > 0) {
      if (label === "정규") {
        xPosition = (x * realworkmin) / data;
      }

      if (label === "연장" && data > 0 && realextmin >= 0) {
        xPosition = (x * realextmin) / data;
      }
    }

    return {
      xPosition
    };
  }

  makeOverTimeXPosition(regularXPosition: number): IMakeXPositionOutput {
    const data = this.data ?? 0;
    const label = this.label;
    const x = this.x;
    const realextmin = this.realextmin ? parseInt(this.realextmin) : 0;

    let xPosition = 0;
    if (regularXPosition > 0) {
      if (label === "연장" && realextmin >= 0) {
        if (data < 1) {
          xPosition = x - regularXPosition;
        } else {
          xPosition = ((x - regularXPosition) * realextmin) / data;
        }
        xPosition += regularXPosition;
      }
    }

    return {
      xPosition
    };
  }

  makeEachXPosition({
    textWidth,
    regularXPosition
  }: IMakeEachXPosition): IMakeEachXPositionOutput {
    const { xPosition } = this.makeXPosition();
    const { xPosition: overTimeXPosition } =
      this.makeOverTimeXPosition(regularXPosition);
    const y = this.y;
    const label = this.label;
    const newXPosition = label === "연장" ? overTimeXPosition : xPosition;
    let fillRect = {
      x: newXPosition - (textWidth + 10) / 2,
      y: y - 35,
      textX: textWidth + 10,
      textY: 20
    };
    let fillText = {
      x: newXPosition - textWidth / 2,
      y: y - 20
    };
    if (label === "연장") {
      fillRect.y = y + 15;
      fillRect.textY = 15;
      fillText.y = y + 26;
    }
    return {
      fillRect,
      fillText
    };
  }
}

export default MakeTooltip;
