import HelpContainer from "../../../../shared/help_container/help_container";
import InfoIcon from "../../../../shared/info-icon/info-icon";

function EachWeekHelp() {
  return (
    <InfoIcon
      minHeight="630px"
      height="630px"
      minWidth="400px"
      title="주차별 근무시간 설정"
    >
      <HelpContainer>
        <pre>{`탄력 근무제는의 편성 주차별 또는 요일별로  근무 시간 적용이 가능합니다.`}</pre>
        <ul>
          <li>{`주차`}</li>
          <pre>{`“근무 편성 기간 설정”에 따라 편성 가능한 주차가 변경됩니다.`}</pre>
          <li>{`주차별 근무 편성 시간`}</li>
          <pre>{`해당 주차의 근무시간 합계시간이 자동 연산되어 각 주차 밑에 표시 됩
니다.`}</pre>
          <li>{`주별 설정`}</li>
          <pre>{`근무시간 설정 방식이 “주별 근무시간” 또는 “주별 출/퇴근시간”인 경우 
근무시간을 주단위로 일괄 적용하기 위한 “주별 설정” 항목이 활성화 됩
니다. 설정 방식이 “근무시간”인지 “출/퇴근시간”인지에 따라 설정 항목
이 변경 됩니다.`}</pre>
          <li>요일별 설정</li>
          <pre>{`근무시간 설정 방식이 “일별 근무시간” 또는 “일별 출/퇴근시간”인 경우 
근무 요일에 해당 하는 설정 창이 활성화 됩니다. 설정 방식이 “근무시
간”인지 “출/퇴근시간”인지에 따라 설정 항목이 변경 됩니다.`}</pre>
          <li>{`근무시간`}</li>
          <pre>{`각 주차 또는 요일의 일별 총 근무시간만 변경하는 경우 사용합니다.
기본 근무시간과 설정 시간이 다른 경우 퇴근 시간이 조절 됩니다.`}</pre>
          <li>{`출/퇴근 시간`}</li>
          <pre>{`각 주차 또는 요일 별로 출/퇴근 시간을 개별 설정 하는 경우 사용합니
다. 기본 설정의 출/퇴근 시간을 기본 값으로 갖습니다.`}</pre>
          <li>{`휴게 시간`}</li>
          <pre>{`각 주차 또는 요일 별로 휴게 시간을 개별 설정 하는 경우 사용합니다.
변경하지 않는 경우 기본 휴게 시간이 적용 됩니다.`}</pre>
          <li>{`총 근무시간 표시`}</li>
          <pre>{`설정하신 총 근무시간을 합산하여 하단에 표시 하며, 총 근무시간 합산
이 설정 가능 근무 시간보다 많은 경우 저장 하실수 없습니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default EachWeekHelp;
