import { useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import listOfApolloVar from "../../../apollo/apollo-var";
import {
  ApprovalEntity,
  ApprovalSearchType,
  Approval_For_List_Type,
  useCountListOfApprovalLazyQuery,
  useGetListOfApprovalLazyQuery
} from "../../../generated/graphql";
import useNewSortBy from "../../../hooks/use-new-sort-by/use-new-sort-by";
import usePageControl from "../../../hooks/use-page-control/use-page-control";
import EChannel from "../../../Utils/EChannel/e-channel";
import { SearchFieldValues } from "../../asonic-table/asonic-search";
import ApprovalDetailPopup, {
  ApprovalType,
  IApprovalDetailMessage
} from "../approval-detail-popup";
import ApprovalListForm from "./approval-list-form";
import { PopUpContainer } from "./approval-waiting-list";

const title = "결재 완료함";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

function ListOfCompletedApproval() {
  const isRequest = useRef<boolean>(false);
  const { fieldSort, handleFieldSort } = useNewSortBy();
  const [selectedRow, setSelectedRow] = useState<ApprovalEntity | undefined>(
    undefined
  );

  const [showApprovalPopup, setShowApprovalPopup] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  const handleSelectedRowIndex = useCallback((index: number) => {
    setSelectedRowIndex(index);
  }, []);

  const handleShowApprovalPopup = useCallback((value: boolean) => {
    setShowApprovalPopup(value);
  }, []);
  const approvalData = useReactiveVar(listOfApolloVar.approvalDataVar);
  const approvalType = useReactiveVar(listOfApolloVar.approvalTypeVar);

  const handleSelectedRow = useCallback((row: ApprovalEntity) => {
    setSelectedRow(row);
  }, []);

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [searchType, setSearchType] = useState<ApprovalSearchType>(
    ApprovalSearchType.All
  );
  const [searchValue, setSearchValue] = useState("");

  const bc = useMemo(() => {
    if (selectedRow) {
      return new BroadcastChannel(EChannel.APPROVAL);
    }
    return;
  }, [selectedRow]);

  const [getListOfCompletedApproval, { data, loading }] =
    useGetListOfApprovalLazyQuery({
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (data.getListOfApproval.ok && data.getListOfApproval.list) {
          if (isRequest.current) {
            setSelectedRowIndex(data.getListOfApproval.list?.length - 1);
            isRequest.current = false;
          }
        }
      }
    });

  const [countListOfApproval, { data: countData, loading: countLoading }] =
    useCountListOfApprovalLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type) {
        const INIT_PAGE = 1;
        setSearchType(data.type as ApprovalSearchType);
        setSearchValue(data.value ?? "");
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.value as string);
      }
    },
    [handleCurrentPage]
  );

  const list: ApprovalEntity[] = useMemo(() => {
    return data?.getListOfApproval.list || [];
  }, [data]);

  const total: number = useMemo(() => {
    return countData?.countListOfApproval.count ?? 0;
  }, [countData]);

  const totalPage = useMemo(() => Math.ceil(total / take), [total, take]);

  const handleSelectForm = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        if (
          typeof selectedRowIndex === "number" &&
          selectedRowIndex < list.length - 1
        ) {
          const nextIndex = selectedRowIndex + 1;
          handleSelectedRowIndex(nextIndex);
        } else if (currentPage < totalPage) {
          handleSelectedRowIndex(0);
          handleCurrentPage(currentPage + 1);
        }
      } else {
        if (typeof selectedRowIndex === "number" && selectedRowIndex > 0) {
          const preIndex = selectedRowIndex - 1;
          handleSelectedRowIndex(preIndex);
        } else if (currentPage > 1) {
          handleCurrentPage(currentPage - 1);
          if (!isRequest.current) {
            isRequest.current = true;
          }
        }
      }
    },
    [selectedRowIndex, list, handleSelectedRowIndex, currentPage, total]
  );

  useEffect(() => {
    if (list.length > 0 && !isRequest.current) {
      const selectedRow = list[selectedRowIndex];
      listOfApolloVar.approvalDataVar(selectedRow);
    }
  }, [list, selectedRowIndex]);

  useEffect(() => {
    const payload = {
      approvalType: Approval_For_List_Type.Complete,
      searchType,
      searchValue,
      take,
      page: currentPage,
      fieldSort
    };
    getListOfCompletedApproval({
      variables: payload
    });
  }, [
    searchValue,
    searchType,
    getListOfCompletedApproval,
    take,
    currentPage,
    fieldSort
  ]);

  useEffect(() => {
    const payload = {
      approvalType: Approval_For_List_Type.Complete,
      searchType,
      searchValue
    };
    countListOfApproval({
      variables: payload
    });
  }, [searchValue, searchType, countListOfApproval]);

  useEffect(() => {
    if (bc && selectedRow) {
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = {
          type: ApprovalType.COMPLETE,
          data: selectedRow,
          isRequest: false
        };
        bc.postMessage(message);
        setSelectedRow(undefined);
        bc.close();
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, selectedRow]);

  return (
    <Container>
      {showApprovalPopup ? (
        <PopUpContainer>
          <ApprovalDetailPopup
            approvalData={approvalData}
            newApprovalType={approvalType}
            handleShowApprovalPopup={handleShowApprovalPopup}
            handleSelectForm={handleSelectForm}
          />
        </PopUpContainer>
      ) : (
        <ApprovalListForm
          list={list}
          title={title}
          fieldSort={fieldSort}
          handleFieldSort={handleFieldSort}
          // downloadExcel={downloadExcel}
          // handleSelectRow={handleSelectRow}
          isLoading={loading ?? countLoading}
          // selectedRow={selectedRow}
          handleSelectedRow={handleSelectedRow}
          handleShowApprovalPopup={handleShowApprovalPopup}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          take={take}
          handleTake={handleTake}
          handleSearch={handleSearch}
          handleSelectedRowIndex={handleSelectedRowIndex}
          total={total}
        />
      )}
    </Container>
  );
}

export default ListOfCompletedApproval;
