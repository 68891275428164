import { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import Auth from "../../Auth/Auth";
import { useSelector, useDispatch } from "react-redux";
import { getWorkingHours } from "../../redux/modules/main-view/mainViewReducer";
import { categorySelectionActionCreator } from "../../redux/modules/main-view/categorySelectionReducer";
import { getRequestDocsForCal } from "../../redux/modules/main-view/request/requestReducer";
import { globalNotiOn } from "../../redux/modules/main-view/mainViewReducer";
import { WEB_SERVER_ADDR } from "../../constValues";
import selectedItemHook from "../../hooks/selectedItemHook";
import { getTreeData } from "../../Utils/commonAxiosCall";
import MainViewContext from "../../components/main-view/store";
import MainView from "../../components/main-view/MainView";
import { setUserInfo } from "../../redux/modules/sign-in/signInReducer";
import { useLocation, useNavigate } from "react-router-dom";

const MainViewContainer = props => {
  //hooks
  const [isTreeData, setisTreeData] = useState(false);
  const [drawerOpen, setDrawOpen] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleDrawerOpen = useCallback(() => {
    if (drawerOpen) {
      setDrawOpen(false);
    } else {
      setDrawOpen(true);
    }
  }, [drawerOpen]);

  const { selectedItem: treeData, handleSelectedItem: handleTreeData } =
    selectedItemHook([]);

  const isMounted = useRef(false);

  // getReducer
  const signInReducer = useSelector(state => state.signInReducer);
  const mainViewReducer = useSelector(state => state.mainViewReducer);

  // dispatch
  const dispatch = useDispatch();
  const setUser = useCallback(
    userInfo => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );
  const getCategoriesDispatch = useCallback(
    () => dispatch(categorySelectionActionCreator.getCategories()),
    [dispatch]
  );
  const getWorkingHoursDispatch = useCallback(
    getWorkingHoursInfo => dispatch(getWorkingHours(getWorkingHoursInfo)),
    [dispatch]
  );
  const getRequestDocsForCalDispatch = useCallback(
    getRequestDocsForCalInfo =>
      dispatch(getRequestDocsForCal(getRequestDocsForCalInfo)),
    [dispatch]
  );
  const globalNotiOnDispatch = useCallback(
    payload => dispatch(globalNotiOn(payload)),
    [dispatch]
  );

  const moveToPage = useCallback(async () => {
    let url = pathname;
    if (url[url.length - 1] === "/") {
      url = pathname.slice(0, pathname.length - 1);
    }
    if (signInReducer.name === "" || signInReducer.employee_id === "") {
      const token = Auth.getUserToken();

      if (!token) {
        navigate("/");
        return;
      }

      let restApiUri = `${WEB_SERVER_ADDR}/api/mainview`;
      let res;
      try {
        res = await axios.get(restApiUri, {
          headers: {
            token: Auth.getUserToken()
          }
        });
      } catch {
        navigate("/");
        Auth.deauthenticateUser();
      } finally {
        if (res) {
          setUser(res.data);
        }
        return;
      }
    }
  }, [
    navigate,
    pathname,
    setUser,
    signInReducer.employee_id,
    signInReducer.name
  ]);

  const newProps = {
    signInReducer,
    mainViewReducer,
    setUser,
    getCategoriesDispatch,
    getWorkingHoursDispatch,
    getRequestDocsForCalDispatch,
    globalNotiOnDispatch,
    treeData,
    drawerOpen,
    handleDrawerOpen,
    isTreeData
  };

  useEffect(() => {
    moveToPage();
  }, [moveToPage]);

  // 근무관리정보 데이터 가져오기
  useEffect(() => {
    if (signInReducer.employee_id && !isMounted.current) {
      const waitGetTreeData = () => {
        getTreeData(signInReducer.employee_id)
          .then(({ data: { ok, data, error } }) => {
            if (ok) {
              handleTreeData(data);
            } else {
              console.log(error);
            }
            setisTreeData(true);
            isMounted.current = true;
          })
          .catch(error => {
            console.log(error);
          });
      };
      waitGetTreeData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [handleTreeData, signInReducer.employee_id]);

  return (
    <MainViewContext.Provider value={newProps}>
      <MainView {...props} {...newProps} />
    </MainViewContext.Provider>
  );
};

export default MainViewContainer;
