import { useState } from "react";

const useSearchTerm = () => {
  const [term, setTerm] = useState("");
  const updateTerm = event => {
    setTerm(event.target.value);
  };
  return { term, updateTerm };
};

export default useSearchTerm;
