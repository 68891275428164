import { useState, useEffect } from "react";

const useSearchIndex = searchedDe => {
  const [searchSelectedIndex, setSearchSelectedIndex] = useState(0);

  useEffect(() => {
    setSearchSelectedIndex(0);
  }, [searchedDe]);

  const upItem = () => {
    if (searchedDe.length > 0 && searchSelectedIndex > 0) {
      setSearchSelectedIndex(searchSelectedIndex - 1);
    }
  };

  const downItem = () => {
    if (searchedDe.length > 0 && searchSelectedIndex < searchedDe.length - 1) {
      setSearchSelectedIndex(searchSelectedIndex + 1);
    }
  };
  return { searchSelectedIndex, upItem, downItem, setSearchSelectedIndex };
};

export default useSearchIndex;
