import styled from "styled-components";
import ContentContainer from "../../../shared/content_container/content_container";
import ChoiceDayGraph from "./choice_day_graph";
import ChoiceHourGraph from "./choice_hour_graph";
import * as ReactTable from "react-table";
import {
  Day_Type,
  OtSelfWorkingStandardHoursEntity,
  useGetSelfDeterminedWhGraphLazyQuery
} from "../../../../generated/graphql";
import { useEffect, useState } from "react";
import { SelfDeterminedWeekGraphEntity } from "../../../../generated/graphql";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";

interface IProps {
  selectedTemplate?: ReactTable.Row<OtSelfWorkingStandardHoursEntity>;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 2px;
  border: 3px solid ${props => props.theme.colors.frameGrey};
  padding: 10px 0px;
  user-select: none;
`;

function ChoiceDayHourGraph({ selectedTemplate }: IProps) {
  const [selectedDayName, setSelectedDayName] = useState<string>();
  const [weekDayOfWeekInfo, setWeekDayOfWeekInfo] = useState<
    SelfDeterminedWeekGraphEntity[]
  >([]);

  const [selectedDay, setSelectedDay] =
    useState<SelfDeterminedWeekGraphEntity>();
  const [getSelfDeterminedWHGraph] = useGetSelfDeterminedWhGraphLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getSelfDeterminedWHGraph.ok &&
        data.getSelfDeterminedWHGraph.weekDayOfWeekInfo
      ) {
        setWeekDayOfWeekInfo(data.getSelfDeterminedWHGraph.weekDayOfWeekInfo);
      } else if (
        !data.getSelfDeterminedWHGraph.ok &&
        data.getSelfDeterminedWHGraph.error
      ) {
        handleErrorLog(data.getSelfDeterminedWHGraph.error);
      }
    }
  });
  useEffect(() => {
    if (selectedDayName) {
      switch (selectedDayName) {
        case "월":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Mon) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Mon
              });
            }
          });
          break;
        case "화":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Tue) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Tue
              });
            }
          });
          break;
        case "수":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Wed) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Wed
              });
            }
          });
          break;
        case "목":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Thr) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Thr
              });
            }
          });
          break;
        case "금":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Fri) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Fri
              });
            }
          });
          break;
        case "토":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Sat) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Sat
              });
            }
          });
          break;
        case "일":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Sun) {
              setSelectedDay(item);
            } else {
              setSelectedDay({
                dayOfWeek: Day_Type.Sun
              });
            }
          });
          break;
      }
    }
  }, [selectedDayName, weekDayOfWeekInfo]);

  useEffect(() => {
    if (selectedTemplate?.original.workingTemplateIdx) {
      getSelfDeterminedWHGraph({
        variables: {
          workingTemplateIdx: selectedTemplate.original.workingTemplateIdx,
          workingTimeName: selectedTemplate.original.workingTimeName
        }
      });
    }
  }, [getSelfDeterminedWHGraph, selectedTemplate]);

  return (
    <ContentContainer>
      <Container>
        <ChoiceDayGraph
          weekDayOfWeekInfo={weekDayOfWeekInfo}
          selectedTemplate={selectedTemplate}
          selectedDayName={selectedDayName}
          setSelectedDayName={setSelectedDayName}
        />
        <ChoiceHourGraph
          selectedTemplate={selectedTemplate}
          selectedDay={selectedDay}
        />
      </Container>
    </ContentContainer>
  );
}

export default ChoiceDayHourGraph;
