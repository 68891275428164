const ROOT_PATH = "/";
const MAIN_VIEW_PATH = "/mainview/";
const NO_AUTH = "/noauth/";
const WEB_SERVER_ADDR =
  process.env.REACT_APP_WEB_SERVER || "http://localhost:18081";

const HOME = `${MAIN_VIEW_PATH}home`;
const PERSONAL = `${MAIN_VIEW_PATH}personal`;
const WORKING_MANAGEMENT_INFORMATION = `${MAIN_VIEW_PATH}work_management_information`;

const RTC = "rtc";
const ROOM = (id?: string) => `${RTC}:${id}`;
const CHART = "chart";
const CALENDAR = `${MAIN_VIEW_PATH}calendar`;
const SUPER_ADMIN_SETTINGS = `${MAIN_VIEW_PATH}super-admin-settings`;

const ELECTRON = `/electron`;
const ELECTRON_SERVICE_STATUS = `${ELECTRON}/service-status`;
const ELECTRON_CALENDAR = `${ELECTRON}/calendar`;
const ELECTRON_CHAT = `${ELECTRON}/chat`;

// 결재관리
const APPROVAL = "approval";
const DASHBOARD = "dashboard";
const LIST_OF_APPROVAl = "list";
const TEMPORARY_DOCUMENT = "temporary-document";
const SETTING = "setting";
const APPROVAL_POPUP = "popup";
const APPROVAL_REQUEST_POPUP = "request-popup";
const SELECT_APPROVAL_FORM_POPUP = "select-approval-form";
const APPROVAL_PROCESS = "approval-process";
const SELECT_USERS_FOR_FORM = "select-users-for-form";

// 통계
const STATISTICS = "statistics";
const ADMIN = "admin";
const USER = "user";

//근무관리
const WORK_MANAGEMENT_INFORMATION = "work-management-information";

//차량주행관리
const VEHICLE_DRIVING_MANAGEMENT = "vehicle-driving-management";

//예약정보관리
const RESERVATION_MANAGEMENT = `reservation-management`;

//환경설정
const SETTINGS = `settings`;
const ANNUAL = `annual`;
const WORKING_SYSTEM = `working-system`;
const ASONIC_WORKING_SYSTEM = `asonic-working-system`;

//모바일
const MOBILE = `/m${MAIN_VIEW_PATH}`;
const MOBILE_HOME = `${MOBILE}home`;
const MOBILE_REQUEST = `${MOBILE}request`;
const MOBILE_APPROVAL = `${MOBILE}${APPROVAL}`;
const MOBILE_SETTINGS = `${MOBILE}${SETTINGS}`;

// 모바일 v2
const MOBILE_V_2 = `/m`;
const MOBILE_WORK_INFO = `${MOBILE_V_2}/work-info`;

const page = {
  root: ROOT_PATH,
  main: MAIN_VIEW_PATH,
  home: HOME,
  personal: PERSONAL,
  workingManagementInformation: WORKING_MANAGEMENT_INFORMATION,
  settings: `${MAIN_VIEW_PATH}${SETTINGS}`,
  annualEnvSetting: `${MAIN_VIEW_PATH}${SETTINGS}/${ANNUAL}`,
  workingSystem: `${MAIN_VIEW_PATH}${SETTINGS}/${WORKING_SYSTEM}`,
  asonicWorkingSystem: `${MAIN_VIEW_PATH}${SETTINGS}/${ASONIC_WORKING_SYSTEM}`,
  noAuth: NO_AUTH,
  room: ROOM,
  chart: CHART,
  rtc: RTC,
  webServerAddr: WEB_SERVER_ADDR,
  calendar: CALENDAR,
  superAdminSettings: SUPER_ADMIN_SETTINGS,
  vehicleDrivingManagement: `${MAIN_VIEW_PATH}${VEHICLE_DRIVING_MANAGEMENT}`,
  reservationManagement: `${MAIN_VIEW_PATH}${RESERVATION_MANAGEMENT}`,
  electron: ELECTRON,
  electronServiceStatus: ELECTRON_SERVICE_STATUS,
  electronCalendar: ELECTRON_CALENDAR,
  electronChat: ELECTRON_CHAT,
  approval: `${MAIN_VIEW_PATH}${APPROVAL}`,
  approvalDashboard: `${MAIN_VIEW_PATH}${APPROVAL}/${DASHBOARD}`,
  approvalList: `${MAIN_VIEW_PATH}${APPROVAL}/${LIST_OF_APPROVAl}`,
  approvalTemporaryDocument: `${MAIN_VIEW_PATH}${APPROVAL}/${TEMPORARY_DOCUMENT}`,
  approvalSetting: `${MAIN_VIEW_PATH}${APPROVAL}/${SETTING}`,
  approvalPopup: `/${APPROVAL}/${APPROVAL_POPUP}`,
  approvalRequestPopup: `/${APPROVAL}/${APPROVAL_REQUEST_POPUP}`,
  approvalProcess: `/${APPROVAL}/${APPROVAL_PROCESS}`,
  approvalSelectUsers: `/${APPROVAL}/${SELECT_USERS_FOR_FORM}`,
  selectApprovalFormPopup: `/${APPROVAL}/${SELECT_APPROVAL_FORM_POPUP}/${APPROVAL_POPUP}`,
  statisticsAdmin: `${MAIN_VIEW_PATH}${STATISTICS}/${ADMIN}`,
  statisticsUser: `${MAIN_VIEW_PATH}${STATISTICS}/${USER}`,
  workManagementInformation: `${MAIN_VIEW_PATH}${WORK_MANAGEMENT_INFORMATION}`,
  mobile: MOBILE,
  mobileHome: MOBILE_HOME,
  mobileRequest: MOBILE_REQUEST,
  mobileApproval: MOBILE_APPROVAL,
  mobileSettings: MOBILE_SETTINGS,
  mobileV2: MOBILE_V_2,
  mobileWorkInfo: MOBILE_WORK_INFO
};

export default page;
