import {
  Container,
  TitleBar,
  Title,
  Time,
  ChartSection,
  ChartTitleBar,
  ChartTitle,
  ChartDescription,
  ChartContainer,
  ChartDescriptionTitle,
  ChartDescriptionContent
} from "./styled";
import WeeklyTotalWorkingHourChart from "./weekly-total-workingHour-chart";
import { Reducers } from "../../../../../../types/reducers";
import { useSelector } from "react-redux";
import useWeeklyTotalWorkingHour from "../../../../../hooks/electron-hook/service-status-hook/weekly-total-working-hour-hook/use-weekly-total-working-hour";

const ElectronWeeklyTotalWorkingHour = () => {
  const { employee_id: employeeId } = useSelector(
    (state: Reducers) => state.signInReducer
  );
  const {
    weeklyTotalWorkingHour,
    regularLevDisplay,
    overTimeLevDisplay,
    regularChartData,
    overTimeChartData
  } = useWeeklyTotalWorkingHour({ employeeId });

  return (
    <Container data-testid="electron-weekly-total-working-hour">
      <TitleBar>
        <Title>금주 총 근무시간</Title>
        <Time>{weeklyTotalWorkingHour}</Time>
      </TitleBar>
      <ChartSection>
        <ChartTitleBar>
          <ChartTitle>정규 근무시간</ChartTitle>
        </ChartTitleBar>
        <ChartContainer>
          <WeeklyTotalWorkingHourChart {...regularChartData} max={2400} />
        </ChartContainer>
        <ChartDescription>
          <ChartDescriptionTitle>잔여시간</ChartDescriptionTitle>
          <ChartDescriptionContent>{regularLevDisplay}</ChartDescriptionContent>
        </ChartDescription>
      </ChartSection>
      <ChartSection>
        <ChartTitleBar>
          <ChartTitle>연장 근무시간</ChartTitle>
        </ChartTitleBar>
        <ChartContainer>
          <WeeklyTotalWorkingHourChart {...overTimeChartData} max={720} />
        </ChartContainer>
        <ChartDescription>
          <ChartDescriptionTitle>잔여시간</ChartDescriptionTitle>
          <ChartDescriptionContent>
            {overTimeLevDisplay}
          </ChartDescriptionContent>
        </ChartDescription>
      </ChartSection>
    </Container>
  );
};

export default ElectronWeeklyTotalWorkingHour;
