import * as React from "react";
import {
  SerializeItem,
  GoogleEvent,
  ListOfGoogleColor
} from "../../../hooks/link-google-hooks/use-google-calendar";

const CalendarContext = React.createContext<{
  googleCalendarlist: SerializeItem[];
  googleEventOfList: GoogleEvent[];
  listOfGoogleColor: ListOfGoogleColor;
  isOpen: boolean;
  handleOpenDialog: (value: boolean) => void;
  dialogTitle?: string;
  handleDialogTitle?: (value: string) => void;
  selectedEvent?: GoogleEvent;
  handleSelectedEvent: (selectItem?: GoogleEvent) => void;
  currentMoment?: string;
  handleIsRequestData?: (value: boolean) => void;
}>({
  googleCalendarlist: [],
  googleEventOfList: [],
  listOfGoogleColor: { calendar: {}, event: {} },
  isOpen: false,
  handleOpenDialog: (value: boolean) => {},
  handleSelectedEvent: (selectItem?: GoogleEvent) => {}
});

export default CalendarContext;
