import AsonicIconButton from "../shared/asonic-icon-button/asonic-icon-button";
import styled from "styled-components";
import SendIcon from "@iconify/icons-clarity/circle-arrow-solid";
import { useCallback, useContext, useEffect } from "react";
import { ChatContext, ChatHandleContext } from "./chat";
import { Reducers } from "../../../types/reducers";
import { useSelector } from "react-redux";
import { TRANS_TOKEN } from "../../hooks/use-socket/use-socket";
import { useForm } from "react-hook-form";

interface FieldValues {
  message: string;
}

const Container = styled.form`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
  padding: 10px;
  svg {
    font-size: 24px;
  }
`;

const Textarea = styled.textarea`
  display: flex;
  flex: 1;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.colors.black};
  resize: none;
  padding: 8px;
`;

function ChatWrite() {
  const {
    signInReducer: { employee_id, name }
  } = useSelector((state: Reducers) => state);

  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm<FieldValues>({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const handleMessage = useCallback(
    ({ message }: FieldValues) => {
      if (chatContext?.socket && chatContext.selectedChatRoomIdx && message) {
        const regex = /'/g;
        const tagRe = /(<([^>]+)>)/gi;
        const newMessage = message.replace(tagRe, "").replace(regex, `''`);
        const payload = {
          type: "message",
          body: {
            authToken: TRANS_TOKEN,
            roomId: chatContext.selectedChatRoomIdx,
            employeeId: employee_id,
            name,
            message: newMessage
          }
        };
        chatHandleContext?.handleIsScrollInChatRoom(false);
        chatContext?.socket.send(JSON.stringify(payload));
        setValue("message", "");
      }
    },
    [chatContext, employee_id, name, setValue, chatHandleContext]
  );

  useEffect(() => {
    if (errors.message?.type === "maxLength") {
      const message = getValues("message").slice(0, 2040);
      setValue("message", message);
      clearErrors("message");
    }
  }, [errors.message, setValue, getValues, clearErrors]);

  return (
    <Container onSubmit={handleSubmit(handleMessage)}>
      <Textarea
        {...register("message", { required: true, maxLength: 2040 })}
        rows={1}
      />
      <AsonicIconButton icon={SendIcon} onClick={handleSubmit(handleMessage)} />
    </Container>
  );
}

export default ChatWrite;
