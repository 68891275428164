import { Icon } from "@iconify/react";
import PlayIcon from "@iconify/icons-foundation/play";
import ForwardIcon from "@iconify/icons-foundation/fast-forward";
import StyledFlexContainer from "../shared/styled_flex_container";
import { useMemo } from "react";
import styled from "styled-components";

interface IProps {
  currentPage: number;
  totalPage: number;
  handleCurrentPage: (value: number) => void;
}

const Container = styled(StyledFlexContainer)`
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  user-select: none;
`;

const PageSpanContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const PageSpan = styled.div<{ $page: number; $currentPage: number }>`
  display: flex;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: ${props => {
    if (props.$currentPage === props.$page) {
      return props.theme.colors.green;
    }
  }};
  &:hover {
    color: ${props => props.theme.colors.lightGreen};
  }
`;

const ArrowBtn = styled.div<{ $isLeft?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => (props.$isLeft ? `rotate(0.5turn)` : ``)};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.skyBlue};
  }
  &:active {
    transform: scale(0.9) ${props => (props.$isLeft ? `rotate(0.5turn)` : ``)};
  }
`;

function PageController({ currentPage, totalPage, handleCurrentPage }: IProps) {
  const listOfPage: number[] = useMemo(() => {
    const list: number[] = [];
    const initPage = Math.ceil(currentPage / 5);

    let totalCurrentPage = initPage * 5;
    if (totalCurrentPage === 0) {
      totalCurrentPage += 5;
    }
    if (totalPage < totalCurrentPage) {
      totalCurrentPage = totalPage;
    }

    let initFirstPage = totalCurrentPage - 5 + 1;
    if (initFirstPage <= 0) {
      initFirstPage = totalCurrentPage - totalCurrentPage + 1;
    }

    for (let i = initFirstPage; i <= totalCurrentPage; i++) {
      list.push(i);
    }

    return list;
  }, [currentPage, totalPage]);
  return (
    <Container>
      <PageSpanContainer>
        <ArrowBtn
          $isLeft
          onClick={() => {
            if (currentPage > 1) {
              handleCurrentPage(1);
            }
          }}
        >
          <Icon icon={ForwardIcon} fontSize={"18px"} />
        </ArrowBtn>
        <ArrowBtn
          $isLeft
          onClick={() => {
            if (currentPage > 1) {
              handleCurrentPage(currentPage - 1);
            }
          }}
        >
          <Icon icon={PlayIcon} fontSize={"18px"} />
        </ArrowBtn>
        {listOfPage.map((item, index) => (
          <PageSpan
            key={`${item} ${index}`}
            $currentPage={currentPage}
            $page={item}
            onClick={() => {
              handleCurrentPage(item);
            }}
          >
            {item}
          </PageSpan>
        ))}
        <ArrowBtn
          onClick={() => {
            if (currentPage < totalPage) {
              handleCurrentPage(currentPage + 1);
            }
          }}
        >
          <Icon icon={PlayIcon} fontSize={"18px"} />
        </ArrowBtn>
        <ArrowBtn
          onClick={() => {
            if (currentPage < totalPage) {
              handleCurrentPage(totalPage);
            }
          }}
        >
          <Icon icon={ForwardIcon} fontSize={"18px"} />
        </ArrowBtn>
      </PageSpanContainer>
    </Container>
  );
}

export default PageController;
