import SectionContainer from "../../shared/section_container/section_container";
import SubTitle from "../../shared/sub-title/sub-title";
import ContentContainer from "../../shared/grid_content_container/grid_content_container";
import FormRow from "../../shared/form-row/form-row";
import StyleInput from "../../inputs/style-input";
import TitleContainer from "../../shared/title_container/title_container";
import Button from "../../globalComponents/Button";
import RadioContainer from "../../shared/radio_container/radio_container";
import WSBottomContainer from "../w_s_bottom_container/w_s_bottom_container";
import {
  Is_Use,
  Widget_Setting_Type,
  useGetWidgetSettingInfoLazyQuery
} from "../../../generated/graphql";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import { useContext, useEffect, useState } from "react";
import { WS_STATE } from "../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";

function WidgetSetting() {
  const wSState = useContext(WS_STATE);
  const [asonicWidgetUse, setAsonicWidgetUse] = useState<Widget_Setting_Type>(
    Widget_Setting_Type.UnUse
  );
  const [asonicWidgetAutoStart, setAsonicWidgetAutoStart] = useState<Is_Use>(
    Is_Use.UnUse
  );

  const [getWidgetSettingInfo] = useGetWidgetSettingInfoLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getWidgetSettingInfo.ok && data.getWidgetSettingInfo.info) {
        const { asonicWidgetUse, asonicWidgetAutoStart } =
          data.getWidgetSettingInfo.info;
        setAsonicWidgetUse(asonicWidgetUse);
        setAsonicWidgetAutoStart(asonicWidgetAutoStart);
      } else if (data.getWidgetSettingInfo.error) {
        handleErrorLog(data.getWidgetSettingInfo.error);
      }
    }
  });

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      wSState?.setWidgetSettingInfo({
        workingTemplateIdx: wSState?.workingTemplateIdx,
        asonicWidgetUse,
        asonicWidgetAutoStart
      });
    }
  }, [
    wSState?.workingTemplateIdx,
    wSState?.setWidgetSettingInfo,
    asonicWidgetUse,
    asonicWidgetAutoStart
  ]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getWidgetSettingInfo({
        variables: {
          workingTemplateIdx: wSState?.workingTemplateIdx
        }
      });
    }
  }, [wSState?.workingTemplateIdx, getWidgetSettingInfo]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"위젯 설정"} />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title={"위젯 사용 설정"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="unused"
              minWidth="20px"
              maxWidth="20px"
              name="isWidgetSetting"
              checked={asonicWidgetUse === Widget_Setting_Type.UnUse}
              onChange={() => {
                setAsonicWidgetUse(Widget_Setting_Type.UnUse);
              }}
            />
            <label htmlFor="unused">{`미사용`}</label>
            <StyleInput
              type="radio"
              id="calendar"
              minWidth="20px"
              maxWidth="20px"
              name="isWidgetSetting"
              checked={asonicWidgetUse === Widget_Setting_Type.CalendarStyle}
              onChange={() => {
                setAsonicWidgetUse(Widget_Setting_Type.CalendarStyle);
              }}
            />
            <label htmlFor="calendar">{`스타일(달력)`}</label>
            <StyleInput
              type="radio"
              id="graph"
              minWidth="20px"
              maxWidth="20px"
              name="isWidgetSetting"
              checked={asonicWidgetUse === Widget_Setting_Type.GraphStyle}
              onChange={() => {
                setAsonicWidgetUse(Widget_Setting_Type.GraphStyle);
              }}
            />
            <label htmlFor="graph">{`스타일(그래프)`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"부팅시 자동 실행"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="unusedAutoBoot"
              minWidth="20px"
              maxWidth="20px"
              name="autoBoot"
              checked={asonicWidgetAutoStart === Is_Use.UnUse}
              onChange={() => {
                setAsonicWidgetAutoStart(Is_Use.UnUse);
              }}
            />
            <label htmlFor="unusedAutoBoot">{`미사용`}</label>
            <StyleInput
              type="radio"
              id="useAutoBoot"
              minWidth="20px"
              maxWidth="20px"
              name="autoBoot"
              checked={asonicWidgetAutoStart === Is_Use.Use}
              onChange={() => {
                setAsonicWidgetAutoStart(Is_Use.Use);
              }}
            />
            <label htmlFor="useAutoBoot">{`사용`}</label>
          </RadioContainer>
        </FormRow>
      </ContentContainer>
      <WSBottomContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          disabled={!wSState?.workingTemplateIdx}
          onClick={() => {
            wSState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </WSBottomContainer>
    </SectionContainer>
  );
}

export default WidgetSetting;
