import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserProfile from "../../components/main-view/smallcomponents/UserProfile";
import { signInActionCreator } from "../../redux/modules/sign-in/signInReducer";

const mapStateToProps = state => ({
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...signInActionCreator
    },
    dispatch
  )
});

const UserProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

export default UserProfileContainer;
