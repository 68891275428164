import RightMenuButton from "../../../../../Rightmenu/RightMenuButton";

type Props = {
  handleOpenDialog: (value: boolean) => void;
  isSelectedItem: boolean;
};

const PFMRightMenu = <P extends Props>({
  handleOpenDialog,
  isSelectedItem
}: P) => {
  return (
    <RightMenuButton
      title="자리비움(이석)정보 변경"
      handleClick={() => {
        if (isSelectedItem) {
          handleOpenDialog(true);
        }
      }}
    ></RightMenuButton>
  );
};

export default PFMRightMenu;
