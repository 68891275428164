import styled from "styled-components";

const CheckBoxListContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  :not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
`;

export default CheckBoxListContainer;
