import * as React from "react";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { ItemContainer, Item, ItemTitle, ItemList, TriangleI } from "./styled";
import RightMenuRow from "../right-menu-row";

interface IProps {
  title: string;
  list?: any[];
  subList?: any[];
  listHandleClick?: (data: any) => () => void;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isDivide?: boolean;
}

const RightMenuButton = (props: IProps) => {
  const { title, list, subList, handleClick, listHandleClick, isDivide } =
    props;
  return (
    <ItemContainer>
      <Item
        isList={!!list}
        onClick={event => {
          if (handleClick) {
            handleClick(event);
          }
        }}
      >
        {list && list.length > 0 && <TriangleI icon={faCaretRight} />}
        <ItemTitle>{title}</ItemTitle>
        {list && (
          <ItemList
            itemCount={subList ? list.length + subList.length : list.length}
          >
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <React.Fragment>
                    <List
                      height={height}
                      itemCount={
                        subList ? list.length + subList.length : list.length
                      }
                      itemData={subList ? [...list, ...subList] : list}
                      itemSize={30}
                      width={width}
                    >
                      {RightMenuRow({
                        listHandleClick,
                        isDivide,
                        listLength: list.length
                      })}
                    </List>
                  </React.Fragment>
                );
              }}
            </AutoSizer>
          </ItemList>
        )}
      </Item>
    </ItemContainer>
  );
};

export default RightMenuButton;
