import { useState } from "react";

const ApprovalDialogStateManager = () => {
  const [approved, setApproved] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [remarksComment, setRemarksComment] = useState("");

  const handleApproved = () => {
    if (approved) {
      setApproved(false);
    } else {
      setApproved(true);
    }
  };

  const handleRejected = () => {
    if (rejected) {
      setRejected(false);
    } else {
      setRejected(true);
    }
  };

  const handleRemarksComment = event => {
    setRemarksComment(event.target.value);
  };

  const handleApproveCheck = (name, makeApproveDecision) => event => {
    handleApproved();
    handleRejected();
    makeApproveDecision(name === "approved");
  };

  return {
    approved,
    rejected,
    remarksComment,
    handleRemarksComment,
    handleApproveCheck
  };
};

export default ApprovalDialogStateManager;
