import {
  Container,
  DialogContainer,
  ApplyReasonContainer,
  ApplyTitle,
  ApplyReasonContents,
  ApprovalListContainer,
  ApprovalListTitle,
  ApprovalRemarksContainer
} from "./styled";
import approvalDialogStateManager from "./approvalStateManager";
import CheckBoxUi from "../../../../components/globalComponents/CheckBoxUi";
import TextField from "../../../../components/main-view/settings/TextField";
import styled from "styled-components";
import Button from "../../../../components/globalComponents/Button";

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
`;

const PLACEHOLDER_REMAKRS = "결재에 대한 비고를 작성하세요";

const MobileApprovalDialogView = props => {
  const {
    approved,
    rejected,
    remarksComment,
    handleRemarksComment,
    handleApproveCheck
  } = approvalDialogStateManager();

  const {
    data,
    selectedIndex,
    openDialog,
    handleOpenDialog,
    makeApproveDecision,
    setComment
  } = props;

  return (
    <Container openDialog={openDialog}>
      <DialogContainer>
        <ApplyReasonContainer>
          <ApplyTitle>신청 사유</ApplyTitle>
          <ApplyReasonContents>
            {selectedIndex !== -1 && data[selectedIndex].extend_descr}
          </ApplyReasonContents>
        </ApplyReasonContainer>
        <ApprovalListContainer>
          <ApprovalListTitle>결재 항목</ApprovalListTitle>
          <CheckBoxUi
            htmlFor="승인"
            title="승인"
            checked={approved}
            onChange={handleApproveCheck("approved", makeApproveDecision)}
          />
          <CheckBoxUi
            htmlFor="반려"
            title="반려"
            checked={rejected}
            onChange={handleApproveCheck("rejected", makeApproveDecision)}
          />
        </ApprovalListContainer>
        <ApprovalRemarksContainer>
          <TextField
            value={remarksComment}
            valueChange={handleRemarksComment}
            placeholder={PLACEHOLDER_REMAKRS}
            label="결재 비고"
            type="text"
          />
        </ApprovalRemarksContainer>
        <ButtonContainer>
          <Button onClick={handleOpenDialog(-1, false)}>취소</Button>
          <Button
            onClick={() => {
              setComment(remarksComment);
              handleOpenDialog(-1, true);
            }}
          >
            저장
          </Button>
        </ButtonContainer>
      </DialogContainer>
    </Container>
  );
};

export default MobileApprovalDialogView;
