import * as React from "react";

const useIsLoading = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleIsLoading = React.useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  return { isLoading, handleIsLoading };
};

export default useIsLoading;
