export interface IConvertedTime {
  isAM: boolean;
  hour: number;
  minute: number;
  second: number;
}

export default class DateManipulator {
  // _formatDateString은 HH:mm:ss 형식
  private _isAM: boolean;
  private _hour: number;
  private _minute: number;
  private _second: number;

  constructor(private _formatDateString: string = "09:00:00") {
    //  정규 표현식을 사용하여 HH:mm:ss 형식인지 확인
    const convertedTime = this.getConvertedTimeFromParam(_formatDateString);
    this._isAM = convertedTime.isAM;
    this._hour = convertedTime.hour;
    this._minute = convertedTime.minute;
    this._second = convertedTime.second;
  }

  getConvertedTime(): IConvertedTime {
    return {
      isAM: this._isAM,
      hour: this._hour,
      minute: this._minute,
      second: this._second
    };
  }

  convertMinToHourAndMin(min: number): { hour: number; minute: number } {
    const hour = Math.floor(min / 60);
    const minute = min % 60;
    return { hour, minute };
  }

  revertBackToMin(hour: number, minute: number): number {
    return hour * 60 + minute;
  }

  getConvertedTimeFromParam(
    formatDateString: string,
    defaultTime: string = "09:00:00"
  ): IConvertedTime {
    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    if (!timeFormatRegex.test(formatDateString)) {
      formatDateString = defaultTime;
    }
    const isAM = parseInt(formatDateString.split(":")[0]) < 12;
    const hour = parseInt(formatDateString.split(":")[0]) % 12;
    const minute = parseInt(formatDateString.split(":")[1]);
    const second = parseInt(formatDateString.split(":")[2]);
    return {
      isAM,
      hour,
      minute,
      second
    };
  }

  revertBackTimeFormat(
    amPm: string,
    hour: number,
    minute: number,
    second: number
  ): string {
    // padStart 사용해서 시간, 분, 초를 두 자리로 만들기
    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");
    const formattedSecond = second.toString().padStart(2, "0");
    if (amPm === "AM") {
      return `${formattedHour}:${formattedMinute}:${formattedSecond}`;
    } else {
      return `${hour + 12}:${formattedMinute}:${formattedSecond}`;
    }
  }
}
