import styled from "styled-components";
import AsonicDialog from "../../../../asonic-dialog/asonic-dialog";
import FormRow from "../../../../shared/form-row/form-row";
import StyledColumnContainer from "../../../../shared/styled_column_container";
import StyledInput from "../../../../shared/styled_input";
import ButtonContainer from "../../../../shared/button_container";
import * as ReactTable from "react-table";
import StyledButton from "../../../../shared/styled_button";
import { useState, useEffect } from "react";
import moment from "moment";
import GridContentContainer from "../../../../shared/grid_content_container/grid_content_container";
import StyleTextarea from "../../../../inputs/style-textarea";
import {
  AnnualStatusEntity,
  EditAnnualStatusMutationVariables,
  UserEntity,
  useGetUserLazyQuery
} from "../../../../../generated/graphql";
import StyledFlexContainer from "../../../../shared/styled_flex_container";

interface IProps {
  title: string;
  btnTitle: string;
  handleOpenDialog: (value: boolean) => void;
  handleEditor: (
    payload: Omit<EditAnnualStatusMutationVariables, "employeeIdList">
  ) => void;
  selectedRow?: ReactTable.Row<AnnualStatusEntity>;
  setUserListForAdd?: React.Dispatch<React.SetStateAction<string[]>>;
}

const Container = styled(StyledColumnContainer)`
  justify-content: center;
  ${ButtonContainer} {
    justify-content: flex-end;
  }
`;

const ContentContainer = styled(StyledFlexContainer)`
  gap: 5px;
  label {
    font-weight: bold;
  }
`;

const DataListContainer = styled(StyledColumnContainer)`
  flex: 0;
  ${StyledInput} {
    width: 200px;
  }
`;

const Select = styled.select`
  background-color: rgba(0, 0, 0, 0.6); /* 검은색 배경, 80% 투명도 */
  color: ${props => props.theme.colors.white};
`;

function AnnualStatusDialogEditor({
  title,
  btnTitle,
  handleOpenDialog,
  handleEditor,
  selectedRow,
  setUserListForAdd
}: IProps) {
  const [userName, setUserName] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [userList, setUserList] = useState<UserEntity[]>([]);

  const [annualDay, setAnnualDay] = useState<string>("");
  const [descr, setDescr] = useState<string>("");
  const [applyYear, setApplyYear] = useState(moment().format("YYYY"));

  const [getUser] = useGetUserLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      console.log(data.getUser);
      if (data.getUser.ok) {
        if (data.getUser.user) {
          setUserList(data.getUser.user.slice(0, 11));
        } else {
          setUserList([]);
        }
      } else if (data.getUser.error) {
        console.log(data.getUser.error);
      }
    }
  });

  useEffect(() => {
    if (selectedRow) {
      setApplyYear(selectedRow.original.applyYear ?? moment().format("YYYY"));
      setAnnualDay(
        selectedRow.original.annualDay
          ? `${selectedRow.original.annualDay}`
          : "0"
      );
      setDescr(selectedRow.original.descr ?? "");
    }
  }, [selectedRow]);

  useEffect(() => {
    if (userName.length > 1) {
      getUser({
        variables: {
          name: userName
        }
      });
    } else {
      setIsFocus(false);
    }
  }, [userName, getUser]);

  useEffect(() => {
    if (setUserListForAdd) {
      const newList = userList.map(item => item.employeeId ?? "");
      setUserListForAdd(newList);
    }
  }, [userList]);

  return (
    <AsonicDialog
      title={title}
      handleClose={(value: boolean) => {
        handleOpenDialog(value);
      }}
      width="650px"
      minWidth="650px"
      height="210px"
      minHeight="210px"
    >
      <Container>
        {setUserListForAdd && (
          <FormRow title="직원 검색">
            <ContentContainer>
              <DataListContainer>
                <StyledInput
                  list="user-list"
                  value={userName}
                  name="search"
                  onFocus={() => {
                    setIsFocus(true);
                  }}
                  onChange={event => {
                    setUserName(event.currentTarget.value);
                    setIsFocus(true);
                  }}
                />
                {isFocus && userList.length > 0 && (
                  <Select
                    onChange={event => {
                      if (event.currentTarget.value) {
                        userList.forEach(item => {
                          if (item.employeeId === event.currentTarget.value) {
                            setSelectedUser(item);
                            setUserName(item.name ?? item.employeeId);
                          }
                        });
                      }
                    }}
                    onBlur={() => {
                      setIsFocus(false);
                    }}
                  >
                    <option value="">{`사용자를 선택해주세요`}</option>
                    {userList.map(item => (
                      <option
                        key={item.employeeId}
                        value={item.employeeId}
                      >{`${item.name}(${item.employeeId})`}</option>
                    ))}
                  </Select>
                )}
              </DataListContainer>
              <label htmlFor="">
                {`선택된 직원 : ${selectedUser?.name ?? "없음"}${
                  selectedUser?.employeeId
                    ? `(${selectedUser?.employeeId})`
                    : ""
                }`}
              </label>
            </ContentContainer>
          </FormRow>
        )}
        <GridContentContainer flex={0}>
          <FormRow title="귀속년도">
            <StyledInput
              $width="100px"
              type="number"
              value={applyYear}
              placeholder={`예: ${new Date().getFullYear()}`}
              min={0}
              onChange={event => setApplyYear(event.currentTarget.value)}
            />
          </FormRow>
          <FormRow title="총 발생일">
            <StyledInput
              $width="100px"
              type="number"
              value={annualDay}
              min={0}
              max={99.9}
              onChange={event => setAnnualDay(event.currentTarget.value)}
            />
          </FormRow>
          <FormRow title="비고" gridNumber={1}>
            <StyleTextarea
              value={descr.replace(/\/nn/g, "\n")}
              onChange={event => setDescr(event.currentTarget.value)}
            />
          </FormRow>
        </GridContentContainer>
        <ButtonContainer>
          <StyledButton
            disabled={
              setUserListForAdd
                ? userList.length === 0 && !selectedUser
                : !applyYear
            }
            $maxWidth="50px"
            onClick={() => {
              handleEditor({
                searchYear: applyYear,
                annualDay: parseFloat(annualDay),
                descr
              });
            }}
          >
            {btnTitle}
          </StyledButton>
        </ButtonContainer>
      </Container>
    </AsonicDialog>
  );
}

export default AnnualStatusDialogEditor;
