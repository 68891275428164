import * as React from "react";
import serviceStatusApi from "../../../../../api/electron-api/service-status-api";
import moment from "moment";

type makeDatasetsParameter = {
  totalTime: number;
  remainTime: number;
  workType: typeof regularType | typeof extensionType;
};

type Datasets = {
  backgroundColor: string[];
  data: number[];
  borderWidth: number;
  barThickness: number;
};

type ChartData = {
  datasets: Datasets[];
  labels: string[];
};

type Props = {
  employeeId?: string;
};

const regularType = 1,
  extensionType = 2;

const useTotalWorkingHour = <P extends Props>({ employeeId }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [todayChartData, setTodayChartData] = React.useState<ChartData>({
    datasets: [
      {
        backgroundColor: ["#3d78b1", "#E5E5E5"],
        data: [0, 0],
        barThickness: 10,
        borderWidth: 1
      }
    ],
    labels: ["근무시간", "남은 근무시간"]
  });
  const [tomorrowChartData, setTomorrowChartData] = React.useState<ChartData>({
    datasets: [
      {
        backgroundColor: ["#3d78b1", "#E5E5E5"],
        data: [0, 0],
        barThickness: 10,
        borderWidth: 1
      }
    ],
    labels: ["근무시간", "남은 근무시간"]
  });

  const [todayRemainTime, setTodayRemainTime] = React.useState<string>("");
  const [tomorrowRemainTime, setTomorrowRemainTime] =
    React.useState<string>("");

  const makeDatasets = React.useCallback(
    ({ totalTime, remainTime, workType }: makeDatasetsParameter) => {
      let hours = 0;
      let minutes = 0;
      if (remainTime) {
        hours = Math.floor(moment.duration(remainTime, "minutes").asHours());
        minutes = remainTime - hours * 60;
      }
      let displayRemainTime = `${hours}시간 ${minutes}분`;
      const todayWorkTypeColor =
        workType === regularType ? "#3d78b1" : "#043571";

      let newDatasets = {
        datasets: [
          {
            backgroundColor: [todayWorkTypeColor, "#E5E5E5"],
            data: [totalTime, remainTime],
            barThickness: 10,
            borderWidth: 1
          }
        ],
        labels: ["근무시간", "남은 근무시간"]
      };
      if (!totalTime && !remainTime) {
        displayRemainTime = `0시간 0분`;
        newDatasets = {
          datasets: [
            {
              backgroundColor: [todayWorkTypeColor, "#E5E5E5"],
              data: [0, -1],
              barThickness: 10,
              borderWidth: 1
            }
          ],
          labels: ["근무시간", "남은 근무시간"]
        };
      }
      return { displayRemainTime, newDatasets };
    },
    []
  );

  const handleData = React.useCallback(async () => {
    try {
      if (employeeId && isMount.current) {
        const { data } = await serviceStatusApi.getTotalWorkingHour({
          employeeId
        });

        const {
          astimetype: todayWorkType,
          astotmin: todayAstotmin,
          asleavemin: todayAsworkLeavemin
        } = data[0];
        const {
          astimetype: tomorrowWorkType,
          astotmin: tomorrowAstotmin,
          asleavemin: tomorrowAsworkLeavemin
        } = data[1];

        const todayTotalWorkingHour = todayAstotmin - todayAsworkLeavemin;

        const {
          displayRemainTime: displayTodayRemainTime,
          newDatasets: newTodayDatasets
        } = makeDatasets({
          totalTime: todayTotalWorkingHour,
          remainTime: todayAsworkLeavemin,
          workType: todayWorkType
        });
        setTodayRemainTime(displayTodayRemainTime);
        setTodayChartData(newTodayDatasets);

        const tomorrowTotalWorkingHour =
          tomorrowAstotmin - tomorrowAsworkLeavemin;
        const {
          displayRemainTime: displayTomorrowRemainTime,
          newDatasets: newTomorrowDatasets
        } = makeDatasets({
          totalTime: tomorrowTotalWorkingHour,
          remainTime: tomorrowAsworkLeavemin,
          workType: tomorrowWorkType
        });
        setTomorrowRemainTime(displayTomorrowRemainTime);
        setTomorrowChartData(newTomorrowDatasets);
      }
    } catch (error) {}
  }, [employeeId, makeDatasets]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    handleData();
    const timeId = setInterval(handleData, 60000);
    return () => {
      clearInterval(timeId);
      isMount.current = false;
    };
  }, [handleData]);
  return {
    todayChartData,
    todayRemainTime,
    tomorrowChartData,
    tomorrowRemainTime
  };
};

export default useTotalWorkingHour;
