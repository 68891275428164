import * as React from "react";
import Collapsible from "react-collapsible";
import { Container, Trigger, Icon, ArrowIcon, TriggerTitle } from "./styled";
import { faLock, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
export interface IProps {
  title?: string;
  children?: React.ReactNode;
  fontAwesomeIconProps?: FontAwesomeIconProps;
}

const CollapsiblePanel = (props: IProps) => {
  const { title } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = React.useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  return (
    <Container>
      <Collapsible
        lazyRender={true}
        onTriggerOpening={() => {
          handleOpen(true);
        }}
        onTriggerClosing={() => {
          handleOpen(false);
        }}
        trigger={
          <Trigger>
            <TriggerTitle>
              <Icon icon={props.fontAwesomeIconProps?.icon || faLock} />
              {title ?? "Start here"}
            </TriggerTitle>
            <ArrowIcon icon={faChevronDown} open={isOpen} />
          </Trigger>
        }
        transitionTime={200}
        easing="ease-in-out"
      >
        {props.children}
      </Collapsible>
    </Container>
  );
};

export default CollapsiblePanel;
