import { ControllerRenderProps } from "react-hook-form";
import styled from "styled-components";
import {
  Is_Sign_Alarm_Type,
  Is_Sign_Password
} from "../../../../generated/graphql";
import CheckBoxUi from "../../../globalComponents/CheckBoxUi";
import StyleInput from "../../../inputs/style-input";
import FormRow from "../../../shared/form-row/form-row";
import SubTitle from "../../../shared/sub-title/sub-title";
import { IApprovalSettingFormValue } from "./approval-management-setting";
interface IProps {
  passwordField: ControllerRenderProps<IApprovalSettingFormValue, "password">;
  isSignPasswordField: ControllerRenderProps<
    IApprovalSettingFormValue,
    "isSignPassword"
  >;
  isSignEmailAlarmField: ControllerRenderProps<
    IApprovalSettingFormValue,
    "isSignEmailAlarm"
  >;
  isSignMobileAlarmField: ControllerRenderProps<
    IApprovalSettingFormValue,
    "isSignMobileAlarm"
  >;
}

interface IFormValue {
  password: string;
  isSignPassword: Is_Sign_Password;
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  isSignMobileAlarm: Is_Sign_Alarm_Type;
}

const Container = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

function ApprovalDetailSetting({
  passwordField,
  isSignPasswordField,
  isSignEmailAlarmField,
  isSignMobileAlarmField
}: IProps) {
  return (
    <Container>
      <SubTitle title="결재 설정" />
      <Content>
        <FormRow title="결재 비밀 번호">
          <StyleInput {...passwordField} type="password" autoComplete="on" />
        </FormRow>
        <FormRow title="결재 비밀 번호 사용">
          <CheckBoxUi
            {...isSignPasswordField}
            checked={isSignPasswordField.value === Is_Sign_Password.Used}
            htmlFor={"isApprovalPasswordUsed"}
            onChange={event => {
              if (event.target.checked) {
                isSignPasswordField.onChange(Is_Sign_Password.Used);
              } else {
                isSignPasswordField.onChange(Is_Sign_Password.UnUsed);
              }
            }}
            title="결재시 마다 비밀 번호를 입력합니다."
          />
        </FormRow>
        <FormRow title="결재 알림 수신 여부">
          <CheckBoxContainer>
            <CheckBoxUi
              {...isSignEmailAlarmField}
              checked={isSignEmailAlarmField.value === Is_Sign_Alarm_Type.Used}
              htmlFor={"isSignEmailAlarm"}
              onChange={event => {
                if (event.target.checked) {
                  isSignEmailAlarmField.onChange(Is_Sign_Alarm_Type.Used);
                } else {
                  isSignEmailAlarmField.onChange(Is_Sign_Alarm_Type.UnUsed);
                }
              }}
              title="이메일"
            />
            <CheckBoxUi
              checked={isSignMobileAlarmField.value === Is_Sign_Alarm_Type.Used}
              htmlFor={"isSignMobileAlarm"}
              onChange={event => {
                if (event.target.checked) {
                  isSignMobileAlarmField.onChange(Is_Sign_Alarm_Type.Used);
                } else {
                  isSignMobileAlarmField.onChange(Is_Sign_Alarm_Type.UnUsed);
                }
              }}
              title="모바일"
            />
          </CheckBoxContainer>
        </FormRow>
      </Content>
    </Container>
  );
}

export default ApprovalDetailSetting;
