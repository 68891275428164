import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../globalComponents/Button";
import { IEmployeeInformation } from "../../approval-process/list-of-selected-approval-user";
import ApprovalSelectUsersTreeAndListView from "./approval-select-users-tree-and-list-view";
import {
  EApprovalUserSelectorPacketSource,
  ESocketChannel,
  IApprovalUserSelectorPacket
} from "../types";
import { BUTTON_COLOR } from "../../../../screens/constants";
import {
  Approval_User_Type,
  useGetListOfUserForApprovalFormLazyQuery
} from "../../../../generated/graphql";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 10;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

// approval-process.tsx에서 복사해온 파일
// 참고할 내용이 있으면 해당 파일을 참고

function ApprovalSelectUsersOnNewWindow() {
  const { formTemplateIdx } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const employeeId = queryParams.get("employee_id");

  const bc = useMemo(
    () => new BroadcastChannel(ESocketChannel.APPROVAL_USER_SELECTOR_CHANNEL),
    []
  );
  const [listOfUser, setListOfUser] = useState<IEmployeeInformation[]>([]);

  const formIdx = useMemo(() => {
    return parseInt(formTemplateIdx?.slice(1) ?? "0");
  }, [formTemplateIdx]);

  // getListOfUserForApprovalForm를 정의하고
  // useEffect 같은 곳에서 getListOfUserForApprovalForm를 호출해서 실제 데이터를 가져온다
  const [getListOfUserForApprovalForm, { data, loading }] =
    useGetListOfUserForApprovalFormLazyQuery({
      fetchPolicy: "cache-and-network",
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        const employeeListFromApi =
          data.getListOfUserForApprovalForm.list?.map(v => {
            return {
              employeeId: v.employeeId,
              name: v.empName,
              userPosition: v.userPosition ?? "",
              department: v.departmentName,
              approvalType: Approval_User_Type.Approval
            };
          }) ?? [];
        setListOfUser(employeeListFromApi);
      }
    });
  const handleSetListOfUser = useCallback((list: IEmployeeInformation[]) => {
    setListOfUser(list);
  }, []);

  // variables에 파라미터로 넘길 인자를 넘겨준다 (object 형식)
  useEffect(() => {
    if (formIdx) {
      getListOfUserForApprovalForm({
        variables: {
          employeeId: employeeId ?? "",
          formTemplateIdx: formIdx
        }
      });
    }
  }, [getListOfUserForApprovalForm, formIdx, employeeId]);

  // 선택된 사용자를 부모 창에 WebSocket을 통해 전달하는 함수
  const handleSelectUsers = useCallback(() => {
    const message: IApprovalUserSelectorPacket = {
      source: EApprovalUserSelectorPacketSource.APPROVAL_USER_SELECTOR_VIEW,
      list: listOfUser.map(user => user.employeeId),
      formTemplateIdx: formTemplateIdx?.slice(1)
    };
    bc.postMessage(message);
    window.close();
  }, [bc, listOfUser, formTemplateIdx]);

  useEffect(() => {
    return () => {
      bc && bc.close();
    };
  }, [bc]);

  return (
    <Container>
      <Content>
        <ApprovalSelectUsersTreeAndListView
          handleSetListOfUser={handleSetListOfUser}
          listOfUserFromParent={listOfUser}
        />
      </Content>
      <ButtonContainer>
        <Button onClick={handleSelectUsers}>적용</Button>
        <Button
          backgroundColor={BUTTON_COLOR.CANCEL}
          hoverBackgroundColor={BUTTON_COLOR.CANCEL_HOVER}
          onClick={() => window.close()}
        >
          닫기
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default ApprovalSelectUsersOnNewWindow;
