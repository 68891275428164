import styled from "styled-components";
import AsonicExcel, { IAsonicExcelProps } from "./asonic-excel";
import AsonicSearch, { ISearchProps } from "./asonic-search";
import AsonicSearchWithDate, {
  IAsonicSearchWithDate
} from "./asonic-search-with-date";
import AsonicHideColumn, { IAsonicHideColumnProps } from "./asonic-hide-column";
import SubTitle from "../shared/sub-title/sub-title";
import AsonicPageSet, { IAsonicPageSetProps } from "./asonic-page-set";
export interface IAsonicTableTitleBarProps<P extends object>
  extends Partial<IAsonicPageSetProps>,
    IAsonicExcelProps,
    ISearchProps,
    IAsonicSearchWithDate,
    Partial<IAsonicHideColumnProps<P>> {
  title: string;
  total?: number;
  isPeople?: boolean;
  isFlexStart?: boolean;
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  span {
    width: max-content;
  }
  gap: 10px;
  padding: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const RightMenuContainer = styled.div<{ $isFlexStart?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.$isFlexStart ? "" : "flex-end")};
  gap: 10px;
`;

function AsonicTableTitleBar<P extends object>({
  title,
  total,
  isPeople = false,
  take,
  handleTake,
  downloadExcel,
  listOfSearchType,
  handleSearch,
  handleDate,
  columns,
  toggleHideColumn,
  isFlexStart,
  children
}: IAsonicTableTitleBarProps<P>) {
  return (
    <Container>
      <TitleContainer>
        <SubTitle title={title} />
        {total !== undefined && (
          <span>{`(전체 ${total}${isPeople ? "명" : "개"})`}</span>
        )}
      </TitleContainer>
      {columns && toggleHideColumn && (
        <AsonicHideColumn
          columns={columns}
          toggleHideColumn={toggleHideColumn}
          title={title}
        />
      )}
      {handleDate && <AsonicSearchWithDate handleDate={handleDate} />}
      {listOfSearchType && (
        <AsonicSearch
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
        />
      )}
      <RightMenuContainer $isFlexStart={isFlexStart}>
        {take && handleTake && (
          <AsonicPageSet take={take} handleTake={handleTake} />
        )}
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
        {children}
      </RightMenuContainer>
    </Container>
  );
}

export default AsonicTableTitleBar;
