import styled from "styled-components";
import TimeDescription from "../time_description/time_description";
import TimeGraphBox, { COLOR_TYPE } from "../time_graph_box/time_graph_box";
import TimeGraphLabel from "../time_graph_label/time_graph_label";
import { Work_Type } from "../../../generated/graphql";

export interface ITime {
  title: string;
  min: number;
  type: Work_Type;
  colorType: COLOR_TYPE;
}

interface ITimeLabel {
  labelName: string;
  colorType: COLOR_TYPE;
}

interface IDescription {
  description: string;
  emphasis?: string;
}

interface IProps {
  list: ITime[];
  labelList: ITimeLabel[];
  description?: IDescription;
  subDescription?: IDescription;
  topDescription?: IDescription;
  isBorder?: boolean;
}

const Container = styled.div<{ isBorder?: boolean }>`
  display: flex;
  flex: 1;
  gap: 2px;
  border: ${props =>
    props.isBorder ? `3px solid ${props.theme.colors.frameGrey}` : ""};
  padding: 5px 30px;
  justify-content: center;
  flex-direction: column;
  user-select: none;
`;

const GraphContainer = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;

const DisplayDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const TopDescription = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.colors.darkGrey};
`;

function DisplayTimeGraph({
  list,
  labelList,
  description,
  subDescription,
  topDescription,
  isBorder = true
}: IProps) {
  return (
    <Container isBorder={isBorder}>
      {topDescription && (
        <TopDescription>{topDescription.description}</TopDescription>
      )}
      <GraphContainer>
        {list.map(item => (
          <TimeGraphBox
            key={`${item.min}_${item.title}`}
            title={item.title}
            min={item.min}
            type={item.type}
            colorType={item.colorType}
            isDay={false}
          />
        ))}
      </GraphContainer>
      <DisplayDetail>
        {description && (
          <TimeDescription
            description={description?.description}
            emphasis={description?.emphasis}
          />
        )}
        <LabelContainer>
          {labelList.map((item, index) => (
            <TimeGraphLabel
              labelName={item.labelName}
              colorType={item.colorType}
              key={`${item.labelName}-${item.colorType}-${index}`}
            />
          ))}
        </LabelContainer>
      </DisplayDetail>
      {subDescription && (
        <DisplayDetail>
          <TimeDescription
            description={subDescription.description}
            emphasis={subDescription.emphasis}
          />
        </DisplayDetail>
      )}
    </Container>
  );
}

export default DisplayTimeGraph;
