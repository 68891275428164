import { useRef, useMemo, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { TooltipItem, TooltipModel } from "chart.js";

type Datasets = {
  type: string;
  backgroundColor: string;
  borderColor: string;
  data: number[];
  label?: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  pointRadius?: number;
  datalabels: {
    display: boolean;
    clamp?: boolean;
  };
};

export type Data = {
  labels: string[];
  datasets: Datasets[];
};

type Props = {
  chartData: any;
  stacked?: boolean;
  isMaster?: boolean;
  maxHours?: number;
};

const TotalWeeklyWorkingHoursChart = <P extends Props>({
  chartData,
  stacked,
  isMaster,
  maxHours
}: P) => {
  const ref = useRef<any>(null);

  const options: any = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom"
        },
        datalabels: {
          formatter: function (value: any, context: any) {
            const hours = Math.floor(
              moment.duration(value, "minutes").asHours()
            );
            const min = value % 60;
            if (context.datasetIndex === 0) {
              const time = `
              ${hours}h
              ${min}m
              `;
              return time;
            } else {
              return ``;
            }
          },
          align: 193,
          anchor: "end"
        },
        tooltip: {
          callbacks: {
            label: function (
              this: TooltipModel<"bar">,
              { raw }: TooltipItem<"bar">
            ) {
              const value: number = raw as number;
              let hours = value;
              let min = (value * 60) % 60;

              if (hours < 0) {
                return `0h 0m`;
              }

              if (!isMaster) {
                hours = Math.floor(moment.duration(value, "minutes").asHours());
                min = value % 60;
              }
              return `${hours}h ${min}m`;
            }
          }
        }
      },
      scales: {
        y: {
          max: maxHours,
          stacked,
          grid: {
            display: false
          },
          ticks: {
            stepSize: 20,
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 5,
            fontSize: 14,
            callback: function (value: number) {
              if (value < 0) {
                return `0h`;
              }
              let hours = value;
              if (!isMaster) {
                hours = Math.floor(moment.duration(value, "minutes").asHours());
              }
              return `${hours}h`;
            }
          }
        },
        x: {
          ticks: {
            fontSize: 14
          },
          stacked,
          grid: {
            display: false
          }
        }
      },
      elements: {
        line: {
          fill: false,
          borderWidth: 1
        },
        bar: {
          order: 2
        }
      }
    }),
    [isMaster, maxHours, stacked]
  );

  useEffect(() => {
    ref.current?.chartInstance?.update();
  }, [chartData]);

  return <Bar ref={ref} data={chartData} options={options} />;
};

export default TotalWeeklyWorkingHoursChart;
