import styled from "styled-components";

const AsonicTableHeader = styled.div<{
  isOver?: boolean;
  isFlex?: boolean;
  isLastFlex?: boolean;
  isOneColumn?: boolean;
  $maxWidth?: string;
}>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.4rem 0px;
  cursor: grab;
  background-color: ${props => {
    if (props.isOver === false) {
      return props.theme.colors.tomato;
    }
  }};
  max-width: ${props => (props.$maxWidth ? props.$maxWidth : "")};
  flex: ${props => (props.isFlex ? "1" : "none")};
  :last-child {
    flex: ${props => (props.isLastFlex ? "1" : "none")};
    margin-right: 17px;
  }
`;

export default AsonicTableHeader;
