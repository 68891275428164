import * as React from "react";
import personalApi from "../../../api/dashboard/personal-api";

type Data = {
  title: string;
  data: string;
  type?: string;
};

type Props = {
  userId: string;
};

const useMonthlyAbsenteeismTardinessStatus = <P extends Props>({
  userId
}: P) => {
  const isMounted = React.useRef<boolean>(false);
  const [period, setPeriod] = React.useState<string>("");
  const [mainData, setMainData] = React.useState<Data[]>([]);
  const [subData, setSubData] = React.useState<Data[]>([]);

  const handleData = React.useCallback(async () => {
    try {
      const params = {
        userId
      };
      const { data } = await personalApi.getMonthlyAbsenteeismTardiness(params);
      const result = data[0];

      setPeriod(result?.asperio);

      const newMainData = [
        {
          title: "총 근무일",
          data: result?.tot_workday ?? 0,
          type: "box"
        },
        { title: "휴일", data: result?.tot_holiday ?? 0, type: "box" }
      ];
      const newSubData = [
        { title: "정상근무", data: result?.tot_usecount_1 ?? "", type: "" },
        { title: "휴가", data: result?.tot_usecount_2 ?? "", type: "" },
        { title: "교육", data: result?.tot_usecount_3 ?? "", type: "" },
        { title: "출장", data: result?.tot_usecount_4 ?? "", type: "" },
        { title: "결근", data: result?.tot_usecount_5 ?? "", type: "" },
        { title: "지각", data: result?.tot_usecount_6 ?? "", type: "" },
        { title: "조퇴", data: result?.tot_usecount_7 ?? "", type: "" },
        { title: "병가", data: result?.tot_usecount_8 ?? "", type: "" },
        { title: "휴직", data: result?.tot_usecount_9 ?? "", type: "" }
      ];
      setMainData(newMainData);
      setSubData(newSubData);
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  React.useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true;
    }
    handleData();
    return () => {
      isMounted.current = false;
    };
  }, [handleData]);

  React.useEffect(() => {
    let timeId: NodeJS.Timeout;
    if (isMounted.current) {
      timeId = setInterval(handleData, 300000);
    }
    return () => {
      clearInterval(timeId);
    };
  }, [handleData]);
  return { mainData, subData, period };
};

export default useMonthlyAbsenteeismTardinessStatus;
