import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import BasicEditor from "../../basic-editor";
import Select from "../../globalComponents/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faBell,
  faCalendarAlt,
  faFileAlt
} from "@fortawesome/free-regular-svg-icons";
import {
  faMapMarkerAlt,
  faSync,
  faCalendarDay
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../globalComponents/Button";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import useBasicEditor from "../../../hooks/use-basic-editor";
import useAllDay from "../../../hooks/calendar-hooks/use-all-day/use-all-day";
import { SerializeItem } from "../../../hooks/link-google-hooks/use-google-calendar";
import useNativeSelect from "../../../hooks/use-native-select";
import { useRecurrence } from "../../../hooks/calendar-hooks/use-recurrence/use-recurrence";
import useHandleEvent from "../../../hooks/calendar-hooks/use-handle-event/use-handle-event";
import CalendarContext from "../asonic-calendar-context";
import useGoogleRole from "../../../hooks/link-google-hooks/use-google-role/use-google-role";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";

interface IProps {
  dialogTitle?: string;
  handleClose: (value: boolean) => void;
  items: SerializeItem[];
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  flex: 16;
  padding: 20px;
`;

const TimeContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: max-content;
  justify-content: flex-start;
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  select {
    width: auto;
    height: max-content;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  flex: 1;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  svg {
    min-width: 16px;
  }
`;

const Icon = styled(FontAwesomeIcon)``;

const AsonicExtraCalendarEventEditor = <P extends IProps>({
  items,
  dialogTitle,
  handleClose
}: P) => {
  const { selectedEvent, handleIsRequestData } =
    React.useContext(CalendarContext);

  const { isAllDay, handleIsAllDay, setIsAllDay } = useAllDay();
  const {
    value: summary,
    handleValue: handleSummaryValue,
    handleInvalid: handleSummaryInvalid,
    setValue: setSummary
  } = useBasicEditor({
    initValue: ""
  });
  const {
    value: location,
    handleValue: handleLocationValue,
    handleInvalid: handleLocationInvalid,
    setValue: setLocation
  } = useBasicEditor({
    initValue: ""
  });
  const {
    value: description,
    handleValue: handleDescriptionValue,
    setValue: setDescription
  } = useBasicEditor({
    initValue: ""
  });
  const { value: startDate } = useBasicEditor({
    initValue: moment(dialogTitle, "YYYY[년] M[월] DD[일] dddd").format(
      "YYYY-M-DD"
    )
  });
  const { value: endDate } = useBasicEditor({
    initValue: moment(dialogTitle, "YYYY[년] M[월] DD[일] dddd")
      .add(1, "days")
      .format("YYYY-M-DD")
  });
  const {
    value: startTime,
    handleValue: handleStartTime,
    setValue: setStartTime
  } = useBasicEditor({
    initValue: moment().format("HH:mm")
  });
  const {
    value: endTime,
    handleValue: handleEndTime,
    setValue: setEndTime
  } = useBasicEditor({
    initValue: moment().format("HH:mm")
  });
  const {
    value: reminder,
    handleValue: handleReminder,
    setValue: setReminder
  } = useBasicEditor({
    initValue: 30
  });
  const { selectedItem, handleSelectedItem, setSelectedItem } = useNativeSelect(
    items[0]?.data?.id || ""
  );
  const {
    listOfRecurrenceRule,
    selectedRecurrenceRule,
    handleSelectedRecurrenceRule,
    setSelectedRecurrenceRule
  } = useRecurrence({
    standardDate: moment(dialogTitle, "YYYY[년] M[월] DD[일] dddd").format(
      "YYYY-M-DD"
    )
  });
  const { handleInsertEvent, handleUpdateEvent, handleDeleteEvent } =
    useHandleEvent({
      handleIsRequestData,
      handleClose,
      calendarId: selectedItem,
      eventId: selectedEvent?.id,
      eventData: {
        summary,
        location,
        description,
        start: {
          date: isAllDay ? startDate : undefined,
          dateTime: !isAllDay
            ? moment(startDate, "YYYY-MM-DD").format(
                `YYYY-MM-DD[T]${startTime}:00+09:00`
              )
            : undefined,
          timeZone: "Asia/Seoul"
        },
        end: {
          date: isAllDay ? endDate : undefined,
          dateTime: !isAllDay
            ? moment(startDate, "YYYY-MM-DD").format(
                `YYYY-MM-DD[T]${endTime}:00+09:00`
              )
            : undefined,
          timeZone: "Asia/Seoul"
        },
        recurrence:
          selectedRecurrenceRule.length > 0
            ? selectedRecurrenceRule
            : undefined,
        reminders: {
          useDefault: false,
          overrides: [
            { method: "email", minutes: reminder },
            { method: "popup", minutes: reminder }
          ]
        }
      }
    });

  React.useEffect(() => {
    if (isAllDay) {
      setStartTime("00:00");
      setEndTime("00:00");
    }
  }, [setStartTime, setEndTime, isAllDay]);
  React.useEffect(() => {
    if (selectedEvent) {
      setSummary(selectedEvent.summary || "");
      setLocation(selectedEvent.location || "");
      setDescription(selectedEvent.description || "");
      if (
        selectedEvent.reminders?.overrides &&
        selectedEvent.reminders?.overrides.length > 0
      ) {
        setReminder(selectedEvent.reminders.overrides[0].minutes);
      }
      if (selectedEvent.start.date) {
        setIsAllDay(true);
      } else if (selectedEvent.start.dateTime) {
        setStartTime(moment(selectedEvent.start.dateTime).format("HH:mm"));
        setEndTime(moment(selectedEvent.end.dateTime).format("HH:mm"));
        setIsAllDay(false);
      }
      if (selectedEvent.recurrence) {
        setSelectedRecurrenceRule(selectedEvent.recurrence);
      }
      if (selectedEvent.creator.email) {
        setSelectedItem(selectedEvent.organizer.email);
      }
    }
  }, [
    selectedEvent,
    setSummary,
    setLocation,
    setDescription,
    setIsAllDay,
    setStartTime,
    setEndTime,
    setReminder,
    setSelectedRecurrenceRule,
    setSelectedItem
  ]);
  const { handleCalendarListByNotReader } = useGoogleRole();
  return (
    <AsonicDialog
      title={dialogTitle || "달력 이벤트 에디터"}
      handleClose={handleClose}
      width="420px"
      minWidth="420px"
    >
      <Container
        onSubmit={selectedEvent?.id ? handleUpdateEvent : handleInsertEvent}
      >
        <SelectContainer>
          <IconContainer>
            <Icon icon={faCalendarAlt} size="lg" />
          </IconContainer>
          <Select
            data={handleCalendarListByNotReader(items)}
            selectItem={handleSelectedItem}
            isHidingBorder={true}
            value={selectedItem}
          />
          <CheckBoxUi
            htmlFor="종일"
            onChange={handleIsAllDay}
            checked={isAllDay}
            title="종일"
          />
        </SelectContainer>
        <EditorContainer>
          <IconContainer>
            <Icon icon={faCalendarDay} size="lg" />
          </IconContainer>
          <BasicEditor
            label="이벤트 이름"
            isHideLabel={true}
            value={summary}
            handleValue={handleSummaryValue}
            handleInvalid={handleSummaryInvalid}
            placeholder="이벤트 이름"
            isHidingBorder={true}
            isAuto={true}
          />
        </EditorContainer>
        {!isAllDay && (
          <TimeContainer>
            <IconContainer>
              <Icon icon={faClock} size="lg" />
            </IconContainer>
            <BasicEditor
              label="시작시간"
              isHideLabel={true}
              value={startTime}
              handleValue={handleStartTime}
              handleInvalid={() => {}}
              type="time"
              isHidingBorder={true}
              isAuto={true}
            />
            <Span>부터</Span>
            <BasicEditor
              label="종료시간"
              isHideLabel={true}
              value={endTime}
              handleValue={handleEndTime}
              handleInvalid={() => {}}
              type="time"
              isHidingBorder={true}
              isAuto={true}
            />
          </TimeContainer>
        )}

        <EditorContainer>
          <IconContainer>
            <Icon icon={faMapMarkerAlt} size="lg" />
          </IconContainer>
          <BasicEditor
            label="위치"
            isHideLabel={true}
            value={location}
            handleValue={handleLocationValue}
            handleInvalid={handleLocationInvalid}
            placeholder="위치"
            isHidingBorder={true}
            isAuto={true}
          />
        </EditorContainer>
        <SelectContainer>
          <IconContainer>
            <Icon icon={faSync} size="lg" />
          </IconContainer>
          <Select
            data={listOfRecurrenceRule}
            value={
              selectedRecurrenceRule.length > 0
                ? selectedRecurrenceRule[0]
                : undefined
            }
            selectItem={handleSelectedRecurrenceRule}
            isHidingBorder={true}
          />
        </SelectContainer>
        <EditorContainer>
          <IconContainer>
            <Icon icon={faBell} size="lg" />
          </IconContainer>
          <BasicEditor
            label="미리알림(분)"
            value={reminder}
            handleValue={handleReminder}
            handleInvalid={() => {}}
            type="number"
            isHidingBorder={true}
            isAuto={true}
          />
        </EditorContainer>
        <EditorContainer>
          <IconContainer>
            <Icon icon={faFileAlt} size="lg" />
          </IconContainer>
          <BasicEditor
            label="설명"
            isTextArea={true}
            isHideLabel={true}
            value={description}
            handleValue={handleDescriptionValue}
            handleInvalid={() => {}}
            placeholder="설명"
          />
        </EditorContainer>
        <ButtonContainer>
          {selectedEvent?.id && (
            <Button onClick={handleDeleteEvent} name="delete" value="delete">
              삭제
            </Button>
          )}
          <Button type="submit" name="save" value="save">
            저장
          </Button>
        </ButtonContainer>
      </Container>
    </AsonicDialog>
  );
};

export default AsonicExtraCalendarEventEditor;
