import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainView from "../../moComponents/main-view/MainView";
import { categorySelectionActionCreator } from "../../redux/modules/main-view/categorySelectionReducer";
import { mainViewActionCreator } from "../../redux/modules/main-view/mainViewReducer";
import { requestActionCreator } from "../../redux/modules/main-view/request/requestReducer";
import { signInActionCreator } from "../../redux/modules/sign-in/signInReducer";

const mapStateToProps = state => ({
  mainViewReducer: state.mainViewReducer,
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...mainViewActionCreator,
      ...requestActionCreator,
      ...signInActionCreator,
      ...categorySelectionActionCreator
    },
    dispatch
  )
});

const MainViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainView);

export default MainViewContainer;
