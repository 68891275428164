import React from "react";
import { TitleContainer, Title, TimeContainer, TimeDivider } from "./styled";
import RequestDate from "../RequestDate/RequestDate";
import MobileRequestTime from "../MobileRequestTime";
import { getVoters } from "../../../../Utils/commonAxiosCall";

export const titleCreator = (uiString, activeStep, categoryId) => {
  let title;
  switch (activeStep) {
    case 0:
      title = uiString.dialog.title.request;
      break;
    case 1:
      categoryId === "12"
        ? (title = uiString.dialog.title.alternativeWork)
        : (title = uiString.dialog.title.searchApprover);
      break;
    case 2:
      title = uiString.dialog.title.searchApprover;
      break;
    default:
  }
  return (
    <TitleContainer>
      {title === uiString.dialog.title.request ? (
        <i className="fas fa-edit" />
      ) : (
        <i className="fas fa-search" />
      )}
      <Title>{title}</Title>
    </TitleContainer>
  );
};

export const dateCreator = (start, end, changeStartDate, changeEndDate) => {
  return (
    <React.Fragment>
      <RequestDate
        onChange={changeStartDate}
        isStart={true}
        startDate={start}
        label="시작날짜"
      />
      <RequestDate
        onChange={changeEndDate}
        isStart={false}
        startDate={end}
        label="종료날짜"
      />
    </React.Fragment>
  );
};

export const timeCreator = (
  startTime,
  endTime,
  handleStartTime,
  handleEndTime
) => {
  return (
    <TimeContainer>
      <MobileRequestTime
        onChange={handleStartTime}
        isStart={true}
        startTime={startTime}
        label="시작시간"
      />
      <TimeDivider>~</TimeDivider>
      <MobileRequestTime
        onChange={handleEndTime}
        isStart={false}
        endTime={endTime}
        label="종료시간"
      />
    </TimeContainer>
  );
};

const _handleSearchVoter = (departmentId, nameOfVoter, handleSearchResult) => {
  let searchResult = [];
  if (nameOfVoter.length < 2) {
    handleSearchResult(searchResult);
    return;
  }

  getVoters(departmentId, nameOfVoter)
    .then(voters => {
      if (!voters.data) return;
      let searchResult = [];
      for (let user of voters.data) {
        user["key"] = user.employee_id;
        searchResult.push(user);
      }
      handleSearchResult(searchResult);
    })
    .catch(error => console.log(error));
};

export const _handleKeyPress =
  (departmentId, nameOfVoter, handleSearchResult) => event => {
    if (event.key === "Enter" || event.button === 0) {
      _handleSearchVoter(departmentId, nameOfVoter, handleSearchResult);
    }
  };

export const _getDateFormatter = date => {
  const newDate = new Date(date);
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  return `${newDate.getFullYear()}-${month}-${day}`;
};

export const _getTimeFormatter = time => {
  if (typeof time === "string") {
    return time;
  } else {
    const newTime = new Date(time);
    let hour = newTime.getHours();
    let min = newTime.getMinutes();

    hour = hour < 10 ? `0${hour}` : hour;
    min = min < 10 ? `0${min}` : min;
    return `${hour}:${min}`;
  }
};
