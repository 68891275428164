class Approver {
  static setLocalApprover(approvers) {
    localStorage.setItem("approvers", approvers && JSON.stringify(approvers));
  }

  static getLocalApprover() {
    return localStorage.getItem("approvers");
  }

  static deleteLocalApprover() {
    localStorage.removeItem("approvers");
  }
}

export default Approver;
