import styled from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
`;

export const DialogContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Date = styled.input`
  background-color: #fff;
  background: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png");
  background-position: 97% 50%;
  background-repeat: no-repeat;
  min-width: 235px;
  ::-webkit-inner-spin-button {
    display: none;
  }
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  ::-webkit-clear-button {
    display: none;
  }
  ::-ms-clear {
    display: none;
  }
`;

export const Time = styled.input`
  ::-webkit-clear-button {
    display: none;
  }
  ::-ms-clear {
    display: none;
  }
`;

export const TimeDivider = styled.div`
  margin: 0 5px 0 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  width: 70%;
  min-width: 150px;
`;
