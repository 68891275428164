import { memo } from "react";
import {
  Day_Of_Week_Type,
  Day_Type,
  Work_Hours_Type
} from "../../../../../generated/graphql";
import useTimeControl from "../../../../../hooks/use_time_control/use_time_control";
import useTimeList, {
  HOUR_TYPE
} from "../../../../../hooks/use_time_list/use_time_list";
import StyleInput from "../../../../inputs/style-input";
import FormRow from "../../../../shared/form-row/form-row";
import GridContentContainer from "../../../../shared/grid_content_container/grid_content_container";
import RadioContainer from "../../../../shared/radio_container/radio_container";
import SSelect from "../../../../shared/s_select/s_select";

interface IProps {
  startTime: string;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  endTime: string;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
  settlementSectionHour: number;
  workHoursType: Work_Hours_Type;
  setWorkHoursType: React.Dispatch<React.SetStateAction<Work_Hours_Type>>;
  setSettlementSectionHour: React.Dispatch<React.SetStateAction<number>>;
  dayOfWeekList: Day_Type[];
  dayOfWeekType: Day_Of_Week_Type;
  setDayOfWeekType: React.Dispatch<React.SetStateAction<Day_Of_Week_Type>>;
  handleDayOfWeekList: (day: Day_Type) => void;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  startBreakTime: string;
  setStartBreakTime: React.Dispatch<React.SetStateAction<string>>;
  endBreakTime: string;
  setEndBreakTime: React.Dispatch<React.SetStateAction<string>>;
}

function FlexibleWSBasic({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  settlementSectionHour,
  setSettlementSectionHour,
  workHoursType,
  setWorkHoursType,
  dayOfWeekList,
  dayOfWeekType,
  setDayOfWeekType,
  handleDayOfWeekList,
  startDate,
  setStartDate,
  startBreakTime,
  setStartBreakTime,
  endBreakTime,
  setEndBreakTime
}: IProps) {
  const { hourList, minList, hourTypeList, weekList } = useTimeList();
  const { displayHours, makeHoursType, makeHours } = useTimeControl();
  return (
    <GridContentContainer flex={0}>
      <FormRow title="기본 출/퇴근 시간">
        <SSelect
          id="startTimeType"
          minWidth="60px"
          maxWidth="60px"
          value={
            parseInt(startTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
          }
          onChange={event => {
            const newTime = makeHoursType({
              originH: startTime,
              newType: event.currentTarget.value as HOUR_TYPE
            });
            setStartTime(newTime);
          }}
        >
          {hourTypeList.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </SSelect>
        <SSelect
          id="startTimeHour"
          minWidth="60px"
          maxWidth="60px"
          value={displayHours(startTime.split(":")[0])}
          onChange={event => {
            const hours = startTime.split(":")[0];
            const minute = startTime.split(":")[1];
            let parsedHours = makeHours({
              originH: hours,
              newH: event.currentTarget.value
            });
            setStartTime(`${parsedHours}:${minute}`);
          }}
        >
          {hourList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="startTimeHour">{`시`}</label>
        <SSelect
          id="startMin"
          minWidth="60px"
          maxWidth="60px"
          value={startTime.split(":")[1]}
          onChange={event => {
            const minute = event.currentTarget.value;
            setStartTime(item => {
              const hours = item.split(":")[0];
              return `${hours}:${minute}`;
            });
          }}
        >
          {minList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="startTimeMin">{`분`}</label>
        <span>{`~`}</span>
        <SSelect
          id="endHourType"
          minWidth="60px"
          maxWidth="60px"
          value={
            parseInt(endTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
          }
          onChange={event => {
            const newTime = makeHoursType({
              originH: endTime,
              newType: event.currentTarget.value as HOUR_TYPE
            });
            setEndTime(newTime);
          }}
        >
          {hourTypeList.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </SSelect>
        <SSelect
          id="endHour"
          minWidth="50px"
          maxWidth="50px"
          value={displayHours(endTime.split(":")[0])}
          onChange={event => {
            const hours = endTime.split(":")[0];
            const minute = endTime.split(":")[1];
            let parsedHours = makeHours({
              originH: hours,
              newH: event.currentTarget.value
            });
            setEndTime(`${parsedHours}:${minute}`);
          }}
        >
          {hourList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="endTimeHour">{`시`}</label>
        <SSelect
          id="endMin"
          minWidth="50px"
          maxWidth="50px"
          value={endTime.split(":")[1]}
          onChange={event => {
            const minute = event.currentTarget.value;
            setEndTime(item => {
              const hours = item.split(":")[0];
              return `${hours}:${minute}`;
            });
          }}
        >
          {minList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="endTimeMin">{`분`}</label>
      </FormRow>
      <FormRow title="근무 편성 기간 설정">
        <SSelect
          id="settlementSectionHour"
          minWidth="50px"
          maxWidth="50px"
          value={settlementSectionHour}
          onChange={event => {
            setSettlementSectionHour(Number(event.currentTarget.value));
          }}
        >
          {weekList.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </SSelect>
        <label htmlFor="settlementSectionHour">{`주`}</label>
      </FormRow>
      <FormRow title={"근무시간 설정방식"} gridNumber={1}>
        <RadioContainer>
          <StyleInput
            type="radio"
            id="workHoursType:EACH_WEEK_WORK_TIME"
            minWidth="20px"
            maxWidth="20px"
            checked={workHoursType === Work_Hours_Type.EachWeekWorkTime}
            onChange={() => {
              setWorkHoursType(Work_Hours_Type.EachWeekWorkTime);
            }}
          />
          <label htmlFor="workHoursType:EACH_WEEK_WORK_TIME">{`주별 근무시간`}</label>
          <StyleInput
            type="radio"
            id="workHoursType:EachWeekTotalAndQuittingTime"
            minWidth="20px"
            maxWidth="20px"
            checked={
              workHoursType === Work_Hours_Type.EachWeekTotalAndQuittingTime
            }
            onChange={() => {
              setWorkHoursType(Work_Hours_Type.EachWeekTotalAndQuittingTime);
            }}
          />
          <label htmlFor="workHoursType:EachWeekTotalAndQuittingTime">{`주별 출/퇴근시간`}</label>
          <StyleInput
            type="radio"
            id="workHoursType:EachDayWorkTime"
            minWidth="20px"
            maxWidth="20px"
            checked={workHoursType === Work_Hours_Type.EachDayWorkTime}
            onChange={() => {
              setWorkHoursType(Work_Hours_Type.EachDayWorkTime);
            }}
          />
          <label htmlFor="workHoursType:EachDayWorkTime">{`일별 근무시간`}</label>
          <StyleInput
            type="radio"
            id="workHoursType:EachDayTotalAndQuittingTime"
            minWidth="20px"
            maxWidth="20px"
            checked={
              workHoursType === Work_Hours_Type.EachDayTotalAndQuittingTime
            }
            onChange={() => {
              setWorkHoursType(Work_Hours_Type.EachDayTotalAndQuittingTime);
            }}
          />
          <label htmlFor="workHoursType:EachDayTotalAndQuittingTime">{`일별 출/퇴근시간`}</label>
        </RadioContainer>
      </FormRow>
      <FormRow title={"근무요일 설정"}>
        <RadioContainer>
          <StyleInput
            type="radio"
            id="dayOfWeekType:ALL"
            minWidth="20px"
            maxWidth="20px"
            checked={dayOfWeekType === Day_Of_Week_Type.All}
            onChange={() => {
              setDayOfWeekType(Day_Of_Week_Type.All);
            }}
          />
          <label htmlFor="dayOfWeekType:ALL">{`모든 요일`}</label>
          <StyleInput
            type="radio"
            id="dayOfWeekType:monToFri"
            minWidth="20px"
            maxWidth="20px"
            checked={dayOfWeekType === Day_Of_Week_Type.MonToFri}
            onChange={() => {
              setDayOfWeekType(Day_Of_Week_Type.MonToFri);
            }}
          />
          <label htmlFor="dayOfWeekType:monToFri">{`주중(월~금)`}</label>
          <StyleInput
            type="radio"
            id="dayOfWeekType:monToSat"
            minWidth="20px"
            maxWidth="20px"
            checked={dayOfWeekType === Day_Of_Week_Type.MonToSat}
            onChange={() => {
              setDayOfWeekType(Day_Of_Week_Type.MonToSat);
            }}
          />
          <label htmlFor="dayOfWeekType:monToSat">{`주중(월~토)`}</label>
          <StyleInput
            type="radio"
            id="dayOfWeekType:self"
            minWidth="20px"
            maxWidth="20px"
            checked={dayOfWeekType === Day_Of_Week_Type.InputMethod}
            onChange={() => {
              setDayOfWeekType(Day_Of_Week_Type.InputMethod);
            }}
          />
          <label htmlFor="dayOfWeekType:self">{`직접선택`}</label>
        </RadioContainer>
      </FormRow>
      <FormRow title={"근무요일 선택"}>
        <RadioContainer>
          <StyleInput
            type="checkbox"
            id={Day_Type.Mon}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Mon}
            checked={dayOfWeekList.includes(Day_Type.Mon)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Mon);
            }}
          />
          <label htmlFor={Day_Type.Mon}>{`월`}</label>
          <StyleInput
            type="checkbox"
            id={Day_Type.Tue}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Tue}
            checked={dayOfWeekList.includes(Day_Type.Tue)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Tue);
            }}
          />
          <label htmlFor={Day_Type.Tue}>{`화`}</label>
          <StyleInput
            type="checkbox"
            id={Day_Type.Wed}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Wed}
            checked={dayOfWeekList.includes(Day_Type.Wed)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Wed);
            }}
          />
          <label htmlFor={Day_Type.Wed}>{`수`}</label>
          <StyleInput
            type="checkbox"
            id={Day_Type.Thr}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Thr}
            checked={dayOfWeekList.includes(Day_Type.Thr)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Thr);
            }}
          />
          <label htmlFor={Day_Type.Thr}>{`목`}</label>
          <StyleInput
            type="checkbox"
            id={Day_Type.Fri}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Fri}
            checked={dayOfWeekList.includes(Day_Type.Fri)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Fri);
            }}
          />
          <label htmlFor={Day_Type.Fri}>{`금`}</label>
          <StyleInput
            type="checkbox"
            id={Day_Type.Sat}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Sat}
            checked={dayOfWeekList.includes(Day_Type.Sat)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Sat);
            }}
          />
          <label htmlFor={Day_Type.Sat}>{`토`}</label>
          <StyleInput
            type="checkbox"
            id={Day_Type.Sun}
            minWidth="15px"
            maxWidth="15px"
            name="selectDay"
            value={Day_Type.Sun}
            checked={dayOfWeekList.includes(Day_Type.Sun)}
            disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            onChange={() => {
              handleDayOfWeekList(Day_Type.Sun);
            }}
          />
          <label htmlFor={Day_Type.Sun}>{`일`}</label>
        </RadioContainer>
      </FormRow>
      <FormRow title="시작 주차 선택">
        <StyleInput
          type="date"
          value={startDate}
          onChange={event => {
            setStartDate(event.currentTarget.value);
          }}
        />
      </FormRow>
      <FormRow title="적용 시작일">
        <span>{`${startDate}`}</span>
      </FormRow>
      <FormRow title="휴게시간 설정">
        <SSelect
          id="startBreakTimeType"
          minWidth="60px"
          maxWidth="60px"
          value={
            parseInt(startBreakTime.split(":")[0]) < 12
              ? HOUR_TYPE.AM
              : HOUR_TYPE.PM
          }
          onChange={event => {
            const newTime = makeHoursType({
              originH: endTime,
              newType: event.currentTarget.value as HOUR_TYPE
            });
            setStartBreakTime(newTime);
          }}
        >
          {hourTypeList.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </SSelect>
        <SSelect
          id="startBreakTimeHour"
          minWidth="50px"
          maxWidth="50px"
          value={displayHours(startBreakTime.split(":")[0])}
          onChange={event => {
            const hours = startBreakTime.split(":")[0];
            const minute = startBreakTime.split(":")[1];
            let parsedHours = makeHours({
              originH: hours,
              newH: event.currentTarget.value
            });
            setStartBreakTime(`${parsedHours}:${minute}`);
          }}
        >
          {hourList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="startBreakTimeHour">{`시`}</label>
        <SSelect
          id="startBreakTimeMin"
          minWidth="50px"
          maxWidth="50px"
          value={startBreakTime.split(":")[1]}
          onChange={event => {
            const minute = event.currentTarget.value;
            setStartBreakTime(item => {
              const hours = item.split(":")[0];
              return `${hours}:${minute}`;
            });
          }}
        >
          {minList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="startBreakTimeMin">{`분`}</label>
        <span>{`~`}</span>
        <SSelect
          id="endHourType"
          minWidth="60px"
          maxWidth="60px"
          value={
            parseInt(endBreakTime.split(":")[0]) < 12
              ? HOUR_TYPE.AM
              : HOUR_TYPE.PM
          }
          onChange={event => {
            const newTime = makeHoursType({
              originH: endTime,
              newType: event.currentTarget.value as HOUR_TYPE
            });
            setEndBreakTime(newTime);
          }}
        >
          {hourTypeList.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
        </SSelect>
        <SSelect
          id="endBreakTimeHour"
          minWidth="50px"
          maxWidth="50px"
          value={displayHours(endBreakTime.split(":")[0])}
          onChange={event => {
            const hours = endBreakTime.split(":")[0];
            const minute = endBreakTime.split(":")[1];
            let parsedHours = makeHours({
              originH: hours,
              newH: event.currentTarget.value
            });
            setEndBreakTime(`${parsedHours}:${minute}`);
          }}
        >
          {hourList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="endBreakTimeHour">{`시`}</label>
        <SSelect
          id="endBreakTimeMin"
          minWidth="50px"
          maxWidth="50px"
          value={endBreakTime.split(":")[1]}
          onChange={event => {
            const minute = event.currentTarget.value;
            setEndBreakTime(item => {
              const hours = item.split(":")[0];
              return `${hours}:${minute}`;
            });
          }}
        >
          {minList.map(item => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </SSelect>
        <label htmlFor="endBreakTimeMin">{`분`}</label>
      </FormRow>
      <FormRow title=""></FormRow>
    </GridContentContainer>
  );
}

export default memo(FlexibleWSBasic);
