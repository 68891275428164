import styled from "styled-components";
import { IDay } from "../display_month_graph/display_month_graph";
import TimeDescription from "../time_description/time_description";
import TimeGraphBox, { COLOR_TYPE } from "../time_graph_box/time_graph_box";
import TimeGraphLabel from "../time_graph_label/time_graph_label";
import { Work_Type } from "../../../generated/graphql";

export interface ITime {
  title: string;
  min: number;
  type: Work_Type;
  colorType: COLOR_TYPE;
}

interface ITimeLabel {
  labelName: string;
  colorType: COLOR_TYPE;
}

interface IDescription {
  description: string;
  emphasis?: string;
}

interface IProps {
  weeks: IDay[];
  labelList: ITimeLabel[];
  description?: IDescription;
  subDescription?: IDescription;
  setSelectedDayName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Container = styled.div`
  display: flex;
  gap: 2px;
  padding: 5px 30px;
  justify-content: center;
  flex-direction: column;
  user-select: none;
`;

const GraphContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const DisplayDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 2px;
`;

function DisplayWeekGraph({
  weeks,
  labelList,
  description,
  subDescription,
  setSelectedDayName
}: IProps) {
  return (
    <Container>
      <GraphContainer>
        {weeks.map(item => (
          <TimeGraphBox
            key={`${item.min}_${item.title}`}
            title={item.title}
            min={item.min}
            type={item.type}
            colorType={item.colorType}
            isDay={true}
            isDisabledTitle={item.isDisabledTitle}
            isSun={item.isSun}
            isSat={item.isSat}
            isToday={item.isToday}
            selected={item.selected}
            handleSelect={() => {
              setSelectedDayName(item.title);
            }}
          />
        ))}
      </GraphContainer>
      <DisplayDetail>
        {description && (
          <TimeDescription
            description={description?.description}
            emphasis={description?.emphasis}
          />
        )}
        <LabelContainer>
          {labelList.map((item, index) => (
            <TimeGraphLabel
              labelName={item.labelName}
              colorType={item.colorType}
              key={`${item.labelName}_${item.colorType}_${index}`}
            />
          ))}
        </LabelContainer>
      </DisplayDetail>
      {subDescription && (
        <DisplayDetail>
          <TimeDescription
            description={subDescription.description}
            emphasis={subDescription.emphasis}
          />
        </DisplayDetail>
      )}
    </Container>
  );
}

export default DisplayWeekGraph;
