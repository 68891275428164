import * as React from "react";
import serviceStatusApi from "../../../../../api/electron-api/service-status-api";

type other = {
  name: string;
  time: string;
};

type Data = {
  regular: string;
  others: other[];
};

type Props = {
  employeeId?: string;
};

const useDayWorking = <P extends Props>({ employeeId }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [isToday, setIsToday] = React.useState<boolean>(true);
  const [today, setToday] = React.useState<Data>({
    regular: "",
    others: []
  });
  const [tomorrow, setTomorrow] = React.useState<Data>({
    regular: "",
    others: []
  });

  const handleIsToday = React.useCallback((type: boolean) => {
    setIsToday(type);
  }, []);

  const makeData = React.useCallback((data: string[]) => {
    const newData = data.map((item: any) => {
      return {
        name: item.name,
        time: `${item.extend_sdatetime} ~ ${item.extend_edatetime}`
      };
    });
    return newData;
  }, []);

  const handleData = React.useCallback(async () => {
    try {
      if (employeeId && isMount.current) {
        const params = {
          employeeId
        };
        const { data } = await serviceStatusApi.getWorkingHour(params);
        const newTodayOthers =
          Array.isArray(data[1]) && data[1].length > 0 ? makeData(data[1]) : [];
        const newTomorrowOthers =
          Array.isArray(data[3]) && data[3].length > 0 ? makeData(data[3]) : [];
        const newToday = {
          regular: data[0].WorkingTime,
          others: newTodayOthers
        };
        const newTomorrow = {
          regular: data[2].WorkingTime,
          others: newTomorrowOthers
        };

        setToday(newToday);
        setTomorrow(newTomorrow);
      }
    } catch (error) {
      console.log(error);
    }
  }, [employeeId, makeData]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    handleData();
    return () => {
      isMount.current = false;
    };
  }, [handleData]);
  return { today, tomorrow, isToday, handleIsToday };
};

export default useDayWorking;
