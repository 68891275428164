import { useForm } from "react-hook-form";
import styled from "styled-components";
import TextInputWithLabel from "../inputs/text-input-with-label";
import AsonicIconButton from "../shared/asonic-icon-button/asonic-icon-button";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import EditIcon from "@iconify/icons-fa-solid/edit";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import { GetListOfReservationType_getListOfReservationType_list } from "../../__generated__/GetListOfReservationType";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import { colors } from "../GlobalStyle/GlobalStyle";
import { gql, useMutation } from "@apollo/client";
import {
  AddReservationType,
  AddReservationTypeVariables
} from "../../__generated__/AddReservationType";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import {
  UpdateReservationType,
  UpdateReservationTypeVariables
} from "../../__generated__/UpdateReservationType";
import { useCallback, useEffect } from "react";
import {
  DeleteReservationType,
  DeleteReservationTypeVariables
} from "../../__generated__/DeleteReservationType";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../hooks/confirm-dialog-hook/use-confirm-dialog";

interface IProps {
  selectedRow?: IAsonicRow<GetListOfReservationType_getListOfReservationType_list>;
}

interface IFormValues {
  groupName: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
`;

const MUTATION_ADD_RESERVATION_TYPE = gql`
  mutation AddReservationType($groupName: String!) {
    addReservationType(groupName: $groupName) {
      ok
      error
      groupIdx
    }
  }
`;

const MUTATION_UPDATE_RESERVATION_TYPE = gql`
  mutation UpdateReservationType($groupIdx: Int!, $groupName: String!) {
    updateReservationType(groupIdx: $groupIdx, groupName: $groupName) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_RESERVATION_TYPE = gql`
  mutation DeleteReservationType($listOfGroupIdx: [Int!]!) {
    deleteReservationType(listOfGroupIdx: $listOfGroupIdx) {
      ok
      error
    }
  }
`;

function ReservationTypeEditor({ selectedRow }: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isOpenConfirmDialog,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const { register, watch, setValue } = useForm<IFormValues>();
  const groupName = watch("groupName");
  const [addReservationType] = useMutation<
    AddReservationType,
    AddReservationTypeVariables
  >(MUTATION_ADD_RESERVATION_TYPE, {
    update(cache, { data }, { variables }) {
      if (
        data?.addReservationType.ok &&
        data.addReservationType.groupIdx &&
        variables
      ) {
        const newReservationType: GetListOfReservationType_getListOfReservationType_list =
          {
            parentGroupIdx: 0,
            groupIdx: parseInt(data?.addReservationType.groupIdx),
            groupName: variables?.groupName,
            __typename: "ReservationTypeEntity"
          };
        const newCacheReservationType = cache.writeFragment({
          id: `ReservationTypeEntity:${data?.addReservationType.groupIdx}`,
          fragment: gql`
            fragment add on ReservationTypeEntity {
              __typename
              parentGroupIdx
              groupIdx
              groupName
            }
          `,
          data: {
            ...newReservationType
          }
        });
        cache.modify({
          id: `GetListOfReservationTypeOutput`,
          fields: {
            list(prev) {
              if (prev) {
                return [...prev, newCacheReservationType];
              }
              return [newCacheReservationType];
            }
          }
        });
        handleToast(
          "성공적으로 예약종류를 추가하셨습니다.",
          MessageTypes.SUCCESS
        );
      }
      if (data?.addReservationType.error) {
        handleToast(data.addReservationType.error, MessageTypes.ERROR);
      }
    }
  });

  const [updateReservationType] = useMutation<
    UpdateReservationType,
    UpdateReservationTypeVariables
  >(MUTATION_UPDATE_RESERVATION_TYPE, {
    update(cache, { data }, { variables }) {
      if (data?.updateReservationType.ok && variables) {
        cache.modify({
          id: `ReservationTypeEntity:${variables.groupIdx}`,
          fields: {
            groupName() {
              return variables.groupName;
            }
          }
        });
        handleToast(
          "성공적으로 선택된 예약종류를 수정하셨습니다.",
          MessageTypes.SUCCESS
        );
      }
      if (data?.updateReservationType.error) {
        handleToast(data.updateReservationType.error, MessageTypes.ERROR);
      }
    }
  });

  const [deleteReservationType] = useMutation<
    DeleteReservationType,
    DeleteReservationTypeVariables
  >(MUTATION_DELETE_RESERVATION_TYPE, {
    update(cache, { data }, { variables }) {
      if (data?.deleteReservationType.ok && variables) {
        cache.evict({
          id: `ReservationTypeEntity:${variables.listOfGroupIdx[0]}`
        });
        handleToast(
          "성공적으로 선택된 예약종류를 삭제하셨습니다.",
          MessageTypes.SUCCESS
        );
      }
      if (data?.deleteReservationType.error) {
        handleToast(data.deleteReservationType.error, MessageTypes.ERROR);
      }
      handleIsOpen(false);
    }
  });

  const handleConfirm = useCallback(() => {
    if (groupName && selectedRow) {
      deleteReservationType({
        variables: {
          listOfGroupIdx: [selectedRow.original.groupIdx]
        }
      });
    }
  }, [selectedRow, groupName, deleteReservationType]);

  useEffect(() => {
    if (selectedRow) {
      setValue("groupName", selectedRow.original.groupName);
      handleConfirmMessage({
        title: `예약종류 삭제`,
        p: `예약종류: ${selectedRow.original.groupName}를 삭제하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
    }
  }, [selectedRow, handleConfirmMessage, setValue]);

  return (
    <Container>
      <TextInputWithLabel title="종류" {...register("groupName")} />
      <ButtonContainer>
        <AsonicIconButton
          icon={AddIcon}
          disabled={!groupName ? true : false}
          onClick={() => {
            if (groupName) {
              addReservationType({
                variables: {
                  groupName
                }
              });
            }
          }}
        />
        <AsonicIconButton
          icon={EditIcon}
          disabled={!groupName || !selectedRow ? true : false}
          onClick={() => {
            if (groupName && selectedRow) {
              updateReservationType({
                variables: {
                  groupIdx: selectedRow.original.groupIdx,
                  groupName
                }
              });
            }
          }}
        />
        <AsonicIconButton
          icon={DeleteIcon}
          disabled={!selectedRow ? true : false}
          color={colors.tomato}
          onClick={() => {
            if (selectedRow?.original.groupIdx) {
              handleIsOpen(true);
            }
          }}
        />
      </ButtonContainer>
      {isOpenConfirmDialog && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ReservationTypeEditor;
