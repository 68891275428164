import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import usePageControl from "../../hooks/use-page-control/use-page-control";
import {
  GetListOfVehicleInformation,
  GetListOfVehicleInformationVariables,
  GetListOfVehicleInformation_getListOfVehicleInformation_list
} from "../../__generated__/GetListOfVehicleInformation";
import {
  GetTotalNumberOfVehicleInformation,
  GetTotalNumberOfVehicleInformationVariables
} from "../../__generated__/GetTotalNumberOfVehicleInformation";
import { SEARCH_TYPE_FOR_VEHICLE_INFORMATION } from "../../__generated__/globalTypes";
import { SearchFieldValues } from "../asonic-table/asonic-search";
import * as ReactTable from "react-table";
import useDnd from "../../hooks/use-dnd/use-dnd";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import {
  DownloadVehicleInformationExcel,
  DownloadVehicleInformationExcelVariables
} from "../../__generated__/DownloadVehicleInformationExcel";
import { downloadFileFromServer } from "../main-view/statistics/Utils";
import moment from "moment";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import VehicleInformationController from "./vehicle-information-controller/vehicle-information-controller";
import { useSelector } from "react-redux";
import { Reducers } from "../../../types/reducers";
import { Role } from "../../user-types";
import useFixedColumn from "../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import UserInfoSectionHeader from "../main-view/WorkManagementInfo/user-info-section/user_info_section_container";
import TableLayoutContainer from "../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../table_v2/table_v2";
import useNewSortBy from "../../hooks/use-new-sort-by/use-new-sort-by";
import TableLayoutFooter from "../table_layout/table_layout_footer";
import PageController from "../table/page_controller";

enum COLUMN_FOR_VALUE {
  vehicleNumber = "차량번호",
  vehicleName = "차량이름"
}

export type TYPE_FOR_VEHICLE_INFORMATION_TABLE = keyof typeof COLUMN_FOR_VALUE;

interface IProps {
  handleSelectedVehicle: (
    vehicle: ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>
  ) => void;
  handleIsDisableZIndex: (value: boolean) => void;
}

const title = "차량관리";

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_GET_LIST_OF_VEHICLE_INFORMATION = gql`
  query GetListOfVehicleInformation(
    $page: Float
    $take: Float
    $fieldSort: FieldSort
    $searchType: SEARCH_TYPE_FOR_VEHICLE_INFORMATION
    $searchValue: String
  ) {
    getListOfVehicleInformation(
      page: $page
      take: $take
      fieldSort: $fieldSort
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      list {
        vehicleNumber
        vehicleName
      }
    }
  }
`;

const QUERY_GET_TOTAL_NUMBER_OF_VEHICLE_INFORMATION = gql`
  query GetTotalNumberOfVehicleInformation(
    $searchType: SEARCH_TYPE_FOR_VEHICLE_INFORMATION
    $searchValue: String
  ) {
    getTotalNumberOfVehicleInformation(
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_VEHICLE_INFORMATION_EXCEL = gql`
  query DownloadVehicleInformationExcel(
    $searchType: SEARCH_TYPE_FOR_VEHICLE_INFORMATION
    $searchValue: String
  ) {
    downloadVehicleInformationExcel(
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

function VehicleInformation({
  handleSelectedVehicle,
  handleIsDisableZIndex
}: IProps) {
  const { signInReducer } = useSelector((state: Reducers) => state);
  const { oldFieldSort, fieldSort, handleFieldSort } = useNewSortBy();
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [searchType, setSearchType] =
    useState<SEARCH_TYPE_FOR_VEHICLE_INFORMATION>(
      SEARCH_TYPE_FOR_VEHICLE_INFORMATION.vehicleNumber
    );

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const [searchValue, setSearchValue] = useState("");

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const isSuperAdmin = useMemo(() => {
    return signInReducer.loginIdInfo === Role.SUPER_ADMIN;
  }, [signInReducer.loginIdInfo]);

  const [getListOfVehicleInformation, { data, loading }] = useLazyQuery<
    GetListOfVehicleInformation,
    GetListOfVehicleInformationVariables
  >(QUERY_GET_LIST_OF_VEHICLE_INFORMATION, {
    fetchPolicy: "network-only",
    onError(error) {
      console.log(error.message);
      handleToast("차량정보를 가져오지 못했습니다.", MessageTypes.ERROR);
    },
    onCompleted(data) {
      if (
        !data.getListOfVehicleInformation.ok &&
        data.getListOfVehicleInformation.error
      ) {
        handleToast(data.getListOfVehicleInformation.error, MessageTypes.ERROR);
        handleIsToastMessageOpen(true);
      }
    }
  });

  const [
    getTotalNumberOfVehicleInformation,
    { data: totalData, loading: totalLoading }
  ] = useLazyQuery<
    GetTotalNumberOfVehicleInformation,
    GetTotalNumberOfVehicleInformationVariables
  >(QUERY_GET_TOTAL_NUMBER_OF_VEHICLE_INFORMATION, {
    fetchPolicy: "network-only",
    onError(error) {
      console.log(error.message);
      handleToast(
        "차량 목록 합계 정보를 가져오지 못했습니다.",
        MessageTypes.ERROR
      );
      handleIsToastMessageOpen(true);
    },
    onCompleted(data) {
      if (
        !data.getTotalNumberOfVehicleInformation.ok &&
        data.getTotalNumberOfVehicleInformation.error
      ) {
        handleToast(
          data.getTotalNumberOfVehicleInformation.error,
          MessageTypes.ERROR
        );
        handleIsToastMessageOpen(true);
      }
    }
  });

  const [downloadVehicleInformationExcel] = useLazyQuery<
    DownloadVehicleInformationExcel,
    DownloadVehicleInformationExcelVariables
  >(QUERY_DOWNLOAD_VEHICLE_INFORMATION_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadVehicleInformationExcel.ok &&
        data.downloadVehicleInformationExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadVehicleInformationExcel.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}-list-of-vehicle-information.csv`
        );
      }
      if (
        !data.downloadVehicleInformationExcel.ok &&
        data.downloadVehicleInformationExcel.error
      ) {
        handleToast(
          data.downloadVehicleInformationExcel.error,
          MessageTypes.ERROR
        );
        handleIsToastMessageOpen(true);
      }
    }
  });

  const downloadExcel = useCallback(() => {
    downloadVehicleInformationExcel({
      variables: {
        searchType,
        searchValue
      }
    });
  }, [downloadVehicleInformationExcel, searchType, searchValue]);

  const total = useMemo(() => {
    if (
      totalData?.getTotalNumberOfVehicleInformation.ok &&
      totalData?.getTotalNumberOfVehicleInformation.total
    ) {
      return totalData?.getTotalNumberOfVehicleInformation.total;
    }
    return 0;
  }, [totalData]);

  const columns: ReactTable.Column<GetListOfVehicleInformation_getListOfVehicleInformation_list>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let sticky = "";
        if (sFixedColumnNumber !== undefined) {
          if (fixedColumnName.length > 0) {
            fixedColumnName.forEach((fColumn, fColumnIndex) => {
              if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
                sticky = "left";
              }
            });
          }
        }
        let width = 120;
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_FOR_VEHICLE_INFORMATION_TABLE],
          accessor: item as TYPE_FOR_VEHICLE_INFORMATION_TABLE,
          width,
          sticky
        };
      });
    }, [sFixedColumnNumber, fixedColumnName]);

  const list: GetListOfVehicleInformation_getListOfVehicleInformation_list[] =
    useMemo(() => {
      return data?.getListOfVehicleInformation.list || [];
    }, [data]);

  const table =
    ReactTable.useTable<GetListOfVehicleInformation_getListOfVehicleInformation_list>(
      {
        columns,
        data: list
      },
      ReactTable.useBlockLayout,
      ReactTable.useRowSelect,
      ReactTable.useColumnOrder,
      useSticky
    );

  const { moveColumn } =
    useDnd<GetListOfVehicleInformation_getListOfVehicleInformation_list>({
      columns: table.visibleColumns,
      setColumnOrder: table.setColumnOrder,
      title: `${title}-for-ordering-column`,
      setFixedColumnName
    });

  const selectedRow:
    | ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  const listOfSearchType = useMemo(() => {
    return Object.keys(COLUMN_FOR_VALUE).map(item => ({
      value: item,
      name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
    }));
  }, []);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type) {
        const INIT_PAGE = 1;
        handleCurrentPage(INIT_PAGE);
        if (data.type) {
          setSearchType(data.type as SEARCH_TYPE_FOR_VEHICLE_INFORMATION);
        }
        setSearchValue(data.value ?? "");
        getTotalNumberOfVehicleInformation({
          variables: {
            searchType: data.type as SEARCH_TYPE_FOR_VEHICLE_INFORMATION,
            searchValue: data.value
          }
        });
      }
    },
    [getTotalNumberOfVehicleInformation, handleCurrentPage]
  );

  useEffect(() => {
    if (selectedRow) {
      handleSelectedVehicle(selectedRow);
    }
  }, [selectedRow, handleSelectedVehicle]);

  useEffect(() => {
    getTotalNumberOfVehicleInformation({
      variables: {
        searchType,
        searchValue
      }
    });
  }, [getTotalNumberOfVehicleInformation, searchValue, searchType]);

  useEffect(() => {
    getListOfVehicleInformation({
      variables: {
        page: currentPage,
        take: take,
        searchType: searchType,
        searchValue: searchValue,
        fieldSort: oldFieldSort
      }
    });
  }, [
    getListOfVehicleInformation,
    currentPage,
    take,
    searchType,
    searchValue,
    oldFieldSort
  ]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);

  return (
    <Container>
      <UserInfoSectionHeader<GetListOfVehicleInformation_getListOfVehicleInformation_list>
        columns={
          table.columns as TColumn<GetListOfVehicleInformation_getListOfVehicleInformation_list>[]
        }
        table={table}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        setIsActiveFilter={setIsActiveFilter}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                loading={loading || totalLoading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      {/* <AsonicTable<GetListOfVehicleInformation_getListOfVehicleInformation_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={handleSelectRow}
        isLoading={loading || totalLoading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        handleFieldSort={handleFieldSort}
        fieldSort={fieldSort}
        columns={
          vehicleInformationColumn as TColumn<GetListOfVehicleInformation_getListOfVehicleInformation_list>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      /> */}
      {isSuperAdmin && (
        <VehicleInformationController
          handleToast={handleToast}
          selectedRow={selectedRow}
          handleIsDisableZIndex={handleIsDisableZIndex}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default VehicleInformation;
