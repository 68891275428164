import {
  useState,
  useRef,
  useMemo,
  useCallback,
  useContext,
  Fragment
} from "react";
import usePersonalStatusChart from "../../../../../hooks/master-work-management-page-hooks/use-personal-status-chart";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { AxiosResponse } from "axios";
import { Params } from "../../../../../api/master-work-management-page-api";
import { Store } from "../../master-work-management-page";
import useOpenDialog from "../../../../../hooks/use-open-dialog";
import SearchDashBoard from "../../../../dash-board/search-dash-board/search-dash-board";
import {
  SEARCH_DATE_TYPE_FOR_DASH_BOARD,
  SEARCH_DB_TYPE_FOR_DASH_BOARD
} from "../../../../../__generated__/globalTypes";
import {
  OT_EXT_TIME_SECTION,
  OT_TOT_TIME_SECTION,
  OT_WORK_TIME_SECTION
} from "../../../../asonic-table/asonic-table-for-dash-board/asonic-search-by-date-with-type";
import { TooltipItem, TooltipModel } from "chart.js";

type Props = {
  title: string;
  callData: (params: Params) => Promise<AxiosResponse<any>>;
  handlePerio: (value: string) => void;
  color: string;
};

const PersonalStatusChart = <P extends Props>(props: P) => {
  const chartRef = useRef();
  const [additionalSearchType, setAdditionalSearchType] = useState(
    SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTimeSection
  );
  const [additionalSearchTimeValue, setAdditionalSearchTypeValue] = useState(
    SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_52_HOURS
  );

  const store = useContext<any>(Store);
  const { isOpen, handleOpenDialog } = useOpenDialog();

  const { chartData } = usePersonalStatusChart({
    ...props,
    isRenewal: store.isRenewal
  });

  const handleOnClick = useCallback(
    (event: any) => {
      const { label } = chartData.datasets[0];
      if (chartRef?.current) {
        const [elementAtEvent] = getElementAtEvent(chartRef?.current, event);
        if (!elementAtEvent) {
          return;
        }
        switch (label) {
          case "근로시간별":
            Object.entries(OT_TOT_TIME_SECTION).forEach((item, index) => {
              if (index === elementAtEvent.index) {
                setAdditionalSearchTypeValue(
                  item[0] as SEARCH_DATE_TYPE_FOR_DASH_BOARD
                );
              }
            });
            setAdditionalSearchType(
              SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTimeSection
            );
            break;
          case "정규근무":
            Object.entries(OT_WORK_TIME_SECTION).forEach((item, index) => {
              if (index === elementAtEvent.index) {
                setAdditionalSearchTypeValue(
                  item[0] as SEARCH_DATE_TYPE_FOR_DASH_BOARD
                );
              }
            });
            setAdditionalSearchType(
              SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTimeSection
            );
            break;
          case "연장근무":
            Object.entries(OT_EXT_TIME_SECTION).forEach((item, index) => {
              if (index === elementAtEvent.index) {
                setAdditionalSearchTypeValue(
                  item[0] as SEARCH_DATE_TYPE_FOR_DASH_BOARD
                );
              }
            });
            setAdditionalSearchType(
              SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTimeSection
            );
            break;
        }
      } else {
        return;
      }

      handleOpenDialog(true);
    },
    [handleOpenDialog, chartData]
  );

  const options: any = useMemo(
    () => ({
      onHover: function (event: any, chartElement: any) {
        if (chartElement.length === 1) {
          if (event.chart.canvas.style) {
            event.chart.canvas.style.cursor = "pointer";
          }
        }
        if (chartElement.length === 0) {
          if (event.chart.canvas.style) {
            event.chart.canvas.style.cursor = "default";
          }
        }
      },
      indexAxis: "y" as const,
      plugins: {
        legend: {
          display: false,
          labels: {
            fontSize: 16
          }
        },
        tooltip: {
          callbacks: {
            label: function (
              this: TooltipModel<"bar">,
              { raw }: TooltipItem<"bar">
            ) {
              const value = raw as number;
              let people = value;
              if (people < 0) {
                return `0명`;
              }
              return `${people}명`;
            }
          }
        },
        datalabels: {
          formatter: function (value: any, context: any) {
            if (value > 99) {
              return "...";
            }
            return `${value}명`;
          },
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
          font: {
            size: 12
          }
        }
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            fontSize: 16
          },
          grid: {
            display: false
          }
        },
        x: {
          ticks: {
            stepSize: 5,
            beginAtZero: true,
            maxTicksLimit: 5,
            fontSize: 16
          }
        }
      }
    }),
    []
  );

  return (
    <Fragment>
      <Bar
        ref={chartRef}
        data={chartData}
        onClick={handleOnClick}
        options={options}
      />
      {isOpen && (
        <SearchDashBoard
          handleClose={handleOpenDialog}
          additionalSearchType={additionalSearchType}
          additionalSearchTimeValue={additionalSearchTimeValue}
        />
      )}
    </Fragment>
  );
};

export default PersonalStatusChart;
