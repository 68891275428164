import * as React from "react";

type OnChange = React.ChangeEvent<HTMLInputElement>;
type TextAreaOnChange = React.ChangeEvent<HTMLTextAreaElement>;

type Props = {
  initValue: any;
};

const useBasicEditor = <P extends Props>({ initValue }: P) => {
  const [value, setValue] = React.useState<any>(initValue);

  const handleValue = React.useCallback(
    (event: OnChange | TextAreaOnChange) => {
      const {
        target: { value }
      } = event;
      event.currentTarget.setCustomValidity("");
      setValue(value);
    },
    []
  );

  const handleInvalid = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.currentTarget.setCustomValidity(
        `${event.currentTarget.name}을/를 입력해 주세요`
      );
    },
    []
  );

  return { value, handleValue, handleInvalid, setValue };
};

export default useBasicEditor;
