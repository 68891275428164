import { useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import listOfApolloVar from "../../../../apollo/apollo-var";
import TextInput from "../../../inputs/text-input";
import mColors from "../../../../style-sheet/m_colors";
import { TextArea } from "../../../basic-editor/styled";
import { useRecoilState } from "recoil";
import mRAStore from "./m_r_a_store";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const TimeDateContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled.label``;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex: 10;
`;

const STextInput = styled(TextInput)<{ $isWarning?: boolean }>`
  background-color: ${mColors.lightGreen};
  border: ${props =>
    props.$isWarning ? `2px solid ${props.theme.colors.darkRed}` : ""};
`;

const STextArea = styled(TextArea)`
  flex: 4;
  border-radius: 6px;
  background-color: ${mColors.lightGreen};
  outline: none;
  border: 1px solid ${props => props.theme.colors.black};
  :focus {
    border: 2px solid ${props => props.theme.colors.lightGreen};
  }
`;

function MRequestApprovalDetail() {
  const [
    { startDate, startTime, endDate, endTime, title, description, template },
    setMRAState
  ] = useRecoilState(mRAStore);
  const FormName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [isActiveTime, setIsActiveTime] = useState(true);
  const [isWarning, setIsWarning] = useState<boolean>(false);

  const handleIsActiveTime = useCallback(() => {
    const initFormIdx = 0;
    const formIdx: number = template?.formIdx ?? initFormIdx;
    switch (FormName.get(formIdx)) {
      case FormName.get(3):
        setIsActiveTime(false);
        break;
      case FormName.get(4):
        setIsActiveTime(false);
        break;
      case FormName.get(5):
        setIsActiveTime(false);
        break;
    }
  }, [template]);

  useEffect(() => {
    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = new Date(`${endDate}T${endTime}`);

    if (endDateTime < startDateTime) {
      console.log("종료 시간이 시작 시간보다 작습니다.");
      setIsWarning(true);
    } else {
      setIsWarning(false);
      console.log("종료 시간이 시작 시간보다 크거나 같습니다.");
    }
  }, [startDate, endDate, startTime, endTime]);

  useEffect(() => {
    handleIsActiveTime();
  }, [handleIsActiveTime]);

  return (
    <Container>
      <TimeDateContainer>
        <ContentContainer>
          <Label>{`시작일자`}</Label>
          <STextInput
            type="date"
            value={startDate}
            onChange={event => {
              setMRAState(pre => {
                return { ...pre, startDate: event.currentTarget.value };
              });
            }}
          />
        </ContentContainer>
        <ContentContainer>
          <Label>{`시작시간`}</Label>
          <STextInput
            type="time"
            value={startTime}
            onChange={event => {
              setMRAState(pre => {
                return { ...pre, startTime: event.currentTarget.value };
              });
            }}
            disabled={isActiveTime}
          />
        </ContentContainer>
      </TimeDateContainer>
      <TimeDateContainer>
        <ContentContainer>
          <Label>{`종료일자`}</Label>
          <STextInput
            type="date"
            value={endDate}
            onChange={event => {
              setMRAState(pre => {
                return { ...pre, endDate: event.currentTarget.value };
              });
            }}
            $isWarning={isWarning}
          />
        </ContentContainer>
        <ContentContainer>
          <Label>{`종료시간`}</Label>
          <STextInput
            type="time"
            value={endTime}
            onChange={event => {
              setMRAState(pre => {
                return { ...pre, endTime: event.currentTarget.value };
              });
            }}
            disabled={isActiveTime}
          />
        </ContentContainer>
      </TimeDateContainer>
      <TitleContainer>
        <STextInput
          placeholder="제목을 입력하세요(세글자 이상)"
          value={title}
          onChange={event => {
            setMRAState(pre => {
              return { ...pre, title: event.currentTarget.value };
            });
          }}
        />
      </TitleContainer>
      <DescriptionContainer>
        <STextArea
          placeholder="내용을 입력하세요(세글자 이상)"
          value={description}
          onChange={event => {
            setMRAState(pre => {
              return { ...pre, description: event.currentTarget.value };
            });
          }}
        />
      </DescriptionContainer>
    </Container>
  );
}

export default MRequestApprovalDetail;
