import { useState } from "react";
import styled from "styled-components";

const ResultCategoryRootDiv = styled.div`
  display: flex;
  border: 0.2px solid rgba(0, 0, 0, 0.2);
`;

const ResultCategoryText = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  color: rgba(89, 89, 89, 1);
  padding: 0px 4px 0px 4px;
  font-weight: bold;
  background-color: rgba(238, 238, 238, 1);
  border: 0.2px solid rgba(207, 207, 207, 1);
`;

const CategorySelector = styled.select`
  text-align: center;
`;

const ResultCategory = props => {
  const [requestResult, updateRequestResult] = useState(999);
  return (
    <ResultCategoryRootDiv>
      <ResultCategoryText>처리결과</ResultCategoryText>
      <CategorySelector
        value={requestResult}
        name="approvalResult"
        onChange={event => {
          props.handleApprovalResult(event.target.value);
          return updateRequestResult(event.target.value);
        }}
      >
        <option key={999} value={999}>
          전체
        </option>
        <option key={1} value={1}>
          승인
        </option>
        <option key={3} value={3}>
          반려
        </option>
      </CategorySelector>
    </ResultCategoryRootDiv>
  );
};

export default ResultCategory;
