import styled from "styled-components";
import { GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list } from "../../../__generated__/GetListOfReservationDetailInformation";
import ControllerWithIconButton from "../../controller-with-icon-button/controller-with-icon-button";
import * as ReactTable from "react-table";
import useOpenDialog from "../../../hooks/use-open-dialog";
import useConfirmDialog from "../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import { useCallback, useEffect, useState } from "react";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";
import { MessageTypes } from "../../toast-message/toast-message";
import { gql, useMutation } from "@apollo/client";
import {
  DeleteReservation,
  DeleteReservationVariables
} from "../../../__generated__/DeleteReservation";
import {
  UpdateReservation,
  UpdateReservationVariables
} from "../../../__generated__/UpdateReservation";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import TextInputWithLabel from "../../inputs/text-input-with-label";
import Button from "../../globalComponents/Button";
import { useForm } from "react-hook-form";
import TextAreaWithLabel from "../../shared/text-area/text-area";
import moment from "moment";
import {
  AddReservation,
  AddReservationVariables
} from "../../../__generated__/AddReservation";
import { GetListOfReservationInformation_getListOfReservationInformation_list } from "../../../__generated__/GetListOfReservationInformation";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";

interface IProps {
  handleToast: (message: string, type: MessageTypes) => void;
  selectedRow?: ReactTable.Row<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>;
  selectedReservationInfo:
    | ReactTable.Row<GetListOfReservationInformation_getListOfReservationInformation_list>
    | undefined;
}

interface FieldValues {
  reserveTitle: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  description: string;
}

enum ControlType {
  ADD = "ADD",
  UPDATE = "UPDATE"
}

const Container = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  right: 10px;
  bottom: 10px;
  min-width: max-content;
  min-height: max-content;
  gap: 5px;
  z-index: 2;
`;

const ControllerDialogContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const ButtonContainer = styled(Section)`
  align-self: flex-end;
`;

const DateContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const Input = styled.input<{ minWidth?: string }>`
  display: flex;
  flex: 12;
  min-width: ${props => props.minWidth || ""};
`;

const Label = styled.label`
  font-size: 12px;
  color: ${props => props.theme.colors.darkGrey};
  flex: 1;
  min-width: max-content;
`;

const MUTATION_ADD_RESERVATION = gql`
  mutation AddReservation(
    $categoryIdx: Int!
    $requestEmployeeId: String!
    $reserveTitle: String!
    $startDate: String!
    $endDate: String!
    $startTime: String!
    $endTime: String!
    $description: String
  ) {
    addReservation(
      categoryIdx: $categoryIdx
      requestEmployeeId: $requestEmployeeId
      reserveTitle: $reserveTitle
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      description: $description
    ) {
      ok
      error
      infoIdx
    }
  }
`;

const MUTATION_UPDATE_RESERVATION = gql`
  mutation UpdateReservation(
    $infoIdx: Int!
    $requestEmployeeId: String!
    $reserveTitle: String!
    $startDate: String!
    $endDate: String!
    $startTime: String!
    $endTime: String!
    $description: String
  ) {
    updateReservation(
      infoIdx: $infoIdx
      requestEmployeeId: $requestEmployeeId
      reserveTitle: $reserveTitle
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      description: $description
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_RESERVATION = gql`
  mutation DeleteReservation($listOfInfoIdx: [Int!]!) {
    deleteReservation(listOfInfoIdx: $listOfInfoIdx) {
      ok
      error
    }
  }
`;

function ReservationDetailInformationController({
  selectedReservationInfo,
  selectedRow,
  handleToast
}: IProps) {
  const { signInReducer } = useSelector((state: Reducers) => state);

  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isOpenConfirmDialog,
    handleIsOpen: handleIsOpenConfirmDialog,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const { register, handleSubmit, setValue } = useForm<FieldValues>({
    mode: "onSubmit",
    defaultValues: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: moment().format("HH:mm"),
      endTime: moment().add(1, "h").format("HH:mm")
    }
  });

  const [controlType, setControlType] = useState<ControlType>(ControlType.ADD);

  const [addReservation] = useMutation<AddReservation, AddReservationVariables>(
    MUTATION_ADD_RESERVATION,
    {
      onError(error) {
        console.log(error);
        handleToast(
          "알수 없는 이유로 예약을 하실 수 없습니다.",
          MessageTypes.ERROR
        );
      },
      update(cache, { data }, { variables }) {
        if (
          data?.addReservation.ok &&
          data.addReservation.infoIdx &&
          variables &&
          selectedReservationInfo &&
          signInReducer
        ) {
          const newReservation: GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list =
            {
              infoIdx: data.addReservation.infoIdx,
              groupName: selectedReservationInfo.original.groupName,
              categoryName: selectedReservationInfo.original.categoryName,
              requestEmployeeId: variables.requestEmployeeId,
              name: signInReducer.name,
              reserveTitle: variables.reserveTitle,
              startDate: variables.startDate,
              endDate: variables.endDate,
              startTime: variables.startTime,
              endTime: variables.endTime,
              description: variables.description || "",
              __typename: "ReservationDetailInformationEntity"
            };

          const newCacheReservation = cache.writeFragment({
            id: `ReservationDetailInformationEntity:${data.addReservation.infoIdx}`,
            fragment: gql`
              fragment add on ReservationDetailInformationEntity {
                infoIdx
                groupName
                categoryName
                requestEmployeeId
                name
                reserveTitle
                startDate
                endDate
                startTime
                endTime
                description
                __typename
              }
            `,
            data: {
              ...newReservation
            }
          });
          cache.modify({
            id: `GetTotalListOfReservationDetailInformationOutput`,
            fields: {
              total(prev) {
                if (prev) {
                  return prev + 1;
                }
                return 1;
              }
            }
          });
          cache.modify({
            id: `GetListOfReservationDetailInformationOutput`,
            fields: {
              list(prev) {
                if (prev) {
                  return [...prev, newCacheReservation];
                }
                return [newCacheReservation];
              }
            }
          });
          handleToast(
            `성공적으로 ${variables.reserveTitle}이름으로 예약 등록하셨습니다.`,
            MessageTypes.SUCCESS
          );
        }
        if (!data?.addReservation.ok && data?.addReservation.error) {
          handleToast(data?.addReservation.error, MessageTypes.ERROR);
        }
      }
    }
  );

  const [updateReservation] = useMutation<
    UpdateReservation,
    UpdateReservationVariables
  >(MUTATION_UPDATE_RESERVATION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 예약정보를 수정하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(cache, { data }, { variables }) {
      if (data?.updateReservation.ok && variables) {
        cache.modify({
          id: `ReservationDetailInformationEntity:${variables.infoIdx}`,
          fields: {
            reserveTitle() {
              return variables.reserveTitle;
            },
            startDate() {
              return variables.startDate;
            },
            endDate() {
              return variables.endDate;
            },
            startTime() {
              return variables.startTime;
            },
            endTime() {
              return variables.endTime;
            },
            description() {
              return variables.description;
            }
          }
        });
        handleToast(
          `성공적으로 ${variables.reserveTitle}이름의 예약정보를 수정 하셨습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (!data?.updateReservation.ok && data?.updateReservation.error) {
        handleToast(data?.updateReservation.error, MessageTypes.ERROR);
      }
      handleOpenDialog(false);
    }
  });

  const [deleteReservation] = useMutation<
    DeleteReservation,
    DeleteReservationVariables
  >(MUTATION_DELETE_RESERVATION, {
    update(cache, { data }, { variables }) {
      if (data?.deleteReservation.ok && variables) {
        handleIsOpen(false);
        handleToast(
          `선택된 예약상세정보를 성공적으로 삭제하셨습니다.`,
          MessageTypes.SUCCESS
        );
        cache.evict({
          id: `ReservationDetailInformationEntity:${variables.listOfInfoIdx[0]}`
        });
        cache.modify({
          id: `GetTotalListOfReservationDetailInformationOutput`,
          fields: {
            total(prev) {
              if (prev) {
                return prev - 1;
              }
              return prev;
            }
          }
        });
      }
      if (!data?.deleteReservation.ok && data?.deleteReservation.error) {
        handleToast(data?.deleteReservation.error, MessageTypes.ERROR);
      }
    }
  });

  const handleIsOpen = useCallback(
    (value: boolean) => {
      handleIsOpenConfirmDialog(value);
    },
    [handleIsOpenConfirmDialog]
  );

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      deleteReservation({
        variables: {
          listOfInfoIdx: [selectedRow.original.infoIdx]
        }
      });
    }
  }, [selectedRow, deleteReservation]);

  const handleAddOrUpdateSubmit = useCallback(
    ({
      reserveTitle,
      startDate,
      endDate,
      startTime,
      endTime,
      description
    }: FieldValues) => {
      if (selectedReservationInfo) {
        if (controlType === ControlType.ADD) {
          addReservation({
            variables: {
              categoryIdx: selectedReservationInfo?.original.categoryIdx,
              requestEmployeeId: signInReducer.employee_id,
              reserveTitle,
              startDate,
              endDate,
              startTime,
              endTime,
              description
            }
          });
        } else if (controlType === ControlType.UPDATE && selectedRow) {
          updateReservation({
            variables: {
              infoIdx: selectedRow.original.infoIdx,
              requestEmployeeId: signInReducer.employee_id,
              reserveTitle,
              startDate,
              endDate,
              startTime,
              endTime,
              description
            }
          });
        }
      }
    },
    [
      controlType,
      selectedReservationInfo,
      selectedRow,
      signInReducer,
      addReservation,
      updateReservation
    ]
  );

  useEffect(() => {
    if (selectedRow) {
      setValue("reserveTitle", selectedRow.original.reserveTitle ?? "");
      setValue("startDate", selectedRow.original.startDate ?? "");
      setValue("startTime", selectedRow.original.startTime ?? "");
      setValue("endDate", selectedRow.original.endDate ?? "");
      setValue("endTime", selectedRow.original.endTime ?? "");
      setValue("description", selectedRow.original.description ?? "");
      handleConfirmMessage({
        title: `예약상세정보 삭제`,
        p: `예약상세항목 ${selectedRow.original.categoryName}를 삭제하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
    }
  }, [handleConfirmMessage, selectedRow, setValue]);

  return (
    <Container>
      <ControllerWithIconButton
        isAddActive={!selectedReservationInfo}
        isEditActive={!selectedRow}
        isDeleteActive={!selectedRow}
        handleAddButton={() => {
          handleOpenDialog(true);
          setControlType(ControlType.ADD);
        }}
        handleEditButton={() => {
          handleOpenDialog(true);
          setControlType(ControlType.UPDATE);
        }}
        handleDeleteButton={() => {
          handleIsOpen(true);
        }}
      />
      {isOpenConfirmDialog && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      {isOpenDialog && (
        <AsonicDialog
          title={controlType === ControlType.ADD ? "예약 등록" : "예약 수정"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
          height="290px"
          minHeight="290px;"
          width="500px"
          minWidth="500px"
        >
          <ControllerDialogContainer
            onSubmit={handleSubmit(handleAddOrUpdateSubmit)}
          >
            <Section>
              <TextInputWithLabel
                title="예약 목적"
                {...register("reserveTitle")}
                minWidth="382px"
              />
              <DateContainer>
                <Label htmlFor="예약일">예약일</Label>
                <Input {...register("startDate")} id="예약일" type="date" />
                <span>~</span>
                <Input {...register("endDate")} id="예약일" type="date" />
              </DateContainer>
              <DateContainer>
                <Label htmlFor="예약 시간">예약 시간</Label>
                <Input
                  {...register("startTime")}
                  id="예약시간"
                  type="time"
                  minWidth="172px"
                />
                <span>~</span>
                <Input
                  {...register("endTime")}
                  id="예약시간"
                  type="time"
                  minWidth="172px"
                />
              </DateContainer>
              <TextAreaWithLabel
                title="설명"
                {...register("description")}
                minWidth="382px"
              />
            </Section>
            <ButtonContainer>
              <Button>
                {controlType === ControlType.ADD ? "등록하기" : "수정하기"}
              </Button>
            </ButtonContainer>
          </ControllerDialogContainer>
        </AsonicDialog>
      )}
    </Container>
  );
}

export default ReservationDetailInformationController;
