import * as React from "react";
import styled from "styled-components";
import { SerializeItem } from "../../../hooks/link-google-hooks/use-google-calendar";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import CollapsiblePanel from "../../main-view/smallcomponents/CollapsiblePanel";
import CalendarContext from "../asonic-calendar-context";

const Container = styled.div`
  top: 0;
  left: 40px;
  z-index: 1;
  position: absolute;
  min-width: max-content;
  div {
    margin: 0;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
interface IProps {
  items: SerializeItem[];
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AsonicCalendarList = <P extends IProps>({ items, handleOnChange }: P) => {
  const { listOfGoogleColor } = React.useContext(CalendarContext);

  return (
    <Container>
      <CollapsiblePanel title="달력 리스트">
        <ListContainer>
          {items?.length > 0 &&
            items.map(item => (
              <CheckBoxUi
                key={`${item.data.name} ${item.data.id}`}
                htmlFor={item.data.id}
                onChange={handleOnChange}
                checked={item.isChecked}
                title={item.data.name}
                color={
                  listOfGoogleColor.calendar[item?.origin.colorId]?.background
                }
              />
            ))}
        </ListContainer>
      </CollapsiblePanel>
    </Container>
  );
};

export default AsonicCalendarList;
