import { useCallback, useMemo, useState } from "react";

function useFixedColumn() {
  const [fixedColumnName, setFixedColumnName] = useState<string[]>([]);
  const [selectedFixedColumnNumber, setSelectedFixedColumnNumber] =
    useState<string>("기본");
  const [isActiveFilter, setIsActiveFilter] = useState<boolean>(false);

  const handleSelectedFCN = useCallback((payload: string) => {
    setSelectedFixedColumnNumber(payload);
  }, []);
  const fixedColumnNumber = useMemo(() => {
    const newFixedColumnNumber = ["기본"];
    for (let i = 1; i <= 5; i++) {
      newFixedColumnNumber.push(`${i}`);
    }
    return newFixedColumnNumber;
  }, []);
  const sFixedColumnNumber: number | undefined = useMemo(() => {
    const newSFixedColumnNumber = Number(selectedFixedColumnNumber);
    if (!isNaN(newSFixedColumnNumber)) {
      return newSFixedColumnNumber;
    }
  }, [selectedFixedColumnNumber]);
  return {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  };
}

export default useFixedColumn;
