import styled from "styled-components";
import { IEmployeeInformationForReceiveAndReference } from "./list-of-selected-approval-user-for-receive-and-reference-form";
import * as ReactTable from "react-table";
import { useMemo } from "react";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import ApprovalReceiveReferenceSelect from "./approval-receive-reference-select";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import CompactTable from "../../compact-table/compact-table";

const COLUMN_FOR_VALUE = {
  checked: "선택된 수신/참조",
  type: "구분",
  name: "수신/참조"
} as const;

type TYPE_OF_DEPARTMENT = keyof typeof COLUMN_FOR_VALUE;

interface IProps {
  listOfDepartment: IEmployeeInformationForReceiveAndReference[];
  listOfAddedDepartment: string[];
  handleAllAddedDepartment: (users: string[]) => void;
  handleChangeDepartmentInfoInListOfApprovalDepartment: (
    payload: IEmployeeInformationForReceiveAndReference
  ) => void;
  handleAddedDepartment: (department: string) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  border-right: 1px solid ${props => props.theme.colors.grey};
`;

function ListOfReceiveAndReferenceDepartment({
  listOfDepartment,
  listOfAddedDepartment,
  handleAllAddedDepartment,
  handleChangeDepartmentInfoInListOfApprovalDepartment,
  handleAddedDepartment
}: IProps) {
  const columns: ReactTable.Column<IEmployeeInformationForReceiveAndReference>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      const newListOfColumn: ReactTable.Column<IEmployeeInformationForReceiveAndReference>[] =
        listOfColumn.map(item => {
          let width = 50;
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT] ===
            COLUMN_FOR_VALUE.name
          ) {
            width = 150;
          }
          return {
            Header(header) {
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT] ===
                COLUMN_FOR_VALUE.checked
              ) {
                return (
                  <CheckBoxUi
                    checked={
                      header.data.length > 0 &&
                      header.data.length === listOfAddedDepartment.length
                    }
                    htmlFor={COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT]}
                    onChange={() => {
                      const newData: string[] = header.data.map(
                        (item: IEmployeeInformationForReceiveAndReference) =>
                          item.departmentId
                      );
                      handleAllAddedDepartment(newData);
                    }}
                  />
                );
              }
              return COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT];
            },
            accessor: item as TYPE_OF_DEPARTMENT,
            Cell(cell: Cell<IEmployeeInformationForReceiveAndReference>) {
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT] ===
                COLUMN_FOR_VALUE.name
              ) {
                const { department } = cell.row.original;

                return department?.split(">").at(-1);
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT] ===
                COLUMN_FOR_VALUE.type
              ) {
                return (
                  <ApprovalReceiveReferenceSelect
                    cell={cell}
                    handleChangeUserInfoInListOfApprovalUser={
                      handleChangeDepartmentInfoInListOfApprovalDepartment
                    }
                  />
                );
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_DEPARTMENT] ===
                COLUMN_FOR_VALUE.checked
              ) {
                return (
                  <CheckBoxUi
                    checked={
                      !!listOfAddedDepartment.find(
                        item => item === cell.row.original.departmentId
                      )
                    }
                    htmlFor={cell.value}
                    onChange={() => {
                      if (cell.row.original.departmentId) {
                        handleAddedDepartment(cell.row.original.departmentId);
                      }
                    }}
                    name={cell.value}
                  />
                );
              }
            },
            width
          };
        });
      return newListOfColumn;
    }, [
      handleChangeDepartmentInfoInListOfApprovalDepartment,
      listOfAddedDepartment,
      handleAddedDepartment,
      handleAllAddedDepartment
    ]);

  const departmentData: IEmployeeInformationForReceiveAndReference[] =
    useMemo(() => {
      return listOfDepartment.map((item, index) => {
        return {
          employeeId: item.employeeId,
          type: item.type,
          name: item.name,
          departmentId: item.departmentId,
          department: item.department,
          userPosition: item?.userPosition ?? ""
        };
      });
    }, [listOfDepartment]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<IEmployeeInformationForReceiveAndReference>(
    {
      columns,
      data: departmentData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow:
    | ICompactRow<IEmployeeInformationForReceiveAndReference>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  return (
    <Container>
      <CompactTable
        title="수신 및 참조 부서 정보 목록"
        subTitle="수신/참조 부서 목록"
        isLoading={false}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        isTitleBar={false}
      />
    </Container>
  );
}

export default ListOfReceiveAndReferenceDepartment;
