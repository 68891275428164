import * as React from "react";
import FileSaver from "file-saver";
import {
  SettingRootDiv,
  PanelRootDiv,
  PanelDiv,
  LogButtonContainer
} from "./styled";
import axios from "axios";
import useMenuByWebEtcFlag from "../../../hooks/use-menu-by-etc-flag";
import FileUploader from "./FileUploader";
import UpdatePassword from "./UpdatePassword";
import jwt from "jsonwebtoken";
import Delegate from "./Delegate";
import SmtpSetup from "./SmtpSetup";
import moment from "moment";
import PersonalEmail from "./PersonalEmail";
import Auth from "../../../Auth/Auth";
import { WEB_SERVER_ADDR } from "../../../constValues";
import settings from "../../../config/settings";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../globalComponents/IconButton";
import ReactTooltip from "react-tooltip";
import settingsApi from "../../../api/settings-api";

const RW_ADMINE = 2;
const Settings = ({ signInReducer, setUserInfo, setSmtp }) => {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [reEnteredNewPassword, setReEnteredNewPassword] = React.useState("");
  const {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();
  const handleChangePassword = React.useCallback(
    name => event => {
      if (name === "currentPassword") {
        setCurrentPassword(event.target.value);
      } else if (name === "newPassword") {
        setNewPassword(event.target.value);
      } else if (name === "reEnteredNewPassword") {
        setReEnteredNewPassword(event.target.value);
      }
    },
    []
  );

  const saveThePassword = React.useCallback(async () => {
    if (newPassword !== reEnteredNewPassword) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage("새로 입력하신 패스워드가 일치하지 않습니다.");
      handleIsOpen(true);
      return;
    }

    let restApiUri = `${WEB_SERVER_ADDR}/api/settings/password`;

    let secretToken = jwt.sign(
      {
        userId: signInReducer.employee_id,
        currentPassword: currentPassword,
        newPassword: newPassword
      },
      settings.secret
    );

    try {
      const ret = await axios.post(
        restApiUri,
        {
          secretToken
        },
        {
          headers: {
            token: Auth.getUserToken()
          }
        }
      );
      if (ret.status !== 200) {
        handleToastMessageType(MessageTypes.ERROR);
        handleIsOpen(true);
        handleMessage("현재 패스워드를 잘못 입력하셨습니다.");
      } else {
        handleToastMessageType(MessageTypes.SUCCESS);
        handleIsOpen(true);
        handleMessage("패스워드를 성공적으로 변경하였습니다.");
      }
    } catch (e) {
      handleToastMessageType(MessageTypes.ERROR);
      handleIsOpen(true);
      handleMessage("현재 패스워드를 잘못 입력하셨습니다.");
    }
  }, [
    currentPassword,
    newPassword,
    signInReducer,
    reEnteredNewPassword,
    handleIsOpen,
    handleMessage,
    handleToastMessageType
  ]);

  const updateUserPicture = React.useCallback(() => {
    let restApiUri = `${WEB_SERVER_ADDR}/api/mainview`;
    axios
      .get(restApiUri, {
        headers: {
          token: Auth.getUserToken()
        }
      })
      .then(response => {
        if (response.data) {
          setUserInfo(response.data);
          handleToastMessageType(MessageTypes.SUCCESS);
          handleIsOpen(true);
          handleMessage("성공적으로 사진을 변경했습니다.");
        }
      })
      .catch(e => {
        console.log(e);
        handleToastMessageType(MessageTypes.ERROR);
        handleIsOpen(true);
        handleMessage("사진 변경에 실패했습니다.");
      });
  }, [setUserInfo, handleToastMessageType, handleIsOpen, handleMessage]);

  let personalEmailKey = "personal";
  if (signInReducer.user_email !== "") {
    personalEmailKey += "Email";
  }
  if (signInReducer.cclist.length > 0) {
    personalEmailKey += "CClist";
  }
  const { menu } = useMenuByWebEtcFlag({
    webEtcFlag: signInReducer.menuList[0]?.web_etc_flag
  });

  const downloadLogAsZip = React.useCallback(async () => {
    const filename = "log.zip";
    try {
      const { data } = await settingsApi.downloadLogAsZip();

      const blob = new Blob([data], { type: "application/zip" });
      FileSaver(blob, filename);
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <SettingRootDiv>
      <PanelRootDiv>
        <PanelDiv>
          <UpdatePassword
            currentPassword={currentPassword}
            handleChangePassword={handleChangePassword}
            saveThePassword={saveThePassword}
            newPassword={newPassword}
            reEnteredNewPassword={reEnteredNewPassword}
          />
          <FileUploader
            username={signInReducer.name}
            userPic={signInReducer.my_picture}
            employeeId={signInReducer.employee_id}
            updateUserPicture={updateUserPicture}
          />
          {signInReducer.loginIdInfo !== -1 &&
            menu.setSMTPForEmailNotification && (
              <SmtpSetup
                key={signInReducer.smtpSetup ? "has-smtp" : "non-smtp"}
                employeeId={signInReducer.employee_id}
                smtpSetup={signInReducer.smtpSetup}
                setSmtp={setSmtp}
                authCheck={signInReducer.loginIdInfo === RW_ADMINE}
                handleIsOpen={handleIsOpen}
                handleMessage={handleMessage}
                handleToastMessageType={handleToastMessageType}
              />
            )}
          {menu.setCreatorListForEmailNotification && (
            <PersonalEmail
              key={personalEmailKey}
              employeeId={signInReducer.employee_id}
              cclist={signInReducer.cclist.map(user => {
                return { ...user, key: user.employee_id };
              })}
              userEmail={signInReducer.user_email}
              handleIsOpen={handleIsOpen}
              handleMessage={handleMessage}
              handleToastMessageType={handleToastMessageType}
            />
          )}
          {menu.designateProxyApprover && (
            <Delegate
              key={
                signInReducer.delegaterInfo
                  ? "has-delegater"
                  : "has-no-delegater"
              }
              departmentId={signInReducer.department_id}
              employeeId={signInReducer.employee_id}
              delegaterInfo={
                signInReducer.delegaterInfo && {
                  ...signInReducer.delegaterInfo,
                  key: signInReducer.delegaterInfo.employee_id,
                  startDate: moment(
                    new Date(signInReducer.delegaterInfo.approval_start_date)
                  ),
                  endDate: moment(
                    new Date(signInReducer.delegaterInfo.approval_end_date)
                  )
                }
              }
              handleIsOpen={handleIsOpen}
              handleMessage={handleMessage}
              handleToastMessageType={handleToastMessageType}
            />
          )}
          <LogButtonContainer>
            <IconButton
              icon={faFileArchive}
              size="2x"
              data-tip="Log 다운로드"
              onClick={downloadLogAsZip}
            />
          </LogButtonContainer>
        </PanelDiv>
      </PanelRootDiv>
      <ToastMessage
        messageTypes={toastMessageType}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        message={message}
      />
      <ReactTooltip type="success" />
    </SettingRootDiv>
  );
};

export default Settings;
