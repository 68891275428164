import styled from "styled-components";
import colors from "../../../../style-sheet/colors";

export const Container = styled.div`
  background-color: ${colors.personalDashBoard.myWorkingCurrentSituationBox
    .BackgroundColor};
  border-radius: 2px;
  grid-column: span 8;
  grid-row: 1 / span 1;
  color: ${colors.personalDashBoard.Info};
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 22px;
`;

export const TitleBar = styled.div``;

export const Title = styled.span``;

export const WorkingPolicyContainer = styled.div`
  font-size: 16px;
`;

export const WorkingPolicyTitle = styled.span``;

export const WorkingPolicyContent = styled.span`
  color: rgba(255, 255, 255, 0.8);
  margin-left: 5px;
`;

export const JobGroupContainer = styled.div`
  font-size: 16px;
`;

export const JobGroupTitle = styled.span``;

export const JobGroupContent = styled.span`
  color: rgba(255, 255, 255, 0.8);
  margin-left: 5px;
`;

export const DepartmentContainer = styled.div`
  font-size: 16px;
`;

export const DepartmentTitle = styled.span``;

export const DepartmentContent = styled.span`
  color: rgba(255, 255, 255, 0.8);
  margin-left: 5px;
`;

export const WorkingStatusContainer = styled.div`
  font-size: 16px;
`;

export const WorkingStatusTitle = styled.span``;

export const WorkingStatusContent = styled.span`
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.8);
`;

export const Divider = styled.div`
  height: 30px;
  width: 4px;
  background-color: rgba(255, 255, 255, 0.4);
`;
