import { memo, useContext } from "react";
import styled from "styled-components";
import { FlexibleWSStore } from "../../work_time_tab";

const Container = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  padding: 10px;
  gap: 5px;
`;

const Span = styled.span``;

const Time = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`;

function TotalWorkTime() {
  const { weekWH } = useContext(FlexibleWSStore);
  return (
    <Container>
      <Span>{`설정하신 총 근무시간은 [`}</Span>
      <Time>{weekWH.totalWorkHour}</Time>
      <Span>{`] 시간 입니다.`}</Span>
    </Container>
  );
}

export default memo(TotalWorkTime);
