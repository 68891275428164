import * as React from "react";
import {
  Container,
  TalbeContainer,
  TableTitleBar,
  TableTitle,
  TableContentContainer,
  TableContentBox,
  TableContentInBox,
  TableContent
} from "./styled";

type Data = {
  title: string;
  data: string;
  type?: string;
};

type Props = {
  data: Data;
};

const StatusTable = <P extends Props>({ data }: P) => {
  return (
    <Container data-testid="status-table">
      <TalbeContainer>
        <TableTitleBar>
          <TableTitle data-testid="status-table-title">{data.title}</TableTitle>
        </TableTitleBar>
        <TableContentContainer data-testid="status-table-content">
          {data.type === "box" ? (
            <TableContentBox type={data.title}>
              <TableContentInBox>{`${data.data}일`}</TableContentInBox>
            </TableContentBox>
          ) : (
            <TableContent>{data.data}</TableContent>
          )}
        </TableContentContainer>
      </TalbeContainer>
    </Container>
  );
};

export default StatusTable;
