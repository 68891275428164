import { FixedSizeList as List, ListOnScrollProps } from "react-window";
import { gql, useLazyQuery } from "@apollo/client";
import {
  GetListOfChatRoom,
  GetListOfChatRoomVariables,
  GetListOfChatRoom_getListOfChatRoom_list
} from "../../__generated__/GetListOfChatRoom";
import { Reducers } from "../../../types/reducers";
import styled from "styled-components";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import ChatRow from "./chat-row";
import ReactLoading from "react-loading";
import { colors } from "../GlobalStyle/GlobalStyle";
import {
  CountListOfChatRoom,
  CountListOfChatRoomVariables
} from "../../__generated__/CountListOfChatRoom";
import { ChatContext, ChatHandleContext } from "./chat";

const Container = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex: 1;
  font-size: 14px;
  border-top: 1px solid ${props => props.theme.colors.darkGrey};
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};
  align-items: ${props => (props.isLoading ? "center" : "flex-start")};
`;

export const QUERY_GET_LIST_OF_CHAT_ROOM = gql`
  query GetListOfChatRoom($employeeId: String!, $page: Float, $take: Float) {
    getListOfChatRoom(employeeId: $employeeId, page: $page, take: $take) {
      ok
      error
      list {
        chatroom_idx
        roomname
        chatroom_message_data
        noreadcount
      }
    }
  }
`;

const QUERY_COUNT_LIST_OF_CHAT_ROOM = gql`
  query CountListOfChatRoom($employeeId: String!, $page: Float, $take: Float) {
    countListOfChatRoom(employeeId: $employeeId, page: $page, take: $take) {
      ok
      error
      count
    }
  }
`;

function ListOfChat() {
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);

  const [listOfChat, setListOfChat] = useState<
    GetListOfChatRoom_getListOfChatRoom_list[]
  >([]);

  const [page, setPage] = useState<number>(1);

  const take = useMemo(() => {
    return 10;
  }, []);

  const [countListOfChatRoom, { loading: countLoading }] = useLazyQuery<
    CountListOfChatRoom,
    CountListOfChatRoomVariables
  >(QUERY_COUNT_LIST_OF_CHAT_ROOM, {
    onCompleted(data) {
      if (data.countListOfChatRoom.ok && data.countListOfChatRoom.count) {
        const newTotalPage = Math.ceil(data.countListOfChatRoom.count / take);
        chatHandleContext?.handleTotalPageForListOfChat(newTotalPage);
      }
    }
  });

  const [getListOfChatRoom, { loading, variables }] = useLazyQuery<
    GetListOfChatRoom,
    GetListOfChatRoomVariables
  >(QUERY_GET_LIST_OF_CHAT_ROOM, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      if (data.getListOfChatRoom.ok) {
        setListOfChat(preData => {
          const postData = data.getListOfChatRoom.list || [];
          let newData: GetListOfChatRoom_getListOfChatRoom_list[] = [];
          if (preData.length > 0 && variables?.page !== 1) {
            newData = [...preData, ...postData];
          } else {
            newData = [...postData];
          }
          return newData;
        });
      }
    }
  });

  const listRef: any = useRef();

  const handleScroll = useCallback(
    (_: ListOnScrollProps) => {
      const bottom =
        listRef.current.scrollHeight - listRef.current.scrollTop ===
        listRef.current.clientHeight;

      if (
        bottom &&
        listRef.current.scrollHeight !== 0 &&
        chatContext?.totalPageForListOfChat &&
        page < chatContext?.totalPageForListOfChat
      ) {
        setPage(prePage => {
          return prePage + 1;
        });
      }
    },
    [page, chatContext?.totalPageForListOfChat]
  );

  useEffect(() => {
    if (employee_id) {
      countListOfChatRoom({
        variables: { employeeId: employee_id }
      });
    }
  }, [countListOfChatRoom, employee_id]);

  useEffect(() => {
    if (employee_id) {
      getListOfChatRoom({ variables: { employeeId: employee_id, page, take } });
    }
  }, [getListOfChatRoom, employee_id, page, take]);

  return (
    <Container isLoading={loading || countLoading}>
      {loading || countLoading ? (
        <ReactLoading
          type="spokes"
          color={colors.darkBlue}
          height={200}
          width={170}
        />
      ) : (
        <List
          outerRef={listRef}
          onScroll={handleScroll}
          height={466}
          itemCount={listOfChat.length || 0}
          itemData={listOfChat}
          itemSize={50}
          width={400}
        >
          {ChatRow}
        </List>
      )}
    </Container>
  );
}

export default ListOfChat;
