import styled from "styled-components";
import { Icon } from "@iconify/react";
import ChatIcon from "@iconify/icons-bi/chat";
import GroupIcon from "@iconify/icons-el/group";
import { LIST_OF_CHAT_MENU } from "./chat";

interface IProps {
  menu: LIST_OF_CHAT_MENU;
  handleMenu: (payload: LIST_OF_CHAT_MENU) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Menu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 2px;
  svg {
    font-size: 20px;
  }
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.skyBlue};
    color: ${props => props.theme.colors.white};
  }
  :first-child {
    border-bottom-left-radius: 14px;
  }
  :last-child {
    border-bottom-right-radius: 14px;
  }
`;

function ChatMenu({ handleMenu }: IProps) {
  return (
    <Container>
      <Menu
        onClick={() => {
          handleMenu(LIST_OF_CHAT_MENU.CHAT);
        }}
      >
        <Icon icon={ChatIcon} />
        <span>대화</span>
      </Menu>
      <Menu
        onClick={() => {
          handleMenu(LIST_OF_CHAT_MENU.GROUP);
        }}
      >
        <Icon icon={GroupIcon} />
        <span>그룹</span>
      </Menu>
    </Container>
  );
}

export default ChatMenu;
