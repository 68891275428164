import styled from "styled-components";
import Button from "../../../globalComponents/Button";

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gothic", sans-serif;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const CheckMark = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
  ::after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBoxContainer = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
`;

export const CheckBox = styled.input`
  position: absolute;
  margin: 0;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 25px;
  height: 100%;
  z-index: 999999999999;
  :disabled ~ ${CheckMark} {
    background-color: #636e72;
  }
  :checked ~ ${CheckMark} {
    ::after {
      display: block;
    }
    background-color: #2196f3;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  ${Button} {
    align-self: flex-end;
  }
`;

export const InfoTypeContainer = styled.section`
  min-width: 400px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 0;
`;

export const InfoPeriodContainer = styled.section`
  min-width: 200px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 0;
`;

export const InfoItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const InfoTitle = styled.label`
  margin-left: 5px;
`;

export const CheckMarkRadio = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  ::after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBoxRadio = styled.input`
  position: absolute;
  margin: 0;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 25px;
  height: 100%;
  z-index: 999999999999;
  :checked ~ ${CheckMarkRadio} {
    ::after {
      display: block;
    }
    background-color: #2196f3;
  }
`;

export const DateContainer = styled.section`
  padding: 10px 0 10px 0;
`;

export const Period = styled.input``;

export const PeriodDescription = styled.span`
  margin-left: 5px;
`;

export const TotalPersonnelContainer = styled.section`
  padding: 10px 0 10px 0;
`;

export const TotalPersonnelDescription = styled.span`
  font-weight: bold;
`;

export const PersonnelInfoItem = styled.div`
  min-width: 250px;
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :last-child {
    min-width: 62px;
    width: 62px;
  }
`;

export const PersonnelContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PersonnelInput = styled.input``;

export const PersonnelDescription = styled.span``;
