import { useMemo } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import CaretIcon from "@iconify-icons/bi/caret-right-fill";
import ForwardIcon from "@iconify-icons/bi/fast-forward-fill";

export interface IAsonicPageControllerProps {
  totalPage: number;
  currentPage: number;
  handleCurrentPage: (value: number) => void;
  take: number;
  handleTake: (value: string) => void;
}

export interface IPageControllerFields {
  take?: string;
  goPage?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const DisplayPagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px;
  gap: 4px;
`;

const ForwardButton = styled.div`
  display: flex;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  padding: 3px;
  border-radius: 4px;
  :hover {
    background-color: ${props => props.theme.colors.skyBlue};
  }
  :first-child {
    transform: rotate(1.5turn);
  }
  background-color: ${props => props.theme.colors.darkGrey};
`;

const SBtn = styled.div`
  display: flex;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  padding: 3px;
  border-radius: 4px;
  :hover {
    background-color: ${props => props.theme.colors.skyBlue};
  }
  :nth-child(2) {
    transform: rotate(1.5turn);
  }
  background-color: ${props => props.theme.colors.darkGrey};
`;

const PageButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 5px;
`;

const PageButton = styled.div<{ page: number; currentPage: number }>`
  display: flex;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  color: ${props => {
    if (props.currentPage === props.page) {
      return props.theme.colors.green;
    }
  }};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
`;
function AsonicPageController({
  take,
  totalPage,
  currentPage,
  handleCurrentPage
}: IAsonicPageControllerProps) {
  const listOfPage: number[] = useMemo(() => {
    const list: number[] = [];
    const initPage = Math.ceil(currentPage / 10);

    let totalCurrentPage = initPage * 10;
    if (totalCurrentPage === 0) {
      totalCurrentPage += 10;
    }
    if (totalPage < totalCurrentPage) {
      totalCurrentPage = totalPage;
    }

    let initFirstPage = totalCurrentPage - 10 + 1;
    if (initFirstPage <= 0) {
      initFirstPage = totalCurrentPage - totalCurrentPage + 1;
    }

    for (let i = initFirstPage; i <= totalCurrentPage; i++) {
      list.push(i);
    }

    return list;
  }, [currentPage, totalPage]);

  return (
    <Container>
      <DisplayPagesContainer>
        <ForwardButton
          onClick={() => {
            if (currentPage > 1) {
              handleCurrentPage(1);
            }
          }}
        >
          <Icon icon={ForwardIcon} />
        </ForwardButton>
        <SBtn
          onClick={() => {
            if (currentPage > 1) {
              handleCurrentPage(currentPage - 1);
            }
          }}
        >
          <Icon icon={CaretIcon} />
        </SBtn>
        <PageButtonContainer>
          {listOfPage.map((item, index) => (
            <PageButton
              key={`${item} ${index}`}
              currentPage={currentPage}
              page={item}
              onClick={() => {
                handleCurrentPage(item);
              }}
            >
              {item}
            </PageButton>
          ))}
        </PageButtonContainer>
        <SBtn
          onClick={() => {
            if (currentPage < totalPage) {
              handleCurrentPage(currentPage + 1);
            }
          }}
        >
          <Icon icon={CaretIcon} />
        </SBtn>
        <ForwardButton
          onClick={() => {
            if (currentPage < totalPage) {
              handleCurrentPage(totalPage);
            }
          }}
        >
          <Icon icon={ForwardIcon} />
        </ForwardButton>
      </DisplayPagesContainer>
    </Container>
  );
}

export default AsonicPageController;
