import styled from "styled-components";

export interface ISelectedTab {
  isSelected?: boolean;
}

export interface ITab<T> {
  key: T;
  value: string;
}
interface IProps<T> {
  list: ITab<T>[];
  selectedTab: T;
  handleSelectTab: (payload: T) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  gap: 8px;
  padding: 0px 10px;
`;

const Tab = styled.div<ISelectedTab>`
  display: flex;
  background-color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.white};
  padding: 7px 20px 7px 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid ${props => props.theme.colors.green};
  color: ${props =>
    props.isSelected ? props.theme.colors.white : props.theme.colors.black};
  font-weight: bold;
  :hover {
    color: white;
    background-color: ${props => props.theme.colors.lightGreen};
  }
  user-select: none;
  cursor: pointer;
  :active {
    transform: scale(0.98);
  }
`;

function AsonicTab<T>({ list, selectedTab, handleSelectTab }: IProps<T>) {
  return (
    <Container>
      {list.map((tab, index) => (
        <Tab
          key={index}
          isSelected={selectedTab === tab.key}
          onClick={() => {
            handleSelectTab(tab.key);
          }}
        >
          {tab.value}
        </Tab>
      ))}
    </Container>
  );
}

export default AsonicTab;
