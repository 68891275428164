import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CompanyEntity,
  useGetCompanyListLazyQuery
} from "../../generated/graphql";

interface IProps {
  handleSelectedCompany: (company: CompanyEntity) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.colors.lightGrey};
  align-items: center;
  padding-left: 20px;
  gap: 10px;
`;

const Title = styled.div`
  display: flex;
`;

function ListOfCompanies({ handleSelectedCompany }: IProps) {
  const [listOfCompanies, setListOfCompanies] = useState<
    CompanyEntity[] | undefined
  >();

  const [getCompanyList] = useGetCompanyListLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getCompanyList.ok && data.getCompanyList.list) {
        handleSelectedCompany(data.getCompanyList.list[0]);
        setListOfCompanies(data.getCompanyList.list);
      }
    }
  });

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);
  return (
    <Container>
      <Title>회사 선택</Title>
      {listOfCompanies && (
        <select
          name="company"
          id="list-of-company"
          onChange={event => {
            handleSelectedCompany(JSON.parse(event.target.value));
          }}
        >
          {listOfCompanies.map((item, index) => (
            <option
              value={JSON.stringify(item)}
              key={`${item.companyCode}:${index}`}
            >
              {item.companyName}
            </option>
          ))}
        </select>
      )}
    </Container>
  );
}

export default ListOfCompanies;
