import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

export interface ITextAreaProps extends UseFormRegisterReturn {
  title: string;
  minWidth?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
`;

const TextArea = styled.textarea<{ minWidth?: string }>`
  display: flex;
  flex: 12;
  min-width: ${props => props.minWidth || ""};
`;

const Label = styled.label`
  font-size: 12px;
  color: ${props => props.theme.colors.darkGrey};
  flex: 1;
  min-width: max-content;
`;

function CTextAreaWithLabel(props: ITextAreaProps, ref: any) {
  return (
    <Container>
      {props.required && <span>*</span>}
      <Label htmlFor={props.title}>{props.title}</Label>
      <TextArea
        placeholder={props.title}
        {...props}
        ref={ref}
        id={props.title}
        minWidth={props.minWidth}
        rows={5}
      />
    </Container>
  );
}

const TextAreaWithLabel = forwardRef(CTextAreaWithLabel);
export default TextAreaWithLabel;
