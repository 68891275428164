import { Fragment } from "react";
import RightMenuButton from "../RightMenuButton";

interface IProps {
  handlePhoneNumberRegisterDialog: (isTrue: boolean) => void;
}

const PhoneNumberRegister = <P extends IProps>({
  handlePhoneNumberRegisterDialog
}: P) => {
  return (
    <Fragment>
      <RightMenuButton
        title="전화번호"
        handleClick={() => handlePhoneNumberRegisterDialog(true)}
      />
    </Fragment>
  );
};

export default PhoneNumberRegister;
