import styled from "styled-components";

interface IProps {
  description: string;
  emphasis?: string;
}

const Container = styled.div`
  display: flex;
  font-size: 10px;
`;

const Description = styled.span`
  color: ${props => props.theme.colors.darkGrey};
`;

const Emphasis = styled.span`
  color: ${props => props.theme.colors.darkBlue};
`;

function TimeDescription({ description, emphasis }: IProps) {
  return (
    <Container>
      <Description>{description}</Description>
      <Emphasis>{emphasis}</Emphasis>
    </Container>
  );
}

export default TimeDescription;
