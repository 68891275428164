import { all } from "redux-saga/effects";

import {
  watchGetApprovalDocs,
  watchGetCategories,
  watchGetRequestDocs,
  watchUpdateApprovalDoc,
  watchGetRequestDocsForCal,
  watchMakeNewRequest,
  watchGetWorkingHours
} from "./watchers";

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchGetApprovalDocs(),
    watchGetCategories(),
    watchUpdateApprovalDoc(),
    watchGetRequestDocs(),
    watchMakeNewRequest(),
    watchGetRequestDocsForCal(),
    watchGetWorkingHours()
  ]);
}
