import { useForm } from "react-hook-form";
import styled from "styled-components";
import TextInputWithLabel from "../../../../inputs/text-input-with-label";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import EditIcon from "@iconify/icons-fa-solid/edit";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import AsonicIconButton from "../../../../shared/asonic-icon-button/asonic-icon-button";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import { GetPublicTimeTemplate_getPublicTimeTemplate_list } from "../../../../../__generated__/GetPublicTimeTemplate";
import { useCallback, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  AddTimeTemplate,
  AddTimeTemplateVariables
} from "../../../../../__generated__/AddTimeTemplate";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  UpdateTimeTemplate,
  UpdateTimeTemplateVariables
} from "../../../../../__generated__/UpdateTimeTemplate";
import {
  DeleteTimeTemplate,
  DeleteTimeTemplateVariables
} from "../../../../../__generated__/DeleteTimeTemplate";

interface IFormValues {
  timeName: string;
}

interface IProps {
  selectedRow?: IAsonicRow<GetPublicTimeTemplate_getPublicTimeTemplate_list>;
}

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
`;

const MUTATION_ADD_TIME_TEMPLATE = gql`
  mutation AddTimeTemplate($timeName: String!) {
    addTimeTemplate(timeName: $timeName) {
      ok
      error
      id
    }
  }
`;

const MUTATION_UPDATE_TIME_TEMPLATE = gql`
  mutation UpdateTimeTemplate($timeName: String!, $timeTemplateIdx: Int!) {
    updateTimeTemplate(timeName: $timeName, timeTemplateIdx: $timeTemplateIdx) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_TIME_TEMPLATE = gql`
  mutation DeleteTimeTemplate($timeTemplateIdx: Int!) {
    deleteTimeTemplate(timeTemplateIdx: $timeTemplateIdx) {
      ok
      error
    }
  }
`;

function TimeTemplateEditor({ selectedRow }: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();
  const { register, watch, setValue, getValues } = useForm<IFormValues>();
  const isTimeName = watch("timeName");

  const [deleteTimeTemplate] = useMutation<
    DeleteTimeTemplate,
    DeleteTimeTemplateVariables
  >(MUTATION_DELETE_TIME_TEMPLATE, {
    update(cache, { data }, { variables }) {
      const timeName = getValues("timeName");
      if (data?.deleteTimeTemplate.ok && variables?.timeTemplateIdx) {
        cache.evict({
          id: `PublicTimeTemplate:${variables.timeTemplateIdx}`
        });
        handleMessage(`성공적으로 ${timeName} 템플릿 을 삭제하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.deleteTimeTemplate.ok &&
        data?.deleteTimeTemplate.error
      ) {
        handleMessage(data?.deleteTimeTemplate.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const handleDeleteTimeTemplate = useCallback(() => {
    if (selectedRow?.original.timeTemplateIdx) {
      deleteTimeTemplate({
        variables: {
          timeTemplateIdx: selectedRow?.original.timeTemplateIdx
        }
      });
    }
  }, [deleteTimeTemplate, selectedRow]);

  const [updateTimeTemplate] = useMutation<
    UpdateTimeTemplate,
    UpdateTimeTemplateVariables
  >(MUTATION_UPDATE_TIME_TEMPLATE, {
    update(cache, { data }, { variables }) {
      if (data?.updateTimeTemplate.ok && variables?.timeTemplateIdx) {
        let timeNameBeforeUpdate = "";
        cache.modify({
          id: `PublicTimeTemplate:${variables?.timeTemplateIdx}`,
          fields: {
            timeName(prev) {
              timeNameBeforeUpdate = prev;
              return variables.timeName;
            }
          }
        });
        handleMessage(
          `성공적으로 ${timeNameBeforeUpdate} 템플릿 을 ${variables?.timeName}으로 변경하였습니다.`
        );
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (
        !data?.updateTimeTemplate.ok &&
        data?.updateTimeTemplate.error
      ) {
        handleMessage(data?.updateTimeTemplate.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const handleUpdateTimeTemplate = useCallback(() => {
    const timeName = getValues("timeName");
    if (selectedRow?.original.timeTemplateIdx && timeName) {
      updateTimeTemplate({
        variables: {
          timeTemplateIdx: selectedRow?.original.timeTemplateIdx,
          timeName
        }
      });
    }
  }, [updateTimeTemplate, selectedRow, getValues]);

  const [addTimeTemplate] = useMutation<
    AddTimeTemplate,
    AddTimeTemplateVariables
  >(MUTATION_ADD_TIME_TEMPLATE, {
    update(cache, { data }, { variables }) {
      if (
        data?.addTimeTemplate.ok &&
        data?.addTimeTemplate.id &&
        variables?.timeName
      ) {
        const newTimeTemplate: GetPublicTimeTemplate_getPublicTimeTemplate_list =
          {
            timeTemplateIdx: data?.addTimeTemplate.id,
            timeName: variables?.timeName,
            __typename: "PublicTimeTemplate"
          };

        const newCacheTimeTemplate = cache.writeFragment({
          id: `PublicTimeTemplate:${data?.addTimeTemplate.id}`,
          fragment: gql`
            fragment add on PublicTimeTemplate {
              __typename
              timeTemplateIdx
              timeName
            }
          `,
          data: {
            ...newTimeTemplate
          }
        });
        cache.modify({
          id: `GetPublicTimeTemplateOutput`,
          fields: {
            list(prev) {
              return [...prev, newCacheTimeTemplate];
            }
          }
        });
        handleMessage(
          `성공적으로 ${variables?.timeName} 템플릿을 추가하셨습니다.`
        );
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (!data?.addTimeTemplate.ok && data?.addTimeTemplate.error) {
        handleMessage(data?.addTimeTemplate.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const handleAddTimeTemplate = useCallback(() => {
    const timeName = getValues("timeName");
    addTimeTemplate({
      variables: {
        timeName
      }
    });
  }, [getValues, addTimeTemplate]);

  useEffect(() => {
    if (selectedRow?.original.timeName) {
      setValue("timeName", selectedRow.original.timeName);
    }
  }, [selectedRow, setValue]);
  return (
    <Container>
      <TextInputWithLabel title="템플릿 이름" {...register("timeName")} />
      <ButtonContainer>
        <AsonicIconButton
          icon={AddIcon}
          disabled={!isTimeName ? true : false}
          onClick={handleAddTimeTemplate}
        />
        <AsonicIconButton
          icon={EditIcon}
          disabled={!isTimeName || !selectedRow ? true : false}
          onClick={handleUpdateTimeTemplate}
        />
        <AsonicIconButton
          icon={DeleteIcon}
          disabled={!selectedRow ? true : false}
          onClick={handleDeleteTimeTemplate}
        />
      </ButtonContainer>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default TimeTemplateEditor;
