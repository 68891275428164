import api from "../api";

const WORKING_TIME_SCHEDULE = `${api.api}/working_time_schedule`;
const WORKING_TIME_SCHEDULE_TEMPLETE = `${WORKING_TIME_SCHEDULE}/templete`;
const WORKING_TIME_SCHEDULE_TEMPLETE_PERSONAL = `${WORKING_TIME_SCHEDULE_TEMPLETE}/personal`;
const WORKING_TIME_SCHEDULE_LIST = `${WORKING_TIME_SCHEDULE_TEMPLETE}/schedule`;
const WORKING_TIME_SCHEDULE_TEMPLETE_CATEGORIES = `${WORKING_TIME_SCHEDULE_TEMPLETE}/categories`;
const WORKING_TIME_SCHEDULE_TEMPLATE_FOR_USER = `${WORKING_TIME_SCHEDULE_TEMPLETE}/user`;
const WORKING_TIME_SCHEDULE_TEMPLATE_FOR_DEPARTMENT = `${WORKING_TIME_SCHEDULE_TEMPLETE}/department`;
const PERSONAL_WORKING_TIME_SCHEDULE_TEMPLATE = `${WORKING_TIME_SCHEDULE_TEMPLETE}/personal`;

const workingTimeScheduleApi = {
  workingTimeSchedule: WORKING_TIME_SCHEDULE,
  workingTimeScheduleTemplete: WORKING_TIME_SCHEDULE_TEMPLETE,
  workingTimeScheduleTemplatePersonal: WORKING_TIME_SCHEDULE_TEMPLETE_PERSONAL,
  workingTimeScheduleList: WORKING_TIME_SCHEDULE_LIST,
  workingTimeScheduleTempleteCategories: WORKING_TIME_SCHEDULE_TEMPLETE_CATEGORIES,
  workingTimeScheduleTemplateForUser: WORKING_TIME_SCHEDULE_TEMPLATE_FOR_USER,
  workingTimeScheduleTemplateForDepartment: WORKING_TIME_SCHEDULE_TEMPLATE_FOR_DEPARTMENT,
  personalWorkingTimeScheduleTemplate: (employeeId: string) => {
    return `${PERSONAL_WORKING_TIME_SCHEDULE_TEMPLATE}/${employeeId}`;
  }
};

export default workingTimeScheduleApi;
