import React, { useMemo } from "react";
import CellChartsectionPresenter, {
  Data,
  Options
} from "./cell-chart-section-presenter";
import moment from "moment";
import { TooltipItem, TooltipModel } from "chart.js";

type Props = {
  totalTime: number;
  totalUsingTime: number;
};
const CellChartSectionContainer = <P extends Props>(props: P) => {
  const { totalTime, totalUsingTime } = props;

  const remainTime = React.useMemo(() => {
    const calculateTime = totalTime - totalUsingTime;
    return calculateTime <= 0 ? 0 : calculateTime;
  }, [totalTime, totalUsingTime]);

  const data = useMemo((): Data => {
    return {
      datasets: [
        {
          data: [totalUsingTime, remainTime],
          backgroundColor: ["#C9C9C9", "#03449A"],
          borderWidth: 0
        }
      ],
      labels: ["사용시간", "잔여시간"]
    };
  }, [totalUsingTime, remainTime]);

  const options: Options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      datalabels: false,
      title: {
        display: true,
        fontColor: "#2FBAF3",
        text: `총 ${Math.floor(totalTime / 60)}H(잔여${Math.floor(
          remainTime / 60
        )}H)`,
        position: "bottom"
      },
      tooltip: {
        callbacks: {
          label: function (
            this: TooltipModel<"doughnut">,
            tooltipItem: TooltipItem<"doughnut">
          ) {
            const { label, parsed } = tooltipItem;
            let hours = parsed;
            if (hours < 0) {
              return "0h";
            }
            hours = Math.floor(moment.duration(parsed, "minutes").asHours());
            return `${label}: ${hours}h`;
          }
        }
      }
    }
  };
  return <CellChartsectionPresenter {...props} data={data} options={options} />;
};

export default CellChartSectionContainer;
