import * as React from "react";
import {
  Container,
  ButtonsContainer,
  Button,
  ContentContainer
} from "./styled";
import { Reducers } from "../../../../../types/reducers";
import { useSelector } from "react-redux";
import Content from "./content";
import useDayWorking from "../../../../hooks/electron-hook/service-status-hook/day-working-hook/use-day-working";
import useTotalWorkingHour from "../../../../hooks/electron-hook/service-status-hook/day-working-hook/use-total-working-hour";

const ElectronDayWorkingStatus = () => {
  const { employee_id: employeeId } = useSelector(
    (state: Reducers) => state.signInReducer
  );
  const { today, tomorrow, isToday, handleIsToday } = useDayWorking({
    employeeId
  });
  const {
    todayChartData,
    todayRemainTime,
    tomorrowChartData,
    tomorrowRemainTime
  } = useTotalWorkingHour({ employeeId });
  return (
    <Container data-testid="electron-day-working-status">
      <ButtonsContainer>
        <Button
          isSelected={isToday}
          onClick={() => {
            handleIsToday(true);
          }}
        >
          금일
        </Button>
        <Button
          isSelected={!isToday}
          onClick={() => {
            handleIsToday(false);
          }}
        >
          내일
        </Button>
      </ButtonsContainer>
      <ContentContainer>
        {isToday ? (
          <Content
            data={today}
            chartData={todayChartData}
            remainTime={todayRemainTime}
          />
        ) : (
          <Content
            data={tomorrow}
            chartData={tomorrowChartData}
            remainTime={tomorrowRemainTime}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

export default ElectronDayWorkingStatus;
