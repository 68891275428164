import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  SearchApproverContainer,
  VoterListsContainer,
  VoterItem,
  VoterName,
  PlusIcon,
  SelectedVoterContainer,
  SelectedVoterTitle,
  SelectedVoterTitleContainer,
  MinusIcon,
  UpAndDownContainer,
  UpAndDownIcon,
  SubmitBtn
} from "../MobileRequestDialogView/styled";
import { Input } from "../../../../components/shared";
import SearchIcon from "@iconify/icons-flat-color-icons/search";
import AsonicIconButton from "../../../../components/shared/asonic-icon-button/asonic-icon-button";

const MobileSearchApprover = props => {
  const {
    _handleKeyPress,
    departmentId,
    handleSearchResult,
    handleNameOfVoter,
    nameOfVoter,
    uiString,
    searchResult,
    selectedVoter,
    handleAddVoter,
    handleDeleteVoter,
    approvers,
    setSelectedVoter,
    handleSelectedVoterPosition,
    handleAlertOpen,
    setActiveStep
  } = props;
  useEffect(() => {
    if (selectedVoter.length < 1 && searchResult.length < 1) {
      setSelectedVoter(approvers);
    }
  }, [searchResult.length, selectedVoter.length, approvers, setSelectedVoter]);

  return (
    <React.Fragment>
      <SearchApproverContainer>
        <Input
          id="textarea"
          value={nameOfVoter}
          label={uiString.dialog.title.searchApprover}
          placeholder={uiString.dialog.searchApproverPlaceholder}
          onChange={handleNameOfVoter}
        />
        <AsonicIconButton
          icon={SearchIcon}
          onClick={_handleKeyPress(
            departmentId,
            nameOfVoter,
            handleSearchResult
          )}
        />
      </SearchApproverContainer>
      <VoterListsContainer searchResult={searchResult}>
        {searchResult &&
          searchResult.map(user => (
            <VoterItem key={user.key}>
              <VoterName>{`${user.employee_name}-${user.department_name}`}</VoterName>
              <PlusIcon
                onClick={handleAddVoter(user)}
                className="fas fa-plus-square"
              />
            </VoterItem>
          ))}
        <SelectedVoterContainer>
          <SelectedVoterTitleContainer>
            <SelectedVoterTitle>지정된 결재자</SelectedVoterTitle>
          </SelectedVoterTitleContainer>
          {selectedVoter &&
            selectedVoter.map(user => (
              <VoterItem key={user.employee_id}>
                <UpAndDownContainer>
                  <UpAndDownIcon
                    onClick={handleSelectedVoterPosition(user, "up")}
                    className="fas fa-chevron-circle-up"
                  />
                  <UpAndDownIcon
                    onClick={handleSelectedVoterPosition(user, "down")}
                    className="fas fa-chevron-circle-down"
                  />
                </UpAndDownContainer>
                <VoterName>{`${user.employee_name}-${user.department_name}`}</VoterName>
                <MinusIcon
                  onClick={handleDeleteVoter(user)}
                  className="fas fa-minus-square"
                />
              </VoterItem>
            ))}
        </SelectedVoterContainer>
      </VoterListsContainer>
      <SubmitBtn
        onClick={() => {
          handleAlertOpen();
          if (selectedVoter.length > 0) {
            setActiveStep(0);
          }
        }}
      >
        {uiString.dialog.title.save}
      </SubmitBtn>
    </React.Fragment>
  );
};

MobileSearchApprover.propTypes = {
  _handleKeyPress: PropTypes.func.isRequired,
  departmentId: PropTypes.number.isRequired,
  handleSearchResult: PropTypes.func.isRequired,
  handleNameOfVoter: PropTypes.func.isRequired,
  nameOfVoter: PropTypes.string.isRequired,
  uiString: PropTypes.object.isRequired,
  searchResult: PropTypes.array.isRequired,
  handleDeleteVoter: PropTypes.func.isRequired,
  selectedVoter: PropTypes.array,
  handleAddVoter: PropTypes.func.isRequired,
  approvers: PropTypes.array,
  setSelectedVoter: PropTypes.func
};

export default MobileSearchApprover;
