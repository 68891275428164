import styled from "styled-components";
import { IProps } from "../RightMenuContainer";

export const Container = styled.div`
  z-index: 10;
  font-size: 12px;
  position: fixed;
  top: ${(props: IProps) =>
    props.rightMenuPosition && `${props.rightMenuPosition.pageY}px`};
  left: ${(props: IProps) =>
    props.rightMenuPosition && `${props.rightMenuPosition.pageX}px`};
  background-color: white;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  width: 150px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-shadow: 7px 6px 13px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 6px 13px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 6px 13px -2px rgba(0, 0, 0, 0.75);
  visibility: ${props => (props.isRightMenu ? "visible" : "hidden")};
`;
