export const GLOBAL_NOTIFICATION_ON =
  "SIGNIN_STATE_REDUCER/GLOBAL_NOTIFICATION_ON";
export const GET_WORKING_HOURS = "SIGNIN_STATE_REDUCER/GET_WORKING_HOURS";
export const SET_WORKING_HOURS = "SIGNIN_STATE_REDUCER/SET_WORKING_HOURS";
export const SET_WORKING_INFO = "SIGNIN_STATE_REDUCER/SET_WORKING_INFO";

// TYPE
let initialState = {
  notiOpen: false,
  message: "",
  workingHours: [],
  workingInfo: {}
};

export const globalNotiOn = notibarConf => ({
  type: GLOBAL_NOTIFICATION_ON,
  notibarConf
});

export const getWorkingHours = args => ({
  type: GET_WORKING_HOURS,
  args
});

export const mainViewActionCreator = {
  globalNotiOn,
  getWorkingHours
};

const mainViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_NOTIFICATION_ON:
      return {
        ...state,
        ...action.notibarConf
      };
    case SET_WORKING_HOURS:
      return {
        ...state,
        workingHours: action.workingHours
      };
    case SET_WORKING_INFO:
      return {
        ...state,
        workingInfo: action.workingInfo
      };
    default:
      return state;
  }
};

export default mainViewReducer;
