import { INextProps } from "../DateWithTimeContainer";
import { Container, Label, DateWithTimeInput } from "./styled";

const DateWithTime = (props: INextProps) => {
  const { data, handleData, title } = props;
  return (
    <Container>
      <Label htmlFor={title}>{title}</Label>
      <DateWithTimeInput
        type="datetime-local"
        value={data}
        onChange={handleData}
        required
        id={title}
      />
    </Container>
  );
};

export default DateWithTime;
