import * as React from "react";
import moment from "moment";
import { RRule } from "rrule";
import { Data } from "../../../components/globalComponents/select";

interface IProps {
  standardDate: string;
}

export const useRecurrence = <P extends IProps>({ standardDate }: P) => {
  const [listOfRecurrenceRule, setListOfRecurrenceRule] = React.useState<
    Data[]
  >([]);
  const [selectedRecurrenceRule, setSelectedRecurrenceRule] = React.useState<
    string[]
  >([]);

  const handleSelectedRecurrenceRule = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        setSelectedRecurrenceRule([event.target.value]);
      } else {
        setSelectedRecurrenceRule([]);
      }
    },
    []
  );

  const findDay = React.useCallback((dayNumber: number) => {
    let day = RRule.MO.toString();

    switch (dayNumber.toString()) {
      case RRule.MO.weekday.toString():
        day = RRule.MO.toString();
        break;
      case RRule.TU.weekday.toString():
        day = RRule.TU.toString();
        break;
      case RRule.WE.weekday.toString():
        day = RRule.WE.toString();
        break;
      case RRule.TH.weekday.toString():
        day = RRule.TH.toString();
        break;
      case RRule.FR.weekday.toString():
        day = RRule.FR.toString();
        break;
      case RRule.SA.weekday.toString():
        day = RRule.SA.toString();
        break;
      case RRule.SU.weekday.toString():
        day = RRule.SU.toString();
        break;
      default:
        break;
    }
    return day;
  }, []);

  React.useEffect(() => {
    const DAY_NAME = moment(standardDate, "YYYY-M-DD").format("dddd");
    const DAY_FULL_NAME = moment(standardDate, "YYYY-M-DD").format("MMMM D일");
    const DAY_NUMBER = moment(standardDate, "YYYY-M-DD").day() - 1;

    const weekNumber =
      moment(standardDate, "YYYY-M-DD").week() -
      moment(standardDate, "YYYY-M-DD").startOf("month").week() +
      1;
    const dailyRule = "RRULE:FREQ=DAILY";
    const weeklyRule = new RRule({
      freq: RRule.WEEKLY,
      byweekday: DAY_NUMBER
    }).toString();
    const monthlyRule = `RRULE:FREQ=MONTHLY;BYDAY=${weekNumber}${findDay(
      DAY_NUMBER
    )}`;
    const yearlyRule = `RRULE:FREQ=YEARLY`;
    const everyDayOftheWeek = `RRULE:FREQ=WEEKLY;BYDAY=FR,MO,TH,TU,WE`;

    setListOfRecurrenceRule([
      {
        data: { id: "", name: "반복안함" },
        origin: ""
      },
      { data: { id: dailyRule, name: "매일" }, origin: dailyRule },
      {
        data: { id: weeklyRule, name: `매주 ${DAY_NAME}` },
        origin: weeklyRule
      },
      {
        data: { id: monthlyRule, name: `매월 ${weekNumber}번째 ${DAY_NAME}` },
        origin: monthlyRule
      },
      {
        data: { id: yearlyRule, name: `매년 ${DAY_FULL_NAME}` },
        origin: yearlyRule
      },
      {
        data: { id: everyDayOftheWeek, name: "주중 매일(월~금)" },
        origin: everyDayOftheWeek
      }
    ]);
  }, [findDay, standardDate]);
  return {
    listOfRecurrenceRule,
    selectedRecurrenceRule,
    handleSelectedRecurrenceRule,
    setSelectedRecurrenceRule
  };
};
