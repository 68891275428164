import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MapButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${props => props.theme.colors.lightMidNightBlue};
  margin-right: 10px;
  :hover {
    color: ${props => props.theme.colors.lightGreen};
    transform: scale(1.5);
  }
`;
