import styled from "styled-components";
import basicImg from "../../images/basicimage.jpg";

interface UserInfo {
  employeeId: string;
  name: string;
  department: string;
  picture: string;
  telephone?: string;
  email?: string;
  etc?: string;
}
interface PhotoProps {
  photo?: string;
  uploadImg?: string | null;
}

interface DepartMentProps {
  departmentId: number;
  fullName: string;
}

interface Props {
  userInfo?: UserInfo;
  departmentInfo?: DepartMentProps;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Container = styled.div`
  display: flex;
  min-width: max-content;
  min-height: max-content;
  flex: 1;
  background-color: ${props => props.theme.colors.white};
  gap: 10px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 14px;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
`;

const ProfilePicture = styled.div<PhotoProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url(data:image/png+jpg;base64,${props.photo})`
      : `url(${props.uploadImg || basicImg})`};
`;

const PictureContainer = styled.div`
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;
const UserDepartment = styled.span``;

const ButtonContainer = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  align-items: center;
  svg {
    font-size: 20px;
  }
`;

function ProfileCard({ userInfo, departmentInfo, children, onClick }: Props) {
  return (
    <Container onClick={onClick}>
      {userInfo && (
        <PictureContainer>
          <ProfilePicture photo={userInfo?.picture} />
        </PictureContainer>
      )}
      <UserInfoContainer>
        <UserName>{userInfo?.name || departmentInfo?.fullName}</UserName>
        {userInfo && (
          <UserDepartment>부서명 - {userInfo.department}</UserDepartment>
        )}
        {userInfo?.telephone && (
          <UserDepartment>연락처 - {userInfo.telephone}</UserDepartment>
        )}
        {userInfo?.email && (
          <UserDepartment>Email - {userInfo.email}</UserDepartment>
        )}
        {userInfo?.etc && <UserDepartment>{userInfo.etc}</UserDepartment>}
      </UserInfoContainer>
      <ButtonContainer>{children}</ButtonContainer>
    </Container>
  );
}

export default ProfileCard;
