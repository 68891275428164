import { useForm } from "react-hook-form";
import styled from "styled-components";
import TextInputWithLabel from "../inputs/text-input-with-label";
import AsonicIconButton from "../shared/asonic-icon-button/asonic-icon-button";
import TextAreaWithLabel from "../shared/text-area/text-area";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import EditIcon from "@iconify/icons-fa-solid/edit";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";
import { colors } from "../GlobalStyle/GlobalStyle";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import { GetListOfReservationCategory_getListOfReservationCategory_list } from "../../__generated__/GetListOfReservationCategory";
import { useCallback, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  AddReservationCategory,
  AddReservationCategoryVariables
} from "../../__generated__/AddReservationCategory";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import {
  UpdateReservationCategory,
  UpdateReservationCategoryVariables
} from "../../__generated__/UpdateReservationCategory";
import {
  DeleteReservationCategory,
  DeleteReservationCategoryVariables
} from "../../__generated__/DeleteReservationCategory";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../hooks/confirm-dialog-hook/use-confirm-dialog";

interface IProps {
  selectedGroupIdx?: number;
  selectedRow?: IAsonicRow<GetListOfReservationCategory_getListOfReservationCategory_list>;
}

interface IFormValues {
  categoryName: string;
  categoryDescription: string;
}

const Container = styled.div`
  display: flex;
  flex: 5;
  border-top: 1px solid ${props => props.theme.borderColor};
  flex-direction: column;
  padding: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
`;

const MUTATION_ADD_RESERVATION_CATEGORY = gql`
  mutation AddReservationCategory(
    $groupIdx: Int!
    $categoryName: String!
    $categoryDescription: String!
  ) {
    addReservationCategory(
      groupIdx: $groupIdx
      categoryName: $categoryName
      categoryDescription: $categoryDescription
    ) {
      ok
      error
      categoryIdx
    }
  }
`;

const MUTATION_UPDATE_RESERVATION_CATEGORY = gql`
  mutation UpdateReservationCategory(
    $categoryIdx: Int!
    $categoryName: String!
    $categoryDescription: String!
  ) {
    updateReservationCategory(
      categoryIdx: $categoryIdx
      categoryName: $categoryName
      categoryDescription: $categoryDescription
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_RESERVATION_CATEGORY = gql`
  mutation DeleteReservationCategory($listOfCategoryIdx: [Int!]!) {
    deleteReservationCategory(listOfCategoryIdx: $listOfCategoryIdx) {
      ok
      error
    }
  }
`;

function ReservationCategoryEditor({ selectedRow, selectedGroupIdx }: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isOpenConfirmDialog,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const { register, watch, setValue, getValues } = useForm<IFormValues>();
  const categoryName = watch("categoryName");

  const [deleteReservationCategory] = useMutation<
    DeleteReservationCategory,
    DeleteReservationCategoryVariables
  >(MUTATION_DELETE_RESERVATION_CATEGORY, {
    update(cache, { data }, { variables }) {
      if (data?.deleteReservationCategory.ok && variables) {
        cache.evict({
          id: `ReservationCategoryEntity:${variables.listOfCategoryIdx[0]}`
        });
        handleToast(
          "성공적으로 선택된 상세예약항목을 삭제하셔습니다.",
          MessageTypes.SUCCESS
        );
        handleIsOpen(false);
      }

      if (data?.deleteReservationCategory.error) {
        handleToast(data.deleteReservationCategory.error, MessageTypes.ERROR);
      }
    }
  });

  const [updateReservationCategory] = useMutation<
    UpdateReservationCategory,
    UpdateReservationCategoryVariables
  >(MUTATION_UPDATE_RESERVATION_CATEGORY, {
    update(cache, { data }, { variables }) {
      if (data?.updateReservationCategory.ok && variables) {
        cache.modify({
          id: `ReservationCategoryEntity:${variables.categoryIdx}`,
          fields: {
            categoryName() {
              return variables.categoryName;
            },
            categoryDescription() {
              return variables.categoryDescription;
            }
          }
        });
        handleToast(
          "성공적으로 선택된 상세예약항목 정보를 변경하셨습니다.",
          MessageTypes.SUCCESS
        );
      }

      if (data?.updateReservationCategory.error) {
        handleToast(data.updateReservationCategory.error, MessageTypes.ERROR);
      }
    }
  });

  const [addReservationCategory] = useMutation<
    AddReservationCategory,
    AddReservationCategoryVariables
  >(MUTATION_ADD_RESERVATION_CATEGORY, {
    update(cache, { data }, { variables }) {
      if (
        data?.addReservationCategory.ok &&
        data.addReservationCategory.categoryIdx &&
        variables
      ) {
        const newReservationCategory: GetListOfReservationCategory_getListOfReservationCategory_list =
          {
            groupIdx: variables?.groupIdx,
            categoryIdx: data?.addReservationCategory.categoryIdx,
            categoryName: variables?.categoryName,
            categoryDescription: variables.categoryDescription,
            __typename: "ReservationCategoryEntity"
          };
        const newCacheReservationCategory = cache.writeFragment({
          id: `ReservationCategoryEntity:${data?.addReservationCategory.categoryIdx}`,
          fragment: gql`
            fragment add on ReservationCategoryEntity {
              groupIdx
              categoryIdx
              categoryName
              categoryDescription
              __typename
            }
          `,
          data: {
            ...newReservationCategory
          }
        });
        cache.modify({
          id: `GetListOfReservationCategoryOutput`,
          fields: {
            list(prev) {
              if (prev) {
                return [...prev, newCacheReservationCategory];
              }
              return [newCacheReservationCategory];
            }
          }
        });
        handleToast(
          "성공적으로 예약상세항목을 추가하셨습니다.",
          MessageTypes.SUCCESS
        );
      }
      if (data?.addReservationCategory.error) {
        handleToast(data?.addReservationCategory.error, MessageTypes.SUCCESS);
      }
    }
  });

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      deleteReservationCategory({
        variables: {
          listOfCategoryIdx: [selectedRow.original.categoryIdx]
        }
      });
    }
  }, [selectedRow, deleteReservationCategory]);

  useEffect(() => {
    if (selectedRow) {
      setValue("categoryName", selectedRow.original.categoryName);
      setValue("categoryDescription", selectedRow.original.categoryDescription);
      handleConfirmMessage({
        title: `예약상세항목 삭제`,
        p: `예약상세항목: ${selectedRow.original.categoryName}을 삭제하시겠습니까?`,
        messageTypes: MessageTypes.WARNING
      });
    }
  }, [selectedRow, handleConfirmMessage, setValue]);

  return (
    <Container>
      <FormContainer>
        <TextInputWithLabel title="항목 이름" {...register("categoryName")} />
        <TextAreaWithLabel
          title="상세 설명"
          {...register("categoryDescription")}
        />
      </FormContainer>
      <ButtonContainer>
        <AsonicIconButton
          disabled={!categoryName || !selectedGroupIdx}
          icon={AddIcon}
          onClick={() => {
            if (categoryName && selectedGroupIdx) {
              const categoryDescription = getValues("categoryDescription");
              addReservationCategory({
                variables: {
                  groupIdx: selectedGroupIdx,
                  categoryName,
                  categoryDescription
                }
              });
            }
          }}
        />
        <AsonicIconButton
          disabled={!selectedRow || !categoryName}
          icon={EditIcon}
          onClick={() => {
            if (selectedRow) {
              const categoryDescription = getValues("categoryDescription");
              updateReservationCategory({
                variables: {
                  categoryName,
                  categoryDescription,
                  categoryIdx: selectedRow.original.categoryIdx
                }
              });
            }
          }}
        />
        <AsonicIconButton
          disabled={!selectedRow}
          icon={DeleteIcon}
          color={colors.tomato}
          onClick={() => {
            if (selectedRow?.original.categoryIdx) {
              handleIsOpen(true);
            }
          }}
        />
      </ButtonContainer>
      {isOpenConfirmDialog && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ReservationCategoryEditor;
