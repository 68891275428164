import styled from "styled-components";
import {
  ApprovalEntity,
  ApprovalSearchType,
  Approval_For_List_Type,
  useGetListOfApprovalLazyQuery
} from "../../../generated/graphql";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AutoSizer } from "react-virtualized";
import MApprovalRow from "./m_approval_row";
import ReactLoading from "react-loading";
import { colors } from "../../GlobalStyle/GlobalStyle";
import { MessageTypes } from "../../toast-message/toast-message";
import { isSafari } from "react-device-detect";

export interface MApprovalIProps {
  employeeId?: string;
  searchValue: string;
  count: number;
  handleToast: (message: string, type: MessageTypes) => void;
  isInit: boolean;
  setIsInit: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IMApprovalEntity extends ApprovalEntity {
  isOpen?: boolean;
}

export const MContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const MListContainer = styled.div<{
  $height: number;
  $width: number;
  $loading?: boolean;
}>`
  display: flex;
  flex: 1;
  overflow: auto;
  height: ${props => (props.$height ? `${props.$height}px` : "")};
  width: ${props => (props.$width ? `${props.$width}px` : "")};
  flex-direction: column;
  will-change: scroll-position;
  justify-content: ${props => (props.$loading ? "center" : "")};
  align-items: ${props => (props.$loading ? "center" : "")};
`;

function MApprovalWaitingList({
  employeeId,
  searchValue,
  count,
  handleToast,
  isInit,
  setIsInit
}: MApprovalIProps) {
  const listRef: any = useRef();
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [list, setList] = useState<IMApprovalEntity[]>([]);
  const [page, setPage] = useState<number>(1);
  const take = useMemo(() => 30, []);
  const [getListOfApproval, { loading }] = useGetListOfApprovalLazyQuery({
    onCompleted(data) {
      if (data.getListOfApproval.ok && data.getListOfApproval.list) {
        setList(pre => {
          const newList = [...pre];
          if (data.getListOfApproval.list) {
            data.getListOfApproval.list.forEach(item => {
              const isExist = pre.find(
                originItem =>
                  originItem.approvalRequestIdx === item.approvalRequestIdx
              );
              if (!isExist) {
                newList.push({ ...item, isOpen: false });
              }
            });
          }
          return newList;
        });
      }
    }
  });

  const handleScroll = useCallback(() => {
    const scrollHeight = listRef.current.scrollHeight;
    const scrollTop = listRef.current.scrollTop;
    const clientHeight = listRef.current.clientHeight;
    const takeingPoint = scrollHeight * 0.35;

    let bottom = scrollHeight - scrollTop < takeingPoint;
    const newTotalPage = Math.ceil(count / take);

    if (isSafari && navigator.userAgent.indexOf("Whale") === -1) {
      bottom = scrollHeight - scrollTop - 1 === clientHeight;
    }

    if (
      bottom &&
      scrollHeight !== 0 &&
      newTotalPage &&
      page < newTotalPage &&
      !loading
    ) {
      setPage(prePage => {
        return prePage + 1;
      });
    }
  }, [count, take, page, loading]);

  useEffect(() => {
    getListOfApproval({
      variables: {
        approvalType: Approval_For_List_Type.Waiting,
        searchType: ApprovalSearchType.App,
        searchValue,
        take,
        page,
        customEmployeeId: employeeId
      }
    });
  }, [getListOfApproval, take, employeeId, searchValue, page]);

  useLayoutEffect(() => {
    if (isRendered) {
      if (listRef.current) {
        listRef.current?.addEventListener("scroll", handleScroll);
      }
    }
    if (!isRendered) {
      if (listRef.current) {
        setIsRendered(true);
      }
    }
    return () => {
      listRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isRendered, handleScroll]);

  useEffect(() => {
    if (isInit) {
      setList([]);
    }
    setIsInit(false);
  }, [isInit, setIsInit]);

  return (
    <MContainer>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <MListContainer
              $height={height}
              $width={width}
              ref={listRef}
              $loading={loading}
            >
              {loading && (
                <ReactLoading
                  type="spokes"
                  color={colors.green}
                  height={200}
                  width={170}
                />
              )}
              {!loading &&
                list.map(item => (
                  <MApprovalRow
                    setList={setList}
                    data={item}
                    key={item.approvalRequestIdx}
                    handleToast={handleToast}
                    employeeId={employeeId}
                    isEdit
                  />
                ))}
            </MListContainer>
          );
        }}
      </AutoSizer>
    </MContainer>
  );
}

export default MApprovalWaitingList;
