import basicImg from "../../images/basicimage.jpg";
import {
  ChatContext,
  ChatHandleContext,
  DEFAULT_PARENT_DEPARTMENT_ID,
  LIST_OF_CHAT_MENU
} from "./chat";
import { GetUser_getUser_user } from "../../__generated__/GetUser";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import { useContext } from "react";
import WindowCloseIcon from "@iconify/icons-fa-solid/window-close";
import ChatBubbleIcon from "@iconify-icons/cil/chat-bubble";
import { gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { Reducers } from "../../../types/reducers";
import {
  AddChatRoom,
  AddChatRoomVariables
} from "../../__generated__/AddChatRoom";
import { GetListOfChatRoom_getListOfChatRoom_list } from "../../__generated__/GetListOfChatRoom";
import {
  AddUserFromChatRoom,
  AddUserFromChatRoomVariables
} from "../../__generated__/AddUserFromChatRoom";

interface IProps {
  selectedUser: GetUser_getUser_user;
}

interface PhotoProps {
  photo?: string | null;
}
const Container = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  border-radius: 14px;
  background-color: ${props => props.theme.colors.cobaltBlue};
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
`;

const PictureContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const ProfilePicture = styled.div<PhotoProps>`
  width: 50px;
  max-width: 50px;
  height: 50px;
  max-height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url(data:image/png+jpg;base64,${props.photo})`
      : `url(${basicImg})`};
`;

const Name = styled.span`
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: bold;
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  align-self: flex-end;
  padding: 10px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.tomato};
  }
  :active {
    transform: scale(0.95);
  }
`;

const ChatBtnContainer = styled.div`
  display: flex;
  flex: 2;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  svg {
    font-size: 16px;
  }
  :hover {
    color: ${props => props.theme.colors.lemon};
  }
  :active {
    transform: scale(0.98);
  }
`;

const ChatBtnTitle = styled.div``;

const MUTATION_ADD_CHAT_ROOM = gql`
  mutation AddChatRoom($userId: String!, $partnerId: String!) {
    addChatRoom(userId: $userId, partnerId: $partnerId) {
      ok
      error
      chatRoomIdx
      nameOfRoom
    }
  }
`;

const MUTATION_ADD_USER_FROM_CHAT_ROOM = gql`
  mutation AddUserFromChatRoom(
    $chatRoomIdx: Int!
    $userId: String!
    $inviteeName: String
  ) {
    addUserFromChatRoom(
      chatRoomIdx: $chatRoomIdx
      userId: $userId
      inviteeName: $inviteeName
    ) {
      ok
      error
    }
  }
`;

function UserInfoInChat({ selectedUser }: IProps) {
  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const [addUserFromChatRoom] = useMutation<
    AddUserFromChatRoom,
    AddUserFromChatRoomVariables
  >(MUTATION_ADD_USER_FROM_CHAT_ROOM, {
    async update(_, { data }, { variables }) {
      if (data?.addUserFromChatRoom.ok && variables) {
        // cache.modify({
        //   id: `ListOfChatRoom:${variables.chatRoomIdx}`,
        //   fields: {
        //     roomname(preRoomName) {
        //       const regexp = /외\d{1,3}/gi;
        //       let newRoomName = preRoomName;
        //       if (regexp.test(preRoomName)) {
        //         const newNumber = parseInt(preRoomName.match(/\d{1,3}$/gi)) + 1;
        //         newRoomName = preRoomName.replace(regexp, `외${newNumber}`);
        //         chatHandleContext?.handleSelectedChatRoomName(newRoomName);
        //         return newRoomName;
        //       }
        //       newRoomName = `${preRoomName}.. 외3`;
        //       chatHandleContext?.handleSelectedChatRoomName(newRoomName);
        //       return newRoomName;
        //     }
        //   }
        // });
        chatHandleContext?.handleIsUserInvitation(false);
        chatHandleContext?.handleParentDepartment({
          parentDepartmentId: DEFAULT_PARENT_DEPARTMENT_ID,
          selectedDepartmentName: "전체"
        });
        chatHandleContext?.handleSelectedUser(undefined);
      }
    }
  });

  const [addChatRoom] = useMutation<AddChatRoom, AddChatRoomVariables>(
    MUTATION_ADD_CHAT_ROOM,
    {
      onError(error) {
        console.log(error);
      },
      update(cache, { data }, { variables }) {
        if (
          data?.addChatRoom.ok &&
          data.addChatRoom.chatRoomIdx &&
          data.addChatRoom.nameOfRoom &&
          variables
        ) {
          chatHandleContext?.handleSelectedChatRoomName(
            data.addChatRoom.nameOfRoom
          );
          const newChatRoom: GetListOfChatRoom_getListOfChatRoom_list = {
            chatroom_idx: data.addChatRoom.chatRoomIdx,
            roomname: data.addChatRoom.nameOfRoom,
            chatroom_message_data: "",
            noreadcount: 0,
            __typename: "ListOfChatRoom"
          };
          const newCacheChatRoom = cache.writeFragment({
            id: `ListOfChatRoom:${data?.addChatRoom.chatRoomIdx}`,
            fragment: gql`
              fragment add on ListOfChatRoom {
                chatroom_idx
                roomname
                chatroom_message_data
                noreadcount
                __typename
              }
            `,
            data: {
              ...newChatRoom
            }
          });
          cache.modify({
            id: `GetListOfChatRoomOutput`,
            fields: {
              list(prev) {
                if (prev) {
                  return [...prev, newCacheChatRoom];
                }
                return [newCacheChatRoom];
              }
            }
          });
          chatHandleContext?.handleSelectedChatRoom(
            data.addChatRoom.chatRoomIdx
          );
          chatHandleContext?.handleSelectedChatRoom(
            data.addChatRoom.chatRoomIdx
          );
          chatHandleContext?.handleMenu(LIST_OF_CHAT_MENU.CHAT_ROOM);
          chatHandleContext?.handleSelectedUser(undefined);
        }
      }
    }
  );

  return (
    <Container>
      <TitleBar>
        <IconContainer
          onClick={() => {
            chatHandleContext?.handleSelectedUser(undefined);
          }}
        >
          <Icon icon={WindowCloseIcon} />
        </IconContainer>
      </TitleBar>
      <PictureContainer>
        <ProfilePicture photo={selectedUser.myPicture} />
      </PictureContainer>
      <Name>{selectedUser.name}</Name>
      <ChatBtnContainer
        onClick={() => {
          if (selectedUser) {
            if (
              chatContext?.isUserInvitation &&
              chatContext?.selectedChatRoomIdx
            ) {
              addUserFromChatRoom({
                variables: {
                  userId: selectedUser.employeeId,
                  inviteeName: selectedUser.name,
                  chatRoomIdx: chatContext.selectedChatRoomIdx
                }
              });
            } else {
              addChatRoom({
                variables: {
                  userId: employee_id,
                  partnerId: selectedUser.employeeId
                }
              });
            }
          }
        }}
      >
        <Icon icon={ChatBubbleIcon} />
        <ChatBtnTitle>
          {chatContext?.isUserInvitation ? "대화상대 초대" : "1:1 대화 시작"}
        </ChatBtnTitle>
      </ChatBtnContainer>
    </Container>
  );
}

export default UserInfoInChat;
