import * as React from "react";
import moment from "moment";

type Start = "start";
type End = "end";

type IsStartOrEnd = Start | End;

const useDate = () => {
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));

  const handleDate = React.useCallback(
    (type: IsStartOrEnd) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const date = event.target.value;
      if (type === "start") {
        setStartDate(moment(date).format("YYYY-MM-DD"));
      } else if (type === "end") {
        setEndDate(moment(date).format("YYYY-MM-DD"));
      }
    },
    []
  );

  return { startDate, endDate, handleDate };
};
export default useDate;
