import * as React from "react";
import WorkingStatusInformation from "../working-status-information";
import WorkingStatusChart from "../working-status-chart";

type other = {
  name: string;
  time: string;
};
type Data = {
  regular: string;
  others: other[];
};
type Datasets = {
  backgroundColor: string[];
  data: number[];
  borderWidth: number;
  barThickness: number;
};

type ChartData = {
  datasets: Datasets[];
  labels: string[];
};

type Props = {
  data: Data;
  chartData: ChartData;
  remainTime: string;
};

const Content = <P extends Props>({ data, chartData, remainTime }: P) => {
  return (
    <React.Fragment>
      <WorkingStatusInformation data={data} />
      <WorkingStatusChart chartData={chartData} remainTime={remainTime} />
    </React.Fragment>
  );
};

export default Content;
