import * as React from "react";
import {
  HomeTitleTextContainer,
  HomeTitleContainer,
  HomeMainTitleText,
  HomeSubTitleText
} from "./styled";

interface IProps {
  open?: boolean;
  handleGoHome: () => void;
  loginIdInfo: boolean;
}

const HomeTitle = (props: IProps) => {
  const { open, handleGoHome } = props;
  return (
    <HomeTitleContainer onClick={handleGoHome}>
      <HomeTitleTextContainer open={open}>
        <HomeMainTitleText>A-SONIC</HomeMainTitleText>
        <HomeSubTitleText>근무관리</HomeSubTitleText>
      </HomeTitleTextContainer>
    </HomeTitleContainer>
  );
};

export default HomeTitle;
