import styled from "styled-components";
import AsonicSearch, {
  SearchFieldValues
} from "../../../asonic-table/asonic-search";
import { memo, useCallback, useMemo, useState } from "react";
import MainTokenTable from "./main_token_table";
import SubTokenTable from "./sub_token_table";

export const S_A_PERMISSION_SEARCH_TYPE = {
  TOKEN: "TOKEN",
  USER_ID: "USER_ID"
} as const;

export type S_A_PERMISSION_SEARCH_TYPE =
  (typeof S_A_PERMISSION_SEARCH_TYPE)[keyof typeof S_A_PERMISSION_SEARCH_TYPE];

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

const Body = styled.div`
  display: flex;
  flex: 10;
  gap: 20px;
`;

function SuperAdminPermissionSetting() {
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState<S_A_PERMISSION_SEARCH_TYPE>(
    S_A_PERMISSION_SEARCH_TYPE.TOKEN
  );
  const [selectedMainTokenField, setSelectedMainTokenField] = useState<
    string | undefined
  >("");

  const listOfSearchType = useMemo(() => {
    return [
      {
        value: S_A_PERMISSION_SEARCH_TYPE.TOKEN,
        name: "토큰"
      },
      {
        value: S_A_PERMISSION_SEARCH_TYPE.USER_ID,
        name: "사번"
      }
    ];
  }, []);

  const handleSearch = useCallback((data: SearchFieldValues) => {
    if (data.type) {
      setSearchType(data.type as S_A_PERMISSION_SEARCH_TYPE);
      setSearchValue(data.value as string);
    }
  }, []);

  return (
    <Container>
      <Header>
        <AsonicSearch
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
        />
      </Header>
      <Body>
        <MainTokenTable
          searchValue={searchValue}
          searchType={searchType}
          setSelectedMainTokenField={setSelectedMainTokenField}
        />
        <SubTokenTable selectedMainTokenField={selectedMainTokenField} />
      </Body>
    </Container>
  );
}

export default memo(SuperAdminPermissionSetting);
