import * as React from "react";
import MapRegister from "./MapRegister/MapRegister";
import uiString from "../string.json";
import { faGlobeAsia, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import {
  LogOutBtn,
  // GoMobileBtn,
  Panel,
  AdminPanel,
  MainPanel,
  Arrow,
  Row,
  RowTitle,
  Icon,
  TitleDiv,
  LogOutIcon,
  ButtonContainer
} from "./styled";
import { MAINVIEW_PATH, RTC } from "../../../../constValues.js";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import AsonicCalendar from "../../../asonic-calendar";
import DownloadDebuggingFile from "./download-debugging-file";
import SuperAdminMenu from "./super-admin-menu";
import useTitleOfTitleBar from "../../../../hooks/title-bar-hook/use-title-of-title-bar";
import PageTitleOfTitleBar from "./page-title-of-title-bar";
import useCheckWebAuthFn from "../../../../hooks/use-check-web-auth-fn";
import useTitleBarMenu from "../../../../hooks/use-menu-by-etc-flag";
import SearchDashBoard from "../../../dash-board/search-dash-board/search-dash-board";
import useOpenDialog from "../../../../hooks/use-open-dialog";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import MapRegisterDialog from "./map-register-dialog/map-register-dialog";
import { colors } from "../../../GlobalStyle/GlobalStyle";
import WorkingTimeScheduleSettingDialog from "./working-time-schedule-setting-dialog/wokring-time-schedule-setting-dialog";
import { Role } from "../../../../user-types";
import ChatButton from "../../../chat/chat-button";
import { SEARCH_DB_TYPE_FOR_DASH_BOARD } from "../../../../__generated__/globalTypes";
import { SignInReducer } from "../../../../../types/reducers";

interface IProps {
  handleLogout: () => void;
  handleMobile: () => void;
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  isOpenDialog: boolean;
  handleOpenDialog: (value: boolean) => void;
  signInReducer: SignInReducer;
  isDebugging: boolean;
}

const TitleBarPresenter = <P extends IProps>({
  // handleMobile,
  isOpen,
  handleLogout,
  handleIsOpen,
  isOpenDialog,
  handleOpenDialog,
  signInReducer: { loginIdInfo, menuList, checkWebAuthFn, employee_id },
  isDebugging
}: P) => {
  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: menuList[0]?.web_etc_flag
  });
  const { menu } = useTitleBarMenu({ webEtcFlag: menuList[0]?.web_etc_flag });
  const isShowMap = React.useMemo(() => {
    if (
      menuList.length > 0 &&
      menuList[0] &&
      menuList[0].web_commutegps_flag &&
      menuList[0].web_commutegps_flag === 1
    ) {
      return true;
    } else {
      return false;
    }
  }, [menuList]);
  const {
    isOpen: isDashboardSearchDialog,
    handleOpenDialog: handleOpenDashboardSearchDialog
  } = useOpenDialog();

  const {
    isOpen: isWorkingTimeScheduleSettingDialog,
    handleOpenDialog: handleOpenWorkingTimeScheduleSettingDialog
  } = useOpenDialog();

  return (
    <TitleDiv>
      <PageTitleOfTitleBar
        {...useTitleOfTitleBar()}
        menuList={menuList?.[0]}
        employeeId={employee_id}
        handleOpenDashboardSearchDialog={handleOpenDashboardSearchDialog}
      />
      <Panel>
        <AdminPanel
          onClick={() => {
            handleIsOpen(isOpen ? false : true);
          }}
        >
          <Icon icon={faGlobeAsia} size="lg" />
        </AdminPanel>
        <MainPanel
          isOpen={isOpen}
          onClick={() => {
            handleIsOpen(false);
          }}
        >
          <Row to={`${MAINVIEW_PATH}${RTC}`} target="_blank">
            <Arrow />
            <RowTitle>화면공유</RowTitle>
          </Row>
        </MainPanel>
      </Panel>
      <ButtonContainer>
        {isDebugging && <DownloadDebuggingFile />}
        {loginIdInfo === Role.SUPER_ADMIN && <SuperAdminMenu />}
        {webMenuAuthList.calendarBtn && menu.calendar && <AsonicCalendar />}
        {/* {webMenuAuthList.workingTimePlanSetting && (
          <Icon
            icon={faClipboardList}
            size="lg"
            data-tip="근무시간 계획설정"
            color={colors.lightMidNightBlue}
            onClick={() => {
              handleOpenWorkingTimeScheduleSettingDialog(true);
            }}
          />
        )} */}
        {isShowMap && webMenuAuthList.gpsBtn && (
          <MapRegister handleOpenDialog={handleOpenDialog} />
        )}
        {/* <GoMobileBtn onClick={handleMobile}>
          <i className="fas fa-mobile-alt fa-2x" />
        </GoMobileBtn> */}
        {webMenuAuthList.chatMenu && <ChatButton />}
        <LogOutBtn onClick={handleLogout}>
          <LogOutIcon icon={faSignOutAlt} size="lg" />
          {uiString.titles.logout}
        </LogOutBtn>
      </ButtonContainer>
      {isOpenDialog && (
        <AsonicDialog
          title="출퇴근 반경 정보"
          width="1100px"
          height="350px"
          minHeight="350px"
          handleClose={handleOpenDialog}
        >
          <MapRegisterDialog />
        </AsonicDialog>
      )}
      {isWorkingTimeScheduleSettingDialog && (
        <AsonicDialog
          title="근무시간 계획설정"
          width="1100px"
          height="600px"
          minHeight="600px"
          handleClose={handleOpenWorkingTimeScheduleSettingDialog}
        >
          <WorkingTimeScheduleSettingDialog />
        </AsonicDialog>
      )}
      {isDashboardSearchDialog && (
        <SearchDashBoard
          handleClose={handleOpenDashboardSearchDialog}
          initSearchDbTypeForDashBoard={SEARCH_DB_TYPE_FOR_DASH_BOARD.empName}
        />
      )}
    </TitleDiv>
  );
};

export default TitleBarPresenter;
