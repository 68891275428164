import {
  faAngleDoubleRight,
  faAngleDoubleLeft
} from "@fortawesome/free-solid-svg-icons";
import { ENTIRE_CATEGORY, APPROVED_REQUEST } from "../../constValues";
import uiString from "./string.json";
import {
  MainViewRootDiv,
  LeftNavigationBarDiv,
  RightContentsDiv,
  RightContentsChildViewDiv,
  MainContainer,
  Btn,
  List,
  Drawer,
  BtnTitle,
  BtnIcon
} from "./styled";
import HomeTitleContainer from "./HomeTitle/HomeTitleContainer";
import LeftMenuListContainer from "../../containers/main-view/LeftMenuListContainer";
import TitleBar from "./smallcomponents/TitleBar";
import { UserProfileContainer } from "../../containers";
import { useCallback, useEffect } from "react";
import moment from "moment";
import { Outlet } from "react-router-dom";

export interface IOutletContext {
  drawerOpen: boolean;
  treeData: any[];
}

function MainView({
  getCategoriesDispatch,
  getRequestDocsForCalDispatch,
  getWorkingHoursDispatch,
  signInReducer,
  treeData,
  drawerOpen,
  handleDrawerOpen
}: any) {
  const getDateFormatter = useCallback((date: Date): string => {
    const newDate = moment(date).add(1, "M").format("YYYY-MM-DD");
    return newDate;
  }, []);

  useEffect(() => {
    let mDate = new Date();
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), 1);
    let lastDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 0);
    getCategoriesDispatch();

    getWorkingHoursDispatch({
      startDate: getDateFormatter(newDate),
      endDate: getDateFormatter(lastDate),
      employeeId: signInReducer.employee_id
    });

    getRequestDocsForCalDispatch({
      startDate: getDateFormatter(newDate),
      endDate: getDateFormatter(lastDate),
      categoryId: ENTIRE_CATEGORY,
      resultValue: APPROVED_REQUEST,
      employeeId: signInReducer.employee_id
    });
  }, [
    getDateFormatter,
    getCategoriesDispatch,
    getWorkingHoursDispatch,
    getRequestDocsForCalDispatch,
    signInReducer.employee_id
  ]);

  return (
    <MainViewRootDiv>
      <MainContainer>
        <Drawer open={drawerOpen}>
          <LeftNavigationBarDiv>
            <HomeTitleContainer
              open={drawerOpen}
              loginIdInfo={signInReducer.loginIdInfo !== -1 ? true : false}
            />
            <UserProfileContainer open={drawerOpen} />
            <List>
              <LeftMenuListContainer
                open={drawerOpen}
                loginIdInfo={signInReducer.loginIdInfo !== -1 ? true : false}
                menuStatus={signInReducer.menuList[0]}
                workingAuthWeb={signInReducer.checkWebAuthFn}
              />
            </List>
            <Btn onClick={handleDrawerOpen}>
              <BtnTitle>
                <BtnIcon
                  icon={drawerOpen ? faAngleDoubleLeft : faAngleDoubleRight}
                  size="lg"
                />
                {drawerOpen && uiString.buttons.menuClose}
              </BtnTitle>
            </Btn>
          </LeftNavigationBarDiv>
        </Drawer>
        <RightContentsDiv>
          <TitleBar key={drawerOpen ? "open-drawer" : "close-drawer"} />
          <RightContentsChildViewDiv>
            <Outlet
              context={{
                drawerOpen,
                treeData
              }}
            />
          </RightContentsChildViewDiv>
        </RightContentsDiv>
      </MainContainer>
    </MainViewRootDiv>
  );
}

export default MainView;
