import React from "react";
import styled from "styled-components";
import items from "../../NavBar/items";

const HeaderContainer = styled.div`
  z-index: 9;
  top: 0;
  padding: 10px;
  text-align: center;
  position: sticky;
  background-color: ${props => props.backgroundColor};
  color: white;
  font-size: 14px;
  border-radius: 0 0 25px 25px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  margin: 0;
`;

const titleCreator = category => {
  return items[category].title;
};

const backgroundColorCreator = category => {
  return items[category].backgroundColor;
};

const HeaderTitle = props => {
  const backgroundColor = backgroundColorCreator(props.category);
  return (
    <HeaderContainer backgroundColor={backgroundColor}>
      <Title>{titleCreator(props.category)}</Title>
    </HeaderContainer>
  );
};

export default HeaderTitle;
