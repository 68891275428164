import { useCallback, useState } from "react";
import {
  MessageTypes,
  SuccessType,
  ErrorType,
  WarningType,
  InfoType
} from "../../components/toast-message/toast-message";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export interface IHandleConfirmMessage {
  title: string;
  p: string;
  messageTypes: MessageTypes;
}

export interface IConfirmType {
  title: string;
  iconColor: string;
  backgroundColor: string;
  icon: IconDefinition;
}

const useConfirmDialog = () => {
  const [confirmTitle, setConfirmTitle] = useState<string>("");
  const [confirmParagraph, setConfirmParagraph] = useState<string>("");
  const [confirmType, setConfirmType] = useState<IConfirmType>(InfoType);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  const makeTypes = useCallback(messageTypes => {
    switch (messageTypes) {
      case MessageTypes.SUCCESS:
        return SuccessType;
      case MessageTypes.ERROR:
        return ErrorType;
      case MessageTypes.WARNING:
        return WarningType;
      case MessageTypes.INFO:
        return InfoType;
      default:
        return InfoType;
    }
  }, []);

  const handleConfirmMessage = useCallback(
    ({ title, p, messageTypes }: IHandleConfirmMessage) => {
      setConfirmTitle(title);
      setConfirmParagraph(p);
      setConfirmType(makeTypes(messageTypes));
    },
    [makeTypes]
  );

  return {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  };
};

export default useConfirmDialog;
