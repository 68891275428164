import * as React from "react";

const useWeeklyWorkingHoursTab = () => {
  const [selected, setSelected] = React.useState("weekly");

  const handleSelected = React.useCallback((value: string) => {
    setSelected(value);
  }, []);

  return { selected, handleSelected };
};

export default useWeeklyWorkingHoursTab;
