import styled from "styled-components";
import colors from "../../../../style-sheet/colors";

type Props = {
  type: string;
};

export const TalbeContainer = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TableTitleBar = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.personalDashBoard.monthlyWithVacationStatusBox
    .tableTitleBackground};
`;

export const Container = styled.div`
  width: 100%;
  :first-child {
    ${TableTitleBar} {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
  :last-child {
    ${TableTitleBar} {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
`;

export const TableTitle = styled.span`
  padding: 10px 10px 10px 10px;
  font-size: 18px;
  font-weight: bold;
`;

export const TableContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const TableContentBox = styled.div<Props>`
  border-radius: 2px;
  width: max-content;
  padding: 5px 10px 5px 10px;
  background-color: ${props =>
    props.type === "총 근무일" ? "#3AAAC7" : "#6FB955"};
`;

export const TableContentInBox = styled.span`
  font-size: 18px;
  color: ${colors.personalDashBoard.Info};
`;

export const TableContent = styled.span`
  padding: 5px 10px 5px 10px;
  font-size: 16px;
`;
