import styled from "styled-components";
import FormRow from "../../../../../shared/form-row/form-row";
import StyleInput from "../../../../../inputs/style-input";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../../types/reducers";
import { useCallback, useEffect, useMemo, useState } from "react";
import basicImg from "../../../../../../images/no-image.jpg";
import useOpenToastMessage from "../../../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../../../toast-message/toast-message";
import Button from "../../../../../globalComponents/Button";
import SubTitle from "../../../../../shared/sub-title/sub-title";
import { Role } from "../../../../../../user-types";
import {
  BasicUserInfoEntity,
  useGetBasicUserInfoLazyQuery,
  useUpdateBasicUserinfoMutation
} from "../../../../../../generated/graphql";

interface IProps {
  employeeId?: string;
}

const Container = styled.form`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 14px;
  flex-direction: column;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
`;

const DivSection = styled.section`
  display: flex;
  flex-direction: column;
  min-width: max-content;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  margin: 0px 10px 0px 0px;
  gap: 10px;
`;

const Image = styled.img`
  display: flex;
  width: 120px;
`;

const InputFile = styled.input`
  display: none;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.green};
  cursor: pointer;
  height: max-content;
  padding: 8px;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
  }
`;

const SSpan = styled.span`
  display: flex;
  min-width: 163px;
  padding: 5px;
`;

const BasicInformationManagement = ({ employeeId }: IProps) => {
  const [picture, setPicture] = useState<string>(basicImg);
  const {
    signInReducer: { loginIdInfo }
  } = useSelector((state: Reducers) => state);

  const { register, handleSubmit, setValue, watch } =
    useForm<
      Omit<BasicUserInfoEntity, "departmentId" | "myPicture" | "my_picture">
    >();

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const [getBasicUserInfo] = useGetBasicUserInfoLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getBasicUserInfo.ok && data.getBasicUserInfo.user) {
        const {
          departmentName,
          empName,
          joiningDate,
          leavingDate,
          myPicture,
          nameChinese,
          nameEnglish,
          phoneNum,
          userEmail,
          userJob,
          userPosition,
          userRank
        } = data?.getBasicUserInfo?.user;
        if (myPicture) {
          const url = `data:image/png+jpg;base64,${myPicture}`;
          setPicture(url);
        }

        setValue("departmentName", departmentName);
        setValue("empName", empName);
        setValue("joiningDate", joiningDate);
        setValue("leavingDate", leavingDate);
        setValue("joiningDate", joiningDate);
        setValue("nameChinese", nameChinese);
        setValue("nameEnglish", nameEnglish);
        setValue("phoneNum", phoneNum);
        setValue("userEmail", userEmail);
        setValue("userJob", userJob);
        setValue("userPosition", userPosition);
        setValue("userRank", userRank);
      } else if (data.getBasicUserInfo.error) {
        handleToast(data?.getBasicUserInfo?.error, MessageTypes.WARNING);
      }
    }
  });

  const [updateBasicUserinfo, { client }] = useUpdateBasicUserinfoMutation({
    onError(error) {
      console.log(error);
      handleToast(
        "알수 없는 이유로 인사정보를 갱신할 수 없습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.updateBasicUserinfo.ok) {
        client.resetStore();
        handleToast(
          `성공적으로 인사 정보를 갱신했습니다.
           사용자를 다시 선택해주세요.`,
          MessageTypes.SUCCESS
        );
      }
      if (data?.updateBasicUserinfo.error) {
        handleToast(data?.updateBasicUserinfo.error, MessageTypes.ERROR);
      }
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const handleSelectedFile = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const maxSize = 1024 * 512;
      if (
        target.files &&
        target.files?.length > 0 &&
        target.files[0].size <= maxSize
      ) {
        const reader = new FileReader();
        const img = target.files[0];
        reader.onloadend = () => {
          setPicture(reader.result as string);
        };
        reader.readAsDataURL(img);
      } else {
        handleToast(
          `최대 파일 크기는 500KB를 초과할 수 없습니다.`,
          MessageTypes.ERROR
        );
      }
    },
    [handleToast]
  );

  const isAdmin = useMemo(() => loginIdInfo !== Role.USER, [loginIdInfo]);
  const {
    departmentName,
    userJob,
    userRank,
    userPosition,
    leavingDate,
    joiningDate
  } = watch();
  const handleUpdateBasicUserInfo = useCallback(
    (
      data: Omit<
        BasicUserInfoEntity,
        "departmentId" | "myPicture" | "my_picture"
      >
    ) => {
      if (picture && employeeId) {
        const payload = {
          employeeId,
          employeeName: data.empName,
          myPicture: picture,
          nameChinese: data.nameChinese,
          nameEnglish: data.nameEnglish,
          userJob: data.userJob,
          userPosition: data.userPosition,
          userRank: data.userRank,
          joiningDate: data.joiningDate,
          leavingDate: data.leavingDate,
          phoneNum: data.phoneNum || null,
          userEmail: data.userEmail || null
        };
        updateBasicUserinfo({
          variables: payload
        });
      }
    },
    [picture, employeeId]
  );
  useEffect(() => {
    if (employeeId) {
      getBasicUserInfo({
        variables: {
          getBasicUserInfoId: employeeId
        }
      });
    }
  }, [getBasicUserInfo, employeeId]);

  return (
    <Container onSubmit={handleSubmit(handleUpdateBasicUserInfo)}>
      <DivSection>
        <SubTitle title="기본 정보" />
        <ContentContainer>
          <Section>
            <ImageContainer>
              <Image src={picture} alt="user-image" />
              <Label htmlFor="imageFile">사진등록</Label>
              <InputFile
                onChange={handleSelectedFile}
                id="imageFile"
                type="file"
                accept="image/png, image/jpeg"
                name="avatar"
              />
            </ImageContainer>
          </Section>
          <Section>
            <FormRow title="이름">
              <StyleInput
                placeholder="이름"
                {...register("empName")}
                autoComplete="off"
              />
            </FormRow>
            <FormRow title="성명(한자)">
              <StyleInput
                placeholder="성명(한자)"
                {...register("nameChinese")}
              />
            </FormRow>
            <FormRow title="부서명">
              <SSpan>{departmentName}</SSpan>
            </FormRow>
            <FormRow title="직위">
              {isAdmin ? (
                <StyleInput
                  {...register("userPosition")}
                  placeholder="직위"
                  readOnly={!isAdmin}
                />
              ) : (
                <span>{userPosition}</span>
              )}
            </FormRow>
            <FormRow title="입사일자">
              {isAdmin ? (
                <StyleInput
                  type="date"
                  placeholder="입사일자"
                  {...register("joiningDate")}
                />
              ) : (
                <span>{joiningDate}</span>
              )}
            </FormRow>
            <FormRow title="전화번호">
              <StyleInput
                type="tel"
                placeholder="ex) 01012345678"
                autoComplete="off"
                {...register("phoneNum")}
              />
            </FormRow>
          </Section>
          <Section>
            <FormRow title="사번">
              <SSpan>{employeeId}</SSpan>
            </FormRow>
            <FormRow title="성명(영문)">
              <StyleInput
                placeholder="성명(영문)"
                autoComplete="off"
                {...register("nameEnglish")}
              />
            </FormRow>
            <FormRow title="직책">
              {isAdmin ? (
                <StyleInput
                  placeholder="직책"
                  {...register("userJob")}
                  readOnly={!isAdmin}
                />
              ) : (
                <span>{userJob}</span>
              )}
            </FormRow>
            <FormRow title="직급(호봉)">
              {isAdmin ? (
                <StyleInput
                  placeholder="직급(호봉)"
                  {...register("userRank")}
                  readOnly={!isAdmin}
                />
              ) : (
                <span>{userRank}</span>
              )}
            </FormRow>
            <FormRow title="퇴사일자">
              {isAdmin ? (
                <StyleInput
                  type="date"
                  placeholder="퇴사일자"
                  readOnly={!isAdmin}
                  {...register("leavingDate")}
                />
              ) : (
                <span>{leavingDate}</span>
              )}
            </FormRow>
            <FormRow title="이메일">
              <StyleInput
                type="email"
                placeholder="이메일"
                {...register("userEmail")}
              />
            </FormRow>
          </Section>
        </ContentContainer>
        <ButtonSection>
          <Button customMinWidth="50px" disabled={!employeeId}>
            저장
          </Button>
        </ButtonSection>
      </DivSection>
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
};

export default BasicInformationManagement;
