import styled from "styled-components";
import {
  Day_Type,
  OtWorkingFlexibleHoursEntity,
  Work_Hours_Type
} from "../../../../../generated/graphql";
import StyleInput from "../../../../inputs/style-input";
import useTimeList from "../../../../../hooks/use_time_list/use_time_list";
import { memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import useTimeControl from "../../../../../hooks/use_time_control/use_time_control";
import { WS_STATE } from "../../../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import moment from "moment";

interface IProps {
  workTimeSettingType: Work_Hours_Type;
  day: OtWorkingFlexibleHoursEntity;
  isDay: boolean;
  dayType:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";
  handleDayWorkHour: ({
    workHour,
    type
  }: {
    workHour: moment.Duration;
    type:
      | "monday"
      | "tuesday"
      | "wednesday"
      | "thursday"
      | "friday"
      | "saturday"
      | "sunday";
  }) => void;
  isDisableWeekST: boolean;
  stWorkHour: number;
  stStartTime: string;
  stEndTime: string;
  stStartBreakTime: string;
  stEndBreakTime: string;
}

const Section = styled.div<{ dayType: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  grid-area: ${props => props.dayType};
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InputContainer = styled(SelectContainer)``;

const DisplaySpan = styled.span<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  grid-area: workTime;
  color: ${props =>
    props.disabled ? props.theme.colors.grey : props.theme.colors.black};
  height: 25px;
`;

const TimeSelect = styled.select``;

function DayOfWeek({
  workTimeSettingType,
  day,
  isDay,
  dayType,
  handleDayWorkHour,
  isDisableWeekST,
  stWorkHour,
  stStartTime,
  stEndTime,
  stStartBreakTime,
  stEndBreakTime
}: IProps) {
  const wSState = useContext(WS_STATE);
  const [startTime, setStartTime] = useState(day.startTime);
  const [endTime, setEndTime] = useState(day.endTime);
  const [startBreakTime, setStartBreakTime] = useState(day.startBreakTime);
  const [endBreakTime, setEndBreakTime] = useState(day.endBreakTime);
  const [workHour, setWorkHour] = useState(day.workHour);

  const { timeController, handleWorkHour, addTime, makeTotalHourForWeek } =
    useTimeControl();

  const { workHourList } = useTimeList();

  useEffect(() => {
    if (isDisableWeekST) {
      setStartTime(stStartTime);
      setEndTime(stEndTime);
      setStartBreakTime(stStartBreakTime);
      setEndBreakTime(stEndBreakTime);
      setWorkHour(stWorkHour);
    } else {
      setStartTime(day.startTime);
      setEndTime(day.endTime);
      setStartBreakTime(day.startBreakTime);
      setEndBreakTime(day.endBreakTime);
      setWorkHour(day.workHour);
    }
  }, [
    isDisableWeekST,
    stStartTime,
    stEndTime,
    stStartBreakTime,
    stEndBreakTime,
    stWorkHour,
    day
  ]);

  useLayoutEffect(() => {
    if (wSState?.setOtWorkingFlexibleHours) {
      wSState?.setOtWorkingFlexibleHours(list => {
        const payload = {
          dayOfWeek: day.dayOfWeek,
          endBreakTime: endBreakTime,
          endTime: endTime,
          startBreakTime: startBreakTime,
          startTime: startTime,
          weekSequence: day.weekSequence,
          workHour,
          workingTemplateIdx: wSState?.workingTemplateIdx
        };
        const foundIndex = list.findIndex(item => {
          if (
            item.weekSequence === payload.weekSequence &&
            item.dayOfWeek === payload.dayOfWeek
          ) {
            return true;
          }
        });
        if (foundIndex > -1) {
          list.splice(foundIndex, 1, payload);
        } else {
          list.push(payload);
        }
        return list;
      });
    }
  }, [
    endBreakTime,
    endTime,
    startBreakTime,
    startTime,
    workHour,
    day.dayOfWeek,
    day.weekSequence,
    wSState?.workingTemplateIdx,
    wSState?.setOtWorkingFlexibleHours
  ]);

  useEffect(() => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");

    const startBreak = moment(startBreakTime, "HH:mm");
    const endBreak = moment(endBreakTime, "HH:mm");

    let workDuration = moment.duration(end.diff(start));

    if (end.isBefore(start)) {
      workDuration = workDuration.add(24, "hours");
    }

    let netDuration = workDuration;

    // 휴식시간이 출퇴근시간 안에 포함되는지 비교
    if (start.isBefore(startBreak) && end.isAfter(endBreak)) {
      const breakDuration = moment.duration(endBreak.diff(startBreak));
      netDuration = moment.duration(workDuration.subtract(breakDuration));
    }
    handleDayWorkHour({
      type: dayType,
      workHour: netDuration
    });
  }, [handleDayWorkHour, startTime, endTime, startBreakTime, endBreakTime]);

  return (
    <Section dayType={dayType}>
      <SelectContainer>
        {workTimeSettingType === Work_Hours_Type.EachDayTotalAndQuittingTime ? (
          <DisplaySpan>
            {makeTotalHourForWeek(
              startTime,
              endTime,
              startBreakTime,
              endBreakTime
            )}
          </DisplaySpan>
        ) : (
          <TimeSelect
            id={dayType}
            disabled={isDay}
            value={workHour}
            onChange={event => {
              const newWorkHour = parseInt(event.currentTarget.value);
              setEndTime(
                addTime({
                  startTime,
                  startBreakTime,
                  endBreakTime,
                  workHour: newWorkHour
                })
              );
              setWorkHour(newWorkHour);
            }}
          >
            {workHourList.map((item, index) => (
              <option
                value={item}
                key={`${day.weekSequence}-day.workHour-${index}`}
              >
                {item}
              </option>
            ))}
          </TimeSelect>
        )}
        {workTimeSettingType !==
          Work_Hours_Type.EachDayTotalAndQuittingTime && (
          <label htmlFor="setting">{"시간"}</label>
        )}
      </SelectContainer>
      <InputContainer>
        {workTimeSettingType === Work_Hours_Type.EachDayWorkTime ? (
          <DisplaySpan>{`${startTime} ~ ${endTime}`}</DisplaySpan>
        ) : (
          <>
            <StyleInput
              minWidth="35px"
              maxWidth="35px"
              disabled={isDay}
              value={startTime}
              maxLength={5}
              onChange={() => {
                // setStartTime(event.currentTarget.value);
              }}
              onBlur={event => {
                const time = timeController({
                  time: event.currentTarget.value
                });
                setStartTime(time);
                setWorkHour(
                  handleWorkHour(time, endTime, startBreakTime, endBreakTime)
                );
                setStartTime(time);
              }}
            />
            <DisplaySpan disabled={isDay}>{`~`}</DisplaySpan>
            <StyleInput
              minWidth="35px"
              maxWidth="35px"
              disabled={isDay}
              value={endTime}
              onChange={() => {
                // setEndTime(event.currentTarget.value);
              }}
              onBlur={event => {
                const time = timeController({
                  time: event.currentTarget.value
                });
                setEndTime(time);
                setWorkHour(
                  handleWorkHour(startTime, time, startBreakTime, endBreakTime)
                );
                setEndTime(time);
              }}
            />
          </>
        )}
      </InputContainer>
      <InputContainer>
        {workTimeSettingType === Work_Hours_Type.EachDayWorkTime ? (
          <DisplaySpan>{`${startBreakTime} ~ ${endBreakTime}`}</DisplaySpan>
        ) : (
          <>
            <StyleInput
              minWidth="35px"
              maxWidth="35px"
              disabled={isDay}
              value={startBreakTime}
              onChange={() => {
                // setStartBreakTime(event.currentTarget.value);
              }}
              onBlur={event => {
                const time = timeController({
                  time: event.currentTarget.value
                });
                setStartBreakTime(time);
                setWorkHour(
                  handleWorkHour(startTime, endTime, time, endBreakTime)
                );
                setStartBreakTime(time);
              }}
            />
            <DisplaySpan disabled={isDay}>{`~`}</DisplaySpan>
            <StyleInput
              minWidth="35px"
              maxWidth="35px"
              disabled={isDay}
              value={endBreakTime}
              onChange={() => {
                // setEndBreakTime(event.currentTarget.value);
              }}
              onBlur={event => {
                const time = timeController({
                  time: event.currentTarget.value
                });
                setEndBreakTime(time);
                setWorkHour(
                  handleWorkHour(startTime, endTime, startBreakTime, time)
                );
                setEndBreakTime(time);
              }}
            />
          </>
        )}
      </InputContainer>
    </Section>
  );
}

export default memo(DayOfWeek);
