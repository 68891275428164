import { useState, useCallback } from "react";

const useOpenDialog = () => {
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogTitle = useCallback((value: string) => {
    setDialogTitle(value);
  }, []);

  const handleOpenDialog = useCallback(
    (value: boolean) => {
      setIsOpen(value);
    },
    [setIsOpen]
  );

  return { dialogTitle, handleDialogTitle, isOpen, handleOpenDialog };
};

export default useOpenDialog;
