import React from "react";
import moment from "moment";
import {
  Container,
  TitleBar,
  Span,
  CellBody,
  MainScheduleContainer
} from "./styled";
import CellSchduleSection from "./cell-schedule-section";
import CellChartSection from "./cell-chart-section";
import AsonicExtraSchedule from "./asonic-extra-schedule";
import CalendarContext from "../asonic-calendar-context";
import useAuthenticateGoogleUser from "../../../hooks/link-google-hooks/use-authenticate-google-user";

const AsonicCalendarCellPresenter = (props: Partial<DayInfo>) => {
  const {
    titleColor,
    date,
    day,
    scheduleName,
    today,
    title,
    totalUsingTime,
    totalTime,
    isCurrentMonth,
    extraData,
    fullDate
  } = props;
  const { handleDialogTitle, handleOpenDialog, handleSelectedEvent } =
    React.useContext(CalendarContext);
  const { isLogin } = useAuthenticateGoogleUser();
  return (
    <Container
      today={today}
      isCurrentMonth={isCurrentMonth}
      onClick={() => {
        if (isLogin) {
          handleOpenDialog(true);
        }
        if (handleDialogTitle && isLogin) {
          handleDialogTitle(moment(fullDate).format("YYYY년 MMMM D일 dddd"));
          handleSelectedEvent();
        }
      }}
    >
      <TitleBar title={title}>
        <Span color={titleColor}>{date || title}</Span>
        <Span color={titleColor}>{day}</Span>
      </TitleBar>
      <CellBody>
        {scheduleName && (
          <MainScheduleContainer>
            <CellSchduleSection
              scheduleName={scheduleName}
              totalTime={totalTime}
            />
          </MainScheduleContainer>
        )}
        {totalUsingTime !== undefined &&
          totalTime !== undefined &&
          typeof totalTime === "number" && (
            <CellChartSection
              totalUsingTime={totalUsingTime}
              totalTime={totalTime}
            />
          )}
        {extraData && (
          <AsonicExtraSchedule fullDate={fullDate} cellData={props} />
        )}
      </CellBody>
    </Container>
  );
};

export default AsonicCalendarCellPresenter;
