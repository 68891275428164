export const BUTTON_COLOR = {
  ADD: "#007bff",
  EDIT: "#007bff",
  SAVE: "#007bff",
  CANCEL: "#E7473C",
  CANCEL_HOVER: "#ee574c",
  DELETE: "#E7473C",
  DELETE_HOVER: "#ee574c"
};

export const ITEMS_PER_PAGE = [50, 100, 150];
