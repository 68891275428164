import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { getCalendar } from "../../../Utils/calendar-axios-call";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";
import { CalendarDirection } from "../../../components/asonic-calendar/asonic-calendar-month/asonic-calendar-month-presenter";

enum DataType {
  mainEvent = 1,
  subEvent
}

enum Week {
  saturday = "토",
  sunday = "일"
}
interface CalendarData {
  TodayWorkingTime: string;
  astottime: string;
  currentdate: string;
  datatype: DataType;
  employee_id: string;
  weekdaytext: string;
}

interface MakeCellArgs {
  selectedMoment: moment.Moment;
  currentMonth: number;
  day: string;
  today: boolean;
  data?: CalendarData[];
}

const useCalendar = () => {
  const { signInReducer } = useSelector((state: Reducers) => state);
  const [data, setData] = useState<CalendarData[]>([]);
  const [weeks, setWeeks] = useState<DayInfo[][]>([]);
  const [currentMoment, setCurrentMoment] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const selectedMoment = useMemo<moment.Moment>(
    () => moment(currentMoment, "YYYY-MM").date(1),
    [currentMoment]
  );
  const aWeekDays = useMemo<number>(() => 7, []);
  const year = useMemo<number>(() => selectedMoment.year(), [selectedMoment]);
  const month = useMemo<number>(
    () => selectedMoment.month() + 1,
    [selectedMoment]
  );

  const dateNow = useMemo<moment.Moment>(() => moment(), []);

  const firstDayInMonth = useMemo<number>(
    () => moment(`${year}-${month}-1`, "YYYY-MM-DD").day(),
    [year, month]
  );
  const lastDay = useMemo<number>(
    () => selectedMoment.daysInMonth(),
    [selectedMoment]
  );
  const totalWeeks = useMemo(
    () => Math.ceil((lastDay + firstDayInMonth - 1) / aWeekDays),
    [lastDay, firstDayInMonth, aWeekDays]
  );
  const totalDays = useMemo<number>(() => {
    const newTotalDays = aWeekDays * totalWeeks;
    if (firstDayInMonth === 0) {
      return newTotalDays + 7;
    }
    return newTotalDays;
  }, [firstDayInMonth, aWeekDays, totalWeeks]);

  const handleCurrentMoment = useCallback((date: Date) => {
    setCurrentMoment(moment(date).format("YYYY-MM-DD"));
  }, []);

  const handleMoveMonth = useCallback(
    (direction: CalendarDirection) => () => {
      const year = moment(currentMoment, "YYYY-MM-DD").year();
      const month = moment(currentMoment, "YYYY-MM-DD").month();
      if (direction === CalendarDirection.PRE) {
        const preMonth = month - 1;
        const newPrevMoment = moment().year(year).month(preMonth);
        setCurrentMoment(newPrevMoment.format("YYYY-MM-DD"));
      } else if (direction === CalendarDirection.NEXT) {
        const newNextMoment = moment()
          .year(year)
          .month(month + 1);
        setCurrentMoment(newNextMoment.format("YYYY-MM-DD"));
      }
    },
    [currentMoment]
  );

  const makeDay = useCallback((year: number, month: number, day: number) => {
    return moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format("ddd");
  }, []);

  const makeCell = useCallback(
    ({ selectedMoment, currentMonth, day, today, data }: MakeCellArgs) => {
      const selectedDate = selectedMoment.format("YYYY-MM-DD");
      const selectedMonth = parseInt(selectedMoment.format("MM"));
      let isCurrentMonth = currentMonth === selectedMonth;
      let titleColor = "";
      if (day === Week.saturday || day === Week.sunday) {
        titleColor = "#eb4d4b";
      } else {
        titleColor = "";
      }
      let cellData: Partial<DayInfo> = {
        day,
        today,
        scheduleName: "",
        date: selectedMoment.format("DD"),
        fullDate: selectedMoment.format("YYYY-MM-DD"),
        isCurrentMonth,
        totalTime: "",
        extraData: [],
        titleColor
      };

      if (data && data.length) {
        data.forEach(item => {
          const currentMomentInData = moment(item.currentdate, "YYYY-MM-DD");
          const day = currentMomentInData.format("ddd");
          // currentMonth index가 0 ~ 11로 month 표시
          const isCurrentMonth =
            currentMonth - 1 === currentMomentInData.month();
          if (item.currentdate === selectedDate) {
            if (item.datatype === DataType.mainEvent) {
              cellData = {
                day,
                today,
                scheduleName: item.astottime,
                date: currentMomentInData.format("DD"),
                fullDate: currentMomentInData.format("YYYY-MM-DD"),
                totalTime: item.TodayWorkingTime,
                isCurrentMonth,
                extraData: []
              };
            } else if (item.datatype === DataType.subEvent) {
              cellData = {
                ...cellData,
                extraData: [
                  {
                    day,
                    today,
                    scheduleName: item.astottime,
                    date: currentMomentInData.format("DD"),
                    fullDate: currentMomentInData.format("YYYY-MM-DD"),
                    totalTime: item.TodayWorkingTime,
                    backgroundColor: "#F7E4B5",
                    isCurrentMonth
                  }
                ]
              };
            }
          }
        });
      }
      return cellData;
    },
    []
  );

  const makeWeeks = useCallback(
    (calendar: DayInfo[]): DayInfo[][] => {
      let weeks = Array(totalWeeks);
      let mutableWeek = 0;
      calendar.forEach((item, index) => {
        const remainder = (index + 1) % 7;
        let newWeek = [];
        if (remainder === 0) {
          newWeek = [...weeks[mutableWeek], item];
          weeks[mutableWeek] = newWeek;
          mutableWeek++;
        } else {
          if (
            Array.isArray(weeks[mutableWeek]) &&
            weeks[mutableWeek].length > 0
          ) {
            newWeek = [...weeks[mutableWeek], item];
            weeks[mutableWeek] = newWeek;
          } else {
            weeks[mutableWeek] = [item];
          }
        }
      });
      return weeks;
    },
    [totalWeeks]
  );

  const makeCalendar = useCallback(() => {
    let mutableDay = 1;
    let nextMonthDay = 1;
    // 이전달
    const preMonth = month - 1;
    const dayOfFirstWeek = 1;
    let calendar = Array(totalDays);

    for (let i = dayOfFirstWeek; i < calendar.length + 1; i++) {
      if (firstDayInMonth === 0 || firstDayInMonth > 1) {
        let dayIndex = i;
        if (firstDayInMonth === 0) {
          dayIndex = i - 7;
        }
        const newPreMoment = moment()
          .year(year)
          .month(preMonth)
          .date(1)
          .day(dayIndex);
        const today =
          dateNow.format("YYYY-MM-DD") === newPreMoment.format("YYYY-MM-DD");
        const day = makeDay(
          parseInt(newPreMoment.format("YYYY")),
          parseInt(newPreMoment.format("MM")),
          parseInt(newPreMoment.format("DD"))
        );

        const thisDay = makeCell({
          selectedMoment: newPreMoment,
          currentMonth: month,
          day,
          today,
          data
        });
        calendar[i - 1] = thisDay;
      } else if (mutableDay > lastDay && firstDayInMonth < i) {
        const nextMonth = month;
        const newNextMoment = moment()
          .year(year)
          .month(nextMonth)
          .date(nextMonthDay);
        const today =
          dateNow.format("YYYY-MM-DD") === newNextMoment.format("YYYY-MM-DD");
        const day = makeDay(
          parseInt(newNextMoment.format("YYYY")),
          parseInt(newNextMoment.format("MM")),
          parseInt(newNextMoment.format("DD"))
        );
        const thisDay = makeCell({
          selectedMoment: newNextMoment,
          currentMonth: month,
          day,
          today,
          data
        });
        calendar[i - 1] = thisDay;
        nextMonthDay++;
      } else if (mutableDay <= lastDay && i >= firstDayInMonth) {
        const newDay = moment(`${year}-${month}-${mutableDay}`, "YYYY-MM-DD");
        const day = makeDay(year, month, mutableDay);
        const today =
          dateNow.format("YYYY-MM-DD") === newDay.format("YYYY-MM-DD");
        const thisDay = makeCell({
          selectedMoment: newDay,
          currentMonth: month,
          day,
          today,
          data
        });
        calendar[i - 1] = thisDay;
        mutableDay++;
      }
    }
    const newWeeks = makeWeeks(calendar);
    setWeeks(newWeeks);
  }, [
    data,
    year,
    month,
    totalDays,
    lastDay,
    makeDay,
    dateNow,
    firstDayInMonth,
    makeCell,
    makeWeeks
  ]);

  const getCalendarInfo = useCallback(() => {
    // getCalendar
    const startDate = moment(`${year}-${month}`, "YYYY-MM")
      .startOf("month")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");

    const endDate = moment(`${year}-${month}`, "YYYY-MM")
      .endOf("month")
      .endOf("isoWeek")
      .format("YYYY-MM-DD");

    const params = {
      employeeId: signInReducer.employee_id,
      startDate,
      endDate
    };
    getCalendar(params)
      .then(({ data }) => {
        setData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [year, month, signInReducer]);

  useEffect(() => {
    makeCalendar();
  }, [makeCalendar]);

  useEffect(() => {
    getCalendarInfo();
  }, [getCalendarInfo]);

  return { currentMoment, handleCurrentMoment, handleMoveMonth, weeks };
};

export default useCalendar;
