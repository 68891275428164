import styled from "styled-components";

interface IProps {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  customMinWidth?: string;
  customMinHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
}

export default styled.button<IProps>`
  border-radius: 4px;
  min-height: ${props =>
    props.customMinHeight ? props.customMinHeight : "30px"};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  background-color: ${props =>
    props.backgroundColor ?? props.theme.colors.green};
  color: white;
  border: none;
  outline: none;
  padding: 6px;
  min-width: ${props => {
    return props.customMinWidth ?? "100px";
  }};
  max-width: ${props => props.maxWidth ?? ""};
  :hover {
    background-color: ${props =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.98);
  }
  opacity: ${props => (props.disabled ? "0.2" : "1")};
`;
