import styled from "styled-components";
import {
  ApprovalDetailInformationEntity,
  ApprovalHistoryEntity,
  ApprovalStatus,
  useGetListOfApprovalHistoryLazyQuery,
  useRejectApprovalMutation,
  useSignApprovalMutation
} from "../../../generated/graphql";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import SubTitle from "../../shared/sub-title/sub-title";
import { AutoSizer } from "react-virtualized";
import { VariableSizeList as List } from "react-window";
import { useCallback, useEffect, useMemo } from "react";
import Button from "../../globalComponents/Button";
import RenderApprovalHistory from "../select-approval-form-popup/render-approval-history";
import StyleTextarea from "../../inputs/style-textarea";
import { useForm } from "react-hook-form";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";

export interface IApprovalHistory {
  title: string;
  status: ApprovalStatus;
  description?: string;
}

interface IApprovalDescriptionValue {
  description: string;
}
interface IProps {
  handleClose: (value: boolean) => void;
  approvalDetailInformation?: ApprovalDetailInformationEntity;
  isSignOrReject: boolean;
  approvalRequestIdx: number;
  handleShowApprovalPopup?: (value: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 5;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const StatusDescription = styled.span`
  color: ${props => props.theme.colors.green};
  white-space: pre;
  font-weight: bold;
`;

function ApprovalSignAndRejectDialog({
  handleClose,
  approvalDetailInformation,
  isSignOrReject,
  approvalRequestIdx,
  handleShowApprovalPopup
}: IProps) {
  const { register, handleSubmit } = useForm<IApprovalDescriptionValue>();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [signApproval, { client }] = useSignApprovalMutation({
    onError(error) {
      console.log(error);
      handleToast("결재 승인이 실패했습니다.", MessageTypes.ERROR);
    },
    update(_, { data }) {
      if (data?.signApproval.ok) {
        client.resetStore();
        if (handleShowApprovalPopup) {
          handleShowApprovalPopup(false);
        }
        handleClose(false);
      }
      if (data?.signApproval.error) {
        handleToast(data?.signApproval.error, MessageTypes.ERROR);
      }
    }
  });

  const [rejectApproval] = useRejectApprovalMutation({
    onError(error) {
      console.log(error);
      handleToast("결재 반려가 실패했습니다.", MessageTypes.ERROR);
    },
    update(_, { data }) {
      if (data?.rejectApproval.ok) {
        client.resetStore();
        if (handleShowApprovalPopup) {
          handleShowApprovalPopup(false);
        }
        handleClose(false);
      }
      if (data?.rejectApproval.error) {
        handleToast(data?.rejectApproval.error, MessageTypes.ERROR);
      }
    }
  });

  const [getListOfApprovalHistory, { data }] =
    useGetListOfApprovalHistoryLazyQuery();

  const handleSignApproval = useCallback(
    ({ description }: IApprovalDescriptionValue) => {
      signApproval({
        variables: {
          approvalRequestIdx,
          description
        }
      });
    },
    [signApproval, approvalRequestIdx]
  );

  const handleRejectApproval = useCallback(
    ({ description }: IApprovalDescriptionValue) => {
      rejectApproval({
        variables: {
          approvalRequestIdx,
          description
        }
      });
    },
    [rejectApproval, approvalRequestIdx]
  );

  const list = useMemo(() => {
    let listOfApprovalHistory: ApprovalHistoryEntity[] =
      data?.getListOfApprovalHistory.list ?? [];
    listOfApprovalHistory = [...listOfApprovalHistory].slice(1);
    return listOfApprovalHistory;
  }, [data]);

  const getItemSize = useCallback(
    (index: number): number => {
      const item = list[index];
      if (item?.descriptionComment) {
        return 50;
      }
      return 30;
    },
    [list]
  );

  useEffect(() => {
    if (approvalDetailInformation?.approvalRequestIdx) {
      getListOfApprovalHistory({
        variables: {
          approvalRequestIdx: approvalDetailInformation?.approvalRequestIdx
        }
      });
    }
  }, [approvalDetailInformation?.approvalRequestIdx]);
  return (
    <AsonicDialog
      title={isSignOrReject ? "결재 승인" : "결재 반려"}
      handleClose={handleClose}
      width="420px"
      minWidth="420px"
      height="400px"
      minHeight="400px"
    >
      <Container>
        <TitleContainer>
          <SubTitle title="결재선 의견" />
          <StatusDescription>{`R: read
W: write`}</StatusDescription>
        </TitleContainer>
        <Content>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  height={height}
                  itemCount={list.length}
                  itemData={list}
                  width={width}
                  itemSize={getItemSize}
                >
                  {RenderApprovalHistory}
                </List>
              );
            }}
          </AutoSizer>
        </Content>
        <DescriptionContainer>
          <SubTitle title={isSignOrReject ? "의견" : "반려 사유"} />
          <StyleTextarea {...register("description")} />
        </DescriptionContainer>
        <ButtonContainer>
          {isSignOrReject ? (
            <Button
              customMinWidth="20px"
              onClick={handleSubmit(handleSignApproval)}
            >
              결재
            </Button>
          ) : (
            <Button
              customMinWidth="20px"
              onClick={handleSubmit(handleRejectApproval)}
            >
              반려
            </Button>
          )}
          <Button
            customMinWidth="20px"
            onClick={() => {
              handleClose(false);
            }}
          >
            취소
          </Button>
        </ButtonContainer>
        <ToastMessage
          message={message}
          isOpen={isToastMessageOpen}
          handleIsOpen={handleIsToastMessageOpen}
          messageTypes={toastMessageType}
        />
      </Container>
    </AsonicDialog>
  );
}

export default ApprovalSignAndRejectDialog;
