import styled from "styled-components";
import mColors from "../../../style-sheet/m_colors";
import { UserEntity } from "../../../generated/graphql";
import basicImg from "../../../images/basicimage.jpg";
import { Icon } from "@iconify/react";
import { useMemo } from "react";
import AlarmIcon from "@iconify-icons/ph/alarm-bold";
import ClipBoardCheckIcon from "@iconify/icons-mdi/clipboard-check";
import mRAStore from "./m_request_approval/m_r_a_store";
import { useRecoilValue } from "recoil";

export const MApprovalTabs = {
  LIST_WAITING: "대기목록",
  LIST_COMPLETED: "진행/완료",
  LIST_RECEIVE: "수신",
  LIST_REFERENCE: "참조",
  LIST_OF_MY_APPROVAL: "나의결재"
} as const;

export type MApprovalTabs = (typeof MApprovalTabs)[keyof typeof MApprovalTabs];

interface PhotoProps {
  photo?: string | null;
}

interface IProps {
  tab: MApprovalTabs;
  setTab: React.Dispatch<React.SetStateAction<MApprovalTabs>>;
  waitingCount?: number | null | undefined;
  completedCount?: number | null | undefined;
  receiveCount?: number | null | undefined;
  referenceCount?: number | null | undefined;
  myCount?: number | null | undefined;
}

const ProfilePicture = styled.div<PhotoProps>`
  width: 50px;
  max-width: 50px;
  height: 50px;
  max-height: 50px;
  border-radius: 6px;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url(data:image/png+jpg;base64,${props.photo})`
      : `url(${basicImg})`};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${mColors.lightGreen};
  border-radius: 6px;
  -webkit-box-shadow: 4px 6px 10px 0px rgba(156, 156, 156, 1);
  -moz-box-shadow: 4px 6px 10px 0px rgba(156, 156, 156, 1);
  box-shadow: 4px 6px 10px 0px rgba(156, 156, 156, 1);
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

const ProfileContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ProfileInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const DepartmentName = styled.span``;

const ScrollContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ListButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  overflow-x: scroll;
  width: 0px;
`;

const ListButton = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.$isSelected ? mColors.yellow : props.theme.colors.green};
  color: ${props =>
    props.$isSelected ? props.theme.colors.black : props.theme.colors.white};
  gap: 2px;
  font-weight: bold;
  padding: 8px;
  border-radius: 6px;
  min-width: 70px;
  will-change: scroll-position;
  cursor: pointer;
  :hover {
    background-color: ${props =>
      props.$isSelected ? mColors.lightYellow : props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.95);
  }
  max-height: 58px;
`;

const IconContainer = styled.div`
  svg {
    font-size: 30px;
  }
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  white-space: pre-line;
`;

function MApprovalUserInfo({
  tab,
  setTab,
  waitingCount,
  completedCount,
  receiveCount,
  referenceCount,
  myCount
}: IProps) {
  const { user } = useRecoilValue(mRAStore);
  const photo: string = useMemo(() => {
    return user?.myPicture ?? "";
  }, [user?.myPicture]);

  return (
    <Container>
      <ProfileContainer>
        <ProfilePicture photo={photo} />
        <ProfileInfoContainer>
          <UserName>{user?.name}</UserName>
          <DepartmentName>{user?.fullDepartmentName}</DepartmentName>
        </ProfileInfoContainer>
      </ProfileContainer>
      <ScrollContainer>
        <ListButtonContainer>
          <ListButton
            $isSelected={tab === MApprovalTabs.LIST_WAITING}
            onClick={() => {
              setTab(MApprovalTabs.LIST_WAITING);
            }}
          >
            <IconContainer>
              <Icon icon={AlarmIcon} />
            </IconContainer>
            <Description>{`대기목록`}</Description>
            <Description>
              {`${waitingCount ? `(${waitingCount})` : ""}`}
            </Description>
          </ListButton>
          <ListButton
            $isSelected={tab === MApprovalTabs.LIST_COMPLETED}
            onClick={() => {
              setTab(MApprovalTabs.LIST_COMPLETED);
            }}
          >
            <IconContainer>
              <Icon icon={ClipBoardCheckIcon} />
            </IconContainer>
            <Description>{`진행/완료`}</Description>
            <Description>
              {`${completedCount ? `(${completedCount})` : ""}`}
            </Description>
          </ListButton>
          <ListButton
            $isSelected={tab === MApprovalTabs.LIST_RECEIVE}
            onClick={() => {
              setTab(MApprovalTabs.LIST_RECEIVE);
            }}
          >
            <IconContainer>
              <Icon icon="lets-icons:load-circle" />
            </IconContainer>
            <Description>{`수신`}</Description>
            <Description>
              {`${receiveCount ? `(${receiveCount})` : ""}`}
            </Description>
          </ListButton>
          <ListButton
            $isSelected={tab === MApprovalTabs.LIST_REFERENCE}
            onClick={() => {
              setTab(MApprovalTabs.LIST_REFERENCE);
            }}
          >
            <IconContainer>
              <Icon icon="fa6-solid:clipboard-user" />
            </IconContainer>
            <Description>{`참조`}</Description>
            <Description>
              {`${referenceCount ? `(${referenceCount})` : ""}`}
            </Description>
          </ListButton>
          <ListButton
            $isSelected={tab === MApprovalTabs.LIST_OF_MY_APPROVAL}
            onClick={() => {
              setTab(MApprovalTabs.LIST_OF_MY_APPROVAL);
            }}
          >
            <IconContainer>
              <Icon icon="mdi:list-status" />
            </IconContainer>
            <Description>{`나의결재`}</Description>
            <Description>{`${myCount ? `(${myCount})` : ""}`}</Description>
          </ListButton>
        </ListButtonContainer>
      </ScrollContainer>
    </Container>
  );
}

export default MApprovalUserInfo;
