import moment from "moment";

export interface IState {
  title?: string;
  description?: string;
  base_query?: string;
  employees?: string[];
  departments?: string[];
  approval_result?: number;
  publish?: number;
  start_date?: string | moment.Moment;
  end_date?: string | moment.Moment;
}

export interface IAction extends IState {
  type: string;
}

export const queryContentReducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.title
      };
    case "description":
      return {
        ...state,
        description: action.description
      };
    case "base_query":
      return {
        ...state,
        base_query: action.base_query
      };
    case "employees":
      return {
        ...state,
        employees: action.employees
      };
    case "departments":
      return {
        ...state,
        departments: action.departments
      };
    case "approval_result":
      return {
        ...state,
        approval_result: action.approval_result
      };
    case "start_date":
      return {
        ...state,
        start_date: action.start_date
      };
    case "end_date":
      return {
        ...state,
        end_date: action.end_date
      };
    case "publish":
      return {
        ...state,
        publish: action.publish
      };
    default:
      throw new Error("Unhandled action");
  }
};
