import * as React from "react";
import { Container, Icon } from "./styled";
import { faBug } from "@fortawesome/free-solid-svg-icons";
import useSaveDebuggingFile from "../../../../../hooks/use-save-debugging-file";
import ReactTooltip from "react-tooltip";

type Props = {};

const DownloadDebuggingFile = <P extends Props>(props: P) => {
  const { saveJsonFile } = useSaveDebuggingFile();
  return (
    <Container>
      <ReactTooltip type="success" />
      <Icon
        onClick={saveJsonFile}
        icon={faBug}
        size="lg"
        data-tip="디버깅 다운로드"
      />
    </Container>
  );
};

export default DownloadDebuggingFile;
