import HelpContainer from "../../../../../../components/shared/help_container/help_container";
import InfoIcon from "../../../../../../components/shared/info-icon/info-icon";

function WorkingSystemHelp() {
  return (
    <InfoIcon
      minHeight="720px"
      height="720px"
      minWidth="400px"
      title="근무제 설정 - 기본정보"
    >
      <HelpContainer>
        <span>{`근무제 설정 기본 정보`}</span>
        <ul>
          <li>{`관리이름`}</li>
          <span>{`생성하는 근무제의 관리 하기 위한 이름을 설정합니다.`}</span>
          <li>{`출/퇴근 기록방식`}</li>
          <span>{`출근 및 퇴근을 기록하는 방식을 선택합니다.`}</span>
          <pre>{`출/퇴근 기록 방식을 A-Sonic 관리자 콘솔에서 생성 가능합니다.`}</pre>
          <li>{`근무 유형`}</li>
          <ul>
            <li>{`시차 출퇴근제`}</li>
            <pre>{`시차 출퇴근제는 1주 40시간, 1일 8시간 근무를 하며 출/퇴근 시간
을 조정하는 제도 입니다. 이를 초과하는 경우 연장근로(연장근무)로
규정합니다.`}</pre>
            <li>{`탄력 근무시간제`}</li>
            <pre>{`일이 많은 주의 근무시간을 늘리는 대신, 일이 적은 주의 근무시간을
줄여 평균 주 40시간의 근무시간을 맞추는 제도 입니다.`}</pre>
            <li>{`선택 근무시간제`}</li>
            <pre>{`1주 40시 범위내에서 1일의 근로 시간을 자율적으로 조정하여 근무
할수 있는 제도이며, 선택 근무시간 구간과 반드시 근무해야 하는 의
무 근로시간 구간이 존재합니다.`}</pre>
            <li>{`자율 출퇴근제`}</li>
            <pre>{`주 5일, 하루8시간, 주40시간 근무를 지키며, 원하는 시간에 출근하
는 제도 입니다. 퇴근 시간에 출근 시간에 따라 자동으로 변경 지정됩
니다. 사업주의 방침에 따라 출근 가능 시간의 범위를 지정할수 있습
니다.`}</pre>
          </ul>
          <li>{`근무 시간 유형`}</li>
          <pre>{`일 8시간, 주 40시간 근무는 동일하며, 주 52시간 근무제는 주 최대 연
장 근로시간이 12시간입니다. 69시간 근무제는 주 최대 연장 근로시간
이 29시간이고, 일 연속휴게시간(11시간)이 주어집니다.`}</pre>
          <li>{`기본근무제`}</li>
          <pre>{`근무제 적용 기간이 설정되는 경우 적용 기간 전/후의 미적용 기간 동안 
적용되는 기본 근무제를 설정합니다. (근무제 적용 기간이 상시 인 경우
에는 사용되지 않습니다.)`}</pre>
          <ul>
            <li>
              <pre>{`미사용 : 적용 근무제가 없는 상태로 적용됩니다.`}</pre>
            </li>
            <li>
              <pre>{`근무제선택 : 선택된 근무제로 변경됩니다.`}</pre>
            </li>
            <pre>{`선택 할수 있는 근무제 유형은 “표준/시차출퇴근제”와 
“자율 출근제”중에서만 가능합니다.`}</pre>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default WorkingSystemHelp;
