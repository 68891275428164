import * as React from "react";
import moment from "moment";
import useCheckIsDebugging from "../use-check-is-debugging";

export type Debugging = <T>({
  data,
  message
}: {
  data: T;
  message: string;
}) => void;

type DebuggingPayload = <T>({
  message,
  data,
  time
}: {
  message: string;
  data: T;
  time: string;
}) => void;

const useDebugging = () => {
  const { isDebugging } = useCheckIsDebugging({});
  const isMounted = React.useRef<boolean>(false);

  const handleNowTime = React.useCallback(() => {
    const nowTime = moment().format("YYYY/MM/DD HH:mm:SSS");
    return nowTime;
  }, []);

  const printDebuggingMessage = React.useCallback<DebuggingPayload>(
    ({ message, data, time }) => {
      console.log(`Message: ${message}`);
      console.log(`Debugging time:${time}`);
      console.log(data);
      console.log("----------------------");
    },
    []
  );

  const storeDebuggingInSessionStorage = React.useCallback<DebuggingPayload>(
    ({ message, data, time }) => {
      const key = time;
      const payload = {
        message,
        data
      };
      window.sessionStorage.setItem(key, JSON.stringify(payload));
    },
    []
  );

  const handleDebugging = React.useCallback<Debugging>(
    ({ data, message }) => {
      if (isDebugging) {
        const nowTime = handleNowTime();
        const payload = { message, data, time: nowTime };
        printDebuggingMessage(payload);
        storeDebuggingInSessionStorage(payload);
      }
    },
    [
      printDebuggingMessage,
      handleNowTime,
      isDebugging,
      storeDebuggingInSessionStorage
    ]
  );

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      window.sessionStorage.clear();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  return { handleDebugging };
};

export default useDebugging;
