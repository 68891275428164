import * as React from "react";
import { useNavigate } from "react-router-dom";
import HomeTitle from "../Hometitle";

interface IProps {
  open?: boolean;
  loginIdInfo: boolean;
}

const HomeTitleContainer = <P extends IProps>(props: P) => {
  const { loginIdInfo } = props;
  const navigate = useNavigate();
  const handleGoHome = React.useCallback((): void => {
    if (loginIdInfo) {
      navigate("/");
    } else {
      navigate("/mainview/personal");
    }
  }, [navigate, loginIdInfo]);
  return <HomeTitle {...props} handleGoHome={handleGoHome} />;
};

export default HomeTitleContainer;
