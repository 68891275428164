import styled from "styled-components";

type Props = {
  isSelected: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: span 3;
`;

export const ButtonsContainer = styled.div`
  padding-left: 2%;
`;

export const Button = styled.button<Props>`
  font-size: 13px;
  border: none;
  outline: none;
  background-color: ${props => (props.isSelected ? "#1A9DCE" : "#a6c3cd")};
  border-radius: 2px;
  padding: 2px 15px 2px 15px;
  color: white;
  :last-child {
    margin-left: 3px;
  }
  :hover {
    cursor: pointer;
    background-color: ${props => (props.isSelected ? "#46c1f0" : "#bcdde8")};
  }
  :active {
    transform: scale(0.98);
  }
`;

export const ContentContainer = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
