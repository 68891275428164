import drawTree from "./drawTree";
import search from "./search-state-manager";
import selectedItem from "./selectedItem";
import clickRightMenu from "../WorkManagementInfoContainer/states/clickRightMenu";
const stateManager = () => {
  const rightMenu = clickRightMenu();
  const drawTreeFromData = drawTree(rightMenu.handleContextMenu);
  const searchStateManger = search();
  const treeSelectedItem = selectedItem();
  return {
    ...drawTreeFromData,
    ...searchStateManger,
    ...treeSelectedItem,
    ...rightMenu
  };
};

export default stateManager;
