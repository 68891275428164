import styled from "styled-components";
import InfoIcon from "../../../../../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    margin: 0px;
    padding: 0px;
  }
`;

const Emphasis = styled.span`
  font-weight: bold;
`;

function WorkInformationManagementHelp() {
  return (
    <InfoIcon
      minHeight="760px"
      height="760px"
      minWidth="400px"
      title="사용자 연차 정보"
    >
      <HelpContainer>
        <span>{`귀속 년도의 개인 연차 정보를 확인합니다.`}</span>
        <ul>
          <li>{`귀속년도`}</li>
          <span>{`현 연차가 적용되고 있는 년도를 표기 합니다.`}</span>
          <li>
            {`상태`}
            <ul>
              <li>{`확정(자동생성)`}</li>
              <span>{`시스템에서 자동으로 생성된 연차 확정`}</span>
              <li>{`확정(관리자)`}</li>
              <span>{`관리자에 의해 생성/수정된 연차 확정`}</span>
              <li>{`미생성`}</li>
              <span>{`연차 생성 전으로 관리자의 승인이 필요한 상태`}</span>
            </ul>
          </li>
          <li>{`연차 시작일`}</li>
          <span>{`연차 적용 시작일`}</span>
          <li>{`연차 종료일`}</li>
          <span>{`연차 적용 적용일`}</span>
          <li>{`기본 일수`}</li>
          <pre>{`입사 관리 체계에 따라 기본적으로 부여되는 연차 일수 입니다.
입사 기간에 따른 회사 정책에 의해 부여 됩니다.`}</pre>
          <li>{`기본 조정`}</li>
          <pre>{`관리자에 의해 기본 일수의 추가 부여 또는 감소 되는 일수 입니다.
해당 설정은 인사 관리자가 설정합니다.`}</pre>
          <li>{`가산일수`}</li>
          <span>{`근속 년도에 따라 기본 연차 일수에 추가 부여되는 연차 일수 입니다.`}</span>
          <li>{`이월연차`}</li>
          <pre>{`전년도의 마이너스 연차 또는 미사용 연차 등의 일수로 연차 관리 설정에
의해 연차 이월이 허용 된 경우 적용됩니다.`}</pre>
          <li>{`사용일수`}</li>
          <span>{`사용한 연차 일수 입니다.`}</span>
          <li>{`사용 조정`}</li>
          <pre>{`불가피하게 연차 사용일수를 조정해야 하는 경우 관리자에 의해 조정되
는 일수 입니다. (증/감 설정)`}</pre>
          <li>{`잔여일 계산`}</li>
          <pre>{`부여일 = 기본일수 ± 기본 조정일수(관리자 설정)
+ 가산일수 ± 이월연차
사용일 = 사용일수 ± 사용 조정일수(관리자설정)`}</pre>
          <Emphasis>{`잔여일 = 부여일 - 사용일`}</Emphasis>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default WorkInformationManagementHelp;
