import * as React from "react";
import {
  ApprovalSearchResult,
  CategorySelectionReducer
} from "../../../../types/reducers";
import { _findNameOfCategoryId } from "../../../moComponents/main-view/smallComponents/MobileTableContents/utils";
import { getApprovalSearchDocs } from "../../../redux/sagas/restapi-calls/approvalCalls";
import { getRequestSearchDocs } from "../../../redux/sagas/restapi-calls/requestCalls";
interface IProps {
  type: typeof APPROVAL_PAGE | typeof REQUEST_PAGE;
  categorySelectionReducer: CategorySelectionReducer;
  selectDoc: any;
  startDate: Date;
  endDate: Date;
  categoryId: string;
  searchWord?: string;
  searchType?: number;
  departmentId?: string;
  resultValue: string;
  employeeId: string;
  handleIsLoading: (value: boolean) => void;
}

const APPROVAL_PAGE = "Approval";
const REQUEST_PAGE = "Request";

const useHandleDataInApprovalWithRequest = <P extends IProps>({
  type,
  categorySelectionReducer,
  selectDoc,
  startDate,
  endDate,
  searchWord,
  searchType,
  departmentId,
  categoryId,
  resultValue,
  employeeId,
  handleIsLoading
}: P) => {
  const isNoData = React.useRef<boolean>(false);
  const [data, setData] = React.useState<ApprovalSearchResult[]>([]);
  const searchData = React.useCallback(
    async (offset: number = 0) => {
      if (employeeId !== "0") {
        //   setRequestSearchArgs();
        if (offset === 0 && isNoData.current) {
          isNoData.current = false;
          setData([]);
        }
        if (type === APPROVAL_PAGE && !isNoData.current) {
          handleIsLoading(true);
          try {
            const dataFromSever = await getApprovalSearchDocs({
              startDate,
              endDate,
              categoryId,
              searchType,
              searchWord,
              resultValue,
              departmentId,
              employeeId,
              offset
            });
            if (dataFromSever.length > 0) {
              const newData = dataFromSever.map((value: any) =>
                _findNameOfCategoryId(value, categorySelectionReducer)
              );
              if (offset === 0) {
                setData(newData);
              } else {
                setData([...data, ...newData]);
              }
            } else if (offset === 0) {
              isNoData.current = true;
              setData([]);
            } else {
              isNoData.current = true;
            }
          } catch (error) {
            console.log(error);
          } finally {
            handleIsLoading(false);
          }
        }
        if (type === REQUEST_PAGE && !isNoData.current) {
          handleIsLoading(true);
          try {
            const dataFromSever = await getRequestSearchDocs({
              startDate,
              endDate,
              categoryId,
              resultValue,
              employeeId: employeeId,
              limit: 10,
              offset
            });
            if (dataFromSever.length > 0) {
              const newData = dataFromSever.map((value: any) =>
                _findNameOfCategoryId(value, categorySelectionReducer)
              );
              if (offset === 0) {
                setData(newData);
              } else {
                setData([...data, ...newData]);
              }
            } else if (offset === 0) {
              isNoData.current = true;
              setData([]);
            } else {
              isNoData.current = true;
            }
          } catch (error) {
            console.log(error);
          } finally {
            handleIsLoading(false);
          }
        }
      }
      selectDoc(-1);
    },
    [
      type,
      departmentId,
      searchType,
      searchWord,
      handleIsLoading,
      selectDoc,
      startDate,
      endDate,
      categoryId,
      resultValue,
      employeeId,
      data,
      categorySelectionReducer
    ]
  );

  return { data, searchData };
};

export default useHandleDataInApprovalWithRequest;
