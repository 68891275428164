import * as React from "react";
import { ListItem } from "../../../../hooks/use-super-admin/use-web-menu-list";
import Button from "../../../globalComponents/Button";
import {
  Container,
  CheckBoxContainer,
  CheckBox,
  CheckMark,
  ContentContainer,
  ContentTitle,
  Title,
  ListContainer,
  ListTitle,
  ButtonContainer,
  RadioButtonGroup
} from "./styled";
import useOpenDialog from "../../../../hooks/use-open-dialog/use-open-dialog";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import SuperAdminPermissionSetting from "../super_admin_permission_setting/super_admin_permission_setting";
import StyleInput from "../../../inputs/style-input";
import RadioContainer from "../../../shared/radio_container/radio_container";
import styled from "styled-components";
import uiString from "../string.json";

type OnChange = React.ChangeEvent<HTMLInputElement>;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatisticsLabel = styled.label`
  margin-right: 10px;
  font-weight: bold;
`;

interface IProps {
  title?: string;
  list?: ListItem[];
  handleOnChange: (event: OnChange) => void;
  updateMenuList?: () => void;
}

export const REPORT_FLAG_KEY = "web_report_flag";

// 통계메뉴 사용 여부
enum StatisticsUsage {
  ALL = 1, // 전체사용
  NONE = 2, // 미사용
  WORK_STATUS = 3, // 근무현황 사용
  STATISTICS_LIST = 4 // 통계목록 사용
}

// 메뉴 숨김 작동은 LeftMenuList.jsx에서 처리
const MenuCheckBoxList = (props: IProps) => {
  const { title, list, handleOnChange, updateMenuList } = props;
  const { isOpen, handleOpenDialog } = useOpenDialog();
  return (
    <Container>
      <Title>{title}</Title>
      {list &&
        list.map((item, index) => (
          <ListContainer key={index}>
            <ListTitle>{item.mainTitle}</ListTitle>
            {item.data.map((subItem, subindex) => (
              <ContentContainer key={subindex}>
                {subItem.key === REPORT_FLAG_KEY ? (
                  <StatisticsContainer>
                    <StatisticsLabel>
                      {uiString.titles.statistics}
                    </StatisticsLabel>
                    <RadioButtonGroup>
                      {[
                        [
                          uiString.buttons.statisticsUsage.none,
                          StatisticsUsage.NONE
                        ],
                        [
                          uiString.buttons.statisticsUsage.all,
                          StatisticsUsage.ALL
                        ],
                        [
                          uiString.buttons.statisticsUsage.workStatus,
                          StatisticsUsage.WORK_STATUS
                        ],
                        [
                          uiString.buttons.statisticsUsage.statisticsList,
                          StatisticsUsage.STATISTICS_LIST
                        ]
                      ].map(([option, value], optionIndex) => {
                        return (
                          <RadioContainer key={value}>
                            <StyleInput
                              type="radio"
                              value={value}
                              id={`${subItem.key}-${value}`}
                              name={REPORT_FLAG_KEY}
                              minWidth="20px"
                              checked={subItem.status === value}
                              onChange={handleOnChange}
                            />
                            <label htmlFor={`${subItem.key}-${value}`}>
                              {option}
                            </label>
                          </RadioContainer>
                        );
                      })}
                    </RadioButtonGroup>
                  </StatisticsContainer>
                ) : (
                  <>
                    <CheckBoxContainer>
                      <CheckBox
                        type="checkbox"
                        title={subItem.title}
                        id={subItem.key}
                        name={subItem.key}
                        value={subItem.status}
                        checked={subItem.isCheck}
                        onChange={handleOnChange}
                      />
                      <CheckMark />
                    </CheckBoxContainer>
                    <ContentTitle>{subItem.title}</ContentTitle>
                  </>
                )}
              </ContentContainer>
            ))}
          </ListContainer>
        ))}
      <ButtonContainer>
        <Button onClick={updateMenuList}>변경</Button>
        {title === "MOBILE" && (
          <Button
            onClick={() => {
              handleOpenDialog(true);
            }}
          >{`권한설정`}</Button>
        )}
      </ButtonContainer>
      {isOpen && (
        <AsonicDialog
          title={"권한설정"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
          width="1500px"
          minWidth="1500px"
          height="430px"
          minHeight="430px"
        >
          <SuperAdminPermissionSetting />
        </AsonicDialog>
      )}
    </Container>
  );
};

export default MenuCheckBoxList;
