import styled from "styled-components";
import mColors from "../../../style-sheet/m_colors";
import { Icon } from "@iconify/react";
import { MApprovalTabs } from "./m_approval_user_info";
import { useMemo, useState } from "react";
import MApprovalWaitingList from "./m_approval_waiting_list";
import MApprovalCompletedList from "./m_approval_completed_list";
import ToastMessage from "../../toast-message/toast-message";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import MApprovalReceiveList from "./m_approval_receive_list";
import MApprovalReferenceList from "./m_approval_reference_list";
import MApprovalMyList from "./m_approval_my_list";
import { useRecoilValue } from "recoil";
import mRAStore from "./m_request_approval/m_r_a_store";

interface IProps {
  tab: MApprovalTabs;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  waitingCount?: number | null | undefined;
  completedCount?: number | null | undefined;
  receiveCount?: number | null | undefined;
  referenceCount?: number | null | undefined;
  myCount?: number | null | undefined;
  searchValue: string;
}

const Container = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 5px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

const SearchInput = styled.input`
  display: flex;
  flex: 5;
  border-radius: 15px;
  background-color: ${mColors.lightGreen};
  min-height: 25px;
  :focus {
    border-color: ${props => props.theme.colors.green};
  }
`;

const IconContainer = styled.div`
  display: flex;
  height: 25px;
  width: 25px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 24px;
  }
  background-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.95);
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex: 8;
`;

const FieldSet = styled.fieldset`
  display: flex;
  flex: 1;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.grey};
`;

function MApprovalList({
  tab,
  setSearchValue,
  waitingCount,
  completedCount,
  receiveCount,
  referenceCount,
  myCount,
  searchValue
}: IProps) {
  const { user } = useRecoilValue(mRAStore);
  const [value, setValue] = useState<string>("");
  const [isInit, setIsInit] = useState<boolean>(false);
  // 각 탭에 따른 필드셋 이름 변경
  const title = useMemo(() => {
    let netTitle = "대기목록";
    switch (tab) {
      case MApprovalTabs.LIST_COMPLETED:
        netTitle = "진행/완료";
        break;
      case MApprovalTabs.LIST_RECEIVE:
        netTitle = "수신";
        break;
      case MApprovalTabs.LIST_REFERENCE:
        netTitle = "참조";
        break;
      case MApprovalTabs.LIST_OF_MY_APPROVAL:
        netTitle = "나의결재";
        break;
    }
    return netTitle;
  }, [tab]);

  const count = useMemo(() => {
    let newCount = 0;
    switch (tab) {
      case MApprovalTabs.LIST_WAITING:
        newCount = waitingCount ?? 0;
        break;
      case MApprovalTabs.LIST_COMPLETED:
        newCount = completedCount ?? 0;
        break;
      case MApprovalTabs.LIST_RECEIVE:
        newCount = receiveCount ?? 0;
        break;
      case MApprovalTabs.LIST_REFERENCE:
        newCount = referenceCount ?? 0;
        break;
      case MApprovalTabs.LIST_OF_MY_APPROVAL:
        newCount = myCount ?? 0;
        break;
    }
    if (newCount > 0) {
      return `(${newCount})`;
    }
    return "";
  }, [waitingCount, completedCount, tab]);

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  return (
    <Container>
      <SearchBarContainer>
        <SearchInput
          placeholder="검색어 입력"
          value={value}
          onChange={event => {
            setValue(event.currentTarget.value ?? "");
          }}
        />
        <IconContainer
          onClick={() => {
            setSearchValue(value);
            setIsInit(true);
          }}
        >
          <Icon icon="mdi:magnify" />
        </IconContainer>
      </SearchBarContainer>
      <ListContainer>
        <FieldSet>
          <legend>{`${title}${count}`}</legend>
          {tab === MApprovalTabs.LIST_WAITING && (
            <MApprovalWaitingList
              employeeId={user?.employeeId}
              searchValue={searchValue}
              count={waitingCount ?? 0}
              handleToast={handleToast}
              isInit={isInit}
              setIsInit={setIsInit}
            />
          )}
          {tab === MApprovalTabs.LIST_COMPLETED && (
            <MApprovalCompletedList
              employeeId={user?.employeeId}
              searchValue={searchValue}
              count={completedCount ?? 0}
              handleToast={handleToast}
              isInit={isInit}
              setIsInit={setIsInit}
            />
          )}
          {tab === MApprovalTabs.LIST_RECEIVE && (
            <MApprovalReceiveList
              employeeId={user?.employeeId}
              searchValue={searchValue}
              count={receiveCount ?? 0}
              handleToast={handleToast}
              isInit={isInit}
              setIsInit={setIsInit}
            />
          )}
          {tab === MApprovalTabs.LIST_REFERENCE && (
            <MApprovalReferenceList
              employeeId={user?.employeeId}
              searchValue={searchValue}
              count={referenceCount ?? 0}
              handleToast={handleToast}
              isInit={isInit}
              setIsInit={setIsInit}
            />
          )}
          {tab === MApprovalTabs.LIST_OF_MY_APPROVAL && (
            <MApprovalMyList
              employeeId={user?.employeeId}
              searchValue={searchValue}
              count={myCount ?? 0}
              handleToast={handleToast}
              isInit={isInit}
              setIsInit={setIsInit}
            />
          )}
        </FieldSet>
      </ListContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
        widget
      />
    </Container>
  );
}

export default MApprovalList;
