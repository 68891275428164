import styled from "styled-components";
import AsonicTable from "../../../../../asonic-table/asonic-table";
import { useCallback, useEffect, useMemo } from "react";
import * as ReactTable from "react-table";
import { TColumn } from "../../../../../../hooks/use-hide-columns/use-hide-columns";
import InfoIcon from "../../../../../shared/info-icon/info-icon";
import UserInformationChangeHistoryHelp from "./user-information-change-history-help";
import {
  useGetAnnualHistoryLazyQuery,
  AnnualHistoryEntity,
  useDownloadAnnualHistoryAsExcelLazyQuery
} from "../../../../../../generated/graphql";
import { Cell } from "../../../../../../../types/@react-table/react-table/react-table";
import moment from "moment";
import { downloadFileFromServer } from "../../../../statistics/Utils";

interface IProps {
  employeeId: string;
  applyYear: string;
}

const COLUMN_FOR_VALUE = {
  logIdx: "No",
  modifyDatetime: "변경일자",
  statusFlag: "변경내역",
  createSubject: "변경자",
  basicDay: "기본일수",
  basicModifyDay: "기본조정",
  useModifyDay: "사용조정",
  modifyReason: "사유"
} as const;

type COLUMN_FOR_VALUE = typeof COLUMN_FOR_VALUE[keyof typeof COLUMN_FOR_VALUE];

type TYPE_COLUMN_FOR_VALUE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const title = "연차 변경 이력";

function UserInformationHistory({ employeeId, applyYear }: IProps) {
  const [getAnnualHistory, { data, loading }] = useGetAnnualHistoryLazyQuery();
  const [downloadAnnualHistoryAsExcel] =
    useDownloadAnnualHistoryAsExcelLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted(data) {
        if (
          data.downloadAnnualHistoryAsExcel.ok &&
          data.downloadAnnualHistoryAsExcel.excel
        ) {
          downloadFileFromServer(
            data.downloadAnnualHistoryAsExcel.excel,
            `${moment().format("YYYY-MM-DD-hh-mm-ss")}-history.csv`
          );
        }
      }
    });
  const handleDownloadExcel = useCallback(() => {
    downloadAnnualHistoryAsExcel({
      variables: {
        employeeId,
        applyYear
      }
    });
  }, [downloadAnnualHistoryAsExcel, employeeId, applyYear]);

  const columns: ReactTable.Column<AnnualHistoryEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);

    return listOfColumn.map(item => {
      let width = 120;
      if (
        COLUMN_FOR_VALUE[item as TYPE_COLUMN_FOR_VALUE] ===
        COLUMN_FOR_VALUE.logIdx
      ) {
        width = 60;
      }
      return {
        Header: COLUMN_FOR_VALUE[item as TYPE_COLUMN_FOR_VALUE],
        accessor: item as TYPE_COLUMN_FOR_VALUE,
        width,
        Cell(cell: Cell<AnnualHistoryEntity>) {
          if (
            COLUMN_FOR_VALUE[item as TYPE_COLUMN_FOR_VALUE] ===
            COLUMN_FOR_VALUE.modifyDatetime
          ) {
            return cell.value ? cell.value.split(" ")[0] : "";
          }
          return cell.value;
        }
      };
    });
  }, []);

  const historyData: AnnualHistoryEntity[] = useMemo(() => {
    return data?.getAnnualHistory.history || [];
  }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    columns: userColumns
  } = ReactTable.useTable<AnnualHistoryEntity>(
    {
      columns,
      data: historyData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  useEffect(() => {
    getAnnualHistory({
      variables: {
        employeeId,
        applyYear
      }
    });
  }, [getAnnualHistory, employeeId, applyYear]);

  return (
    <Container>
      <AsonicTable<AnnualHistoryEntity>
        title={title}
        total={10}
        downloadExcel={handleDownloadExcel}
        isLoading={loading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        columns={userColumns as TColumn<AnnualHistoryEntity>[]}
      >
        <UserInformationChangeHistoryHelp />
      </AsonicTable>
    </Container>
  );
}

export default UserInformationHistory;
