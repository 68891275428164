import styled from "styled-components";

const ButtonContainer = styled.div<{ flex?: number }>`
  display: flex;
  flex: ${props => props.flex ?? ""};
  align-items: ${props => (props.flex ? "flex-start" : "center")};
  justify-content: center;
  gap: 5px;
`;

export default ButtonContainer;
