import { Container, Ul, Li, Description } from "./styled";

type other = {
  name: string;
  time: string;
};
type Data = {
  regular: string;
  others: other[];
};

type Props = {
  data: Data;
};

const WorkingStatusInformation = <P extends Props>({ data }: P) => {
  return (
    <Container data-testid="working-status-information">
      <Ul>
        {data && data.regular && (
          <Li>
            <Description>{`정규근무 (${data.regular})`}</Description>
          </Li>
        )}
        {data.others.length > 0 &&
          data.others?.map((item, index) => (
            <Li key={index}>
              <Description>{`${item.name} (${item.time})`}</Description>
            </Li>
          ))}
      </Ul>
    </Container>
  );
};

export default WorkingStatusInformation;
