import styled from "styled-components";

export const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  gap: 10px;
`;

export const BtnContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 80px;
  right: 10px;
`;

export const Btn = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.darkBlue};
  font-weight: bold;
  border: none;
  svg {
    font-size: 30px;
  }
  :active {
    transform: scale(0.9);
  }
`;
