import { Icon } from "@iconify/react";
import styled from "styled-components";
import StyledColumnContainer from "../../shared/styled_column_container";
import MApprovalUserInfo, { MApprovalTabs } from "./m_approval_user_info";
import MApprovalList from "./m_approval_list";
import {
  CountAllListOfApprovalQueryVariables,
  UserEntity,
  useCountAllListOfApprovalLazyQuery,
  useGetUserLazyQuery
} from "../../../generated/graphql";
import { useEffect, useState } from "react";
import MRequestApproval from "./m_request_approval/m_request_approval";
import { useRecoilState } from "recoil";
import mRAStore from "./m_request_approval/m_r_a_store";

interface IProps {
  employeeId?: string;
}

const ContentContainer = styled(StyledColumnContainer)`
  flex: 20;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: 10px;
  gap: 20px;
`;

const AddIconContainer = styled.div`
  display: flex;
  height: 25px;
  width: 25px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 24px;
  }
  background-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  :active {
    transform: scale(0.95);
  }
  position: fixed;
  left: 83%;
  bottom: 4%;
  background-color: ${props => props.theme.colors.darkRed};
  :hover {
    background-color: ${props => props.theme.colors.lightRed};
  }
  box-shadow: 4px 5px 2px -1px rgba(173, 173, 173, 0.79);
  -webkit-box-shadow: 4px 5px 2px -1px rgba(173, 173, 173, 0.79);
  -moz-box-shadow: 4px 5px 2px -1px rgba(173, 173, 173, 0.79);
`;

function MApprovalInfo({ employeeId }: IProps) {
  const [{ user }, setMRAState] = useRecoilState(mRAStore);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [tab, setTab] = useState<MApprovalTabs>(MApprovalTabs.LIST_WAITING);
  const [getUser, { data: userData }] = useGetUserLazyQuery();
  const [waitingCount, setWaitingCount] = useState<number | null | undefined>(
    0
  );
  const [completedCount, setCompletedCount] = useState<
    number | null | undefined
  >(0);
  const [receiveCount, setReceiveCount] = useState<number | null | undefined>(
    0
  );
  const [referenceCount, setReferenceCount] = useState<
    number | null | undefined
  >(0);
  const [myCount, setMyCount] = useState<number | null | undefined>(0);

  const [countAllListOfApproval] = useCountAllListOfApprovalLazyQuery({
    fetchPolicy: "cache-and-network",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.countAllListOfApproval.ok) {
        const {
          waitingCount,
          completedCount,
          receiveCount,
          referenceCount,
          myCount
        } = data.countAllListOfApproval;
        setWaitingCount(waitingCount);
        setReceiveCount(receiveCount);
        setReferenceCount(referenceCount);
        setMyCount(myCount);
        setCompletedCount(completedCount);
      } else if (
        !data.countAllListOfApproval.ok &&
        data.countAllListOfApproval.error
      ) {
        console.log(data.countAllListOfApproval.error);
      }
    }
  });

  useEffect(() => {
    if (userData?.getUser.user) {
      setMRAState(pre => {
        if (userData?.getUser.user) {
          return {
            ...pre,
            user: userData.getUser.user[0]
          };
        }
        return pre;
      });
    }
  }, [userData]);

  useEffect(() => {
    if (employeeId) {
      getUser({
        variables: {
          getUserId: employeeId,
          like: false
        }
      });
    }
  }, [employeeId, getUser]);

  useEffect(() => {
    if (user?.employeeId) {
      let payload: CountAllListOfApprovalQueryVariables = {
        searchValue,
        employeeId: user?.employeeId
      };
      countAllListOfApproval({
        variables: payload
      });
    }
  }, [countAllListOfApproval, searchValue, user?.employeeId]);

  return (
    <ContentContainer>
      {!isAdd && (
        <MApprovalUserInfo
          tab={tab}
          setTab={setTab}
          waitingCount={waitingCount}
          completedCount={completedCount}
          receiveCount={receiveCount}
          referenceCount={referenceCount}
          myCount={myCount}
        />
      )}
      {!isAdd && (
        <MApprovalList
          tab={tab}
          setSearchValue={setSearchValue}
          waitingCount={waitingCount}
          completedCount={completedCount}
          receiveCount={receiveCount}
          referenceCount={referenceCount}
          myCount={myCount}
          searchValue={searchValue}
        />
      )}
      {isAdd && <MRequestApproval setIsAdd={setIsAdd} user={user} />}
      {!isAdd && (
        <AddIconContainer onClick={() => setIsAdd(true)}>
          <Icon icon="mingcute:plus-fill" />
        </AddIconContainer>
      )}
    </ContentContainer>
  );
}

export default MApprovalInfo;
