import styled from "styled-components";

type Props = {
  titleColor: string;
  titleContentColor: string;
  titleContentBackgroundColor: string;
};

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const TitleContainer = styled.div`
  flex: 1.5;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Title = styled.span<Partial<Props>>`
  color: ${props => props.titleColor ?? "white"};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const TitleContentSection = styled.div<Partial<Props>>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.titleContentColor ?? "white"};
  background-color: ${props => props.titleContentBackgroundColor ?? "white"};
  border-radius: 2px;
`;

export const TitleContent = styled.span`
  font-size: 18px;
  padding: 5px;
`;

export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex: 7;
  width: 200px;
  height: 90px;
`;

export const DescriptionContainer = styled.div`
  flex: 2;
  border-left: 4px solid white;
  border-right: 4px solid white;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
`;
