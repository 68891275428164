import { useForm } from "react-hook-form";
import styled from "styled-components";
import TextInput from "../inputs/text-input";
import { Icon } from "@iconify/react";
import { useMemo } from "react";
import SearchIcon from "@iconify/icons-mdi/magnify";

export interface IList {
  name: string;
  value: string;
}

export interface SearchFieldValues {
  type?: string;
  value?: string;
}
export interface ISearchProps {
  listOfSearchType?: IList[];
  handleSearch?: (data: SearchFieldValues) => void;
}

const Container = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Label = styled.label`
  min-width: max-content;
`;

const Select = styled.select``;

const Option = styled.option``;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.9);
  }
  cursor: pointer;
`;

function AsonicSearch({ listOfSearchType, handleSearch }: ISearchProps) {
  const { register, handleSubmit, watch } = useForm<SearchFieldValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      type: listOfSearchType ? listOfSearchType[0]?.value : "",
      value: ""
    }
  });
  const data = watch();
  const type = useMemo(() => {
    if (data.type?.toLocaleLowerCase().includes("date")) {
      return "date";
    }
    return "text";
  }, [data.type]);

  return (
    <Container onSubmit={handleSearch && handleSubmit(handleSearch)}>
      <Label htmlFor="search">검색</Label>
      <Select {...register("type")}>
        {listOfSearchType?.map(item => (
          <Option value={item.value} key={item.value}>
            {item.name}
          </Option>
        ))}
      </Select>
      <TextInput id="search" {...register("value")} type={type} />
      <IconContainer onClick={handleSearch && handleSubmit(handleSearch)}>
        <Icon icon={SearchIcon} />
      </IconContainer>
    </Container>
  );
}

export default AsonicSearch;
