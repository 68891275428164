import styled from "styled-components";
import colors from "../../../../style-sheet/colors";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const Title = styled.div`
  display: flex;
  font-size: 20px;
  color: ${colors.personalDashBoard.monthlyWorkStatusBox.title};
  font-weight: bold;
  gap: 10px;
`;

export const SubTitle = styled.span`
  font-size: 13px;
  color: ${colors.personalDashBoard.monthlyWorkStatusBox.subTitle};
`;

export const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
