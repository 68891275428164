import styled from "styled-components";
import * as ReactTable from "react-table";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  AnnualStatusEntity,
  ApprovalStatusEntity,
  IApprovalStatusSearchType,
  UserDailyWorkPlanEntity,
  useGetApprovalStatusListCountLazyQuery,
  useGetApprovalStatusListExcelLazyQuery,
  useGetApprovalStatusListLazyQuery
} from "../../../../../generated/graphql";
import { downloadFileFromServer } from "../../../statistics/Utils";
import { Cell } from "../../../../../../types/@react-table/react-table/react-table";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import Spinner from "../../../../shared/spinner";
import PageController from "../../../../table/page_controller";
import useNewSortBy from "../../../../../hooks/use-new-sort-by/use-new-sort-by";
import ApprovalStatusHeader from "./approval_status_header";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../../table_v2/table_v2";

const COLUMN_FOR_VALUE = {
  deptName: "부서",
  employeeId: "사번",
  empName: "이름",
  requestDateTime: "작성일",
  categoryName: "정보구분",
  startDateTime: "신청시작일시",
  endDateTime: "신청종료일시",
  requestDescr: "신청사유",
  processStatus: "처리결과",
  processDateTime: "처리시간",
  processDescr: "처리사유"
} as const;

type TYPE_OF_ANNUAL_STATUS = keyof typeof COLUMN_FOR_VALUE;

export interface IUserDailyWorkPlanEntity
  extends Partial<UserDailyWorkPlanEntity> {
  isCheck?: boolean;
}
const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 10;
  overflow-y: hidden;
  overflow-x: auto;
  will-change: scroll-position;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;

function ApprovalStatus() {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();
  const { fieldSort, handleFieldSort } = useNewSortBy();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [searchStartDate, setSearchStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [searchType, setSearchType] = useState<IApprovalStatusSearchType>(
    IApprovalStatusSearchType.EmpName
  );
  const [searchValue, setSearchValue] = useState("");
  const [rSearchType, setRSearchType] = useState<IApprovalStatusSearchType>(
    IApprovalStatusSearchType.EmpName
  );
  const [rSearchValue, setRSearchValue] = useState("");

  const [isSearch, setIsSearch] = useState<boolean>(true);
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const listOfSearchType = useMemo(() => {
    return [
      {
        value: IApprovalStatusSearchType.DeptName,
        name: "부서"
      },
      {
        value: IApprovalStatusSearchType.EmployeeId,
        name: "사번"
      },
      {
        value: IApprovalStatusSearchType.EmpName,
        name: "이름"
      },
      {
        value: IApprovalStatusSearchType.CategoryName,
        name: "정보구분"
      },
      {
        value: IApprovalStatusSearchType.RequestDescr,
        name: "신청사유"
      },
      {
        value: IApprovalStatusSearchType.ProcessStatus,
        name: "처리결과"
      },
      {
        value: IApprovalStatusSearchType.ProcessDescr,
        name: "처리사유"
      }
    ];
  }, []);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [getApprovalStatusListCount, { data: totalCountData }] =
    useGetApprovalStatusListCountLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
      }
    });

  const [getApprovalStatusList, { data, loading }] =
    useGetApprovalStatusListLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
        setIsSearch(false);
      },
      onCompleted() {
        setIsSearch(false);
      }
    });

  const [getApprovalStatusListExcel] = useGetApprovalStatusListExcelLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 결재현황 정보를 엑셀로 다운로드 하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    onCompleted(data) {
      if (
        data.getApprovalStatusListExcel.ok &&
        data.getApprovalStatusListExcel.excel
      ) {
        downloadFileFromServer(
          data.getApprovalStatusListExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")})_list_of_annual_status.csv`
        );
      } else if (data.getApprovalStatusListExcel.error) {
        handleToast(data.getApprovalStatusListExcel.error, MessageTypes.ERROR);
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getApprovalStatusListExcel({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          searchStartDate,
          searchEndDate,
          searchType: rSearchType,
          searchValue: rSearchValue
        }
      });
    }
  }, [
    getApprovalStatusListExcel,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate,
    rSearchType,
    rSearchValue
  ]);

  const count: number = useMemo(() => {
    return totalCountData?.getApprovalStatusListCount.count ?? 0;
  }, [totalCountData]);

  const columns: ReactTable.Column<ApprovalStatusEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const smallWidth = 70;
    let newListOfColumn: ReactTable.Column<ApprovalStatusEntity>[] =
      listOfColumn.map((item, index) => {
        let width: number = 130;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS] ===
            COLUMN_FOR_VALUE.processStatus ||
          COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS] ===
            COLUMN_FOR_VALUE.empName
        ) {
          width = smallWidth;
        }
        let sticky = "";
        if (sFixedColumnNumber) {
          if (index + 1 <= sFixedColumnNumber) {
            sticky = "left";
          }
        }
        return {
          Header(header) {
            return COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS];
          },
          accessor: item as TYPE_OF_ANNUAL_STATUS,
          Cell(cell: Cell<AnnualStatusEntity>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS] ===
                COLUMN_FOR_VALUE.requestDescr ||
              COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS] ===
                COLUMN_FOR_VALUE.processDescr
            ) {
              if (cell.value.length > 14) {
                return `${cell.value.slice(0, 14)}...`;
              }
              return cell.value;
            }
            return cell.value ?? "";
          },
          width,
          sticky
        };
      });
    return newListOfColumn;
  }, [sFixedColumnNumber]);

  const list = useMemo(() => {
    return data?.getApprovalStatusList.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<ApprovalStatusEntity>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const selectedRow: ReactTable.Row<ApprovalStatusEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  useEffect(() => {
    setIsSearch(true);
  }, [currentPage]);

  useEffect(() => {
    if (isSearch) {
      setSearchStartDate(startDate);
      setSearchEndDate(endDate);
      setRSearchType(searchType);
      setRSearchValue(searchValue);
    }
  }, [isSearch, startDate, endDate, searchType, searchValue]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getApprovalStatusList({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          searchStartDate,
          searchEndDate,
          searchType: rSearchType,
          searchValue: rSearchValue,
          page: currentPage,
          take,
          fieldSort
        }
      });
    }
  }, [
    getApprovalStatusList,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate,
    rSearchType,
    rSearchValue,
    currentPage,
    take,
    fieldSort
  ]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length) {
      getApprovalStatusListCount({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          searchStartDate,
          searchEndDate,
          searchType: rSearchType,
          searchValue: rSearchValue
        }
      });
    }
  }, [
    getApprovalStatusListCount,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate,
    rSearchType,
    rSearchValue
  ]);

  return (
    <Container>
      <ApprovalStatusHeader<ApprovalStatusEntity>
        columns={table.columns as TColumn<ApprovalStatusEntity>[]}
        table={table}
        setIsSearch={setIsSearch}
        title="결재현황"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={count}
        handleToast={handleToast}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        searchType={searchType}
        setSearchType={setSearchType}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        listOfSearchType={listOfSearchType}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title="결재현황"
                  selectedRow={selectedRow}
                  fieldSort={fieldSort}
                  handleFieldSort={handleFieldSort}
                  height={height}
                  width={width}
                  loading={loading}
                />
              );
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableFooter>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ApprovalStatus;
