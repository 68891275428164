import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MobileSummaryView from "../../../moComponents/main-view/mobile-summary-view";
import { requestActionCreator } from "../../../redux/modules/main-view/request/requestReducer";
import { mainViewActionCreator } from "../../../redux/modules/main-view/mainViewReducer";
import { signInActionCreator } from "../../../redux/modules/sign-in/signInReducer";

const mapStateToProps = state => ({
  requestReducer: state.requestReducer,
  signInReducer: state.signInReducer,
  mainViewReducer: state.mainViewReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...mainViewActionCreator,
      ...signInActionCreator,
      ...requestActionCreator
    },
    dispatch
  )
});

const MobileSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileSummaryView);

export default MobileSummaryContainer;
