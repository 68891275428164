import styled from "styled-components";
import SubTitle from "../shared/sub-title/sub-title";
import FormRow from "../shared/form-row/form-row";
import StyleInput from "../inputs/style-input";
import { useCallback, useEffect, useMemo, useState } from "react";
import BasicInfoForAnnualLeave from "./basic-info-for-annual-leave";
import { useForm } from "react-hook-form";
import {
  CompanyEntity,
  useGetAnnualEnvInfoLazyQuery,
  useUpdateAnnualEnvInfoMutation,
  AnnualEnvInfoEntity,
  Annual_Accrual_Type,
  Annual_Fix_Type
} from "../../generated/graphql";
import Button from "../globalComponents/Button";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";

interface IProps {
  selectedCompany?: CompanyEntity;
}

interface IFormValues
  extends Omit<AnnualEnvInfoEntity, "annualDeductionPriorityType"> {}

const ORDER_TYPE = {
  annualLeaveCarriedForward: "annualLeaveCarriedForward",
  numberOfDaysAdded: "numberOfDaysAdded",
  numberOfBasicDays: "numberOfBasicDays"
} as const;

type ORDER_TYPE = (typeof ORDER_TYPE)[keyof typeof ORDER_TYPE];

const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 1200px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  min-width: 900px;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 460px;
  min-width: 460px;
  :first-child {
    select {
      min-width: 235px;
    }
  }
`;

function BasicAnnualLeaveSetting({ selectedCompany }: IProps) {
  const { register, setValue, watch, handleSubmit } = useForm<IFormValues>({
    defaultValues: {
      annualAccrualType: Annual_Accrual_Type.BasedOnFiscalYear,
      annualMaxCount: 0,
      annualFixType: Annual_Fix_Type.FullAutoConfirm,
      accountingMonth: 1
    }
  });

  const listOfAnnualSetting = useMemo(
    () => [
      { title: "회계년도 기준", value: Annual_Accrual_Type.BasedOnFiscalYear },
      {
        title: "입사일 기준",
        value: Annual_Accrual_Type.BasedOnTheDateOfJoining
      }
    ],
    []
  );

  const listOfAnnualLeaveFixSetting = useMemo(
    () => [
      { title: "전체 자동 확정", value: Annual_Fix_Type.FullAutoConfirm },
      {
        title: "근속 1년 이상 자만 자동 확정",
        value: Annual_Fix_Type.MoreThanAnYearConfirm
      },
      {
        title: "입사 차기년도 부터 자동 확정",
        value: Annual_Fix_Type.AutoConfirmNextYear
      },
      {
        title: "관리자에 의한 개별 확정",
        value: Annual_Fix_Type.IndividualConfirmByManager
      }
    ],
    []
  );

  const listOfAnnualDeductionPriority = useMemo(
    () => [
      { title: "이월연차", value: "annualLeaveCarriedForward" },
      { title: "가산일수", value: "numberOfDaysAdded" },
      { title: "기본일수", value: "numberOfBasicDays" }
    ],
    []
  );

  const [firstType, setFirstType] = useState<ORDER_TYPE>(
    ORDER_TYPE.annualLeaveCarriedForward
  );
  const [secondType, setSecondType] = useState<ORDER_TYPE>(
    ORDER_TYPE.numberOfBasicDays
  );
  const [thirdType, setThirdType] = useState<ORDER_TYPE>(
    ORDER_TYPE.numberOfDaysAdded
  );

  const handleAnnualDeductionPriorityType = useCallback(
    (annualDeductionPriorityType: ORDER_TYPE, order: number) => {
      switch (order) {
        case 1:
          setFirstType(type => {
            return annualDeductionPriorityType;
          });
          setSecondType(type => {
            if (type === annualDeductionPriorityType) {
              return firstType;
            }
            return type;
          });
          setThirdType(type => {
            if (type === annualDeductionPriorityType) {
              return firstType;
            }
            return type;
          });
          break;
        case 2:
          setFirstType(type => {
            if (type === annualDeductionPriorityType) {
              return secondType;
            }
            return type;
          });
          setSecondType(annualDeductionPriorityType);
          setThirdType(type => {
            if (type === annualDeductionPriorityType) {
              return secondType;
            }
            return type;
          });
          break;
        case 3:
          setFirstType(type => {
            if (type === annualDeductionPriorityType) {
              return thirdType;
            }
            return type;
          });
          setSecondType(type => {
            if (type === annualDeductionPriorityType) {
              return thirdType;
            }
            return type;
          });
          setThirdType(annualDeductionPriorityType);
          break;
      }
    },
    [firstType, secondType, thirdType]
  );

  const handleTypeByOrder = useCallback((order: number) => {
    switch (order) {
      case 1:
        setFirstType(ORDER_TYPE.annualLeaveCarriedForward);
        setSecondType(ORDER_TYPE.numberOfDaysAdded);
        setThirdType(ORDER_TYPE.numberOfBasicDays);
        break;
      case 2:
        setFirstType(ORDER_TYPE.annualLeaveCarriedForward);
        setSecondType(ORDER_TYPE.numberOfBasicDays);
        setThirdType(ORDER_TYPE.numberOfDaysAdded);
        break;
      case 3:
        setFirstType(ORDER_TYPE.numberOfDaysAdded);
        setSecondType(ORDER_TYPE.annualLeaveCarriedForward);
        setThirdType(ORDER_TYPE.numberOfBasicDays);
        break;
      case 4:
        setFirstType(ORDER_TYPE.numberOfDaysAdded);
        setSecondType(ORDER_TYPE.numberOfBasicDays);
        setThirdType(ORDER_TYPE.annualLeaveCarriedForward);
        break;
      case 5:
        setFirstType(ORDER_TYPE.numberOfBasicDays);
        setSecondType(ORDER_TYPE.annualLeaveCarriedForward);
        setThirdType(ORDER_TYPE.numberOfDaysAdded);
        break;
      case 6:
        setFirstType(ORDER_TYPE.numberOfBasicDays);
        setSecondType(ORDER_TYPE.numberOfDaysAdded);
        setThirdType(ORDER_TYPE.annualLeaveCarriedForward);
        break;
    }
  }, []);

  const [getAnnualEnvInfo] = useGetAnnualEnvInfoLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getAnnualEnvInfo.ok && data.getAnnualEnvInfo.info) {
        const {
          annualAccrualType,
          annualMaxCount,
          annualFixType,
          accountingMonth,
          annualDeductionPriorityType
        } = data.getAnnualEnvInfo.info;
        setValue("annualAccrualType", annualAccrualType);
        setValue("annualMaxCount", annualMaxCount);
        setValue("annualFixType", annualFixType);
        setValue("accountingMonth", accountingMonth);
        handleTypeByOrder(annualDeductionPriorityType);
      }
    }
  });

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const [updateAnnualEnvInfo] = useUpdateAnnualEnvInfoMutation({
    onError(error) {
      console.log(error);
    },
    update(_, { data }) {
      if (data?.updateAnnualEnvInfo.ok) {
        handleToast(
          "성공적으로 연차 설정(기본) 저장하였습니다.",
          MessageTypes.SUCCESS
        );
      } else if (data?.updateAnnualEnvInfo.error) {
        handleToast(data.updateAnnualEnvInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const handleUpdate = useCallback(
    (data: IFormValues) => {
      let annualDeductionPriorityType = 1;
      if (firstType === ORDER_TYPE.annualLeaveCarriedForward) {
        if (secondType === ORDER_TYPE.numberOfDaysAdded) {
          annualDeductionPriorityType = 1;
        } else {
          annualDeductionPriorityType = 2;
        }
      } else if (firstType === ORDER_TYPE.numberOfDaysAdded) {
        if (secondType === ORDER_TYPE.annualLeaveCarriedForward) {
          annualDeductionPriorityType = 3;
        } else {
          annualDeductionPriorityType = 4;
        }
      } else if (firstType === ORDER_TYPE.numberOfBasicDays) {
        if (secondType === ORDER_TYPE.annualLeaveCarriedForward) {
          annualDeductionPriorityType = 5;
        } else {
          annualDeductionPriorityType = 6;
        }
      }
      if (selectedCompany) {
        const payload = {
          companyCode: selectedCompany.companyCode,
          annualAccrualType: data.annualAccrualType,
          accountingMonth: parseInt(`${data.accountingMonth}`),
          annualMaxCount: parseInt(`${data.annualMaxCount}`),
          annualDeductionPriorityType,
          annualFixType: data.annualFixType
        };

        updateAnnualEnvInfo({
          variables: payload
        });
      }
    },
    [selectedCompany, updateAnnualEnvInfo, firstType, secondType]
  );

  const { accountingMonth } = watch();

  useEffect(() => {
    if (selectedCompany) {
      getAnnualEnvInfo({
        variables: {
          companyCode: selectedCompany.companyCode
        }
      });
    }
  }, [getAnnualEnvInfo, selectedCompany]);

  return (
    <Container onSubmit={handleSubmit(handleUpdate)}>
      <TitleBar>
        <SubTitle title="연차 설정(기본)" />
        <BasicInfoForAnnualLeave />
      </TitleBar>
      <Body>
        <Section>
          <FormRow title="연차 발생기준 설정">
            <select {...register("annualAccrualType")}>
              {listOfAnnualSetting.map(item => (
                <option value={item.value} key={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </FormRow>
          <FormRow title="최대 연차 제한">
            <span>최대</span>
            <StyleInput type="number" min={0} {...register("annualMaxCount")} />
            <span>{`일 부여`}</span>
          </FormRow>
          <FormRow title="연차 확정 설정">
            <select {...register("annualFixType")}>
              {listOfAnnualLeaveFixSetting.map(item => (
                <option value={item.value} key={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </FormRow>
        </Section>
        <Section>
          <FormRow title="회계기준일">
            <span>{`${accountingMonth}월 1일`}</span>
          </FormRow>
          <FormRow title="연차 차감 우선 순위">
            <select
              name="annual-fix-setting-1"
              id="annual-fix-setting-1"
              onChange={event => {
                handleAnnualDeductionPriorityType(
                  event.target.value as ORDER_TYPE,
                  1
                );
              }}
              value={firstType}
            >
              {listOfAnnualDeductionPriority.map(item => (
                <option value={item.value} key={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
            <span>{`>`}</span>
            <select
              name="annual-fix-setting-2"
              id="annual-fix-setting-2"
              onChange={event => {
                handleAnnualDeductionPriorityType(
                  event.target.value as ORDER_TYPE,
                  2
                );
              }}
              value={secondType}
            >
              {listOfAnnualDeductionPriority.map(item => (
                <option value={item.value} key={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
            <span>{`>`}</span>
            <select
              name="annual-fix-setting-3"
              id="annual-fix-setting-3"
              onChange={event => {
                handleAnnualDeductionPriorityType(
                  event.target.value as ORDER_TYPE,
                  3
                );
              }}
              value={thirdType}
            >
              {listOfAnnualDeductionPriority.map(item => (
                <option value={item.value} key={item.value}>
                  {item.title}
                </option>
              ))}
            </select>
          </FormRow>
        </Section>
      </Body>
      <ButtonContainer>
        <Button>저장</Button>
      </ButtonContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default BasicAnnualLeaveSetting;
