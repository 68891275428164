import * as ReactTable from "react-table";
import AsonicTableRow from "./asonic-table-row";
import { colors } from "../GlobalStyle/GlobalStyle";
import AsonicTableData from "./asonic-table-data";
import { areEqual } from "react-window";
import { memo } from "react";

export interface IAsonicRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}
export interface IAsonicRenderRowProps<P extends {}> {
  prepareRow: (row: IAsonicRow<P>) => void;
  handleSelectRow?: (row?: IAsonicRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: IAsonicRow<P>;
  flexIndex?: number;
  isAllFlex?: boolean;
  listOfFlexForHeader?: string[];
  isLastFlex?: boolean;
  isOverFlow?: boolean;
  isOneColumn?: boolean;
}
interface IRenderRow<P extends {}> {
  data: IAsonicRow<P>[];
  index: number;
  style: object;
}

function AsonicRenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  flexIndex,
  listOfFlexForHeader,
  isOneColumn,
  isOverFlow,
  isLastFlex = true
}: IAsonicRenderRowProps<P>) {
  return memo(({ index, data, style }: IRenderRow<P>) => {
    const row: IAsonicRow<P> = data[index];
    prepareRow(row);
    const backgroundColor = row.isSelected ? colors.green : "none";
    const color = row.isSelected ? colors.white : colors.black;
    const initStyle = { ...style, backgroundColor, color };
    const multiSelect = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const contextButton = 2;
      if (event.type === "contextmenu" || event.button === contextButton) {
        event.preventDefault();
      }
      if (row.toggleRowSelected) {
        if (
          row.isSelected &&
          (event.type !== "contextmenu" || event.button !== contextButton)
        ) {
          data.forEach(item => {
            if (item.isSelected && item.toggleRowSelected) {
              item.toggleRowSelected(false);
            }
          });
          row.toggleRowSelected(false);
        } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
          row.toggleRowSelected(true);
          if (
            (event.type === "contextmenu" || event.button === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
          }
        } else if (selectedRow && event.shiftKey) {
          data.forEach(item => {
            if (
              parseInt(selectedRow.id) < parseInt(item.id) &&
              parseInt(item.id) <= parseInt(row.id) &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              } else if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
            if (
              parseInt(item.id) < parseInt(selectedRow.id) &&
              parseInt(row.id) <= parseInt(item.id) &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              }
            }
            if (
              (event.type !== "contextmenu" ||
                event.buttons !== contextButton) &&
              handleSelectRow
            ) {
              handleSelectRow(row);
            }
          });
        } else {
          if (
            (event.type === "contextmenu" || event.buttons === contextButton) &&
            handleContextMenu
          ) {
            if (row.isSelected) {
              handleContextMenu(event);
            } else {
              data.forEach(item => {
                if (item.isSelected && item.toggleRowSelected) {
                  item.toggleRowSelected(false);
                }
              });
              row.toggleRowSelected(true);
              handleContextMenu(event);
            }
          } else if (handleSelectRow) {
            if (selectedRow) {
              data.forEach(item => {
                if (item.isSelected && item.toggleRowSelected) {
                  item.toggleRowSelected(false);
                }
              });
            }
            row.toggleRowSelected(true);
            handleSelectRow(row);
          }
        }
      }
    };
    return (
      <AsonicTableRow {...row.getRowProps({ style: initStyle })}>
        {row.cells.map((cell, rowIndex) => {
          let isFlex = flexIndex === rowIndex;
          if (listOfFlexForHeader && cell.column.Header) {
            isFlex = listOfFlexForHeader.includes(cell.column.Header as string);
          }
          if (isOneColumn && rowIndex === 0) {
            isFlex = true;
          }
          if (isOneColumn && rowIndex !== 0) {
            isFlex = false;
          }

          let isNewLastFlex =
            (flexIndex && flexIndex >= 0) || !isLastFlex ? false : true;
          isNewLastFlex = isOneColumn
            ? false
            : (flexIndex && flexIndex >= 0) || !isLastFlex
            ? false
            : true;
          return (
            <AsonicTableData
              {...cell.getCellProps()}
              key={`${index} ${Math.random() * 10}`}
              id={`${data[index].id}-context-menu`}
              onContextMenu={multiSelect}
              onClick={multiSelect}
              isFlex={isFlex}
              isLastFlex={isNewLastFlex}
              isOverFlow={!!isOverFlow}
              isOneColumn={isOneColumn}
            >
              {cell.render("Cell")}
            </AsonicTableData>
          );
        })}
      </AsonicTableRow>
    );
  }, areEqual);
}

export default AsonicRenderRow;
