import {
  CountListOfEmployeeCommuteManagement,
  CountListOfEmployeeCommuteManagementVariables
} from "../../../../__generated__/CountListOfEmployeeCommuteManagement";
import {
  DownloadListOfEmployeeCommuteManagementExcel,
  DownloadListOfEmployeeCommuteManagementExcelVariables
} from "../../../../__generated__/DownloadListOfEmployeeCommuteManagementExcel";
import { downloadFileFromServer } from "../../statistics/Utils";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import * as ReactTable from "react-table";
import { SLink } from "../Content/styled";
import ContextMenu from "../../../context-menu/context-menu";
import useCheckWebAuthFn from "../../../../hooks/use-check-web-auth-fn";
import useContextMenu from "../../../../hooks/use-context-menu/use-context-menu";
import CommuteApprovalMenu from "../DetailView/CommuteContainer/CommuteApprovalMenu";
import { AddCommuteHistoryMenu } from "../DetailView/CommuteContainer/add-commute-history-menu/add-commute-history-menu";
import useIsOpenHook from "../../../../hooks/isOpenHook";
import { AddCommuteHistoryDialog } from "../DetailView/CommuteContainer/add-commute-history-dialog/add-commute-history-dialog";
import CommuteEditorContainer from "../DetailView/CommuteContainer/CommuteEditorDialog/CommuteEditorDialogContainer";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import listOfApolloVar from "../../../../apollo/apollo-var";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import { Role } from "../../../../user-types";
import {
  ApprovalFlag,
  CommuteManagement,
  CommuteType,
  useListOfEmployeeCommuteInformationLazyQuery
} from "../../../../generated/graphql";
import useNewSortBy from "../../../../hooks/use-new-sort-by/use-new-sort-by";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import TableLayoutFooter from "../../../table_layout/table_layout_footer";
import PageController from "../../../table/page_controller";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../table_v2/table_v2";
import CommuteViewHeader from "./commute_view_header";
import { TABS_DB_SCHEME_OBJECT } from "../logics";
import { useSearchDateRange } from "../hooks";

enum COLUMN_FOR_VALUE {
  name = "이름",
  date = "날짜",
  time = "시간",
  commuteType = "구분",
  deviceType = "출/퇴근기기",
  approvalFlag = "출퇴근 인정여부",
  checkDateTimeModify = "출/퇴근 일시",
  commuteAddress = "위치"
}

type TYPE_OF_COMMUTE_MANAGEMENT = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_COUNT_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT = gql`
  query CountListOfEmployeeCommuteManagement(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    countListOfEmployeeCommuteManagement(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
      total
      cacheId
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT_EXCEL = gql`
  query DownloadListOfEmployeeCommuteManagementExcel(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    downloadListOfEmployeeCommuteManagementExcel(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      excel
      error
      ok
    }
  }
`;

const title = "출퇴근관리정보";
let prevEmployeeIdList: string[] = [];

function CommuteViewSection() {
  const {
    signInReducer: { checkWebAuthFn, webMenuList, loginIdInfo, employee_id }
  } = useSelector((state: Reducers) => state);

  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: webMenuList[0]?.web_etc_flag
  });
  const { handleContextMenu, isContextMenu, ctxMenuPosition } =
    useContextMenu();

  const { isOpen, handleIsOpen } = useIsOpenHook();
  const { isOpen: isOpenDialog, handleIsOpen: handleIsOpenDialog } =
    useIsOpenHook();

  const { fieldSort, handleFieldSort } = useNewSortBy();

  const {
    isOpen: isOpenToast,
    handleIsOpen: handleIsOpenToast,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName
  } = useFixedColumn();

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchStartDate,
    searchEndDate,
    saveSearchDate,
    retryRender,
    setRetryRender
  } = useSearchDateRange(TABS_DB_SCHEME_OBJECT.commute, title);

  const [getListOfEmployeeCommuteInformation, { data, loading }] =
    useListOfEmployeeCommuteInformationLazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (
          !data.listOfEmployeeCommuteInformation.ok &&
          data.listOfEmployeeCommuteInformation.error
        ) {
          console.log(data.listOfEmployeeCommuteInformation.error);
        }
      }
    });

  const [countListOfEmployeeCommuteManagement, { data: totalData }] =
    useLazyQuery<
      CountListOfEmployeeCommuteManagement,
      CountListOfEmployeeCommuteManagementVariables
    >(QUERY_COUNT_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT, {
      fetchPolicy: "cache-and-network"
    });

  const selectedListOfEmployeeId = useMemo(() => {
    if (loginIdInfo === Role.USER) {
      return [employee_id];
    }
    return listOfEmployeeId;
  }, [loginIdInfo, employee_id, listOfEmployeeId]);

  const fetchCountListOfEmployeeCommuteManagement = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      countListOfEmployeeCommuteManagement({
        variables: {
          listOfEmployeeId: selectedListOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate
        }
      });
    }
  }, [
    countListOfEmployeeCommuteManagement,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate
  ]);

  const fetchListOfEmployeeCommuteInformation = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getListOfEmployeeCommuteInformation({
        variables: {
          listOfEmployeeId: selectedListOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate,
          page: currentPage,
          take,
          fieldSort
        }
      });
    }
  }, [
    getListOfEmployeeCommuteInformation,
    selectedListOfEmployeeId,
    searchStartDate,
    searchEndDate,
    currentPage,
    take,
    fieldSort
  ]);

  if (
    prevEmployeeIdList.length !== selectedListOfEmployeeId.length ||
    prevEmployeeIdList.some(
      (id, index) => id !== selectedListOfEmployeeId[index]
    ) ||
    retryRender
  ) {
    prevEmployeeIdList = [...selectedListOfEmployeeId];
    fetchCountListOfEmployeeCommuteManagement();
    fetchListOfEmployeeCommuteInformation();
    if (retryRender) {
      setRetryRender(false);
    }
  }

  const [downloadListOfEmployeeCommuteManagementExcel] = useLazyQuery<
    DownloadListOfEmployeeCommuteManagementExcel,
    DownloadListOfEmployeeCommuteManagementExcelVariables
  >(QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfEmployeeCommuteManagementExcel.ok &&
        data.downloadListOfEmployeeCommuteManagementExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfEmployeeCommuteManagementExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-commute-management.csv`
        );
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (listOfEmployeeId) {
      downloadListOfEmployeeCommuteManagementExcel({
        variables: {
          listOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate
        }
      });
    }
  }, [
    downloadListOfEmployeeCommuteManagementExcel,
    listOfEmployeeId,
    searchStartDate,
    searchEndDate
  ]);

  const total: number = useMemo(() => {
    return totalData?.countListOfEmployeeCommuteManagement.total ?? 0;
  }, [totalData]);

  const handleAddress = useCallback(
    (cell: ReactTable.Cell<CommuteManagement>) => {
      const title = cell.row.original.commuteAddress,
        lat = cell.row.original.gpsLat,
        long = cell.row.original.gpsLong;
      let address = "";
      let isAddress = false;
      if (title) {
        isAddress = true;
      }
      if (lat && long) {
        address = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      }
      return (
        <>
          {isAddress ? (
            <SLink href={`${address}`} target="_blank">
              {title}
            </SLink>
          ) : (
            <p>{title}</p>
          )}
        </>
      );
    },
    []
  );

  const columns: ReactTable.Column<CommuteManagement>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    return listOfColumn.map(item => {
      let width = 120;
      if (item === "approvalFlag" || item === "checkDateTimeModify") {
        width = 150;
      }
      let sticky = "";
      if (sFixedColumnNumber !== undefined) {
        if (fixedColumnName.length > 0) {
          fixedColumnName.forEach((fColumn, fColumnIndex) => {
            if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
              sticky = "left";
            }
          });
        }
      }
      return {
        Header: COLUMN_FOR_VALUE[item as TYPE_OF_COMMUTE_MANAGEMENT],
        accessor: item as TYPE_OF_COMMUTE_MANAGEMENT,
        width,
        sticky,
        Cell(cell: ReactTable.Cell<CommuteManagement>) {
          if (cell.column.Header === COLUMN_FOR_VALUE.approvalFlag) {
            if (
              cell.row.original.approvalFlag === ApprovalFlag.OriginRecognition
            ) {
              return "원본인정";
            }
            if (
              cell.row.original.approvalFlag === ApprovalFlag.CorrectedVersion
            ) {
              return "수정본인정";
            }
            if (cell.row.original.approvalFlag === ApprovalFlag.NoRecognition) {
              return "미인정";
            }
          }
          if (cell.column.Header === COLUMN_FOR_VALUE.commuteAddress) {
            return handleAddress(cell);
          }
          if (cell.column.Header === COLUMN_FOR_VALUE.commuteType) {
            if (cell.value === CommuteType.Attendance) {
              return "출근";
            }
            if (cell.value === CommuteType.Leave) {
              return "퇴근";
            }
          }
          return cell.value;
        }
      };
    });
  }, [handleAddress, sFixedColumnNumber, fixedColumnName]);

  const list: CommuteManagement[] = useMemo(() => {
    return data?.listOfEmployeeCommuteInformation.list || [];
  }, [data]);

  const table = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const { moveColumn } = useDnd<CommuteManagement>({
    columns: table.visibleColumns,
    setColumnOrder: table.setColumnOrder,
    title: `${title}-for-ordering-column`,
    setFixedColumnName
  });

  const selectedRow: ReactTable.Row<CommuteManagement> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  useEffect(() => {
    if (
      !data?.listOfEmployeeCommuteInformation.ok &&
      data?.listOfEmployeeCommuteInformation.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.listOfEmployeeCommuteInformation.error);
      handleIsOpenToast(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsOpenToast]);

  return (
    <Container
      id={`commute-view-section-context-menu`}
      onContextMenu={event => {
        handleContextMenu(event);
      }}
    >
      <CommuteViewHeader<CommuteManagement>
        columns={table.columns as TColumn<CommuteManagement>[]}
        table={table}
        setIsSearch={saveSearchDate}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                handleContextMenu={handleContextMenu}
                loading={loading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      {/* <NewAsonicTable<CommuteManagement>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={() => {}}
        isLoading={loading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        handleDate={handleDate}
        fieldSort={fieldSort}
        handleFieldSort={handleFieldSort}
        columns={commuteColumns as TColumn<CommuteManagement>[]}
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      /> */}
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      {(selectedRow || listOfEmployeeId.length > 0) &&
        webMenuAuthList.changeCommutingRecord && (
          <ContextMenu isContextMenu={isContextMenu} {...ctxMenuPosition}>
            {selectedRow && <CommuteApprovalMenu handleIsOpen={handleIsOpen} />}
            {listOfEmployeeId.length > 0 && (
              <AddCommuteHistoryMenu handleIsOpen={handleIsOpenDialog} />
            )}
          </ContextMenu>
        )}
      {isOpen && (
        <CommuteEditorContainer
          handleClose={handleIsOpen}
          row={selectedRow && selectedRow.original}
        />
      )}
      {isOpenDialog && (
        <AddCommuteHistoryDialog
          handleClose={handleIsOpenDialog}
          selectedRow={selectedRow && selectedRow.original}
          handleMessage={handleMessage}
          handleToastMessageType={handleToastMessageType}
          handleIsOpenToast={handleIsOpenToast}
          listIdForCache={data?.listOfEmployeeCommuteInformation.cacheId}
          listOfEmployeeId={listOfEmployeeId}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isOpenToast}
        handleIsOpen={handleIsOpenToast}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default CommuteViewSection;
