import HelpContainer from "../../shared/help_container/help_container";
import InfoIcon from "../../shared/info-icon/info-icon";

function ControlNotiSettingHelp() {
  return (
    <InfoIcon
      minHeight="530px"
      height="530px"
      minWidth="400px"
      title="제어(알림) 설정"
    >
      <HelpContainer>
        <span>{`근무제의 제어 및 알림 창을 설정 합니다.`}</span>
        <ul>
          <li>{`제어(알림)의 생성 및 편집`}</li>
          <ul>
            <li>{`제어 알림은 A-Sonic 관리자 콘솔에서만 생성 및 수정 가능합니다.`}</li>
          </ul>
          <li>{`출근/퇴근 시간 제어(알림) 설정`}</li>
          <pre>{`출근 또는 퇴근 시간을 기준으로 입력하신 시간(분) 전에 발생하는 제어 
알림을 선택 합니다.`}</pre>
          <ul>
            <li>
              {`예시1) 출근 [30] 분전, 제어(알림) [ 출근전알림 ]`}
              <ul>
                <li>
                  <span>{`지정된 출근시간 30분전 “출근전알림” 알림창이 실행 됩니다.`}</span>
                </li>
              </ul>
            </li>
            <li>
              {`예시2) 출근 [0] 분전, 제어(알림) [ 출근체크알림 ]`}
              <ul>
                <li>
                  <span>{`지정된 출근시간에 “출근체크알림” 알림창이 실행 됩니다.`}</span>
                </li>
              </ul>
            </li>
            <li>
              <pre>
                {`자율 출퇴근제의 제어 알림
자율 출퇴근제는 출근 시간이 지정되어 있지 않기 때문에 
출근 시간의 제어(알림)은  출근 가능 시작 시간을 기준으로 동작합
니다. 퇴근 시간의 제어(알림)은 출근 시간에 따라 지정된 퇴근 시
간에 맞춰 동작 합니다.`}
              </pre>
            </li>
          </ul>
          <li>{`퇴근 시간 이후 제어(알림)`}</li>
          <pre>{`지정된 퇴근 시간 이후, 다음 출근 시간까지 동작하는 제어(알림)을 선
택합니다. 미사용인 경우 퇴근 시간 이후 PC제어를 하지 않습니다.`}</pre>
          <li>{`근무시간 초과 제어 알림`}</li>
          <pre>{`최대 시간외 근무  가능시간을 초과 하는 경우 발생하는 제어 알림입
니다. 시간외 근무는 근무시간 유형에 따라 변경 될수 있으며, 주52시간 
근무제의 경우 주 최대 12시간의 시간 외 근무가 가능합니다.`}</pre>
          <li>{`휴게시간 제어알림`}</li>
          <span>{`휴게 시간 동안 발생하는 제어(알림)을 설정 합니다.`}</span>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default ControlNotiSettingHelp;
