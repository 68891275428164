import styled from "styled-components";
import colors from "../../../style-sheet/colors";

type Props = {
  isMiddleSection?: boolean;
};

export const LoaderContainer = styled.div``;

export const Container = styled.div`
  display: grid;
  padding: 20px;
  flex: 1;
  background-color: ${colors.mainBackgroundColor};
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(40px, 1fr));
  gap: 10px;
`;

export const MonthlyAbsenteeismTardinessStatusContainer = styled.div<Props>`
  background-color: white;
  border-radius: 2px;
  grid-column: span 6;
  grid-row: ${props => (props.isMiddleSection ? "span 3" : "span 4")};
`;

export const VacationStatusContainer = styled.div<Props>`
  background-color: white;
  border-radius: 2px;
  grid-column: span 5;
  grid-row: ${props => (props.isMiddleSection ? "span 3" : "span 4")};
`;

export const MonthlyServiceStatusContainer = styled.div<Props>`
  display: flex;
  flex: 1;
  background-color: white;
  border-radius: 2px;
  grid-column: span 11;
  grid-row: ${props => (props.isMiddleSection ? "span 5" : "span 8")};
`;

export const BigSection = styled.section`
  flex: 3;
  margin: 10px;
`;

export const SmallSection = styled.section`
  flex: 1;
  margin: 10px;
`;

export const TableTitleBar = styled.div`
  margin-bottom: 2px;
`;

export const TableTitle = styled.span`
  font-size: 16px;
  color: #5f5f5f;
`;

export const TableContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
`;
