import RightMenuButton from "../../../../../Rightmenu/RightMenuButton";

interface IProps {
  handleIsOpen: (value: boolean) => void;
}

const CommuteApprovalMenu = ({ handleIsOpen }: IProps) => {
  return (
    <>
      <RightMenuButton
        title="출/퇴근 기록 변경"
        handleClick={() => handleIsOpen(true)}
      />
    </>
  );
};

export default CommuteApprovalMenu;
