import api from "../../api";

const BASE_URL = `${api.api}/additional-information-management`;
const ADDITIONAL_INFORMATION_MANAGEMENT_OF_BASE_INFO = `${BASE_URL}/base-information`;
const ADDITIONAL_INFORMATION_MANAGEMENT_OF_WORK_INFO = `${BASE_URL}/work-information`;

const additionalInformationManagementRoutes = {
  additionalInformationManagement: (employeeId: string) =>
    `${BASE_URL}/${employeeId}`,
  additionalInformationManagementOfBaseInfo: ADDITIONAL_INFORMATION_MANAGEMENT_OF_BASE_INFO,
  additionalInformationManagementOfWorkInfo: ADDITIONAL_INFORMATION_MANAGEMENT_OF_WORK_INFO
};
export default additionalInformationManagementRoutes;
