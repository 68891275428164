/**
 * IndexedDB를 사용하여 데이터를 저장하고 불러오는 클래스
 */

export default class IndexedDBManager {
  private dbName: string;
  private storeName: string;
  private dbVersion: number;

  constructor(storeName: string, dbVersion: number = 1) {
    this.dbName = "asonic-work-manager";
    this.storeName = storeName;
    this.dbVersion = dbVersion;
  }

  private openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onupgradeneeded = event => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          // 테이블과 유사한 객체를 생성한다.
          // keyPath는 고유식별자를 어떤 컬럼으로 할 것인지를 설정한다.
          db.createObjectStore(this.storeName, { keyPath: "id" });
        }
      };

      request.onsuccess = event => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = event => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  public async saveData(id: string, data: any): Promise<void> {
    const db = await this.openDatabase();
    const transaction = db.transaction(this.storeName, "readwrite");
    const store = transaction.objectStore(this.storeName);
    store.put({ id, value: data });
  }

  public async loadData(id: string): Promise<any> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.get(id);

      request.onsuccess = event => {
        resolve((event.target as IDBRequest).result?.value);
      };

      request.onerror = event => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}
