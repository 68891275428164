import styled from "styled-components";
import InfoIcon from "../../../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

function EtcSettingHelp() {
  return (
    <InfoIcon
      minHeight="260px"
      height="260px"
      minWidth="400px"
      title="출퇴근 기록 - 기타 설정"
    >
      <HelpContainer>
        <span>{`출근 및 퇴근 기록의 추가 옵션을 설정합니다.`}</span>
        <ul>
          <li>{`특정 요일 상시 출퇴근`}</li>
          <pre>{`특정 요일만 상시 출퇴근을 허용 하는 경우 사용합니다.
상시 출퇴근으로 적용된 요일은 정규 근무시간에 맞춰 사용자가 직접 
출근/퇴근 체크를 기록 합니다.`}</pre>
          <ul>
            <li>
              <pre>{`상시 출퇴근 사용사의 출퇴근 기록 시간
지정된 정규 출/퇴근 시간에 출/퇴근 기록 창이 발생하지 않으며, 
근무자가 원하는 시간에 PC에이전트 또는 앱에서 출/퇴근 체크가 
가능하며, 하루에도 여러번 출근/퇴근 체크가 가능합니다. `}</pre>
            </li>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default EtcSettingHelp;
