import { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";
import MiniCalendarWeek from "./mini-calendar-week/mini-calendar-week";

enum Week {
  saturday = "토",
  sunday = "일"
}

interface IProps {
  weeks: DayInfo[][];
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DayContainer = styled.div`
  display: flex;
  font-weight: bold;
  border-top: 1px solid black;
`;

const DisplayDayContainer = styled.div`
  display: flex;
  flex: 1;
`;

const DisplayDay = styled.span<{ day: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: ${props => {
    if (props.day === Week.saturday || props.day === Week.sunday) {
      return "#eb4d4b";
    } else {
      return "black";
    }
  }};
`;

const MiniCalendar = <P extends IProps>({ weeks }: P) => {
  const weekDays: string[] = useMemo(() => {
    const weekArray = [1, 2, 3, 4, 5, 6, 0].map(dow =>
      moment().weekday(dow).format("ddd")
    );
    return weekArray;
  }, []);

  return (
    <Container>
      <DayContainer>
        {weekDays.map(item => (
          <DisplayDayContainer key={Symbol(item).toString()}>
            <DisplayDay day={item}>{item}</DisplayDay>
          </DisplayDayContainer>
        ))}
      </DayContainer>
      {weeks.map((cells, index) => (
        <MiniCalendarWeek
          key={Symbol(index).toString()}
          cells={cells}
          isFirstWeek={index === 0}
        />
      ))}
    </Container>
  );
};

export default MiniCalendar;
