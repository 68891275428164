import { useContext } from "react";
import uiString from "./string.json";
import DetailViewContext from "../../../../containers/main-view/WorkManagementInfoContainer/DetailViewContainer/DetailViewContext";
import useTitleBarMenu from "../../../../hooks/use-menu-by-etc-flag";
import { Role } from "../../../../user-types";
import STabContainer from "../../../shared/s_tab_container/s_tab_container";
import STab from "../../../shared/s_tab/s_tab";
import { colors } from "../../../GlobalStyle/GlobalStyle";

export enum ViewType {
  WORKING_TEMPLATE = "workingTemplate",
  PC = "pc",
  WORK_MANAGEMENT = "workManagement",
  COMMUTE = "commute",
  PLACE_FREE_MANAGEMENT = "placeFreeManagement",
  ADDITIONAL_INFORMATION_MANAGEMENT = "additionalInformationManagement",
  APPROVAL_NOTIFICATION = "approvalNotification",
  WORK_TIME_INFORMATION = "workTimeInformation",
  ANNUAL_STATUS = "annualStatus",
  APPROVAL_STATUS = "approvalStatus"
}

const ViewPanel = () => {
  const {
    handleViewType,
    viewType,
    signInReducer: { menuList, loginIdInfo }
  } = useContext(DetailViewContext);
  const { menu } = useTitleBarMenu({ webEtcFlag: menuList[0]?.web_etc_flag });
  return (
    <STabContainer $backGroundColor={colors.lightGrey}>
      {menu.workingTemplate && (
        <STab
          onClick={handleViewType(ViewType.WORKING_TEMPLATE)}
          isSelected={viewType === ViewType.WORKING_TEMPLATE}
        >
          <span>{uiString.workingTemplate.ko}</span>
        </STab>
      )}
      {menu.pcInfo && (
        <STab
          onClick={handleViewType(ViewType.PC)}
          isSelected={viewType === ViewType.PC}
        >
          <span>{uiString.pcInfo.ko}</span>
        </STab>
      )}
      {menu.serviceManagementInfo && (
        <STab
          onClick={handleViewType(ViewType.WORK_MANAGEMENT)}
          isSelected={viewType === ViewType.WORK_MANAGEMENT}
        >
          <span>{uiString.workingInfo.ko}</span>
        </STab>
      )}
      {menu.commutingManagementInfo && (
        <STab
          onClick={handleViewType(ViewType.COMMUTE)}
          isSelected={viewType === ViewType.COMMUTE}
        >
          <span>{uiString.commute.ko}</span>
        </STab>
      )}
      {menu.placeFreeManagement && (
        <STab
          onClick={handleViewType(ViewType.PLACE_FREE_MANAGEMENT)}
          isSelected={viewType === ViewType.PLACE_FREE_MANAGEMENT}
        >
          <span>{uiString.placeFreeManagement.ko}</span>
        </STab>
      )}
      {menu.additionalInformationManagement && (
        <STab
          onClick={handleViewType(ViewType.ADDITIONAL_INFORMATION_MANAGEMENT)}
          isSelected={viewType === ViewType.ADDITIONAL_INFORMATION_MANAGEMENT}
        >
          <span>{uiString.additionalInformationManagement.ko}</span>
        </STab>
      )}
      {menu.approvalNotification && loginIdInfo === Role.SUPER_ADMIN && (
        <STab
          onClick={handleViewType(ViewType.APPROVAL_NOTIFICATION)}
          isSelected={viewType === ViewType.APPROVAL_NOTIFICATION}
        >
          <span>{uiString.approvalNotificationManagement.ko}</span>
        </STab>
      )}
      {menu.workTimeInformation && loginIdInfo === Role.SUPER_ADMIN && (
        <STab
          onClick={handleViewType(ViewType.WORK_TIME_INFORMATION)}
          isSelected={viewType === ViewType.WORK_TIME_INFORMATION}
        >
          <span>{uiString.workTimeInformation.ko}</span>
        </STab>
      )}
      {menu.annualStatus && loginIdInfo === Role.SUPER_ADMIN && (
        <STab
          onClick={handleViewType(ViewType.ANNUAL_STATUS)}
          isSelected={viewType === ViewType.ANNUAL_STATUS}
        >
          <span>{`연차현황`}</span>
        </STab>
      )}
      {menu.approvalStatus && loginIdInfo === Role.SUPER_ADMIN && (
        <STab
          onClick={handleViewType(ViewType.APPROVAL_STATUS)}
          isSelected={viewType === ViewType.APPROVAL_STATUS}
        >
          <span>{`결재현황`}</span>
        </STab>
      )}
    </STabContainer>
  );
};

export default ViewPanel;
