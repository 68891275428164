import { Fragment } from "react";
import RightMenuButton from "../RightMenuButton";

interface IProps {
  handleInitPassword: () => Promise<void>;
}

const InitPassword = <P extends IProps>({ handleInitPassword }: P) => {
  return (
    <Fragment>
      <RightMenuButton
        title="패스워드 초기화"
        handleClick={() => handleInitPassword()}
      />
    </Fragment>
  );
};

export default InitPassword;
