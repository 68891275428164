import { useCallback, useEffect, useState } from "react";
import { FileNameDisplayDiv, ControllersDiv, ButtonContainer } from "./styled";
import FileReaderInput from "react-file-reader-input";
import uiString from "../string.json";
import { uploadUserProfile } from "../../../../Utils/commonAxiosCall";
import Button from "../../../globalComponents/Button";
import CollapsiblePanel from "../../smallcomponents/CollapsiblePanel";
import styled from "styled-components";
import basicImg from "../../../../images/basicimage.jpg";

const PictureContainer = styled.div`
  width: 65px;
  height: 65px;
`;

const Container = styled.div`
  display: flex;
`;

const ProfilePicture = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url( data:image/png+jpg;base64,${props.photo})`
      : `url(${props.uploadImg || basicImg})`};
`;

function FileUploader({ employeeId, userPic, updateUserPicture }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [photo, setPhoto] = useState("");

  const fileChangedHandler = useCallback((e, results) => {
    const maxSize = 1024 * 512;
    let userImage = results[0][1];

    if (userImage.size <= maxSize) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(userImage);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(userImage);
    }
  }, []);

  const uploadUserPic = useCallback(() => {
    const result = uploadUserProfile(
      employeeId,
      selectedFile,
      selectedFile.name
    );
    if (result) {
      updateUserPicture();
    }
  }, [employeeId, selectedFile, updateUserPicture]);

  useEffect(() => {
    if (imagePreviewUrl) {
      setPhoto(imagePreviewUrl);
    } else if (userPic && userPic.data.length !== 1) {
      const buf = Buffer.from(userPic.data);
      let base64String = buf.toString("base64");
      setPhoto(base64String);
    }
  }, [imagePreviewUrl, userPic]);

  return (
    <CollapsiblePanel title={uiString.titles.changeProfilePicture}>
      <Container>
        <PictureContainer>
          <ProfilePicture
            uploadImg={imagePreviewUrl}
            photo={imagePreviewUrl ? "" : photo}
          />
        </PictureContainer>
        <ControllersDiv>
          <FileNameDisplayDiv>
            {selectedFile
              ? selectedFile.name
              : "최대 파일 크기는 500KB를 초과할 수 없습니다."}
          </FileNameDisplayDiv>
          <ButtonContainer>
            <FileReaderInput
              as="binary"
              onChange={fileChangedHandler}
              accept=".png,.jpg"
            >
              <Button>{uiString.buttons.chooseFile}</Button>
            </FileReaderInput>
            <Button onClick={uploadUserPic}>{uiString.buttons.save}</Button>
          </ButtonContainer>
        </ControllersDiv>
      </Container>
    </CollapsiblePanel>
  );
}

export default FileUploader;
