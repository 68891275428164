import styled from "styled-components";
import mColors from "../../../style-sheet/m_colors";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../apollo/apollo-var";
import { useCallback, useEffect, useMemo, useState } from "react";
import { colors } from "../../GlobalStyle/GlobalStyle";
import { IMApprovalEntity } from "./m_approval_waiting_list";
import StyledButton from "../../shared/styled_button";
import { TextArea } from "../../basic-editor/styled";
import {
  useGetApprovalFormDetailLazyQuery,
  useRejectApprovalMutation,
  useSignApprovalMutation
} from "../../../generated/graphql";
import { MessageTypes } from "../../toast-message/toast-message";

interface IProps {
  data: IMApprovalEntity;
  setList: React.Dispatch<React.SetStateAction<IMApprovalEntity[]>>;
  handleToast: (message: string, type: MessageTypes) => void;
  employeeId: string | undefined;
  isEdit?: boolean;
}

export const M_APPROVAL_STATUS = {
  RETURN: "반려",
  APPROVAL: "승인",
  WAITING: "대기",
  IN_THE_MIDDLE_OF_RETURN: "중간 반려",
  OUTSTANDING: "미처리"
} as const;

type M_APPROVAL_STATUS =
  (typeof M_APPROVAL_STATUS)[keyof typeof M_APPROVAL_STATUS];

const Container = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex: 1;
  margin: 5px 0px;
  padding: 10px;
  background-color: ${mColors.lightGreen};
  max-height: ${props => (props.isOpen ? "" : "37px")};
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 6px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 6px;
  gap: 5px;
`;

const TopSectionContainer = styled.section`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;

const FormTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FormTypeTitle = styled.span`
  font-weight: bold;
`;

const FormTitleDetail = styled.div`
  color: ${props => props.theme.colors.darkGrey};
`;

const SubSection = styled.section`
  display: flex;
  flex: 1;
  border-left: 3px solid ${props => props.theme.colors.darkGrey};
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 77px;
  padding: 0px 5px;
`;

const Badge = styled.div<{ $bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.$bgColor ?? ""};
  color: ${mColors.lightGreen};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-transform: uppercase;
`;

const MiddleSectionContainer = styled.section`
  display: flex;
  flex: 1;
`;

const MiddleSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const Ul = styled.ul`
  margin: 0px;
  margin-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
`;

const Li = styled.li`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const LiTitle = styled.span`
  min-width: max-content;
`;

const FormTypeSpan = styled.span<{ $color?: string }>`
  padding-right: 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.$color ?? ""};
`;

const Point = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.green};
  text-indent: 22px;
  text-align: center;
  margin-right: 10px;
`;

const DescriptionContainer = styled.pre`
  display: flex;
  flex: 4;
  font-weight: normal;
  margin-top: 5px;
  margin-right: 5%;
  padding: 5px 0px;
  text-wrap: wrap;
  background-color: ${mColors.lightGreen};
`;

const FooterSectionContainer = styled.section`
  display: flex;
  flex: 1;
`;

const BtnContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  min-width: 77px;
  padding: 0px 5px;
`;

const STextArea = styled(TextArea)`
  flex: 4;
  border-radius: 6px;
  background-color: ${mColors.lightGreen};
  outline: none;
  border: 1px solid ${props => props.theme.colors.black};
  :focus {
    border: 2px solid ${props => props.theme.colors.lightGreen};
  }
`;

function MApprovalRow({
  setList,
  data,
  handleToast,
  employeeId,
  isEdit
}: IProps) {
  const FormName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [description, setDescription] = useState<string>("");
  const [approver, setApprover] = useState<string>("");

  const [getApprovalFormDetail, { data: detailData }] =
    useGetApprovalFormDetailLazyQuery({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (
          data.getApprovalFormDetail.listOfApprovalUser &&
          data.getApprovalFormDetail.listOfApprovalUser?.length > 1
        ) {
          let newApprover =
            data.getApprovalFormDetail.listOfApprovalUser[1].name;
          if (data.getApprovalFormDetail.listOfApprovalUser?.length > 2) {
            newApprover += ` 외 ${
              data.getApprovalFormDetail.listOfApprovalUser?.length - 2
            }명`;
          }
          setApprover(newApprover);
        }
        if (
          !data.getApprovalFormDetail.ok &&
          data.getApprovalFormDetail.error
        ) {
          console.log(data.getApprovalFormDetail.error);
        }
      }
    });

  const formType = useMemo(() => {
    const formIdx = data?.formIdx;
    if (formIdx) {
      return FormName.get(formIdx) ? `[${FormName.get(formIdx)}]` : "";
    }
    return "";
  }, [data.formIdx]);

  const formTypeName = useMemo(() => {
    const formIdx = data?.formIdx;
    if (formIdx) {
      return FormName.get(formIdx) ? `${FormName.get(formIdx)}` : "";
    }
    return "";
  }, [data.formIdx]);

  const approvalTitleString = useMemo(() => {
    const newApprovalTitle = data?.approvalTitle;
    if (newApprovalTitle) {
      if (newApprovalTitle.length > 10) {
        if (window.innerWidth <= 380) {
          return `${newApprovalTitle?.slice(0, 8)}...`;
        }
        return `${newApprovalTitle?.slice(0, 10)}...`;
      }
      return data.approvalTitle;
    }
    return "";
  }, [data.approvalTitle]);

  const mainStatus = useMemo(() => {
    let status = "w";
    const keys = Object.keys(M_APPROVAL_STATUS);
    switch (data.approvalStatus) {
      case keys[0]:
        status = "d";
        break;
      case keys[1]:
        status = "a";
        break;
      case keys[3]:
        status = "d";
        break;
      case keys[4]:
        status = "o";
        break;
    }
    return status;
  }, [data.approvalStatus]);

  const bgColor = useMemo(() => {
    let newBgColor = mColors.darkYellow;
    const keys = Object.keys(M_APPROVAL_STATUS);
    switch (data.approvalStatus) {
      case keys[0]:
        newBgColor = mColors.lightRed;
        break;
      case keys[1]:
        newBgColor = colors.green;
        break;
      case keys[3]:
        newBgColor = colors.darkRed;
        break;
      case keys[4]:
        newBgColor = colors.navy;
        break;
    }
    return newBgColor;
  }, [data.approvalStatus]);

  const handleSelect = useCallback(
    (id: number) => {
      setList(pre => {
        let newList = [...pre];
        newList.forEach((item, index) => {
          if (item.approvalRequestIdx === id) {
            if (item.isOpen) {
              item.isOpen = false;
            } else {
              item.isOpen = true;
            }
          } else {
            item.isOpen = false;
          }
        });
        return newList;
      });
    },
    [setList]
  );

  const [signApproval, { client }] = useSignApprovalMutation({
    onError(error) {
      console.log(error);
      handleToast("결재 승인이 실패했습니다.", MessageTypes.ERROR);
    },
    update(_, { data: result }) {
      if (result?.signApproval.ok) {
        setList(pre => {
          return pre.filter(
            item => item.approvalRequestIdx !== data?.approvalRequestIdx
          );
        });
        client.resetStore();
        handleToast("결재 승인 하였습니다.", MessageTypes.SUCCESS);
      }
      if (result?.signApproval.error) {
        handleToast(result?.signApproval.error, MessageTypes.ERROR);
      }
    }
  });

  const [rejectApproval] = useRejectApprovalMutation({
    onError(error) {
      console.log(error);
      handleToast("결재 반려가 실패했습니다.", MessageTypes.ERROR);
    },
    update(_, { data: result }) {
      if (result?.rejectApproval.ok) {
        setList(pre => {
          return pre.filter(
            item => item.approvalRequestIdx !== data?.approvalRequestIdx
          );
        });
        client.resetStore();
        handleToast("결재 반려 하였습니다.", MessageTypes.SUCCESS);
      }
      if (result?.rejectApproval.error) {
        handleToast(result?.rejectApproval.error, MessageTypes.ERROR);
      }
    }
  });

  const handleRejectApproval = useCallback(() => {
    rejectApproval({
      variables: {
        approvalRequestIdx: data.approvalRequestIdx,
        description,
        customEmployeeId: employeeId
      }
    });
  }, [rejectApproval, description, data.approvalRequestIdx, employeeId]);

  const handleSignApproval = useCallback(() => {
    signApproval({
      variables: {
        approvalRequestIdx: data.approvalRequestIdx,
        description,
        customEmployeeId: employeeId
      }
    });
  }, [signApproval, description, data.approvalRequestIdx, employeeId]);

  useEffect(() => {
    if (data.approvalRequestIdx && employeeId) {
      getApprovalFormDetail({
        variables: {
          approvalRequestIdx: data.approvalRequestIdx,
          employeeId
        }
      });
    }
  }, [data.approvalRequestIdx, employeeId]);

  return (
    <Container isOpen={data.isOpen}>
      <ContentContainer>
        <Content>
          <TopSectionContainer
            onClick={() => {
              handleSelect(data.approvalRequestIdx);
            }}
          >
            <TitleContainer>
              <FormTitle>
                <FormTypeTitle>{formType}</FormTypeTitle>
                <span>{approvalTitleString}</span>
              </FormTitle>
              <FormTitleDetail>{`${data.name ?? ""} | ${
                data.signUpDateTimeTemp
              }`}</FormTitleDetail>
            </TitleContainer>
            <SubSection>
              <Badge $bgColor={data.isOpen ? mColors.darkYellow : bgColor}>
                {data.isOpen ? "M" : mainStatus}
              </Badge>
              {data.approvalStatus && !data.isOpen
                ? M_APPROVAL_STATUS[data.approvalStatus]
                : "접기"}
            </SubSection>
          </TopSectionContainer>
          {data.isOpen && (
            <MiddleSectionContainer>
              <MiddleSectionContent>
                <Ul>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`결재양식`}
                    </LiTitle>
                    <FormTypeSpan>{formTypeName}</FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`시작일자`}
                    </LiTitle>
                    <FormTypeSpan $color={colors.green}>
                      {data?.extendStartDate ?? ""}
                    </FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`시작일시`}
                    </LiTitle>
                    <FormTypeSpan $color={colors.green}>
                      {data?.extendStartDateTime ?? ""}
                    </FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`종료일자`}
                    </LiTitle>
                    <FormTypeSpan $color={colors.darkRed}>
                      {data?.extendEndDate ?? ""}
                    </FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`종료일시`}
                    </LiTitle>
                    <FormTypeSpan $color={colors.darkRed}>
                      {data?.extendEndDateTime ?? ""}
                    </FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`기안자`}
                    </LiTitle>
                    <FormTypeSpan>{data?.name ?? ""}</FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`결재자`}
                    </LiTitle>
                    <FormTypeSpan $color={mColors.darkYellow}>
                      {approver}
                    </FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`제목`}
                    </LiTitle>
                    <FormTypeSpan>{approvalTitleString ?? ""}</FormTypeSpan>
                  </Li>
                  <Li>
                    <LiTitle>
                      <Point>●</Point>
                      {`내용`}
                    </LiTitle>
                  </Li>
                  <DescriptionContainer>
                    {data?.extendDescr ?? ""}
                  </DescriptionContainer>
                </Ul>
              </MiddleSectionContent>
              <SubSection>
                <Badge $bgColor={mColors.lightGrey}>{`c`}</Badge>
                {`내용`}
              </SubSection>
            </MiddleSectionContainer>
          )}
          {data.isOpen && (
            <FooterSectionContainer>
              {!isEdit && (
                <DescriptionContainer>{data?.resultDescr}</DescriptionContainer>
              )}
              {isEdit && (
                <STextArea
                  placeholder="사유입력"
                  onChange={event => {
                    setDescription(`${event.currentTarget.value ?? ""}`);
                  }}
                />
              )}
              {!isEdit && (
                <SubSection>
                  <Badge $bgColor={bgColor}>{mainStatus}</Badge>
                  {data.approvalStatus &&
                    M_APPROVAL_STATUS[data.approvalStatus]}
                </SubSection>
              )}
              {isEdit && (
                <BtnContainer>
                  <StyledButton
                    $maxWidth="53px"
                    onClick={event => {
                      event.stopPropagation();
                      handleSignApproval();
                    }}
                  >{`A 승인`}</StyledButton>
                  <StyledButton
                    $maxWidth="53px"
                    $backgroundColor={colors.darkRed}
                    $hoverBackgroundColor={colors.lightRed}
                    onClick={event => {
                      event.stopPropagation();
                      handleRejectApproval();
                    }}
                  >{`D 반려`}</StyledButton>
                </BtnContainer>
              )}
            </FooterSectionContainer>
          )}
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default MApprovalRow;
