export const LABELS = {
  templateTitle: "관리 이름",
  otTimeTypeCategory: "정보 구분",
  approvalType: "결재 방식",
  approvalForm: "결재 양식",
  approvalPermissions: "사용 권한",
  approvalSharing: "결재 공유",
  description: "설명",
  allUsers: "전체 사용자",
  specificUsers: "특정 사용자",
  applyToAll: "전 직원 적용",
  notUsed: "사용안함",
  used: "사용"
};

export const BUTTON_TEXT = {
  ADD: "추가",
  EDIT: "수정",
  DELETE: "삭제",
  SAVE: "저장",
  CANCEL: "취소"
};

export const CONFIRM_DIALOG_TEXT = {
  TITLE: "결재 양식 삭제",
  PARAGRAPH: "선택된 결재 양식들을 삭제하시겠습니까?",
  SUCCESS_EDIT: "성공적으로 선택된 결재 템플릿을 수정하였습니다.",
  SUCCESS_ADD: "성공적으로 결재 템플릿을 추가했습니다.",
  SUCCESS_DELETE: "성공적으로 결재 템플릿을 삭제했습니다.",
  ERROR_ADD: "템플릿을 수정하거나 추가하지 못했습니다.",
  ERROR_DELETE: "템플릿을 삭제하지 못했습니다."
};

export const WINDOW_FEATURES = "width=1030, height=650, scrollbars=yes";

export const APPROVAL_TYPE = {
  APPROVAL_LINE: "결재선",
  AUTO_APPROVAL: "자동결재"
} as const;

export type T_KEY_APPROVAL_TYPE = keyof typeof APPROVAL_TYPE;

export const DISABLE = 0;
export const ENABLE = 1;
