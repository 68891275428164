import axios from "axios";
import { WEB_SERVER_ADDR } from "../constValues";
import Auth from "../Auth/Auth";
import { workManagementAxios } from "../../types/axios";
import routes from "../routes";

const API = () =>
  axios.create({
    baseURL: `${WEB_SERVER_ADDR}`,
    headers: {
      token: Auth.getUserToken(),
      Pragma: "no-cache"
    }
  });

// User 정보
export const getUserInfosCount = (
  params: workManagementAxios.IUserInfosCount
) =>
  API().get(`${routes.apiRoutes.workHour.employeeResourceCount}`, {
    params
  });

export const getUserInfos = (params: workManagementAxios.IGetUserInfos) =>
  API().get(`${routes.apiRoutes.workHour.getLimitedEmployeeInfo}`, {
    params
  });

export const downloadUserInfosExcel = (
  params: workManagementAxios.IUserInfosCount
) =>
  API().get(`${routes.apiRoutes.workHour.employeeResource}`, {
    params
  });

// endpointofemployee 정보
export const getPublicEndpointCount = (
  params: workManagementAxios.IPublicEndpointOfEmployeeCount
) =>
  API().get(`${routes.apiRoutes.workHour.getPublicEndpointCount}`, {
    params
  });

export const getPublicEndpointInfo = (
  params: workManagementAxios.IPublicEndpointOfEmployeeInfo
) =>
  API().get(`${routes.apiRoutes.workHour.getLimitedPublicEndpointInfo}`, {
    params
  });

export const searchPublicEndpointCount = (
  params: workManagementAxios.ISearchPublicEndpointCount
) =>
  API().get(`${routes.apiRoutes.search.publicEndpointOfEmployeeCount}`, {
    params
  });

export const searchPublicEndpointInfo = (
  params: workManagementAxios.ISearchPublicEndpointInfo
) =>
  API().get(`${routes.apiRoutes.search.publicEndpointOfEmployeeInfo}`, {
    params
  });

export const getEndpointOfemployeeCount = (
  params: workManagementAxios.IEndpointOfemployeeCount
) =>
  API().get(`${routes.apiRoutes.workHour.getPcCount}`, {
    params
  });

export const getEndpointOfEmployeeInfo = (
  params: workManagementAxios.IEndPointOfEmployeeInfo
) =>
  API().get(`${routes.apiRoutes.workHour.getLimitedPcInfo}`, {
    params
  });

export const searchEndpointInfoCount = (
  params: workManagementAxios.ISearchEndpointInfoCount
) =>
  API().get(`${routes.apiRoutes.search.endpointOfEmployeeCount}`, {
    params
  });

export const searchEndpointInfo = (
  params: workManagementAxios.ISearchEndpointInfo
) =>
  API().get(`${routes.apiRoutes.search.endpointOfemployee}`, {
    params
  });

export const downloadEndpointExcel = (
  params: workManagementAxios.DownloadEndpointExcel
) =>
  API().get(`${routes.apiRoutes.workHour.endPointOfEmployee}`, {
    params
  });

// 특정기간 근무관리정보

export const getWorkHoursForTimePeriodCount = (
  params: workManagementAxios.IWorkHoursForPeriodCount
) =>
  API().get(`${routes.apiRoutes.workHour.getWorkHoursForImePeriodCount}`, {
    params
  });

export const getLimitedWorkHoursForTimePeriod = (
  params: workManagementAxios.IWorkHoursForPeriod
) =>
  API().get(`${routes.apiRoutes.workHour.getWorkHourForLimitedImePeriod}`, {
    params
  });

export const downloadWorkHoursForTimePeriodExcel = (
  params: workManagementAxios.IWorkHoursForPeriodCount
) =>
  API().get(`${routes.apiRoutes.workHour.getWorkHoursForImePeriod}`, {
    params
  });

// 출퇴근관리정보

export const getEmployeeCommuteCount = (
  params: workManagementAxios.IEmployeeCommuteCount
) =>
  API().get(`${routes.apiRoutes.workHour.tableCommuteCount}`, {
    params
  });

export const getLimitedEmployeeCommute = (
  params: workManagementAxios.IEmployeeCommute
) =>
  API().get(`${routes.apiRoutes.workHour.tableCommuteLimited}`, {
    params
  });

export const downloadEmployeeCommuteExcel = (
  params: workManagementAxios.IEmployeeCommuteCount
) =>
  API().get(`${routes.apiRoutes.workHour.tableCommute}`, {
    params
  });

export const getUserPhoneNumbers = (employeeid: string) =>
  API().get(`${routes.apiRoutes.settings.phoneNumbersUser(employeeid)}`);

export const insertOrUpdatePhoneNumbers = (
  employee_id: string,
  phoneNumbers: string
) =>
  API().post(`${routes.apiRoutes.settings.phoneNumbers}`, {
    employee_id,
    phoneNumbers
  });

export const getCalendarCount = (
  employeeId: string,
  startDate: string,
  endDate: string
) =>
  API().get(`${routes.apiRoutes.workHour.calendarCount}`, {
    params: {
      employeeId: employeeId,
      startDate,
      endDate
    }
  });

export const getCalendarInfomation = (employeeId: string, date: string) =>
  API().get(`${routes.apiRoutes.workHour.calendarInformation}`, {
    params: {
      employeeId: employeeId,
      date
    }
  });
