import AsonicTableBody from "../asonic-table-body";
import AsonicTableContainer from "../asonic-table-container";
import AsonicTableHead from "../asonic-table-head";
import AsonicTableLayout from "../asonic-table-layout";
import AsonicTableRow from "../asonic-table-row";
import { AutoSizer } from "react-virtualized";
import AsonicRenderRow, {
  IAsonicRenderRowProps,
  IAsonicRow
} from "../asonic-render-row";
import { FixedSizeList as List } from "react-window";
import ReactLoading from "react-loading";
import styled from "styled-components";
import * as ReactTable from "react-table";
import { colors } from "../../GlobalStyle/GlobalStyle";
import { FieldSort } from "../../../__generated__/globalTypes";
import AsonicTableColumn, { TMoveColumn } from "../asonic-table-column";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AsonicTableTitleBarForDashboard, {
  IAsonicTableTitleBarProps
} from "./asonic-table-title-bar-for-dash-board";
import { useMemo } from "react";
import AsonicPageController from "../asonic-page-controller";

interface IAsonicHeaderGroup<P extends {}>
  extends ReactTable.HeaderGroup<P extends object ? P : {}> {}

interface IAsonicHeaderGroups<P extends {}> extends IAsonicHeaderGroup<P> {}

interface IProps<P extends object>
  extends IAsonicTableTitleBarProps<P>,
    IAsonicRenderRowProps<P> {
  getTableProps: (
    propGetter?:
      | Partial<ReactTable.TableProps>
      | Partial<ReactTable.TableProps>[]
      | ((
          props: Partial<ReactTable.TableProps>,
          meta: ReactTable.MetaBase<any>
        ) => Partial<any> | Partial<any>[])
      | undefined
  ) => ReactTable.TableProps;
  headerGroups: IAsonicHeaderGroups<P>[];
  getTableBodyProps: () => ReactTable.TableBodyProps;
  rows: IAsonicRow<P>[];
  isLoading?: boolean;
  handleFieldSort?: (payload?: FieldSort) => void;
  fieldSort?: FieldSort;
  listOfColumnDisabled?: string[];
  moveColumn?: TMoveColumn;
  isTitleBar?: boolean;
}

const Content = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};
  align-items: ${props => (props.isLoading ? "center" : "normal")};
  flex-direction: column;
`;

function AsonicTableForDashBoard<P extends object>({
  title,
  total,
  take,
  currentPage,
  handleCurrentPage,
  handleTake,
  downloadExcel,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  handleSelectRow,
  isLoading,
  handleContextMenu,
  selectedRow,
  handleDate,
  handleFieldSort,
  fieldSort,
  listOfColumnDisabled,
  columns,
  toggleHideColumn,
  moveColumn,
  isTitleBar = true,
  handleDateWithType,
  searchType,
  handleSearchType,
  searchValue,
  handleSearchValue,
  searchValueForTime,
  handleSearchValueForTime,
  searchValueForTruthyFalsy,
  handleSearchValueForTruthyFalsy,
  isAdditionalSearchTimeValue
}: IProps<P>) {
  const totalPage = useMemo(
    () => take && total && Math.ceil(total / take),
    [take, total]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <AsonicTableLayout>
        {isTitleBar && (
          <AsonicTableTitleBarForDashboard
            total={total}
            title={title}
            downloadExcel={downloadExcel}
            handleDate={handleDate}
            columns={columns}
            toggleHideColumn={toggleHideColumn}
            handleDateWithType={handleDateWithType}
            searchType={searchType}
            handleSearchType={handleSearchType}
            searchValue={searchValue}
            handleSearchValue={handleSearchValue}
            searchValueForTime={searchValueForTime}
            handleSearchValueForTime={handleSearchValueForTime}
            searchValueForTruthyFalsy={searchValueForTruthyFalsy}
            handleSearchValueForTruthyFalsy={handleSearchValueForTruthyFalsy}
            isAdditionalSearchTimeValue={isAdditionalSearchTimeValue}
          />
        )}
        <Content isLoading={isLoading}>
          {isLoading ? (
            <ReactLoading
              type="spokes"
              color={colors.green}
              height={200}
              width={170}
            />
          ) : (
            <AsonicTableContainer {...getTableProps()}>
              <AsonicTableHead>
                {headerGroups.map(headerGroup => (
                  <AsonicTableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <AsonicTableColumn
                        key={column.id}
                        column={column}
                        index={index}
                        fieldSort={fieldSort}
                        handleFieldSort={handleFieldSort}
                        listOfColumnDisabled={listOfColumnDisabled}
                        moveColumn={moveColumn}
                        title={title}
                      />
                    ))}
                  </AsonicTableRow>
                ))}
              </AsonicTableHead>
              <AsonicTableBody {...getTableBodyProps()}>
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <List
                        height={height}
                        itemCount={rows.length}
                        itemData={rows}
                        itemSize={30}
                        width={width}
                      >
                        {AsonicRenderRow({
                          prepareRow: prepareRow,
                          handleSelectRow: handleSelectRow,
                          handleContextMenu: handleContextMenu,
                          selectedRow: selectedRow
                        })}
                      </List>
                    );
                  }}
                </AutoSizer>
              </AsonicTableBody>
            </AsonicTableContainer>
          )}
          {totalPage !== undefined &&
            currentPage &&
            handleCurrentPage &&
            take &&
            handleTake && (
              <AsonicPageController
                totalPage={totalPage}
                currentPage={currentPage}
                handleCurrentPage={handleCurrentPage}
                take={take}
                handleTake={handleTake}
              />
            )}
        </Content>
      </AsonicTableLayout>
    </DndProvider>
  );
}

export default AsonicTableForDashBoard;
