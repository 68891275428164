import { useState } from "react";

const useIsOpened = (item, parentId) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleOpenItem = () => {
    if (isOpened && item.parentdepartment_id === parentId) {
      setIsOpened(false);
    } else if (item.parentdepartment_id === parentId) {
      setIsOpened(true);
    }
  };

  return { isOpened, handleOpenItem };
};

export default useIsOpened;
