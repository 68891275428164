const items = {
  home: {
    title: "홈",
    icon: "far fa-calendar-alt fa-2x",
    path: "/m/mainview/home"
  },
  approval: {
    title: "결재 처리 조회",
    icon: "fas fa-tasks fa-2x",
    path: "/m/mainview/approval",
    backgroundColor: "#3D81E7"
  },
  request: {
    title: "결재 신청 조회",
    icon: "fas fa-list-ul fa-2x",
    path: "/m/mainview/request",
    backgroundColor: "#55BF8C"
  },
  settings: {
    title: "환경 설정",
    icon: "fas fa-cogs fa-2x",
    path: "/m/mainview/settings",
    backgroundColor: "#cc6262"
  },
  logOut: {
    title: "로그아웃",
    redirect: "/",
    icon: "fas fa-sign-out-alt fa-2x",
    path: "/mainview/logout"
  },
  des: {
    title: "데스크탑모드",
    icon: "fas fa-desktop fa-2x",
    path: "/mainview/desktop"
  }
};

export default items;
