import styled from "styled-components";

const CompactTableHeader = styled.div<{ isOver?: boolean }>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.4rem 0px;
  cursor: grab;
  background-color: ${props => {
    if (props.isOver === false) {
      return props.theme.colors.tomato;
    }
  }};
  :last-child {
    flex: 1;
    margin-right: 17px;
  }
`;

export default CompactTableHeader;
