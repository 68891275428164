import styled from "styled-components";
import useTimeList from "../../../../../hooks/use_time_list/use_time_list";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import StyleInput from "../../../../inputs/style-input";
import { Day_Type, Work_Hours_Type } from "../../../../../generated/graphql";
import { IEachWeekWS } from "./each_week_w_s";
import { WS_STORE } from "../../../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import { FlexibleWSStore } from "../../work_time_tab";
import DayOfWeek from "./day_of_week";
import useTimeControl from "../../../../../hooks/use_time_control/use_time_control";
import moment from "moment";

interface IProps {
  workTimeSettingType: Work_Hours_Type;
  data: IEachWeekWS;
  dayOfWeekList: Day_Type[];
}

interface IIsDisabled {
  settingTime: boolean;
  SETime: boolean;
  breakTime: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export type IDayWorkHour = {
  monday?: moment.Duration;
  tuesday?: moment.Duration;
  wednesday?: moment.Duration;
  thursday?: moment.Duration;
  friday?: moment.Duration;
  saturday?: moment.Duration;
  sunday?: moment.Duration;
};

const Container = styled.div<{ day: Day_Type }>`
  display: grid;
  flex: 1;
  grid-template-columns: 50px 50px repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas: ${props => {
    let row =
      "week type setting monday tuesday wednesday thursday friday saturday sunday";
    // 컨셉 변경으로 인한 요일변경 기능 보류(시작기준요일 )
    // switch (props.day) {
    //   case Day_Type.Mon:
    //     row =
    //       "week type setting monday tuesday wednesday thursday friday saturday sunday";
    //     break;
    //   case Day_Type.Tue:
    //     row =
    //       "week type setting tuesday wednesday thursday friday saturday sunday monday";
    //     break;
    //   case Day_Type.Wed:
    //     row =
    //       "week type setting wednesday thursday friday saturday sunday monday tuesday";
    //     break;
    //   case Day_Type.Thr:
    //     row =
    //       "week type setting thursday friday saturday sunday monday tuesday wednesday";
    //     break;
    //   case Day_Type.Fri:
    //     row =
    //       "week type setting friday saturday sunday monday tuesday wednesday thursday";
    //     break;
    //   case Day_Type.Sat:
    //     row =
    //       "week type setting saturday sunday monday tuesday wednesday thursday friday";
    //     break;
    //   default:
    //     row =
    //       "week type setting sunday monday tuesday wednesday thursday friday saturday";
    // }
    return `"${row}"
    "${row}"
    "${row}"
    "${row}"`;
  }};
`;

const Week = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  grid-area: week;
  background-color: ${props => props.theme.colors.frameGrey};
`;

const DisplayWeek = styled.div``;

const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayHour = styled.div`
  word-wrap: break-word;
  max-width: 25px;
  color: ${props => props.theme.colors.green};
  font-weight: bold;
  margin: 0px 2px;
`;

const Type = styled(Week)`
  grid-area: type;
`;

const BoldSpan = styled.pre`
  font-weight: bold;
  margin: 0px;
  :not(:nth-child(2)) {
    margin: 5px 0px 5px 0px;
  }
`;

const SettingSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  grid-area: setting;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InputContainer = styled(SelectContainer)``;

const TimeSelect = styled.select``;

const DisplaySpan = styled.span<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  grid-area: workTime;
  color: ${props =>
    props.disabled ? props.theme.colors.grey : props.theme.colors.black};
  height: 25px;
`;

function EachWeekRow({ workTimeSettingType, data, dayOfWeekList }: IProps) {
  const { WEEK, MON, TUE, WED, THR, FRI, SAT, SUN, weekSequence } = data;
  const { handleWeekWH } = useContext(FlexibleWSStore);
  const { day } = useContext(WS_STORE);
  const [totalHour, setTotalHour] = useState<string>("");
  const [workHour, setWorkHour] = useState(WEEK?.workHour);
  const [startTime, setStartTime] = useState(WEEK?.startTime);
  const [endTime, setEndTime] = useState(WEEK?.endTime);
  const [startBreakTime, setStartBreakTime] = useState(WEEK?.startBreakTime);
  const [endBreakTime, setEndBreakTime] = useState(WEEK?.endBreakTime);
  const { timeController, handleWorkHour, addTime, makeDuration } =
    useTimeControl();

  const [dayWorkHour, setDayWorkHour] = useState<IDayWorkHour>({});

  const handleDayWorkHour = useCallback(
    ({
      workHour,
      type
    }: {
      workHour: moment.Duration;
      type: keyof IDayWorkHour;
    }) => {
      switch (type) {
        case "monday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.monday !== workHour) {
              newDayWorkHour.monday = workHour;
            }
            return newDayWorkHour;
          });
          break;
        case "tuesday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.tuesday !== workHour) {
              newDayWorkHour.tuesday = workHour;
            }
            return newDayWorkHour;
          });
          break;
        case "wednesday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.tuesday !== workHour) {
              newDayWorkHour.tuesday = workHour;
            }
            return newDayWorkHour;
          });
          break;
        case "thursday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.tuesday !== workHour) {
              newDayWorkHour.tuesday = workHour;
            }
            return newDayWorkHour;
          });
          break;
        case "friday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.tuesday !== workHour) {
              newDayWorkHour.tuesday = workHour;
            }
            return newDayWorkHour;
          });
          break;
        case "saturday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.tuesday !== workHour) {
              newDayWorkHour.tuesday = workHour;
            }
            return newDayWorkHour;
          });
          break;
        case "sunday":
          setDayWorkHour(dayWorkHour => {
            const newDayWorkHour = { ...dayWorkHour };
            if (dayWorkHour.tuesday !== workHour) {
              newDayWorkHour.tuesday = workHour;
            }
            return newDayWorkHour;
          });
          break;
      }
    },
    []
  );

  const [isDisable, setIsDisable] = useState<IIsDisabled>({
    settingTime: false,
    SETime: false,
    breakTime: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });

  const { workHourList } = useTimeList();

  useLayoutEffect(() => {
    switch (workTimeSettingType) {
      case Work_Hours_Type.EachWeekWorkTime:
        setIsDisable(state => {
          let newState = { ...state };
          newState.SETime = true;
          newState.breakTime = true;
          newState.monday = true;
          newState.tuesday = true;
          newState.wednesday = true;
          newState.thursday = true;
          newState.friday = true;
          newState.saturday = true;
          newState.sunday = true;
          return newState;
        });
        break;
      case Work_Hours_Type.EachWeekTotalAndQuittingTime:
        setIsDisable(state => {
          let newState = { ...state };
          newState.settingTime = true;
          newState.monday = true;
          newState.tuesday = true;
          newState.wednesday = true;
          newState.thursday = true;
          newState.friday = true;
          newState.saturday = true;
          newState.sunday = true;
          newState.SETime = false;
          return newState;
        });
        break;
      case Work_Hours_Type.EachDayWorkTime:
        setIsDisable(state => {
          let newState = { ...state };
          newState.settingTime = true;
          newState.SETime = true;
          newState.breakTime = true;
          newState.monday = dayOfWeekList.includes(Day_Type.Mon) ? false : true;
          newState.tuesday = dayOfWeekList.includes(Day_Type.Tue)
            ? false
            : true;
          newState.wednesday = dayOfWeekList.includes(Day_Type.Wed)
            ? false
            : true;
          newState.thursday = dayOfWeekList.includes(Day_Type.Thr)
            ? false
            : true;
          newState.friday = dayOfWeekList.includes(Day_Type.Fri) ? false : true;
          newState.saturday = dayOfWeekList.includes(Day_Type.Sat)
            ? false
            : true;
          newState.sunday = dayOfWeekList.includes(Day_Type.Sun) ? false : true;
          return newState;
        });
        break;
      case Work_Hours_Type.EachDayTotalAndQuittingTime:
        setIsDisable(state => {
          let newState = { ...state };
          newState.settingTime = true;
          newState.SETime = true;
          newState.breakTime = true;
          newState.monday = dayOfWeekList.includes(Day_Type.Mon) ? false : true;
          newState.tuesday = dayOfWeekList.includes(Day_Type.Tue)
            ? false
            : true;
          newState.wednesday = dayOfWeekList.includes(Day_Type.Wed)
            ? false
            : true;
          newState.thursday = dayOfWeekList.includes(Day_Type.Thr)
            ? false
            : true;
          newState.friday = dayOfWeekList.includes(Day_Type.Fri) ? false : true;
          newState.saturday = dayOfWeekList.includes(Day_Type.Sat)
            ? false
            : true;
          newState.sunday = dayOfWeekList.includes(Day_Type.Sun) ? false : true;
          return newState;
        });
        break;
    }
  }, [workTimeSettingType, data, dayOfWeekList]);

  useEffect(() => {
    if (startTime && endTime && startBreakTime && endBreakTime) {
      const newDuration = makeDuration(
        startTime,
        endTime,
        startBreakTime,
        endBreakTime
      );
      let newDayWorkHour = {
        monday: newDuration,
        tuesday: newDuration,
        wednesday: newDuration,
        thursday: newDuration,
        friday: newDuration,
        saturday: newDuration,
        sunday: newDuration
      };
      if (MON) {
        newDayWorkHour.monday = makeDuration(
          MON.startTime,
          MON.endTime,
          MON.startBreakTime,
          MON.endBreakTime
        );
      }
      if (TUE) {
        newDayWorkHour.tuesday = makeDuration(
          TUE.startTime,
          TUE.endTime,
          TUE.startBreakTime,
          TUE.endBreakTime
        );
      }
      if (WED) {
        newDayWorkHour.wednesday = makeDuration(
          WED.startTime,
          WED.endTime,
          WED.startBreakTime,
          WED.endBreakTime
        );
      }
      if (THR) {
        newDayWorkHour.thursday = makeDuration(
          THR.startTime,
          THR.endTime,
          THR.startBreakTime,
          THR.endBreakTime
        );
      }
      if (FRI) {
        newDayWorkHour.friday = makeDuration(
          FRI.startTime,
          FRI.endTime,
          FRI.startBreakTime,
          FRI.endBreakTime
        );
      }
      if (SAT) {
        newDayWorkHour.saturday = makeDuration(
          SAT.startTime,
          SAT.endTime,
          SAT.startBreakTime,
          SAT.endBreakTime
        );
      }
      if (SUN) {
        newDayWorkHour.sunday = makeDuration(
          SUN.startTime,
          SUN.endTime,
          SUN.startBreakTime,
          SUN.endBreakTime
        );
      }
      setDayWorkHour(newDayWorkHour);
    }
  }, [
    startTime,
    endTime,
    startBreakTime,
    endBreakTime,
    makeDuration,
    MON,
    TUE,
    WED,
    THR,
    FRI,
    SAT,
    SUN
  ]);

  useLayoutEffect(() => {
    let total = 0;
    const totalDuration = moment.duration(0);
    if (
      workTimeSettingType === Work_Hours_Type.EachWeekTotalAndQuittingTime ||
      workTimeSettingType === Work_Hours_Type.EachWeekWorkTime
    ) {
      if (dayOfWeekList) {
        dayOfWeekList.forEach(_ => {
          if (dayWorkHour.monday) {
            totalDuration.add(dayWorkHour.monday);
            total += Math.floor(dayWorkHour.monday?.asHours());
          }
        });
      }
    } else {
      if (dayOfWeekList) {
        dayOfWeekList.forEach(item => {
          if (item === Day_Type.Mon && dayWorkHour.monday) {
            totalDuration.add(dayWorkHour.monday);
            total += Math.floor(dayWorkHour.monday?.asHours());
          }
          if (item === Day_Type.Tue && dayWorkHour.tuesday) {
            totalDuration.add(dayWorkHour.tuesday);
            total += Math.floor(dayWorkHour.tuesday.asHours());
          }
          if (item === Day_Type.Wed && dayWorkHour.wednesday) {
            totalDuration.add(dayWorkHour.wednesday);
            total += Math.floor(dayWorkHour.wednesday.asHours());
          }
          if (item === Day_Type.Thr && dayWorkHour.thursday) {
            totalDuration.add(dayWorkHour.thursday);
            total += Math.floor(dayWorkHour.thursday.asHours());
          }
          if (item === Day_Type.Fri && dayWorkHour.friday) {
            totalDuration.add(dayWorkHour.friday);
            total += Math.floor(dayWorkHour.friday.asHours());
          }
          if (item === Day_Type.Sat && dayWorkHour.saturday) {
            totalDuration.add(dayWorkHour.saturday);
            total += Math.floor(dayWorkHour.saturday.asHours());
          }
          if (item === Day_Type.Sun && dayWorkHour.sunday) {
            totalDuration.add(dayWorkHour.sunday);
            total += Math.floor(dayWorkHour.sunday.asHours());
          }
        });
      }
    }
    handleWeekWH({
      workHour: total,
      weekSequence: data?.weekSequence
    });
    const hours = totalDuration.asHours();
    let minutes = totalDuration.minutes();

    setTotalHour(`${Math.floor(hours)}h${minutes}m`);
  }, [
    dayWorkHour,
    handleWeekWH,
    weekSequence,
    dayOfWeekList,
    workTimeSettingType
  ]);

  return (
    <Container day={day}>
      <Week>
        <DisplayWeek>{`${weekSequence}주`}</DisplayWeek>
        <DisplayContainer>
          <span>{`[`}</span>
          <DisplayHour>
            <span>{totalHour}</span>
          </DisplayHour>
          <span>{`]`}</span>
        </DisplayContainer>
      </Week>
      <Type>
        <BoldSpan>{`근무시간`}</BoldSpan>
        <BoldSpan>{`출/퇴근
시간`}</BoldSpan>
        <BoldSpan>{`휴게시간`}</BoldSpan>
      </Type>
      <SettingSection>
        <SelectContainer>
          <TimeSelect
            id="setting"
            disabled={isDisable.settingTime}
            value={workHour}
            onChange={event => {
              const newWorkHour = parseInt(event.currentTarget.value);
              setEndTime(
                addTime({
                  startTime,
                  startBreakTime,
                  endBreakTime,
                  workHour: newWorkHour
                })
              );
              setWorkHour(newWorkHour);
            }}
          >
            {workHourList.map((item, index) => (
              <option
                value={item}
                key={`${weekSequence}-BASIC.workHour-${index}`}
              >
                {item}
              </option>
            ))}
          </TimeSelect>
          <label htmlFor="setting">{"시간"}</label>
        </SelectContainer>
        <InputContainer>
          <StyleInput
            minWidth="35px"
            maxWidth="35px"
            disabled={isDisable.SETime}
            value={startTime}
            onChange={event => {
              setStartTime(event.currentTarget.value);
            }}
            onBlur={event => {
              const time = timeController({ time: event.currentTarget.value });
              setWorkHour(
                handleWorkHour(time, endTime, startBreakTime, endBreakTime)
              );
              setStartTime(time);
            }}
          />
          <DisplaySpan disabled={isDisable.SETime}>{`~`}</DisplaySpan>
          <StyleInput
            minWidth="35px"
            maxWidth="35px"
            disabled={isDisable.SETime}
            value={endTime}
            onChange={event => {
              setEndTime(event.currentTarget.value);
            }}
            onBlur={event => {
              const time = timeController({ time: event.currentTarget.value });
              setWorkHour(
                handleWorkHour(startTime, time, startBreakTime, endBreakTime)
              );
              setEndTime(time);
            }}
          />
        </InputContainer>
        <InputContainer>
          <StyleInput
            minWidth="35px"
            maxWidth="35px"
            disabled={isDisable.breakTime}
            value={startBreakTime}
            onChange={event => {
              setStartBreakTime(event.currentTarget.value);
            }}
            onBlur={event => {
              const time = timeController({ time: event.currentTarget.value });
              setWorkHour(
                handleWorkHour(startTime, endTime, time, endBreakTime)
              );
              setStartBreakTime(time);
            }}
          />
          <DisplaySpan disabled={isDisable.breakTime}>{`~`}</DisplaySpan>
          <StyleInput
            minWidth="35px"
            maxWidth="35px"
            disabled={isDisable.breakTime}
            value={endBreakTime}
            onChange={event => {
              setEndBreakTime(event.currentTarget.value);
            }}
            onBlur={event => {
              const time = timeController({ time: event.currentTarget.value });
              setWorkHour(
                handleWorkHour(startTime, endTime, startBreakTime, time)
              );
              setEndBreakTime(time);
            }}
          />
        </InputContainer>
      </SettingSection>
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={MON}
        isDay={isDisable.monday}
        dayType="monday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={TUE}
        isDay={isDisable.tuesday}
        dayType="tuesday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={WED}
        isDay={isDisable.wednesday}
        dayType="wednesday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={THR}
        isDay={isDisable.thursday}
        dayType="thursday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={FRI}
        isDay={isDisable.friday}
        dayType="friday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={SAT}
        isDay={isDisable.saturday}
        dayType="saturday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
      <DayOfWeek
        workTimeSettingType={workTimeSettingType}
        day={SUN}
        isDay={isDisable.sunday}
        dayType="sunday"
        handleDayWorkHour={handleDayWorkHour}
        isDisableWeekST={!isDisable.settingTime || !isDisable.SETime}
        stWorkHour={workHour}
        stStartTime={startTime}
        stEndTime={endTime}
        stStartBreakTime={startBreakTime}
        stEndBreakTime={endBreakTime}
      />
    </Container>
  );
}

export default memo(EachWeekRow);
