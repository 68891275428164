import { useCallback, useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../../../types/reducers";
import approvalManagementApi from "../../../../api/approval-management-api";
import {
  Is_Sign_Alarm_Type,
  Is_Sign_Password,
  SignType,
  useGetApprovalPasswordAndSignSettingInfoLazyQuery,
  useSaveApprovalPasswordAndSignSettingMutation
} from "../../../../generated/graphql";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import tokenMaker from "../../../../Utils/tokenMaker";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import Button from "../../../globalComponents/Button";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import ApprovalDetailSetting from "./approval-detail-setting";
import ApprovalSignSetting from "./approval-sign-setting";

export interface IApprovalSettingFormValue {
  password: string;
  isSignPassword: Is_Sign_Password;
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  signType: SignType;
  signText: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
`;

const Content = styled.form`
  display: flex;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: 10px;
  height: 60%;
  min-height: 400px;
  gap: 10px;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  min-width: 983px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function ApprovalManagementSetting() {
  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isOpenToastMessage,
    handleIsOpen: handleIsOpenToastMessage,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [previewURLForSave, setPreviewURLForSave] = useState<
    string | null | undefined
  >();
  const [selectedFile, setSelectedFile] = useState<File>();
  const handleSelectFile = useCallback((file: File) => {
    setSelectedFile(file);
  }, []);

  const handlePreviewURLForSave = useCallback((image: string) => {
    setPreviewURLForSave(image);
  }, []);

  const [signText, setSignText] = useState<string | undefined>();

  const handleSignText = useCallback((text: string) => {
    setSignText(text);
  }, []);

  const { handleSubmit, setValue, control } =
    useForm<IApprovalSettingFormValue>();

  const { field: passwordField } = useController({
    name: "password",
    defaultValue: "",
    control
  });
  const { field: isSignPasswordField } = useController({
    name: "isSignPassword",
    defaultValue: Is_Sign_Password.UnUsed,
    control
  });
  const { field: isSignEmailAlarmField } = useController({
    name: "isSignEmailAlarm",
    defaultValue: Is_Sign_Alarm_Type.UnUsed,
    control
  });
  const { field: isSignMobileAlarmField } = useController({
    name: "isSignMobileAlarm",
    defaultValue: Is_Sign_Alarm_Type.UnUsed,
    control
  });
  const { field: signTextField } = useController({
    name: "signText",
    defaultValue: "",
    control
  });
  const { field: signTypeField } = useController({
    name: "signType",
    control,
    rules: { required: true }
  });

  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);
  const [getApprovalPasswordAndSignSettingInfo, { data }] =
    useGetApprovalPasswordAndSignSettingInfoLazyQuery();
  const [useSaveApprovalPasswordAndSignSetting] =
    useSaveApprovalPasswordAndSignSettingMutation({
      onError(error) {
        console.log(error);
        handleToast("결재설정 저장을 하지 못했습니다.", MessageTypes.ERROR);
      },
      update(_, { data }) {
        if (data?.saveApprovalPasswordAndSignSetting.ok) {
          handleToast(
            "성공적으로 결재설정을 저장하셨습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.saveApprovalPasswordAndSignSetting.error) {
          handleToast(
            data?.saveApprovalPasswordAndSignSetting.error,
            MessageTypes.ERROR
          );
        }
      }
    });

  const handleSave = useCallback(
    (data: IApprovalSettingFormValue) => {
      const signPassword = tokenMaker(data.password);
      useSaveApprovalPasswordAndSignSetting({
        variables: {
          ...data,
          signPassword,
          isSignMobileAlarm: data.isSignMobileAlarm,
          employeeId: employee_id
        }
      });
      if (data.signType === SignType.Image && selectedFile && employee_id) {
        const signImage = new FormData();
        signImage.append("sign", selectedFile, selectedFile.name);
        const params = {
          employeeId: employee_id,
          signImage
        };
        approvalManagementApi.approvalSaveSignImage(params);
      } else if (previewURLForSave) {
        const signImage = new FormData();
        const buffer = Buffer.from(previewURLForSave, "base64");
        const blob = new Blob([buffer]);
        signImage.append("sign", blob, "sign");
        const params = {
          employeeId: employee_id,
          signImage
        };
        approvalManagementApi.approvalSaveSignImage(params);
      }
      handleIsOpen(false);
    },
    [
      handleIsOpen,
      useSaveApprovalPasswordAndSignSetting,
      employee_id,
      selectedFile,
      previewURLForSave
    ]
  );

  useEffect(() => {
    handleConfirmMessage({
      title: "확인",
      p: "설정 내역을 저장하시겠습니까?",
      messageTypes: MessageTypes.SUCCESS
    });
  }, [handleConfirmMessage]);

  useEffect(() => {
    if (employee_id) {
      getApprovalPasswordAndSignSettingInfo({
        variables: {
          employeeId: employee_id
        }
      });
    }
  }, [getApprovalPasswordAndSignSettingInfo, employee_id]);

  useEffect(() => {
    if (data?.getApprovalPasswordAndSignSettingInfo.info) {
      const { info } = data?.getApprovalPasswordAndSignSettingInfo;
      setValue("password", info.signPassword);
      setValue("isSignPassword", info.isSignPassword);
      setValue("isSignEmailAlarm", info.isSignEmailAlarm);
      setValue("isSignMobileAlarm", info.isSignMobileAlarm);
      setValue("isSignPassword", info.isSignPassword);
      setValue("signType", info.signType);
      setValue("signText", info.signText ?? "");
      setPreviewURLForSave(info.signImage);
      handleSignText(info.signText ?? "");
    }
  }, [data, handleSignText]);
  return (
    <Container>
      <Content>
        <ApprovalDetailSetting
          passwordField={passwordField}
          isSignPasswordField={isSignPasswordField}
          isSignEmailAlarmField={isSignEmailAlarmField}
          isSignMobileAlarmField={isSignMobileAlarmField}
        />
        <ApprovalSignSetting
          previewURLForSave={previewURLForSave}
          handlePreviewURLForSave={handlePreviewURLForSave}
          signTextField={signTextField}
          signTypeField={signTypeField}
          signText={signText}
          handleSignText={handleSignText}
          handleSelectFile={handleSelectFile}
        />
        <ButtonContainer>
          <Button
            onClick={event => {
              event.preventDefault();
              handleIsOpen(true);
            }}
          >
            저장
          </Button>
        </ButtonContainer>
        {isOpen && (
          <ConfirmDialog
            confirmTitle={confirmTitle}
            confirmParagraph={confirmParagraph}
            confirmType={confirmType}
            messageTypes={MessageTypes.SUCCESS}
            handleIsOpen={handleIsOpen}
            handleConfirm={handleSubmit(handleSave)}
          />
        )}
      </Content>
      <ToastMessage
        isOpen={isOpenToastMessage}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpenToastMessage}
      />
    </Container>
  );
}

export default ApprovalManagementSetting;
