import { useState } from "react";
import moment from "moment";
import { Input } from "../../../../components/shared";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.veryDarkBlue};
  font-weight: bold;
  min-width: max-content;
`;

const RequestDate = ({ label, startDate, endDate, isStart, onChange }) => {
  const [start, setStart] = useState(
    startDate
      ? moment(startDate).format("YYYY[-]MM[-]DD")
      : moment().subtract(7, "days").format("YYYY[-]MM[-]DD")
  );
  const [end, setEnd] = useState(
    endDate
      ? moment(endDate).format("YYYY[-]MM[-]DD")
      : moment().format("YYYY[-]MM[-]DD")
  );

  const updateDate = event => {
    if (isStart) {
      setStart(event.target.value);
    } else {
      setEnd(event.target.value);
    }
    onChange(event);
  };
  return (
    <Container>
      <Label htmlFor={label}>{label}</Label>
      <Input
        onChange={updateDate}
        defaultValue={isStart ? start : end}
        id={label}
        type="date"
      />
    </Container>
  );
};

export default RequestDate;
