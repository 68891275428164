import { Component } from "react";
import { Container, Select, Typography } from "./styled";

class CategorySelection extends Component {
  state = {
    overtimeCategory: ""
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.props.updateCategoryId(event.target.value);
  };

  componentDidMount() {
    if (
      this.props.categorySelectionReducer &&
      this.props.categorySelectionReducer.categories.length > 0
    ) {
      this.props.updateCategoryId(
        this.props.categorySelectionReducer.categories[0].category_id
      );
    }
  }

  render() {
    const { categorySelectionReducer, showAll, controlTitle, customWidth } =
      this.props;

    return (
      <Container customWidth={customWidth}>
        {controlTitle && <Typography>{controlTitle}</Typography>}
        <Select
          value={this.state.overtimeCategory}
          onChange={this.handleChange("overtimeCategory")}
          name="overtimeCategory"
        >
          {showAll && (
            <option key={0} value={0}>
              전체
            </option>
          )}
          {categorySelectionReducer &&
            categorySelectionReducer.categories.map(v => (
              <option key={v.category_id} value={v.category_id}>
                {v.name}
              </option>
            ))}
        </Select>
      </Container>
    );
  }
}

export default CategorySelection;
