import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import listOfApolloVar from "../../../apollo/apollo-var";
import {
  getUserPhoneNumbers,
  insertOrUpdatePhoneNumbers
} from "../../../Utils/workManagementAxiosCall";
import PhoneNumberRegisterDialog from "./PhoneNumberRegisterDialog";

interface Props {
  handlePhoneNumberRegisterDialog: (isTrue: boolean) => void;
  authCheck: number;
}

export interface NewProps extends Props {
  phoneNumbers: string[];
  handlePhoneNumbers: (
    type: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}
const PhoneNumberRegisterDialogContainer = (props: Props) => {
  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const userId = React.useMemo(
    () => listOfEmployeeId[listOfEmployeeId.length - 1],
    [listOfEmployeeId]
  );
  const [phoneNumbers, setPhoneNumbers] = React.useState<string[]>([
    "",
    "",
    ""
  ]);
  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const userPhoneNumbers: string = phoneNumbers.join(",");
      insertOrUpdatePhoneNumbers(userId, userPhoneNumbers);
      props.handlePhoneNumberRegisterDialog(false);
    },
    [phoneNumbers, userId, props]
  );

  const handlePhoneNumbers = React.useCallback(
    (type: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const temporary = [...phoneNumbers];
      switch (type) {
        case 0:
          temporary[type] = event.target.value || "";
          setPhoneNumbers(temporary);
          break;
        case 1:
          temporary[type] = event.target.value || "";
          setPhoneNumbers(temporary);
          break;
        case 2:
          temporary[type] = event.target.value || "";
          setPhoneNumbers(temporary);
          break;
        default:
          break;
      }
    },
    [phoneNumbers]
  );

  React.useEffect(() => {
    if (userId) {
      getUserPhoneNumbers(userId)
        .then(res => {
          if (res.data.app_phonenum) {
            const updateNumbers = res.data.app_phonenum.split(",");
            setPhoneNumbers(updateNumbers);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [userId]);

  const newProps: NewProps = {
    ...props,
    phoneNumbers,
    handlePhoneNumbers,
    handleSubmit
  };
  return <PhoneNumberRegisterDialog {...newProps} />;
};

export default PhoneNumberRegisterDialogContainer;
