import {
  Container,
  CheckBox,
  CheckMark,
  ContentContainer,
  InfoTypeContainer,
  InfoItem,
  InfoTitle,
  CheckBoxContainer,
  InfoPeriodContainer,
  CheckBoxRadio,
  CheckMarkRadio,
  DateContainer,
  Period,
  PeriodDescription,
  TotalPersonnelContainer,
  TotalPersonnelDescription,
  PersonnelInfoItem,
  PersonnelContainer,
  PersonnelInput,
  PersonnelDescription
} from "./styled";
import { TotalProps } from "../ChartDialogContainer";

import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import Button from "../../../globalComponents/Button";
import ToastMessage from "../../../toast-message/toast-message";
import { useMemo } from "react";

const ChartDialog = (props: TotalProps) => {
  const {
    handleChartDialog,
    chartInfoTypes,
    chartInfoPeriods,
    selectedPeriodType,
    handlePeriodType,
    checkedPeriodType,
    listOfEmployeeId,
    date,
    handleDate,
    handleSelectedInfoTypes,
    handleChartData,
    handleUserRank,
    topUsers,
    lowerUsers,
    handleSelectedSortedUserType,
    selectedSortedUserType,
    selectedInfoTypes,
    isOpen,
    handleIsOpen,
    message,
    toastMessageType
  } = props;
  const minHeight = useMemo(() => {
    if (listOfEmployeeId.length > 1) {
      return "35%";
    }
    return "25%";
  }, [listOfEmployeeId]);
  return (
    <Container>
      <AsonicDialog
        title="그래프 보기"
        handleClose={handleChartDialog}
        width="25%"
        minWidth="20%"
        minHeight={minHeight}
        height={minHeight}
      >
        <ContentContainer>
          <InfoTypeContainer>
            {chartInfoTypes.map((item: string, index: number) => (
              <InfoItem key={index}>
                <CheckBoxContainer>
                  <CheckBox
                    type="checkbox"
                    id={item}
                    name={item}
                    value={index + 1}
                    checked={selectedInfoTypes.includes(index + 1)}
                    onChange={handleSelectedInfoTypes}
                  />
                  <CheckMark />
                </CheckBoxContainer>
                <InfoTitle htmlFor={item}>{item}</InfoTitle>
              </InfoItem>
            ))}
          </InfoTypeContainer>
          <InfoPeriodContainer>
            {chartInfoPeriods.map((type: string, index: number) => (
              <InfoItem key={index}>
                <CheckBoxContainer>
                  <CheckBoxRadio
                    type="radio"
                    name="period"
                    value={type}
                    id={type}
                    checked={type === checkedPeriodType ? true : false}
                    onChange={handlePeriodType}
                  />
                  <CheckMarkRadio />
                </CheckBoxContainer>
                <InfoTitle htmlFor={type}>{type}</InfoTitle>
              </InfoItem>
            ))}
          </InfoPeriodContainer>
          <DateContainer>
            <Period
              type={selectedPeriodType}
              value={date}
              onChange={handleDate}
            />
            <PeriodDescription>{checkedPeriodType}</PeriodDescription>
          </DateContainer>
          {listOfEmployeeId.length > 1 && (
            <TotalPersonnelContainer>
              <TotalPersonnelDescription>{`총${listOfEmployeeId.length}명`}</TotalPersonnelDescription>
              <PersonnelContainer>
                <PersonnelInfoItem>
                  <CheckBoxContainer>
                    <CheckBox
                      type="checkbox"
                      id="상위"
                      value="top"
                      onChange={handleSelectedSortedUserType}
                      disabled={selectedSortedUserType.total}
                      checked={selectedSortedUserType.top}
                    />
                    <CheckMark />
                  </CheckBoxContainer>
                  <InfoTitle htmlFor="상위">상위</InfoTitle>
                  <PersonnelInput
                    type="number"
                    onChange={handleUserRank("top")}
                    value={topUsers}
                    disabled={selectedSortedUserType.total}
                  />
                  <PersonnelDescription>명</PersonnelDescription>
                </PersonnelInfoItem>
                <PersonnelInfoItem>
                  <CheckBoxContainer>
                    <CheckBox
                      type="checkbox"
                      id="하위"
                      value="low"
                      onChange={handleSelectedSortedUserType}
                      disabled={selectedSortedUserType.total}
                      checked={selectedSortedUserType.low}
                    />
                    <CheckMark />
                  </CheckBoxContainer>
                  <InfoTitle htmlFor="하위">하위</InfoTitle>
                  <PersonnelInput
                    type="number"
                    onChange={handleUserRank("low")}
                    value={lowerUsers}
                    disabled={selectedSortedUserType.total}
                  />
                  <PersonnelDescription>명</PersonnelDescription>
                </PersonnelInfoItem>
                <PersonnelInfoItem>
                  <CheckBoxContainer>
                    <CheckBox
                      type="checkbox"
                      id="전체"
                      value="total"
                      onChange={handleSelectedSortedUserType}
                      disabled={
                        selectedSortedUserType.top || selectedSortedUserType.low
                      }
                      checked={selectedSortedUserType.total}
                    />
                    <CheckMark />
                  </CheckBoxContainer>
                  <InfoTitle htmlFor="전체">전체</InfoTitle>
                </PersonnelInfoItem>
              </PersonnelContainer>
            </TotalPersonnelContainer>
          )}
          <Button onClick={handleChartData}>그래프보기</Button>
        </ContentContainer>
      </AsonicDialog>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
};

export default ChartDialog;
