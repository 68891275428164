import styled from "styled-components";
import { Icon } from "@iconify/react";
import { useRecoilValue } from "recoil";
import mRAStore from "./m_r_a_store";
import { colors } from "../../../GlobalStyle/GlobalStyle";
import mColors from "../../../../style-sheet/m_colors";
import { useMemo } from "react";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const IconContainer = styled.div<{ $fontSize?: string }>`
  svg {
    font-size: ${props => props.$fontSize ?? "24px"};
  }
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.green};
  border-radius: 50%;
  padding: 8px;
`;

const Header = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Subtitle = styled.span`
  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.colors.darkGrey};
  font-weight: bold;
`;

const Content = styled.section`
  display: flex;
  flex: 10;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  flex-direction: column;
  padding: 20px;
`;

const Ul = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px;
  font-size: 16px;
  gap: 3px;
`;

const Li = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 5px;
  :before {
    content: "•";
    color: ${props => props.theme.colors.darkGrey};
  }
`;

const LIContentContainer = styled.div`
  display: flex;
  flex: 1;
  color: ${props => props.theme.colors.darkGrey};
  justify-content: space-between;
`;

const LITitle = styled.span`
  text-align: center;
  color: ${props => props.theme.colors.darkGrey};
  font-weight: bold;
`;

const LIContent = styled.span<{ $color?: string }>`
  text-align: center;
  color: ${props => props.$color ?? props.theme.colors.black};
`;

const DescriptionContainer = styled.pre`
  display: flex;
  flex: 4;
  font-weight: normal;
  margin: 0px;
  padding: 5px;
  text-wrap: wrap;
  background-color: ${mColors.lightGreen};
  border-radius: 6px;
`;

function MRequestApprovalRequest() {
  const {
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    approvalLineUser,
    template,
    user,
    description
  } = useRecoilValue(mRAStore);
  const approver: string = useMemo(() => {
    let newApprover = "";
    if (approvalLineUser.length > 0) {
      newApprover = approvalLineUser[0].name;
      if (approvalLineUser.length > 1) {
        newApprover += ` 외 ${approvalLineUser.length - 1}명`;
      }
    }
    return newApprover;
  }, [approvalLineUser]);
  return (
    <Container>
      <Header>
        <IconContainer>
          <Icon icon="mingcute:check-fill" />
        </IconContainer>
        <Title>{`결재 신청 완료`}</Title>
        <Subtitle>{`신청하신 결재는 나의결재에서 확인하실 수 있습니다`}</Subtitle>
      </Header>
      <Content>
        <Ul>
          <Li>
            <LIContentContainer>
              <LITitle>{`결재양식`}</LITitle>
              <LIContent>{template?.formName}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`시작일자`}</LITitle>
              <LIContent $color={colors.green}>{startDate}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`시작일시`}</LITitle>
              <LIContent $color={colors.green}>{startTime}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`종료일자`}</LITitle>
              <LIContent $color={colors.darkRed}>{endDate}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`종료일시`}</LITitle>
              <LIContent $color={colors.darkRed}>{endTime}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`기안자`}</LITitle>
              <LIContent>{user?.name ?? ""}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`결재자`}</LITitle>
              <LIContent $color={mColors.darkYellow}>{approver}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`제목`}</LITitle>
              <LIContent>{title}</LIContent>
            </LIContentContainer>
          </Li>
          <Li>
            <LIContentContainer>
              <LITitle>{`내용`}</LITitle>
            </LIContentContainer>
          </Li>
        </Ul>
        <DescriptionContainer>{description}</DescriptionContainer>
      </Content>
    </Container>
  );
}

export default MRequestApprovalRequest;
