import styled from "styled-components";
import {
  EmployeeInformation,
  GroupEntity
} from "../../../../generated/graphql";
import { ICompactRow } from "../../../compact-table/compact-table-render-row";
import ListOfEmployeeInGroups from "../../approval-process/list-of-employee-in-groups";
import TreeOfApprovalProcess from "../../approval-process/tree-of-approval-process";
import ListOfPersonalApprovalLineUser from "../list-of-personal-approval-line-user";
import { IApprovalLineManagementTabs } from "./approval-process-management";

interface IProps {
  handleSelectedDepartment: (department: GroupEntity) => void;
  selectedDepartment?: number;
  handleAddUser: (user?: ICompactRow<EmployeeInformation>) => void;
  selectedTab: IApprovalLineManagementTabs;
  handleSelectTab: (payload: IApprovalLineManagementTabs) => void;
  hiddenGroupTab?: boolean;
  hiddenApprovalTab?: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 10px;
  min-width: 500px;
`;

function ApprovalProcessView({
  handleSelectedDepartment,
  selectedDepartment,
  handleAddUser,
  selectedTab,
  handleSelectTab,
  hiddenGroupTab = false, // 조직도 탭 숨김 설정
  hiddenApprovalTab = false // 개인결재선 탭 숨김 설정
}: IProps) {
  return (
    <Container>
      <TreeOfApprovalProcess
        handleSelectedDepartment={handleSelectedDepartment}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
        hiddenGroupTab={hiddenGroupTab}
        hiddenApprovalTab={hiddenApprovalTab}
      />
      {selectedTab === IApprovalLineManagementTabs.ORGANIZATION_CHART && (
        <ListOfEmployeeInGroups
          selectedDepartment={selectedDepartment}
          handleAddUser={handleAddUser}
        />
      )}
      {selectedTab ===
        IApprovalLineManagementTabs.PERSONAL_APPROVAL_PROCESS && (
        <ListOfPersonalApprovalLineUser />
      )}
    </Container>
  );
}

export default ApprovalProcessView;
