import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  Carriedforward_Range_Type,
  Carriedforward_Type,
  CompanyEntity,
  InfoMoreThanAYearEntity,
  Minus_Annual_Type,
  useGetInfoMoreThanAYearLazyQuery,
  useUpdateInfoMoreThanAYearMutation,
  UpdateInfoMoreThanAYearMutationVariables
} from "../../generated/graphql";
import StyleInput from "../inputs/style-input";
import FormRow from "../shared/form-row/form-row";
import SubTitle from "../shared/sub-title/sub-title";
import { useForm, useWatch } from "react-hook-form";
import Button from "../globalComponents/Button";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import InfoMoreThanAYearHelp from "./info-more-than-a-year-help";

interface IProps {
  selectedCompany?: CompanyEntity;
}

const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 1200px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  min-width: 900px;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 460px;
  min-width: 460px;
  :first-child {
    select {
      min-width: 244px;
    }
    span {
      width: max-content;
    }
  }
  :nth-child(2) {
    select {
      min-width: 200px;
    }
  }
`;

function InfoMoreThanAYearSetting({ selectedCompany }: IProps) {
  const { register, setValue, handleSubmit, control } =
    useForm<InfoMoreThanAYearEntity>({
      defaultValues: {
        defaultDayCount: 1,
        addedDayCount: 1,
        addedMaxDayCount: 1,
        addedYearCount: 1,
        carriedforwardRangeType: Carriedforward_Range_Type.MinusAnnualLeaveOnly,
        carriedforwardType: Carriedforward_Type.Apply,
        minusAnnualDayCount: 1,
        minusAnnualType: Minus_Annual_Type.LimitByBasicDayWithMinusAnnual
      }
    });

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const listOfMinusAnnualLeave = useMemo(
    () => [
      { title: "제한없음", value: Minus_Annual_Type.UnLimit },
      {
        title: "기본일수 + 마이너스연차 허용일수로 제한",
        value: Minus_Annual_Type.LimitByBasicDayWithMinusAnnual
      },
      {
        title: "마이너스연차 허용일수로 제한",
        value: Minus_Annual_Type.LimitByMinusAllowedAnnual
      },
      { title: "미사용", value: Minus_Annual_Type.UnUsed }
    ],
    []
  );

  const listOfCarriedforwardType = useMemo(
    () => [
      { title: "적용", value: Carriedforward_Type.Apply },
      { title: "미적용", value: Carriedforward_Type.NotApplied }
    ],
    []
  );

  const listOfCarriedforwardRangeType = useMemo(
    () => [
      {
        title: "마이너스 연차만 적용",
        value: Carriedforward_Range_Type.MinusAnnualLeaveOnly
      },
      {
        title: "플러스 연차만 적용",
        value: Carriedforward_Range_Type.PlusAnnualLeaveOnly
      },
      {
        title: "마이너스, 플러스연차 모두 적용",
        value: Carriedforward_Range_Type.MinusAndPlusAnnualLeave
      }
    ],
    []
  );

  const [getInfoMoreThanAYear] = useGetInfoMoreThanAYearLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getInfoMoreThanAYear.ok && data.getInfoMoreThanAYear.info) {
        const {
          addedDayCount,
          addedMaxDayCount,
          addedYearCount,
          carriedforwardRangeType,
          carriedforwardType,
          defaultDayCount,
          minusAnnualDayCount,
          minusAnnualType
        } = data.getInfoMoreThanAYear.info;
        setValue("addedDayCount", addedDayCount);
        setValue("addedMaxDayCount", addedMaxDayCount);
        setValue("addedYearCount", addedYearCount);
        setValue("carriedforwardRangeType", carriedforwardRangeType);
        setValue("carriedforwardType", carriedforwardType);
        setValue("defaultDayCount", defaultDayCount);
        setValue("minusAnnualDayCount", minusAnnualDayCount);
        setValue("minusAnnualType", minusAnnualType);
      }
    }
  });

  const [updateInfoMoreThanAYear] = useUpdateInfoMoreThanAYearMutation({
    onError(error) {
      console.log(error);
      handleToast(
        "만 1년 이상 설정을 저장하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.updateInfoMoreThanAYear.ok) {
        handleToast(
          "성공적으로 만 1년 이상 설정을 저장하였습니다.",
          MessageTypes.SUCCESS
        );
      } else if (data?.updateInfoMoreThanAYear.error) {
        handleToast(data.updateInfoMoreThanAYear.error, MessageTypes.ERROR);
      }
    }
  });

  const handleUpdate = useCallback(
    (data: InfoMoreThanAYearEntity) => {
      if (selectedCompany) {
        const payload: UpdateInfoMoreThanAYearMutationVariables = {
          companyCode: selectedCompany.companyCode,
          defaultDayCount: parseInt(`${data.defaultDayCount}`),
          addedYearCount: parseInt(`${data.addedYearCount}`),
          addedDayCount: parseInt(`${data.addedDayCount}`),
          addedMaxDayCount: parseInt(`${data.addedMaxDayCount}`),
          minusAnnualType: data.minusAnnualType,
          minusAnnualDayCount: parseInt(`${data.minusAnnualDayCount}`),
          carriedforwardType: data.carriedforwardType,
          carriedforwardRangeType: data.carriedforwardRangeType
        };
        console.log(payload);
        updateInfoMoreThanAYear({ variables: payload });
      }
    },
    [selectedCompany, updateInfoMoreThanAYear]
  );

  useEffect(() => {
    if (selectedCompany) {
      getInfoMoreThanAYear({
        variables: { companyCode: selectedCompany.companyCode }
      });
    }
  }, [getInfoMoreThanAYear, selectedCompany]);

  const carriedforwardType = useWatch({
    control,
    name: "carriedforwardType" // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const minusAnnualType = useWatch({
    control,
    name: "minusAnnualType" // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  return (
    <Container onSubmit={handleSubmit(handleUpdate)}>
      <TitleBar>
        <SubTitle title="만 1년 이상" />
        <InfoMoreThanAYearHelp />
      </TitleBar>
      <Body>
        <Section>
          <FormRow title="기본 일수 설정">
            <StyleInput
              type="number"
              min={0}
              {...register("defaultDayCount")}
            />
            <span>{`일 부여`}</span>
          </FormRow>
          <FormRow title="가산 일수 설정">
            <StyleInput
              type="number"
              min={0}
              minWidth="30px"
              maxWidth="42px"
              {...register("addedYearCount")}
            />
            <span>{`년 마다`}</span>
            <StyleInput
              type="number"
              min={0}
              minWidth="30px"
              maxWidth="42px"
              {...register("addedDayCount")}
            />
            <span>{`일 추가 부여`}</span>
          </FormRow>
          <FormRow title="마이너스 연차 사용 설정">
            <select {...register("minusAnnualType")}>
              {listOfMinusAnnualLeave.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
          <FormRow title="이월 연차 적용 설정">
            <select {...register("carriedforwardType")}>
              {listOfCarriedforwardType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
        </Section>
        <Section>
          <FormRow title=""></FormRow>
          <FormRow title="최대 가산 일수">
            <span>{`최대`}</span>
            <StyleInput
              type="number"
              min={0}
              {...register("addedMaxDayCount")}
            />
            <span>{`일로 제한`}</span>
          </FormRow>
          <FormRow title="마이너스연차 허용일">
            <StyleInput
              type="number"
              min={0}
              minWidth="190px"
              {...register("minusAnnualDayCount")}
              disabled={
                minusAnnualType === Minus_Annual_Type.UnUsed ||
                minusAnnualType === Minus_Annual_Type.UnLimit
              }
            />
            <span>{`일`}</span>
          </FormRow>
          <FormRow title="이월 연차 적용 범위">
            <select
              {...register("carriedforwardRangeType")}
              disabled={carriedforwardType === Carriedforward_Type.NotApplied}
            >
              {listOfCarriedforwardRangeType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
        </Section>
      </Body>
      <ButtonContainer>
        <Button>저장</Button>
      </ButtonContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default InfoMoreThanAYearSetting;
