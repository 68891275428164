import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AsonicCalendarPresenter from "./asonic-calendar-presenter";
import routes from "../../routes";

type Props = {};

const AsonicCalendarContainer = <P extends Props>(props: P) => {
  const navigate = useNavigate();

  const goCalendar = useCallback(() => {
    navigate(routes.pageRoutes.calendar);
  }, [navigate]);

  return <AsonicCalendarPresenter {...props} goCalendar={goCalendar} />;
};

export default AsonicCalendarContainer;
