import * as React from "react";
import { GoogleEvent } from "../../../hooks/link-google-hooks/use-google-calendar";

const useMakeCalnedar = () => {
  const [selectedEvent, setSelectedEvent] = React.useState<GoogleEvent>();

  const handleSelectedEvent = React.useCallback((selectItem?: GoogleEvent) => {
    setSelectedEvent(selectItem);
  }, []);

  return {
    selectedEvent,
    handleSelectedEvent
  };
};

export default useMakeCalnedar;
