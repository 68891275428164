import styled from "styled-components";
import InfoIcon from "../../shared/info-icon/info-icon";
import etcImage from "../../../images/etc_example.png";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  img {
    margin-bottom: 4px;
  }
  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

function EtcOperatingTimeHelp() {
  return (
    <InfoIcon
      minHeight="600px"
      height="600px"
      minWidth="400px"
      title="기타운영시간"
    >
      <HelpContainer>
        <pre>{`근무시간 외 일괄적으로 운영 되는 시간을 설정 합니다. 
공휴일, 창립기념일, 근무 운영정책에 따른 알림창 등의 기타 운영시간을 
근무제에 적용 하실수 있습니다.
기타 운영시간의 생성 및 수정은 A-Sonic 관리자 콘솔에서만 가능합니다.`}</pre>
        <ul>
          <li>{`기타운영 시간 목록 항목 설명`}</li>
          <span>{`사용 가능한 기타 운영 시간의 목록 및 설정을 보여 줍니다.`}</span>
          <ul>
            <li>
              <pre>{`구분(요일/날짜)
요일로 지정된 경우 해당 요일 마다 반복하여 동작하며, 
날짜로 지정된 경우 해당 기간에만 동작 합니다.`}</pre>
            </li>
            <li>
              <pre>{`시작시간, 종료시간
해당 기타운영시간이 동작하는 시간을 표시 합니다.`}</pre>
            </li>
            <li>
              <pre>{`제어(알림)
해당 기타 운영시간이 사용하는 제어(알림)을 표시 합니다.`}</pre>
            </li>
          </ul>
          <li>{`기타운영 시간 적용 방법`}</li>
          <pre>{`적용하려는 기타 운영 시간의 체크 박스를 활성화 하여, 해당 근무제에 
적용 합니다. 체크 되지 않은 운영시간은 해당 근무제에 반영되지 않습
니다.`}</pre>
          <picture>
            <source
              srcSet={etcImage}
              media="(orientation: portrait)"
              width="100"
              height="50"
            />
            <img
              src={etcImage}
              alt="etc_operating_time_example"
              width="380"
              height="80"
            />
          </picture>
          <span>{`* 위와 같이 체크되어 있는 항목만 적용됨`}</span>
          <li>{`신규 기타 운영시간 자동 적용`}</li>
          <span>{`체크시 신규 생성되는 기타운영 시간은 자동으로 적용됩니다.`}</span>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default EtcOperatingTimeHelp;
