import { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  ApprovalLineEntity,
  Is_Default_Approval_Line,
  useGetListOfApprovalLineLazyQuery
} from "../../../generated/graphql";
import { Icon } from "@iconify/react";
import ReactLoading from "react-loading";
import OpenFolderIcon from "@iconify/icons-fa-solid/folder-open";
import CloseFolderIcon from "@iconify/icons-fa-solid/folder";
import { colors } from "../../GlobalStyle/GlobalStyle";
import ApprovalLineContext from "../approval-setting/context/approval-line-context";
import { TOKEN } from "../../../apollo/apollo";
import { handleDecodedToken } from "../../../Utils/tokenMaker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`;

const Item = styled.div<{ isOpen: boolean; isSelected: boolean }>`
  display: flex;
  flex: 1;
  gap: 10px;
  svg {
    font-size: 14px;
  }
  align-items: center;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  :active {
    color: ${props => props.theme.colors.lightGreen};
  }
  color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.black};
  user-select: none;
`;

const IconContainer = styled.div``;

function PersonalApprovalProcess() {
  const store = useContext(ApprovalLineContext);

  const employeeId: string | undefined = useMemo(() => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData?.employee_id;
    }
    return;
  }, []);

  const [getListOfApprovalLine, { data, loading }] =
    useGetListOfApprovalLineLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const tree = useMemo((): ApprovalLineEntity[] => {
    return data?.getListOfApprovalLine.list ?? [];
  }, [data]);

  useEffect(() => {
    if (employeeId) {
      getListOfApprovalLine({
        variables: {
          employeeId: employeeId
        }
      });
    }
  }, [employeeId, getListOfApprovalLine]);

  useEffect(() => {
    if (store?.selectedApprovalLine && store?.handlePersonalApprovalLine) {
      store?.handlePersonalApprovalLine(store?.selectedApprovalLine);
    } else if (store?.handlePersonalApprovalLine) {
      store.handlePersonalApprovalLine(undefined);
    }
  }, [store?.selectedApprovalLine, store?.handlePersonalApprovalLine]);

  return (
    <Container>
      {loading ? (
        <ReactLoading
          type="spokes"
          color={colors.green}
          height={200}
          width={170}
        />
      ) : (
        <ListContainer>
          <ItemContainer>
            <Item isOpen={false} isSelected={false} onClick={() => {}}>
              <IconContainer>
                <Icon icon={OpenFolderIcon} />
              </IconContainer>
              <span>{`개인결재선`}</span>
            </Item>
            {tree.map(brunch => (
              <ItemContainer key={`${brunch.lineIdx}`}>
                <Item
                  isOpen={false}
                  isSelected={
                    brunch.lineIdx === store?.selectedApprovalLine?.lineIdx
                  }
                  onClick={() => {
                    store?.handleSelectedApprovalLine(brunch);
                  }}
                >
                  <IconContainer>
                    <Icon
                      icon={
                        brunch.lineIdx === store?.selectedApprovalLine?.lineIdx
                          ? OpenFolderIcon
                          : CloseFolderIcon
                      }
                    />
                  </IconContainer>
                  <span>{`${brunch.newTitle}${
                    brunch.basicLine === Is_Default_Approval_Line.Used
                      ? "[기본결재선]"
                      : ""
                  }`}</span>
                </Item>
              </ItemContainer>
            ))}
          </ItemContainer>
        </ListContainer>
      )}
    </Container>
  );
}

export default PersonalApprovalProcess;
