import { colors } from "../../GlobalStyle/GlobalStyle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../../types/reducers";
import ReactLoading from "react-loading";
import {
  ApprovalTemplateEntity,
  Type_Control_Approval_Form,
  useGetListOfApprovalTemplateLazyQuery
} from "../../../generated/graphql";
import CompactTable from "../../compact-table/compact-table";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import * as ReactTable from "react-table";
import { APPROVAL_TYPE, T_KEY_APPROVAL_TYPE } from "./constants";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import routes from "../../../routes";
import EChannel from "../../../Utils/EChannel/e-channel";
import { ApprovalType, IApprovalDetailMessage } from "../approval-detail-popup";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import ApprovalTemplateDetail from "./approval-template-detail";

export interface Row<P extends {}>
  extends ReactTable.Row<P>,
    ReactTable.UseRowSelectRowProps<P> {}

export interface IApprovalTemplateEntity extends ApprovalTemplateEntity {
  number?: number;
  preView?: string;
}

const COLUMN_FOR_VALUE = {
  number: "No",
  templateTitle: "관리이름",
  name: "정보구분",
  approvalType: "결재방식",
  formName: "결재양식 선택",
  preView: "미리보기"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 10;
  flex-direction: column;
  gap: 10px;
`;

const commonStyles = {
  display: "flex",
  justifyContent: "center",
  border: "1px solid",
  padding: "2px",
  borderRadius: "6px",
  cursor: "pointer"
};

const Preview = styled.button`
  ${commonStyles}
  border-color: ${props => props.theme.colors.black};
  :active {
    transform: scale(0.98);
  }
  :hover {
    background-color: ${props => props.theme.colors.green};
  }
`;

const TableContainer = styled.div`
  ${commonStyles}
  flex: 5;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  justify-content: flex-start; /* Override justify-content for TableContainer */
  cursor: default; /* Override cursor for TableContainer */
`;

// 결재 관리 - 결재설정 - 관리자설정
function ListOfApprovalTemplate() {
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);
  const [getListOfApprovalTemplate, { data, loading }] =
    useGetListOfApprovalTemplateLazyQuery();
  const [checkedRows, toggleRow] = useState<Row<IApprovalTemplateEntity>[]>([]);
  const [controlType, setControlType] = useState(
    Type_Control_Approval_Form.Delete
  );
  const [previewTemplate, setPreviewTemplate] = useState<
    IApprovalTemplateEntity | undefined
  >();

  const bc = useMemo(() => {
    if (previewTemplate) {
      return new BroadcastChannel(EChannel.APPROVAL);
    }
  }, [previewTemplate]);

  const handleControlType = useCallback((type: Type_Control_Approval_Form) => {
    setControlType(type);
  }, []);

  const handleCheckedRow = useCallback(
    (row: Row<IApprovalTemplateEntity>): boolean => {
      let checked = false;
      if (checkedRows) {
        checkedRows.forEach(item => {
          if (item.id === row.id) {
            checked = true;
          }
        });
      }
      return checked;
    },
    [checkedRows]
  );

  const handleToggleRow = useCallback(
    (newRow: Row<IApprovalTemplateEntity>) => {
      toggleRow(checkedRows => {
        let newCheckedRow: Row<IApprovalTemplateEntity>[] = [...checkedRows];
        const foundRow = checkedRows.find(row => {
          return row.id === newRow.id;
        });
        if (foundRow) {
          newCheckedRow = checkedRows.filter(row => row.id !== newRow.id);
        } else {
          newCheckedRow.push(newRow);
        }
        return newCheckedRow;
      });
    },
    []
  );

  const handleAllToggleRow = useCallback((newRows: any[]) => {
    toggleRow(checkedRows => {
      if (checkedRows.length < newRows.length) {
        return newRows;
      }
      return [];
    });
  }, []);

  const handleInitCheckedRows = useCallback(() => {
    toggleRow([]);
  }, []);

  const columns: ReactTable.Column<IApprovalTemplateEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const newColumns: ReactTable.Column<IApprovalTemplateEntity>[] =
      listOfColumn.map(item => {
        let width = 120;
        return {
          Header(header) {
            if (COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] === "No") {
              return (
                <CheckBoxUi
                  checked={header.rows.length === checkedRows.length}
                  htmlFor={header.value}
                  onChange={() => {
                    handleAllToggleRow(header.rows);
                  }}
                  name={COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE]}
                />
              );
            }
            return COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE];
          },
          accessor: item as TYPE_OF_EMPLOYEE,
          width,
          Cell(cell: Cell<IApprovalTemplateEntity>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.number
            ) {
              return (
                <CheckBoxUi
                  checked={handleCheckedRow(cell.row)}
                  htmlFor={cell.value}
                  onChange={() => {
                    handleToggleRow(cell.row);
                  }}
                  name={cell.value}
                />
              );
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.preView
            ) {
              return (
                <Preview
                  onClick={event => {
                    event.preventDefault();
                    setPreviewTemplate(cell?.row?.original);
                  }}
                >{`미리보기`}</Preview>
              );
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.approvalType
            ) {
              return APPROVAL_TYPE[cell.value as T_KEY_APPROVAL_TYPE];
            }
            return cell.value;
          }
        };
      });
    return newColumns;
  }, [
    handleCheckedRow,
    handleToggleRow,
    handleAllToggleRow,
    checkedRows.length
  ]);

  const templateData = useMemo(() => {
    let newData: IApprovalTemplateEntity[] = [];
    if (data?.getListOfApprovalTemplate.list) {
      newData = data?.getListOfApprovalTemplate.list.map((item, index) => {
        return { number: index + 1, ...item };
      });
    }
    return newData;
  }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<IApprovalTemplateEntity>(
    {
      columns,
      data: templateData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ICompactRow<IApprovalTemplateEntity> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return;
    }, [selectedFlatRows]);

  const handleSelectRow = useCallback(
    (row?: ICompactRow<IApprovalTemplateEntity>) => {},
    []
  );

  useEffect(() => {
    if (bc && previewTemplate) {
      window.open(
        routes.pageRoutes.approvalPopup,
        "",
        "width=800, height=880, scrollbars=yes"
      );
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = {
          type: ApprovalType.PREVIEW_POPUP,
          template: previewTemplate,
          isRequest: false
        };
        bc.postMessage(message);
        bc.close();
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, previewTemplate]);

  useEffect(() => {
    if (employee_id) {
      getListOfApprovalTemplate({
        variables: {
          employeeId: employee_id,
          isAdmin: true // 관리자설정에 목록 얻을 때는 항상 true로 전달
        }
      });
    }
  }, [employee_id, getListOfApprovalTemplate]);

  return (
    <Container>
      {loading ? (
        <ReactLoading
          type="spokes"
          color={colors.green}
          height={200}
          width={170}
        />
      ) : (
        <TableContainer>
          <CompactTable
            title="결재 양식 관리"
            handleSelectRow={handleSelectRow}
            isLoading={false}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            isTitleBar={false}
          />
        </TableContainer>
      )}
      <ApprovalTemplateDetail
        selectedRow={selectedRow}
        controlType={controlType}
        checkedRows={checkedRows}
        handleControlType={handleControlType}
        handleInitCheckedRows={handleInitCheckedRows}
      />
    </Container>
  );
}

export default ListOfApprovalTemplate;
