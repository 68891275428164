import styled from "styled-components";
import { Icon } from "@iconify/react";
import CloseFolderIcon from "@iconify/icons-fa-solid/folder";
import OpenFolderIcon from "@iconify/icons-fa-solid/folder-open";
import { Fragment, useCallback, useState } from "react";

export interface IApprovalLineFormTree {
  id: number;
  formName: string;
}

interface IProps {
  tree: IApprovalLineFormTree[];
  selectedForm?: IApprovalLineFormTree;
  handleSelectedForm: (form: IApprovalLineFormTree) => void;
}

const Container = styled.div`
  flex: 1;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 12px;
  :first-child {
    padding: 0px;
  }
`;

const Item = styled.div<{ isOpen: boolean; isSelected: boolean }>`
  display: flex;
  flex: 1;
  gap: 10px;
  svg {
    font-size: 14px;
  }
  align-items: center;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  :active {
    color: ${props => props.theme.colors.lightGreen};
  }
  color: ${props =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.black};
  user-select: none;
`;

const IconContainer = styled.div``;

function DrawApprovalFormList({ tree, handleSelectedForm }: IProps) {
  const [listOfOpen, setListOfOpen] = useState<number[]>([1]);

  const handleListOfOpen = useCallback((payload: number) => {
    setListOfOpen(listOfOpenedItem => {
      let newList = [payload];
      if (listOfOpenedItem.includes(payload)) {
        newList = listOfOpenedItem.filter(item => item !== payload);
      }
      return newList;
    });
  }, []);

  const handleSelectChildItem = useCallback((form: IApprovalLineFormTree) => {
    handleSelectedForm(form);
    setListOfOpen(listOfOpenedItem => {
      let newList = [listOfOpenedItem[0], form.id];
      return newList;
    });
  }, []);

  return (
    <Container>
      <ItemContainer>
        <Item isOpen={listOfOpen.includes(1)} isSelected={false}>
          <IconContainer
            onClick={() => {
              handleListOfOpen(1);
            }}
          >
            <Icon
              icon={listOfOpen.includes(1) ? OpenFolderIcon : CloseFolderIcon}
            />
          </IconContainer>
          <span>{`결재 양식함`}</span>
        </Item>
        {listOfOpen.includes(1) && (
          <Fragment>
            {tree.map((item, index) => (
              <ItemContainer key={index}>
                <Item
                  isOpen={listOfOpen.includes(item.id)}
                  isSelected={listOfOpen.includes(item.id)}
                  onClick={() => {
                    handleSelectChildItem(item);
                  }}
                >
                  <IconContainer>
                    <Icon
                      icon={
                        listOfOpen.includes(item.id)
                          ? OpenFolderIcon
                          : CloseFolderIcon
                      }
                    />
                  </IconContainer>
                  {item.formName}
                </Item>
              </ItemContainer>
            ))}
          </Fragment>
        )}
      </ItemContainer>
    </Container>
  );
}

export default DrawApprovalFormList;
