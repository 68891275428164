import isRightMenuState from "./isRightMenuState";
import rightMenuPositionState from "./rightMenuPositionState";
import confirmDialogState from "./confirmDialogState";

type Props = {
  getUsers?: (params: { limit: number; offset: number }) => Promise<void>;
};

const clickRightMenu = <P extends Props>(props: P) => {
  const { isRightMenu, setIsRightMenu } = isRightMenuState();
  let rightMenuPosition = rightMenuPositionState(setIsRightMenu);
  let confirmDialog = confirmDialogState();
  if (props && props.getUsers) {
    confirmDialog = confirmDialogState({ getUsers: props.getUsers });
  }

  return { isRightMenu, ...rightMenuPosition, ...confirmDialog };
};

export default clickRightMenu;
