import * as React from "react";
import { getPersonalWorkingTimeScheduleTemplate } from "../../../api/working-time-schedule-api";

interface IUsePersonalScheduleSettingProps {
  employeeId?: string;
  mainTitle: string;
}

const usePersonalScheduleSetting = <
  P extends IUsePersonalScheduleSettingProps
>({
  employeeId,
  mainTitle
}: P) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [personalTemplateId, setPersonalTemplateId] = React.useState<number>();

  const handleIsOpenDialog = React.useCallback(
    (value: boolean) => () => {
      setIsOpen(value);
    },
    []
  );

  const handleGetPersonalTemplate = React.useCallback(async () => {
    if (employeeId) {
      try {
        const { data } = await getPersonalWorkingTimeScheduleTemplate({
          employeeId
        });
        if (data[0]?.time_templete_idx) {
          setPersonalTemplateId(data[0].time_templete_idx);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [employeeId]);

  React.useEffect(() => {
    if (mainTitle === "달력") {
      handleGetPersonalTemplate();
    }
  }, [handleGetPersonalTemplate, mainTitle]);

  return { isOpen, handleIsOpenDialog, personalTemplateId };
};

export default usePersonalScheduleSetting;
