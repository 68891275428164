import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  visibility: visible;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  button {
    align-self: center;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Select = styled.select`
  margin: 10px;
`;

export const Option = styled.option``;

export const Label = styled.label`
  margin-right: 10px;
`;
