import * as React from "react";

const usePersonalStatusBoxPeriod = () => {
  const [period, setPeriod] = React.useState<string>("");

  const handlePeriod = React.useCallback((value: string) => {
    setPeriod(value);
  }, []);

  return { period, handlePeriod };
};

export default usePersonalStatusBoxPeriod;
