import * as React from "react";
import masterWorkManagementPageApi from "../../../api/master-work-management-page-api";

type DataFromServer = {
  name: string;
  astotmin: number;
  astotmin_work: number;
  astotmin_ext: number;
  asbasicmin: number;
};

type Datasets = {
  type: string;
  backgroundColor: string;
  borderColor: string;
  data: number[];
  label?: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  pointRadius?: number;
  tension: number;
  datalabels: {
    display: boolean;
    clamp?: boolean;
  };
};

export type Data = {
  labels: string[];
  datasets: Datasets[];
};

type DataType =
  | typeof REGULAR_WORK
  | typeof OVER_TIME_WORK
  | typeof TOTAL_WORK
  | typeof STANDARD_WORK;

const BAR = "bar",
  LINE = "line";

const REGULAR_WORK = "정규근무",
  OVER_TIME_WORK = "연장근무",
  TOTAL_WORK = "총근무",
  STANDARD_WORK = "기준 근로시간";

type Props = {
  accountIdx: number;
  isRenewal: boolean;
};
const useTotalWeeklyHoursChart = <P extends Partial<Props>>({
  isRenewal
}: P) => {
  const isMount = React.useRef<boolean>(false);
  const [period, setPeriod] = React.useState<string>("");
  const [regularWorkData, setRegularWorkData] = React.useState<number[]>([]);
  const [overTimeWorkData, setOverTimeWorkData] = React.useState<number[]>([]);
  const [standardWorkData, setStandardWorkData] = React.useState<number[]>([]);
  const [totalWorkData, setTotalWorkData] = React.useState<number[]>([]);
  const [labels, setLabels] = React.useState<string[]>([]);

  const makeDataSets = React.useCallback(
    (dataType: DataType) => {
      let data: number[] = [];
      let label = "";
      let type = BAR;
      let color = "#4C8AC8";
      let datalabels = {
        display: false
      };
      if (dataType === REGULAR_WORK || dataType === OVER_TIME_WORK) {
        if (dataType === REGULAR_WORK) {
          data = regularWorkData;
          label = REGULAR_WORK;
        } else {
          color = "#053D80";
          data = overTimeWorkData;
          label = OVER_TIME_WORK;
        }
      } else if (dataType === TOTAL_WORK || dataType === STANDARD_WORK) {
        if (dataType === TOTAL_WORK) {
          datalabels.display = true;
          color = "#F73763";
          data = totalWorkData;
          label = TOTAL_WORK;
        } else {
          color = "#32BADB";
          data = standardWorkData;
          label = STANDARD_WORK;
        }
        type = LINE;
      }

      const dataSet: Datasets = {
        type: type,
        backgroundColor: color,
        borderColor: color,
        data,
        label,
        barThickness: 18,
        maxBarThickness: 20,
        minBarLength: 2,
        pointRadius: 1,
        tension: 0.2,
        datalabels
      };
      return dataSet;
    },
    [regularWorkData, standardWorkData, overTimeWorkData, totalWorkData]
  );

  const datasets = React.useMemo(() => {
    const regularWorkDataSets = makeDataSets(REGULAR_WORK);
    const overTimeWorkDataSets = makeDataSets(OVER_TIME_WORK);
    const totalTimeWorkDataSets = makeDataSets(TOTAL_WORK);
    const standardWorkDataSets = makeDataSets(STANDARD_WORK);

    return [
      standardWorkDataSets,
      totalTimeWorkDataSets,
      regularWorkDataSets,
      overTimeWorkDataSets
    ];
  }, [makeDataSets]);

  const chartData = React.useMemo(() => {
    const chartData = {
      labels,
      datasets
    };

    return chartData;
  }, [labels, datasets]);

  const handleData = React.useCallback(async () => {
    try {
      if (isRenewal) {
        const params = {};
        const { data } = await masterWorkManagementPageApi.totalWeekWorkingTime(
          params
        );
        setPeriod(data[0]?.asperio);
        const newRegularWorkData: number[] = [],
          newOverTimeWorkData: number[] = [],
          newStandardWorkDAta: number[] = [],
          newTotalWorkData: number[] = [],
          newLabels: string[] = [];
        if (data && data.length > 0 && isMount.current) {
          data.forEach((item: DataFromServer) => {
            newLabels.push(item.name);
            newRegularWorkData.push(Math.floor(item.astotmin_work / 60));
            newOverTimeWorkData.push(Math.floor(item.astotmin_ext / 60));
            newStandardWorkDAta.push(Math.floor(item.asbasicmin / 60));
            newTotalWorkData.push(Math.floor(item.astotmin / 60));
          });
          setLabels(newLabels);
          setRegularWorkData(newRegularWorkData);
          setOverTimeWorkData(newOverTimeWorkData);
          setStandardWorkData(newStandardWorkDAta);
          setTotalWorkData(newTotalWorkData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [isRenewal]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    handleData();
    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [handleData]);

  React.useEffect(() => {
    let timeId: any;
    if (isMount.current) {
      timeId = setInterval(handleData, 300000);
    }
    return () => {
      clearInterval(timeId);
    };
  }, [handleData]);

  return { chartData, period };
};

export default useTotalWeeklyHoursChart;
