import { useState, useEffect, useRef } from "react";

const useTreeDataState = preTreeDa => {
  const isMounted = useRef(false);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted) {
      setTreeData(preTreeDa);
    }
    return () => {
      isMounted.current = false;
    };
  }, [preTreeDa]);

  return { treeData, setTreeData };
};

export default useTreeDataState;
