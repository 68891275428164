import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LeftMenuList from "../../../components/main-view/LeftMenuList";

const LeftMenuListContainer = props => {
  const navigate = useNavigate();

  const handleList = useCallback(
    path => () => {
      navigate(path);
    },
    [navigate]
  );
  return <LeftMenuList {...props} handleList={handleList} />;
};

export default LeftMenuListContainer;
