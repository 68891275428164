import { TextFieldContainer, TextFieldTitle, STextField } from "./styled";
import PropTypes from "prop-types";

const TextField = props => {
  const { label, placeholder, value, valueChange, type } = props;
  return (
    <TextFieldContainer>
      <STextField
        type={type}
        id={label}
        onChange={valueChange}
        placeholder={placeholder}
        value={value}
      />
      <TextFieldTitle value={value} htmlFor={label}>
        {label}
      </TextFieldTitle>
    </TextFieldContainer>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

export default TextField;
