import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../../../types/reducers";
import SectionContainer from "../../../../../../shared/section_container/section_container";
import ChoiceBasicInfo from "../../../../../../work_system_dialog/choice_work_graph/choice_basic_info/choice_basic_info";
import listOfApolloVar from "../../../../../../../apollo/apollo-var";
import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  OtSelfWorkingStandardHoursEntity,
  WtGraphBasicEntity,
  useGetSelfDeterminedWorkHourInfoLazyQuery,
  useGetWtiFromEmployeeLazyQuery
} from "../../../../../../../generated/graphql";
import handleApolloErrorLog from "../../../../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../../../../Utils/handle_error_log/handle_error_log";
import * as ReactTable from "react-table";
import ChoiceBasicTemplate from "../../../../../../work_system_dialog/choice_work_graph/choice_basic_template/choice_basic_template";
import ChoiceDayHourGraph from "../../../../../../work_system_dialog/choice_work_graph/choice_day_hour_graph/choice_day_hour_graph";
import { Role } from "../../../../../../../user-types";

interface IProps {
  selectedTemplate?: ReactTable.Row<OtSelfWorkingStandardHoursEntity>;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<
      ReactTable.Row<OtSelfWorkingStandardHoursEntity> | undefined
    >
  >;
}

function SelectTemplateDialog({
  selectedTemplate,
  setSelectedTemplate
}: IProps) {
  const [basicInfo, setBasicInfo] = useState<WtGraphBasicEntity>();
  const [otWSHoursList, setOtWSHoursList] = useState<
    OtSelfWorkingStandardHoursEntity[]
  >([]);

  const [workingTemplateIdx, setWorkingTemplateIdx] = useState<number>();

  const {
    signInReducer: { loginIdInfo, employee_id }
  } = useSelector((state: Reducers) => state);

  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const [getSelfDeterminedWorkHourInfo] =
    useGetSelfDeterminedWorkHourInfoLazyQuery({
      onError(error) {
        handleApolloErrorLog(error);
      },
      onCompleted(data) {
        if (data.getSelfDeterminedWorkHourInfo.ok) {
          if (data.getSelfDeterminedWorkHourInfo.basicInfo) {
            setBasicInfo(data.getSelfDeterminedWorkHourInfo.basicInfo);
          }
          if (data.getSelfDeterminedWorkHourInfo.otWSHoursList) {
            setOtWSHoursList(data.getSelfDeterminedWorkHourInfo.otWSHoursList);
          }
        } else if (
          !data.getSelfDeterminedWorkHourInfo.ok &&
          data.getSelfDeterminedWorkHourInfo.error
        ) {
          handleErrorLog(data.getSelfDeterminedWorkHourInfo.error);
        }
      }
    });

  const [getWTIFromEmployee] = useGetWtiFromEmployeeLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getWTIFromEmployee.ok &&
        data.getWTIFromEmployee.workingTemplateIdx
      ) {
        setWorkingTemplateIdx(
          Number(data.getWTIFromEmployee.workingTemplateIdx)
        );
      } else if (!data.getWTIFromEmployee.ok && data.getWTIFromEmployee.error) {
        handleErrorLog(data.getWTIFromEmployee.error);
      }
    }
  });

  useEffect(() => {
    if (workingTemplateIdx) {
      getSelfDeterminedWorkHourInfo({
        variables: {
          workingTemplateIdx
        }
      });
    }
  }, [workingTemplateIdx, getSelfDeterminedWorkHourInfo]);

  useEffect(() => {
    if (loginIdInfo === Role.USER || selectedListOfEmployeeId.length < 1) {
      getWTIFromEmployee({
        variables: {
          employeeId: employee_id
        }
      });
    } else if (selectedListOfEmployeeId.length > 0) {
      const employeeId = selectedListOfEmployeeId.at(-1);
      if (employeeId) {
        getWTIFromEmployee({
          variables: {
            employeeId
          }
        });
      }
    }
  }, [getWTIFromEmployee, selectedListOfEmployeeId, loginIdInfo, employee_id]);

  return (
    <SectionContainer>
      <ChoiceBasicInfo basicInfo={basicInfo} />
      <ChoiceBasicTemplate
        otWSHoursList={otWSHoursList}
        setSelectedTemplate={setSelectedTemplate}
      />
      <ChoiceDayHourGraph selectedTemplate={selectedTemplate} />
    </SectionContainer>
  );
}

export default SelectTemplateDialog;
