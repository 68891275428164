import * as React from "react";
import RightMenu from "../ORightMenu";

interface IRightMenuPosition {
  pageY: number;
  pageX: number;
}

interface OIProps {
  rootDom?: any;
  children?: React.ReactNode;
}

export interface IProps extends OIProps {
  isRightMenu: boolean;
  rightMenuPosition?: IRightMenuPosition;
}

const RightMenuContainer = (props: OIProps) => {
  const [isRightMenu, setIsRightMenu] = React.useState<boolean>(false);
  const [rightMenuPosition, setRightMenuPosition] =
    React.useState<IRightMenuPosition>();

  const handleContextMenu = React.useCallback((event: MouseEvent) => {
    event.preventDefault();
    const { pageX, pageY } = event;
    setRightMenuPosition({ pageX, pageY });
    setIsRightMenu(true);
  }, []);

  const handleRightMenu = React.useCallback((event: MouseEvent) => {
    setIsRightMenu(false);
  }, []);

  React.useEffect(() => {
    document.addEventListener("click", handleRightMenu);
    return () => {
      document.removeEventListener("click", handleRightMenu);
    };
  }, [handleRightMenu]);
  React.useEffect(() => {
    if (props.rootDom) {
      props.rootDom.addEventListener("contextmenu", handleContextMenu);
    }
    return () => {
      if (props.rootDom) {
        props.rootDom.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, [handleContextMenu, props]);

  return (
    <RightMenu
      {...props}
      isRightMenu={isRightMenu}
      rightMenuPosition={rightMenuPosition}
    />
  );
};

export default RightMenuContainer;
