import { call, put } from "redux-saga/effects";
import {
  getWorkingHours,
  getWorkingInfo
} from "../../../redux/sagas/restapi-calls/mainViewCalls";
import {
  SET_WORKING_HOURS,
  SET_WORKING_INFO
} from "../../../redux/modules/main-view/mainViewReducer";

export function* getWorkingHoursAsync(action: any) {
  const workingHours: any[] = yield call(getWorkingHours, action.args);
  yield put({
    type: SET_WORKING_HOURS,
    workingHours
  });

  const workingInfo: any[] = yield call(getWorkingInfo, action.args);
  yield put({
    type: SET_WORKING_INFO,
    workingInfo
  });
}
