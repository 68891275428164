import styled from "styled-components";

const GroupTableHeader = styled.td<{
  isOver?: boolean;
  isFlex?: boolean;
  isLastFlex?: boolean;
  isOverFlow: boolean;
  isGroup?: boolean;
}>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.4rem 0px;
  cursor: grab;
  background-color: ${props => {
    if (props.isOver === false) {
      return props.theme.colors.tomato;
    }
  }};
  flex: ${props => (props.isFlex ? "1" : "none")};
  :last-child {
    margin-right: ${props => {
      if (navigator.userAgent.indexOf("Mac") != -1) {
        return props.isOverFlow ? "17px" : "2px";
      } else if (navigator.userAgent.indexOf("Edg") != -1) {
        if (props.isGroup) {
          return props.isOverFlow ? "17px" : "2px";
        } else {
          return props.isOverFlow ? "19px" : "2px";
        }
      } else if (
        navigator.userAgent.indexOf("Chrome") != -1 &&
        navigator.userAgent.indexOf("Whale") == -1
      ) {
        return props.isOverFlow ? "19px" : "2px";
      }

      return "2px";
    }};
  }
  :not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.grey};
  }
`;

export default GroupTableHeader;
