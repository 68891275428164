import * as React from "react";
import { getWorkingTemplatePolicy } from "../../../../../Utils/commonAxiosCall";

type List = { working_templete_idx: number | null; name: string };

const useGetWorkScheduleState = () => {
  const isMounted = React.useRef(false);
  const [workingTemplate, setWorkingTemplate] = React.useState<List[]>([]);

  const handleWorkingTemplate = React.useCallback(async () => {
    const { data } = await getWorkingTemplatePolicy();
    if (isMounted.current) {
      data.push({ working_templete_idx: null, name: "미사용" });
      setWorkingTemplate(data);
    }
  }, []);

  React.useEffect(() => {
    isMounted.current = true;
    handleWorkingTemplate().catch(error => console.log(error));

    return () => {
      isMounted.current = false;
    };
  }, [handleWorkingTemplate]);

  return { workingTemplate };
};

export default useGetWorkScheduleState;
