import { ChangeEvent, useCallback, useEffect, useState } from "react";
import settingsApi from "../../../api/settings-api";
import { MessageTypes } from "../../../components/toast-message/toast-message";
import { ListItem } from "../../use-super-admin/use-web-menu-list";

export type OnChange = ChangeEvent<HTMLInputElement>;

type Props = {
  handleIsOpen: (value: boolean) => void;
  handleMessage: (value: string) => void;
  handleToastMessageType: (value: MessageTypes) => void;
};

const successedMessage = "성공적으로 변경되었습니다.",
  failedMessage = "변경에 실패했습니다.";

const SHOW = 1;

const useGetData = <P extends Props>({
  handleIsOpen,
  handleMessage,
  handleToastMessageType
}: P) => {
  const [list, setList] = useState<ListItem[]>([
    {
      mainTitle: "메인기능",
      data: [
        {
          title: "Public 허용",
          key: Symbol("Public 허용").toString(),
          status: 0,
          isCheck: false
        }
      ]
    }
  ]);

  const makeData = useCallback((value: number) => {
    const newList = [
      {
        mainTitle: "메인기능",
        data: [
          {
            title: "Public 허용",
            key: Symbol("Public 허용").toString(),
            status: value,
            isCheck: value === SHOW ? true : false
          }
        ]
      }
    ];
    setList(newList);
  }, []);

  const getData = useCallback(async () => {
    try {
      const { data } = await settingsApi.getPublicDeviceList();
      makeData(data[0].employeeApptokenPublicAllow);
    } catch (error) {
      console.log(error);
    }
  }, [makeData]);

  const updateMenuList = useCallback(
    async (list: ListItem[]) => {
      try {
        const params = {
          employeeApptokenPublicAllow: list[0].data[0].status
        };
        handleMessage(successedMessage);
        await settingsApi.updatePublicDeviceList(params);
        handleToastMessageType(MessageTypes.SUCCESS);
      } catch (error) {
        handleToastMessageType(MessageTypes.INFO);
        handleMessage(failedMessage);
        console.log(error);
      } finally {
        handleIsOpen(true);
      }
    },
    [handleMessage, handleToastMessageType, handleIsOpen]
  );

  const handleOnChange = useCallback((event: OnChange) => {
    let value = parseInt(event.currentTarget.value);
    if (value === 1) {
      value = 0;
    } else {
      value = 1;
    }
    const newList = [
      {
        mainTitle: "메인기능",
        data: [
          {
            title: "Public 허용",
            key: Symbol("Public 허용").toString(),
            status: value,
            isCheck: value === SHOW ? true : false
          }
        ]
      }
    ];
    updateMenuList(newList);
    setList(newList);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return { list, handleOnChange, makeData, getData };
};

export default useGetData;
