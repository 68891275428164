import * as React from "react";
import DateWithTime from "../DateWithTime/DateWithTime";
import moment from "moment";

type OnChange = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  title?: string;
  dateWithTime?: string;
  handleDateWithTime?: (value: string) => void;
}

export interface INextProps extends IProps {
  data: string;
  handleData: (event: OnChange) => void;
}

const DateWithTimeContainer = (props: IProps) => {
  const { dateWithTime, handleDateWithTime } = props;
  const [data, setData] = React.useState(moment().format("YYYY-MM-DD[T]HH:mm"));

  const handleData = React.useCallback(
    (event: OnChange) => {
      setData(event.target.value);
      if (handleDateWithTime) {
        handleDateWithTime(event.target.value);
      }
    },
    [handleDateWithTime]
  );

  React.useEffect(() => {
    if (dateWithTime) {
      setData(dateWithTime);
    }
  }, [dateWithTime]);

  return <DateWithTime {...props} data={data} handleData={handleData} />;
};

export default DateWithTimeContainer;
