// import { AutoSizer } from "react-virtualized";
// import { FixedSizeList as List } from "react-window";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../types/reducers";
import {
  LeavingTheRoom,
  LeavingTheRoomVariables
} from "../../__generated__/LeavingTheRoom";
import { ChatContext, ChatHandleContext, LIST_OF_CHAT_MENU } from "./chat";
// import ListOfUserInRoom from "./list-of-user-in-room";

const Container = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  bottom: -65px;
  background-color: ${props => props.theme.colors.white};
  justify-self: flex-end;
  z-index: 1;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  padding: 0px;
`;

const Content = styled.ul`
  display: flex;
  flex: 1;
  padding: 0px;
  margin: 0px;
  flex-direction: column;
`;

const Menu = styled.li`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  :hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.skyBlue};
  }
`;

const MUTATION_LEAVING_THE_ROOM = gql`
  mutation LeavingTheRoom($chatRoomIdx: Int!, $employeeId: String!) {
    leavingTheRoom(chatRoomIdx: $chatRoomIdx, employeeId: $employeeId) {
      ok
      error
    }
  }
`;

function ChatRoomSetting() {
  const { employee_id } = useSelector((state: Reducers) => state.signInReducer);
  const chatContext = useContext(ChatContext);
  const chatHandleContext = useContext(ChatHandleContext);
  const [leavingTheRoom, { client }] = useMutation<
    LeavingTheRoom,
    LeavingTheRoomVariables
  >(MUTATION_LEAVING_THE_ROOM, {
    update(_, { data }) {
      if (data?.leavingTheRoom.ok) {
        client.resetStore();
        if (chatHandleContext?.handleMenu) {
          chatHandleContext.handleMenu(LIST_OF_CHAT_MENU.CHAT);
          chatHandleContext?.handleIsRoomSetting(false);
          chatContext?.socket?.close();
        }
      }
    }
  });
  const handleLeavingTheRoom = useCallback(() => {
    if (chatContext?.selectedChatRoomIdx && employee_id) {
      leavingTheRoom({
        variables: {
          employeeId: employee_id,
          chatRoomIdx: chatContext.selectedChatRoomIdx
        }
      });
    }
  }, [leavingTheRoom, chatContext, employee_id]);

  return (
    <Container>
      <Content>
        <Menu onClick={handleLeavingTheRoom}>방나가기</Menu>
        <Menu
          onClick={() => {
            chatHandleContext?.handleIsUserInvitation(true);
          }}
        >
          대화상대 초대
        </Menu>
        {/* {chatContext?.listOfUserInRoom && (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  height={height}
                  itemCount={chatContext?.listOfUserInRoom.length}
                  itemData={chatContext?.listOfUserInRoom}
                  itemSize={30}
                  width={width}
                >
                  {ListOfUserInRoom}
                </List>
              );
            }}
          </AutoSizer>
        )} */}
      </Content>
    </Container>
  );
}

export default ChatRoomSetting;
