import { electroneApi } from "./electron-route";

const WIDGET_USER_SETTING = `${electroneApi}/widget-user-setting`;
const SEARCH_USER_FOR_PC = `${WIDGET_USER_SETTING}/search`;
const UPDATE_USER_FOR_PC = `${WIDGET_USER_SETTING}/update-user-for-pc`;
const USER_AVATAR = `${WIDGET_USER_SETTING}/avatar`;

const widgetUserSettingRoute = {
  widgetUserSetting: WIDGET_USER_SETTING,
  searchUserForPc: SEARCH_USER_FOR_PC,
  updateUserForPc: UPDATE_USER_FOR_PC,
  userAvatar: USER_AVATAR
};

export default widgetUserSettingRoute;
