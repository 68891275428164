import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 10%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
`;

export const NotiContainer = styled.div`
  position: absolute;
  bottom: 46px;
  background-color: red;
  width: 200px;
  height: 30px;
`;
