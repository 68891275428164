import { Bar } from "react-chartjs-2";
import moment from "moment";
import { useMemo } from "react";
import { TooltipItem, TooltipModel } from "chart.js";

type DataSets = {
  backgroundColor: string;
  barThickness: number;
  minBarLength: number;
  data: number[];
  label: string;
};

type Props = {
  labels: string[];
  datasets: DataSets[];
  max: number;
};

const WeeklyTotalWorkingHourChart = <P extends Props>({
  labels,
  datasets,
  max
}: P) => {
  const options: any = useMemo(
    () => ({
      indexAxis: "y" as const,
      showLines: false,
      plugins: {
        legend: {
          display: false
        },
        centerText: false,
        tooltip: {
          callbacks: {
            label: function (
              this: TooltipModel<"bar">,
              tooltipItem: TooltipItem<"bar">
            ) {
              const {
                raw,
                dataset: { label }
              } = tooltipItem;
              let hours: number = raw as number;
              if (hours < 0) {
                return `0h`;
              }
              hours = Math.floor(
                moment.duration(raw as number, "minutes").asHours()
              );
              const min = (raw as number) % 60;
              return `${label}: ${hours}h ${min}m`;
            }
          }
        },
        datalabels: {
          formatter: function (value: any, context: any) {
            const hours = Math.floor(
              moment.duration(value, "minutes").asHours()
            );
            const min = value % 60;
            if (context.datasetIndex === 0) {
              return `${hours}h ${min}m`;
            } else {
              return ``;
            }
          },
          display: true,
          align: "center",
          anchor: "center",
          color: "white"
        }
      },
      maintainAspectRatio: false,
      tooltip: { enabled: false },
      scales: {
        y: {
          display: false,
          stacked: true
        },
        x: {
          grid: {
            display: false,
            drawBorder: false
          },
          max,
          ticks: {
            beginAtZero: true,
            max,
            callback: function (value: number) {
              if (value <= 0) {
                return `0h`;
              }
              if (value < max) {
                return "";
              }
              const hours = Math.floor(
                moment.duration(value, "minutes").asHours()
              );
              return `${hours}h`;
            }
          },
          stacked: true
        }
      },
      layout: {
        padding: {
          top: 16,
          bottom: 0
        }
      }
    }),
    [max]
  );
  return (
    <Bar data={{ labels, datasets }} width={10} height={20} options={options} />
  );
};

export default WeeklyTotalWorkingHourChart;
