import moment from "moment";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";

export interface IDates {
  startDate: string;
  endDate: string;
}

export interface IAsonicSearchWithDate {
  handleDate?: (payload: IDates) => void;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StartDate = styled.input`
  outline: none;
`;

const EndDate = styled(StartDate)``;

const Term = styled.div`
  font-size: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.9);
  }
  cursor: pointer;
`;

function AsonicSearchWithDate({ handleDate }: IAsonicSearchWithDate) {
  const { register, handleSubmit } = useForm<IDates>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    }
  });
  return (
    <Container>
      <StartDate type="date" {...register("startDate")} />
      <Term>~</Term>
      <EndDate type="date" {...register("endDate")} />
      <IconContainer onClick={handleDate && handleSubmit(handleDate)}>
        <Icon icon={SearchIcon} />
      </IconContainer>
    </Container>
  );
}

export default AsonicSearchWithDate;
