let ROOT_PATH = "/";
let MAINVIEW_PATH = "/mainview/";
let NOAUTH = "/noauth/";
let WEB_SERVER_ADDR =
  process.env.REACT_APP_WEB_SERVER || "http://localhost:18081";

if (process.env.NODE_ENV === "production") {
  ROOT_PATH = "/";
  MAINVIEW_PATH = "/mainview/";
  NOAUTH = "/noauth/";
  WEB_SERVER_ADDR = "";
}

export const ENTIRE_CATEGORY = "0";
export const APPROVED_REQUEST = "1";
export const ENTIRE_APPROVE_CATEGORY = "999";
export const RTC = "rtc";
export const ROOM = id => `${RTC}:${id}`;
export const CHART = "chart";
export { ROOT_PATH };
export { MAINVIEW_PATH };
export { NOAUTH };
export { WEB_SERVER_ADDR };

export const GLOBAL_REDUCER_INIT_STATE = "GLOBAL_REDUCER/INIT_STATE";
