import React, { useState } from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import { searchDepartment } from "../../../Utils/commonAxiosCall";
import uiString from "./string.json";
import Button from "../../globalComponents/Button";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import SearchIcon from "@iconify/icons-mdi/magnify";
import AsonicIconButton from "../../shared/asonic-icon-button/asonic-icon-button";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import RemoveIcon from "@iconify/icons-fa-solid/minus-circle";
import ProfileCard from "../../profile-card/profile-card";
import { colors } from "../../GlobalStyle/GlobalStyle";
import TextInput from "../../inputs/text-input";

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const RootDiv = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
  svg {
    font-size: 24px;
  }
`;

const ErrorMsgDiv = styled.div`
  font-weight: bold;
  color: #ff0000;
`;

const DialogContent = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  gap: 10px;
`;

const ListSection = styled.section`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const RenderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DepartmentSelectorDlg = props => {
  const [departmentName, updateDepartmentName] = useState("");
  const [searchResult, updateSearchResult] = useState([]);
  const [chosenDepartments, updateChosenDepartments] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const _handleKeyPress = event => {
    if (event.key === "Enter") {
      getNameOfGroup();
    }
  };

  const getNameOfGroup = () => {
    if (departmentName.length < 2) {
      setErrorMsg(uiString.notifications.nameLessThanTwoLetters);
      return;
    } else {
      setErrorMsg("");
    }

    searchDepartment(departmentName)
      .then(res => {
        if (res.data) {
          updateSearchResult(res.data);
        }
      })
      .catch(error => console.log(error));
  };

  const addChip = React.useCallback(
    department => () => {
      let alreadyHave = -1;
      for (let i = 0; i < chosenDepartments.length; i++) {
        if (chosenDepartments[i].department_id === department.department_id) {
          alreadyHave = i;
          break;
        }
      }

      if (alreadyHave === -1) {
        department["key"] = department.department_id;
        let newChosenList = [...chosenDepartments, department];
        updateChosenDepartments(newChosenList);
      }
    },
    [chosenDepartments]
  );

  const handleDeleteChosenDepartment = React.useCallback(
    department => () => {
      const chosenList = [...chosenDepartments];
      for (let i = 0; i < chosenList.length; i++) {
        if (chosenList[i].department_id === department.department_id) {
          chosenList.splice(i, 1);
          break;
        }
      }
      updateChosenDepartments(chosenList);
    },
    [chosenDepartments]
  );

  const handleUpdateDepartmentName = event => {
    updateDepartmentName(event.target.value);
  };

  const Row = React.useCallback(
    ({ index, style }) => {
      const department = searchResult[index];
      const departmentInfo = {
        fullName: department.fullname,
        departmentId: department.department_id
      };
      return (
        <RenderContainer style={style}>
          <ProfileCard departmentInfo={departmentInfo}>
            <div onClick={addChip(searchResult[index])}>
              <AsonicIconButton icon={AddIcon} />
            </div>
          </ProfileCard>
        </RenderContainer>
      );
    },
    [searchResult, addChip]
  );

  const ChoosenRow = React.useCallback(
    ({ index, style }) => {
      const department = chosenDepartments[index];
      const departmentInfo = {
        fullName: department.fullname,
        departmentId: department.department_id
      };
      return (
        <RenderContainer style={style}>
          <ProfileCard departmentInfo={departmentInfo}>
            <div
              onClick={handleDeleteChosenDepartment(chosenDepartments[index])}
            >
              <AsonicIconButton icon={RemoveIcon} color={colors.tomato} />
            </div>
          </ProfileCard>
        </RenderContainer>
      );
    },
    [chosenDepartments, handleDeleteChosenDepartment]
  );

  return (
    <AsonicDialog
      title={props.title}
      handleClose={() => props.handleClose()}
      height="430px"
      minHeight="430px"
      width="400px"
    >
      <DialogContent>
        <span>{props.description}</span>
        <RootDiv>
          <TextInput
            margin="normal"
            onChange={handleUpdateDepartmentName}
            onKeyPress={_handleKeyPress}
          />
          <AsonicIconButton icon={SearchIcon} onClick={getNameOfGroup} />
        </RootDiv>
        <ErrorMsgDiv>{errorMsg}</ErrorMsgDiv>
        <ListSection>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={searchResult.length}
                itemSize={45}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <Divider />
        <h3>{"지정된 부서 조건"}</h3>
        <ListSection>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={chosenDepartments.length}
                itemSize={45}
                width={width}
              >
                {ChoosenRow}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <ButtonContainer>
          <Button onClick={props.handleClose}>{uiString.buttons.cancel}</Button>
          <Button
            onClick={() => {
              props.handleStatisticsState({
                departments: chosenDepartments.map(dep => dep.department_id)
              });
              props.handleClose();
            }}
            autoFocus
          >
            {uiString.buttons.confirm}
          </Button>
        </ButtonContainer>
      </DialogContent>
    </AsonicDialog>
  );
};

export default DepartmentSelectorDlg;
