import * as React from "react";
import uiString from "../../components/main-view/string.json";
import {
  faHome,
  faFileSignature,
  faInfoCircle,
  faCog,
  faChartPie,
  faCar,
  faClipboardList
} from "@fortawesome/free-solid-svg-icons";
import { WebMenuAuthList } from "../use-check-web-auth-fn";
import routes from "../../routes";
import { Role } from "../../user-types";
import { useSelector } from "react-redux";
import { Reducers } from "../../../types/reducers/index.d";

const HOME_ICON = faHome;
const APPROVAL_ICON = faFileSignature;
const STATISTICS_ICON = faChartPie;
const INFO_ICON = faInfoCircle;
const SETTING_ICON = faCog;
const CAR_ICON = faCar;
const RESERVATION_ICON = faClipboardList;

type Props = {
  webMenuAuthList: WebMenuAuthList;
};

const useLeftMenuList = <P extends Partial<Props>>({ webMenuAuthList }: P) => {
  const {
    signInReducer: { loginIdInfo }
  } = useSelector((state: Reducers) => state);
  const makeNewMenu = React.useCallback(() => {
    if (webMenuAuthList) {
      return [
        {
          title: uiString.titles.dashBoard,
          icon: HOME_ICON,
          pathname: routes.pageRoutes.personal,
          isActive: webMenuAuthList.dashBoard,
          children: [
            {
              title: uiString.titles.dashBoardPersonal,
              pathname: routes.pageRoutes.personal,
              isActive: true
            },
            {
              title: uiString.titles.dashBoardMaster,
              pathname: routes.pageRoutes.home,
              isActive: true
            }
          ]
        },
        {
          key: "web_approval_flag",
          title: uiString.titles.approvalManagement,
          icon: APPROVAL_ICON,
          pathname: routes.pageRoutes.approvalDashboard,
          isActive: webMenuAuthList.approvalMenu,
          children: [
            {
              title: "대시 보드",
              pathname: routes.pageRoutes.approvalDashboard,
              isActive: true
            },
            {
              title: "결재 목록",
              pathname: routes.pageRoutes.approvalList,
              isActive: true
            },
            {
              title: "임시 저장 문서",
              pathname: routes.pageRoutes.approvalTemporaryDocument,
              isActive: true
            }
          ]
        },
        {
          key: "web_report_flag",
          title: uiString.titles.statistics,
          icon: STATISTICS_ICON,
          pathname: routes.pageRoutes.statisticsUser,
          isActive: true,
          children: [
            {
              title: uiString.titles.adminStatistics,
              pathname: routes.pageRoutes.statisticsAdmin,
              isActive: webMenuAuthList.managerStatistics
            },
            {
              title: uiString.titles.userStatistics,
              pathname: routes.pageRoutes.statisticsUser,
              isActive: true
            }
          ]
        },
        {
          key: "web_otmng_flag",
          title: uiString.titles.workManagementInformation,
          icon: INFO_ICON,
          pathname: routes.pageRoutes.workManagementInformation,
          isActive: true
        },
        {
          key: "web_vehicle_flag",
          title: uiString.titles.vehicleDrivingManagementMenu,
          icon: CAR_ICON,
          pathname: routes.pageRoutes.vehicleDrivingManagement,
          isActive: webMenuAuthList.vehicleDrivingManagementMenu
        },
        {
          key: "web_reserve_flag",
          title: uiString.titles.reservationInformationManagementMenu,
          icon: RESERVATION_ICON,
          pathname: routes.pageRoutes.reservationManagement,
          isActive: webMenuAuthList.reservationManagementMenu
        },
        {
          title: uiString.titles.configuration,
          icon: SETTING_ICON,
          pathname: routes.pageRoutes.settings,
          isActive: true,
          children: [
            {
              title: uiString.titles.basicSetting,
              pathname: routes.pageRoutes.settings,
              isActive: true
            },
            {
              title: "근무제 설정",
              pathname: routes.pageRoutes.workingSystem,
              isActive:
                loginIdInfo !== Role.USER && webMenuAuthList.workingSystem
            },
            {
              title: uiString.titles.annualEnvSetting,
              pathname: routes.pageRoutes.annualEnvSetting,
              isActive:
                loginIdInfo !== Role.USER && webMenuAuthList.annualSetting
            },
            {
              title: "결재 설정",
              pathname: routes.pageRoutes.approvalSetting,
              isActive: webMenuAuthList.approvalMenu
            }
          ]
        }
      ];
    } else {
      return [];
    }
  }, [webMenuAuthList, loginIdInfo]);
  const newMenu = React.useMemo(makeNewMenu, [makeNewMenu]);
  return { newMenu };
};

export default useLeftMenuList;
