import styled from "styled-components";

const GroupTableHead = styled.tr`
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  background-color: ${props => props.theme.colors.lightGrey};
  border-top: 2px solid ${props => props.theme.colors.darkGrey};
  font-weight: bold;
  z-index: 1;
  svg {
    color: ${props => props.theme.colors.green};
  }
  font-family: sans-serif;
`;

export default GroupTableHead;
