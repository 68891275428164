import * as React from "react";
import CurrentTimeBox from "../../../components/main-view/personal-work-dashboard-page/current-time-box";
import { SignInReducer } from "../../../../types/reducers";
import WorkingInformationBoxContainer from "../../../components/main-view/personal-work-dashboard-page/working-information-box/working-information-box-container";
import MobileProfile from "./mobile-profile";

type Props = {
  signInReducer: SignInReducer;
};

const MobileSummaryView = <P extends Props>({ signInReducer }: P) => {
  return (
    <React.Fragment>
      <MobileProfile />
      <CurrentTimeBox isMobile={true} />
      <WorkingInformationBoxContainer userId={signInReducer.employee_id} />
    </React.Fragment>
  );
};

export default MobileSummaryView;
