import useTotalWeeklyHoursChart from "../../../../hooks/master-work-management-page-hooks/use-total-weekly-hours-chart";
import {
  Container,
  Head,
  TitleBar,
  Title,
  RankingTitle,
  SubTitle,
  ChartContainer,
  Select,
  Option
} from "./styled";
import TotalWeeklyWorkingHoursChart from "./total-weekly-working-hours-chart";
import useWeeklyWorkingHoursTab from "../../../../hooks/master-work-management-page-hooks/use-weekly-working-hours-tab";
import useAverageWorkingHoursDepartmentChart from "../../../../hooks/master-work-management-page-hooks/use-average-working-hours-department-chart";
import { Store } from "../../master-work-management-page";
import { useForm } from "react-hook-form";
import { useCallback, useContext, useMemo } from "react";
import CheckBox from "../../WorkManagementInfo/DetailView/approval-notification-management/check-box";

interface Props {}

export interface IFieldValues {
  isOnlyDepartmentName: boolean;
}

const IS_ONLY_DISPLAYING_DEPARTMENT_NAME = "isOnlyDisplayingDepartmentName";

const TotalWeeklyWorkingHours = <P extends Props>(props: P) => {
  const store = useContext<any>(Store);
  const params = {
    isRenewal: store.isRenewal
  };

  const handleGetStatusDisplayingDepartmentName = useMemo(() => {
    const isOnlyDisplayingDepartmentName = localStorage.getItem(
      IS_ONLY_DISPLAYING_DEPARTMENT_NAME
    );
    if (isOnlyDisplayingDepartmentName) {
      return JSON.parse(isOnlyDisplayingDepartmentName);
    }
    return false;
  }, []);

  const { register, watch } = useForm<IFieldValues>({
    mode: "onChange",
    defaultValues: {
      isOnlyDepartmentName: handleGetStatusDisplayingDepartmentName
    }
  });

  const { chartData: weeklyChartData, period } =
    useTotalWeeklyHoursChart(params);
  const { chartData: departmentChartData } =
    useAverageWorkingHoursDepartmentChart({
      isOnlyDepartmentName: watch("isOnlyDepartmentName"),
      ...params
    });
  const { selected, handleSelected } = useWeeklyWorkingHoursTab();

  const handleCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      localStorage.setItem(
        IS_ONLY_DISPLAYING_DEPARTMENT_NAME,
        `${event.target.checked}`
      );
    },
    []
  );

  return (
    <Container data-testid="total-weekly-working-hours">
      <Head>
        <TitleBar data-testid="title-bar">
          <Title data-testid="title">
            {selected === "weekly"
              ? "주간 총 근로시간"
              : "부서별 평균 근로시간"}
          </Title>
          <RankingTitle data-testid="ranking-title">Top 15</RankingTitle>
          <Select
            data-testid="total-weekly-working-tab"
            onChange={event => {
              handleSelected(event.target.value);
            }}
          >
            <Option value="weekly">주간</Option>
            <Option value="department">부서별</Option>
          </Select>
          {selected === "department" && (
            <CheckBox
              title="부서명만 보기"
              {...register("isOnlyDepartmentName", {
                onChange: handleCheckBox
              })}
            />
          )}
        </TitleBar>
        <SubTitle data-testid="sub-title">{`${period}`}</SubTitle>
      </Head>
      <ChartContainer>
        {selected === "weekly" ? (
          <TotalWeeklyWorkingHoursChart
            chartData={weeklyChartData}
            isMaster={true}
          />
        ) : (
          <TotalWeeklyWorkingHoursChart
            chartData={departmentChartData}
            isMaster={true}
          />
        )}
      </ChartContainer>
    </Container>
  );
};

export default TotalWeeklyWorkingHours;
