import { useCallback, useEffect, useState } from "react";
import { FieldSort, SortColumType } from "../../generated/graphql";
import {
  FieldSort as OldFieldSort,
  SortColumType as OldSortColumnType
} from "../../__generated__/globalTypes";

function useNewSortBy() {
  const [oldFieldSort, setOldFieldSort] = useState<OldFieldSort | undefined>();
  const [fieldSort, setFieldSort] = useState<FieldSort | undefined>();
  const [listOfColumnDisabled, setListOfColumnDisabled] = useState<string[]>(
    []
  );

  const handleListOfColumnDisabled = useCallback((payload: string[]) => {
    setListOfColumnDisabled(payload);
  }, []);

  const handleFieldSort = useCallback((payload?: FieldSort) => {
    setFieldSort(payload);
  }, []);

  useEffect(() => {
    if (fieldSort?.sort === SortColumType.Asc) {
      setOldFieldSort({
        name: fieldSort.name,
        sort: OldSortColumnType.ASC
      });
    } else if (fieldSort?.sort === SortColumType.Desc) {
      setOldFieldSort({
        name: fieldSort.name,
        sort: OldSortColumnType.DESC
      });
    }
  }, [fieldSort]);

  return {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled,
    oldFieldSort
  };
}

export default useNewSortBy;
