import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactTable from "react-table";
import styled from "styled-components";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import useDnd from "../../hooks/use-dnd/use-dnd";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import usePageControl from "../../hooks/use-page-control/use-page-control";
import {
  DownloadVehicleDrivingInformationExcel,
  DownloadVehicleDrivingInformationExcelVariables
} from "../../__generated__/DownloadVehicleDrivingInformationExcel";
import {
  GetListOfVehicleDrivingInformation,
  GetListOfVehicleDrivingInformationVariables,
  GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list
} from "../../__generated__/GetListOfVehicleDrivingInformation";
import { GetListOfVehicleInformation_getListOfVehicleInformation_list } from "../../__generated__/GetListOfVehicleInformation";
import {
  GetTotalNumberOfVehicleDrivingInformation,
  GetTotalNumberOfVehicleDrivingInformationVariables
} from "../../__generated__/GetTotalNumberOfVehicleDrivingInformation";
import { SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION } from "../../__generated__/globalTypes";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import { SearchFieldValues } from "../asonic-table/asonic-search";
import { downloadFileFromServer } from "../main-view/statistics/Utils";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import DrivingInformationController from "./driving-information-controller";
import useFixedColumn from "../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import TableLayoutFooter from "../table_layout/table_layout_footer";
import PageController from "../table/page_controller";
import TableLayoutContainer from "../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import UserInfoSectionHeader from "../main-view/WorkManagementInfo/user-info-section/user_info_section_container";
import TableV2 from "../table_v2/table_v2";
import useNewSortBy from "../../hooks/use-new-sort-by/use-new-sort-by";

interface IProps {
  selectedVehicle?: ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>;
  isDisableZIndex: boolean;
}

enum COLUMN_FOR_VALUE {
  vehicleNumber = "차량번호",
  vehicleName = "차명",
  startEmployeeId = "출발 운전자 사번",
  startEmployeeName = "출발 운전자 이름",
  endEmployeeId = "도착 운전자 사번",
  endEmployeeName = "도착 운전자 이름",
  startKm = "출발 전 거리",
  endKm = "도착 후 거리",
  startDateTime = "출발 날짜 및 시간",
  endDateTime = "도착 날짜 및 시간",
  startGpsAddress = "출발지",
  endGpsAddress = "도착지"
}

interface IColumn
  extends GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list {}

export type TYPE_FOR_VEHICLE_DRIVING_INFORMATION_TABLE =
  keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_GET_LIST_OF_VEHICLE_DRIVING_INFORMATION = gql`
  query GetListOfVehicleDrivingInformation(
    $vehicleNumber: String!
    $page: Float
    $take: Float
    $fieldSort: FieldSort
    $searchType: SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION
    $searchValue: String
  ) {
    getListOfVehicleDrivingInformation(
      vehicleNumber: $vehicleNumber
      page: $page
      take: $take
      fieldSort: $fieldSort
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      list {
        logIdx
        vehicleNumber
        vehicleName
        startEmployeeId
        startEmployeeName
        endEmployeeId
        endEmployeeName
        startKm
        endKm
        startDateTime
        endDateTime
        startGpsAddress
        endGpsAddress
        categoryName
        categoryId
      }
    }
  }
`;

const QUERY_GET_TOTAL_NUMBER_OF_VEHICLE_DRIVING_INFORMATION = gql`
  query GetTotalNumberOfVehicleDrivingInformation(
    $vehicleNumber: String!
    $searchType: SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION
    $searchValue: String
  ) {
    getTotalNumberOfVehicleDrivingInformation(
      vehicleNumber: $vehicleNumber
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_VEHICLE_DRIVING_INFORMATION_EXCEL = gql`
  query DownloadVehicleDrivingInformationExcel(
    $vehicleNumber: String!
    $searchType: SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION
    $searchValue: String
  ) {
    downloadVehicleDrivingInformationExcel(
      vehicleNumber: $vehicleNumber
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

const title = "운행정보";

function DrivingInformation({ selectedVehicle, isDisableZIndex }: IProps) {
  const { oldFieldSort, fieldSort, handleFieldSort } = useNewSortBy();
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [searchType, setSearchType] =
    useState<SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION>(
      SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION.vehicleNumber
    );

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const [searchValue, setSearchValue] = useState("");

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const [downloadVehicleDrivingInformation] = useLazyQuery<
    DownloadVehicleDrivingInformationExcel,
    DownloadVehicleDrivingInformationExcelVariables
  >(QUERY_DOWNLOAD_VEHICLE_DRIVING_INFORMATION_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadVehicleDrivingInformationExcel.ok &&
        data.downloadVehicleDrivingInformationExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadVehicleDrivingInformationExcel.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}-list-of-vehicle-driving-information.csv`
        );
      }
      if (
        !data.downloadVehicleDrivingInformationExcel.ok &&
        data.downloadVehicleDrivingInformationExcel.error
      ) {
        handleToast(
          data.downloadVehicleDrivingInformationExcel.error,
          MessageTypes.ERROR
        );
        handleIsToastMessageOpen(true);
      }
    }
  });

  const [getListOfVehicleDrivingInformation, { data, loading }] = useLazyQuery<
    GetListOfVehicleDrivingInformation,
    GetListOfVehicleDrivingInformationVariables
  >(QUERY_GET_LIST_OF_VEHICLE_DRIVING_INFORMATION, {
    fetchPolicy: "cache-and-network"
  });

  const [
    getTotalNumberOfVehicleDrivingInformation,
    { data: totalData, loading: totalLoading }
  ] = useLazyQuery<
    GetTotalNumberOfVehicleDrivingInformation,
    GetTotalNumberOfVehicleDrivingInformationVariables
  >(QUERY_GET_TOTAL_NUMBER_OF_VEHICLE_DRIVING_INFORMATION, {
    fetchPolicy: "network-only",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (
        !data.getTotalNumberOfVehicleDrivingInformation.ok &&
        data.getTotalNumberOfVehicleDrivingInformation.error
      ) {
        console.log(data.getTotalNumberOfVehicleDrivingInformation.error);
      }
    }
  });

  const total = useMemo(() => {
    if (
      totalData?.getTotalNumberOfVehicleDrivingInformation.ok &&
      totalData?.getTotalNumberOfVehicleDrivingInformation.total
    ) {
      return totalData?.getTotalNumberOfVehicleDrivingInformation.total;
    }
    return 0;
  }, [totalData]);

  const downloadExcel = useCallback(() => {
    if (selectedVehicle) {
      downloadVehicleDrivingInformation({
        variables: {
          vehicleNumber: selectedVehicle?.original.vehicleNumber,
          searchType,
          searchValue
        }
      });
    }
  }, [
    downloadVehicleDrivingInformation,
    selectedVehicle,
    searchType,
    searchValue
  ]);

  const listOfSearchType = useMemo(() => {
    return Object.keys(SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION).map(
      item => ({
        value: item,
        name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
      })
    );
  }, []);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type && selectedVehicle) {
        const INIT_PAGE = 1;
        setSearchType(data.type as SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION);
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.value as string);
        if (data.type) {
          setSearchType(
            data.type as SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION
          );
        }
        setSearchValue(data.value ?? "");
        getTotalNumberOfVehicleDrivingInformation({
          variables: {
            vehicleNumber: selectedVehicle?.original.vehicleNumber,
            searchType:
              data.type as SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION,
            searchValue: data.value
          }
        });
      }
    },
    [
      getTotalNumberOfVehicleDrivingInformation,
      handleCurrentPage,
      selectedVehicle
    ]
  );

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>
    ) => {},
    []
  );

  const columns: ReactTable.Column<IColumn>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    return listOfColumn.map(item => {
      let width = 120;

      if (item === "startDateTime" || item === "endDateTime") {
        width = 140;
      } else if (item === "startGpsAddress" || item === "endGpsAddress") {
        width = 220;
      }
      let sticky = "";
      if (sFixedColumnNumber !== undefined) {
        if (fixedColumnName.length > 0) {
          fixedColumnName.forEach((fColumn, fColumnIndex) => {
            if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
              sticky = "left";
            }
          });
        }
      }
      return {
        Header:
          COLUMN_FOR_VALUE[item as TYPE_FOR_VEHICLE_DRIVING_INFORMATION_TABLE],
        accessor: item as TYPE_FOR_VEHICLE_DRIVING_INFORMATION_TABLE,
        width,
        sticky,
        Cell(cell: any) {
          if (cell?.value?.length > 20) {
            return `${cell.value.slice(0, 20)}...`;
          }
          return cell.value;
        }
      };
    });
  }, [sFixedColumnNumber, fixedColumnName]);

  const list: GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list[] =
    useMemo(() => {
      return data?.getListOfVehicleDrivingInformation.list || [];
    }, [data]);

  const table =
    ReactTable.useTable<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>(
      {
        columns,
        data: list
      },
      ReactTable.useBlockLayout,
      ReactTable.useRowSelect,
      ReactTable.useColumnOrder,
      useSticky
    );

  const { moveColumn } =
    useDnd<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>(
      {
        columns: table.visibleColumns,
        setColumnOrder: table.setColumnOrder,
        title: `${title}-for-ordering-column`,
        setFixedColumnName
      }
    );

  const selectedRow:
    | ReactTable.Row<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  useEffect(() => {
    if (selectedVehicle) {
      getTotalNumberOfVehicleDrivingInformation({
        variables: {
          vehicleNumber: selectedVehicle.original.vehicleNumber,
          searchType: searchType,
          searchValue: searchValue
        }
      });
    }
  }, [
    selectedVehicle,
    searchType,
    searchValue,
    getTotalNumberOfVehicleDrivingInformation
  ]);

  useEffect(() => {
    if (selectedVehicle) {
      getListOfVehicleDrivingInformation({
        variables: {
          vehicleNumber: selectedVehicle.original.vehicleNumber,
          page: currentPage,
          take: take,
          searchType: searchType,
          searchValue: searchValue,
          fieldSort: oldFieldSort
        }
      });
    }
  }, [
    selectedVehicle,
    currentPage,
    take,
    searchType,
    searchValue,
    oldFieldSort,
    getListOfVehicleDrivingInformation
  ]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);

  return (
    <Container>
      <UserInfoSectionHeader<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>
        columns={
          table.columns as TColumn<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>[]
        }
        table={table}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        setIsActiveFilter={setIsActiveFilter}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                loading={loading || totalLoading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      {/* <AsonicTable<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={handleSelectRow}
        isLoading={loading || totalLoading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        handleFieldSort={handleFieldSort}
        fieldSort={fieldSort}
        columns={
          vehicleDrivingInformation as TColumn<GetListOfVehicleDrivingInformation_getListOfVehicleDrivingInformation_list>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      /> */}
      <DrivingInformationController
        selectedRow={selectedRow}
        handleToast={handleToast}
        selectedVehicle={selectedVehicle}
        isDisableZIndex={isDisableZIndex}
      />
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default DrivingInformation;
