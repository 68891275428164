import styled from "styled-components";

interface IProps {
  isDivide?: boolean;
  listHandleClick?: (data: any) => () => void;
  listLength?: number;
}

interface IRenderRow {
  data: { name?: string; title?: string }[];
  index: number;
  style: object;
}

const Item = styled.div<{ isDivide?: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: ${props => (props.isDivide ? "1px solid black" : "")};
  min-width: max-content;
`;

export const ItemInList = styled.div<{ isDivide?: boolean }>`
  cursor: pointer;
  position: relative;
  background-color: white;
  color: black;
  :hover {
    ${Item} {
      background-color: ${props => props.theme.colors.green};
      color: ${props => props.theme.colors.white};
    }
  }
`;

function RightMenuRow({ listHandleClick, isDivide, listLength }: IProps) {
  return ({ index, data, style }: IRenderRow) => {
    return (
      <ItemInList
        key={index}
        onClick={listHandleClick && listHandleClick(data[index])}
        style={style}
      >
        <Item isDivide={isDivide && listLength === index}>
          {data[index]?.name ?? data[index]?.title}
        </Item>
      </ItemInList>
    );
  };
}

export default RightMenuRow;
