import { AutoSizer } from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import SubTitle from "../shared/sub-title/sub-title";
import SummaryRenderRow, {
  ISummaryRowProps,
  ISummaryRow
} from "./summary-render-row";
import * as ReactTable from "react-table";
import { useNavigate } from "react-router-dom";

interface IProps<P extends object> extends ISummaryRowProps<P> {
  title: string;
  getTableProps: (
    propGetter?:
      | Partial<ReactTable.TableProps>
      | Partial<ReactTable.TableProps>[]
      | ((
          props: Partial<ReactTable.TableProps>,
          meta: ReactTable.MetaBase<any>
        ) => Partial<any> | Partial<any>[])
      | undefined
  ) => ReactTable.TableProps;
  getTableBodyProps: () => ReactTable.TableBodyProps;
  rows: ISummaryRow<P>[];
  isLoading?: boolean;
  listOfColumnDisabled?: string[];
  isTitleBar?: boolean;
  itemSize?: number;
  handleIsAllList: (isAll: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  border-top: 2px solid ${props => props.theme.colors.darkGrey};
  flex-direction: column;
`;

const TableTitle = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 2px solid ${props => props.theme.colors.frameGrey};
  justify-content: space-between;
`;

const ExtraTitleSection = styled.div`
  display: flex;
`;

const ExtraButton = styled.div`
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  :active {
    transform: scale(0.98);
  }
`;

const TableBody = styled.div`
  display: flex;
  flex: 1;
`;

function SummaryTable<P extends object>({
  title,
  getTableBodyProps,
  rows,
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  handleIsAllList,
  flexIndex,
  itemSize = 30
}: IProps<P>) {
  return (
    <Container>
      <TableTitle>
        <SubTitle title={title} />
        <ExtraTitleSection
          onClick={() => {
            handleIsAllList(true);
          }}
        >
          <ExtraButton>{`[ 전체보기 ]`}</ExtraButton>
        </ExtraTitleSection>
      </TableTitle>
      <TableBody {...getTableBodyProps()}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                height={height}
                itemCount={rows.length}
                itemData={rows}
                itemSize={itemSize}
                width={width}
              >
                {SummaryRenderRow({
                  prepareRow: prepareRow,
                  handleSelectRow: handleSelectRow,
                  handleContextMenu: handleContextMenu,
                  selectedRow: selectedRow,
                  flexIndex
                })}
              </List>
            );
          }}
        </AutoSizer>
      </TableBody>
    </Container>
  );
}

export default SummaryTable;
