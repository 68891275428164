import PropTypes from "prop-types";
import {
  SearchContainer,
  SearchInput,
  BtnContainer,
  UpBtn,
  DownBtn
} from "../styled";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  term: string;
  updateTerm: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateSearchDe: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  upItem: () => void;
  downItem: () => void;
}

const Search = ({
  term,
  updateTerm,
  updateSearchDe,
  upItem,
  downItem
}: IProps) => {
  return (
    <SearchContainer>
      <SearchInput
        value={term}
        onChange={updateTerm}
        onKeyDown={updateSearchDe}
        type="text"
        placeholder="부서 검색"
      />
      <BtnContainer>
        <UpBtn onClick={upItem} icon={faPlay} />
        <DownBtn onClick={downItem} icon={faPlay} />
      </BtnContainer>
    </SearchContainer>
  );
};

Search.propTypes = {
  term: PropTypes.string,
  updateTerm: PropTypes.func.isRequired,
  updateSearchDe: PropTypes.func.isRequired
};

export default Search;
