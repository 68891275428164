import * as React from "react";

type Props = {};

const useCheckIsDebugging = <P extends Props>(props: P) => {
  const [isDebugging, setIsDebugging] = React.useState<boolean>(false);
  const isMount = React.useRef<boolean>(false);

  const handleIsDebugging = React.useCallback((value: boolean) => {
    if (isMount.current) {
      setIsDebugging(value);
    }
  }, []);

  const getDebuggingValueFromLocalStorage = React.useCallback(() => {
    const value = window.localStorage.getItem("asonic-debug");
    let parseValue = false;
    if (value) {
      parseValue = JSON.parse(value);
    }
    return parseValue;
  }, []);

  React.useEffect(() => {
    isMount.current = true;
    const value = getDebuggingValueFromLocalStorage();
    handleIsDebugging(value);
    return () => {
      isMount.current = false;
    };
  }, [getDebuggingValueFromLocalStorage, handleIsDebugging]);

  return { isDebugging };
};

export default useCheckIsDebugging;
