import styled from "styled-components";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import CompactTable from "../../compact-table/compact-table";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import {
  ApprovalTemplateEntity,
  Approval_Sort,
  useGetListOfApprovalTemplateLazyQuery
} from "../../../generated/graphql";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import { TOKEN } from "../../../apollo/apollo";
import { handleDecodedToken } from "../../../Utils/tokenMaker";
import { IApprovalLineFormTree } from "./draw-approval-form-list";

export enum COLUMN_FOR_VALUE {
  numRank = "No",
  templateTitle = "관리이름",
  name = "정보구분",
  approvalType = "결재방식",
  formName = "결재양식"
}

export type TYPE_OF_AWAITING_APPROVAL = keyof typeof COLUMN_FOR_VALUE;

interface IProps {
  selectedForm?: IApprovalLineFormTree;
  selectedTemplate?: ApprovalTemplateEntity;
  handleSelectedTemplate: (template: ApprovalTemplateEntity) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

// 결재관리 - 결재신청
function ApprovalInfoList({
  selectedTemplate,
  selectedForm,
  handleSelectedTemplate
}: IProps) {
  const [selectedRow, setSelectedRow] =
    useState<ICompactRow<ApprovalTemplateEntity>>();
  const [isSelectedRow, setIsSelectedRow] = useState<boolean>(false);
  const [getListOfApprovalTemplate, { data }] =
    useGetListOfApprovalTemplateLazyQuery();

  const columns: ReactTable.Column<ApprovalTemplateEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    return listOfColumn.map(item => {
      let width = 100;
      if (
        COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
        COLUMN_FOR_VALUE.numRank
      ) {
        width = 50;
      }
      return {
        Header: COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL],
        accessor: item as TYPE_OF_AWAITING_APPROVAL,
        width,
        Cell(cell: Cell<ApprovalTemplateEntity>) {
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_AWAITING_APPROVAL] ===
            COLUMN_FOR_VALUE.approvalType
          ) {
            if (cell.value === Approval_Sort.ApprovalLine) {
              return "결재선";
            } else if (cell.value === Approval_Sort.AutoApproval) {
              return "자동결재";
            }
          }
          return cell.value;
        }
      };
    });
  }, []);

  const list: ApprovalTemplateEntity[] =
    useMemo((): ApprovalTemplateEntity[] => {
      return data?.getListOfApprovalTemplate.list || [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    toggleAllRowsSelected
  } = ReactTable.useTable<ApprovalTemplateEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback(
    (row?: ICompactRow<ApprovalTemplateEntity>) => {},
    []
  );

  useEffect(() => {
    if (selectedTemplate) {
      setIsSelectedRow(true);
    } else {
      toggleAllRowsSelected(false);
      setIsSelectedRow(false);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      const row = selectedFlatRows[selectedFlatRows.length - 1];
      setSelectedRow(row);
      handleSelectedTemplate(row.original);
    }
  }, [selectedFlatRows, handleSelectedTemplate]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      if (decodedData?.employee_id && selectedForm?.id === 0) {
        getListOfApprovalTemplate({
          variables: {
            employeeId: decodedData.employee_id,
            isAdmin: false // 결재신청 페이지에서 리스트를 요청할 때는 항상 false로 전달
          }
        });
      }
    }
  }, [getListOfApprovalTemplate, selectedForm]);

  return (
    <Container>
      <CompactTable
        title="알림 설정"
        handleSelectRow={handleSelectRow}
        isLoading={false}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={isSelectedRow ? selectedRow : undefined}
        isTitleBar={false}
      />
    </Container>
  );
}

export default ApprovalInfoList;
