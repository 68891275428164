import * as React from "react";
import { CategorySelect, Label } from "./styled";
import uiString from "./ui-string.json";
import PropTypes from "prop-types";

const MobileCategorySelectionView = ({
  updateCategoryId,
  categorySelectionReducer,
  isCategory,
  updateRequestResult,
  categoryId,
  requestResult,
  isRequestDialogPage
}) => {
  const changeCategory = event => {
    updateCategoryId(event.target.value);
  };

  const changeRequestResult = event => {
    updateRequestResult(event.target.value);
  };

  return (
    <React.Fragment>
      <Label>
        {isCategory
          ? uiString.titles.categories
          : uiString.titles.requestResult}
      </Label>
      {isCategory ? (
        <CategorySelect value={categoryId} onChange={changeCategory}>
          {!isRequestDialogPage && (
            <option key={0} value="0">
              전체
            </option>
          )}
          {categorySelectionReducer.categories &&
            categorySelectionReducer.categories.map(v => (
              <option key={v.category_id} value={v.category_id}>
                {v.name}
              </option>
            ))}
        </CategorySelect>
      ) : (
        <CategorySelect value={requestResult} onChange={changeRequestResult}>
          <option key={999} value="999">
            전체
          </option>
          <option key={1} value="1">
            승인
          </option>
          <option key={3} value="3">
            반려
          </option>
          <option key={4} value="4">
            미처리
          </option>
        </CategorySelect>
      )}
    </React.Fragment>
  );
};

MobileCategorySelectionView.propTypes = {
  isCategory: PropTypes.bool,
  categorySelectionReducer: PropTypes.object.isRequired,
  updateCategoryId: PropTypes.func,
  updateRequestResult: PropTypes.func,
  categoryId: PropTypes.string,
  requestResult: PropTypes.string
};

export default MobileCategorySelectionView;
