import styled from "styled-components";
import colors from "../../../../../style-sheet/colors";

export const TodayWorkingInformationBoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${colors.personalDashBoard.todayWorkingHoursBox.backGroundColor};
  border-radius: 2px;
  grid-column: span 8;
  grid-row: span 2;
  flex: 1;
`;

export const WeekWorkingInformationBoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${colors.personalDashBoard.weekWorkingHoursBox.backGroundColor};
  border-radius: 2px;
  grid-column: span 8;
  grid-row: span 2;
  flex: 1;
`;
