import styled from "styled-components";

const AsonicTableRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.littleLightGrey};
  :hover {
    background-color: ${props => props.theme.colors.littleLightGrey};
    color: ${props => props.theme.colors.black};
  }
`;

export default AsonicTableRow;
