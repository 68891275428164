import { useState } from "react";
import MainContainer from "../../../../shared/main_container/main_container";
import SelectedWorkingTemplateInfo from "./selected_working_template_info/selected_working_template_info";
import WorkingTemplateBs from "./working_template_bs/working_template_bs";
import { UserPolicyBasicInfoEntity } from "../../../../../generated/graphql";

function WorkingTemplate() {
  const [basicInfo, setBasicInfo] = useState<UserPolicyBasicInfoEntity>();

  return (
    <MainContainer>
      <WorkingTemplateBs basicInfo={basicInfo} setBasicInfo={setBasicInfo} />
      {basicInfo?.workType === 2 && <SelectedWorkingTemplateInfo />}
    </MainContainer>
  );
}

export default WorkingTemplate;
