import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import stateManager from "./stateManager";
import WorkManagementInfo from "../../../components/main-view/WorkManagementInfo/WorkManagementInfo";
import * as signInActionCreator from "../../../redux/modules/sign-in/signInReducer";
import {
  WorkManagementReducer,
  WorkManagementContext,
  WorkManagementDispatch
} from "./WorkViewContainer/WorkManagementContext";
import { downloadUserInfosExcel } from "../../../Utils/workManagementAxiosCall";
import { useCallback, useReducer } from "react";
import { useOutletContext } from "react-router-dom";

const NUMBER_OF_PEOPLE_FORM = "NUMBER_OF_PEOPLE_FORM";

const mapStateToProps = state => ({
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...signInActionCreator
    },
    dispatch
  )
});

const WorkManagementInfoContainer = props => {
  const { treeData } = useOutletContext();
  const getNumberOfPeopleFromLocalStorage = useCallback(() => {
    const numberOfPeople = window.localStorage.getItem(NUMBER_OF_PEOPLE_FORM);
    if (numberOfPeople) {
      return JSON.parse(numberOfPeople);
    } else {
      return undefined;
    }
  }, []);
  const [workManagementState, dispatch] = useReducer(WorkManagementReducer, {
    userCount: 0,
    selectedDepartment: "",
    downloadUserInfosExcel: downloadUserInfosExcel,
    getNumberOfPeopleFromLocalStorage
  });
  const newProps = stateManager({
    ...props,
    store: workManagementState,
    treeData
  });
  return (
    <WorkManagementDispatch.Provider value={dispatch}>
      <WorkManagementContext.Provider value={workManagementState}>
        <WorkManagementInfo {...props} {...newProps} />
      </WorkManagementContext.Provider>
    </WorkManagementDispatch.Provider>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkManagementInfoContainer);
