import StyledFlexContainer from "../shared/styled_flex_container";
import PageSet from "../table/set_page";
import HideColumn, { IHideColumnProps } from "../table/hide_column";
import PointTitle from "../shared/point_title";
import styled from "styled-components";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../table_v2/fixed_column_dropbox";
import AsonicExcel from "../asonic-table/asonic-excel";
import AsonicSearch, { ISearchProps } from "../asonic-table/asonic-search";
import AsonicHideColumn from "../asonic-table/asonic-hide-column";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox,
    ISearchProps {
  count: number;
  toggleHideColumn: (columnId: string, value?: boolean | undefined) => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

function WorkingSystemHeader<P extends object>({
  columns,
  table,
  title,
  headerTitleList,
  count,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN,
  listOfSearchType,
  handleSearch,
  setIsActiveFilter,
  toggleHideColumn
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title={title} subTitle={`(전체 ${count}개)`} />
        {columns && toggleHideColumn && (
          <AsonicHideColumn
            columns={columns}
            toggleHideColumn={toggleHideColumn}
            title={title}
            setIsActiveFilter={setIsActiveFilter}
          />
        )}
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          {listOfSearchType && (
            <AsonicSearch
              listOfSearchType={listOfSearchType}
              handleSearch={handleSearch}
            />
          )}
        </SearchContainer>
      </LeftSection>
    </Container>
  );
}

export default WorkingSystemHeader;
