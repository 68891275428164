import StyledFlexContainer from "../../../../shared/styled_flex_container";
import PageSet from "../../../../table/set_page";
import HideColumn, { IHideColumnProps } from "../../../../table/hide_column";
import PointTitle from "../../../../shared/point_title";
import styled from "styled-components";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../../table_v2/fixed_column_dropbox";
import AsonicExcel from "../../../../asonic-table/asonic-excel";
import AsonicSearch, {
  ISearchProps
} from "../../../../asonic-table/asonic-search";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox,
    ISearchProps {
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

function ApprovalNotificationEmployeeHeader<P extends object>({
  columns,
  table,
  title,
  headerTitleList,
  take,
  handleTake,
  count,
  downloadExcel,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN,
  listOfSearchType,
  handleSearch
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title="검색결과" subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          {listOfSearchType && (
            <AsonicSearch
              listOfSearchType={listOfSearchType}
              handleSearch={handleSearch}
            />
          )}
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
      <FileContainer>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </FileContainer>
    </Container>
  );
}

export default ApprovalNotificationEmployeeHeader;
