import styled from "styled-components";

export const TextFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  width: calc(100% - 32px);
  margin: 20px 0px 20px 0px;
`;

export const TextFieldTitle = styled.label`
  position: absolute;
  top: ${props => (props.value ? "-14px" : "0px")};
  left: ${props => (props.value ? "0px" : "10px")};
  opacity: ${props => (props.value ? "1" : "0")};
  color: ${props => (props.value ? "black" : "rgba(0, 0, 0, 0.54)")};
  visibility: ${props => (props.value ? "visible" : "hidden")};
  transition: all 0.1s ease-in-out;
`;

export const STextField = styled.input`
  border: none;
  outline: none;
  padding: 5px;
  transition: all 0.1s ease-in-out;
  :focus + ${TextFieldTitle} {
    top: -14px;
    left: 0px;
    opacity: 1;
    visibility: visible;
    color: black;
  }
`;
