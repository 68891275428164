import styled from "styled-components";
import GroupTableHeader from "../group_table/group_table_header/group_table_header";

const GTableData = styled(GroupTableHeader)<{
  isOverFlow: boolean;
  isGroup?: boolean;
  $isSelected?: boolean;
}>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props =>
    props.$isSelected ? props.theme.colors.green : props.theme.colors.white};
  color: ${props =>
    props.$isSelected ? props.theme.colors.white : props.theme.colors.black};
  :last-child {
    width: ${props => {
      if (navigator.userAgent.indexOf("Edg") != -1) {
        if (props.isGroup) {
          return "";
        } else {
          return props.isOverFlow ? "135px !important" : "2px";
        }
      }
      if (
        navigator.userAgent.indexOf("Chrome") != -1 &&
        navigator.userAgent.indexOf("Whale") == -1
      ) {
        if (props.isGroup) {
          return "";
        } else {
          return props.isOverFlow ? "133px !important" : "2px";
        }
      }
      return "";
    }};
    margin-right: ${props => {
      if (navigator.userAgent.indexOf("Mac") != -1) {
        return props.isOverFlow ? "17px" : "2px";
      } else if (
        navigator.userAgent.indexOf("Chrome") != -1 &&
        navigator.userAgent.indexOf("Whale") == -1
      ) {
        console.log("chrome whale");
        return props.isOverFlow ? "19px" : "2px";
        // if (props.isGroup) {
        //   return "2px";
        // } else {
        //   return props.isOverFlow ? "19px" : "2px";
        // }
      } else if (navigator.userAgent.indexOf("Edg") != -1) {
        if (props.isGroup) {
          return "1000px";
        } else {
          return "2px";
        }
      }
      return "2px";
    }};
  }
`;

export default GTableData;
