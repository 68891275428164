import {
  IMApprovalEntity,
  MApprovalIProps,
  MContainer,
  MListContainer
} from "./m_approval_waiting_list";
import { AutoSizer } from "react-virtualized";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  ApprovalSearchType,
  Approval_For_List_Type,
  useGetListOfApprovalLazyQuery
} from "../../../generated/graphql";
import MApprovalRow from "./m_approval_row";
import { isSafari } from "react-device-detect";

function MApprovalReceiveList({
  employeeId,
  searchValue,
  count,
  handleToast
}: MApprovalIProps) {
  const listRef: any = useRef();
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [list, setList] = useState<IMApprovalEntity[]>([]);
  const [page, setPage] = useState<number>(1);
  const take = useMemo(() => 30, []);
  const [getListOfApproval, { loading }] = useGetListOfApprovalLazyQuery({
    onCompleted(data) {
      if (data.getListOfApproval.ok && data.getListOfApproval.list) {
        setList(pre => {
          const newList = [...pre];
          if (data.getListOfApproval.list) {
            data.getListOfApproval.list.forEach(item => {
              const isExist = pre.find(
                originItem =>
                  originItem.approvalRequestIdx === item.approvalRequestIdx
              );
              if (!isExist) {
                newList.push({ ...item, isOpen: false });
              }
            });
          }
          return newList;
        });
      }
    }
  });

  const handleScroll = useCallback(() => {
    const scrollHeight = listRef.current.scrollHeight;
    const scrollTop = listRef.current.scrollTop;
    const clientHeight = listRef.current.clientHeight;
    const takeingPoint = scrollHeight * 0.35;

    let bottom = scrollHeight - scrollTop < takeingPoint;
    const newTotalPage = Math.ceil(count / take);

    if (isSafari && navigator.userAgent.indexOf("Whale") === -1) {
      bottom = scrollHeight - scrollTop - 1 === clientHeight;
    }

    if (
      bottom &&
      scrollHeight !== 0 &&
      newTotalPage &&
      page < newTotalPage &&
      !loading
    ) {
      setPage(prePage => {
        return prePage + 1;
      });
    }
  }, [count, take, page, loading]);

  useEffect(() => {
    getListOfApproval({
      variables: {
        approvalType: Approval_For_List_Type.Receive,
        searchType: ApprovalSearchType.App,
        searchValue,
        take,
        page,
        customEmployeeId: employeeId
      }
    });
  }, [getListOfApproval, take, employeeId, searchValue, page]);

  useLayoutEffect(() => {
    if (isRendered) {
      if (listRef.current) {
        listRef.current?.addEventListener("scroll", handleScroll);
      }
    }
    if (!isRendered) {
      if (listRef.current) {
        setIsRendered(true);
      }
    }
    return () => {
      listRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isRendered, handleScroll]);

  return (
    <MContainer>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <MListContainer
              $height={height}
              $width={width}
              ref={listRef}
              $loading={loading}
            >
              {list.map(item => (
                <MApprovalRow
                  setList={setList}
                  data={item}
                  key={item.approvalRequestIdx}
                  handleToast={handleToast}
                  employeeId={employeeId}
                  isEdit
                />
              ))}
            </MListContainer>
          );
        }}
      </AutoSizer>
    </MContainer>
  );
}

export default MApprovalReceiveList;
