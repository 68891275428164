import * as React from "react";
import {
  SerializeItem,
  GoogleEvent,
  ListOfGoogleColor
} from "../../../../hooks/link-google-hooks/use-google-calendar";

const MiniCalendarContext = React.createContext<{
  googleCalendarlist: SerializeItem[];
  googleEventOfList: GoogleEvent[];
  listOfGoogleColor: ListOfGoogleColor;
  currentMoment?: string;
  selectedEventList?: Partial<DayInfo>[];
  handleSelectedEventList?: (payload: Partial<DayInfo>[]) => void;
  selectedDate: string;
  handleSelectedDate?: (fullDate: string) => void;
}>({
  googleCalendarlist: [],
  googleEventOfList: [],
  listOfGoogleColor: { calendar: {}, event: {} },
  selectedDate: ""
});

export default MiniCalendarContext;
