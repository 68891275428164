import TColumnComponent, { ITColumnComponentProps } from "./t_column_component";
import TBody from "./t_body";
import THead from "./t_head";
import THeaderRow from "./t_header_row";
import TLayout from "./t_layout";
import TMainContainer from "./t_main_container";
import * as ReactTable from "react-table";
import TRenderRow, { TRenderRowProps } from "./t_render_row";
import { useLoading } from "../../screens/components/loading-context";
import { useEffect } from "react";

export interface ITableProps<P extends object>
  extends Partial<ITColumnComponentProps<P>>,
    Partial<TRenderRowProps<P>> {
  title: string;
  table: ReactTable.TableInstance<P>;
  height: number;
  width: number;
  loading?: boolean;
}

function TableV2<P extends object>({
  table,
  title,
  fieldSort,
  handleFieldSort,
  listOfColumnDisabled,
  moveColumn,
  selectedRow,
  height,
  width,
  handleSelectRow,
  handleContextMenu,
  loading,
  isCustomSelect,
  isGroupColor
}: ITableProps<P>) {
  const { setIsLoading } = useLoading();
  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading]);

  return (
    <TLayout $height={height} $width={width}>
      <TMainContainer {...table.getTableProps()} className="table sticky">
        <THead className="header">
          {table.headerGroups.map(headerGroup => (
            <THeaderRow {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column, index) => (
                <TColumnComponent<P>
                  key={column.id}
                  column={column}
                  index={index}
                  fieldSort={fieldSort}
                  handleFieldSort={handleFieldSort}
                  listOfColumnDisabled={listOfColumnDisabled}
                  moveColumn={moveColumn}
                  title={title}
                />
              ))}
            </THeaderRow>
          ))}
        </THead>
        <TBody {...table.getTableBodyProps()} className="body">
          {!loading &&
            table.rows.map(item => {
              return (
                <TRenderRow
                  key={item.id}
                  prepareRow={table.prepareRow}
                  handleSelectRow={handleSelectRow}
                  handleContextMenu={handleContextMenu}
                  selectedRow={selectedRow}
                  row={item}
                  data={table.rows}
                  isCustomSelect={isCustomSelect}
                  isGroupColor={isGroupColor}
                />
              );
            })}
        </TBody>
      </TMainContainer>
    </TLayout>
  );
}

export default TableV2;
