import styled from "styled-components";

export const Container = styled.div`
  grid-column: span 1;
  grid-row: 4 / span 5;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleBar = styled.div`
  font-weight: bold;
`;

export const Title = styled.span``;

export const Time = styled.span`
  margin-left: 2%;
  color: #61c7ec;
`;

export const ChartSection = styled.div`
  width: 100%;
  height: 40%;
  background-color: white;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  display: flex;
  font-weight: bold;
  color: #72797d;
`;

export const ChartTitleBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartTitle = styled.span``;

export const ChartContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: bottom;
  min-width: 200px;
  min-height: 70px;
`;

export const ChartDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChartDescriptionTitle = styled.span``;

export const ChartDescriptionContent = styled.span`
  background-color: #ededed;
  padding: 3px;
  border-radius: 3px;
`;
