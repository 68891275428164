import api from "./api";
import pageRoutes from "./page";
import approvalApi from "./approvalApi";
import chartApi from "./chartApi";
import mainViewApi from "./mainViewApi";
import mapApi from "./mapApi";
import overTimeCategoriesApi from "./overTimeCategoriesApi";
import requestApi from "./requestApi";
import settingsApi from "./settingsApi";
import workHourApi from "./workHourApi";
import signInApi from "./signInApi";
import statisticsApi from "./statisticsApi";
import searchApi from "./searchApi";
import workingTimeScheduleApi from "./workingTimeScheduleApi";
import commuteApi from "./commuteApi";
import calendarApi from "./calendar-api";
import masterWorkManagementPageApi from "./master-work-management-page-api";
import personalApi from "./dashboard-api/personal-api";
import serviceStatusRoute from "./electron/service-status-route";
import placeFreeManagementRoutes from "./place-free-management-routes";
import additionalInformationManagementRoutes from "./detail-routes/additional-information-management-routes";
import approvalNotificationManagementRoutes from "./approval-notification-management-routes/approval-notification-management-routes";
import electronProfileRoute from "./electron/electron-profile-route";
import widgetUserSettingRoute from "./electron/widget-user-setting-route";
import menuSettingsRoutes from "./menu-settings-routes";

const apiRoutes = {
  api: api,
  dashboard: {
    personalApi
  },
  calendar: calendarApi,
  workHour: workHourApi,
  signIn: signInApi,
  mainview: mainViewApi,
  map: mapApi,
  request: requestApi,
  approval: approvalApi,
  statistics: statisticsApi,
  settings: settingsApi,
  overtimecategories: overTimeCategoriesApi,
  chart: chartApi,
  search: searchApi,
  workingTimeSchedule: workingTimeScheduleApi,
  commute: commuteApi,
  masterWorkManagementPage: masterWorkManagementPageApi,
  placeFreeManagement: placeFreeManagementRoutes,
  additionalInformationManagement: additionalInformationManagementRoutes,
  approvalNotificationManagementRoutes,
  menuSettingsRoutes,
  kakao: {
    base: api.kakao
  },
  electron: {
    serviceStatusRoute,
    electronProfileRoute,
    widgetUserSettingRoute
  }
};

const routes = {
  pageRoutes,
  apiRoutes
};
export default routes;
