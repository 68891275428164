import * as React from "react";

type DataSets = {
  backgroundColor: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  data: number[];
  label: string;
  borderColor?: string[];
  borderWidth?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
};

type Props = {
  workingTime?: number;
  maxWorkingTime?: number;
  maxOverWorkingTime?: number;
  remainWorkingTime?: number;
  overRemainingWorkingTime?: number;
  overWorkingTimeColor?: string;
  WorkingTimeColor?: string;
  isToday: boolean;
};

const useWorkingInformationChart = <P extends Props>({
  isToday,
  workingTime,
  maxWorkingTime,
  maxOverWorkingTime,
  overRemainingWorkingTime,
  remainWorkingTime,
  overWorkingTimeColor,
  WorkingTimeColor
}: P) => {
  const isMount = React.useRef<boolean>(false);
  const [datasets, setDatasets] = React.useState<DataSets[]>([]);

  const handleDatasets = React.useCallback(() => {
    const totalTime = isToday ? 1440 : 3120;
    if (
      isMount.current &&
      workingTime !== undefined && // 금일 총 근무시간 16시간-
      maxWorkingTime !== undefined && // 정규 최대 근무 시간
      maxOverWorkingTime !== undefined && // 연장 최대 근무 시간
      overRemainingWorkingTime !== undefined && // 연장 잔여 시간
      remainWorkingTime !== undefined && // 정규 잔여 시간
      overWorkingTimeColor &&
      WorkingTimeColor
    ) {
      const remainTime = totalTime - (maxWorkingTime + maxOverWorkingTime);
      setDatasets([
        {
          backgroundColor: WorkingTimeColor,
          barThickness: 18,
          maxBarThickness: 18,
          minBarLength: 4,
          data: [maxWorkingTime],
          label: "정규"
        },
        {
          backgroundColor: overWorkingTimeColor,
          barThickness: 18,
          maxBarThickness: 18,
          minBarLength: 4,
          data: [maxOverWorkingTime],
          label: "연장"
        },
        {
          backgroundColor: "#c4c4c4",
          barThickness: 18,
          maxBarThickness: 18,
          minBarLength: 4,
          data: [remainTime < 0 ? 0 : remainTime],
          label: "잔여"
        }
      ]);
    }
  }, [
    isToday,
    workingTime,
    maxWorkingTime,
    maxOverWorkingTime,
    overRemainingWorkingTime,
    remainWorkingTime,
    overWorkingTimeColor,
    WorkingTimeColor
  ]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }

    handleDatasets();
    return () => {
      isMount.current = false;
    };
  }, [handleDatasets]);

  return { datasets };
};

export default useWorkingInformationChart;
