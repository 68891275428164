import axios from "axios";
import Auth from "../../../Auth/Auth";
import { WEB_SERVER_ADDR } from "../../../constValues";

export async function getOvertimeCategories() {
  let restUri = `${WEB_SERVER_ADDR}/api/overtimecategories/categories`;

  try {
    const response = await axios.get(restUri, {
      headers: {
        token: Auth.getUserToken()
      }
    });
    if (response.data.error) {
      return [];
    }
    return response.data;
  } catch (err) {
    console.warn(err);
  }
  return [];
}
