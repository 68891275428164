import styled from "styled-components";

const TableLayoutContainer = styled.div`
  display: flex;
  flex: 10;
  overflow-y: hidden;
  overflow-x: auto;
  will-change: scroll-position;
`;

export default TableLayoutContainer;
