import * as React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { MessageTypes } from "../../../../components/toast-message/toast-message";

const StateManager = ({ handleToast }) => {
  const isMount = React.useRef(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [categoryId, setCategoryId] = React.useState("0");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [comment, setComment] = React.useState("");
  const [alterStartDate, setAlterStartDate] = React.useState(new Date());
  const [alterEndDate, setAlterEndDate] = React.useState(new Date());
  const [alterStartTime, setAlterStartTime] = React.useState(new Date());
  const [alterEndTime, setAlterEndTime] = React.useState(new Date());
  const [nameOfVoter, setNameOfVoter] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const [selectedVoter, setSelectedVoter] = React.useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const categorySelectionReducer = useSelector(
    state => state.categorySelectionReducer
  );

  const handleAlertOpen = () => {
    if (alertOpen) {
      setAlertOpen(false);
    } else if (selectedVoter.length < 1) {
      handleToast("지정된 결재자가 없습니다.", MessageTypes.WARNING);
    } else if (setAlertOpen && selectedVoter.length > 0) {
      setAlertOpen(true);
    }
  };

  const handleSearchResult = value => {
    setSearchResult(value);
  };

  const handleNameOfVoter = event => {
    setNameOfVoter(event.target.value);
  };

  const handleAlterEndTime = event => {
    setAlterEndTime(event.target.value);
  };

  const handleAlterStartTime = event => {
    setAlterStartTime(event.target.value);
  };

  const handleAlterEndDate = event => {
    if (event.target) {
      setAlterEndDate(moment(event.target.value).toDate());
    } else {
      setAlterEndDate(moment(event).toDate());
    }
  };

  const handleAlterStartDate = event => {
    if (event.target) {
      setAlterStartDate(moment(event.target.value).toDate());
    } else {
      setAlterStartDate(moment(event).toDate());
    }
  };

  const handleComment = event => {
    if (event) {
      setComment(event.target.value);
    } else {
      setComment("");
    }
  };

  const handleNextActiveStep = (isCheck, comment) => () => {
    if (isCheck && activeStep === 1 && !comment) {
      handleToast("결재 사유를 적어주세요", MessageTypes.WARNING);
    } else if (!isCheck && activeStep === 0 && !comment) {
      handleToast("결재 사유를 적어주세요", MessageTypes.WARNING);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBackActiveStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const updateCategoryId = React.useCallback(id => {
    setCategoryId(id);
  }, []);

  const handleStartTime = event => {
    setStartTime(event.target.value);
  };

  const handleEndTime = event => {
    setEndTime(event.target.value);
  };

  const handleAddVoter = user => () => {
    const updatedSearchResult = searchResult.filter(
      originalUser => originalUser.employee_id !== user.employee_id
    );
    handleSearchResult(updatedSearchResult);
    const updatedSelectedVoter = selectedVoter.filter(
      originalUser => originalUser.employee_id !== user.employee_id
    );
    setSelectedVoter([...updatedSelectedVoter, user]);
  };

  const handleDeleteVoter = user => () => {
    const updatedSelectedVoter = selectedVoter.filter(
      originalUser => originalUser.employee_id !== user.employee_id
    );
    setSelectedVoter(updatedSelectedVoter);
    const updatedSearchResult = searchResult.filter(
      originalUser => originalUser.employee_id !== user.employee_id
    );
    setSearchResult([...updatedSearchResult, user]);
  };

  const handleSelectedVoterPosition = React.useCallback(
    (user, direction) => () => {
      const temporaryArray = [...selectedVoter];
      const updateUserIndex = selectedVoter.indexOf(user);
      if (direction === "up" && updateUserIndex !== 0) {
        const preItem = temporaryArray[updateUserIndex - 1];
        temporaryArray[updateUserIndex - 1] = temporaryArray[updateUserIndex];
        temporaryArray[updateUserIndex] = preItem;
        setSelectedVoter(temporaryArray);
      } else if (
        direction === "down" &&
        updateUserIndex !== selectedVoter.length - 1
      ) {
        const preItem = temporaryArray[updateUserIndex + 1];
        temporaryArray[updateUserIndex + 1] = temporaryArray[updateUserIndex];
        temporaryArray[updateUserIndex] = preItem;
        setSelectedVoter(temporaryArray);
      }
    },
    [selectedVoter]
  );

  const handleInitial = React.useCallback(() => {
    setStartTime(new Date());
    setEndTime(new Date());
    setComment("");
    setAlterStartDate("");
    setAlterEndDate("");
    setAlterStartTime(new Date());
    setAlterEndTime(new Date());
  }, []);

  React.useEffect(() => {
    if (
      !isMount.current &&
      updateCategoryId &&
      categorySelectionReducer.categories.length > 0
    ) {
      updateCategoryId(
        categorySelectionReducer.categories[0].category_id.toString()
      );
      isMount.current = true;
    }
  }, [updateCategoryId, categorySelectionReducer]);

  return {
    activeStep,
    handleNextActiveStep,
    handleBackActiveStep,
    categoryId,
    updateCategoryId,
    startTime,
    handleStartTime,
    endTime,
    handleEndTime,
    comment,
    handleComment,
    alterStartDate,
    handleAlterStartDate,
    alterEndDate,
    handleAlterEndDate,
    alterStartTime,
    handleAlterStartTime,
    alterEndTime,
    handleAlterEndTime,
    nameOfVoter,
    handleNameOfVoter,
    searchResult,
    handleSearchResult,
    selectedVoter,
    handleAddVoter,
    handleDeleteVoter,
    setSelectedVoter,
    handleSelectedVoterPosition,
    handleInitial,
    alertOpen,
    handleAlertOpen,
    setActiveStep
  };
};

export default StateManager;
