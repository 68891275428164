import { createContext, useCallback, useEffect, useState } from "react";
import { Work_Type } from "../../../generated/graphql";
import SectionContainer from "../../shared/section_container/section_container";
import AutonomyAttendance from "./autonomy_attendance/autonomy_attendance";
import ChoiceWS from "./choice_w_s/choice_w_s";
import FlexibleWS from "./flexible_w_s/flexible_w_s";
import TDType from "./t_d_type";

interface IProps {
  workType: Work_Type;
}

interface IWeekWorkHour {
  firstWeekWH: number;
  secondWeekWH: number;
  thirdWeekWH: number;
  fourthWeekWH: number;
  fifthWeekWH: number;
  totalWorkHour: number;
}

interface IFlexibleWSSTore {
  settlementSectionHour: number;
  weekWH: IWeekWorkHour;
  handleWeekWH: (payload: { weekSequence: number; workHour: number }) => void;
  handleSettleMentSectionHour: (payload: number) => void;
}

export const FlexibleWSStore = createContext<IFlexibleWSSTore>(
  {} as IFlexibleWSSTore
);

function WorkTimeTab({ workType }: IProps) {
  const [settlementSectionHour, setSettlementSectionHour] = useState<number>(1);
  const [weekWH, setWeekWH] = useState<IWeekWorkHour>({
    firstWeekWH: 0,
    secondWeekWH: 0,
    thirdWeekWH: 0,
    fourthWeekWH: 0,
    fifthWeekWH: 0,
    totalWorkHour: 0
  });
  const handleSettleMentSectionHour = useCallback((payload: number) => {
    setSettlementSectionHour(payload);
  }, []);

  const handleWeekWH = useCallback(
    ({
      weekSequence,
      workHour
    }: {
      weekSequence: number;
      workHour: number;
    }) => {
      const newWeekWH = { ...weekWH };
      switch (weekSequence) {
        case 1:
          if (newWeekWH.firstWeekWH !== workHour) {
            newWeekWH.firstWeekWH = workHour;
            newWeekWH.totalWorkHour =
              newWeekWH.firstWeekWH +
              newWeekWH.secondWeekWH +
              newWeekWH.thirdWeekWH +
              newWeekWH.fourthWeekWH +
              newWeekWH.fifthWeekWH;
            setWeekWH(newWeekWH);
          }
          break;
        case 2:
          if (newWeekWH.secondWeekWH !== workHour) {
            newWeekWH.secondWeekWH = workHour;
            newWeekWH.totalWorkHour =
              newWeekWH.firstWeekWH +
              newWeekWH.secondWeekWH +
              newWeekWH.thirdWeekWH +
              newWeekWH.fourthWeekWH +
              newWeekWH.fifthWeekWH;
            setWeekWH(newWeekWH);
          }
          break;
        case 3:
          if (newWeekWH.thirdWeekWH !== workHour) {
            newWeekWH.thirdWeekWH = workHour;
            newWeekWH.totalWorkHour =
              newWeekWH.firstWeekWH +
              newWeekWH.secondWeekWH +
              newWeekWH.thirdWeekWH +
              newWeekWH.fourthWeekWH +
              newWeekWH.fifthWeekWH;
            setWeekWH(newWeekWH);
          }
          break;
        case 4:
          if (newWeekWH.fourthWeekWH !== workHour) {
            newWeekWH.fourthWeekWH = workHour;
            newWeekWH.totalWorkHour =
              newWeekWH.firstWeekWH +
              newWeekWH.secondWeekWH +
              newWeekWH.thirdWeekWH +
              newWeekWH.fourthWeekWH +
              newWeekWH.fifthWeekWH;
            setWeekWH(newWeekWH);
          }
          break;
        case 5:
          if (newWeekWH.fifthWeekWH !== workHour) {
            newWeekWH.fifthWeekWH = workHour;
            newWeekWH.totalWorkHour =
              newWeekWH.firstWeekWH +
              newWeekWH.secondWeekWH +
              newWeekWH.thirdWeekWH +
              newWeekWH.fourthWeekWH +
              newWeekWH.fifthWeekWH;
            setWeekWH(newWeekWH);
          }
          break;
      }
    },
    [weekWH]
  );

  useEffect(() => {
    switch (settlementSectionHour) {
      case 1:
        setWeekWH(weekWH => {
          const newWeekWH = { ...weekWH };
          newWeekWH.secondWeekWH = 0;
          newWeekWH.thirdWeekWH = 0;
          newWeekWH.fourthWeekWH = 0;
          newWeekWH.fifthWeekWH = 0;
          newWeekWH.totalWorkHour =
            newWeekWH.firstWeekWH +
            newWeekWH.secondWeekWH +
            newWeekWH.thirdWeekWH +
            newWeekWH.fourthWeekWH +
            newWeekWH.fifthWeekWH;
          return newWeekWH;
        });
        break;
      case 2:
        setWeekWH(weekWH => {
          const newWeekWH = { ...weekWH };
          newWeekWH.thirdWeekWH = 0;
          newWeekWH.fourthWeekWH = 0;
          newWeekWH.fifthWeekWH = 0;
          newWeekWH.totalWorkHour =
            newWeekWH.firstWeekWH +
            newWeekWH.secondWeekWH +
            newWeekWH.thirdWeekWH +
            newWeekWH.fourthWeekWH +
            newWeekWH.fifthWeekWH;
          return newWeekWH;
        });
        break;
      case 3:
        setWeekWH(weekWH => {
          const newWeekWH = { ...weekWH };
          newWeekWH.fourthWeekWH = 0;
          newWeekWH.fifthWeekWH = 0;
          newWeekWH.totalWorkHour =
            newWeekWH.firstWeekWH +
            newWeekWH.secondWeekWH +
            newWeekWH.thirdWeekWH +
            newWeekWH.fourthWeekWH +
            newWeekWH.fifthWeekWH;
          return newWeekWH;
        });
        break;
      case 4:
        setWeekWH(weekWH => {
          const newWeekWH = { ...weekWH };
          newWeekWH.fifthWeekWH = 0;
          newWeekWH.totalWorkHour =
            newWeekWH.firstWeekWH +
            newWeekWH.secondWeekWH +
            newWeekWH.thirdWeekWH +
            newWeekWH.fourthWeekWH +
            newWeekWH.fifthWeekWH;
          return newWeekWH;
        });
        break;
    }
  }, [settlementSectionHour]);

  return (
    <FlexibleWSStore.Provider
      value={{
        settlementSectionHour,
        weekWH,
        handleWeekWH,
        handleSettleMentSectionHour
      }}
    >
      <>
        {workType === Work_Type.SFCommutingPolicy && <TDType />}
        {workType === Work_Type.FlexibleWorkingHoursPolicy && <FlexibleWS />}
        {workType === Work_Type.FlexibleSchedulePolicy && <ChoiceWS />}
        {workType === Work_Type.AutonomousCommutingPolicy && (
          <AutonomyAttendance />
        )}
      </>
    </FlexibleWSStore.Provider>
  );
}

export default WorkTimeTab;
