import styled from "styled-components";

export const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  /* min-width: 1600px;
  min-height: 900px; */
`;

export const SearchBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  max-width: max-content;
`;

export const StyledButton = styled.button`
  outline: none;
  background-color: #4193cc;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 100px;
  :disabled {
    background-color: #7f8c8d;
    :hover {
      background-color: #7f8c8d;
    }
  }
  :hover {
    background-color: #74b9ff;
  }
  :active {
    transform: scale(0.98);
  }
`;
