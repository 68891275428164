import styled from "styled-components";
// import profilePicture from "../../images/basicimage.jpg";
// import { colors } from "../GlobalStyle/GlobalStyle";
interface IProps {
  name: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

// const Picture = styled.picture``;
// const Img = styled.img`
//   border-radius: 50%;
//   height: 30px;
//   width: 30px;
//   border: 4px solid ${colors.veryDarkBlue};
// `;
const Name = styled.span``;

function AsonicUserProfile({ name }: IProps) {
  return (
    <Container>
      {/* <Picture>
        <Img src={profilePicture} />
      </Picture> */}
      <Name>{name}</Name>
    </Container>
  );
}

export default AsonicUserProfile;
