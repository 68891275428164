import { createContext } from "react";
import { MessageTypes } from "../../../../toast-message/toast-message";
import { IApprovalNotificationTemplate } from "../../../../../hooks/approval-notification-management-hook/use-approval-notification-template";
interface IApprovalNotificationManagementCtx {
  handleToast: (value: string, type: MessageTypes) => void;
  selectedTemplate: IApprovalNotificationTemplate;
  getApprovalNotificationListOfSchedules: () => void;
}

const ApprovalNotificationManagementDialogCtx = createContext<
  Partial<IApprovalNotificationManagementCtx>
>({});

export default ApprovalNotificationManagementDialogCtx;
