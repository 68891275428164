import { useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import listOfApolloVar from "../../apollo/apollo-var";

export const TRANS_TOKEN = "KYYL2+Yu443WOEGs5UBOMB0GA1UdDgQWB";

interface ISocket {
  employeeId: string;
  roomIdx?: number;
  isConnecting?: boolean;
}

function useSocket({ roomIdx, employeeId, isConnecting }: ISocket) {
  const chatServerIp = useReactiveVar(listOfApolloVar.chatServerIp);
  const [socket, setSocket] = useState<WebSocket>();
  const connect = useCallback(() => {
    if (chatServerIp) {
      const newSocket = new WebSocket(`ws://${chatServerIp}/ws`);
      setSocket(newSocket);
    }
  }, [chatServerIp]);

  useEffect(() => {
    if (isConnecting) {
      connect();
    }
  }, [isConnecting, connect]);
  useEffect(() => {
    if (socket) {
      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] The connection ended successfully (code=${event.code} reason=${event.reason})`
          );
        } else {
          console.log("[close] The connection is dead.");
          setTimeout(connect, 2000);
        }
        setSocket(undefined);
      };
      socket.onopen = () => {
        console.log("[connect] socket connected");
        if (roomIdx) {
          let auth = {
            authToken: TRANS_TOKEN,
            roomId: roomIdx,
            employeeId: employeeId
          };
          socket.send(JSON.stringify(auth));
        }
      };
    }
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket, employeeId, roomIdx, connect]);
  return { socket };
}

export default useSocket;
