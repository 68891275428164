import styled from "styled-components";
import AttendanceStatus from "./attendance_status";
import { useState } from "react";
import * as ReactTable from "react-table";
import AttendanceDetails from "../attendance_details/attendance_details";
import { StatisticsConditionEntity } from "../../../../generated/graphql";

interface IProps {
  isAdmin: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
`;

const TopSection = styled.section`
  display: flex;
  flex: 3;
`;

const BodySection = styled.section`
  display: flex;
  flex: 5;
`;

function StatisticsStatus({ isAdmin }: IProps) {
  const [selectedRow, setSelectedRow] = useState<
    ReactTable.Row<StatisticsConditionEntity> | undefined
  >();

  return (
    <Container>
      <TopSection>
        <AttendanceStatus isAdmin={isAdmin} setSelectedRow={setSelectedRow} />
      </TopSection>
      <BodySection>
        <AttendanceDetails selectedRow={selectedRow} />
      </BodySection>
    </Container>
  );
}

export default StatisticsStatus;
