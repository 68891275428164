import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { TOKEN } from "../../../apollo/apollo";
import { useConfirmIsApprovalPasswordLazyQuery } from "../../../generated/graphql";
import tokenMaker, { handleDecodedToken } from "../../../Utils/tokenMaker";
import Button from "../../globalComponents/Button";
import StyleInput from "../../inputs/style-input";
import FormRow from "../../shared/form-row/form-row";
import { MessageTypes } from "../../toast-message/toast-message";

interface IProps {
  handleOpenPasswordDialog: (value: boolean) => void;
  handleToast: (message: string, type: MessageTypes) => void;
  handleOpenSignAndRejectDialog?: (value: boolean) => void;
}

interface IApprovalPasswordValue {
  password: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

function ApprovalPassword({
  handleOpenPasswordDialog,
  handleToast,
  handleOpenSignAndRejectDialog
}: IProps) {
  const { register, handleSubmit } = useForm<IApprovalPasswordValue>();
  const name = useMemo((): string => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData.name;
    }
    return "";
  }, []);

  const [confirmIsApprovalPassword] = useConfirmIsApprovalPasswordLazyQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.confirmIsApprovalPassword.ok) {
        if (handleOpenSignAndRejectDialog) {
          handleOpenPasswordDialog(false);
          handleOpenSignAndRejectDialog(true);
        }
      } else {
        handleToast("비밀번호가 일치하지 않습니다.", MessageTypes.ERROR);
      }
    }
  });
  const handleConfirm = useCallback(
    (data: IApprovalPasswordValue) => {
      const password = tokenMaker(data.password);
      if (password) {
        confirmIsApprovalPassword({ variables: { password } });
      }
    },
    [confirmIsApprovalPassword]
  );

  return (
    <Container>
      <Content>
        <FormRow title="사용자명">
          <h4>{name}</h4>
        </FormRow>
        <FormRow title="비밀번호">
          <StyleInput
            {...register("password", {
              required: true
            })}
            type="password"
            autoComplete="on"
          />
        </FormRow>
      </Content>
      <ButtonContainer>
        <Button
          customMinWidth="20px"
          onClick={event => {
            handleOpenPasswordDialog(false);
          }}
        >
          취소
        </Button>
        <Button customMinWidth="20px" onClick={handleSubmit(handleConfirm)}>
          확인
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default ApprovalPassword;
