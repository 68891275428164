import { useCallback, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { ChatContext, ChatHandleContext, LIST_OF_CHAT_MENU } from "./chat";
import { Icon } from "@iconify/react";
import MenuIcon from "@iconify-icons/eva/menu-fill";
import ChatRoomSetting from "./chat-room-setting";

const Container = styled.div`
  position: relative;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  justify-items: center;
  align-items: center;
  padding: 10px;
`;

const Button = styled.aside`
  cursor: pointer;
  :first-child {
    justify-self: flex-start;
    font-size: 18px;
  }
  font-size: 30px;
  justify-self: flex-end;
  font-weight: bold;
  user-select: none;
  align-items: center;
  :hover {
    color: ${props => props.theme.colors.skyBlue};
  }
  :active {
    transform: scale(0.98);
  }
`;

function ChatRoomTitleBar() {
  const titleBarRef = useRef<HTMLDivElement>(null);
  const chatHandleContext = useContext(ChatHandleContext);
  const chatContext = useContext(ChatContext);

  const handleCloseSettingMenu = useCallback(() => {
    chatHandleContext?.handleIsRoomSetting(false);
  }, [chatHandleContext]);

  useEffect(() => {
    const containerRef = titleBarRef.current;
    containerRef?.addEventListener("mouseleave", handleCloseSettingMenu);
    return () => {
      containerRef?.removeEventListener("mouseleave", handleCloseSettingMenu);
    };
  }, [handleCloseSettingMenu]);
  return (
    <Container ref={titleBarRef}>
      <Button
        onClick={() => {
          if (chatHandleContext?.handleMenu) {
            chatHandleContext.handleMenu(LIST_OF_CHAT_MENU.CHAT);
            chatContext?.socket?.close();
          }
        }}
      >{`<`}</Button>
      <h2>{chatContext?.selectedChatRoomName}</h2>
      <Button
        onClick={() => {
          chatHandleContext?.handleIsRoomSetting(true);
        }}
      >
        <Icon icon={MenuIcon} />
      </Button>
      {chatContext?.isRoomSetting && <ChatRoomSetting />}
    </Container>
  );
}

export default ChatRoomTitleBar;
