import styled from "styled-components";
import MiniCalendarCell from "../mini-calendar-cell/mini-calendar-cell";

interface IProps {
  cells: DayInfo[];
  isFirstWeek: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  border-top: 1px solid black;
  :last-child {
    border-bottom: 1px solid black;
  }
`;

const MiniCalendarWeek = <P extends IProps>({ cells, isFirstWeek }: P) => {
  return (
    <Container>
      {cells.map(cell => (
        <MiniCalendarCell
          cell={cell}
          key={Symbol(cell.fullDate).toString()}
          isFirstWeek={isFirstWeek}
        />
      ))}
    </Container>
  );
};

export default MiniCalendarWeek;
