import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ItemContainer = styled.div`
  width: 100%;
  position: relative;
  :not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  }
`;

export const Item = styled.div<{ isList?: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${props => {
      if (props.isList) {
        return props.theme.colors.softGreen;
      }
      return props.theme.colors.green;
    }};
    color: ${props => {
      if (props.isList) {
        return props.theme.colors.green;
      }
      return props.theme.colors.white;
    }};
    div {
      visibility: visible;
    }
  }
`;

export const ItemTitle = styled.span`
  padding: 5px;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const ItemList = styled.div<{ itemCount: number }>`
  visibility: hidden;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  position: absolute;
  top: -1px;
  right: -152px;
  width: 100%;
  min-width: max-content;
  height: ${props =>
    props.itemCount > 5 ? "148px" : `${props.itemCount * 25}px`};
  -webkit-box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.75);
`;

export const TriangleI = styled(FontAwesomeIcon)`
  color: #4193cc;
  position: absolute;
  right: 10%;
`;
