import * as ReactTable from "react-table";
import { useMemo, useState } from "react";
import { IAsonicRenderRowProps } from "../asonic-table/asonic-render-row";
import { TMoveColumn } from "../asonic-table/asonic-table-column";
import AsonicTableHeaderRow from "../asonic-table/asonic-table-header-row";
import { IAsonicTableTitleBarProps } from "../asonic-table/asonic-table-title-bar";
import { FieldSort } from "../../generated/graphql";
import TMainContainer from "../table_v2/t_main_container";
import TLayout from "../table_v2/t_layout";
import GroupTableHead from "../group_table/group_table_head/group_table_head";
import GroupTableColumn from "../group_table/group_table_column/group_table_column";
import TBody from "../table_v2/t_body";
import ReactLoading from "react-loading";
import styled from "styled-components";
import GRenderRow from "./g_render_row";
import { colors } from "../GlobalStyle/GlobalStyle";

interface IProps<P extends object>
  extends Partial<IAsonicRenderRowProps<P>>,
    IAsonicTableTitleBarProps<P> {
  table: ReactTable.TableInstance<P>;
  fieldSort?: FieldSort;
  handleFieldSort?: (payload?: FieldSort) => void;
  listOfColumnDisabled?: string[];
  moveColumn?: TMoveColumn;
  isTitleBar?: boolean;
  isLoading?: boolean;
  itemSize?: number;
  listOfFlexForHeader?: string[];
  listOfFlexForData?: string[];
  isLastFlex?: boolean;
  isGroupColor?: boolean;
  isGroup?: boolean;
  height: number;
  width: number;
  loading?: boolean;
}

const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function GroupTableV2<P extends object>({
  table,
  title,
  fieldSort,
  handleFieldSort,
  listOfColumnDisabled,
  moveColumn,
  handleContextMenu,
  selectedRow,
  handleSelectRow,
  flexIndex,
  listOfFlexForHeader,
  height,
  width,
  isGroup,
  loading,
  isLastFlex = true,
  itemSize = 30
}: IProps<P>) {
  const isOverFlow = useMemo(() => {
    const totalHeight = itemSize * table.rows.length;
    return height <= totalHeight;
  }, [itemSize, table.rows, height]);
  return (
    <TLayout $height={height} $width={width}>
      <TMainContainer {...table.getTableProps()} className="table sticky">
        <GroupTableHead className="header">
          {table.headerGroups.map(headerGroup => (
            <AsonicTableHeaderRow
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
            >
              {headerGroup.headers.map((column, index) => {
                let isFlex = flexIndex === index;
                if (listOfFlexForHeader && column?.Header) {
                  isFlex = listOfFlexForHeader.includes(
                    column.Header as string
                  );
                }
                return (
                  <GroupTableColumn
                    key={column.id}
                    column={column}
                    isOverFlow={isOverFlow}
                    index={index}
                    fieldSort={fieldSort}
                    handleFieldSort={handleFieldSort}
                    listOfColumnDisabled={listOfColumnDisabled}
                    moveColumn={moveColumn}
                    title={title}
                    isFlex={isFlex}
                    isLastFlex={isLastFlex}
                    isGroup={isGroup}
                  />
                );
              })}
            </AsonicTableHeaderRow>
          ))}
        </GroupTableHead>
        <TBody {...table.getTableBodyProps()} className="body">
          {loading ? (
            <Content>
              <ReactLoading
                type="spokes"
                color={colors.green}
                height={200}
                width={170}
              />
            </Content>
          ) : (
            table.rows.map(item => {
              return (
                <GRenderRow
                  key={item.id}
                  prepareRow={table.prepareRow}
                  handleContextMenu={handleContextMenu}
                  selectedRow={selectedRow}
                  handleSelectRow={handleSelectRow}
                  flexIndex={flexIndex}
                  isOverFlow={isOverFlow}
                  listOfFlexForHeader={listOfFlexForHeader}
                  isGroupColor
                  isGroup
                  row={item}
                  data={table.rows}
                />
              );
            })
          )}
        </TBody>
      </TMainContainer>
    </TLayout>
  );
}

export default GroupTableV2;
