import { call, select, put } from "redux-saga/effects";
import {
  getApprovalSearchDocs,
  updateApprovalDoc
} from "../../../redux/sagas/restapi-calls/approvalCalls";

import { SET_APPROVAL_SEARCH_RESULT } from "../../../redux/modules/main-view/approval/approvalReducer";
import { GLOBAL_NOTIFICATION_ON } from "../../../redux/modules/main-view/mainViewReducer";
import { Reducers, ApprovalReducer } from "../../../../types/reducers";
const getApprovalReducer = (state: Reducers) => state.approvalReducer;

export function* getApprovalDocsAsync(action: any) {
  //   const approvalReducer = yield select(getApprovalReducer);
  const searchResults: any[] = yield call(getApprovalSearchDocs, action.args);
  yield put({
    type: SET_APPROVAL_SEARCH_RESULT,
    searchResults
  });

  if (searchResults.length < 1) {
    yield put({
      type: GLOBAL_NOTIFICATION_ON,
      notibarConf: {
        notiOpen: true,
        message: "검색된 데이터가 없습니다."
      }
    });
  }
}

export function* updateApprovalDocAsync(action: any) {
  const approvalReducer: ApprovalReducer = yield select(getApprovalReducer);

  yield call(
    updateApprovalDoc,
    action.approverId,
    approvalReducer.approvalSearchResults[approvalReducer.selectedIndex]
      .approval_request_idx,
    approvalReducer.approvedOrRejected,
    approvalReducer.comment
  );
}
