import styled from "styled-components";
import { Icon } from "@iconify/react";
import LayerIcon from "@iconify/icons-fa-solid/layer-group";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

const BoxContainer = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${props => props.theme.borderColor};
  :last-child {
    flex: 3;
  }
  flex-direction: column;
`;

const BoxTitleBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  gap: 10px;
`;

const BoxContents = styled.div`
  display: flex;
  flex: 9;
`;

function StatisticsBox({ title, children }: IProps) {
  return (
    <BoxContainer>
      <BoxTitleBar>
        <Icon icon={LayerIcon} />
        {title}
      </BoxTitleBar>
      <BoxContents>{children}</BoxContents>
    </BoxContainer>
  );
}

export default StatisticsBox;
