import SectionContainer from "../../shared/section_container/section_container";
import SubTitle from "../../shared/sub-title/sub-title";
import ContentContainer from "../../shared/grid_content_container/grid_content_container";
import FormRow from "../../shared/form-row/form-row";
import StyleInput from "../../inputs/style-input";
import SSelect from "../../shared/s_select/s_select";
import useTimeList, {
  OperatorType
} from "../../../hooks/use_time_list/use_time_list";
import TitleContainer from "../../shared/title_container/title_container";
import EtcSetting from "./etc_setting/etc_setting";
import ButtonContainer from "../../shared/button_container/button_container";
import Button from "../../globalComponents/Button";
import RecordingTimeHelp from "./recording_time_help";
import RadioContainer from "../../shared/radio_container/radio_container";
import {
  Day_Type,
  Is_Check_Auto_Time,
  Is_Use,
  Work_Type,
  useGetCommuteRecordInfoLazyQuery
} from "../../../generated/graphql";
import { useCallback, useContext, useEffect, useState } from "react";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import { WS_STATE } from "../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import { SInput } from "../work_time_tab/choice_w_s/choice_basic_w_s/choice_basic_w_s";
import styled from "styled-components";

interface IProps {
  workType: Work_Type;
}

const MultiRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RowContainer = styled.div<{ $indent?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  ${props => props.$indent && `padding-left: 20px;`}
`;

const workingFormRowHeight = "100px";

function RecordingTime({ workType }: IProps) {
  const wSState = useContext(WS_STATE);
  const { listOfOperator } = useTimeList();
  const [pcUse, setPcUse] = useState<Is_Use>(Is_Use.UnUse);
  const [appUse, setAppUse] = useState<Is_Use>(Is_Use.UnUse);

  const [startWorkingMinute, setStartWorkingMinute] = useState<number>(0);
  const [startOperator, setStartOperator] = useState<OperatorType>(
    OperatorType.MINUS
  );

  const [endWorkingMinute, setEndWorkingMinute] = useState<number>(0);
  const [endOperator, setEndOperator] = useState<OperatorType>(
    OperatorType.MINUS
  );

  const [dayOfWeek, setDayOfWeek] = useState<Day_Type[]>([]);
  const [approvalDataType, setApprovalDataType] = useState<Is_Use>(
    Is_Use.UnUse
  );

  const [unCheckoutAutoCheckoutFlag, setUnCheckoutAutoCheckoutFlag] =
    useState<Is_Check_Auto_Time>(Is_Check_Auto_Time.SetTime);

  const [unCheckoutAutoCheckoutTime, setUnCheckoutAutoCheckoutTime] =
    useState<string>("16:30");

  const [checkInStartWorkTimeSetFlag, setCheckInStartWorkTimeSetFlag] =
    useState<Is_Use>(Is_Use.UnUse);

  const [checkoutEndWorkTimeSetFlag, setCheckoutEndWorkTimeSetFlag] =
    useState<Is_Use>(Is_Use.UnUse);

  const [updateEndWorkTimeOnlyFlag, setUpdateEndWorkTimeOnlyFlag] =
    useState<Is_Use>(Is_Use.UnUse);

  const [
    checkinStartWorkTimeSetFlagAddMin,
    setCheckinStartWorkTimeSetFlagAddMin
  ] = useState<number>(540);
  const [
    checkinStartWorkTimeSetFlagAddMinOperator,
    setCheckinStartWorkTimeSetFlagAddMinOperator
  ] = useState<OperatorType>(OperatorType.PLUS);

  const handleDayOfWeek = useCallback((newDay: Day_Type) => {
    setDayOfWeek(list => {
      if (list.includes(newDay)) {
        return list.filter(day => day !== newDay);
      }
      return [...list, newDay];
    });
  }, []);

  // 시간을 뺀 분을 만들어주기
  const handleMinute = useCallback((minute: number) => {
    return String(Math.abs(minute)).padStart(2, "0");
  }, []);

  const [getCommuteRecordingInfo] = useGetCommuteRecordInfoLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getCommuteRecordInfo.info) {
        const {
          pcUse,
          appUse,
          startWorkingMin,
          endWorkingMin,
          dayOfWeek,
          approvalDataType,
          unCheckoutAutoCheckoutFlag,
          unCheckoutAutoCheckoutTime,
          checkInStartWorkTimeSetFlag,
          checkoutEndWorkTimeSetFlag,
          checkinStartWorkTimeSetFlagAddMin,
          checkinStartWorkTimeSetFlagBefore
        } = data.getCommuteRecordInfo.info;
        setPcUse(pcUse ?? Is_Use.UnUse);
        setAppUse(appUse ?? Is_Use.UnUse);
        setStartWorkingMinute(startWorkingMin ?? 0);
        setEndWorkingMinute(endWorkingMin ?? 0);
        setDayOfWeek(dayOfWeek ?? []);
        setApprovalDataType(approvalDataType ?? Is_Use.UnUse);
        setUnCheckoutAutoCheckoutFlag(
          unCheckoutAutoCheckoutFlag ?? Is_Check_Auto_Time.UnUse
        );
        setUnCheckoutAutoCheckoutTime(unCheckoutAutoCheckoutTime ?? "");
        setCheckInStartWorkTimeSetFlag(
          checkInStartWorkTimeSetFlag ?? Is_Use.UnUse
        );

        setCheckoutEndWorkTimeSetFlag(
          checkoutEndWorkTimeSetFlag ?? Is_Use.UnUse
        );

        setCheckinStartWorkTimeSetFlagAddMin(
          checkinStartWorkTimeSetFlagAddMin ?? 540
        );

        setCheckinStartWorkTimeSetFlagAddMinOperator(
          checkinStartWorkTimeSetFlagAddMin &&
            checkinStartWorkTimeSetFlagAddMin < 0
            ? OperatorType.MINUS
            : OperatorType.PLUS
        );

        setUpdateEndWorkTimeOnlyFlag(
          checkinStartWorkTimeSetFlagBefore ?? Is_Use.UnUse
        );

        // 출근 데이터 분을 시간으로 바꿔서 셋팅
        setStartOperator(
          startWorkingMin && startWorkingMin < 0
            ? OperatorType.MINUS
            : OperatorType.PLUS
        );

        // 퇴근 데이터 분을 시간으로 바꿔서 셋팅
        setEndOperator(
          endWorkingMin && endWorkingMin < 0
            ? OperatorType.MINUS
            : OperatorType.PLUS
        );
      } else if (data.getCommuteRecordInfo.error) {
        handleErrorLog(data.getCommuteRecordInfo.error);
      }
    }
  });

  useEffect(() => {
    if (
      wSState?.workingTemplateIdx &&
      wSState?.workPolicyBasicSetting?.name
      // &&
      // wSState?.setCommuteRecordInfo
    ) {
      let startWorkingMin = Math.abs(startWorkingMinute);
      let endWorkingMin = Math.abs(endWorkingMinute);
      let absCheckinStartWorkTimeSetFlagAddMin = Math.abs(
        checkinStartWorkTimeSetFlagAddMin
      );

      wSState?.setCommuteRecordInfo({
        workingTemplateIdx: wSState?.workingTemplateIdx,
        name: wSState?.workPolicyBasicSetting?.name,
        pcUse,
        appUse,
        startWorkingMin:
          startOperator === OperatorType.PLUS
            ? startWorkingMin
            : -startWorkingMin,
        endWorkingMin:
          endOperator === OperatorType.PLUS ? endWorkingMin : -endWorkingMin,
        approvalDataType,
        dayOfWeek,
        unCheckoutAutoCheckoutFlag,
        unCheckoutAutoCheckoutTime,
        checkInStartWorkTimeSetFlag,
        checkoutEndWorkTimeSetFlag,
        checkinStartWorkTimeSetFlagAddMin:
          checkinStartWorkTimeSetFlagAddMinOperator === OperatorType.PLUS
            ? absCheckinStartWorkTimeSetFlagAddMin
            : -absCheckinStartWorkTimeSetFlagAddMin,
        updateEndWorkTimeOnlyFlag
      });
    }
  }, [
    startOperator,
    endOperator,
    startWorkingMinute,
    endWorkingMinute,
    pcUse,
    appUse,
    approvalDataType,
    dayOfWeek,
    unCheckoutAutoCheckoutFlag,
    unCheckoutAutoCheckoutTime,
    checkInStartWorkTimeSetFlag,
    checkoutEndWorkTimeSetFlag,
    checkinStartWorkTimeSetFlagAddMinOperator,
    checkinStartWorkTimeSetFlagAddMin,
    updateEndWorkTimeOnlyFlag,
    wSState?.workingTemplateIdx,
    wSState?.setCommuteRecordInfo,
    wSState?.workPolicyBasicSetting
  ]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getCommuteRecordingInfo({
        variables: {
          workingTemplateIdx: wSState?.workingTemplateIdx
        }
      });
    }
  }, [getCommuteRecordingInfo, wSState?.workingTemplateIdx]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"출/퇴근 기록 설정"} />
        <RecordingTimeHelp />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title="출/퇴근 기록 방식">
          <span>{`출/퇴근 기록 설정 시간에 가능`}</span>
        </FormRow>
        <FormRow title={"출/퇴근 기록 장치"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="PC"
              minWidth="15px"
              maxWidth="15px"
              name="device"
              checked={pcUse === Is_Use.Use}
              onChange={() => {
                setPcUse(isUse => {
                  if (isUse === Is_Use.Use) {
                    return Is_Use.UnUse;
                  }
                  return Is_Use.Use;
                });
              }}
            />
            <label htmlFor="PC">{`PC`}</label>
            <StyleInput
              type="checkbox"
              id="mobileApp"
              minWidth="15px"
              maxWidth="15px"
              name="device"
              checked={appUse === Is_Use.Use}
              onChange={() => {
                setAppUse(isUse => {
                  if (isUse === Is_Use.Use) {
                    return Is_Use.UnUse;
                  }
                  return Is_Use.Use;
                });
              }}
            />
            <label htmlFor="mobileApp">{`모바일 앱`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="출근 체크 알림">
          <span>{`정규 출근시간`}</span>

          <SSelect
            name="startOperator"
            id="startOperator"
            minWidth="50px"
            maxWidth="50px"
            value={startOperator}
            onChange={event => {
              setStartOperator(event.currentTarget.value as OperatorType);
            }}
          >
            {listOfOperator.map(item => (
              <option value={item.value} key={`${item.value}_start`}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SInput
            type="number"
            name="startMin"
            id="startMin"
            value={handleMinute(startWorkingMinute)}
            onChange={event => {
              setStartWorkingMinute(Number(event.currentTarget.value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="startMin">{`분 부터 알림`}</label>
        </FormRow>
        <FormRow title="퇴근 체크 알림">
          <span>{`정규 퇴근시간`}</span>
          <SSelect
            name="endOperator"
            id="endOperator"
            minWidth="50px"
            maxWidth="50px"
            value={endOperator}
            onChange={event => {
              setEndOperator(event.currentTarget.value as OperatorType);
            }}
          >
            {listOfOperator.map(item => (
              <option value={item.value} key={`${item.value}_end`}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SInput
            type="number"
            name="endMin"
            id="endMin"
            value={handleMinute(endWorkingMinute)}
            onChange={event => {
              setEndWorkingMinute(Number(event.currentTarget.value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="endMin">{`분 부터 알림`}</label>
        </FormRow>
        <FormRow title={"연장근무시간 연결 처리"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="changeLeaveTimeType"
              minWidth="15px"
              maxWidth="15px"
              name="changeLeaveTimeType"
              checked={approvalDataType === Is_Use.Use}
              onChange={() => {
                setApprovalDataType(isUse => {
                  if (isUse === Is_Use.Use) {
                    return Is_Use.UnUse;
                  }
                  return Is_Use.Use;
                });
              }}
            />
            <label htmlFor="changeLeaveTimeType">{`퇴근 체크 시간을 연장근무 종료시간으로 변경`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="퇴근 미 체크 시 자동 체크" $isLongTitle>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="LeavingPlansTimeUnUse"
              minWidth="15px"
              maxWidth="15px"
              name="Is_Check_Auto_Time"
              checked={unCheckoutAutoCheckoutFlag === Is_Check_Auto_Time.UnUse}
              onChange={() => {
                setUnCheckoutAutoCheckoutFlag(Is_Check_Auto_Time.UnUse);
              }}
            />
            <label htmlFor="LeavingPlansTimeUnUse">{`미사용`}</label>
            <StyleInput
              type="checkbox"
              id="LeavingPlansTimeLeavingPlansTime"
              minWidth="15px"
              maxWidth="15px"
              name="Is_Check_Auto_Time"
              checked={
                unCheckoutAutoCheckoutFlag ===
                Is_Check_Auto_Time.LeavingPlansTime
              }
              onChange={() => {
                setUnCheckoutAutoCheckoutFlag(
                  Is_Check_Auto_Time.LeavingPlansTime
                );
              }}
            />
            <label htmlFor="LeavingPlansTimeLeavingPlansTime">{`퇴근 계획 시간`}</label>
            <StyleInput
              type="checkbox"
              id="LeavingPlansTimeSetTime"
              minWidth="15px"
              maxWidth="15px"
              name="Is_Check_Auto_Time"
              checked={
                unCheckoutAutoCheckoutFlag === Is_Check_Auto_Time.SetTime
              }
              onChange={() => {
                setUnCheckoutAutoCheckoutFlag(Is_Check_Auto_Time.SetTime);
              }}
            />
            {unCheckoutAutoCheckoutFlag === Is_Check_Auto_Time.SetTime ? (
              <StyleInput
                minWidth="50px"
                maxWidth="50px"
                name="Is_Check_Auto_Time"
                value={unCheckoutAutoCheckoutTime.slice(0, 5)}
                onChange={event => {
                  setUnCheckoutAutoCheckoutTime(
                    event.currentTarget.value ?? ""
                  );
                }}
                placeholder="ex) 16:30"
              />
            ) : (
              <label htmlFor="LeavingPlansTimeSetTime">
                {unCheckoutAutoCheckoutTime.slice(0, 5)}
              </label>
            )}
          </RadioContainer>
        </FormRow>
        <FormRow minHeight={workingFormRowHeight} title="출근 체크 시">
          <MultiRowContainer>
            <RowContainer>
              <RadioContainer>
                <StyleInput
                  type="checkbox"
                  id="checkInStartWorkTimeSetFlag"
                  minWidth="15px"
                  maxWidth="15px"
                  name="checkInStartWorkTimeSetFlag"
                  checked={checkInStartWorkTimeSetFlag === Is_Use.Use}
                  onChange={() => {
                    setCheckInStartWorkTimeSetFlag(isUse => {
                      if (isUse === Is_Use.Use) {
                        return Is_Use.UnUse;
                      }
                      return Is_Use.Use;
                    });
                  }}
                  disabled={workType === Work_Type.AutonomousCommutingPolicy}
                />
                <label htmlFor="checkInStartWorkTimeSetFlag">{`출근 계획 시간 변경 (Asonic 근무제 이용 시 적용)`}</label>
              </RadioContainer>
            </RowContainer>
            <RowContainer $indent>
              <span>{`출근 체크 시간`}</span>
              <SSelect
                name="checkinStartWorkTimeSetFlagAddMinOperator"
                id="checkinStartWorkTimeSetFlagAddMinOperator"
                minWidth="50px"
                maxWidth="50px"
                value={checkinStartWorkTimeSetFlagAddMinOperator}
                onChange={event => {
                  setCheckinStartWorkTimeSetFlagAddMinOperator(
                    event.currentTarget.value as OperatorType
                  );
                }}
              >
                {listOfOperator.map(item => (
                  <option value={item.value} key={`${item.value}_start`}>
                    {item.name}
                  </option>
                ))}
              </SSelect>
              <SInput
                type="number"
                name="addMinForWorkingTime"
                id="addMinForWorkingTime"
                value={handleMinute(checkinStartWorkTimeSetFlagAddMin)}
                onChange={event => {
                  setCheckinStartWorkTimeSetFlagAddMin(
                    Number(event.currentTarget.value)
                  );
                }}
                min={0}
              />
              <label htmlFor="addMinForWorkingTime">{`(분) 퇴근시간`}</label>
            </RowContainer>
            <RowContainer $indent>
              <RadioContainer>
                <StyleInput
                  type="checkbox"
                  id="checkinStartWorkTimeSetFlagBefore"
                  minWidth="15px"
                  maxWidth="15px"
                  name="checkinStartWorkTimeSetFlagBefore"
                  checked={updateEndWorkTimeOnlyFlag === Is_Use.Use}
                  onChange={() => {
                    setUpdateEndWorkTimeOnlyFlag(isUse =>
                      isUse === Is_Use.Use ? Is_Use.UnUse : Is_Use.Use
                    );
                  }}
                  disabled={workType === Work_Type.AutonomousCommutingPolicy}
                />
                <label htmlFor="checkinStartWorkTimeSetFlagBefore">{`출근 계획 시간보다, 일찍 체크 시, 퇴근 계획 시간만 변경`}</label>
              </RadioContainer>
            </RowContainer>
          </MultiRowContainer>
        </FormRow>
        <FormRow minHeight={workingFormRowHeight} title="퇴근 체크 시">
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="checkoutEndWorkTimeSetFlag"
              minWidth="15px"
              maxWidth="15px"
              name="checkoutEndWorkTimeSetFlag"
              checked={checkoutEndWorkTimeSetFlag === Is_Use.Use}
              onChange={() => {
                setCheckoutEndWorkTimeSetFlag(isUse => {
                  if (isUse === Is_Use.Use) {
                    return Is_Use.UnUse;
                  }
                  return Is_Use.Use;
                });
              }}
              disabled={workType === Work_Type.AutonomousCommutingPolicy}
            />
            <label htmlFor="checkoutEndWorkTimeSetFlag">{`퇴근 계획 시간 변경 (Asonic 근무제 이용 시 적용)`}</label>
          </RadioContainer>
        </FormRow>
      </ContentContainer>
      <EtcSetting dayOfWeek={dayOfWeek} handleDayOfWeek={handleDayOfWeek} />
      <ButtonContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          disabled={!wSState?.workingTemplateIdx}
          onClick={() => {
            wSState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </ButtonContainer>
    </SectionContainer>
  );
}

export default RecordingTime;
