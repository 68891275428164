import { useContext } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  ApprovalLineEntity,
  Is_Default_Approval_Line
} from "../../../generated/graphql";
import Button from "../../globalComponents/Button";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import StyleInput from "../../inputs/style-input";
import FormRow from "../../shared/form-row/form-row";
import ApprovalLineContext from "../approval-setting/context/approval-line-context";

interface IFormValue {
  title: string;
  isDefaultApprovalLine: Is_Default_Approval_Line;
}

interface IProps {
  handleOpenDialog: (value: boolean) => void;
  handleEditApprovalLine: (data: IFormValue) => void;
  selectedItem?: ApprovalLineEntity;
}

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const DialogContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

interface IBProps {
  backgroundColor?: string;
  customMinWidth?: string;
}

const CustomButton = styled.button<IBProps>`
  border-radius: 4px;
  height: 30px;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  background-color: ${props =>
    props.backgroundColor ?? props.theme.colors.green};
  color: white;
  border: none;
  outline: none;
  padding: 6px;
  min-width: ${props => {
    return props.customMinWidth ?? "100px";
  }};
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.98);
  }
  font-family: "Gothic", sans-serif;
  opacity: ${props => (props.disabled ? "0.2" : "1")};
`;

function EditApprovalLineNameDialog({
  handleOpenDialog,
  handleEditApprovalLine,
  selectedItem
}: IProps) {
  const store = useContext(ApprovalLineContext);
  const { register, handleSubmit, watch, setValue } = useForm<IFormValue>({
    defaultValues: {
      title: selectedItem?.newTitle ?? "",
      isDefaultApprovalLine: store?.selectedApprovalLine?.basicLine
    }
  });
  const isDefaultApprovalLine = watch("isDefaultApprovalLine");
  return (
    <DialogContentContainer onSubmit={handleSubmit(handleEditApprovalLine)}>
      <DialogContent>
        <FormRow title="결재선 이름">
          <StyleInput {...register("title")} />
        </FormRow>
        <FormRow title="기본 결재선">
          <CheckBoxUi
            checked={isDefaultApprovalLine === Is_Default_Approval_Line.Used}
            onChange={event => {
              if (event.target.checked) {
                setValue(
                  "isDefaultApprovalLine",
                  Is_Default_Approval_Line.Used
                );
              } else {
                setValue(
                  "isDefaultApprovalLine",
                  Is_Default_Approval_Line.UnUsed
                );
              }
            }}
            htmlFor={"기본 결재선"}
            name={"기본 결재선"}
          />
        </FormRow>
      </DialogContent>
      <ButtonContainer>
        <Button customMinWidth="50px">저장</Button>
        <Button
          onClick={() => {
            handleOpenDialog(false);
          }}
          customMinWidth="50px"
        >
          닫기
        </Button>
      </ButtonContainer>
    </DialogContentContainer>
  );
}

export default EditApprovalLineNameDialog;
