import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  Approval_Sort,
  Type_Control_Approval_Form,
  useGetListOfApprovalFormLazyQuery,
  useGetListOfOtTimeTypeCategoryLazyQuery
} from "../../../generated/graphql";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import StyleInput from "../../inputs/style-input";
import StyleTextarea from "../../inputs/style-textarea";
import FormRow from "../../shared/form-row/form-row";
import FormTextRow from "../../shared/form-text-row/form-text-row";
import ApprovalSettingButtons from "./approval-setting-buttons";
import { IApprovalTemplateEntity, Row } from "./list-of-approval-template";
import RadioContainer from "../../shared/radio_container/radio_container";
import Button from "../../globalComponents/Button";
import routes from "../../../routes";
import {
  APPROVAL_TYPE,
  DISABLE,
  ENABLE,
  LABELS,
  WINDOW_FEATURES
} from "./constants";
import {
  EApprovalUserSelectorPacketSource,
  ESocketChannel,
  IApprovalUserSelectorPacket
} from "./types";
import { Reducers } from "../../../../types/reducers";
import { useSelector } from "react-redux";

interface IProps {
  selectedRow?: ICompactRow<IApprovalTemplateEntity>;
  controlType: Type_Control_Approval_Form;
  checkedRows: Row<IApprovalTemplateEntity>[];
  handleControlType: (type: Type_Control_Approval_Form) => void;
  handleInitCheckedRows: () => void;
}

export interface IApprovalTemplate {
  templateTitle: string;
  otTimeTypeCategoryId: number;
  formIdx: number;
  approvalType: string;
  description: string;
}

const Container = styled.form`
  display: flex;
  flex: 5;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const Section = styled.div`
  display: flex;
`;

const Description = styled(Section)`
  flex: 2;
  textarea {
    min-height: 100px;
  }
`;

const Subscription = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding: 10px;
  white-space: pre-line;
  white-space: pre-wrap;
`;

const renderRadioInput = (
  name: string,
  value: number,
  checked: boolean,
  label: string,
  onChange: (value: number) => void,
  disabled: boolean
) => (
  <>
    <StyleInput
      type="radio"
      minWidth="15px"
      maxWidth="15px"
      name={name}
      value={value}
      checked={checked}
      onChange={() => onChange(value)}
      disabled={disabled}
    />
    <label>{label}</label>
  </>
);

const generateApprovalProcessUrl = (
  formIdx: number | undefined,
  employeeId: string | undefined
) => {
  return `${routes.pageRoutes.approvalSelectUsers}:${
    formIdx || ""
  }?employee_id=${employeeId || ""}`;
};

function ApprovalTemplateDetail({
  selectedRow,
  controlType,
  checkedRows,
  handleControlType,
  handleInitCheckedRows
}: IProps) {
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const [getListOfOtTimeTypeCategory, { data: otTimeTypeCategoryData }] =
    useGetListOfOtTimeTypeCategoryLazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (data.getListOfOtTimeTypeCategory.error) {
          console.log(data.getListOfOtTimeTypeCategory.error);
        }
      }
    });
  const [getListOfApprovalForm, { data: listOfApprovalFormData }] =
    useGetListOfApprovalFormLazyQuery();

  const listOfCategory = useMemo(() => {
    return otTimeTypeCategoryData?.getListOfOtTimeTypeCategory.list ?? [];
  }, [otTimeTypeCategoryData]);

  const listOfApprovalForm = useMemo(() => {
    return listOfApprovalFormData?.getListOfApprovalForm.list ?? [];
  }, [listOfApprovalFormData]);

  // 서버에서 받아온 최신 정보 (라디오버튼 상태)
  const approvalPermissions =
    selectedRow?.original.approvalPermissions ?? DISABLE;
  const approvalSharing = selectedRow?.original.approvalSharing ?? DISABLE;

  // ui에서 사용하는 상태
  const [approvalPermissionsState, setApprovalPermissionsState] =
    useState(approvalPermissions);
  const [approvalSharingState, setApprovalSharingState] =
    useState(approvalSharing);
  /////////////////////////

  const [approvedEmployeeIds, setApprovedEmployeeIds] = useState<string[]>([]);
  // WebSocket
  // 사용자설정에서 특정결재 폼에 대한 엑세스 권한이 있는 사용자를 설정한다
  const bc = useMemo(() => {
    if (selectedRow) {
      return new BroadcastChannel(
        ESocketChannel.APPROVAL_USER_SELECTOR_CHANNEL
      );
    }
    return;
  }, [selectedRow]);

  const handleApprovalPermissions = useCallback(
    (value: number) => {
      setApprovalPermissionsState(value);
    },
    [setApprovalPermissionsState]
  );

  const handleApprovalSharing = useCallback(
    (value: number) => {
      setApprovalSharingState(value);
    },
    [setApprovalSharingState]
  );

  const { register, setValue, handleSubmit } = useForm<IApprovalTemplate>({
    mode: "onSubmit",
    defaultValues: {
      approvalType: Approval_Sort.ApprovalLine
    }
  });

  useEffect(() => {
    getListOfOtTimeTypeCategory();
    getListOfApprovalForm();
  }, [getListOfOtTimeTypeCategory, getListOfApprovalForm]);

  useEffect(() => {
    if (controlType === Type_Control_Approval_Form.Edit && selectedRow) {
      setValue("templateTitle", selectedRow.original.templateTitle);
      setValue("description", selectedRow.original?.description ?? "");
      setValue("approvalType", selectedRow.original.approvalType);
    }
  }, [setValue, selectedRow, controlType]);

  useEffect(() => {
    if (listOfCategory && listOfCategory.length > 0) {
      if (controlType === Type_Control_Approval_Form.Add) {
        setValue("otTimeTypeCategoryId", listOfCategory[0].categoryId);
      } else if (selectedRow) {
        const category = listOfCategory.find(
          category => category.name === selectedRow.original.name
        );
        if (category) {
          setValue("otTimeTypeCategoryId", category.categoryId);
        }
      }
    }
  }, [setValue, listOfCategory, controlType, selectedRow]);

  useEffect(() => {
    if (listOfApprovalForm && listOfApprovalForm.length > 0) {
      if (controlType === Type_Control_Approval_Form.Add) {
        setValue("formIdx", listOfApprovalForm[0].formIdx);
      } else if (selectedRow) {
        const approvalForm = listOfApprovalForm.find(
          approvalForm =>
            approvalForm.formName === selectedRow.original.formName
        );
        if (approvalForm) {
          setValue("formIdx", approvalForm.formIdx);
        }
      }
    }
  }, [controlType, setValue, listOfApprovalForm, selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      setApprovalPermissionsState(approvalPermissions);
      setApprovalSharingState(approvalSharing);
    }
  }, [approvalPermissions, approvalSharing, selectedRow]);

  useEffect(() => {
    if (bc && selectedRow) {
      bc.onmessage = event => {
        const message: IApprovalUserSelectorPacket = event.data;
        if (
          message.source ===
          EApprovalUserSelectorPacketSource.APPROVAL_USER_SELECTOR_VIEW
        ) {
          setApprovedEmployeeIds(message.list);
        }
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, selectedRow]);

  const deleteApprovedEmployeeId = useCallback(() => {
    setApprovedEmployeeIds([]);
  }, [approvedEmployeeIds]);

  return (
    <Container>
      <Section>
        <FormRow title={LABELS.templateTitle} isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <span>{selectedRow?.original.templateTitle}</span>
          ) : (
            <StyleInput
              {...register("templateTitle", { required: true })}
              required={true}
              placeholder={LABELS.templateTitle}
            />
          )}
        </FormRow>
        <FormRow title={LABELS.otTimeTypeCategory} isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <span>{selectedRow?.original.name}</span>
          ) : (
            <select {...register("otTimeTypeCategoryId", { required: true })}>
              {listOfCategory.map(category => (
                <option value={category.categoryId} key={category.categoryId}>
                  {category.name}
                </option>
              ))}
            </select>
          )}
        </FormRow>
      </Section>
      <Section>
        <FormRow title={LABELS.approvalType} isCenter>
          {selectedRow?.original.approvalType &&
            controlType === Type_Control_Approval_Form.Delete && (
              <span>{APPROVAL_TYPE[selectedRow?.original.approvalType]}</span>
            )}
          {controlType !== Type_Control_Approval_Form.Delete && (
            <select {...register("approvalType", { required: true })}>
              <option value={Approval_Sort.ApprovalLine}>결재선</option>
              <option value={Approval_Sort.AutoApproval}>자동결재</option>
            </select>
          )}
        </FormRow>
        <FormRow title={LABELS.approvalForm} isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <span>{selectedRow?.original.formName}</span>
          ) : (
            <select {...register("formIdx", { required: true })}>
              {listOfApprovalForm.map(approvalForm => (
                <option value={approvalForm.formIdx} key={approvalForm.formIdx}>
                  {approvalForm.formName}
                </option>
              ))}
            </select>
          )}
        </FormRow>
      </Section>
      <Section>
        <FormRow title={LABELS.approvalPermissions} isCenter>
          <RadioContainer>
            {renderRadioInput(
              "ApprovalFormUsePermission",
              DISABLE,
              approvalPermissionsState === DISABLE,
              LABELS.allUsers,
              handleApprovalPermissions,
              controlType === Type_Control_Approval_Form.Delete
            )}
            {renderRadioInput(
              "ApprovalFormUsePermission",
              ENABLE,
              approvalPermissionsState === ENABLE,
              LABELS.specificUsers,
              handleApprovalPermissions,
              controlType === Type_Control_Approval_Form.Delete
            )}
            <div style={{ marginLeft: "auto", marginRight: "4px" }}>
              {selectedRow?.original.formIdx && (
                <Button
                  type="button"
                  onClick={() => {
                    const employeeId = employee_id; // 여기에 실제 employee_id 값을 넣어야 합니다.
                    window.open(
                      generateApprovalProcessUrl(
                        selectedRow.original.formTemplateIdx,
                        employeeId
                      ),
                      "",
                      WINDOW_FEATURES
                    );
                  }}
                  disabled={controlType === Type_Control_Approval_Form.Delete}
                >
                  사용자 설정
                </Button>
              )}
            </div>
          </RadioContainer>
        </FormRow>
        <FormRow title={LABELS.approvalSharing} isCenter>
          <RadioContainer>
            <label>{LABELS.applyToAll}</label>
            {renderRadioInput(
              "ApprovalShare",
              DISABLE,
              approvalSharingState === DISABLE,
              LABELS.notUsed,
              handleApprovalSharing,
              controlType === Type_Control_Approval_Form.Delete
            )}
            {renderRadioInput(
              "ApprovalShare",
              ENABLE,
              approvalSharingState === ENABLE,
              LABELS.used,
              handleApprovalSharing,
              controlType === Type_Control_Approval_Form.Delete
            )}
          </RadioContainer>
        </FormRow>
      </Section>
      <Description>
        <FormTextRow title={LABELS.description} isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <Subscription>{selectedRow?.original.description}</Subscription>
          ) : (
            <StyleTextarea
              {...register("description", { required: true })}
              required={true}
              placeholder={LABELS.description}
            />
          )}
        </FormTextRow>
      </Description>
      <ApprovalSettingButtons
        checkedRows={checkedRows}
        controlType={controlType}
        handleControlType={handleControlType}
        approvalPermissions={approvalPermissionsState}
        approvalSharing={approvalSharingState}
        handleSubmit={handleSubmit}
        selectedRow={selectedRow}
        handleInitCheckedRows={handleInitCheckedRows}
        approvedEmployeeIds={approvedEmployeeIds}
        deleteApprovedEmployeeId={deleteApprovedEmployeeId}
      />
    </Container>
  );
}

export default ApprovalTemplateDetail;
