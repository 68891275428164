import { GLOBAL_REDUCER_INIT_STATE } from "../../../../constValues";

// TYPE
export const GET_APPROVAL_DOCS = "APPROVAL_REDUCER/GET_APPROVAL_DOCS";
export const SELECT_APPROVAL_DOC = "APPROVAL_REDUCER/SELECT_APPROVAL_DOC";
export const SET_COMMENT = "APPROVAL_REDUCER/SET_COMMENT";
export const UPDATE_APPROVAL_DOC = "APPROVAL_REDUCER/UPDATE_APPROVAL_DOC";
export const MAKE_APPROVE_DECISION = "APPROVAL_REDUCER/MAKE_APPROVE_DECISION";
export const SET_APPROVAL_SEARCH_RESULT =
  "APPROVAL_REDUCER/SET_APPROVAL_SEARCH_RESULT";
export const SET_APPROVAL_SEARCH_ARGS =
  "APPROVAL_REDUCER/SET_APPROVAL_SEARCH_ARGS";

const initialState = {
  startDate: "",
  endDate: "",
  selectedIndex: -1,
  approvedOrRejected: true,
  comment: "",
  approvalSearchResults: undefined
};

export const getApprovalDocs = args => ({
  type: GET_APPROVAL_DOCS,
  args
});

export const updateApprovalDoc = approverId => ({
  type: UPDATE_APPROVAL_DOC,
  approverId
});

export const selectApprovalDoc = selectedIndex => ({
  type: SELECT_APPROVAL_DOC,
  selectedIndex
});

export const setComment = comment => ({
  type: SET_COMMENT,
  comment
});

export const makeApproveDecision = approvedOrRejected => ({
  type: MAKE_APPROVE_DECISION,
  approvedOrRejected
});

export const setApprovalSearchResult = searchResults => ({
  type: SET_APPROVAL_SEARCH_RESULT,
  searchResults
});

export const approvalActionCreator = {
  getApprovalDocs,
  updateApprovalDoc,
  selectApprovalDoc,
  setComment,
  makeApproveDecision,
  setApprovalSearchResult
};
// export const setApprovalSearchArgs = args => ({
//   type: SET_APPROVAL_SEARCH_ARGS,
//   args
// });

const approvalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPROVAL_SEARCH_RESULT:
      return {
        ...state,
        approvalSearchResults: action.searchResults
      };
    case SELECT_APPROVAL_DOC:
      return {
        ...state,
        selectedIndex: action.selectedIndex
      };
    case GET_APPROVAL_DOCS:
      return {
        ...state,
        startDate: action.args.startDate,
        endDate: action.args.endDate
      };
    case MAKE_APPROVE_DECISION:
      return {
        ...state,
        approvedOrRejected: action.approvedOrRejected
      };
    case SET_COMMENT:
      return {
        ...state,
        comment: action.comment
      };
    case GLOBAL_REDUCER_INIT_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default approvalReducer;
