import React, { ChangeEvent } from "react";
import { Container, CalendarContainer, MonthTitleBarContainer } from "./styled";
import { SerializeItem } from "../../../hooks/link-google-hooks/use-google-calendar";
import AsonicListOfWeeks from "../asonic-list-of-weeks";
import AsonicExtraCalendarEventEditor from "../asonic-extra-calendar-event-editor";
import CalendarContext from "../asonic-calendar-context";
import AsonicCalendarTitleBar from "../asonic-calendar-title-bar/asonic-calendar-title-bar";

export enum CalendarDirection {
  PRE = "PRE",
  NEXT = "NEXT"
}

type Props = {
  weeks: DayInfo[][];
  currentMoment: string;
  handleCurrentMoment: (date: Date) => void;
  handleMoveMonth: (direction: CalendarDirection) => () => void;
  authorize: () => void;
  handleSignoutClick: () => void;
  isLogin: boolean;
  googleCalendarlist: SerializeItem[];
  handleCalendarList: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const AsonicCalendarMonthPresenter = ({
  weeks,
  currentMoment,
  handleCurrentMoment,
  handleMoveMonth,
  authorize,
  handleSignoutClick,
  isLogin,
  googleCalendarlist,
  handleCalendarList
}: Props) => {
  const { dialogTitle, isOpen, handleOpenDialog } =
    React.useContext(CalendarContext);

  return (
    <Container>
      <CalendarContainer>
        <MonthTitleBarContainer>
          <AsonicCalendarTitleBar
            currentMoment={currentMoment}
            handleCurrentMoment={handleCurrentMoment}
            handleMoveMonth={handleMoveMonth}
            authorize={authorize}
            handleSignoutClick={handleSignoutClick}
            isLogin={isLogin}
            handleCalendarList={handleCalendarList}
            googleCalendarlist={googleCalendarlist}
          />
        </MonthTitleBarContainer>
        {weeks.length > 0 && <AsonicListOfWeeks weeks={weeks} />}
      </CalendarContainer>
      {isOpen && (
        <AsonicExtraCalendarEventEditor
          items={googleCalendarlist}
          dialogTitle={dialogTitle}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
        />
      )}
    </Container>
  );
};

export default AsonicCalendarMonthPresenter;
