import { Fragment } from "react";
import RightMenuButton from "../RightMenuButton";

interface IProps {
  handleChartDialog: (isTrue: boolean) => void;
}

const ShowGraphMenu = <P extends IProps>({ handleChartDialog }: P) => {
  return (
    <Fragment>
      <RightMenuButton
        title="그래프"
        handleClick={() => handleChartDialog(true)}
      />
    </Fragment>
  );
};

export default ShowGraphMenu;
