import CollapsiblePanel from "../main-view/smallcomponents/CollapsiblePanel";
import {
  TitleBar,
  TableSection,
  Form,
  FormSection,
  ButtonsContainer
} from "./styled";
import NativeSelect from "../native-select";
import Button from "../globalComponents/Button";
import useNativeSelect from "../../hooks/use-native-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  GetListOfAlarm,
  GetListOfAlarm_getListOfAlarm_list
} from "../../__generated__/GetListOfAlarm";
import { GetAlarmEventStatus } from "../../__generated__/GetAlarmEventStatus";
import styled from "styled-components";
import CheckBoxUi from "../globalComponents/CheckBoxUi";
import { AlarmStatus } from "../../__generated__/globalTypes";
import {
  UpdateAlarmEventStatus,
  UpdateAlarmEventStatusVariables
} from "../../__generated__/UpdateAlarmEventStatus";
import useConfirmDialog from "../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import AsonicTable from "../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import {
  AddAlarmEvent,
  AddAlarmEventVariables
} from "../../__generated__/AddAlarmEvent";
import {
  UpdateAlarmEvent,
  UpdateAlarmEventVariables
} from "../../__generated__/UpdateAlarmEvent";
import { useForm } from "react-hook-form";
import TextInputWithLabel from "../inputs/text-input-with-label";
import {
  DeleteAlarmEvent,
  DeleteAlarmEventVariables
} from "../../__generated__/DeleteAlarmEvent";

type Props = {};

interface FieldValues {
  pushMin: string;
  title: string;
  description: string;
}

enum AlarmType {
  ATTENDANCE = 1,
  LEAVE_WORK = 2
}

enum ConfirmType {
  ALARM_POPUP = "ALARM_POPUP",
  ADD = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

type OnSubmit = React.FormEvent<HTMLFormElement>;

const initNativeSelect = 1;

const QUERY_GET_LIST_OF_ALARM = gql`
  query GetListOfAlarm {
    getListOfAlarm {
      ok
      error
      list {
        settingIndex
        targetDevice
        eventType
        pushMin
        title
        description
        linkUrl
      }
    }
  }
`;

const QUERY_GET_ALARM_EVENT_STATUS = gql`
  query GetAlarmEventStatus {
    getAlarmEventStatus {
      ok
      error
      status
    }
  }
`;

const MUTATION_UPDATE_ALARM_EVENT_STATUS = gql`
  mutation UpdateAlarmEventStatus($alarmUse: AlarmStatus!) {
    updateAlarmEventStatus(alarmUse: $alarmUse) {
      ok
      error
    }
  }
`;

const MUTATION_ADD_ALARM_EVENT = gql`
  mutation AddAlarmEvent(
    $targetDevice: Int
    $eventType: Int
    $pushMin: Int
    $title: String
    $description: String
    $linkUrl: String
  ) {
    addAlarmEvent(
      targetDevice: $targetDevice
      eventType: $eventType
      pushMin: $pushMin
      title: $title
      description: $description
      linkUrl: $linkUrl
    ) {
      ok
      error
      id
    }
  }
`;

const MUTATION_UPDATE_ALARM_EVENT = gql`
  mutation UpdateAlarmEvent(
    $settingIndex: Int!
    $targetDevice: Int
    $eventType: Int
    $pushMin: Int
    $title: String
    $description: String
  ) {
    updateAlarmEvent(
      settingIndex: $settingIndex
      targetDevice: $targetDevice
      eventType: $eventType
      pushMin: $pushMin
      title: $title
      description: $description
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_ALARM_EVENT = gql`
  mutation DeleteAlarmEvent($settingIndex: Int!) {
    deleteAlarmEvent(settingIndex: $settingIndex) {
      error
      ok
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
`;

const CheckBoxTitle = styled.span`
  display: flex;
  align-items: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  margin-right: auto;
`;

const AlarmPushPresenter = <P extends Props>(props: P) => {
  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [confirmEventType, setConfirmEventType] = useState<ConfirmType>(
    ConfirmType.ALARM_POPUP
  );

  const { selectedItem, handleSelectedItem } =
    useNativeSelect(initNativeSelect);
  const { register, getValues, setValue } = useForm<FieldValues>({
    mode: "onSubmit",
    defaultValues: {
      pushMin: "0"
    }
  });

  const [deleteAlarmEvent] = useMutation<
    DeleteAlarmEvent,
    DeleteAlarmEventVariables
  >(MUTATION_DELETE_ALARM_EVENT, {
    update(cache, { data }, { variables }) {
      if (data?.deleteAlarmEvent.ok && variables) {
        cache.evict({
          id: `AlarmSettingEntity:${variables.settingIndex}`
        });
        handleMessage(`알람을 삭제 하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (data?.deleteAlarmEvent.error) {
        handleMessage(`알람을 삭제하지 못했습니다.`);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [updateAlarmEvent] = useMutation<
    UpdateAlarmEvent,
    UpdateAlarmEventVariables
  >(MUTATION_UPDATE_ALARM_EVENT, {
    onError(errorData) {
      console.log(errorData.message);
      handleMessage(`알람을 수정하지 못했습니다`);
      handleToastMessageType(MessageTypes.ERROR);
      handleIsToastMessageOpen(true);
    },
    update(cache, { data }, { variables }) {
      if (data?.updateAlarmEvent.ok && variables) {
        cache.modify({
          id: `AlarmSettingEntity:${variables.settingIndex}`,
          fields: {
            eventType() {
              return variables.eventType;
            },
            pushMin() {
              return variables.pushMin;
            },
            title() {
              return variables.title;
            },
            description() {
              return variables.description;
            }
          }
        });
        handleMessage(`알람을 수정하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (!data?.updateAlarmEvent.ok && data?.updateAlarmEvent.error) {
        handleMessage(`알람을 수정하지 못했습니다`);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [addAlarmEvent] = useMutation<AddAlarmEvent, AddAlarmEventVariables>(
    MUTATION_ADD_ALARM_EVENT,
    {
      onError(errorData) {
        console.log(errorData.message);
        console.log(errorData.graphQLErrors);
        handleMessage(`알람을 추가하지 못했습니다`);
        handleToastMessageType(MessageTypes.ERROR);
        handleIsToastMessageOpen(true);
      },
      update(cache, { data }, { variables }) {
        if (data?.addAlarmEvent.ok && data.addAlarmEvent.id && variables) {
          const newAlarmEvent: GetListOfAlarm_getListOfAlarm_list = {
            settingIndex: data.addAlarmEvent.id,
            eventType: variables?.eventType as number | null,
            pushMin: variables?.pushMin as number | null,
            title: variables?.title as string | null,
            description: variables?.description as string | null,
            linkUrl: (variables.linkUrl as string | null) ?? "",
            targetDevice: (variables.targetDevice as number | null) || 2,
            __typename: "AlarmSettingEntity"
          };
          const newCacheAlarmEvent = cache.writeFragment({
            id: `AlarmSettingEntity:${data?.addAlarmEvent.id}`,
            fragment: gql`
              fragment add on AlarmSettingEntity {
                __typename
                settingIndex
                targetDevice
                eventType
                pushMin
                title
                description
                linkUrl
              }
            `,
            data: {
              ...newAlarmEvent
            }
          });
          cache.modify({
            id: `GetListOfAlarmOutput`,
            fields: {
              list(prev) {
                if (prev) {
                  return [...prev, newCacheAlarmEvent];
                }
                return [newCacheAlarmEvent];
              }
            }
          });
          handleMessage(
            `성공적으로 ${variables?.title} 알람을 추가하셨습니다.`
          );
          handleToastMessageType(MessageTypes.SUCCESS);
        } else if (!data?.addAlarmEvent.ok && data?.addAlarmEvent.error) {
          handleMessage(data?.addAlarmEvent.error);
          handleToastMessageType(MessageTypes.ERROR);
        }
        handleIsToastMessageOpen(true);
      }
    }
  );

  const handleAddAlarmEvent = useCallback(() => {
    const { title, description, pushMin } = getValues();
    if (title && pushMin) {
      addAlarmEvent({
        variables: {
          eventType: parseInt(selectedItem),
          pushMin: parseInt(pushMin),
          title: title,
          description: description
        }
      });
    }
  }, [addAlarmEvent, getValues, selectedItem]);

  const handleConfirmEventType = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setConfirmEventType(event.currentTarget.name as ConfirmType);
      switch (event.currentTarget.name) {
        case ConfirmType.ADD:
          handleConfirmMessage({
            title: "알람 이벤트 추가",
            p: "알람 이벤트를 추가 하시겠습니까?",
            messageTypes: MessageTypes.INFO
          });
          break;
        case ConfirmType.UPDATE:
          handleConfirmMessage({
            title: "알람 이벤트 수정",
            p: "알람 이벤트를 수정 하시겠습니까?",
            messageTypes: MessageTypes.WARNING
          });
          break;
        case ConfirmType.DELETE:
          handleConfirmMessage({
            title: "알람 이벤트 삭제",
            p: "알람 이벤트를 삭제 하시겠습니까?",
            messageTypes: MessageTypes.WARNING
          });
          break;
      }
      handleIsOpen(true);
    },
    [handleIsOpen, handleConfirmMessage]
  );

  const [getListOfAlarm, { data, loading }] = useLazyQuery<GetListOfAlarm>(
    QUERY_GET_LIST_OF_ALARM
  );

  const [updateAlarmEventStatus] = useMutation<
    UpdateAlarmEventStatus,
    UpdateAlarmEventStatusVariables
  >(MUTATION_UPDATE_ALARM_EVENT_STATUS, {
    update(_, { data }, { variables }) {
      if (data?.updateAlarmEventStatus.ok) {
        handleToastMessageType(MessageTypes.SUCCESS);
        if (variables?.alarmUse === AlarmStatus.ON) {
          handleMessage("알람팝업을 ON 하셨습니다.");
          setIsChecked(true);
        } else if (variables?.alarmUse === AlarmStatus.OFF) {
          handleMessage("알람팝업을 OFF 하셨습니다.");
          setIsChecked(false);
        }
      } else if (
        !data?.updateAlarmEventStatus.ok &&
        data?.updateAlarmEventStatus.error
      ) {
        handleToastMessageType(MessageTypes.ERROR);
        handleMessage(data.updateAlarmEventStatus.error);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [getAlarmEventStatus] = useLazyQuery<GetAlarmEventStatus>(
    QUERY_GET_ALARM_EVENT_STATUS,
    {
      onCompleted(data) {
        if (data.getAlarmEventStatus.ok) {
          setIsChecked(
            data.getAlarmEventStatus.status === AlarmStatus.ON ? true : false
          );
        }
      }
    }
  );

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleConfirmMessage({
          title: "알림팝업(PUSH) 설정",
          p: `출퇴근 반경 설정을 ON 하시겠습니까?`,
          messageTypes: MessageTypes.WARNING
        });
      } else {
        handleConfirmMessage({
          title: "알림팝업(PUSH) 설정",
          p: `출퇴근 반경 설정을 OFF 하시겠습니까?`,
          messageTypes: MessageTypes.WARNING
        });
      }
      setConfirmEventType(ConfirmType.ALARM_POPUP);
      handleIsOpen(true);
    },
    [handleConfirmMessage, handleIsOpen]
  );

  const handleSubmit = useCallback((event: OnSubmit) => {
    event.preventDefault();
  }, []);

  const list: GetListOfAlarm_getListOfAlarm_list[] = useMemo(() => {
    return data?.getListOfAlarm.list || [];
  }, [data]);

  const columns: ReactTable.Column<GetListOfAlarm_getListOfAlarm_list>[] =
    useMemo(() => {
      const width = 180;
      return [
        {
          Header: "알람종류",
          accessor(data) {
            if (data.eventType === AlarmType.ATTENDANCE) {
              return "출근알람";
            } else if (data.eventType === AlarmType.LEAVE_WORK) {
              return "퇴근알람";
            }
            return data.eventType;
          },
          width
        },
        { Header: "알람 이름", accessor: "title", width },
        { Header: "알람 시간", accessor: "pushMin", width },
        { Header: "상세 설명", accessor: "description", width }
      ];
    }, []);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<GetListOfAlarm_getListOfAlarm_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow:
    | ReactTable.Row<GetListOfAlarm_getListOfAlarm_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<GetListOfAlarm_getListOfAlarm_list>) => {},
    []
  );

  const handleUpdateAlarmEvent = useCallback(() => {
    const { title, pushMin, description } = getValues();
    if (title && pushMin && selectedRow) {
      updateAlarmEvent({
        variables: {
          settingIndex: selectedRow.original.settingIndex,
          targetDevice: selectedRow.original.targetDevice,
          eventType: selectedItem,
          pushMin: parseInt(pushMin),
          title: title,
          description: description
        }
      });
    }
  }, [updateAlarmEvent, selectedRow, getValues, selectedItem]);

  const handleDeleteAlarmEvent = useCallback(() => {
    if (selectedRow) {
      deleteAlarmEvent({
        variables: {
          settingIndex: selectedRow.original.settingIndex
        }
      });
    }
  }, [deleteAlarmEvent, selectedRow]);

  const handleConfirm = useCallback(() => {
    switch (confirmEventType) {
      case ConfirmType.ALARM_POPUP:
        if (isChecked) {
          updateAlarmEventStatus({
            variables: {
              alarmUse: AlarmStatus.OFF
            }
          });
        } else {
          updateAlarmEventStatus({
            variables: {
              alarmUse: AlarmStatus.ON
            }
          });
        }
        break;
      case ConfirmType.ADD:
        handleAddAlarmEvent();
        break;
      case ConfirmType.UPDATE:
        handleUpdateAlarmEvent();
        break;
      case ConfirmType.DELETE:
        handleDeleteAlarmEvent();
        break;
    }
    handleIsOpen(false);
  }, [
    handleDeleteAlarmEvent,
    handleUpdateAlarmEvent,
    isChecked,
    updateAlarmEventStatus,
    handleIsOpen,
    confirmEventType,
    handleAddAlarmEvent
  ]);

  useEffect(() => {
    if (typeof selectedRow?.original.pushMin === "number") {
      setValue("pushMin", selectedRow.original.pushMin.toString());
    }
    if (selectedRow?.original.title) {
      setValue("title", selectedRow.original.title);
    }
    if (selectedRow?.original.description) {
      setValue("description", selectedRow.original.description);
    }
  }, [selectedRow, setValue]);

  useEffect(() => {
    getListOfAlarm();
    getAlarmEventStatus();
  }, [getListOfAlarm, getAlarmEventStatus]);

  return (
    <CollapsiblePanel title="알람설정">
      <Container>
        <TableSection>
          <AsonicTable<GetListOfAlarm_getListOfAlarm_list>
            title="알림 설정"
            handleSelectRow={handleSelectRow}
            isLoading={loading}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            isTitleBar={false}
          />
        </TableSection>
        <Form onSubmit={handleSubmit}>
          <SectionContainer>
            <TitleBar>
              <CheckBoxContainer>
                <CheckBoxTitle>알람팝업(PUSH) 켜기/끄기</CheckBoxTitle>
                <CheckBoxUi
                  checked={isChecked}
                  htmlFor="on-off"
                  onChange={handleCheck}
                  name="ON/OFF"
                />
              </CheckBoxContainer>
            </TitleBar>
            <FormSection>
              <NativeSelect
                title="알람종류"
                propFor="alarmType"
                value={selectedItem}
                datas={[
                  { title: "출근알람", value: AlarmType.ATTENDANCE },
                  { title: "퇴근알람", value: AlarmType.LEAVE_WORK }
                ]}
                handleSelectedItem={handleSelectedItem}
              />
            </FormSection>
            <FormSection>
              <TextInputWithLabel
                title="알림시간"
                {...register("pushMin", { required: true })}
                type="number"
                required={true}
              />
            </FormSection>
            <FormSection>
              <TextInputWithLabel
                title="타이틀"
                {...register("title", { required: true })}
                required={true}
              />
            </FormSection>
            <FormSection>
              <TextInputWithLabel title="내용" {...register("description")} />
            </FormSection>
          </SectionContainer>
          <ButtonsContainer>
            <Button name={ConfirmType.DELETE} onClick={handleConfirmEventType}>
              삭제
            </Button>
            <Button name={ConfirmType.ADD} onClick={handleConfirmEventType}>
              추가
            </Button>
            <Button name={ConfirmType.UPDATE} onClick={handleConfirmEventType}>
              수정
            </Button>
          </ButtonsContainer>
        </Form>
        {isOpen && (
          <ConfirmDialog
            confirmTitle={confirmTitle}
            confirmParagraph={confirmParagraph}
            confirmType={confirmType}
            messageTypes={MessageTypes.SUCCESS}
            handleIsOpen={handleIsOpen}
            handleConfirm={handleConfirm}
          />
        )}
        <ToastMessage
          message={message}
          isOpen={isToastMessageOpen}
          handleIsOpen={handleIsToastMessageOpen}
          messageTypes={toastMessageType}
        />
      </Container>
    </CollapsiblePanel>
  );
};

export default AlarmPushPresenter;
