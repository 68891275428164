import AsonicExcel from "../../../../asonic-table/asonic-excel";
import { ExcelContainer } from "./styled";

const Excel = props => {
  const { downloadExcel } = props;
  return (
    <ExcelContainer>
      <AsonicExcel downloadExcel={downloadExcel} />
    </ExcelContainer>
  );
};

export default Excel;
