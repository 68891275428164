import SectionContainer from "../../shared/section_container/section_container";
import SubTitle from "../../shared/sub-title/sub-title";
import ContentContainer from "../../shared/grid_content_container/grid_content_container";
import FormRow from "../../shared/form-row/form-row";
import StyleInput from "../../inputs/style-input";
import SSelect from "../../shared/s_select/s_select";
import TitleContainer from "../../shared/title_container/title_container";
import Button from "../../globalComponents/Button";
import AbsenceManagementHelp from "./absence_management_help";
import RadioContainer from "../../shared/radio_container/radio_container";
import WSBottomContainer from "../w_s_bottom_container/w_s_bottom_container";
import AutoAbsenceManagementHelp from "./auto_absence_management_help";
import useTimeList from "../../../hooks/use_time_list/use_time_list";
import {
  Is_Use,
  useGetAbsenceManagementInfoLazyQuery
} from "../../../generated/graphql";
import { useContext, useEffect, useState } from "react";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import { WS_STATE } from "../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import { SInput } from "../work_time_tab/choice_w_s/choice_basic_w_s/choice_basic_w_s";

function AbsenceManagement() {
  const wSState = useContext(WS_STATE);
  const { minListForAttendance } = useTimeList();
  const [leftSeatAttendeeInput, setLeftSeatAttendeeInput] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [leftSeatBlackScreenUse, setLeftSeatBlackScreenUse] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [leftSeatPlaceInput, setLeftSeatPlaceInput] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [leftSeatAutoUse, setLeftSeatAutoUse] = useState<Is_Use>(Is_Use.UnUse);
  const [leftSeatBaseMin, setLeftSeatBaseMin] = useState<number>(0);
  const [leftSeatReasonUse, setLeftSeatReasonUse] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [extendLeftSeatAutoCommuteOutUse, setExtendLeftSeatAutoCommuteOutUse] =
    useState<Is_Use>(Is_Use.UnUse);
  const [leftSeatApplyTimeUse, setLeftSeatApplyTimeUse] = useState<Is_Use>(
    Is_Use.UnUse
  );

  const [getAbsenceManagementInfo] = useGetAbsenceManagementInfoLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getAbsenceManagementInfo.ok &&
        data.getAbsenceManagementInfo.info
      ) {
        const {
          extendLeftSeatAutoCommuteOutUse,
          leftSeatApplyTimeUse,
          leftSeatAttendeeInput,
          leftSeatAutoUse,
          leftSeatBaseMin,
          leftSeatBlackScreenUse,
          leftSeatPlaceInput,
          leftSeatReasonUse
        } = data.getAbsenceManagementInfo.info;
        setLeftSeatBlackScreenUse(leftSeatBlackScreenUse);
        setLeftSeatAttendeeInput(leftSeatAttendeeInput);
        setLeftSeatPlaceInput(leftSeatPlaceInput);
        setLeftSeatAutoUse(leftSeatAutoUse);
        setLeftSeatBaseMin(leftSeatBaseMin);
        setLeftSeatReasonUse(leftSeatReasonUse);
        setLeftSeatApplyTimeUse(leftSeatApplyTimeUse);
        setExtendLeftSeatAutoCommuteOutUse(extendLeftSeatAutoCommuteOutUse);
      } else if (data.getAbsenceManagementInfo.error) {
        handleErrorLog(data.getAbsenceManagementInfo.error);
      }
    }
  });

  useEffect(() => {
    if (wSState?.workingTemplateIdx && wSState?.setAbsenceManagementInfo) {
      wSState?.setAbsenceManagementInfo({
        workingTemplateIdx: wSState?.workingTemplateIdx,
        leftSeatBlackScreenUse,
        leftSeatPlaceInput,
        leftSeatAttendeeInput,
        leftSeatAutoUse,
        leftSeatBaseMin,
        leftSeatReasonUse,
        extendLeftSeatAutoCommuteOutUse,
        leftSeatApplyTimeUse
      });
    }
  }, [
    wSState?.workingTemplateIdx,
    wSState?.setAbsenceManagementInfo,
    leftSeatBlackScreenUse,
    leftSeatPlaceInput,
    leftSeatAttendeeInput,
    leftSeatAutoUse,
    leftSeatBaseMin,
    leftSeatReasonUse,
    extendLeftSeatAutoCommuteOutUse,
    leftSeatApplyTimeUse
  ]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getAbsenceManagementInfo({
        variables: {
          workingTemplateIdx: wSState?.workingTemplateIdx
        }
      });
    }
  }, [wSState?.workingTemplateIdx, getAbsenceManagementInfo]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"이석(자리비움) 관리"} />
        <AbsenceManagementHelp />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title={"사유입력 설정"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="blackScreen"
              minWidth="20px"
              maxWidth="20px"
              name="reason"
              checked={leftSeatBlackScreenUse === Is_Use.Use}
              onChange={() => {
                setLeftSeatBlackScreenUse(isUse => {
                  if (isUse === Is_Use.UnUse) {
                    return Is_Use.Use;
                  }
                  return Is_Use.UnUse;
                });
              }}
            />
            <label htmlFor="blackScreen">{`블랙스크린 적용`}</label>
            <StyleInput
              type="checkbox"
              id="place"
              minWidth="20px"
              maxWidth="20px"
              name="reason"
              checked={leftSeatPlaceInput === Is_Use.Use}
              onChange={() => {
                setLeftSeatPlaceInput(isUse => {
                  if (isUse === Is_Use.UnUse) {
                    return Is_Use.Use;
                  }
                  return Is_Use.UnUse;
                });
              }}
            />
            <label htmlFor="place">{`이동 장소 입력`}</label>
            <StyleInput
              type="checkbox"
              id="participants"
              minWidth="20px"
              maxWidth="20px"
              name="reason"
              checked={leftSeatAttendeeInput === Is_Use.Use}
              onChange={() => {
                setLeftSeatAttendeeInput(isUse => {
                  if (isUse === Is_Use.UnUse) {
                    return Is_Use.Use;
                  }
                  return Is_Use.UnUse;
                });
              }}
            />
            <label htmlFor="participants">{`참여자(동행자) 입력`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="">
          <span></span>
        </FormRow>
      </ContentContainer>
      <>
        <TitleContainer flex={0}>
          <SubTitle title={"자동 이석(자리비움) 관리"} />
          <AutoAbsenceManagementHelp />
        </TitleContainer>
        <ContentContainer flex={0}>
          <FormRow title={"자동이석 감지 사용"}>
            <RadioContainer>
              <StyleInput
                type="radio"
                id="unUsedAutomaticDetection"
                minWidth="20px"
                maxWidth="20px"
                name="automaticDetection"
                checked={leftSeatAutoUse === Is_Use.UnUse}
                onChange={() => {
                  setLeftSeatAutoUse(Is_Use.UnUse);
                }}
              />
              <label htmlFor="unUsedAutomaticDetection">{`사용안함`}</label>
              <StyleInput
                type="radio"
                id="useAutomaticDetection"
                minWidth="20px"
                maxWidth="20px"
                name="automaticDetection"
                checked={leftSeatAutoUse === Is_Use.Use}
                onChange={() => {
                  setLeftSeatAutoUse(Is_Use.Use);
                }}
              />
              <label htmlFor="useAutomaticDetection">{`사용`}</label>
            </RadioContainer>
          </FormRow>
          <FormRow title="이석 기준 시간">
            <SInput
              type="number"
              name="min"
              id="min"
              value={String(leftSeatBaseMin).padStart(2, "0")}
              onChange={event => {
                setLeftSeatBaseMin(Number(event.currentTarget.value));
              }}
              max={59}
              min={0}
            />
            <label htmlFor="min">{`분 이상 자리 비움 감지시 이석 적용(자동 이석 감지에만 적용됨)`}</label>
          </FormRow>
          <FormRow title={"이석 해제시 사유 입력"}>
            <RadioContainer>
              <StyleInput
                type="radio"
                id="disableReasonUnused"
                minWidth="20px"
                maxWidth="20px"
                name="disableReason"
                checked={leftSeatReasonUse === Is_Use.UnUse}
                onChange={() => {
                  setLeftSeatReasonUse(Is_Use.UnUse);
                }}
              />
              <label htmlFor="disableReasonUnused">{`사용안함`}</label>
              <StyleInput
                type="radio"
                id="disableReasonUse"
                minWidth="20px"
                maxWidth="20px"
                name="disableReason"
                checked={leftSeatReasonUse === Is_Use.Use}
                onChange={() => {
                  setLeftSeatReasonUse(Is_Use.Use);
                }}
              />
              <label htmlFor="disableReasonUse">{`사용`}</label>
            </RadioContainer>
          </FormRow>
          <FormRow title={"연장 근무 시 이석"}>
            <RadioContainer>
              <StyleInput
                type="checkbox"
                id="vacanciesDuringOvertime"
                minWidth="20px"
                maxWidth="20px"
                name="vacanciesDuringOvertime"
                checked={extendLeftSeatAutoCommuteOutUse === Is_Use.Use}
                onChange={() => {
                  setExtendLeftSeatAutoCommuteOutUse(isUse => {
                    if (isUse === Is_Use.Use) {
                      return Is_Use.UnUse;
                    }
                    return Is_Use.Use;
                  });
                }}
              />
              <label htmlFor="vacanciesDuringOvertime">{`마지막 이석 발생 시간으로 퇴근 시간 자동 적용(연장근무에서 이석 사용시)`}</label>
            </RadioContainer>
          </FormRow>
          <FormRow title={"근무시간 이석 적용 여부"}>
            <RadioContainer>
              <StyleInput
                type="radio"
                id="isAbsenceUnused"
                minWidth="20px"
                maxWidth="20px"
                name="isAbsence"
                checked={leftSeatApplyTimeUse === Is_Use.UnUse}
                onChange={() => {
                  setLeftSeatApplyTimeUse(Is_Use.UnUse);
                }}
              />
              <label htmlFor="isAbsenceUnused">{`사용안함`}</label>
              <StyleInput
                type="radio"
                id="isAbsenceUse"
                minWidth="20px"
                maxWidth="20px"
                name="isAbsence"
                checked={leftSeatApplyTimeUse === Is_Use.Use}
                onChange={() => {
                  setLeftSeatApplyTimeUse(Is_Use.Use);
                }}
              />
              <label htmlFor="isAbsenceUse">{`사용`}</label>
            </RadioContainer>
          </FormRow>
          <FormRow title="">
            <span></span>
          </FormRow>
        </ContentContainer>
      </>
      <WSBottomContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          disabled={!wSState?.workingTemplateIdx}
          onClick={() => {
            wSState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </WSBottomContainer>
    </SectionContainer>
  );
}

export default AbsenceManagement;
