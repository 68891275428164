import { Component } from "react";
import { TextFieldAlignDiv } from "./styled";
import TextField from "../TextField";
import uiString from "../string.json";
import { setSmtpInfo } from "../../../../Utils/commonAxiosCall";
import Button from "../../../globalComponents/Button";
import { MessageTypes } from "../../../toast-message/toast-message";
import CollapsiblePanel from "../../smallcomponents/CollapsiblePanel";

class EmailSetup extends Component {
  state = {
    requestAlert: true,
    approvedAlert: true,
    smtpAddress: this.props.smtpSetup && this.props.smtpSetup.smtp_address,
    smtpPort: this.props.smtpSetup && this.props.smtpSetup.smtp_port,
    alertEmail: this.props.smtpSetup && this.props.smtpSetup.userid,
    alertEmailPassword: ""
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleText = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSmtpSave = setSmtp => () => {
    const { employeeId, handleIsOpen, handleMessage, handleToastMessageType } =
      this.props;
    const { smtpAddress, smtpPort, alertEmail, alertEmailPassword } =
      this.state;
    if (
      smtpAddress === "" ||
      smtpPort === "" ||
      alertEmail === "" ||
      alertEmailPassword === ""
    ) {
      handleIsOpen(true);
      handleMessage(uiString.notiBars.mustFillOut);
      handleToastMessageType(MessageTypes.WARNING);
    } else {
      setSmtpInfo(
        employeeId,
        smtpAddress,
        smtpPort,
        alertEmail,
        alertEmailPassword
      )
        .then(response => {
          if (response.status === 200) {
            setSmtp({
              smtpSetup: {
                smtp_address: smtpAddress,
                smtp_port: smtpPort,
                userid: alertEmail,
                userpassword: alertEmailPassword
              }
            });
            handleIsOpen(true);
            handleMessage(uiString.notiBars.setSmtpSuccess);
            handleToastMessageType(MessageTypes.SUCCESS);
          }
        })
        .catch(e => {
          handleIsOpen(true);
          handleMessage(uiString.notiBars.setSmtpFailure);
          handleToastMessageType(MessageTypes.ERROR);
        });
    }
  };

  // componentDidMount() {
  //   this.setState({
  //     smtpAddress: this.props.smtpSetup && this.props.smtpSetup.smtp_address,
  //     smtpPort: this.props.smtpSetup && this.props.smtpSetup.smtp_port,
  //     alertEmail: this.props.smtpSetup && this.props.smtpSetup.userid
  //   });
  // }

  render() {
    const { authCheck } = this.props;
    return (
      <CollapsiblePanel title={uiString.titles.setupNotificationEmail}>
        <TextFieldAlignDiv>
          <TextField
            label={uiString.textFields.smtpServerAddress}
            placeholder={uiString.textFields.smtpServerAddressDesc}
            value={this.state.smtpAddress}
            valueChange={this.handleText("smtpAddress")}
            type="text"
          />
          <TextField
            type="number"
            label={uiString.textFields.smtpPort}
            placeholder={uiString.textFields.smtpPortDesc}
            value={this.state.smtpPort}
            valueChange={this.handleText("smtpPort")}
          />
          <TextField
            type="email"
            label={uiString.textFields.userEmail}
            placeholder={uiString.textFields.userEmailDesc}
            value={this.state.alertEmail}
            valueChange={this.handleText("alertEmail")}
          />
          <TextField
            type="password"
            label={uiString.textFields.emailPassword}
            placeholder={uiString.textFields.emailPasswordDesc}
            value={this.state.alertEmailPassword}
            valueChange={this.handleText("alertEmailPassword")}
          />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }} />
            {authCheck && (
              <Button onClick={this.handleSmtpSave(this.props.setSmtp)}>
                {uiString.buttons.confirm}
              </Button>
            )}
          </div>
        </TextFieldAlignDiv>
      </CollapsiblePanel>
    );
  }
}

export default EmailSetup;
