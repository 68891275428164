import { useCallback, useState } from "react";
import TextField from "../TextField";
import SearchIcon from "@iconify/icons-mdi/magnify";
import uiString from "../string.json";
import {
  searchEmployees,
  setUserEmail,
  setUserCCList
} from "../../../../Utils/commonAxiosCall";
import Button from "../../../globalComponents/Button";
import { MessageTypes } from "../../../toast-message/toast-message";
import CollapsiblePanel from "../../smallcomponents/CollapsiblePanel";
import AsonicIconButton from "../../../shared/asonic-icon-button/asonic-icon-button";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import RemoveIcon from "@iconify/icons-fa-solid/minus-circle";
import ProfileCard from "../../../profile-card/profile-card";
import { colors } from "../../../GlobalStyle/GlobalStyle";

const TextFieldAlignDiv = styled.div`
  width: 100%;
  svg {
    font-size: 18px;
  }
`;

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const ListSection = styled.section`
  display: flex;
  height: ${props => (props.isHeight ? "100px" : "0px")};
`;

const RenderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin: 0px 10px;
  }
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 24px;
  }
`;

function RenderRow(onClick, isSelected) {
  return ({ data, index, style }) => {
    const user = data[index];
    let base64String = "";
    if (user?.my_picture?.data && user.my_picture.data.length > 1) {
      const buf = Buffer.from(user.my_picture.data);
      base64String = buf.toString("base64");
    }

    const userInfo = {
      employeeId: user.employee_id,
      name: user.employee_name,
      department: user.department_name,
      picture: base64String,
      telephone: user?.telno || "",
      email: user?.user_email || ""
    };

    return (
      <RenderContainer style={style}>
        <ProfileCard userInfo={userInfo}>
          <AsonicIconButton
            icon={isSelected ? RemoveIcon : AddIcon}
            onClick={onClick(user)}
            color={isSelected ? colors.tomato : ""}
          />
        </ProfileCard>
      </RenderContainer>
    );
  };
}

function PersonalEmail(props) {
  const [searchName, setSearchName] = useState("");
  const [personalEmail, setPersonalEmail] = useState(props.userEmail || "");
  const [searchResult, setSearchResult] = useState([]);
  const [cclist, setCclist] = useState(props.cclist);

  const handlePersonalEmail = useCallback(event => {
    setPersonalEmail(event.target.value);
  }, []);

  const handleSearchName = useCallback(event => {
    setSearchName(event.target.value);
  }, []);

  const handleSearchCCPerson = useCallback(() => {
    if (searchName.length < 2) {
      // this.setState({
      //   notiOpen: true,
      //   notiMsg: uiString.notiBars.nameLessThanTwoLetters
      // });
      return;
    }

    searchEmployees(searchName)
      .then(employees => {
        if (!employees.data) return;
        let newSearchResult = [];
        for (let user of employees.data) {
          user["key"] = user.employee_id;
          newSearchResult.push(user);
        }
        setSearchResult(newSearchResult);
      })
      .catch(error => console.log(error));
  }, [searchName]);

  const handleKeyPress = useCallback(
    event => {
      if (event.key === "Enter") {
        handleSearchCCPerson();
      }
    },
    [handleSearchCCPerson]
  );

  const handleDeleteCCList = useCallback(
    chosenUser => () => {
      const chosenList = [...cclist];
      for (let i = 0; i < chosenList.length; i++) {
        if (chosenList[i].employee_id === chosenUser.employee_id) {
          chosenList.splice(i, 1);
          break;
        }
      }
      setCclist(chosenList);
    },
    [cclist]
  );

  const handleAdd = useCallback(
    data => () => {
      const chosenList = [...cclist];
      let alreadyHave = -1;
      for (let i = 0; i < chosenList.length; i++) {
        if (chosenList[i].employee_id === data.employee_id) {
          alreadyHave = i;
          break;
        }
      }

      if (alreadyHave === -1) {
        chosenList.push(data);
      }
      setCclist(chosenList);
    },
    [cclist]
  );

  const handleSave = useCallback(async () => {
    const { employeeId, handleIsOpen, handleMessage, handleToastMessageType } =
      props;
    if (personalEmail === "") return;
    try {
      const { status: emailStatus } = await setUserEmail(
        employeeId,
        personalEmail
      );
      const { status: cCListStatus } = await setUserCCList(
        employeeId,
        cclist.map(user => {
          return { employeeId: user.employee_id, email: user.user_email };
        })
      );
      if (emailStatus === 200 && cCListStatus === 200) {
        handleIsOpen(true);
        handleMessage("Email 및 참조자 목록을 변경했습니다.");
        handleToastMessageType(MessageTypes.SUCCESS);
      }
    } catch (error) {
      handleIsOpen(true);
      handleMessage("Email 및 참조자 목록을 변경 실패했습니다.");
      handleToastMessageType(MessageTypes.ERROR);
    }
  }, [personalEmail, props, cclist]);

  return (
    <CollapsiblePanel expand title={uiString.titles.setupPersonalEmail}>
      <TextFieldAlignDiv>
        <TextField
          label={uiString.textFields.userAlertEmail}
          placeholder={uiString.textFields.userAlertEmailDesc}
          value={personalEmail}
          valueChange={handlePersonalEmail}
          type="text"
        />
        <SearchSection>
          <TextField
            label={uiString.textFields.userAlertCCList}
            placeholder={uiString.textFields.userAlertCCListDesc}
            value={searchName}
            valueChange={handleSearchName}
            onKeyPress={handleKeyPress}
            type="text"
          />
          <AsonicIconButton icon={SearchIcon} onClick={handleSearchCCPerson} />
        </SearchSection>
        <ListSection isHeight={searchResult.length > 0}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={searchResult.length}
                itemData={searchResult}
                itemSize={330}
                width={width}
                layout="horizontal"
              >
                {RenderRow(handleAdd, false)}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <Divider style={{ margin: "30px 0px 30px 0px" }} />
        <h4>{uiString.titles.chosenCCEmail}</h4>
        <ListSection isHeight={cclist.length > 0}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={cclist.length}
                itemData={cclist}
                itemSize={330}
                width={width}
                layout="horizontal"
              >
                {RenderRow(handleDeleteCCList, true)}
              </List>
            )}
          </AutoSizer>
        </ListSection>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }} />
          <Button onClick={handleSave}>{uiString.buttons.confirm}</Button>
        </div>
      </TextFieldAlignDiv>
    </CollapsiblePanel>
  );
}

export default PersonalEmail;
