import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  GetListOfTemplateScheduleInfo,
  GetListOfTemplateScheduleInfoVariables,
  GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list
} from "../../../../../__generated__/GetListOfTemplateScheduleInfo";
import AsonicTable from "../../../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import {
  GetListOfTimeTypeCategory,
  GetListOfTimeTypeCategory_getListOfTimeTypeCategory_list
} from "../../../../../__generated__/GetListOfTimeTypeCategory";
import TimeTemplateScheduleEditor from "./time-template-schedule-editor";

interface IProps {
  selectedTimeTemplateIdx?: number;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const QUERY_GET_LIST_Of_TEMPLATE_SCHEDULE_INFO = gql`
  query GetListOfTemplateScheduleInfo($timeTemplateIdx: Int!) {
    getListOfTemplateScheduleInfo(timeTemplateIdx: $timeTemplateIdx) {
      ok
      error
      list {
        timeIdx
        timeTemplateIdx
        categoryId
        isMonday
        isTuesDay
        isWednesday
        isThursday
        isFriday
        isSaturday
        isSunday
        periodDateStart
        periodDateEnd
        startTime
        endTime
      }
    }
  }
`;

const QUERY_GET_LIST_OF_TIME_TYPE_CATEGORY = gql`
  query GetListOfTimeTypeCategory {
    getListOfTimeTypeCategory {
      ok
      error
      list {
        categoryId
        name
        useTableType
        timeCalcType
      }
    }
  }
`;

function TimeTemplateSchedule({ selectedTimeTemplateIdx }: IProps) {
  const [listOfTimeTypeCategory, setListOfTimeTypeCategory] = useState<
    GetListOfTimeTypeCategory_getListOfTimeTypeCategory_list[]
  >([]);
  const [getListOfTemplateScheduleInfo, { data, loading }] = useLazyQuery<
    GetListOfTemplateScheduleInfo,
    GetListOfTemplateScheduleInfoVariables
  >(QUERY_GET_LIST_Of_TEMPLATE_SCHEDULE_INFO, {
    fetchPolicy: "cache-and-network"
  });

  const [getListOfTimeTypeCategory] = useLazyQuery<GetListOfTimeTypeCategory>(
    QUERY_GET_LIST_OF_TIME_TYPE_CATEGORY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (
          data.getListOfTimeTypeCategory.ok &&
          data.getListOfTimeTypeCategory.list
        ) {
          setListOfTimeTypeCategory(data.getListOfTimeTypeCategory.list);
        }
      }
    }
  );

  const columns: ReactTable.Column<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>[] =
    useMemo(() => {
      return [
        {
          Header: "구분",
          accessor(data) {
            let type = "출/퇴근";
            listOfTimeTypeCategory.forEach(item => {
              if (item.categoryId === data.categoryId && item.name) {
                type = item.name;
              }
            });
            return type;
          }
        },
        { Header: "시작날짜", accessor: "periodDateStart" },
        { Header: "끝나는 날짜", accessor: "periodDateEnd" },
        {
          Header: "운영 요일",
          accessor(data) {
            let weeks = "";
            if (data.isMonday) {
              weeks += "월";
            }
            if (data.isTuesDay) {
              if (weeks.length > 0) {
                weeks += "/화";
              } else {
                weeks += "화";
              }
            }
            if (data.isWednesday) {
              if (weeks.length > 0) {
                weeks += "/수";
              } else {
                weeks += "수";
              }
            }
            if (data.isThursday) {
              if (weeks.length > 0) {
                weeks += "/목";
              } else {
                weeks += "목";
              }
            }
            if (data.isFriday) {
              if (weeks.length > 0) {
                weeks += "/금";
              } else {
                weeks += "금";
              }
            }
            if (data.isSaturday) {
              if (weeks.length > 0) {
                weeks += "/토";
              } else {
                weeks += "토";
              }
            }
            if (data.isSunday) {
              if (weeks.length > 0) {
                weeks += "/일";
              } else {
                weeks += "일";
              }
            }
            return weeks;
          }
        },
        {
          Header: "운영 시간",
          accessor(data) {
            return `${data.startTime} ~ ${data.endTime}`;
          }
        }
      ];
    }, [listOfTimeTypeCategory]);

  const list: GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list[] =
    useMemo(() => {
      return data?.getListOfTemplateScheduleInfo.list || [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: timeTemplateColumns
  } = ReactTable.useTable<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const selectedRow:
    | IAsonicRow<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>
    ) => {},
    []
  );

  useEffect(() => {
    getListOfTimeTypeCategory();
  }, [getListOfTimeTypeCategory]);

  useEffect(() => {
    if (selectedTimeTemplateIdx && listOfTimeTypeCategory.length > 0) {
      getListOfTemplateScheduleInfo({
        variables: {
          timeTemplateIdx: selectedTimeTemplateIdx
        }
      });
    }
  }, [
    selectedTimeTemplateIdx,
    getListOfTemplateScheduleInfo,
    listOfTimeTypeCategory
  ]);

  return (
    <Container>
      <ContentContainer>
        <span>근무계획</span>
        <AsonicTable<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>
          title="근무계획"
          handleSelectRow={handleSelectRow}
          isLoading={loading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          columns={
            timeTemplateColumns as TColumn<GetListOfTemplateScheduleInfo_getListOfTemplateScheduleInfo_list>[]
          }
          isTitleBar={false}
        />
      </ContentContainer>
      <ContentContainer>
        <TimeTemplateScheduleEditor
          selectedRow={selectedRow}
          selectedTimeTemplateIdx={selectedTimeTemplateIdx}
        />
      </ContentContainer>
    </Container>
  );
}

export default TimeTemplateSchedule;
