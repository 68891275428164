import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface IProps {
  size?: any;
  name?: string;
  value?: string;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hoverColor?: string;
  icon: any;
  isBorder?: boolean;
  borderColor?: string;
  disabled?: boolean;
}

const Icon = styled(FontAwesomeIcon)``;

const Button = styled.button<IProps>`
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  outline: none;
  border: none;
  font-weight: bold;
  background: none;
  :hover {
    svg {
      color: ${props => props.hoverColor ?? props.theme.colors.lightGreen};
    }
  }
  :active {
    transform: scale(0.98);
  }
  font-family: "Gothic", sans-serif;
  ${Icon} {
    color: ${props => props.color ?? props.theme.colors.green};
  }
  opacity: ${props => (props.disabled ? "0.2" : "1")};
`;

const IconButton = (props: IProps) => {
  const { icon, size } = props;
  return (
    <Button {...props}>
      <Icon icon={icon} size={size || "lg"} />
    </Button>
  );
};

export default IconButton;
