import * as React from "react";
import "moment/locale/ko";
import { useSelector } from "react-redux";
import styled from "styled-components";
import basicImg from "../../../../images/basicimage.jpg";
import useProfile from "../../../../hooks/electron-hook/service-status-hook/profile-hook/use-profile";
import { Reducers } from "../../../../../types/reducers";

type Props = {
  photo: string;
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #263238;
  padding: 0px 10px 0px 10px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  font-size: 15px;
`;

export const Date = styled.span`
  margin-right: 2px;
`;

export const TimeDescription = styled.span`
  font-size: 15px;
`;

export const Time = styled.span`
  font-size: 26px;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 9;
  align-items: center;
`;

export const ProfilePicture = styled.div<Props>`
  height: 60%;
  flex: 1;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
  background-image: ${props =>
    props.photo
      ? `url( data:image/png+jpg;base64,${props.photo})`
      : `url(${basicImg})`};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DepartmentName = styled.span``;

export const UserName = styled.span`
  font-size: 13px;
`;

const MobileProfile = () => {
  const signInReducer = useSelector((state: Reducers) => state.signInReducer);
  const { photo, date, time } = useProfile({ signInReducer });
  return (
    <Container data-testid="profile">
      <ProfileInfoContainer>
        <ProfilePicture photo={photo} />
        <Content>
          <DepartmentName>{signInReducer.department_name}</DepartmentName>
          <UserName>{signInReducer.name}</UserName>
        </Content>
        <DateContainer>
          <Date>{date}</Date>
          <Time>
            <TimeDescription>현재시간</TimeDescription> {time}
          </Time>
        </DateContainer>
      </ProfileInfoContainer>
    </Container>
  );
};

export default MobileProfile;
