import styled from "styled-components";
import TData from "./t_data";

const THeaderRow = styled.div`
  display: flex;
  flex: 1;
  :hover {
    ${TData} {
      color: ${props => props.theme.colors.black};
    }
  }
`;

export default THeaderRow;
