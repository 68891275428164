import { useCallback, useState } from "react";
import styled from "styled-components";
import DrivingInformation from "../components/driving-information/driving-information";
import VehicleInformation from "../components/vehicle-information/vehicle-information";
import { GetListOfVehicleInformation_getListOfVehicleInformation_list } from "../__generated__/GetListOfVehicleInformation";
import * as ReactTable from "react-table";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  padding: 10px;
  :first-child {
    border-bottom: 2px solid ${props => props.theme.colors.black};
  }
`;

function VehicleDrivingManagementScreen() {
  const [isDisableZIndex, setIsDisableZIndex] = useState(false);
  const handleIsDisableZIndex = useCallback((value: boolean) => {
    setIsDisableZIndex(value);
  }, []);

  const [selectedVehicle, setSelectedVehicle] =
    useState<
      ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>
    >();

  const handleSelectedVehicle = useCallback(
    (
      vehicle: ReactTable.Row<GetListOfVehicleInformation_getListOfVehicleInformation_list>
    ) => {
      setSelectedVehicle(vehicle);
    },
    []
  );

  return (
    <Container>
      <Section>
        <VehicleInformation
          handleSelectedVehicle={handleSelectedVehicle}
          handleIsDisableZIndex={handleIsDisableZIndex}
        />
      </Section>
      <Section>
        <DrivingInformation
          selectedVehicle={selectedVehicle}
          isDisableZIndex={isDisableZIndex}
        />
      </Section>
    </Container>
  );
}

export default VehicleDrivingManagementScreen;
