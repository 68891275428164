import { WorkInformation } from "../../../../../../hooks/additional-information-management-hooks/use-additional-information-management-data";
import styled from "styled-components";
import moment from "moment";
import IconButton from "../../../../../globalComponents/IconButton";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import FormRow from "../../../../../shared/form-row/form-row";
import StyleInput from "../../../../../inputs/style-input";
import SubTitle from "../../../../../shared/sub-title/sub-title";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../../types/reducers";
import { Role } from "../../../../../../user-types";
import Button from "../../../../../globalComponents/Button";
import WorkInformationManagementHelp from "./work-information-management-help";
import { colors } from "../../../../../GlobalStyle/GlobalStyle";
import UserInformationHistory from "./user-information-change-history";
import useOpenDialog from "../../../../../../hooks/use-open-dialog";
import AsonicDialog from "../../../../../asonic-dialog/asonic-dialog";
import {
  UpdateUserAnnualInfoMutationVariables,
  useGetUserAnnualInfoLazyQuery,
  UserAnnualInfoEntity,
  useUpdateUserAnnualInfoMutation
} from "../../../../../../generated/graphql";
import { Fragment, useEffect, useMemo, useState } from "react";
import ToastMessage, {
  MessageTypes
} from "../../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../../hooks/toast-message-hook/use-open-toast-message";

interface IProps {
  employeeId: string;
  isUpdateButton: boolean;
  workInformation?: WorkInformation;
}

const Container = styled.form`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 14px;
  flex-direction: column;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
`;

const DivSection = styled.section`
  display: flex;
  width: 845px;
  flex-direction: column;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const SectionContent = styled.div`
  display: flex;
  flex: 1;
  ${Section} {
    flex: 2;
  }
`;

const StandardYearSection = styled.section`
  display: flex;
  flex: 1;
  > div {
    > div {
      background-color: ${props => props.theme.colors.lightGrey};
    }
  }
  margin-bottom: 20px;
`;

const Select = styled.select<{ isShow: boolean }>`
  display: ${props => (props.isShow ? "block" : "none")};
  visibility: ${props => (props.isShow ? "visible" : "hidden")};
  opacity: ${props => (props.isShow ? "1" : "0")};
`;

const Option = styled.option<{ isShow: boolean }>`
  display: ${props => (props.isShow ? "block" : "none")};
  visibility: ${props => (props.isShow ? "visible" : "hidden")};
  opacity: ${props => (props.isShow ? "1" : "0")};
`;

const LastSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const ButtonSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SSpan = styled.span`
  display: flex;
  min-width: 163px;
  padding: 5px;
`;

const SpanContainer = styled.div`
  display: flex;
  min-width: 212px;
`;

const SubtitleContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 0px;
`;

interface IField
  extends Omit<
    UserAnnualInfoEntity,
    "employeeId" | "createSubject" | "createSubjectEmployeeId"
  > {
  inquiryYear: number;
}

const WorkInformationManagement = ({
  employeeId,
  isUpdateButton,
  workInformation
}: IProps) => {
  const [isSearch, setIsSearch] = useState<boolean>(true);
  const [basicIsPlus, setBasicIsPlus] = useState("+");
  const [useModifyDayPlus, setUseModifyDayPlus] = useState("+");
  const {
    signInReducer: { loginIdInfo, name, employee_id }
  } = useSelector((state: Reducers) => state);
  const { register, getValues, setValue, handleSubmit, watch } =
    useForm<IField>({
      defaultValues: {
        inquiryYear: parseInt(moment().format("YYYY")),
        applyYear: moment().format("YYYY"),
        basicModifyDay: 0,
        useModifyDay: 0
      }
    });

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [getUserAnnualInfo] = useGetUserAnnualInfoLazyQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(`연차 정보를 가져올 수 없습니다.`, MessageTypes.ERROR);
    },
    onCompleted(data) {
      if (data.getUserAnnualInfo.ok && data.getUserAnnualInfo.userAnnualInfo) {
        const {
          addDay,
          applyYear,
          basicDay,
          basicModifyDay,
          insertDatetime,
          leaveCarriedForwardDay,
          leaveDay,
          modifyDatetime,
          modifyReason,
          periodateEnd,
          periodateStart,
          statusFlag,
          useDay,
          useModifyDay
        } = data.getUserAnnualInfo.userAnnualInfo;
        if (basicModifyDay >= 0) {
          setBasicIsPlus("+");
        } else {
          setBasicIsPlus("-");
        }
        if (useModifyDay >= 0) {
          setUseModifyDayPlus("+");
        } else {
          setUseModifyDayPlus("-");
        }
        setValue("addDay", addDay);
        setValue("applyYear", applyYear);
        setValue("basicDay", basicDay);
        setValue("basicModifyDay", basicModifyDay);
        setValue("insertDatetime", insertDatetime);
        setValue("leaveCarriedForwardDay", leaveCarriedForwardDay);
        setValue("leaveDay", leaveDay);
        setValue("modifyDatetime", modifyDatetime);
        setValue("modifyReason", modifyReason);
        setValue("periodateEnd", periodateEnd);
        setValue("periodateStart", periodateStart);
        setValue("statusFlag", statusFlag);
        setValue("useDay", useDay);
        setValue("useModifyDay", useModifyDay);
      } else if (data.getUserAnnualInfo.error) {
        handleToast(data.getUserAnnualInfo.error, MessageTypes.WARNING);
      }
    }
  });
  const [updateUserAnnualInfo] = useUpdateUserAnnualInfoMutation({
    onError(error) {
      console.log(error);
      handleToast(
        "알수 없는 이유로 연차 정보를 갱신할 수 없습니다.",
        MessageTypes.WARNING
      );
    },
    update(_, { data }) {
      if (data?.updateUserAnnualInfo.ok) {
        handleToast(
          "성공적으로 연차 정보를 갱신했습니다.",
          MessageTypes.SUCCESS
        );
      } else if (data?.updateUserAnnualInfo.error) {
        handleToast(data?.updateUserAnnualInfo.error, MessageTypes.WARNING);
      }
    }
  });

  const { isOpen, handleOpenDialog } = useOpenDialog();

  const {
    applyYear,
    addDay,
    useDay,
    insertDatetime,
    leaveCarriedForwardDay,
    statusFlag,
    basicModifyDay,
    useModifyDay,
    periodateStart,
    periodateEnd,
    basicDay,
    modifyReason
  } = watch();

  const isAdmin = useMemo(() => loginIdInfo !== Role.USER, [loginIdInfo]);

  useEffect(() => {
    if (isSearch) {
      const inquiryYear = getValues("inquiryYear");
      getUserAnnualInfo({
        variables: {
          employeeId,
          annualYear: inquiryYear.toString()
        }
      });
      setIsSearch(false);
    }
  }, [employeeId, getValues, isSearch, getUserAnnualInfo]);

  return (
    <Container
      onSubmit={handleSubmit((data, event: any) => {
        if (event?.nativeEvent.submitter.name !== "변경이력보기") {
          const {
            applyYear,
            periodateStart,
            periodateEnd,
            basicDay,
            basicModifyDay,
            addDay,
            leaveCarriedForwardDay,
            useModifyDay,
            modifyReason
          } = data;
          if (periodateStart && periodateEnd) {
            const basicDayData = basicDay || 0;
            const payload: UpdateUserAnnualInfoMutationVariables = {
              employeeId,
              createSubject: name,
              createSubjectEmployeeId: employee_id,
              applyYear,
              periodateStart,
              periodateEnd,
              basicDay:
                typeof basicDayData === "number"
                  ? basicDayData
                  : parseInt(basicDayData),
              basicModifyDay: parseInt(
                `${basicIsPlus}${Math.abs(basicModifyDay)}`
              ),
              addDay: addDay ?? 0,
              leaveCarriedForwardDay: leaveCarriedForwardDay ?? 0,
              useModifyDay: parseInt(
                `${useModifyDayPlus}${Math.abs(useModifyDay)}`
              ),
              modifyReason
            };
            console.log(payload);
            updateUserAnnualInfo({
              variables: payload
            });
          }
        }
      })}
    >
      {isAdmin && (
        <StandardYearSection>
          <FormRow title="조회년도">
            <StyleInput
              {...register("inquiryYear")}
              type="number"
              minLength={4}
              maxLength={4}
              min={parseInt(moment().format("YYYY")) - 100}
              max={parseInt(moment().format("YYYY"))}
            />
            <IconButton
              icon={faSearch}
              onClick={event => {
                event.preventDefault();
                setIsSearch(true);
              }}
            />
          </FormRow>
        </StandardYearSection>
      )}
      <DivSection>
        <SubtitleContainer>
          <SubTitle title="연차 정보" />
          <WorkInformationManagementHelp />
        </SubtitleContainer>
        <ContentContainer>
          <Section>
            <SectionContent>
              <FormRow title="귀속년도">
                {isAdmin ? (
                  <StyleInput
                    type="number"
                    {...register("applyYear")}
                    minLength={4}
                    maxLength={4}
                    min={parseInt(moment().format("YYYY")) - 100}
                  />
                ) : (
                  <span>{applyYear}</span>
                )}
              </FormRow>
            </SectionContent>
            <FormRow title="생성일자">
              <span>{insertDatetime?.split(" ")[0]}</span>
            </FormRow>
            <FormRow title="연차 시작일">
              {isAdmin ? (
                <StyleInput type="date" {...register("periodateStart")} />
              ) : (
                <span>{periodateStart}</span>
              )}
            </FormRow>
            <FormRow title="기본일수">
              {isAdmin ? (
                <StyleInput
                  type="number"
                  {...register("basicDay")}
                  placeholder="기본일수"
                  min={0}
                  step={0.5}
                />
              ) : (
                <SSpan>{basicDay}</SSpan>
              )}
              <span>일</span>
            </FormRow>
            <FormRow title="가산일수">
              <SSpan>{addDay}</SSpan>
              <span>일</span>
            </FormRow>
            <FormRow title="사용일수">
              <SSpan>{useDay}</SSpan>
              <span>일</span>
            </FormRow>
            <FormRow title="잔여일 계산">
              <p>{`(기본일수±기본조정일수+가산일수+이월연차) - (사용일수±사용조정일수)`}</p>
            </FormRow>
          </Section>
          <Section>
            <FormRow title="상태">
              <span>{statusFlag}</span>
            </FormRow>
            <FormRow title="시스템">
              <span>시스템</span>
            </FormRow>
            <FormRow title="연차 종료일">
              {isAdmin ? (
                <StyleInput
                  type="date"
                  {...register("periodateEnd")}
                  placeholder="연차 종료일"
                  minWidth="200px"
                />
              ) : (
                <span>{periodateEnd}</span>
              )}
            </FormRow>
            <FormRow title="기본조정">
              {isAdmin ? (
                <Fragment>
                  <Select
                    isShow
                    value={basicIsPlus}
                    onChange={event => {
                      setBasicIsPlus(event.target.value);
                    }}
                  >
                    <Option isShow value="+">
                      +
                    </Option>
                    <Option isShow value="-">
                      -
                    </Option>
                  </Select>
                  <StyleInput
                    type="number"
                    {...register("basicModifyDay")}
                    value={Math.abs(basicModifyDay)}
                    min={0}
                  />
                </Fragment>
              ) : (
                <SpanContainer>
                  <SSpan>{basicModifyDay}</SSpan>
                </SpanContainer>
              )}

              <span>일</span>
            </FormRow>
            <FormRow title="이월연차">
              <SpanContainer>
                <SSpan>{leaveCarriedForwardDay}</SSpan>
              </SpanContainer>
              <span>일</span>
            </FormRow>
            <FormRow title="사용조정">
              {isAdmin ? (
                <Fragment>
                  <Select
                    value={useModifyDayPlus}
                    onChange={event => {
                      setUseModifyDayPlus(event.target.value);
                    }}
                    isShow
                  >
                    <Option isShow value="+">
                      +
                    </Option>
                    <Option isShow value="-">
                      -
                    </Option>
                  </Select>
                  <StyleInput
                    type="number"
                    {...register("useModifyDay")}
                    value={Math.abs(useModifyDay)}
                    min={0}
                  />
                </Fragment>
              ) : (
                <SpanContainer>
                  <SSpan>{useModifyDay}</SSpan>
                </SpanContainer>
              )}

              <span>일</span>
            </FormRow>
            <FormRow title="잔여일">
              <SSpan>{`8.0`}</SSpan>
            </FormRow>
          </Section>
        </ContentContainer>
        <LastSection>
          <FormRow title="(*)변경사유">
            {isAdmin ? (
              <StyleInput
                {...register("modifyReason")}
                placeholder="(*)변경사유"
                minWidth="600px"
                maxWidth=""
              />
            ) : (
              <span>{modifyReason}</span>
            )}
          </FormRow>
        </LastSection>
        <ButtonSection>
          {isUpdateButton && (
            <Button customMinWidth="50px" disabled={!workInformation}>
              저장
            </Button>
          )}
          <Button
            name="변경이력보기"
            value="변경이력보기"
            backgroundColor={colors.darkBlue}
            hoverBackgroundColor={colors.veryDarkBlue}
            onClick={() => {
              handleOpenDialog(true);
            }}
            disabled={!workInformation}
          >
            변경이력보기
          </Button>
          {isOpen && (
            <AsonicDialog
              title={"연차 변경이력 조회"}
              handleClose={(value: boolean) => {
                handleOpenDialog(value);
              }}
              width="1200px"
              minWidth="1200px"
              height="500px"
              minHeight="500px"
            >
              <UserInformationHistory
                applyYear={applyYear}
                employeeId={employeeId}
              />
            </AsonicDialog>
          )}
        </ButtonSection>
      </DivSection>
      <ToastMessage
        isOpen={isToastMessageOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
};

export default WorkInformationManagement;
