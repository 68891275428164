import styled from "styled-components";
import * as ReactTable from "react-table";
import { useContext, useMemo, useCallback } from "react";
import { ICompactRow } from "../../../compact-table/compact-table-render-row";
import {
  Approval_User_Type,
  ListOfApprovalUser
} from "../../../../generated/graphql";
import { useSelector } from "react-redux";
import CompactTable from "../../../compact-table/compact-table";
import ApprovalLineContext from "../context/approval-line-context";
import { Reducers } from "../../../../../types/reducers";
import CheckBoxUi from "../../../globalComponents/CheckBoxUi";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage from "../../../toast-message/toast-message";
import { Cell } from "../../../../../types/@react-table/react-table/react-table";

export interface IEmployeeInformation extends Partial<ListOfApprovalUser> {
  employeeId: string;
  name: string;
  userPosition: string;
  department: string;
  approvalType: Approval_User_Type;
  checked?: boolean;
}
interface IProps {
  listOfUser: IEmployeeInformation[];
  handleCheckedUser: (user: string) => void;
  listOfCheckedUser: string[];
  handleAllCheckedUser: (users: string[]) => void;
}

const COLUMN_NAMES = {
  checked: "선택된 사용자",
  name: "사용자정보"
} as const;

type TYPE_OF_COLUMN = keyof typeof COLUMN_NAMES;

const Container = styled.div`
  display: flex;
  flex: 3;
  gap: 10px;
  padding-top: 32px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 3;
  border-left: 1px solid ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  border-right: 1px solid ${props => props.theme.colors.grey};
`;

function ListOfSelectedUsersForForm({
  listOfUser,
  handleCheckedUser,
  listOfCheckedUser,
  handleAllCheckedUser
}: IProps) {
  const store = useContext(ApprovalLineContext);
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const renderCellCheckBox = useCallback(
    (
      cell: Cell<IEmployeeInformation>,
      listOfCheckedUser: string[],
      handleCheckedUser: (user: string) => void
    ) => {
      return (
        <CheckBoxUi
          checked={
            !!listOfCheckedUser.find(
              item => item === cell.row.original.employeeId
            )
          }
          htmlFor={cell.value}
          onChange={() => {
            handleCheckedUser(cell.row.original.employeeId);
          }}
          name={cell.value}
        />
      );
    },
    [listOfCheckedUser, handleCheckedUser]
  );

  const renderHeaderCheckBox = useCallback(
    (
      listOfCheckedUser: string[],
      handleAllCheckedUser: (users: string[]) => void,
      item: TYPE_OF_COLUMN
    ) => {
      return (
        <CheckBoxUi
          checked={
            listOfUser.length > 0 &&
            listOfUser.length === listOfCheckedUser.length
          }
          htmlFor={COLUMN_NAMES[item]}
          onChange={() => {
            if (
              listOfUser.length > 0 &&
              listOfUser.length === listOfCheckedUser.length
            ) {
              handleAllCheckedUser([]);
              return;
            }

            const newData: string[] = listOfUser.map(
              (item: IEmployeeInformation) => item.employeeId
            );
            handleAllCheckedUser(newData);
          }}
        />
      );
    },
    [listOfUser, listOfCheckedUser, handleAllCheckedUser]
  );

  const columns: ReactTable.Column<IEmployeeInformation>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_NAMES);
    const newListOfColumn: ReactTable.Column<IEmployeeInformation>[] =
      listOfColumn.map(item => {
        let width = 300;
        if (item === "checked") {
          width = 50;
        }
        return {
          Header() {
            if (item === "checked") {
              return renderHeaderCheckBox(
                listOfCheckedUser,
                handleAllCheckedUser,
                item as TYPE_OF_COLUMN
              );
            }
            return COLUMN_NAMES[item as TYPE_OF_COLUMN];
          },
          accessor: item as TYPE_OF_COLUMN,
          Cell(cell: Cell<IEmployeeInformation>) {
            if (item === "checked") {
              return renderCellCheckBox(
                cell,
                listOfCheckedUser,
                handleCheckedUser
              );
            }
            return `${cell.row.original[item as TYPE_OF_COLUMN]} / ${
              cell.row.original.userPosition
            } / ${cell.row.original.department} `;
          },
          width
        };
      });
    return newListOfColumn;
  }, [listOfCheckedUser, handleCheckedUser, handleAllCheckedUser]);

  const userData: IEmployeeInformation[] = useMemo(() => {
    return listOfUser.map(item => {
      return {
        employeeId: item.employeeId,
        approvalType: item.approvalType,
        name: item.name,
        department: item.department,
        userPosition: item?.userPosition ?? ""
      };
    });
  }, [listOfUser]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<any>(
    {
      columns,
      data: userData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ICompactRow<IEmployeeInformation> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return;
    }, [selectedFlatRows]);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType
  } = useOpenToastMessage();

  return (
    <Container>
      <Content>
        <TableContainer>
          <CompactTable
            title="결재 양식 공유 사용자"
            isLoading={false}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            isTitleBar={false}
          />
        </TableContainer>
      </Content>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ListOfSelectedUsersForForm;
