import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MobileRequestView from "../../../moComponents/main-view/MobileRequestView";
import { requestActionCreator } from "../../../redux/modules/main-view/request/requestReducer";
import { Reducers } from "../../../../types/reducers";

const mapStateToProps = (state: Reducers) => ({
  requestReducer: state.requestReducer,
  categorySelectionReducer: state.categorySelectionReducer,
  signInReducer: state.signInReducer
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      ...requestActionCreator
    },
    dispatch
  )
});

const MobileRequestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileRequestView);

export default MobileRequestContainer;
