import * as React from "react";
import masterWorkManagementPageApi from "../../../api/master-work-management-page-api";

type Store = {
  isRenewal: boolean;
};
type Props = {
  isTreeData: boolean;
};

const useStore = <P extends Partial<Props>>({ isTreeData }: P) => {
  const isRequest = React.useRef<boolean>(false);
  const isMount = React.useRef<boolean>(false);
  const [store, setStore] = React.useState<Store>({ isRenewal: false });
  const handleStore = React.useCallback((value: boolean) => {
    if (isMount.current) {
      setStore({ isRenewal: value });
    }
  }, []);

  const requestRenewal = React.useCallback(async () => {
    try {
      const { status } = await masterWorkManagementPageApi.renewalInformation(
        {}
      );
      const success = 200;

      if (status === success) {
        handleStore(true);
      }
    } catch (error) {
      handleStore(false);
    }
  }, [handleStore]);

  React.useEffect(() => {
    if (!isMount.current && !isRequest.current && isTreeData) {
      requestRenewal();
      isMount.current = true;
      isRequest.current = true;
    }

    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [requestRenewal, isTreeData]);

  return { store, handleStore };
};

export default useStore;
