import styled from "styled-components";

const BORDER_COLOR = "rgba(0, 0, 0, 0.2)";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${BORDER_COLOR};
  background-color: white;
  border-radius: 2px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${BORDER_COLOR};
  label {
    font-size: 14px;
  }
`;

export const TitleBar = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
`;

export const RankingTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #0079d6;
  margin-left: 4px;
`;

export const SubTitle = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 85%;
`;

export const Select = styled.select`
  margin-left: 4px;
`;

export const Option = styled.option``;
