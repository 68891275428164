import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ApprovalTemplateEntity } from "../../../generated/graphql";
import routes from "../../../routes";
import EChannel from "../../../Utils/EChannel/e-channel";
import Button from "../../globalComponents/Button";
import { ApprovalType, IApprovalDetailMessage } from "../approval-detail-popup";
import ApprovalFormDetailInfo from "./approval-form-detail-info";
import ApprovalInfoList from "./approval-info-list";
import DrawApprovalFormList, {
  IApprovalLineFormTree
} from "./draw-approval-form-list";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const PopupTitleBar = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0px 10px;
  font-weight: bold;
  font-size: 15px;
  button {
    min-width: 50px;
  }
  background-color: ${props => props.theme.colors.grey};
`;

const Content = styled.div`
  display: flex;
  flex: 15;
  padding: 10px;
  gap: 10px;
`;

const List = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  padding: 10px;
`;

const InfoSection = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

const InfoList = styled.div`
  display: flex;
  flex: 1;
`;

const DetailInfo = styled.div`
  display: flex;
  flex: 1;
  border-top: 1px solid ${props => props.theme.colors.darkGrey};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  padding: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

function SelectApprovalFormPopup() {
  const [selectedForm, setSelectedForm] = useState<IApprovalLineFormTree>();
  const [selectedTemplate, setSelectedTemplate] = useState<
    ApprovalTemplateEntity | undefined
  >();

  const handleSelectedForm = useCallback((form: IApprovalLineFormTree) => {
    setSelectedForm(form);
  }, []);

  const handleSelectedTemplate = useCallback(
    (template: ApprovalTemplateEntity) => {
      setSelectedTemplate(template);
    },
    [setSelectedTemplate]
  );

  const tree = useMemo(() => {
    return [{ id: 0, formName: "기본 양식함" }];
  }, []);

  const bc = useMemo(() => {
    if (selectedTemplate) {
      return new BroadcastChannel(EChannel.APPROVAL);
    }
    return;
  }, [selectedTemplate]);

  useEffect(() => {
    if (bc && selectedTemplate) {
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (message.type === ApprovalType.APPROVAL_REQUEST_POPUP) {
          const newMessage: IApprovalDetailMessage = {
            type: ApprovalType.APPROVAL_REQUEST_POPUP,
            isRequest: true,
            template: selectedTemplate
          };
          bc.postMessage(newMessage);
          bc.close();
          setSelectedTemplate(undefined);
        }
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, selectedTemplate]);

  return (
    <Container>
      <PopupTitleBar>결재 양식 선택</PopupTitleBar>
      <Content>
        <List>
          <DrawApprovalFormList
            tree={tree}
            selectedForm={selectedForm}
            handleSelectedForm={handleSelectedForm}
          />
        </List>
        <InfoSection>
          <InfoList>
            <ApprovalInfoList
              selectedTemplate={selectedTemplate}
              selectedForm={selectedForm}
              handleSelectedTemplate={handleSelectedTemplate}
            />
          </InfoList>
          <DetailInfo>
            <ApprovalFormDetailInfo selectedTemplate={selectedTemplate} />
          </DetailInfo>
        </InfoSection>
      </Content>
      <ButtonContainer>
        <Button
          onClick={() => {
            if (selectedTemplate) {
              window.open(
                routes.pageRoutes.approvalRequestPopup,
                "",
                "width=800, height=880, scrollbars=yes"
              );
            }
          }}
        >
          선택
        </Button>
        <Button
          onClick={() => {
            window.close();
          }}
        >
          닫기
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default SelectApprovalFormPopup;
