import { Fragment } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../globalComponents/IconButton";
import AsonicCalendarList from "../asonic-calendar-list/asonic-calendar-list";
import useCheckHttps from "../../../hooks/calendar-hooks/use-check-https";
import { SerializeItem } from "../../../hooks/link-google-hooks/use-google-calendar";
import moment from "moment";
import CalendarHeaderInput from "../calendar-header-input";
registerLocale("ko", ko);
enum CalendarDirection {
  PRE = "PRE",
  NEXT = "NEXT"
}

interface IProps {
  currentMoment: string;
  handleCurrentMoment: (date: Date) => void;
  handleMoveMonth: (direction: CalendarDirection) => () => void;
  authorize: () => void;
  handleSignoutClick: () => void;
  isLogin: boolean;
  googleCalendarlist: SerializeItem[];
  handleCalendarList: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const skyBlue = "#2196f3";
const onClickColor = "#0652DD";

const CalendarListContainer = styled.div`
  position: relative;
  display: flex;
`;

const MonthTitleBar = styled.div`
  display: flex;
  justify-content: center;
  min-width: max-content;
`;

const Icon = styled(FontAwesomeIcon)``;

const ImgIcon = styled.div`
  width: 32px;
  height: 35px;
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg");
  border: 1px solid black;
  cursor: pointer;
  :hover {
    background-color: #74b9ff;
  }
  :active {
    transform: scale(0.96);
  }
`;

const ArrowButton = styled.button`
  display: flex;
  background-color: white;
  outline: none;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #b2bec3;
  padding: 10px;
  border-radius: 2px;
  :hover {
    cursor: pointer;
    border-color: ${skyBlue};
    ${Icon} {
      color: ${skyBlue};
    }
  }
  :active {
    border-color: ${onClickColor};
    ${Icon} {
      color: ${onClickColor};
    }
  }
`;

const AsonicCalendarTitleBar = <P extends IProps>({
  currentMoment,
  handleCurrentMoment,
  handleMoveMonth,
  isLogin,
  handleSignoutClick,
  authorize,
  googleCalendarlist,
  handleCalendarList
}: P) => {
  const { isGoogle } = useCheckHttps();
  return (
    <Fragment>
      <CalendarListContainer>
        {isGoogle && (
          <Fragment>
            {isLogin ? (
              <ImgIcon onClick={handleSignoutClick} />
            ) : (
              <IconButton
                icon={faGoogle}
                size="lg"
                isBorder={true}
                onClick={authorize}
                color={"#2f3542"}
                hoverColor={"#a4b0be"}
              />
            )}
            <AsonicCalendarList
              items={googleCalendarlist}
              handleOnChange={handleCalendarList}
            />
          </Fragment>
        )}
      </CalendarListContainer>
      <MonthTitleBar>
        <ArrowButton onClick={handleMoveMonth(CalendarDirection.PRE)}>
          <Icon icon={faChevronLeft} />
        </ArrowButton>
        <DatePicker
          todayButton="오늘로 가기"
          customInput={<CalendarHeaderInput />}
          selected={moment(currentMoment).toDate()}
          onChange={handleCurrentMoment}
          dateFormat="yyyy년 MM월"
          showMonthYearPicker
          locale="ko"
        />
        <ArrowButton onClick={handleMoveMonth(CalendarDirection.NEXT)}>
          <Icon icon={faChevronRight} />
        </ArrowButton>
      </MonthTitleBar>
    </Fragment>
  );
};

export default AsonicCalendarTitleBar;
