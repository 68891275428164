import styled from "styled-components";

interface IProps {
  open?: boolean;
}

export const HomeTitleTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.2s, height 0.2s, opacity 0.2s, visibility 0.2s ease-in-out;
  visibility: ${(props: IProps) => (props.open ? "visible" : "hidden")};
  opacity: ${(props: IProps) => (props.open ? "1" : "0")};
  flex: ${(props: IProps) => (props.open ? "1" : "0")};
  gap: 10px;
`;

export const HomeMainTitleText = styled.span`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;

export const HomeSubTitleText = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;

export const HomeTitleContainer = styled.div`
  transition: width 0.2s ease-in-out;
  flex-shrink: 0;
  flex: 1;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    ${HomeTitleTextContainer} {
      color: ${props => props.theme.colors.lightGreen};
    }
  }
  min-height: 40px;
  max-height: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  border-right: 1px solid ${props => props.theme.colors.frameGrey};
`;
