import * as React from "react";
import SignInBox from "../SignInBox/SignInBox";
import { signIn, isAdmin } from "../../../Utils/globalAxiosCall";
import tokenMaker, { handleDecodedToken } from "../../../Utils/tokenMaker";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import { MessageTypes } from "../../toast-message/toast-message";

export interface IProps {
  color?: string;
  idLabel: string;
  buttonLabel: string;
  handleAfterLogin: <T>(data: T) => void;
  isAdminCheck?: boolean;
}

export const ID = "ID",
  PASSWORD = "PASSWORD";

type InputType = "ID" | "PASSWORD";

type InputOnChange = React.ChangeEvent<HTMLInputElement>;
type OnSubmit = React.FormEvent<HTMLFormElement>;

type HandleIdOrPassword = (type: InputType) => (event: InputOnChange) => void;

export interface ITotalProps extends IProps {
  userId: string;
  password: string;
  handleIdOrPassword: HandleIdOrPassword;
  onSignIn: (event: OnSubmit) => void;
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  message: string;
  toastMessageType: MessageTypes;
}

const SignInBoxContainer = (props: IProps) => {
  const { handleAfterLogin, isAdminCheck } = props;
  const [userId, setUserId] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const handleIdOrPassword = React.useCallback(
    (type: InputType) => (event: InputOnChange) => {
      switch (type) {
        case ID:
          setUserId(event.target.value);
          break;
        case PASSWORD:
          setPassword(event.target.value);
          break;
        default:
          const error = new Error("unhandled type");
          throw error;
      }
    },
    []
  );

  const onSignIn = React.useCallback(
    async (event: OnSubmit) => {
      event.preventDefault();
      const content = {
        username: userId,
        password
      };
      const token = tokenMaker(content);

      try {
        let data;
        if (isAdminCheck) {
          const { data } = await isAdmin({ token });
          handleAfterLogin(data[0]?.isAdmin);
        } else {
          const response = await signIn({ token });
          data = handleDecodedToken(response.data);
          handleAfterLogin(data);
        }
      } catch (error) {
        handleIsOpen(true);
        handleMessage("ID 혹은 암호가 맞지 않습니다.");
        handleToastMessageType(MessageTypes.WARNING);
        console.log(error);
      }
    },
    [
      isAdminCheck,
      userId,
      password,
      handleAfterLogin,
      handleIsOpen,
      handleMessage,
      handleToastMessageType
    ]
  );

  const newProps: ITotalProps = {
    userId,
    password,
    handleIdOrPassword,
    onSignIn,
    isOpen,
    handleIsOpen,
    message,
    toastMessageType,
    ...props
  };
  return <SignInBox {...newProps} />;
};

export default SignInBoxContainer;
