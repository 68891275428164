import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { EmployeeInformation, GroupEntity } from "../../../generated/graphql";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import ApprovalButtonController from "../approval-process/approval-button-controller";
import {
  PositionControl,
  TPositionControl
} from "../approval-process/position-controller";
import ApprovalReceiveAndReferenceList from "./approval-receive-and-reference-list";
import ListOfSelectedApprovalUserForReceiveAndReferenceForm, {
  IEmployeeInformationForReceiveAndReference,
  ReceiveAndReferenceType
} from "./list-of-selected-approval-user-for-receive-and-reference-form";

interface IProps {
  handleSetListOfUserForReceiveAndReference: (
    list: IEmployeeInformationForReceiveAndReference[]
  ) => void;
  initListForUser: IEmployeeInformationForReceiveAndReference[];
  initListForDepartment: IEmployeeInformationForReceiveAndReference[];
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
`;

const Content = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: 10px;
  height: 75%;
  min-height: 550px;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function ApprovalReceiveAndReferenceForm({
  handleSetListOfUserForReceiveAndReference,
  initListForUser,
  initListForDepartment
}: IProps) {
  const [selectedDepartment, setSelectedDepartment] = useState<GroupEntity>();
  const handleSelectedDepartment = useCallback((department: GroupEntity) => {
    setSelectedDepartment(department);
  }, []);
  const [selectedUser, setSelectedUser] =
    useState<ICompactRow<EmployeeInformation>>();
  // 체크박스에 추가된 사용자
  const [listOfAddedUser, setListOfAddedUser] = useState<string[]>([]);
  // 체크박스에 추가된 부서
  const [listOfAddedDepartment, setListOfAddedDepartment] = useState<string[]>(
    []
  );

  // 수신 및 참조 목록에 추가된 사용자 목록
  const [listOfUser, setListOfUser] =
    useState<IEmployeeInformationForReceiveAndReference[]>(initListForUser);
  // 수신 및 참조 목록에 추가된 부서 목록
  const [listOfDepartment, setListOfDepartment] = useState<
    IEmployeeInformationForReceiveAndReference[]
  >(initListForDepartment);

  const handleAllAddedDepartment = useCallback((users: string[]) => {
    setListOfAddedDepartment(list => {
      if (list.length === users.length) {
        return [];
      }
      return users;
    });
  }, []);

  const handleAddedDepartment = useCallback((department: string) => {
    setListOfAddedDepartment(list => {
      const isExcited = list.find(item => item === department);
      if (isExcited) {
        return list.filter(item => item !== department);
      }
      return [...list, department];
    });
  }, []);

  const handleChangeDepartmentInfoInListOfApprovalDepartment = useCallback(
    (payload: IEmployeeInformationForReceiveAndReference) => {
      setListOfDepartment(list => {
        const newList = [...list];
        const foundDepartmentIndex = newList.findIndex(
          item => item.departmentId === payload.departmentId
        );
        newList.splice(foundDepartmentIndex, 1, payload);
        return newList;
      });
    },
    []
  );

  const handleAddDepartmentInListOfApprovalDepartment = useCallback(
    (type: ReceiveAndReferenceType) => {
      if (selectedDepartment) {
        setListOfDepartment(list => {
          const newList = list.filter(row => {
            if (selectedDepartment.departmentId) {
              return (
                row.departmentId !== selectedDepartment.departmentId.toString()
              );
            }
            return false;
          });
          newList.push({
            type,
            departmentId: selectedDepartment.departmentId.toString(),
            department: selectedDepartment.fullGroupName
          });
          return newList;
        });
      }
    },
    [selectedDepartment]
  );

  const handleAllAddedUser = useCallback((users: string[]) => {
    setListOfAddedUser(list => {
      if (list.length === users.length) {
        return [];
      }
      return users;
    });
  }, []);

  const handleAddedUser = useCallback((user: string) => {
    setListOfAddedUser(list => {
      const isExcited = list.find(item => item === user);
      if (isExcited) {
        return list.filter(item => item !== user);
      }
      return [...list, user];
    });
  }, []);

  const handleAddUser = useCallback(
    (user?: ICompactRow<EmployeeInformation>) => {
      setSelectedUser(user);
    },
    []
  );

  const handleAddUserInListOfApprovalUser = useCallback(
    (type: ReceiveAndReferenceType) => {
      if (selectedUser) {
        setListOfUser(list => {
          const newList = list.filter(row => {
            return row.employeeId !== selectedUser.original.employeeId;
          });
          newList.push({
            type,
            name: selectedUser.original.name,
            departmentId: selectedUser.original.departmentId,
            department: selectedUser.original.departName,
            userPosition: selectedUser.original.userPosition ?? "",
            employeeId: selectedUser.original.employeeId
          });
          return newList;
        });
      }
    },
    [selectedUser]
  );

  const handleDeleteUserInListOfApprovalUser = useCallback(() => {
    if (listOfAddedUser) {
      setListOfUser(list => {
        const newList = list.filter(row => {
          const isExist = listOfAddedUser.find(item => item === row.employeeId);
          if (isExist) {
            return false;
          }
          return true;
        });
        return newList;
      });
      setListOfAddedUser([]);
    }
    if (listOfAddedDepartment) {
      setListOfDepartment(list => {
        const newList = list.filter(row => {
          const isExist = listOfAddedDepartment.find(
            item => item === row.departmentId
          );
          if (isExist) {
            return false;
          }
          return true;
        });
        return newList;
      });
      setListOfAddedDepartment([]);
    }
  }, [listOfAddedUser, listOfAddedDepartment]);

  const handleChangeUserInfoInListOfApprovalUser = useCallback(
    (payload: IEmployeeInformationForReceiveAndReference) => {
      setListOfUser(list => {
        const newList = [...list];
        const foundUserIndex = newList.findIndex(
          item => item.employeeId === payload.employeeId
        );
        newList.splice(foundUserIndex, 1, payload);
        return newList;
      });
    },
    []
  );

  const handleChangeTheOrderUser = useCallback(
    (type: TPositionControl) => {
      if (listOfAddedUser.length > 0) {
        setListOfUser(originList => {
          let index: number = -1;
          let lastItem = "";
          const selectedListOfUser = originList.filter(
            (user, originalIndex) => {
              const foundUser = listOfAddedUser.find(
                checkedUser => checkedUser === user.employeeId
              );
              if (foundUser) {
                if (type === PositionControl.TOP && index === -1) {
                  index = originalIndex;
                } else if (type === PositionControl.BOTTOM) {
                  index = originalIndex;
                }
                return true;
              }
              if (
                index !== -1 &&
                index === originalIndex - 1 &&
                user.employeeId
              ) {
                lastItem = user.employeeId;
              }
              return false;
            }
          );
          const newList = originList.filter(user => {
            const foundUser = listOfAddedUser.find(
              checkedUser => checkedUser === user.employeeId
            );
            if (foundUser) {
              return false;
            }
            return true;
          });

          if (type === PositionControl.TOP) {
            if (index !== 0) {
              index -= 1;
            }
            newList.splice(index, 0, ...selectedListOfUser);
          } else if (type === PositionControl.BOTTOM) {
            let findIndex =
              newList.findIndex(user => user.employeeId === lastItem) + 1;
            const lastIndex = 0;
            if (findIndex === lastIndex) {
              findIndex = originList.length - 1;
            }
            newList.splice(findIndex, 0, ...selectedListOfUser);
          }

          return newList;
        });
      }
    },
    [listOfAddedUser]
  );

  const handleChangeTheOrderDepartment = useCallback(
    (type: TPositionControl) => {
      if (listOfAddedDepartment.length > 0) {
        setListOfDepartment(originList => {
          let index: number = -1;
          let lastItem = "";
          const selectedListOfDepartment = originList.filter(
            (department, originalIndex) => {
              const foundDepartment = listOfAddedDepartment.find(
                checkedDepartment =>
                  checkedDepartment === department.departmentId
              );
              if (foundDepartment) {
                if (type === PositionControl.TOP && index === -1) {
                  index = originalIndex;
                } else if (type === PositionControl.BOTTOM) {
                  index = originalIndex;
                }
                return true;
              }
              if (
                index !== -1 &&
                index === originalIndex - 1 &&
                department.departmentId
              ) {
                lastItem = department.departmentId;
              }
              return false;
            }
          );
          const newList = originList.filter(department => {
            const foundDepartment = listOfAddedDepartment.find(
              checkedDepartment => checkedDepartment === department.departmentId
            );
            if (foundDepartment) {
              return false;
            }
            return true;
          });

          if (type === PositionControl.TOP) {
            if (index !== 0) {
              index -= 1;
            }
            newList.splice(index, 0, ...selectedListOfDepartment);
          } else if (type === PositionControl.BOTTOM) {
            let findIndex =
              newList.findIndex(
                department => department.departmentId === lastItem
              ) + 1;
            const lastIndex = 0;
            if (findIndex === lastIndex) {
              findIndex = originList.length - 1;
            }
            newList.splice(findIndex, 0, ...selectedListOfDepartment);
          }

          return newList;
        });
      }
    },
    [listOfAddedDepartment]
  );

  useEffect(() => {
    if (handleSetListOfUserForReceiveAndReference) {
      handleSetListOfUserForReceiveAndReference([
        ...listOfUser,
        ...listOfDepartment
      ]);
    }
  }, [listOfUser, listOfDepartment]);

  return (
    <Container>
      <Content>
        <ApprovalReceiveAndReferenceList
          selectedDepartment={selectedDepartment?.departmentId}
          handleSelectedDepartment={handleSelectedDepartment}
          handleAddUser={handleAddUser}
        />
        <ButtonContainer>
          <ApprovalButtonController
            firstControl={() => {
              if (!selectedUser) {
                handleAddDepartmentInListOfApprovalDepartment(
                  ReceiveAndReferenceType.RECEIVE
                );
              } else {
                handleAddUserInListOfApprovalUser(
                  ReceiveAndReferenceType.RECEIVE
                );
              }
            }}
            secondControl={() => {
              if (!selectedUser) {
                handleAddDepartmentInListOfApprovalDepartment(
                  ReceiveAndReferenceType.REFERENCE
                );
              } else {
                handleAddUserInListOfApprovalUser(
                  ReceiveAndReferenceType.REFERENCE
                );
              }
            }}
            list={[...listOfAddedUser, ...listOfAddedDepartment]}
            isSelectedUser={!!selectedDepartment || !!selectedUser}
            deleteControl={handleDeleteUserInListOfApprovalUser}
            firstButtonName="수신추가"
            secondButtonName="참조추가"
          />
        </ButtonContainer>
        <ListOfSelectedApprovalUserForReceiveAndReferenceForm
          listOfUser={listOfUser}
          listOfAddedUser={listOfAddedUser}
          handleChangeUserInfoInListOfApprovalUser={
            handleChangeUserInfoInListOfApprovalUser
          }
          handleAllAddedUser={handleAllAddedUser}
          handleAddedUser={handleAddedUser}
          listOfDepartment={listOfDepartment}
          listOfAddedDepartment={listOfAddedDepartment}
          handleAllAddedDepartment={handleAllAddedDepartment}
          handleAddedDepartment={handleAddedDepartment}
          handleChangeDepartmentInfoInListOfApprovalDepartment={
            handleChangeDepartmentInfoInListOfApprovalDepartment
          }
          handleChangeTheOrderUser={handleChangeTheOrderUser}
          handleChangeTheOrderDepartment={handleChangeTheOrderDepartment}
        />
      </Content>
    </Container>
  );
}

export default ApprovalReceiveAndReferenceForm;
