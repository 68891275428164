import styled from "styled-components";

export const ContentsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Container = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  cursor: ${props => {
    if (props.isTreeWindowClick) {
      return "col-resize";
    } else if (props.isUserInfoWindowClicked) {
      return "row-resize";
    }
  }};
`;
