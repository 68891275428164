import * as React from "react";

type OnChange = React.ChangeEvent<HTMLSelectElement>;

const useNativeSelect = (initNativeSelect: any) => {
  const [selectedItem, setSelectedItem] = React.useState<any>(initNativeSelect);

  const handleSelectedItem = React.useCallback((event: OnChange) => {
    const {
      target: { value }
    } = event;

    setSelectedItem(value);
  }, []);

  return { selectedItem, handleSelectedItem, setSelectedItem };
};

export default useNativeSelect;
