import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../types/reducers";
import basicImg from "../../images/basicimage.jpg";
import linkifyHtml from "linkify-html";
import parse from "html-react-parser";

interface IStyle {
  isMe: boolean;
}
interface IUserForChat {
  name: string;
  employeeId: string;
  photo?: string;
}

interface PhotoProps {
  photo?: string | null;
}

export enum MESSAGE_TYPE {
  USER,
  SYSTEM,
  DATE
}

export interface IDataForChat extends IUserForChat {
  message: string;
  dateTime: string;
  messageType: MESSAGE_TYPE;
}

interface IRow {
  data: IDataForChat[];
  index: number;
  style: any;
}

const Container = styled.article`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Talk = styled.span<IStyle>`
  display: flex;
  background-color: ${props =>
    props.isMe ? props.theme.colors.lemon : props.theme.colors.white};
  border-radius: 14px;
  border-bottom-left-radius: ${props => (props.isMe ? "14px" : "0px")};
  border-bottom-right-radius: ${props => (props.isMe ? "0px" : "14px")};
  padding: 10px;
  white-space: pre-line;
`;

const Name = styled.div`
  display: flex;
  color: ${props => props.theme.colors.darkGrey};
`;

const ContentContainer = styled.div<IStyle>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${props => (props.isMe ? "flex-end" : "flex-start")};
`;

const Content = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 10px;
  gap: 2px;
`;

const Image = styled.div<PhotoProps>`
  display: flex;
  margin-left: 8px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-size: cover;
  background-image: ${props =>
    props.photo
      ? `url(data:image/png+jpg;base64,${props.photo})`
      : `url(${basicImg})`};
`;

const Blank = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
`;

const TalkContainer = styled.div<IStyle>`
  display: flex;
  gap: 8px;
  flex-flow: ${props => (props.isMe ? "row-reverse" : "row")};
`;

const Time = styled.time`
  display: flex;
  align-items: flex-end;
  color: ${props => props.theme.colors.darkGrey};
`;

const Date = styled.time`
  background-color: ${props => props.theme.colors.veryYellow};
  border-radius: 14px;
  padding: 5px;
  color: ${props => props.theme.colors.black};
`;

const DateContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 2px 0px 2px 0px;
`;

const SystemMessageContainer = styled(DateContainer)``;

const SystemMessage = styled.div`
  background-color: ${props => props.theme.colors.veryYellow};
  border-radius: 14px;
  padding: 5px;
  color: ${props => props.theme.colors.black};
`;

const PreText = styled.span`
  margin: 0;
  white-space: pre-wrap;
`;

function Message(props: IRow) {
  const { data, index, style } = props;
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);
  const { name, employeeId, message, dateTime, messageType, photo } =
    data[index];
  const isMessageOwner = useMemo(() => {
    let preEmployeeId: string = "";
    let postEmployeeId: string = "";
    const preData = data[index - 1];
    const postData = data[index + 1];
    if (preData) {
      preEmployeeId = preData.employeeId;
    }
    if (postData) {
      postEmployeeId = postData.employeeId;
    }
    if (employeeId === preEmployeeId && dateTime === preData.dateTime) {
      if (employeeId === postEmployeeId && dateTime === postData.dateTime) {
        return true;
      }
    }
    if (employeeId === postEmployeeId && dateTime === postData.dateTime) {
      return true;
    }
    return false;
  }, [data, index, employeeId, dateTime]);

  const newMessage = useMemo(() => {
    const options = {
      defaultProtocol: "https"
    };
    return message
      .split(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g
      )
      .map((item, index) => {
        const parsedString = parse(linkifyHtml(message, options));
        if (
          typeof parsedString !== "string" &&
          !Array.isArray(parsedString) &&
          parsedString?.type === "a"
        ) {
          return parsedString;
        }
        if (Array.isArray(parsedString) && parsedString[index]?.type === "a") {
          return parsedString[index];
        }
        return <PreText>{item}</PreText>;
      });
  }, [message]);

  return (
    <Container style={style}>
      {messageType === MESSAGE_TYPE.DATE && (
        <DateContainer>
          <Date dateTime={moment(dateTime).format("YYYY-MM-DD")}>
            {moment(dateTime).format("YYYY년 MMMM DD")}
          </Date>
        </DateContainer>
      )}
      {messageType === MESSAGE_TYPE.SYSTEM && (
        <SystemMessageContainer>
          <SystemMessage>{newMessage}</SystemMessage>
        </SystemMessageContainer>
      )}
      {messageType === MESSAGE_TYPE.USER && (
        <ContentContainer isMe={employee_id === employeeId}>
          {employee_id !== employeeId && !isMessageOwner && (
            <Image photo={photo} />
          )}
          {employee_id !== employeeId && isMessageOwner && <Blank />}
          <Content>
            <TalkContainer isMe={employee_id === employeeId}>
              <Talk isMe={employee_id === employeeId}>{newMessage}</Talk>
              {!isMessageOwner && (
                <Time dateTime={dateTime}>
                  {moment(dateTime).format("HH:mm")}
                </Time>
              )}
            </TalkContainer>
            {employee_id !== employeeId && !isMessageOwner && (
              <Name>{name}</Name>
            )}
          </Content>
        </ContentContainer>
      )}
    </Container>
  );
}

export default Message;
