import axios from "axios";
import { WEB_SERVER_ADDR } from "../../../constValues";
import Auth from "../../../Auth/Auth";

export async function getWorkingHours(workingHoursArgs) {
  let restUri = `${WEB_SERVER_ADDR}/api/mainview/workinghours`;

  try {
    const response = await axios.post(
      restUri,
      {
        employeeId: workingHoursArgs.employeeId,
        startDate: workingHoursArgs.startDate,
        endDate: workingHoursArgs.endDate
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    );
    if (response.data.error) {
      return [];
    }

    return response.data;
  } catch (err) {
    console.warn(err);
  }
  return [];
}

export async function getWorkingInfo(workingInfoArgs) {
  let restUri = `${WEB_SERVER_ADDR}/api/mainview/workinginfo`;

  try {
    const response = await axios.post(
      restUri,
      {
        employeeId: workingInfoArgs.employeeId
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    );
    if (response.data.error) {
      return [];
    }

    return response.data;
  } catch (err) {
    console.warn(err);
  }
  return [];
}
