import api from "../../api";

const PERSONAL = `${api.api}/personal-dashboard`;
const CURRENT_TIME_BOX = `${PERSONAL}/current-time-box`;
const MY_WORK_STATUS_BOX = `${PERSONAL}/my-work-status-box`;
const TODAY_WORKING_INFORMATION_BOX = `${PERSONAL}/today-working-information-box`;
const THIS_WEEK_WORKING_INFORMATION_BOX = `${PERSONAL}/this-week-working-information-box`;
const MONTHLY_ABSENTEEISM_TARDINESS = `${PERSONAL}/monthly-absenteeism-tardiness`;
const VCATION_STATUS = `${PERSONAL}/vacation-status`;
const MONTHLY_WORKING_STATUS = `${PERSONAL}/monthly-working-status`;

const personalApi = {
  currentTimeBox: CURRENT_TIME_BOX,
  myWorkStatusBox: MY_WORK_STATUS_BOX,
  todayWorkingInformationBox: TODAY_WORKING_INFORMATION_BOX,
  thisWeekWorkingInformationBox: THIS_WEEK_WORKING_INFORMATION_BOX,
  monthlyAbsenteeismTardiness: MONTHLY_ABSENTEEISM_TARDINESS,
  vacationStatus: VCATION_STATUS,
  monthlyWorkingStatus: MONTHLY_WORKING_STATUS
};

export default personalApi;
