import styled from "styled-components";

const PADDING = "10px";
const GAP = "10px";
const MIN_HEIGHT = "550px";
const MAX_WIDTH = "50%";
const MIN_WIDTH = "983px";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${PADDING};
`;

export const Content = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: ${PADDING};
  height: 75%;
  min-height: ${MIN_HEIGHT};
  gap: ${GAP};
  flex: 1;
  max-width: ${MAX_WIDTH};
  min-width: ${MIN_WIDTH};

  @media (max-width: 1024px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
