import HelpContainer from "../../../shared/help_container/help_container";
import InfoIcon from "../../../shared/info-icon/info-icon";

function AAhelp() {
  return (
    <InfoIcon
      minHeight="420px"
      height="420px"
      minWidth="400px"
      title="자율 출근제"
    >
      <HelpContainer>
        <pre>{`주 5일, 하루8시간, 주40시간 근무를 지키며, 원하는 시간에 출근하는 
제도 입니다. 퇴근 시간은 출근한 시간에 따라 일 근무 시간을 합산하여 
자동으로 지정됩니다. 출근 가능 시간의 범위를 지정할수 있으며, 
출근 가능 시간 이후 출근한 경우 지각으로 분류 됩니다.`}</pre>
        <ul>
          <li>{`근무 요일 설정`}</li>
          <pre>{`근무 가능한 요일을 설정합니다.`}</pre>
          <li>{`출근 가능 시간`}</li>
          <pre>{`출근 가능 시간을 설정합니다. 설정된 시간에만 정시 출근 체크가 가능
하며 설정된 출근 가능 시간 이후 출근 하는 경우 지각으로 분류됩니다. `}</pre>
          <li>{`퇴근 시간 범위`}</li>
          <pre>{`설정 하신 출근 가능 시간에 일 근무시간을 합산하여 퇴근 시간 범위를 
자동 연산하여 표시 됩니다.`}</pre>
          <li>{`지정 휴게시간`}</li>
          <pre>{`지정된 휴게시간을 사용 하는 경우 모든 근무자가 동일한 휴게 시간을 
사용해야 하며, 그에 따라 지정 휴게 시간은 출근 가능 시간 이후로 설
정되어야 합니다. 
지정된 휴게시간을 미사용 하는 경우 근무자가 근무시간 중 원하는 시
간에 의무 휴게시간을 사용할 수 있게 합니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}

export default AAhelp;
