import HelpContainer from "../../shared/help_container/help_container";
import InfoIcon from "../../shared/info-icon/info-icon";

function TDTypeHelp() {
  return (
    <InfoIcon
      minHeight="390px"
      height="390px"
      minWidth="400px"
      title="시차출퇴근제 - 기본 근무시간 설정"
    >
      <HelpContainer>
        <span>{`시차 출퇴근제의 근무시간을 설정합니다.`}</span>
        <ul>
          <li>{`기본 근무시간`}</li>
          <span>{`기본 적용되는 근무시간을 설정합니다.`}</span>
          <li>{`출근시간 및 퇴근 시간 설정`}</li>
          <pre>{`노동법상 일 최대 근무시간인 8시간을 초과하여 설정 하실수 없으며,
출/퇴근 시간내에 포함된 휴게시간은 근무시간에 포함되지 않습니다. `}</pre>
          <li>{`근무 주차 설정`}</li>
          <pre>{`근무 주차를 설정합니다. 직접 선택을 하시는 경우 매월 선택 된 주차에
만 근무시간이 반영됩니다.`}</pre>
          <li>{`근무 요일 설정`}</li>
          <pre>{`근무 요일을 설정합니다. 직접 선택을 하시는 경우 매주 선택된 요일에
만 근무시간이 반영됩니다.`}</pre>
          <li>{`근무 시간 합계 및 의무 휴게 시간`}</li>
          <pre>{`근무시간 합계는 출근 시간부터 퇴근 시간 까지의 시간 합을 표시하며
의무 휴게 시간은 근무시간 합계시간에 따른 의무 휴게시간을 표시합니
다. 의무 휴게시간이 근무시간내에 포함되는 경우 해당 시간은 일 근무
시간에서 제외 됩니다.`}</pre>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default TDTypeHelp;
