import * as React from "react";
import Auth from "../../Auth/Auth";
import routes from "../../routes";
import { MAINVIEW_PATH } from "../../constValues";
import { useNavigate } from "react-router-dom";

const ON_DASH_BOARD = 256;
const ACTIVE = 1;

const useSignHook = (setUser, getCategories, isMaster) => {
  const navigate = useNavigate();
  const handleHomePage = React.useCallback(
    ({ userInfo: { menuList } }) => {
      const activationDashboard = Boolean(
        menuList[0].web_etc_flag & ON_DASH_BOARD
      );
      const activationWorkingManagementInformation =
        menuList[0].web_otmng_flag === ACTIVE ? true : false;
      let homePage = routes.pageRoutes.home;
      if (!isMaster) {
        homePage = routes.pageRoutes.personal;
      }
      if (!activationDashboard) {
        homePage = routes.pageRoutes.workingManagementInformation;
        if (!activationWorkingManagementInformation) {
          homePage = routes.pageRoutes.settings;
        }
      }
      return homePage;
    },
    [isMaster]
  );

  const onSignIn = React.useCallback(
    data => {
      if (data) {
        const homePage = handleHomePage(data);
        localStorage.setItem("homePage", homePage);
        Auth.authenticateUser(data.token);
        setUser(data.userInfo);
        getCategories();
        navigate(homePage);
        // if (window.screen.width <= 1024) {
        //   navigate(`/m${MAINVIEW_PATH}home`);
        // } else {
        //   navigate(homePage);
        // }
      }
    },
    [navigate, setUser, getCategories, handleHomePage]
  );

  return {
    onSignIn,
    handleHomePage
  };
};
export default useSignHook;
