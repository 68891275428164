/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * 알람 ON/OFF 상태값
 */
export enum AlarmStatus {
  OFF = "OFF",
  ON = "ON",
}

/**
 * 출퇴근 인정 여부 enum 타입
 */
export enum ApprovalFlag {
  CORRECTED_VERSION = "CORRECTED_VERSION",
  NO_RECOGNITION = "NO_RECOGNITION",
  ORIGIN_RECOGNITION = "ORIGIN_RECOGNITION",
}

/**
 * 결재 시작/종료 시간 구분
 */
export enum ApprovalTimeType {
  END = "END",
  START = "START",
}

/**
 * 출퇴근 지역 반경설정 ON/OFF 타입
 */
export enum CommuteAreaUseType {
  OFF = "OFF",
  ON = "ON",
}

/**
 * 출퇴근 enum 타입
 */
export enum CommuteType {
  ATTENDANCE = "ATTENDANCE",
  LEAVE = "LEAVE",
}

/**
 * 출퇴근 장치 타입
 */
export enum DEVICE_TYPE {
  APP = "APP",
  DESKTOP = "DESKTOP",
}

/**
 * 값의 참과 거짓 구분 값
 */
export enum IS_TRUTHY_FALSY {
  FALSY = "FALSY",
  TRUTHY = "TRUTHY",
}

/**
 * 정책 사용 여부 타입
 */
export enum IsControl {
  DISABLE = "DISABLE",
  ENABLE = "ENABLE",
}

/**
 * 정책 사용 여부 타입
 */
export enum IsEnable {
  DISABLE = "DISABLE",
  ENABLE = "ENABLE",
}

/**
 * 정책에외 포함 및 에외 타입
 */
export enum IsException {
  EXCEPTION = "EXCEPTION",
  INCLUDING = "INCLUDING",
}

/**
 * On Off 타입
 */
export enum IsONOff {
  OFF = "OFF",
  ON = "ON",
}

/**
 * 알림 사용 여부
 */
export enum IsUsingNotification {
  FALSE = "FALSE",
  TRUE = "TRUE",
}

/**
 * 처리 및 조회 검색 타입
 */
export enum ProcessingInquirySearchType {
  EMPLOYEE_ID = "EMPLOYEE_ID",
  NAME = "NAME",
}

/**
 * 결재 처리를 위한 값
 */
export enum REJECTED_OR_APPROVED_OR_OTHERS {
  APPROVED = "APPROVED",
  REJECT = "REJECT",
  REJECTED_BY_OTHERS = "REJECTED_BY_OTHERS",
}

/**
 * DashBoard 시간에 따른 검색을 위한 타입
 */
export enum SEARCH_DATE_TYPE_FOR_DASH_BOARD {
  LESS_THAN_34_HOURS = "LESS_THAN_34_HOURS",
  LESS_THAN_40_HOURS = "LESS_THAN_40_HOURS",
  LESS_THAN_6_HOURS = "LESS_THAN_6_HOURS",
  MORE_THAN_10_HOURS_LESS_THAN_12_HOURS = "MORE_THAN_10_HOURS_LESS_THAN_12_HOURS",
  MORE_THAN_12_HOURS = "MORE_THAN_12_HOURS",
  MORE_THAN_34_HOURS_LESS_THAN_36_HOURS = "MORE_THAN_34_HOURS_LESS_THAN_36_HOURS",
  MORE_THAN_36_HOURS_LESS_THAN_38_HOURS = "MORE_THAN_36_HOURS_LESS_THAN_38_HOURS",
  MORE_THAN_38_HOURS_LESS_THAN_40_HOURS = "MORE_THAN_38_HOURS_LESS_THAN_40_HOURS",
  MORE_THAN_40_HOURS = "MORE_THAN_40_HOURS",
  MORE_THAN_40_HOURS_LESS_THAN_45_HOURS = "MORE_THAN_40_HOURS_LESS_THAN_45_HOURS",
  MORE_THAN_45_HOURS_LESS_THAN_50_HOURS = "MORE_THAN_45_HOURS_LESS_THAN_50_HOURS",
  MORE_THAN_50_HOURS_LESS_THAN_52_HOURS = "MORE_THAN_50_HOURS_LESS_THAN_52_HOURS",
  MORE_THAN_52_HOURS = "MORE_THAN_52_HOURS",
  MORE_THAN_6_HOURS_LESS_THAN_8_HOURS = "MORE_THAN_6_HOURS_LESS_THAN_8_HOURS",
  MORE_THAN_8_HOURS_LESS_THAN_10_HOURS = "MORE_THAN_8_HOURS_LESS_THAN_10_HOURS",
}

/**
 * DashBoard DB 검색을 위한 타입
 */
export enum SEARCH_DB_TYPE_FOR_DASH_BOARD {
  all = "all",
  dataPerio = "dataPerio",
  departName = "departName",
  empId = "empId",
  empName = "empName",
  nameOne = "nameOne",
  nameThree = "nameThree",
  nameTwo = "nameTwo",
  otExtTime = "otExtTime",
  otExtTimeSection = "otExtTimeSection",
  otTotTime = "otTotTime",
  otTotTimeSection = "otTotTimeSection",
  otWorkTime = "otWorkTime",
  otWorkTimeSection = "otWorkTimeSection",
  workOx = "workOx",
}

/**
 * 예약상세정보 검색 타입
 */
export enum SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE {
  categoryName = "categoryName",
  endDate = "endDate",
  groupName = "groupName",
  name = "name",
  startDate = "startDate",
}

/**
 * 예약정보 검색 타입
 */
export enum SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE {
  categoryDescription = "categoryDescription",
  categoryName = "categoryName",
  groupName = "groupName",
}

/**
 * 차량 주행 정보 검색을 위한 타입
 */
export enum SEARCH_TYPE_FOR_VEHICLE_DRIVING_INFORMATION {
  endEmployeeId = "endEmployeeId",
  endEmployeeName = "endEmployeeName",
  endGpsAddress = "endGpsAddress",
  startEmployeeId = "startEmployeeId",
  startEmployeeName = "startEmployeeName",
  startGpsAddress = "startGpsAddress",
  vehicleName = "vehicleName",
  vehicleNumber = "vehicleNumber",
}

/**
 * 차량 정보 검색을 위한 타입
 */
export enum SEARCH_TYPE_FOR_VEHICLE_INFORMATION {
  vehicleCompany = "vehicleCompany",
  vehicleName = "vehicleName",
  vehicleNumber = "vehicleNumber",
}

/**
 * 컬럼 정렬 타입
 */
export enum SortColumType {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * 정책적용된 부서 검색 타입
 */
export enum TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT {
  alarmName = "alarmName",
  departName = "departName",
}

/**
 * 정책적용직원 검색 타입
 */
export enum TYPE_OF_EMPLOYEE_APPLIED_TO_THE_TEMPLATE {
  alarmName = "alarmName",
  departName = "departName",
  employeeId = "employeeId",
  isExpect = "isExpect",
  name = "name",
}

/**
 * 직원정보 타입
 */
export enum TYPE_OF_EMPLOYEE_INFORMATION {
  approvalDefine = "approvalDefine",
  asTimeTemplateName = "asTimeTemplateName",
  col1 = "col1",
  col10 = "col10",
  col11 = "col11",
  col12 = "col12",
  col13 = "col13",
  col14 = "col14",
  col15 = "col15",
  col2 = "col2",
  col3 = "col3",
  col4 = "col4",
  col5 = "col5",
  col6 = "col6",
  col7 = "col7",
  col8 = "col8",
  col9 = "col9",
  departName = "departName",
  departmentId = "departmentId",
  employeeId = "employeeId",
  endpointCount = "endpointCount",
  fullDepartmentName = "fullDepartmentName",
  insertType = "insertType",
  name = "name",
  todayWorkingTime = "todayWorkingTime",
  weekMaxExtWorkHour = "weekMaxExtWorkHour",
  weekMaxWorkHour = "weekMaxWorkHour",
  workingName = "workingName",
  workingTemplateIdx = "workingTemplateIdx",
  wtAsextto = "wtAsextto",
  wtTot = "wtTot",
}

/**
 * PC정보 타입
 */
export enum TYPE_OF_PC_INFORMATION {
  computerName = "computerName",
  departName = "departName",
  deviceName = "deviceName",
  isOnOff = "isOnOff",
  name = "name",
  workingName = "workingName",
}

export interface FieldSort {
  name: string;
  sort: SortColumType;
}

/**
 * QueryContent 정보
 */
export interface QueryContent {
  index: number;
  title?: string | null;
  description?: string | null;
  base_query?: string | null;
  employees: string[];
  departments: number[];
  start_date?: string | null;
  end_date?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
