import { useState, useContext } from "react";
import {
  updateDepartmentWorkingTmplate,
  updateUserWorkingTemplate,
  updatePcWorkingTemplate
} from "../../../../../../Utils/changeWorkingTemplate";
import Store from "../../../../../../components/main-view/WorkManagementInfo/Store";

const useConfirmDialogState = props => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [workTempIdx, setWorkTempIdx] = useState();
  const { setIsUpdateTreeData } = useContext(Store);

  const handleDialog = isOpen => {
    if (isOpen) {
      setIsOpenDialog(true);
    } else {
      setIsOpenDialog(false);
    }
  };

  const handleConfirmDialogForDepartment = (
    updateIds,
    working_templete_idx
  ) => {
    if (updateIds && updateIds.length > 0) {
      updateDepartmentWorkingTmplate(updateIds, working_templete_idx)
        .then(() => setIsUpdateTreeData(true))
        .catch(error => console.log(error));
    }
  };

  const handleConfirmDialogForUsers = async (
    updateIds,
    working_templete_idx
  ) => {
    if (props && props.getUsers && updateIds && updateIds.length > 0) {
      try {
        await updateUserWorkingTemplate(updateIds, working_templete_idx);
      } catch (error) {
        console.log(error);
      } finally {
        const params = {
          limit: 10,
          offset: 0
        };
        props.getUsers(params);
      }
    }
  };

  const handleConfirmDialogForPc = (updateIds, working_templete_idx) => {
    if (updateIds && updateIds.length > 0) {
      updatePcWorkingTemplate(updateIds, working_templete_idx).catch(error =>
        console.log(error)
      );
    }
  };

  const handleConfirmDialog = (
    isOpen,
    updateIds,
    working_templete_idx,
    type
  ) => () => {
    switch (type) {
      case "department":
        handleConfirmDialogForDepartment(updateIds, working_templete_idx);
        break;
      case "users":
        handleConfirmDialogForUsers(updateIds, working_templete_idx);
        break;
      case "pc":
        handleConfirmDialogForPc(updateIds, working_templete_idx);
        break;
      default:
        break;
    }
    handleDialog(isOpen);
  };

  const updateWorkingIndex = (isOpen, working_templete_idx) => () => {
    handleDialog(isOpen);
    setWorkTempIdx(working_templete_idx);
  };

  return {
    isOpenDialog,
    handleDialog,
    workTempIdx,
    updateWorkingIndex,
    handleConfirmDialog
  };
};

export default useConfirmDialogState;
