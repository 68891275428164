import styled from "styled-components";
import ApprovalListOfReceive from "./approval-list-of-receive";
import ApprovalListOfReference from "./approval-list-of-reference";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

function ApprovalReceiveReference() {
  return (
    <Container>
      <ApprovalListOfReceive />
      <ApprovalListOfReference />
    </Container>
  );
}

export default ApprovalReceiveReference;
