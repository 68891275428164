import { gql, useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import {
  GetListOfReservationDetailInformation,
  GetListOfReservationDetailInformationVariables,
  GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list
} from "../../__generated__/GetListOfReservationDetailInformation";
import * as ReactTable from "react-table";
import { GetListOfReservationInformation_getListOfReservationInformation_list } from "../../__generated__/GetListOfReservationInformation";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSortBy from "../../hooks/use-sort-by/use-sort-by";
import usePageControl from "../../hooks/use-page-control/use-page-control";
import { SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE } from "../../__generated__/globalTypes";
import AsonicTable from "../asonic-table/asonic-table";
import {
  GetTotalListOfReservationDetailInformation,
  GetTotalListOfReservationDetailInformationVariables
} from "../../__generated__/GetTotalListOfReservationDetailInformation";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import { SearchFieldValues } from "../asonic-table/asonic-search";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../hooks/use-dnd/use-dnd";
import {
  DownloadExcelListOfReservationDetailInformation,
  DownloadExcelListOfReservationDetailInformationVariables
} from "../../__generated__/DownloadExcelListOfReservationDetailInformation";
import { downloadFileFromServer } from "../main-view/statistics/Utils";
import moment from "moment";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import ReservationDetailInformationController from "./reservation-detail-information-controller/reservation-detail-information-controller";
import useFixedColumn from "../../hooks/use_fixed_column/use_fixed_column";
import TableLayoutContainer from "../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../table_v2/table_v2";
import TableLayoutFooter from "../table_layout/table_layout_footer";
import PageController from "../table/page_controller";
import UserInfoSectionHeader from "../main-view/WorkManagementInfo/user-info-section/user_info_section_container";
import useNewSortBy from "../../hooks/use-new-sort-by/use-new-sort-by";
import { useSticky } from "react-table-sticky";

interface IProps {
  selectedReservationInfo:
    | ReactTable.Row<GetListOfReservationInformation_getListOfReservationInformation_list>
    | undefined;
}

enum COLUMN_FOR_VALUE {
  groupName = "예약종류",
  categoryName = "예약 상세 항목",
  reserveTitle = "예약명/목적",
  startDate = "예약시작일",
  endDate = "예약종료일",
  startTime = "예약시작시간",
  endTime = "예약종료시간",
  requestEmployeeId = "예약자 사번",
  name = "예약자 이름",
  description = "상세설명"
}

enum COLUMN_SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE {
  groupName = "예약종류",
  categoryName = "예약 상세 항목",
  startDate = "예약시작일",
  endDate = "예약종료일",
  name = "예약자 이름"
}

export type TYPE_FOR_VEHICLE_INFORMATION_TABLE = keyof typeof COLUMN_FOR_VALUE;

const title = "예약상세정보";

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  flex-direction: column;
`;

const QUERY_GET_LIST_OF_RESERVATION_DETAIL_INFORMATION = gql`
  query GetListOfReservationDetailInformation(
    $categoryIdx: Int!
    $page: Float
    $take: Float
    $fieldSort: FieldSort
    $searchType: SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE
    $searchValue: String
  ) {
    getListOfReservationDetailInformation(
      categoryIdx: $categoryIdx
      page: $page
      take: $take
      fieldSort: $fieldSort
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      list {
        infoIdx
        groupName
        categoryName
        reserveTitle
        startDate
        endDate
        startTime
        endTime
        requestEmployeeId
        name
        description
      }
    }
  }
`;

const QUERY_TOTAL_LIST_OF_RESERVATION_DETAIL_INFORMATION = gql`
  query GetTotalListOfReservationDetailInformation(
    $categoryIdx: Int!
    $searchType: SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE
    $searchValue: String
  ) {
    getTotalListOfReservationDetailInformation(
      categoryIdx: $categoryIdx
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_EXCEL_LIST_OF_RESERVATION_DETAIL_INFORMATION = gql`
  query DownloadExcelListOfReservationDetailInformation(
    $categoryIdx: Int!
    $searchType: SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE
    $searchValue: String
  ) {
    downloadExcelListOfReservationDetailInformation(
      categoryIdx: $categoryIdx
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

function ReservationDetailInformation({ selectedReservationInfo }: IProps) {
  const { oldFieldSort, fieldSort, handleFieldSort } = useNewSortBy();
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [searchType, setSearchType] =
    useState<SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE>(
      SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE.categoryName
    );
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const [searchValue, setSearchValue] = useState("");

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const [downloadExcelListOfReservationInfo] = useLazyQuery<
    DownloadExcelListOfReservationDetailInformation,
    DownloadExcelListOfReservationDetailInformationVariables
  >(QUERY_DOWNLOAD_EXCEL_LIST_OF_RESERVATION_DETAIL_INFORMATION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error.message);
    },
    onCompleted(data) {
      console.log(data);
      if (
        data.downloadExcelListOfReservationDetailInformation.ok &&
        data.downloadExcelListOfReservationDetailInformation.excel
      ) {
        downloadFileFromServer(
          data.downloadExcelListOfReservationDetailInformation.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}-list-of-reservation-detail-information.csv`
        );
      }
      if (
        !data.downloadExcelListOfReservationDetailInformation.ok &&
        data.downloadExcelListOfReservationDetailInformation.error
      ) {
        handleToast(
          data.downloadExcelListOfReservationDetailInformation.error,
          MessageTypes.ERROR
        );
      }
    }
  });

  const [getListOfReservationDetailInformation, { data, loading }] =
    useLazyQuery<
      GetListOfReservationDetailInformation,
      GetListOfReservationDetailInformationVariables
    >(QUERY_GET_LIST_OF_RESERVATION_DETAIL_INFORMATION, {
      fetchPolicy: "network-only",
      onError(error) {
        console.log(error);
        handleToast(
          "알 수 없는 이유로 예약상세정보를 가져올 수 없습니다.",
          MessageTypes.ERROR
        );
      },
      onCompleted(data) {
        if (
          !data.getListOfReservationDetailInformation.ok &&
          data.getListOfReservationDetailInformation.error
        ) {
          handleToast(
            data.getListOfReservationDetailInformation.error,
            MessageTypes.ERROR
          );
        }
      }
    });

  const [
    getTotalListOfReservationDetailInfo,
    { data: totalData, loading: totalLoading }
  ] = useLazyQuery<
    GetTotalListOfReservationDetailInformation,
    GetTotalListOfReservationDetailInformationVariables
  >(QUERY_TOTAL_LIST_OF_RESERVATION_DETAIL_INFORMATION, {
    fetchPolicy: "network-only"
  });

  const downloadExcel = useCallback(() => {
    if (selectedReservationInfo) {
      downloadExcelListOfReservationInfo({
        variables: {
          categoryIdx: selectedReservationInfo.original.categoryIdx,
          searchType,
          searchValue
        }
      });
    }
  }, [
    downloadExcelListOfReservationInfo,
    searchType,
    searchValue,
    selectedReservationInfo
  ]);

  const total = useMemo(() => {
    if (
      totalData?.getTotalListOfReservationDetailInformation.ok &&
      totalData?.getTotalListOfReservationDetailInformation.total
    ) {
      return totalData?.getTotalListOfReservationDetailInformation.total;
    }
    return 0;
  }, [totalData]);

  const listOfSearchType = useMemo(() => {
    return Object.keys(
      COLUMN_SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE
    ).map(item => ({
      value: item,
      name: COLUMN_SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE[
        item as keyof typeof COLUMN_SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE
      ]
    }));
  }, []);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type && selectedReservationInfo) {
        const INIT_PAGE = 1;
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.value ?? "");
        if (data.type) {
          setSearchType(
            data.type as SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE
          );
        }
        getTotalListOfReservationDetailInfo({
          variables: {
            categoryIdx: selectedReservationInfo.original.categoryIdx,
            searchType:
              data.type as SEARCH_TYPE_FOR_RESERVATION_DETAIL_INFORMATION_TYPE,
            searchValue: data.value
          }
        });
      }
    },
    [
      getTotalListOfReservationDetailInfo,
      handleCurrentPage,
      selectedReservationInfo
    ]
  );

  const columns: ReactTable.Column<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let width = 120;
        let sticky = "";
        if (sFixedColumnNumber !== undefined) {
          if (fixedColumnName.length > 0) {
            fixedColumnName.forEach((fColumn, fColumnIndex) => {
              if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
                sticky = "left";
              }
            });
          }
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_FOR_VEHICLE_INFORMATION_TABLE],
          accessor: item as TYPE_FOR_VEHICLE_INFORMATION_TABLE,
          width,
          sticky
        };
      });
    }, [sFixedColumnNumber, fixedColumnName]);

  const list: GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list[] =
    useMemo(() => {
      return data?.getListOfReservationDetailInformation.list || [];
    }, [data]);

  const table =
    ReactTable.useTable<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>(
      {
        columns,
        data: list
      },
      ReactTable.useBlockLayout,
      ReactTable.useRowSelect,
      ReactTable.useColumnOrder,
      useSticky
    );

  const selectedRow:
    | ReactTable.Row<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }

    return;
  }, [table.selectedFlatRows]);

  const { moveColumn } =
    useDnd<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>(
      {
        columns: table.visibleColumns,
        setColumnOrder: table.setColumnOrder,
        title: `${title}-for-ordering-column`,
        setFixedColumnName
      }
    );

  useEffect(() => {
    if (selectedReservationInfo) {
      getTotalListOfReservationDetailInfo({
        variables: {
          categoryIdx: selectedReservationInfo.original.categoryIdx,
          searchType,
          searchValue
        }
      });
    }
  }, [
    getTotalListOfReservationDetailInfo,
    searchType,
    searchValue,
    selectedReservationInfo
  ]);

  useEffect(() => {
    if (selectedReservationInfo) {
      getListOfReservationDetailInformation({
        variables: {
          categoryIdx: selectedReservationInfo.original.categoryIdx,
          page: currentPage,
          take: take,
          searchType: searchType,
          searchValue: searchValue,
          fieldSort: oldFieldSort
        }
      });
    }
  }, [
    getListOfReservationDetailInformation,
    selectedReservationInfo,
    currentPage,
    take,
    searchType,
    searchValue,
    oldFieldSort
  ]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(pre => {
      if (pre.length > 0) {
        return pre;
      }
      return listOfColumn;
    });
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);
  return (
    <Container>
      <UserInfoSectionHeader<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>
        columns={
          table.columns as TColumn<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>[]
        }
        table={table}
        title={title}
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        setIsActiveFilter={setIsActiveFilter}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title={title}
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                loading={loading || totalLoading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      {/* <AsonicTable<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={handleSelectRow}
        isLoading={loading || totalLoading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        handleFieldSort={handleFieldSort}
        fieldSort={fieldSort}
        columns={
          reservationDetailInformationColumn as TColumn<GetListOfReservationDetailInformation_getListOfReservationDetailInformation_list>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      /> */}
      <ReservationDetailInformationController
        selectedRow={selectedRow}
        handleToast={handleToast}
        selectedReservationInfo={selectedReservationInfo}
      />
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ReservationDetailInformation;
