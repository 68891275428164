import styled from "styled-components";
import AsonicDialog from "../../../../../components/asonic-dialog/asonic-dialog";
import FormRow from "../../../../../components/shared/form-row/form-row";
import StyledColumnContainer from "../../../../../components/shared/styled_column_container";
import StyledFlexContainer from "../../../../../components/shared/styled_flex_container";
import StyledInput from "../../../../../components/shared/styled_input";
import ButtonContainer from "../../../../../components/shared/button_container";
import StyledButton from "../../../../../components/shared/styled_button";
import {
  UserEntity,
  useAddUserDailyWorkInfoMutation,
  useGetListOfOtTimeTypeCategoryLazyQuery,
  useGetUserLazyQuery
} from "../../../../../generated/graphql";
import { useContext, useEffect, useMemo, useState } from "react";
import { MessageTypes } from "../../../../../components/toast-message/toast-message";
import moment from "moment";
import { categorySelectionActionCreator } from "../../../../../redux/modules/main-view/categorySelectionReducer";
import MainViewContext from "../../../../../components/main-view/store";

interface IProps {
  handleOpenDialog: (value: boolean) => void;
  handleToast: (message: string, type: MessageTypes) => void;
}

const Container = styled(StyledColumnContainer)`
  justify-content: center;
  ${ButtonContainer} {
    justify-content: flex-end;
  }
`;

const ContentContainer = styled(StyledFlexContainer)`
  gap: 5px;
  label {
    font-weight: bold;
  }
`;

const DataListContainer = styled(StyledColumnContainer)`
  flex: 0;
  ${StyledInput} {
    width: 200px;
  }
`;

const Select = styled.select`
  background-color: rgba(0, 0, 0, 0.6); /* 검은색 배경, 80% 투명도 */
  color: ${props => props.theme.colors.white};
`;

// Spacer 컴포넌트 추가
const Spacer = styled.div<{ height?: string }>`
  height: ${props => props.height || "20px"};
`;

function AddUserDailyWorkPlanDialog({ handleOpenDialog, handleToast }: IProps) {
  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: {
      loginIdInfo: authCheck,
      checkWebAuthFn,
      employee_id: callEmployeeId
    }
  } = rootStore;
  const [userName, setUserName] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>();
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const [userList, setUserList] = useState<UserEntity[]>([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");
  const [plusMinusMark, setPlusMinusMark] = useState<string>("+");
  const [breakTimeAdjustmentMin, setBreakTimeAdjustmentMin] =
    useState<string>("0");

  const [categoryId, setCategoryId] = useState<string>("0");

  const [getUser] = useGetUserLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getUser.ok) {
        if (data.getUser.user) {
          setUserList(data.getUser.user.slice(0, 11));
        } else {
          setUserList([]);
        }
      } else if (data.getUser.error) {
        console.log(data.getUser.error);
      }
    }
  });

  const [addUserDailyWorkInfo, { client }] = useAddUserDailyWorkInfoMutation({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 선택된 일별 근무 계획을 추가하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.addUserDailyWorkInfo.ok) {
        handleToast(
          "성공적으로 선택된 일별 근무 계획을 추가 하였습니다",
          MessageTypes.SUCCESS
        );
        client.resetStore();
        handleOpenDialog(false);
      } else if (data?.addUserDailyWorkInfo.error) {
        handleToast(data?.addUserDailyWorkInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const [getListOfOtTimeTypeCategory, { data: otTimeTypeCategoryData }] =
    useGetListOfOtTimeTypeCategoryLazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (data.getListOfOtTimeTypeCategory.error) {
          console.log(data.getListOfOtTimeTypeCategory.error);
        }
      }
    });

  useEffect(() => {
    getListOfOtTimeTypeCategory();
  }, [getListOfOtTimeTypeCategory]);

  const listOfCategory = useMemo(() => {
    const defaultCategory = { categoryId: 0, name: "미사용" };
    const categories =
      otTimeTypeCategoryData?.getListOfOtTimeTypeCategory.list ?? [];

    const mergedCategories = [defaultCategory, ...categories];
    setCategoryId(`${mergedCategories[0].categoryId}`);
    return mergedCategories;
  }, [otTimeTypeCategoryData]);

  useEffect(() => {
    if (userName.length > 1) {
      getUser({
        variables: {
          name: userName
        }
      });
    } else {
      setIsFocus(false);
    }
  }, [userName, getUser]);

  return (
    <AsonicDialog
      title={"근무시간추가"}
      handleClose={(value: boolean) => {
        handleOpenDialog(value);
      }}
      width="650px"
      minWidth="650px"
      height="270px"
      minHeight="270px"
    >
      <Container>
        <FormRow title="직원 검색">
          <ContentContainer>
            <DataListContainer>
              <StyledInput
                list="user-list"
                value={userName}
                name="search"
                onFocus={() => {
                  setIsFocus(true);
                }}
                onChange={event => {
                  setUserName(event.currentTarget.value);
                  setIsFocus(true);
                }}
              />
              {isFocus && userList.length > 0 && (
                <Select
                  onChange={event => {
                    if (event.currentTarget.value) {
                      userList.forEach(item => {
                        if (item.employeeId === event.currentTarget.value) {
                          setSelectedUser(item);
                          setUserName(item.name ?? item.employeeId);
                        }
                      });
                    }
                  }}
                  onBlur={() => {
                    setIsFocus(false);
                  }}
                >
                  <option value="">{`사용자를 선택해주세요`}</option>
                  {userList.map(item => (
                    <option
                      key={item.employeeId}
                      value={item.employeeId}
                    >{`${item.name}(${item.employeeId})`}</option>
                  ))}
                </Select>
              )}
              {/*datalist컴포넌트 사용시 직원 검색시 마우스 focus 잃을시 onChange 이벤트가 재발생되어 userName이 잘못된방식으로 셋팅됨*/}
              {/* <datalist id="user-list">
              {userList.map(item => (
                <option
                  key={item.employeeId}
                  value={item.name ?? item.employeeId}
                  onClick={() => {
                    console.log("클릭");
                  }}
                >{`${item.name}(${item.employeeId})`}</option>
              ))}
            </datalist> */}
            </DataListContainer>
            <label htmlFor="">
              {`선택된 직원 : ${selectedUser?.name ?? "없음"}${
                selectedUser?.employeeId ? `(${selectedUser?.employeeId})` : ""
              }`}
            </label>
          </ContentContainer>
        </FormRow>
        <FormRow title="출/퇴근 시간">
          <ContentContainer>
            <StyledInput
              type="date"
              value={date}
              onChange={event => setDate(event.currentTarget.value)}
            />
            <StyledInput
              type="time"
              value={startTime}
              onChange={event => setStartTime(event.currentTarget.value)}
            />
            <StyledInput
              type="time"
              value={endTime}
              onChange={event => setEndTime(event.currentTarget.value)}
            />
          </ContentContainer>
        </FormRow>
        <FormRow title="휴게시간(추가인정)">
          <ContentContainer>
            <select
              name="plus_or_minus_mark"
              id="plus_or_minus_mark"
              value={plusMinusMark}
              onChange={event =>
                setPlusMinusMark(event.currentTarget.value ?? "+")
              }
            >
              <option value={"+"}>{"+"}</option>
              <option value={"-"}>{"-"}</option>
            </select>
            <StyledInput
              type="number"
              value={breakTimeAdjustmentMin}
              onChange={event =>
                setBreakTimeAdjustmentMin(event.currentTarget.value)
              }
            />
          </ContentContainer>
        </FormRow>
        <FormRow title={"추가 인정 시간"}>
          <select
            name="categoryId"
            id="categoryId"
            onChange={event => {
              setCategoryId(event.target.value);
            }}
            value={categoryId}
          >
            {listOfCategory.map(category => (
              <option value={category.categoryId} key={category.categoryId}>
                {category.name}
              </option>
            ))}
          </select>
        </FormRow>
        <Spacer height="10px" />
        <ButtonContainer>
          <StyledButton
            disabled={userList.length === 0 && !selectedUser}
            $maxWidth="50px"
            onClick={() => {
              if (userList.length !== 0 && selectedUser) {
                let newBreakTimeAdjustmentMin = breakTimeAdjustmentMin;
                if (plusMinusMark === "-") {
                  newBreakTimeAdjustmentMin = `${plusMinusMark}${breakTimeAdjustmentMin}`;
                }
                const payload = {
                  employeeId: selectedUser.employeeId,
                  callEmployeeId,
                  date,
                  startTime,
                  endTime,
                  breakTimeAdjustmentMin: newBreakTimeAdjustmentMin,
                  categoryIdAdjustment: categoryId
                };
                addUserDailyWorkInfo({
                  variables: {
                    info: [payload]
                  }
                });
              }
            }}
          >{`추가`}</StyledButton>
        </ButtonContainer>
      </Container>
    </AsonicDialog>
  );
}

export default AddUserDailyWorkPlanDialog;
