import { useState } from "react";

const useRightMenuPositionState = setIsRightMenu => {
  const [rightMenuPosition, setRightMenuPosition] = useState({});
  const [updateIds, setUpdateIds] = useState([]);

  const handleContextMenu = (id, updateItems) => event => {
    event.preventDefault();
    if (updateItems && id.length === 1) {
      updateItems(id[0])(event);
    } else if (updateItems) {
      updateItems(id)(event);
    }
    setUpdateIds(id);
    const { pageX, pageY } = event;
    setRightMenuPosition({ pageX, pageY });
    setIsRightMenu(true);
  };
  return { rightMenuPosition, handleContextMenu, updateIds };
};

export default useRightMenuPositionState;
