import StyledFlexContainer from "../../../../shared/styled_flex_container";
import PageSet from "../../../../table/set_page";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import HideColumn, { IHideColumnProps } from "../../../../table/hide_column";
import PointTitle from "../../../../shared/point_title";
import styled from "styled-components";
import StyledInput from "../../../../shared/styled_input";
import IconContainer from "../../../../shared/icon_container";
import { useCallback } from "react";
import * as Excel from "exceljs";
import {
  EditAnnualStatusInput,
  IApprovalStatusSearchType,
  useAddAnnualStatusFormExcelMutation
} from "../../../../../generated/graphql";
import moment from "moment";
import { MessageTypes } from "../../../../toast-message/toast-message";
import AsonicExcel from "../../../../asonic-table/asonic-excel";
import { IList } from "../../../../asonic-table/asonic-search";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../../table_v2/fixed_column_dropbox";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  listOfSearchType?: IList[];
  searchType?: IApprovalStatusSearchType;
  setSearchType: React.Dispatch<
    React.SetStateAction<IApprovalStatusSearchType>
  >;
  searchValue?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleToast: (message: string, type: MessageTypes) => void;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.green};
  cursor: pointer;
  height: max-content;
  padding: 6px;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
  }
`;

function ApprovalStatusHeader<P extends object>({
  columns,
  table,
  title,
  setIsSearch,
  headerTitleList,
  take,
  handleTake,
  count,
  handleToast,
  handleCurrentPage,
  downloadExcel,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  listOfSearchType,
  searchType,
  setSearchType,
  searchValue,
  setSearchValue,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN
}: IProps<P>) {
  const [addAnnualStatusFormExcel, { client }] =
    useAddAnnualStatusFormExcelMutation({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알 수 없는 이유로 연차 정보를 추가하지 못했습니다.",
          MessageTypes.ERROR
        );
      },
      update(_, { data }) {
        if (data?.addAnnualStatusFormExcel.ok) {
          handleToast(
            "성공적으로 연차 정보를 추가하였습니다",
            MessageTypes.SUCCESS
          );
          client.resetStore();
        } else if (data?.addAnnualStatusFormExcel.error) {
          handleToast(data?.addAnnualStatusFormExcel.error, MessageTypes.ERROR);
        }
      }
    });

  const handleXlsx = useCallback(
    (file: File) => {
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;
        if (buffer) {
          const list: EditAnnualStatusInput[] = [];
          wb.xlsx.load(buffer as ArrayBuffer).then(workbook => {
            let employeeIdIndex = 0;
            let applyYearIndex = 0;
            let annualDayIndex = 0;
            workbook.eachSheet((sheet, id) => {
              if (id === 1) {
                sheet.eachRow((row, rowIndex) => {
                  const value = row.values as any[];
                  if (rowIndex === 1) {
                    employeeIdIndex = value.findIndex(item => {
                      if (typeof item === "string") {
                        return item.includes("사번");
                      }
                      return false;
                    });
                    applyYearIndex = value.findIndex(item => {
                      if (typeof item === "string") {
                        return item.includes("귀속년도");
                      }
                      return false;
                    });
                    annualDayIndex = value.findIndex(item => {
                      if (typeof item === "string") {
                        return item.includes("총 발생일");
                      }
                      return false;
                    });
                  }
                  if (rowIndex > 1 && value[employeeIdIndex]) {
                    let searchYear: string = "";
                    let annualDay = 0;
                    if (value[applyYearIndex]) {
                      searchYear = `${value[applyYearIndex]}`;
                    }
                    if (value[annualDayIndex]) {
                      annualDay = value[annualDayIndex];
                    }
                    let employeeId = "";
                    if (value[employeeIdIndex]) {
                      if (typeof value[employeeIdIndex] === "object") {
                        employeeId = value[employeeIdIndex].result;
                      } else {
                        employeeId =
                          typeof value[employeeIdIndex] === "number"
                            ? value[employeeIdIndex].toString()
                            : value[employeeIdIndex];
                      }
                    }
                    const payload: EditAnnualStatusInput = {
                      employeeIdList: [employeeId],
                      annualDay,
                      descr: "",
                      searchYear
                    };
                    list.push(payload);
                  }
                });
              }
            });
            if (list.length > 0) {
              console.log(list);
              addAnnualStatusFormExcel({
                variables: {
                  info: list
                }
              });
            }
          });
        }
      };
    },
    [addAnnualStatusFormExcel]
  );

  const handleCvs = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const csvData = reader.result as string;
      console.log(csvData);
      if (csvData) {
        const list: EditAnnualStatusInput[] = [];
        const rows = csvData.split("\n");

        let employeeIdIndex = 0;
        let applyYearIndex = 0;
        let annualDayIndex = 0;

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const values = row.split(",");

          if (i === 0) {
            employeeIdIndex = values.findIndex(item => item.includes("사번"));
            applyYearIndex = values.findIndex(item =>
              item.includes("귀속년도")
            );
            annualDayIndex = values.findIndex(item =>
              item.includes("총 발생일")
            );
          } else if (values[employeeIdIndex]) {
            let employeeIdList = "";
            let searchYear = "";
            let annualDay = 0;

            if (values[applyYearIndex]) {
              searchYear = moment(values[applyYearIndex]).format("YYYY");
            }
            if (values[annualDayIndex]) {
              annualDay = parseFloat(values[annualDayIndex]) ?? 0;
            }
            console.log(values[employeeIdIndex]);
            // const employeeId =
            //   typeof values[employeeIdIndex] === "number"
            //     ? values[employeeIdIndex].toString()
            //     : values[employeeIdIndex];

            // const payload: EditAnnualStatusInput = {
            //   employeeIdList: ["1"],
            //   annualDay,
            //   descr: "",
            //   searchYear
            // };

            // list.push(payload);
          }
        }
        if (list.length > 0) {
          // 추가적인 처리나 업로드 로직을 여기에 추가할 수 있습니다.
          // addUserDailyWorkInfo({
          //   variables: {
          //     info: list,
          //   },
          // });
        }
      }
    };
  }, []);

  // const handleSelectedFile = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const files = event.currentTarget.files;
  //     if (files && files[0]) {
  //       const file = files[0];
  //       if (file.type === "text/csv") {
  //         handleCvs(file);
  //       } else {
  //         handleXlsx(file);
  //       }
  //     }
  //     event.target.value = ""; // value 초기화
  //   },
  //   [handleXlsx, useCallback]
  // );

  return (
    <Container>
      <LeftSection>
        <PointTitle title="검색결과" subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          <select
            value={searchType}
            onChange={event => {
              if (setSearchType) {
                setSearchType(
                  event.currentTarget.value as IApprovalStatusSearchType
                );
              }
            }}
          >
            {listOfSearchType?.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          <StyledInput
            value={searchValue}
            onChange={event => setSearchValue(event.currentTarget.value ?? "")}
          />
          <StyledInput
            type="date"
            value={startDate}
            onChange={event => {
              setStartDate(event.currentTarget.value);
            }}
          />
          <span>{`~`}</span>
          <StyledInput
            type="date"
            value={endDate}
            onChange={event => {
              setEndDate(event.currentTarget.value);
            }}
          />
          <IconContainer
            onClick={() => {
              setIsSearch(true);
              handleCurrentPage(1);
            }}
          >
            <Icon icon={MagnifyIcon} />
          </IconContainer>
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
      <FileContainer>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </FileContainer>
    </Container>
  );
}

export default ApprovalStatusHeader;
