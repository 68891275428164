import { Container, TitleBar, Title, SubTitle, ChartContainer } from "./styled";
import PersonalStatusChart from "./personal-status-chart";
import { AxiosResponse } from "axios";
import usePersonalStatusBoxPeriod from "../../../../hooks/master-work-management-page-hooks/use-personal-status-box-period";

type Props = {
  title: string;
  callData: (params: any) => Promise<AxiosResponse<any>>;
  color: string;
};

const PersonalStatusBox = <P extends Props>({ title, callData, color }: P) => {
  const { period, handlePeriod } = usePersonalStatusBoxPeriod();
  return (
    <Container data-testid="personal-status-box">
      <TitleBar>
        <Title data-testid="title">{`${title} 인원현황`}</Title>
        <SubTitle data-testid="sub-title">{period}</SubTitle>
      </TitleBar>
      <ChartContainer>
        <PersonalStatusChart
          title={title}
          callData={callData}
          color={color}
          handlePerio={handlePeriod}
        />
      </ChartContainer>
    </Container>
  );
};

export default PersonalStatusBox;
