import * as React from "react";

const useApprovalDialog = () => {
  const [save, setSave] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);

  const handleSave = React.useCallback((value: boolean) => {
    setSave(value);
  }, []);

  const handleOpenDialog = React.useCallback((value: boolean) => {
    setOpenDialog(value);
  }, []);

  const handleSelectedIndex = React.useCallback((value: number) => {
    setSelectedIndex(value);
  }, []);

  const wrappingOpenDialogWithIndex = React.useCallback(
    (index: number, isSave: boolean) => {
      if (isSave) {
        isSave ? handleSave(true) : handleSave(false);
        handleOpenDialog(false);
      }
      return () => {
        if (index === -1) {
          handleOpenDialog(false);
        }
        setSelectedIndex(index);
        isSave ? handleSave(true) : handleSave(false);
      };
    },
    [handleOpenDialog, handleSave]
  );
  return {
    save,
    selectedIndex,
    openDialog,
    handleSave,
    handleOpenDialog,
    handleSelectedIndex,
    wrappingOpenDialogWithIndex
  };
};

export default useApprovalDialog;
