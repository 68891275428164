import api from "../api";

const BASE_URL = `${api.api}/approval-notification-management`;
const LIST_OF_TEMPLATES = `${BASE_URL}/templates`;
const LIST_OF_SCHEDULE = `${BASE_URL}/list-of-schedule`;
const SCHEDULE = `${BASE_URL}/schedule`;
const EMPLOYEES_APPLIED_TO_THE_TEMPLATE = `${BASE_URL}/employees-applied-to-the-template`;
const COUNT_EMPLOYEES_APPLIED_TO_THE_TEMPLATE = `${EMPLOYEES_APPLIED_TO_THE_TEMPLATE}/count`;
const SEARCH_EMPLOYEES_APPLIED_TO_THE_TEMPLATE = `${EMPLOYEES_APPLIED_TO_THE_TEMPLATE}/search`;
const COUNT_SEARCHED_EMPLOYEES_APPLIED_TO_THE_TEMPLATE = `${SEARCH_EMPLOYEES_APPLIED_TO_THE_TEMPLATE}/count`;
const DOWNLOAD_EXCEL_EMPLOYEES_APPLIED_TO_THE_TEMPLATE = `${EMPLOYEES_APPLIED_TO_THE_TEMPLATE}/excel`;
const DEPARTMENT_APPLIED_TO_THE_TEMPLATE = `${BASE_URL}/departments-applied-to-the-template`;
const COUNT_DEPARTMENT_APPLIED_TO_THE_TEMPLATE = `${DEPARTMENT_APPLIED_TO_THE_TEMPLATE}/count`;
const SEARCH_DEPARTMENT_APPLIED_TO_THE_TEMPLATE = `${DEPARTMENT_APPLIED_TO_THE_TEMPLATE}/search`;
const COUNT_SEARCH_DEPARTMENT_APPLIED_TO_THE_TEMPLATE = `${SEARCH_DEPARTMENT_APPLIED_TO_THE_TEMPLATE}/count`;
const DOWNLOAD_EXCEL_DEPARTMENT_APPLIED_TO_THE_TEMPLATE = `${DEPARTMENT_APPLIED_TO_THE_TEMPLATE}/excel`;

const approvalNotificationManagementRoutes = {
  baseUrl: BASE_URL,
  listOfTemplates: LIST_OF_TEMPLATES,
  listOfSchedule: LIST_OF_SCHEDULE,
  schedule: SCHEDULE,
  employeesAppliedToTheTemplate: EMPLOYEES_APPLIED_TO_THE_TEMPLATE,
  searchEmployeesAppliedToTheTemplate: SEARCH_EMPLOYEES_APPLIED_TO_THE_TEMPLATE,
  downloadEmployeesAppliedToTheTemplate:
    DOWNLOAD_EXCEL_EMPLOYEES_APPLIED_TO_THE_TEMPLATE,
  countEmployeesAppliedToTheTemplate: COUNT_EMPLOYEES_APPLIED_TO_THE_TEMPLATE,
  countSearchedEmployeesAppliedToTheTemplate:
    COUNT_SEARCHED_EMPLOYEES_APPLIED_TO_THE_TEMPLATE.includes,
  departmentAppliedToTheTemplate: DEPARTMENT_APPLIED_TO_THE_TEMPLATE,
  countDepartmentAppliedToTheTemplate: COUNT_DEPARTMENT_APPLIED_TO_THE_TEMPLATE,
  searchDepartmentAppliedToTheTemplate:
    SEARCH_DEPARTMENT_APPLIED_TO_THE_TEMPLATE,
  countSearchDepartmentAppliedToTheTemplate:
    COUNT_SEARCH_DEPARTMENT_APPLIED_TO_THE_TEMPLATE,
  downloadExcelDepartmentAppliedToTheTemplate:
    DOWNLOAD_EXCEL_DEPARTMENT_APPLIED_TO_THE_TEMPLATE
};

export default approvalNotificationManagementRoutes;
