import styled from "styled-components";

export const Select = styled.select``;

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Typography = styled.div`
  background-color: #eeeeee;
  color: #595959;
  font-weight: bold;
  min-width: ${props => (props.textWidth ? props.textWidth : "64.612px")};
  letter-spacing: 0.00938em;
`;
