import styled from "styled-components";
import InfoIcon from "../shared/info-icon/info-icon";

const HelpContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  li {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  pre {
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
`;

function InfoMoreThanAYearHelp() {
  return (
    <InfoIcon
      minHeight="690px"
      height="690px"
      minWidth="400px"
      title="만 1년 이상 연차 설정"
    >
      <HelpContainer>
        <span>{`근속 만 1년 이상 근무자에 대한 연차를 설정합니다.`}</span>
        <ul>
          <li>{`기본 일수 설정`}</li>
          <span>{`근속년수 만1년 이상자에 대해 입력한 고정 일수를 부여합니다.`}</span>
          <li>{`가산 일수 설정`}</li>
          <pre>{`근속 년수가 설정한 년수 만큼 증가 할때 마다 설정한 일수 만큼 추가
부여 됩니다.`}</pre>
          <li>{`최대 가산 일수`}</li>
          <span>{`가산일수의 최대 일수 설정 합니다.`}</span>
          <li>{`마이너스 연차 사용 설정`}</li>
          <pre>{`부여된 연차 일수를 모두 소진 시 사용되는 마이너스 연차 사용 방
식을 설정합니다.`}</pre>
          <ul>
            <li>{`미사용`}</li>
            <span>{`근속 1년 이상자에 대해 마이너스 연차 사용을 불허 합니다.`}</span>
            <li>{`제한 없음`}</li>
            <span>{`일수 제한 없이 마이너스 연차를 사용 가능 하게 합니다.`}</span>
            <li>{`기본일수 + 마이너스연차 허용 일수로 제한`}</li>
            <pre>{`대상자가 부여 받은 기본일수와 관리자가 입력한 "마이너스연차
허용일수"의 합 만큼 만 사용 가능합니다.`}</pre>
            <li>{`마이너스 연차 허용 일수로 제한`}</li>
            <span>{`관리자가 입력한 "마이너스연차 허용일수"의 만큼 사용 가능 합니다.`}</span>
          </ul>
          <li>{`마이너스 연차 허용일`}</li>
          <pre>{`마이너스 연차 허용일을 입력합니다.`}</pre>
          <span>{`마이너스 연차 사용 설정이 제한 없음인 경우 적용되지 않습니다.`}</span>
          <li>{`이월 연차 적용 설정`}</li>
          <pre>{`적용시 이전 귀속년도의 잔여 연차를 금번 귀속 년도에 반영하여 합산합
니다. 미적용시 이전 귀속년도의 잔여 연차를 금번 귀속년도에 반영하지
않습니다.`}</pre>
          <li>{`이월 연차 적용 범위`}</li>
          <ul>
            <li>{`마이너스 연차만 적용`}</li>
            <pre>{`이전 귀속년도의 마이너스 연차만 반영하여 합산 합니다.
(이전 년도의 마이너스 연차 사용 일수 만큼 차감됨)`}</pre>
            <li>{`플러스 연차만 적용`}</li>
            <pre>{`이전 귀속년도의 플러스 잔여 연차만 반영하여 합산 합니다.
(이전 년도의 플러스 연차 잔여 일수 만큼 가산됨)`}</pre>
            <li>{`플러스 마이너스 연차 적용`}</li>
            <pre>{`이전 귀속 년도의 플러스 마이너스 잔여 연차를 모두 반영하여
합산합니다.`}</pre>
          </ul>
        </ul>
      </HelpContainer>
    </InfoIcon>
  );
}
export default InfoMoreThanAYearHelp;
