import { useForm } from "react-hook-form";
import * as ReactTable from "react-table";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../../types/reducers";
import { useCallback, useContext, useEffect, useMemo } from "react";
import CheckBox from "./check-box";
import FormError from "../../../../form/form-error";
import { Input } from "../../../../shared";
import ApprovalNotificationManagementCtx from "./approval-notification-management-dialog.ctx";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import {
  ApprovalTimeType,
  IsUsingNotification
} from "../../../../../__generated__/globalTypes";
import { GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list } from "../../../../../__generated__/GetListOfApprovalNotificationScheduleByTemplate";
import { gql, useMutation } from "@apollo/client";
import {
  AddApprovalNotificationSchedule,
  AddApprovalNotificationScheduleVariables
} from "../../../../../__generated__/AddApprovalNotificationSchedule";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  UpdateApprovalNotificationSchedule,
  UpdateApprovalNotificationScheduleVariables
} from "../../../../../__generated__/UpdateApprovalNotificationSchedule";
import {
  DeleteApprovalNotificationSchedule,
  DeleteApprovalNotificationScheduleVariables
} from "../../../../../__generated__/DeleteApprovalNotificationSchedule";
import AsonicIconButton from "../../../../shared/asonic-icon-button/asonic-icon-button";
import AddIcon from "@iconify/icons-fa-solid/plus-circle";
import EditIcon from "@iconify/icons-fa-solid/edit";
import DeleteIcon from "@iconify/icons-fa-solid/trash-alt";

interface IProps {
  selectedFlatRows: ReactTable.Row<GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list>[];
}

export interface IFieldValues {
  scheduleIdx: number;
  templateIdx: number;
  categoryId: string | null;
  isApp: boolean;
  isMail: boolean;
  approvalTimeType: ApprovalTimeType | null;
  dayCount: string;
  dayType: ApprovalTimeType | null;
  minCount: string;
  minType: ApprovalTimeType | null;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 5px;
`;

const EditorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const ButtonSection = styled.section`
  display: flex;
  font-size: 18px;
  align-self: flex-end;
  gap: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Select = styled.select`
  margin-left: 5px;
  width: auto;
  height: max-content;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    text-align: center;
  }
`;

const SInput = styled(Input)`
  width: 50px;
  padding: 3px;
`;

const MUTATION_ADD_APPROVAL_NOTIFICATION_SCHEDULE = gql`
  mutation AddApprovalNotificationSchedule(
    $templateIdx: Int!
    $categoryId: Int!
    $isApp: IsUsingNotification!
    $isMail: IsUsingNotification!
    $approvalTimeType: ApprovalTimeType!
    $dayCount: Int!
    $dayType: ApprovalTimeType
    $minCount: Int
    $minType: ApprovalTimeType
  ) {
    addApprovalNotificationSchedule(
      templateIdx: $templateIdx
      categoryId: $categoryId
      isApp: $isApp
      isMail: $isMail
      approvalTimeType: $approvalTimeType
      dayCount: $dayCount
      dayType: $dayType
      minCount: $minCount
      minType: $minType
    ) {
      ok
      error
      id
    }
  }
`;

const MUTATION_UPDATE_APPROVAL_NOTIFICATION_SCHEDULE = gql`
  mutation UpdateApprovalNotificationSchedule(
    $templateIdx: Int!
    $categoryId: Int!
    $isApp: IsUsingNotification!
    $isMail: IsUsingNotification!
    $approvalTimeType: ApprovalTimeType!
    $dayCount: Int!
    $scheduleIdx: Int!
    $dayType: ApprovalTimeType
    $minCount: Int
    $minType: ApprovalTimeType
  ) {
    updateApprovalNotificationSchedule(
      templateIdx: $templateIdx
      categoryId: $categoryId
      isApp: $isApp
      isMail: $isMail
      approvalTimeType: $approvalTimeType
      dayCount: $dayCount
      scheduleIdx: $scheduleIdx
      dayType: $dayType
      minCount: $minCount
      minType: $minType
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_APPROVAL_NOTIFICATION_SCHEDULE = gql`
  mutation DeleteApprovalNotificationSchedule($scheduleIdx: Int!) {
    deleteApprovalNotificationSchedule(scheduleIdx: $scheduleIdx) {
      ok
      error
    }
  }
`;

function ApprovalNotificationScheduleEditor({ selectedFlatRows }: IProps) {
  const {
    categorySelectionReducer: { categories }
  } = useSelector((state: Reducers) => state);

  const {
    isOpen,
    handleIsOpen,
    message,
    toastMessageType,
    handleMessage,
    handleToastMessageType
  } = useOpenToastMessage();

  const [deleteApprovalNotificationSchedule] = useMutation<
    DeleteApprovalNotificationSchedule,
    DeleteApprovalNotificationScheduleVariables
  >(MUTATION_DELETE_APPROVAL_NOTIFICATION_SCHEDULE, {
    update(cache, { data }, { variables }) {
      if (data?.deleteApprovalNotificationSchedule.ok && variables) {
        cache.evict({
          id: `ApprovalNotificationScheduleByTemplate:${variables.scheduleIdx}`
        });
        handleMessage(`성공적으로 선택한 알림 계획을 삭제 하셨습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (data?.deleteApprovalNotificationSchedule.error) {
        handleMessage(data?.deleteApprovalNotificationSchedule.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpen(true);
    }
  });

  const [updateApprovalNotificationSchedule] = useMutation<
    UpdateApprovalNotificationSchedule,
    UpdateApprovalNotificationScheduleVariables
  >(MUTATION_UPDATE_APPROVAL_NOTIFICATION_SCHEDULE, {
    update(cache, { data }, { variables }) {
      if (data?.updateApprovalNotificationSchedule.ok && variables) {
        cache.modify({
          id: `ApprovalNotificationScheduleByTemplate:${variables.scheduleIdx}`,
          fields: {
            categoryId() {
              return variables.categoryId;
            },
            isApp() {
              return variables.isApp;
            },
            isMail() {
              return variables.isMail;
            },
            approvalTimeType() {
              return variables.approvalTimeType;
            },
            dayCount() {
              return variables.dayCount;
            },
            dayType() {
              return variables.dayType;
            },
            minCount() {
              return variables.minCount;
            },
            minType() {
              return variables.minType;
            }
          }
        });
        handleMessage(`성공적으로 선택한 알림 계획을 수정 하셨습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (data?.updateApprovalNotificationSchedule.error) {
        handleMessage(data?.updateApprovalNotificationSchedule.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpen(true);
    }
  });

  const [addApprovalNotificationSchedule] = useMutation<
    AddApprovalNotificationSchedule,
    AddApprovalNotificationScheduleVariables
  >(MUTATION_ADD_APPROVAL_NOTIFICATION_SCHEDULE, {
    update(cache, { data }, { variables }) {
      if (
        data?.addApprovalNotificationSchedule.ok &&
        data.addApprovalNotificationSchedule.id &&
        variables?.templateIdx &&
        variables.dayType &&
        variables.minCount &&
        variables.minType
      ) {
        const newSchedule: GetListOfApprovalNotificationScheduleByTemplate_getListOfApprovalNotificationScheduleByTemplate_list =
          {
            templateIdx: variables?.templateIdx,
            scheduleIdx: data.addApprovalNotificationSchedule.id,
            categoryId: variables?.categoryId,
            isApp: variables?.isApp,
            isMail: variables?.isMail,
            approvalTimeType: variables?.approvalTimeType,
            dayCount: variables.dayCount,
            dayType: variables.dayType,
            minCount: variables.minCount,
            minType: variables.minType,
            __typename: "ApprovalNotificationScheduleByTemplate"
          };

        const newCacheSchedule = cache.writeFragment({
          id: `ApprovalNotificationScheduleByTemplate:${data.addApprovalNotificationSchedule.id}`,
          fragment: gql`
            fragment add on ApprovalNotificationScheduleByTemplate {
              templateIdx
              scheduleIdx
              categoryId
              isApp
              isMail
              approvalTimeType
              dayCount
              dayType
              minCount
              minType
              __typename
            }
          `,
          data: {
            ...newSchedule
          }
        });
        cache.modify({
          id: `GetListOfApprovalNotificationScheduleByTemplateOutput`,
          fields: {
            list(prev) {
              if (prev) {
                return [...prev, newCacheSchedule];
              }
              return [newCacheSchedule];
            }
          }
        });
        handleMessage(`성공적으로 알림 계획을 추가하셨습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
      } else if (data?.addApprovalNotificationSchedule.error) {
        handleMessage(data.addApprovalNotificationSchedule.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpen(true);
    }
  });

  const { selectedTemplate } = useContext(ApprovalNotificationManagementCtx);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<IFieldValues>({
    mode: "onChange",
    defaultValues: {
      categoryId: categories[0].category_id.toString(),
      approvalTimeType: ApprovalTimeType.START,
      minType: ApprovalTimeType.START,
      dayType: ApprovalTimeType.START
    }
  });

  const handleAdd = useCallback(
    (data: IFieldValues) => {
      if (
        selectedTemplate &&
        data.categoryId &&
        data.approvalTimeType &&
        data.dayCount
      ) {
        addApprovalNotificationSchedule({
          variables: {
            templateIdx: selectedTemplate.templateIdx,
            categoryId: parseInt(data.categoryId),
            isApp: data.isApp
              ? IsUsingNotification.TRUE
              : IsUsingNotification.FALSE,
            isMail: data.isMail
              ? IsUsingNotification.TRUE
              : IsUsingNotification.FALSE,
            approvalTimeType: data.approvalTimeType,
            dayCount: parseInt(data.dayCount),
            dayType: data.dayType,
            minCount: parseInt(data.minCount),
            minType: data.minType
          }
        });
      }
    },
    [selectedTemplate, addApprovalNotificationSchedule]
  );
  const handleUpdate = useCallback(
    (data: IFieldValues) => {
      if (
        selectedFlatRows.length > 0 &&
        data.categoryId &&
        data.approvalTimeType
      ) {
        updateApprovalNotificationSchedule({
          variables: {
            templateIdx: selectedFlatRows[0].original.templateIdx,
            scheduleIdx: selectedFlatRows[0].original.scheduleIdx,
            categoryId: parseInt(data.categoryId),
            isApp: data.isApp
              ? IsUsingNotification.TRUE
              : IsUsingNotification.FALSE,
            isMail: data.isMail
              ? IsUsingNotification.TRUE
              : IsUsingNotification.FALSE,
            approvalTimeType: data.approvalTimeType,
            dayCount: parseInt(data.dayCount),
            dayType: data.dayType,
            minCount: parseInt(data.minCount),
            minType: data.minType
          }
        });
      }
    },
    [updateApprovalNotificationSchedule, selectedFlatRows]
  );
  const handleDelete = useCallback(
    (data: IFieldValues) => {
      if (selectedFlatRows.length > 0) {
        deleteApprovalNotificationSchedule({
          variables: {
            scheduleIdx: selectedFlatRows[0].original.scheduleIdx
          }
        });
      }
    },
    [deleteApprovalNotificationSchedule, selectedFlatRows]
  );

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      if (selectedFlatRows[0].original.categoryId) {
        setValue(
          "categoryId",
          selectedFlatRows[0].original.categoryId.toString()
        );
      }
      setValue("minType", selectedFlatRows[0].original.minType);
      if (selectedFlatRows[0].original.minCount) {
        setValue("minCount", selectedFlatRows[0].original.minCount.toString());
      }
      if (selectedFlatRows[0].original.dayCount) {
        setValue("dayCount", selectedFlatRows[0].original.dayCount.toString());
      }
      setValue("dayType", selectedFlatRows[0].original.dayType);
      setValue(
        "isApp",
        selectedFlatRows[0].original.isApp === IsUsingNotification.TRUE
          ? true
          : false
      );
      setValue(
        "isMail",
        selectedFlatRows[0].original.isMail === IsUsingNotification.TRUE
          ? true
          : false
      );
      setValue(
        "approvalTimeType",
        selectedFlatRows[0].original.approvalTimeType
      );
    }
  }, [selectedFlatRows, setValue]);

  const { isApp, isMail, dayCount, minCount } = watch();

  const isDisabled = useMemo(() => {
    return (isApp || isMail) && dayCount && minCount;
  }, [isApp, isMail, dayCount, minCount]);

  return (
    <Container>
      <EditorContainer>
        <ItemContainer>
          <label htmlFor="categoryId">결재종류</label>
          <Select id="categoryId" {...register("categoryId")}>
            {categories.map(({ category_id, name }) => (
              <option value={category_id} key={Symbol(category_id).toString()}>
                {name}
              </option>
            ))}
          </Select>
        </ItemContainer>
        <ItemContainer>
          <label htmlFor="approvalTimeType">요청시간</label>
          <Select {...register("approvalTimeType")} id="approvalTimeType">
            <option value={ApprovalTimeType.START}>시작시간</option>
            <option value={ApprovalTimeType.END}>종료시간</option>
          </Select>
          <ItemContainer>
            <InputContainer>
              <SInput
                type="number"
                id="dayCount"
                {...register("dayCount", {
                  min: 0,
                  required: "날짜를 선택해주세요"
                })}
                min={0}
                hasError={Boolean(errors.dayCount?.message)}
              />
              <FormError message={errors.dayCount?.message} />
            </InputContainer>
            <label htmlFor="dayType">일</label>
            <Select {...register("dayType")}>
              <option value={ApprovalTimeType.START}>전</option>
              <option value={ApprovalTimeType.END}>후</option>
            </Select>
          </ItemContainer>
          <ItemContainer>
            <InputContainer>
              <SInput
                type="number"
                id="minCount"
                {...register("minCount", {
                  min: 0,
                  required: "시간을 정해주세요"
                })}
                min={0}
                hasError={Boolean(errors.minCount?.message)}
              />
              <FormError message={errors.minCount?.message} />
            </InputContainer>
            <label htmlFor="minCount">분</label>
            <Select {...register("minType")}>
              <option value={ApprovalTimeType.START}>전</option>
              <option value={ApprovalTimeType.END}>후</option>
            </Select>
          </ItemContainer>
        </ItemContainer>
        <ItemContainer>
          <label>알림대상</label>
          <CheckBox {...register("isApp")} title="App" />
          <CheckBox {...register("isMail")} title="Mail" />
        </ItemContainer>
      </EditorContainer>
      <ButtonSection>
        <AsonicIconButton
          icon={AddIcon}
          disabled={isDisabled ? false : true}
          onClick={handleSubmit(handleAdd)}
        />
        <AsonicIconButton
          icon={EditIcon}
          disabled={!isDisabled || selectedFlatRows.length < 1 ? true : false}
          onClick={handleSubmit(handleUpdate)}
        />
        <AsonicIconButton
          icon={DeleteIcon}
          disabled={!isDisabled || selectedFlatRows.length < 1 ? true : false}
          onClick={handleSubmit(handleDelete)}
        />
      </ButtonSection>
      <ToastMessage
        messageTypes={toastMessageType}
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
}

export default ApprovalNotificationScheduleEditor;
