import React, { useCallback } from "react";
import moment from "moment";
import { FixedSizeList as List } from "react-window";
import { GoogleEvent } from "../../../../hooks/link-google-hooks/use-google-calendar";
import CalendarContext from "../../asonic-calendar-context";
import CellSchduleSection from "../cell-schedule-section";
import useGoogleRole from "../../../../hooks/link-google-hooks/use-google-role/use-google-role";
import styled from "styled-components";
import useCalendarEventList from "../../../../hooks/calendar-hooks/use-calendar-event-list/use-calendar-event-list";

type Row = {
  index: number;
  style: object;
};

type Props = {
  fullDate?: string;
  cellData: Partial<DayInfo>;
};

const RowContainer = styled.div``;

const AsonicExtraSchedule = <P extends Props>({ fullDate, cellData }: P) => {
  const {
    googleCalendarlist,
    googleEventOfList,
    listOfGoogleColor,
    handleSelectedEvent,
    handleOpenDialog,
    handleDialogTitle,
    currentMoment
  } = React.useContext(CalendarContext);

  const { eventList } = useCalendarEventList({
    googleCalendarlist,
    googleEventOfList,
    listOfGoogleColor,
    cellData,
    currentMoment
  });
  const { checkWriterOrOwner } = useGoogleRole();
  const Row = useCallback(
    ({ index, style }: Row) => (
      <RowContainer style={style}>
        <CellSchduleSection
          scheduleName={eventList[index].scheduleName}
          totalTime={eventList[index].totalTime}
          isExtra={true}
          backgroundColor={eventList[index].backgroundColor}
          labelColor={eventList[index].labelColor}
          onClick={event => {
            event.stopPropagation();
            const isOwnerOrWriter = checkWriterOrOwner({
              googleCalendarlist,
              googleEvent: eventList[index].original as GoogleEvent
            });
            if (handleDialogTitle && fullDate && isOwnerOrWriter) {
              handleDialogTitle(
                moment(fullDate).format("YYYY년 MMMM D일 dddd")
              );
              handleSelectedEvent(eventList[index].original as GoogleEvent);
              handleOpenDialog(true);
            }
          }}
        />
      </RowContainer>
    ),
    [
      eventList,
      handleSelectedEvent,
      handleOpenDialog,
      handleDialogTitle,
      fullDate,
      googleCalendarlist,
      checkWriterOrOwner
    ]
  );

  return (
    <List height={80} itemCount={eventList.length} itemSize={30} width="100%">
      {Row}
    </List>
  );
};

export default AsonicExtraSchedule;
