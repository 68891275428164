import uiString from "../MobileRequestDialogView/string.json";
import Button from "../../../../components/globalComponents/Button";
import AsonicDialog from "../../../../components/asonic-dialog/asonic-dialog";
import styled from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Description = styled.span`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  gap: 10px;
`;

const MobileRequestAlert = ({ alertOpen, handleAlertOpen, handleSave }) => {
  return (
    <AsonicDialog
      open={alertOpen}
      handleClose={handleAlertOpen}
      width="300px"
      minWidth="300px"
      height="100px"
      minHeight="100px"
      title={uiString.dialog.title.request}
    >
      <ContentContainer>
        <Description>{uiString.alert.save}</Description>
        <ButtonContainer>
          <Button onClick={handleAlertOpen}>취소</Button>
          <Button onClick={handleSave}>확인</Button>
        </ButtonContainer>
      </ContentContainer>
    </AsonicDialog>
  );
};

export default MobileRequestAlert;
