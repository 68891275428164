import moment from "moment";
import styled from "styled-components";
import StyleInput from "../../inputs/style-input";
import { DateContainer, Term } from "./styled";

interface IProps {
  title?: string;
  startDate?: string | moment.Moment | null;
  startOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  endDate?: string | moment.Moment | null;
  endOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Label = styled.label`
  min-width: max-content;
`;

function Date(props: IProps) {
  const { title, startDate, startOnChange, endDate, endOnChange } = props;

  const START = moment(startDate).format("YYYY-MM-DD");
  const END = moment(endDate || moment()).format("YYYY-MM-DD");
  return (
    <DateContainer>
      {title && <Label htmlFor={title}>{title}</Label>}
      <StyleInput
        type="date"
        value={START}
        onChange={startOnChange}
        id={title}
      />
      <Term>~</Term>
      <StyleInput type="date" value={END} onChange={endOnChange} />
    </DateContainer>
  );
}

export default Date;
