import { useForm } from "react-hook-form";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import {
  IEmployeeInformationForReceiveAndReference,
  ReceiveAndReferenceType
} from "./list-of-selected-approval-user-for-receive-and-reference-form";

interface IProps {
  cell: Cell<IEmployeeInformationForReceiveAndReference>;
  handleChangeUserInfoInListOfApprovalUser: (
    payload: IEmployeeInformationForReceiveAndReference
  ) => void;
}

function ApprovalReceiveReferenceSelect({
  cell,
  handleChangeUserInfoInListOfApprovalUser
}: IProps) {
  const { register } = useForm<{
    approvalUserType: ReceiveAndReferenceType;
  }>({
    mode: "onSubmit",
    defaultValues: {
      approvalUserType: cell.value
    }
  });
  const selectRegister = register("approvalUserType", {
    required: true
  });
  return (
    <select
      {...selectRegister}
      onChange={event => {
        selectRegister.onChange(event);
        const newUser: IEmployeeInformationForReceiveAndReference = {
          ...cell.row.original,
          type: event.target.value as ReceiveAndReferenceType
        };
        const payload: IEmployeeInformationForReceiveAndReference = newUser;
        handleChangeUserInfoInListOfApprovalUser(payload);
      }}
    >
      <option value={ReceiveAndReferenceType.RECEIVE}>수신</option>
      <option value={ReceiveAndReferenceType.REFERENCE}>참조</option>
    </select>
  );
}

export default ApprovalReceiveReferenceSelect;
