import { useEffect, useMemo, useState } from "react";
import ContentContainer from "../../../shared/content_container/content_container";
import DisplayTimeGraph, {
  ITime
} from "../../../time_graph/display_time_graph/display_time_graph";
import { COLOR_TYPE } from "../../../time_graph/time_graph_box/time_graph_box";
import {
  DayGraphEntity,
  OtSelfWorkingStandardHoursEntity,
  SelfDeterminedWeekGraphEntity,
  Work_Day_Of_Week,
  Work_Hours_Setting_Type,
  Work_Type,
  useGetSelfDeterminedDayGraphLazyQuery
} from "../../../../generated/graphql";
import * as ReactTable from "react-table";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";
import moment from "moment";
interface IProps {
  selectedTemplate?: ReactTable.Row<OtSelfWorkingStandardHoursEntity>;
  selectedDay?: SelfDeterminedWeekGraphEntity;
}

function ChoiceHourGraph({ selectedTemplate, selectedDay }: IProps) {
  const [dayGraph, setDayGraph] = useState<DayGraphEntity>();
  const [startHour, setStartHour] = useState<string>("00:00");
  const [endHour, setEndHour] = useState<string>("00:00");

  const [startBreakHour, setStartBreakHour] = useState<string>("00:00");
  const [endBreakHour, setEndBreakHour] = useState<string>("00:00");

  const [getSelfDeterminedDayGraph] = useGetSelfDeterminedDayGraphLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getSelfDeterminedDayGraph.ok &&
        data.getSelfDeterminedDayGraph.dayGraph
      ) {
        const { dayGraph } = data.getSelfDeterminedDayGraph;
        setDayGraph(dayGraph);
        if (dayGraph.startTime && dayGraph.endTime) {
          const start = moment(dayGraph.startTime, "HH:mm");
          const end = moment(dayGraph.endTime, "HH:mm");
          setStartHour(start.format("HH:mm"));
          setEndHour(end.format("HH:mm"));
        }

        const startBreak = moment(dayGraph.startBreakTime, "HH:mm");
        const endBreak = moment(dayGraph.endBreakTime, "HH:mm");
        setStartBreakHour(startBreak.format("HH:mm"));
        setEndBreakHour(endBreak.format("HH:mm"));
      } else if (
        !data.getSelfDeterminedDayGraph.ok &&
        data.getSelfDeterminedDayGraph.error
      ) {
        handleErrorLog(data.getSelfDeterminedDayGraph.error);
      }
    }
  });

  const list: ITime[] = useMemo(() => {
    let startHour = 0;
    let endHour = 0;
    let startBreakHour = 0;
    let endBreakHour = 0;
    if (dayGraph) {
      if (dayGraph.startTime) {
        startHour = Number(dayGraph.startTime?.split(":")[0]);
      }
      if (dayGraph.endTime) {
        endHour = Number(dayGraph.endTime?.split(":")[0]);
      }
      startBreakHour = Number(dayGraph.startBreakTime.split(":")[0]);
      endBreakHour = Number(dayGraph.endBreakTime.split(":")[0]);
    }
    const newList: ITime[] = [];
    for (let i = 0; i < 24; i++) {
      const min = i === 0 ? 60 * 12 : i * 60;
      const type = Work_Type.SFCommutingPolicy;
      let colorType: COLOR_TYPE = COLOR_TYPE.OFF_DUTY_HOURS;

      // 시간이 다음 날로 넘어가는 경우 처리
      if (startHour > endHour) {
        if (i >= startHour || i < endHour) {
          colorType = COLOR_TYPE.WORKING_HOURS;
        }
      } else {
        if (i >= startHour && i < endHour) {
          colorType = COLOR_TYPE.WORKING_HOURS;
        }
      }

      if (i >= startBreakHour && i < endBreakHour) {
        colorType = COLOR_TYPE.BREAK_TIME;
      }
      newList.push({
        title: `${i}`,
        min,
        type,
        colorType
      });
    }
    return newList;
  }, [dayGraph]);

  const labelList = useMemo(() => {
    return [
      {
        labelName: "근무외시간",
        colorType: COLOR_TYPE.OFF_DUTY_HOURS
      },
      {
        labelName: "출근가능시간",
        colorType: COLOR_TYPE.WORKING_HOURS
      },
      {
        labelName: "휴게시간",
        colorType: COLOR_TYPE.BREAK_TIME
      }
    ];
  }, []);

  const description = useMemo(() => {
    if (
      selectedTemplate?.original.dayOfWeekType ===
        Work_Day_Of_Week.SelfForWorker ||
      selectedTemplate?.original.workHoursType ===
        Work_Hours_Setting_Type.SelfForWorker
    ) {
      return "근무제 자율편성";
    }
    return `근무가능시간 : ${startHour} ~ ${endHour}, 휴게시간 : ${startBreakHour} ~ ${endBreakHour}`;
  }, [startHour, endHour, startBreakHour, endBreakHour, selectedTemplate]);

  useEffect(() => {
    if (
      selectedDay &&
      selectedTemplate?.original.workingTemplateIdx &&
      selectedDay.dayOfWeek
    ) {
      getSelfDeterminedDayGraph({
        variables: {
          workingTemplateIdx: selectedTemplate.original.workingTemplateIdx,
          workingTimeName: selectedTemplate.original.workingTimeName,
          dayOfWeek: selectedDay.dayOfWeek
        }
      });
    }
  }, [getSelfDeterminedDayGraph, selectedTemplate, selectedDay]);
  return (
    <ContentContainer>
      <DisplayTimeGraph
        list={list}
        labelList={labelList}
        description={{
          description
        }}
        isBorder={false}
      />
    </ContentContainer>
  );
}

export default ChoiceHourGraph;
