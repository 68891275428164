import styled from "styled-components";

export const SLink = styled.a``;

export const Table = styled.table`
  border-spacing: 0;
  border-bottom: 1px solid black;
  width: 100%;
`;

export const Thead = styled.thead`
  background-color: #4193cc;
  color: white;
  white-space: nowrap;
`;

export const Tbody = styled.tbody`
  white-space: nowrap;
`;

export const Tr = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
    }
  }
  :hover {
    td {
      background-color: #9fb4ff;
      color: black;
    }
  }
`;

export const Td = styled.td`
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background-color: ${props =>
    props.selectedItems.includes(props.selectedItem) ? "#70a1ff" : "white"};
  color: ${props =>
    props.selectedItems.includes(props.selectedItem) ? "white" : "black"};
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #636e72;
  border-right: 1px solid #636e72;
  text-align: center;
  :last-child {
    border-right: 0;
  }
`;

export const Th = styled.th`
  margin: 0;
  border-bottom: 1px solid #636e72;
  border-right: 1px solid white;
  :last-child {
    border-right: 0;
  }
`;
