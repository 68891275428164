import { atom } from "recoil";
import {
  ApprovalLineEntity,
  ApprovalTemplateEntity,
  UserEntity
} from "../../../../generated/graphql";
import moment from "moment";
import { IMApprovalUser } from "./m_request_approval_line";

interface IMRAStore {
  user?: UserEntity;
  template?: ApprovalTemplateEntity;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  title: string;
  description: string;
  approvalLine?: ApprovalLineEntity;
  approvalLineUser: IMApprovalUser[];
}

const mRAStore = atom<IMRAStore>({
  key: "mRAStore", // unique ID (with respect to other atoms/selectors)
  default: {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    startTime: "00:00:00",
    endTime: "23:59:59",
    title: "",
    description: "",
    approvalLineUser: []
  } // default value (aka initial value)
});

export default mRAStore;
