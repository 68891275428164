import { useState } from "react";
import { crc16xmodem } from "crc";
import moment from "moment";

const useCreateEmergencyKey = () => {
  const [isEmergencyKeyDialog, setIsEmergencyKeyDialog] = useState(false),
    [dateValue, setDateValue] = useState(moment().format("YYYY-MM-DD")),
    [firstTime, setFirstTime] = useState(moment().format("HH:mm")),
    [lastTime, setLastTime] = useState(moment().add(1, "h").format("HH:mm")),
    [emergencyKey, setEmergencyKey] = useState("");

  const handleOpenEmergencyKeyDialog = isOpen => () => {
    setIsEmergencyKeyDialog(isOpen);
    if (!isOpen) {
      setEmergencyKey("");
    }
  };

  const handleDateValue = event => {
    setDateValue(event.target.value);
  };

  const handleFirstTime = event => {
    setFirstTime(event.target.value);
  };

  const handleLastTime = event => {
    setLastTime(event.target.value);
  };

  const handleEmergencyKey = id => () => {
    if (id && dateValue && firstTime && lastTime) {
      const FIRST_TIME = `${firstTime.split(":")[0]}${firstTime.split(":")[1]}`;
      const LAST_TIME = `${lastTime.split(":")[0]}${lastTime.split(":")[1]}`;
      const newFormatDateValue = moment(dateValue, "YYYY-MM-DD").format(
        "YYYYMMDD"
      );
      const Key = `${FIRST_TIME}${LAST_TIME}${crc16xmodem(
        `${id}${newFormatDateValue}${FIRST_TIME}${LAST_TIME}`
      ).toString(10)}`;
      setEmergencyKey(Key);
    }
  };

  return {
    isEmergencyKeyDialog,
    handleOpenEmergencyKeyDialog,
    dateValue,
    handleDateValue,
    firstTime,
    handleFirstTime,
    lastTime,
    handleLastTime,
    emergencyKey,
    handleEmergencyKey
  };
};

export default useCreateEmergencyKey;
