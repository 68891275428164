import api from "../api";

const MAINVIEW = `${api.api}/mainview`;
const WORKING_HOURS = `${MAINVIEW}/workinghours`;
const WORKING_INFO = `${MAINVIEW}/workinginfo`;
const SET_WORKING_HOUR = `${MAINVIEW}/setworkinghour`;

const mapViewApi = {
  mainView: MAINVIEW,
  workingHours: WORKING_HOURS,
  workingInfo: WORKING_INFO,
  setWorkinghour: SET_WORKING_HOUR
};

export default mapViewApi;
