import styled from "styled-components";

export interface IFixedColumnDropbox {
  fixedColumnNumber: string[];
  selectedFixedColumnNumber: string;
  handleSelectedFCN: (payload: string) => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  label {
    font-weight: bold;
  }
`;

function FixedColumnDropbox({
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN
}: IFixedColumnDropbox) {
  return (
    <Container>
      <label>{`틀 고정`}</label>
      <select
        value={selectedFixedColumnNumber}
        onChange={event => {
          handleSelectedFCN(event.currentTarget.value);
        }}
      >
        {fixedColumnNumber.map((item, index) => (
          <option value={item} key={`${item}-${index}`}>
            {item}
          </option>
        ))}
      </select>
    </Container>
  );
}

export default FixedColumnDropbox;
// table의 컬럼을 고정하는것을 몇개까지 설정하는지에 대한 컴포넌트
