import { useCallback, useMemo } from "react";
import { COLOR_TYPE } from "../../../components/time_graph/time_graph_box/time_graph_box";
import { IDay } from "../../../components/time_graph/display_month_graph/display_month_graph";
import moment from "moment";
import {
  Day_Type,
  Work_Type,
  WtGraphWeekEntity
} from "../../../generated/graphql";

interface IProps {
  weekDayOfWeekInfo: WtGraphWeekEntity[];
  workType?: Work_Type;
  selectedDayIndex?: number;
}

function useWorkingTemplateGraph({
  weekDayOfWeekInfo,
  workType,
  selectedDayIndex
}: IProps) {
  const chooseColor = useCallback((value: number) => {
    const key = `LEVEL${value.toFixed()}`;
    return COLOR_TYPE[key as COLOR_TYPE];
  }, []);

  const parseDayToNumber = useCallback((day: Day_Type) => {
    let dayNum = 2;
    switch (day) {
      case Day_Type.Tue:
        dayNum = 3;
        break;
      case Day_Type.Wed:
        dayNum = 4;
        break;
      case Day_Type.Thr:
        dayNum = 5;
        break;
      case Day_Type.Fri:
        dayNum = 6;
        break;
      case Day_Type.Sat:
        dayNum = 7;
        break;
      case Day_Type.Sun:
        dayNum = 1;
        break;
    }
    return dayNum;
  }, []);

  const month: IDay[] = useMemo(() => {
    const weekDays: IDay[] = [];
    let startIndex = 1;
    weekDayOfWeekInfo.map(item => {
      const dayNum = parseDayToNumber(item.dayOfWeek);
      if (startIndex < dayNum) {
        for (startIndex; startIndex < dayNum; startIndex++) {
          const date = moment().day(startIndex - 1);
          const day = date.locale("ko").format("dddd").charAt(0); // 한국어로 변경
          const min = 0;
          const newDay = {
            title: day,
            min,
            isSat: day === "토",
            isSun: day === "일",
            colorType: chooseColor(0),
            isDisabledTitle: weekDays.length > 6,
            isToday: true,
            selected: selectedDayIndex === weekDays.length,
            type: workType ?? Work_Type.FlexibleWorkingHoursPolicy
          };
          weekDays.push(newDay);
        }
      }
      if (startIndex > dayNum) {
        for (startIndex; startIndex <= 7; startIndex++) {
          const date = moment().day(startIndex - 1);
          const day = date.locale("ko").format("dddd").charAt(0); // 한국어로 변경
          const min = 0;
          const newDay = {
            title: day,
            min,
            isSat: day === "토",
            isSun: day === "일",
            colorType: chooseColor(0),
            isDisabledTitle: weekDays.length > 6,
            isToday: true,
            selected: selectedDayIndex === weekDays.length,
            type: workType ?? Work_Type.FlexibleWorkingHoursPolicy
          };
          weekDays.push(newDay);
        }
        if (startIndex > 7) {
          startIndex = 1;
          if (startIndex < dayNum) {
            for (startIndex; startIndex < dayNum; startIndex++) {
              const date = moment().day(startIndex - 1);
              const day = date.locale("ko").format("dddd").charAt(0); // 한국어로 변경
              const min = 0;
              const newDay = {
                title: day,
                min,
                isSat: day === "토",
                isSun: day === "일",
                colorType: chooseColor(0),
                isDisabledTitle: weekDays.length > 6,
                isToday: true,
                selected: selectedDayIndex === weekDays.length,
                type: workType ?? Work_Type.FlexibleWorkingHoursPolicy
              };
              weekDays.push(newDay);
            }
          }
        }
      }
      if (startIndex < 7) {
        startIndex += 1;
      } else {
        startIndex = 1;
      }
      const date = moment().day(dayNum - 1);
      const day = date.locale("ko").format("dddd").charAt(0); // 한국어로 변경
      let min = item.totHour ? item.totHour * 60 : 0;
      if (item.totHour && item.totHour < 0) {
        min = 0;
      }
      const newDay = {
        title: day,
        min,
        isSat: day === "토",
        isSun: day === "일",
        colorType: chooseColor(
          item?.totHour && item?.totHour >= 0 ? item?.totHour : 0
        ),
        isDisabledTitle: weekDays.length > 6,
        isToday: true,
        selected: selectedDayIndex === weekDays.length,
        type: workType ?? Work_Type.FlexibleWorkingHoursPolicy
      };
      weekDays.push(newDay);
    });
    // 만약 7일로 나누어 떨어지지 않는다면 등록되지 않은 날짜 임시로 등록해주기
    if (weekDays.length % 7 !== 0) {
      let conditionNumber = 7 - (weekDays.length % 7);
      for (conditionNumber; conditionNumber > 0; conditionNumber--) {
        const date = moment().day(startIndex - 1);
        const day = date.locale("ko").format("dddd").charAt(0); // 한국어로 변경
        const min = 0;
        const newDay = {
          title: day,
          min,
          isSat: day === "토",
          isSun: day === "일",
          colorType: chooseColor(0),
          isDisabledTitle: weekDays.length > 6,
          isToday: true,
          selected: selectedDayIndex === weekDays.length,
          type: workType ?? Work_Type.FlexibleWorkingHoursPolicy
        };
        weekDays.push(newDay);
      }
    }
    return weekDays;
  }, [
    chooseColor,
    workType,
    weekDayOfWeekInfo,
    parseDayToNumber,
    selectedDayIndex
  ]);

  return { month };
}

export default useWorkingTemplateGraph;
