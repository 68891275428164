import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Ul = styled.ul`
  flex: 1;
  margin: 0;
  padding-left: 10px;
`;

export const Li = styled.li`
  list-style: none;
  font-weight: bolder;
  padding: 8px 0px 8px 0px;
  color: #a5a9ab;
  :first-child {
    color: #8486bf;
    border-bottom: 1px solid #a5a9ab;
  }
  :before {
    content: "·";
    font-size: 50px;
    vertical-align: text-top;
    line-height: 10px;
  }
`;

export const Description = styled.span`
  color: #a5a9ab;
  font-weight: normal;
`;
