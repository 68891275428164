import { useReactiveVar } from "@apollo/client";
import handleApolloErrorLog from "../../../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../../../Utils/handle_error_log/handle_error_log";
import {
  useGetUserPolicyBasicInfoLazyQuery,
  UserPolicyBasicInfoEntity
} from "../../../../../../generated/graphql";
import useWorkingTemplate from "../../../../../../hooks/use-working-template/use-working-template";
import FormRow from "../../../../../shared/form-row/form-row";
import GridContentContainer from "../../../../../shared/grid_content_container/grid_content_container";
import SectionContainer from "../../../../../shared/section_container/section_container";
import SubTitle from "../../../../../shared/sub-title/sub-title";
import TitleContainer from "../../../../../shared/title_container/title_container";
import listOfApolloVar from "../../../../../../apollo/apollo-var";
import { Reducers } from "../../../../../../../types/reducers";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AsonicDialog from "../../../../../asonic-dialog/asonic-dialog";
import WorkSystemDialog from "../../../../../work_system_dialog/work_system_dialog";
import useOpenDialog from "../../../../../../hooks/use-open-dialog/use-open-dialog";
import styled from "styled-components";
import { Role } from "../../../../../../user-types";

const SSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

interface IProps {
  basicInfo?: UserPolicyBasicInfoEntity;
  setBasicInfo: React.Dispatch<
    React.SetStateAction<UserPolicyBasicInfoEntity | undefined>
  >;
}

function WorkingTemplateBs({ basicInfo, setBasicInfo }: IProps) {
  const {
    signInReducer: { loginIdInfo, employee_id }
  } = useSelector((state: Reducers) => state);

  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const { isOpen, handleOpenDialog } = useOpenDialog();

  const [dialogTitle, setDialogTitle] = useState<string>("표준");

  const [getUserPolicyBasicInfo] = useGetUserPolicyBasicInfoLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getUserPolicyBasicInfo.ok &&
        data.getUserPolicyBasicInfo.basicInfo
      ) {
        setBasicInfo(data.getUserPolicyBasicInfo.basicInfo);
      } else if (
        !data.getUserPolicyBasicInfo.ok &&
        data.getUserPolicyBasicInfo.error
      ) {
        handleErrorLog(data.getUserPolicyBasicInfo.error);
      }
    }
  });

  const {
    listOfWorkingTemplate,
    selectedWorkingTemplate,
    handleSelectWorkingTemplate
  } = useWorkingTemplate();

  useEffect(() => {
    if (basicInfo?.wType) {
      setDialogTitle(basicInfo?.wType.slice(0, 2));
    }
  }, [basicInfo]);

  useEffect(() => {
    listOfWorkingTemplate.forEach(item => {
      if (basicInfo?.workingTemplateIdx === item.working_templete_idx) {
        handleSelectWorkingTemplate(item);
      }
    });
  }, [
    basicInfo?.workingTemplateIdx,
    listOfWorkingTemplate,
    handleSelectWorkingTemplate
  ]);

  useEffect(() => {
    if (loginIdInfo === Role.USER || selectedListOfEmployeeId.length < 1) {
      getUserPolicyBasicInfo({
        variables: {
          employeeId: employee_id
        }
      });
    } else if (selectedListOfEmployeeId.length > 0) {
      const employeeId = selectedListOfEmployeeId.at(-1);
      if (employeeId) {
        getUserPolicyBasicInfo({
          variables: {
            employeeId
          }
        });
      }
    }
  }, [
    getUserPolicyBasicInfo,
    loginIdInfo,
    selectedListOfEmployeeId,
    employee_id
  ]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"기본 설정"} />
      </TitleContainer>
      <GridContentContainer flex={0}>
        <FormRow title="성명(아이디)" isCenter>
          <span>{basicInfo?.nameId ?? ""}</span>
        </FormRow>
        <FormRow title="부서명" isCenter>
          {basicInfo?.departName ?? ""}
        </FormRow>
        <FormRow title="근무관리정책" isCenter>
          <span>{selectedWorkingTemplate?.name ?? ""}</span>
          {/* <select
            value={selectedWorkingTemplate?.name ?? ""}
            onChange={event => {
              listOfWorkingTemplate.forEach(item => {
                if (event.currentTarget.value && item.working_templete_idx) {
                  if (
                    Number(event.currentTarget.value) ===
                    item.working_templete_idx
                  ) {
                    handleSelectWorkingTemplate(item);
                  }
                } else if (
                  !event.currentTarget.value &&
                  item.name === "미사용"
                ) {
                  handleSelectWorkingTemplate(item);
                }
              });
            }}
          >
            {listOfWorkingTemplate.map(item => (
              <option
                value={item.working_templete_idx ?? ""}
                key={item.working_templete_idx}
              >
                {item.name}
              </option>
            ))}
          </select> */}
        </FormRow>
        <FormRow title="근무관리정책 정보" isCenter>
          <span>{basicInfo?.wType ?? ""}</span>
        </FormRow>
        <FormRow title="근무가능시간" isCenter>
          <SSpan
            onClick={() => {
              handleOpenDialog(true);
            }}
          >
            {basicInfo?.possibleTime ?? ""}
          </SSpan>
        </FormRow>
        <FormRow title="" isCenter></FormRow>
      </GridContentContainer>
      {isOpen && (
        <AsonicDialog
          title={`${dialogTitle} 근무가능시간`}
          height={dialogTitle === "자율" ? "" : "400px"}
          minHeight={dialogTitle === "자율" ? "" : "400px"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
        >
          <WorkSystemDialog
            type={dialogTitle}
            workingTemplateIdx={
              basicInfo?.workingTemplateIdx
                ? Number(basicInfo?.workingTemplateIdx)
                : undefined
            }
          />
        </AsonicDialog>
      )}
    </SectionContainer>
  );
}

export default WorkingTemplateBs;
