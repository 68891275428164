import styled from "styled-components";

export const FileNameDisplayDiv = styled.div`
  display: flex;
  margin: 8px;
  padding-left: 8px;
  width: 280px;
  height: 32px;
  align-items: center;
  border: 2px dashed #f69c55;
`;

export const ControllersDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 220px;
`;
