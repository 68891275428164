import { Fragment, useEffect, useMemo, useState } from "react";
import useOpenDialog from "../../../../hooks/use-open-dialog";
import {
  IS_TRUTHY_FALSY,
  SEARCH_DB_TYPE_FOR_DASH_BOARD
} from "../../../../__generated__/globalTypes";
import SearchDashBoard from "../../../dash-board/search-dash-board/search-dash-board";
import {
  Container,
  TitleBar,
  Title,
  ContentBox,
  BackgroundContainer
} from "./styled";

type Props = {
  title: string;
  data: number;
  colors: string[];
};

const TodaySWorkStatusBox = <P extends Props>({ title, data, colors }: P) => {
  const { isOpen, handleOpenDialog } = useOpenDialog();

  const additionalSearchValueForTruthyFalsy = useMemo(
    () => IS_TRUTHY_FALSY.TRUTHY,
    []
  );

  const [todayStatusSearchType, setTodayStatusSearchType] = useState(
    SEARCH_DB_TYPE_FOR_DASH_BOARD.workOx
  );

  useEffect(() => {
    switch (title) {
      case "직원수":
        setTodayStatusSearchType(SEARCH_DB_TYPE_FOR_DASH_BOARD.all);
        break;
      case "정규근무":
        setTodayStatusSearchType(SEARCH_DB_TYPE_FOR_DASH_BOARD.workOx);
        break;
      case "연장근무":
        setTodayStatusSearchType(SEARCH_DB_TYPE_FOR_DASH_BOARD.nameOne);
        break;
      case "휴가":
        setTodayStatusSearchType(SEARCH_DB_TYPE_FOR_DASH_BOARD.nameTwo);
        break;
      case "기타":
        setTodayStatusSearchType(SEARCH_DB_TYPE_FOR_DASH_BOARD.nameThree);
        break;
    }
  }, [title]);

  return (
    <Fragment>
      <Container
        data-testid="today-s-work-status-box"
        colors={colors}
        onClick={event => {
          handleOpenDialog(true);
        }}
      >
        <BackgroundContainer colors={colors} />
        <ContentBox data-testid="data">{`${data}`}</ContentBox>
        <TitleBar>
          <Title data-testid="title">{title}</Title>
        </TitleBar>
      </Container>
      {isOpen && (
        <SearchDashBoard
          handleClose={handleOpenDialog}
          additionalSearchType={todayStatusSearchType}
          additionalSearchValueForTruthyFalsy={
            additionalSearchValueForTruthyFalsy
          }
        />
      )}
    </Fragment>
  );
};

export default TodaySWorkStatusBox;
