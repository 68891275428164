import * as React from "react";
import { SerializeItem, GoogleEvent } from "../use-google-calendar";

interface GoogleCalendarRolePayload {
  googleCalendarlist: SerializeItem[];
  googleEvent: GoogleEvent;
}

enum GoogleCalendarAcessRole {
  OWNER = "owner",
  WRITER = "writer",
  READER = "reader"
}

const useGoogleRole = () => {
  const findCalendarListById = React.useCallback(
    ({
      googleCalendarlist,
      googleEvent
    }: GoogleCalendarRolePayload): string => {
      const filteredList = googleCalendarlist.filter(item => {
        return item.origin.id === googleEvent?.organizer?.email;
      });
      return filteredList.length > 0 ? filteredList[0]?.origin?.accessRole : "";
    },
    []
  );

  const handleGoogleCalendarRole = React.useCallback(
    ({ googleCalendarlist, googleEvent }: GoogleCalendarRolePayload) => {
      return findCalendarListById({ googleCalendarlist, googleEvent });
    },
    [findCalendarListById]
  );

  const handleCalendarListByNotReader = React.useCallback(
    (googleCalendarList: SerializeItem[]) => {
      const newGoogleCalendarList = googleCalendarList.filter(item => {
        return item.origin.accessRole !== GoogleCalendarAcessRole.READER;
      });
      return newGoogleCalendarList;
    },
    []
  );

  const checkWriterOrOwner = React.useCallback(
    (payload: GoogleCalendarRolePayload): boolean => {
      const role = handleGoogleCalendarRole(payload);
      if (
        role === GoogleCalendarAcessRole.OWNER ||
        role === GoogleCalendarAcessRole.WRITER
      ) {
        return true;
      } else {
        return false;
      }
    },
    [handleGoogleCalendarRole]
  );
  return {
    handleGoogleCalendarRole,
    checkWriterOrOwner,
    handleCalendarListByNotReader
  };
};

export default useGoogleRole;
