import api from "../api";

const MAP = `${api.api}/map`;
const COMMUTE_AREA = `${MAP}/commute_area`;
const COMMUTE_AREA_USETYPE = `${MAP}/commute_area_usetype`;

const mapApi = {
  commuteArea: COMMUTE_AREA,
  commuteAreaUsetype: COMMUTE_AREA_USETYPE
};

export default mapApi;
