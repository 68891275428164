import styled from "styled-components";
import { Icon } from "@iconify/react";
import WindowCloseIcon from "@iconify/icons-fa-solid/window-close";
import listOfApolloVar, { CHAT_OPEN } from "../../apollo/apollo-var";
import {
  DEFAULT_PARENT_DEPARTMENT_ID,
  IDepartmentInfoForChat,
  LIST_OF_CHAT_MENU
} from "./chat";
import ChatRoomTitleBar from "./chat-room-title-bar";
import { useApolloClient } from "@apollo/client";

interface IProps {
  handleParentDepartment: (payload: IDepartmentInfoForChat) => void;
  menu: LIST_OF_CHAT_MENU;
  isElectron: boolean;
}

const Container = styled.div<{ menu: LIST_OF_CHAT_MENU; isElectron: boolean }>`
  display: flex;
  flex: 2;
  min-height: max-content;
  flex-direction: column;
  border-top-right-radius: ${props => (props.isElectron ? "0px" : "14px")};
  border-top-left-radius: ${props => (props.isElectron ? "0px" : "14px")};

  background-color: ${props =>
    props.menu === LIST_OF_CHAT_MENU.CHAT_ROOM
      ? props.theme.colors.cobaltBlue
      : props.theme.colors.white};
`;

const Title = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  padding: 10px 10px 0px 10px;
  svg {
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  color: ${props => props.theme.colors.tomato};
  :active {
    opacity: 0.8;
  }
`;

function ChatTitleBar({ handleParentDepartment, menu, isElectron }: IProps) {
  const client = useApolloClient();
  return (
    <Container menu={menu} isElectron={isElectron}>
      {!isElectron && (
        <ButtonsContainer>
          <ButtonContainer
            onClick={async () => {
              listOfApolloVar.chatOpenVar(CHAT_OPEN.CLOSE);
              handleParentDepartment({
                parentDepartmentId: DEFAULT_PARENT_DEPARTMENT_ID,
                selectedDepartmentName: "전체"
              });
              client.resetStore();
            }}
          >
            <Icon icon={WindowCloseIcon} />
          </ButtonContainer>
        </ButtonsContainer>
      )}
      <Title>
        {menu === LIST_OF_CHAT_MENU.CHAT && <h2>대화</h2>}
        {menu === LIST_OF_CHAT_MENU.GROUP && <h2>그룹</h2>}
        {menu === LIST_OF_CHAT_MENU.CHAT_ROOM && <ChatRoomTitleBar />}
      </Title>
    </Container>
  );
}

export default ChatTitleBar;
