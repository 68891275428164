import styled from "styled-components";
import { Icon } from "@iconify/react";
import UpIcon from "@iconify/icons-bx/up-arrow";
import DownIcon from "@iconify/icons-bx/down-arrow";

export const PositionControl = {
  TOP: "TOP",
  BOTTOM: "BOTTOM"
} as const;

export type TPositionControl = keyof typeof PositionControl;

interface IProps {
  handleChangeTheOrder: (type: TPositionControl) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled.button`
  cursor: pointer;
`;

function PositionController({ handleChangeTheOrder }: IProps) {
  return (
    <Container>
      <Button
        onClick={() => {
          handleChangeTheOrder(PositionControl.TOP);
        }}
      >
        <Icon icon={UpIcon} />
      </Button>
      <Button
        onClick={() => {
          handleChangeTheOrder(PositionControl.BOTTOM);
        }}
      >
        <Icon icon={DownIcon} />
      </Button>
    </Container>
  );
}

export default PositionController;
