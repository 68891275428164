import styled from "styled-components";
import TimeGraphBox, { COLOR_TYPE } from "../time_graph_box/time_graph_box";
import { useMemo } from "react";
import { Work_Type } from "../../../generated/graphql";

export interface IDay {
  title: string;
  min: number;
  isSat?: boolean;
  isSun?: boolean;
  isDisabledTitle?: boolean;
  colorType: COLOR_TYPE;
  isToday?: boolean;
  selected?: boolean;
  type: Work_Type;
}

interface IProps {
  month: IDay[];
  setSelectedDayIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const MonthContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
`;

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const RowTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin-top: 2px;
  :first-child {
    margin-top: 0px;
    height: 16px;
  }
  :nth-child(2) {
    margin-top: 0px;
  }
  font-weight: bold;
  color: ${props => props.theme.colors.grey};
`;

// const Description = styled.span`
//   display: flex;
//   flex: 1;
//   align-items: center;
//   justify-content: flex-end;
//   gap: 5px;
//   :before {
//     align-items: center;
//     display: flex;
//     content: "";
//     width: 7px;
//     height: 7px;
//     border-radius: 50%;
//     background-color: ${props => props.theme.colors.darkRed};
//   }
// `;

function DisplayMonthGraph({ month, setSelectedDayIndex }: IProps) {
  const dayRows = useMemo(() => {
    const newDayRows = [];
    const total = Math.round(month.length / 7) + 1;
    while (newDayRows.length < total) {
      if (newDayRows.length === 0) {
        newDayRows.push({
          title: "주"
        });
      } else {
        newDayRows.push({
          title: `${newDayRows.length}주`
        });
      }
    }
    return newDayRows;
  }, [month]);

  return (
    <Container>
      <ContentContainer>
        <RowContainer>
          {dayRows.map(item => (
            <RowTitle key={item.title}>{item.title}</RowTitle>
          ))}
        </RowContainer>
        <MonthContainer>
          {month.map((item, index) => (
            <TimeGraphBox
              key={`${item.min}_${item.title}_${index}`}
              title={item.title}
              min={item.min}
              type={item.type}
              colorType={item.colorType}
              isDay={true}
              isDisabledTitle={item.isDisabledTitle}
              isSun={item.isSun}
              isSat={item.isSat}
              isToday={item.isToday}
              selected={item.selected}
              handleSelect={() => {
                setSelectedDayIndex(index);
              }}
            />
          ))}
        </MonthContainer>
      </ContentContainer>
      {/* <Description>{`오늘: ${moment()
        .locale("ko")
        .format("YYYY-MM-DD(ddd)")}`}</Description> */}
    </Container>
  );
}

export default DisplayMonthGraph;
