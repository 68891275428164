import styled from "styled-components";

const THeader = styled.div<{
  $isOver?: boolean;
  $isFlex?: boolean;
  $isLastFlex?: boolean;
}>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.4rem 0px;
  cursor: grab;
  border-top: 2px solid ${props => props.theme.colors.darkGrey};
  border-bottom: 2px solid ${props => props.theme.colors.grey};
  background-color: ${props => {
    if (props.$isOver === false) {
      return props.theme.colors.tomato;
    }
    return props.theme.colors.lightGrey;
  }};
  flex: ${props => (props.$isFlex ? "1" : "none")};
  &:last-child {
    flex: ${props => (props.$isLastFlex ? "1" : "none")};
  }
  overflow: hidden;
`;

export default THeader;
