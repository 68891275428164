import styled from "styled-components";
import { ListChildComponentProps } from "react-window";
import { ApprovalHistoryEntity } from "../../../generated/graphql";
import { useMemo } from "react";

interface IProps extends ListChildComponentProps<ApprovalHistoryEntity[]> {}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
`;

const NumberIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.colors.midNightBlue};
  color: white;
  font-weight: bold;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  flex: 1;
  gap: 5px;
  align-items: center;
  svg {
    color: ${props => props.theme.colors.green};
    font-weight: bold;
    font-size: 14px;
  }
`;

const Description = styled.p`
  margin: 0px;
  margin-left: 30px;
  overflow-y: auto;
`;

const ViewTime = styled.span`
  display: flex;
  flex: 1;
  gap: 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0px;
  margin-left: 25px;
  gap: 10px;
`;

const MessageStatus = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`;

function RenderApprovalHistory({ index, data, style }: IProps) {
  const title = useMemo(() => {
    const name = data[index].empName;
    const departmentName = data[index].departmentName;
    const position = data[index].userPosition
      ? `/${data[index].userPosition}`
      : "";
    // const dateTime = data[index].approvalTime
    //   ? `(${data[index].approvalTime})`
    //   : "";
    const newTitle = `${name} [${departmentName}${position}]`;
    return newTitle;
  }, [data, index]);
  const description: string = useMemo(() => {
    return data[index].descriptionComment ?? "";
  }, [data, index]);

  const writingTime = useMemo(() => {
    if (data[index]?.approvalTime) {
      return data[index]?.approvalTime;
    }
  }, [data, index]);

  const viewTime = useMemo(() => {
    if (data[index]?.viewTime) {
      return data[index]?.viewTime;
    }
  }, [data, index]);

  return (
    <Container style={style}>
      <Title>
        <NumberIcon>{index + 1}</NumberIcon>
        {title}
      </Title>
      <StatusContainer>
        {viewTime && (
          <ViewTime>
            <MessageStatus>{`(R)`}</MessageStatus>
            {viewTime}
          </ViewTime>
        )}
        {writingTime && (
          <ViewTime>
            <MessageStatus>{`(W)`}</MessageStatus>
            {writingTime}
          </ViewTime>
        )}
      </StatusContainer>
      <Description>{description}</Description>
    </Container>
  );
}
export default RenderApprovalHistory;
