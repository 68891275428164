import styled from "styled-components";
import * as ReactTable from "react-table";
import useNewSortBy from "../../../../hooks/use-new-sort-by/use-new-sort-by";
import {
  StatisticsConditionEntity,
  useGetCountStatisticsConditionLazyQuery,
  useGetStatisticsConditionExcelLazyQuery,
  useGetStatisticsConditionListLazyQuery
} from "../../../../generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import moment from "moment";
import DepartmentSelectorDlg from "../../smallcomponents/DepartmentSelectorDlg";
import { IStatisticsState } from "../admin-statistics/admin-statistics";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import PageController from "../../../table/page_controller";
import { Reducers } from "../../../../../types/reducers";
import { useSelector } from "react-redux";
import { downloadFileFromServer } from "../Utils";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import StatisticsStatusHeader from "./statistics_status_header";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../table_v2/table_v2";
import { useSticky } from "react-table-sticky";

interface IProps {
  isAdmin: boolean;
  setSelectedRow: React.Dispatch<
    React.SetStateAction<ReactTable.Row<StatisticsConditionEntity> | undefined>
  >;
}

const COLUMN_FOR_VALUE = {
  periodInformation: "기간",
  departName: "부서",
  employeeId: "사번",
  empName: "이름",
  workingTemplateName: "근무제",
  totMonWorkDay: "총 근무일",
  totMonHoliDay: "휴일",
  totMonNormalWorkDay: "정상근무",
  totPossibleMin: "소정",
  totWorkPossibleMin: "소정근무",
  useWorkMin: "소정근무",
  totWorkPossibleLeaveMin: "소정근무",
  totExtPossibleMin: "시간외",
  useExtMin: "시간외",
  totExtPossibleLeaveMin: "시간외",
  totMonVacationDay: "휴가",
  totMonEducationDay: "교육",
  totMonBusinessTripDay: "출장",
  totMonAbsentDay: "결근",
  totMonLateDay: "지각",
  totMonEarlyDay: "조퇴",
  totMonSickDay: "병가",
  totMonFurloughDay: "휴직"
} as const;

type COLUMN_FOR_VALUE =
  (typeof COLUMN_FOR_VALUE)[keyof typeof COLUMN_FOR_VALUE];

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 5;
  overflow-x: hidden;
  flex-direction: column;
`;

const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  user-select: none;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const title = "근태현황";

function AttendanceStatus({ isAdmin, setSelectedRow }: IProps) {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isDepartmentDialogOpen, setIsDepartmentDialogOpen] =
    useState<boolean>(false);
  const [departments, setDepartments] = useState<string[]>([]);
  const {
    signInReducer: { employee_id, department_id }
  } = useSelector((state: Reducers) => state);

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useNewSortBy();

  const handleCloseDepartmentDialog = useCallback(() => {
    setIsDepartmentDialogOpen(false);
  }, []);

  const handleStatisticsState = useCallback(
    (payload: Partial<IStatisticsState>) => {
      if (payload.departments) {
        setDepartments(payload.departments.map(id => id.toString()));
      }
    },
    []
  );

  const [getCountStatisticsCondition, { data: countData }] =
    useGetCountStatisticsConditionLazyQuery({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (data.getCountStatisticsCondition.error) {
          console.log(data.getCountStatisticsCondition.error);
        }
      }
    });

  const [getStatisticsConditionList, { data, loading }] =
    useGetStatisticsConditionListLazyQuery({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        setIsSearch(false);
      },
      onCompleted(data) {
        if (data.getStatisticsConditionList.error) {
          console.log(data.getStatisticsConditionList.error);
        }
        setIsSearch(false);
      }
    });

  const [getStatisticsConditionExcel] = useGetStatisticsConditionExcelLazyQuery(
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log("=======근태현황 엑셀 에러");
        console.log(error);
      },
      onCompleted(data) {
        if (
          data.getStatisticsConditionExcel.ok &&
          data.getStatisticsConditionExcel.excel
        ) {
          downloadFileFromServer(
            data.getStatisticsConditionExcel.excel,
            `${moment().format(
              "YYYY-MM-DD-hh-mm-ss"
            )}_list_of_attendance_status.csv`
          );
        }
      }
    }
  );

  const downloadExcel = useCallback(() => {
    if (isAdmin) {
      getStatisticsConditionExcel({
        variables: {
          startDate,
          listOfDepartmentId: departments
        }
      });
    } else if (employee_id && department_id) {
      getStatisticsConditionExcel({
        variables: {
          startDate,
          listOfDepartmentId: [],
          employeeId: employee_id
        }
      });
    }
  }, [
    getStatisticsConditionExcel,
    departments,
    startDate,
    isAdmin,
    employee_id,
    department_id
  ]);

  const total: number = useMemo(() => {
    return countData?.getCountStatisticsCondition.total ?? 0;
  }, [countData]);

  const totalPage = useMemo(() => Math.ceil(total / take), [total, take]);

  const columns: ReactTable.Column<StatisticsConditionEntity>[] =
    useMemo(() => {
      const width = 70;

      let newFixedColumnName: string[] = [];
      if (!isNaN(Number(selectedFixedColumnNumber))) {
        newFixedColumnName = fixedColumnName.slice(
          0,
          Number(selectedFixedColumnNumber)
        );
      }
      return [
        {
          Header: "기간",
          accessor: "periodInformation",
          width: 200,
          sticky: newFixedColumnName.includes("periodInformation") ? "left" : ""
        },
        {
          Header: "부서",
          accessor: "departName",
          sticky: newFixedColumnName.includes("departName") ? "left" : ""
        },
        {
          Header: "사번(ID)",
          accessor: "employeeId",
          sticky: newFixedColumnName.includes("employeeId") ? "left" : ""
        },
        {
          Header: "이름",
          accessor: "empName",
          sticky: newFixedColumnName.includes("empName") ? "left" : ""
        },
        {
          Header: "근무제",
          accessor: "workingTemplateName",
          sticky: newFixedColumnName.includes("workingTemplateName")
            ? "left"
            : ""
        },
        {
          Header: "총 근무일",
          accessor: "totMonWorkDay",
          sticky: newFixedColumnName.includes("totMonWorkDay") ? "left" : ""
        },
        {
          Header: "휴일",
          accessor: "totMonHoliDay",
          sticky: newFixedColumnName.includes("totMonHoliDay") ? "left" : ""
        },
        {
          Header: "정상근무",
          accessor: "totMonNormalWorkDay",
          sticky: newFixedColumnName.includes("totMonNormalWorkDay")
            ? "left"
            : ""
        },
        {
          Header: "소정 + 시간외(가능시간)",
          accessor: "totPossibleMin",
          sticky: newFixedColumnName.includes("totPossibleMin") ? "left" : ""
        },
        {
          Header: "소정근무(가능시간)",
          accessor: "totWorkPossibleMin",
          sticky: newFixedColumnName.includes("totWorkPossibleMin")
            ? "left"
            : ""
        },
        {
          Header: "소정근무(인정시간)",
          accessor: "useWorkMin",
          sticky: newFixedColumnName.includes("useWorkMin") ? "left" : ""
        },
        {
          Header: "소정근무(잔여시간)",
          accessor: "totWorkPossibleLeaveMin",
          sticky: newFixedColumnName.includes("totWorkPossibleLeaveMin")
            ? "left"
            : ""
        },
        {
          Header: "시간외(가능시간)",
          accessor: "totExtPossibleMin",
          sticky: newFixedColumnName.includes("totExtPossibleMin") ? "left" : ""
        },
        {
          Header: "시간외(인정시간)",
          accessor: "useExtMin",
          sticky: newFixedColumnName.includes("useExtMin") ? "left" : ""
        },
        {
          Header: "시간외(잔여시간)",
          accessor: "totExtPossibleLeaveMin",
          sticky: newFixedColumnName.includes("totExtPossibleLeaveMin")
            ? "left"
            : ""
        },
        {
          Header: "휴가",
          accessor: "totMonVacationDay",
          sticky: newFixedColumnName.includes("totMonVacationDay")
            ? "left"
            : "",
          width
        },
        {
          Header: "교육",
          accessor: "totMonEducationDay",
          sticky: newFixedColumnName.includes("totMonEducationDay")
            ? "left"
            : "",
          width
        },
        {
          Header: "출장",
          accessor: "totMonBusinessTripDay",
          sticky: newFixedColumnName.includes("totMonBusinessTripDay")
            ? "left"
            : "",
          width
        },
        {
          Header: "결근",
          accessor: "totMonAbsentDay",
          sticky: newFixedColumnName.includes("totMonAbsentDay") ? "left" : "",
          width
        },
        {
          Header: "지각",
          accessor: "totMonLateDay",
          sticky: newFixedColumnName.includes("totMonLateDay") ? "left" : "",
          width
        },
        {
          Header: "조퇴",
          accessor: "totMonEarlyDay",
          sticky: newFixedColumnName.includes("totMonEarlyDay") ? "left" : "",
          width
        },
        {
          Header: "병가",
          accessor: "totMonSickDay",
          sticky: newFixedColumnName.includes("totMonSickDay") ? "left" : "",
          width
        },
        {
          Header: "휴직",
          accessor: "totMonFurloughDay",
          sticky: newFixedColumnName.includes("totMonFurloughDay")
            ? "left"
            : "",
          width
        }
      ];
    }, [sFixedColumnNumber, fixedColumnName, fixedColumnNumber]);

  const list: StatisticsConditionEntity[] = useMemo(() => {
    return data?.getStatisticsConditionList.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<StatisticsConditionEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useColumnOrder,
    ReactTable.useRowSelect,
    useSticky
  );

  const { moveColumn } = useDnd<StatisticsConditionEntity>({
    columns: table.visibleColumns,
    setColumnOrder: table.setColumnOrder,
    title: `${title}-for-ordering-column`,
    setFixedColumnName
  });

  const selectedRow: ReactTable.Row<StatisticsConditionEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  useEffect(() => {
    handleListOfColumnDisabled([
      "category",
      "monthlyAttendanceStatus",
      "regularWork",
      "workOverTime",
      "monthlyAttendanceStatus2"
    ]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    if (isSearch) {
      if (isAdmin) {
        getCountStatisticsCondition({
          variables: {
            startDate,
            listOfDepartmentId: departments
          }
        });
      } else if (employee_id) {
        getCountStatisticsCondition({
          variables: {
            startDate,
            listOfDepartmentId: [],
            employeeId: employee_id
          }
        });
      }
    }
  }, [
    getCountStatisticsCondition,
    startDate,
    departments,
    isSearch,
    isAdmin,
    employee_id
  ]);

  useEffect(() => {
    if (isSearch) {
      if (isAdmin) {
        getStatisticsConditionList({
          variables: {
            startDate,
            listOfDepartmentId: departments,
            take,
            page: currentPage,
            fieldSort
          }
        });
      } else if (employee_id && department_id) {
        getStatisticsConditionList({
          variables: {
            startDate,
            listOfDepartmentId: [],
            take,
            page: currentPage,
            employeeId: employee_id,
            fieldSort
          }
        });
      }
    }
  }, [
    getStatisticsConditionList,
    startDate,
    departments,
    isSearch,
    take,
    currentPage,
    isAdmin,
    employee_id,
    department_id,
    fieldSort
  ]);

  useEffect(() => {
    if (fieldSort) {
      setIsSearch(true);
    }
  }, [fieldSort, setIsSearch]);

  useEffect(() => {
    setIsSearch(true);
  }, [currentPage]);

  useEffect(() => {
    if (!isAdmin) {
      setIsSearch(true);
    }
  }, [isAdmin]);

  useEffect(() => {
    setSelectedRow(selectedRow);
  }, [selectedRow, setSelectedRow]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);

  return (
    <Container>
      <TableContainer>
        <StatisticsStatusHeader<StatisticsConditionEntity>
          columns={table.columns as TColumn<StatisticsConditionEntity>[]}
          table={table}
          setIsSearch={setIsSearch}
          title={title}
          headerTitleList={Object.values(COLUMN_FOR_VALUE)}
          take={take}
          handleTake={handleTake}
          count={total}
          handleCurrentPage={handleCurrentPage}
          downloadExcel={downloadExcel}
          startDate={startDate}
          setStartDate={setStartDate}
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
          isAdmin={isAdmin}
          setIsDepartmentDialogOpen={setIsDepartmentDialogOpen}
          setIsActiveFilter={setIsActiveFilter}
        />
        <TableLayoutContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title={title}
                  selectedRow={selectedRow}
                  fieldSort={fieldSort}
                  handleFieldSort={handleFieldSort}
                  height={height}
                  width={width}
                  listOfColumnDisabled={listOfColumnDisabled}
                  moveColumn={moveColumn}
                  loading={loading}
                  // listOfFlexForHeader={["구분", "기간"]}
                  // isFlexStart={true}
                  // isLastFlex={false}
                />
              );
            }}
          </AutoSizer>
        </TableLayoutContainer>
        {/* <GroupTable<StatisticsConditionEntity>
          title={title}
          handleSelectRow={handleSelectRow}
          isLoading={loading}
          take={take}
          handleTake={handleTake}
          total={total}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          handleFieldSort={handleFieldSort}
          fieldSort={fieldSort}
          columns={statisticsColumns as TColumn<StatisticsConditionEntity>[]}
          toggleHideColumn={toggleHideColumn}
          listOfFlexForHeader={["구분", "기간"]}
          listOfColumnDisabled={listOfColumnDisabled}
          isFlexStart={true}
          isLastFlex={false}
          isGroup
        >
          <SearchContainer>
            <span>{"검색"}</span>
            <StyledInput
              type={"month"}
              value={moment(startDate).format("YYYY-MM")}
              onChange={event => {
                if (event.currentTarget.value) {
                  setStartDate(`${event.currentTarget.value}-01`);
                }
              }}
            />
            {isAdmin && (
              <Button
                onClick={() => {
                  setIsDepartmentDialogOpen(true);
                }}
              >
                부서 지정
              </Button>
            )}
            <Button
              onClick={() => {
                setIsSearch(true);
              }}
            >{`결과보기`}</Button>
            <AsonicExcel downloadExcel={downloadExcel} />
          </SearchContainer>
        </GroupTable> */}
      </TableContainer>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={totalPage}
          handleCurrentPage={handleCurrentPage}
        />
      </TableFooter>
      {isDepartmentDialogOpen && (
        <DepartmentSelectorDlg
          title="부서 지정"
          description="조건에 들어갈 부서를 검색합니다. (두글자 이상 입력해주세요)"
          handleClose={handleCloseDepartmentDialog}
          handleStatisticsState={handleStatisticsState}
        />
      )}
    </Container>
  );
}

export default AttendanceStatus;
