import { useCallback, ChangeEvent } from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import {
  ListOfGoogleColor,
  SerializeItem
} from "../../hooks/link-google-hooks/use-google-calendar";
import CheckBoxUi from "../globalComponents/CheckBoxUi";
interface IProps {
  data: SerializeItem[];
  listOfGoogleColor: ListOfGoogleColor;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Container = styled.div`
  display: flex;
  flex: 12;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const ScrollingList = <P extends IProps>({
  data,
  handleOnChange,
  listOfGoogleColor
}: P) => {
  const Row = useCallback(
    ({ index, style }: IRenderRow) => {
      return (
        <Item style={style}>
          <CheckBoxUi
            key={`${data[index].data.name} ${data[index].data.id}`}
            htmlFor={data[index].data.id}
            onChange={handleOnChange}
            checked={data[index].isChecked}
            title={data[index].data.name}
            color={
              listOfGoogleColor.calendar[data[index]?.origin.colorId]
                ?.background
            }
          />
        </Item>
      );
    },
    [data, listOfGoogleColor, handleOnChange]
  );

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              height={height}
              itemCount={data.length}
              itemSize={25}
              width={width}
            >
              {Row}
            </List>
          );
        }}
      </AutoSizer>
    </Container>
  );
};

export default ScrollingList;
