import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Title = styled.span`
  padding: 10px;
  margin-left: 5px;
  flex: 1;
  font-weight: ${props => (props.isParent ? "bold" : "none")};
`;

export const Icon = styled(FontAwesomeIcon)`
  padding: 10px;
  color: white;
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  color: white;
  margin-right: 5px;
`;

export const ChildrenContainer = styled.div`
  min-width: max-content;
  transition: all 0.3s ease-in-out;
  z-index: 9;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.midNightBlue};
  color: white;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: ${props => {
    const isDisplay = props?.isOpen && props.open;
    return isDisplay ? "flex" : "none";
  }};
  opacity: ${props => (props?.isOpen ? 1 : 0)};
  visibility: ${props => (props?.isOpen ? "visible" : "hidden")};
  flex-direction: column;
`;

export const ItemChildren = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.lightMidNightBlue};
  }
  color: ${props =>
    props?.selected ? props.theme.colors.green : props.theme.colors.white};
  padding: 10px;
  padding-left: 20%;
`;

export const ChildrenIcon = styled.span`
  font-weight: bold;
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${props =>
    props?.selected ? props.theme.colors.lightGreen : ""};
  justify-content: ${props => (props?.open ? "flex-start" : "center")};
  border-top: ${props => `solid 0.05px ${props.theme.colors.black}`};
  color: white;
  margin: 0;
  svg {
    min-width: 15.99px;
    max-width: 15.99px;
  }
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: ${props => props.theme.colors.lightMidNightBlue};
    color: white;
    ${ChildrenContainer} {
      position: ${props => (props.open ? "static" : "absolute")};
      left: ${props => (!props.open ? "3.5%" : "")};
      display: ${props => {
        if (props.selected) {
          return "flex";
        }
        if (!props.open) {
          return "flex";
        }
        return "none";
      }};
      opacity: ${props => {
        if (props.selected) {
          return 1;
        }
        if (!props.open) {
          return 1;
        }
        return 0;
      }};
      visibility: ${props => {
        if (props.selected) {
          return "visible";
        }
        if (!props.open) {
          return "visible";
        }
        return "hidden";
      }};
    }
    ${ItemChildren} {
      padding: ${props => (props.open ? "" : "10px")};
    }
  }
`;
