import * as React from "react";
import moment from "moment";
import personalApi from "../../../api/dashboard/personal-api";
import "moment/locale/ko";

type CommutingInfo = {
  start: string;
  end: string;
  startDeviceType?: number;
  endDeviceType?: number;
};

type Props = {
  userId: string;
};

const useCurrentTimeBox = <P extends Props>({ userId }: P) => {
  const isMount = React.useRef<boolean>(false);

  const [workingTimeInfo, setWorkingTimeInfo] = React.useState<string>("");
  const [commutingInfo, setCommutingInfo] = React.useState<CommutingInfo>({
    start: "출근 전",
    end: "퇴근 전",
    startDeviceType: undefined,
    endDeviceType: undefined
  });

  const currentDate = React.useMemo<string>(
    () => moment().locale("ko").format("YYYY년 MM월 DD일 dddd"),
    []
  );
  const getCurrentTimeInfo = React.useCallback(async () => {
    const parmas = {
      userId
    };
    try {
      const { data } = await personalApi.getCurrentTimeBox(parmas);
      if (isMount.current && data.length > 0) {
        const newWorkingTimeInfo = data[0].WorkingTime ?? "";

        const newCommutingInfo = {
          start: data[1]?.ascommu_in,
          end: data[2]?.ascommu_out,
          startDeviceType: data[1].device_type || undefined,
          endDeviceType: data[2].device_type || undefined
        };
        setWorkingTimeInfo(newWorkingTimeInfo);
        setCommutingInfo(newCommutingInfo);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    getCurrentTimeInfo();

    return () => {
      isMount.current = false;
    };
  }, [getCurrentTimeInfo]);

  return { currentDate, workingTimeInfo, commutingInfo };
};

export default useCurrentTimeBox;
