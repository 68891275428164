import styled from "styled-components";
import PositionController, {
  TPositionControl
} from "../approval-process/position-controller";
import ListOfReceiveAndReferenceDepartment from "./list-of-receive-and-reference-department";
import ListOfReceiveAndReferenceUser from "./list-of-receive-and-reference-user";

export const ReceiveAndReferenceType = {
  RECEIVE: "RECEIVE",
  REFERENCE: "REFERENCE"
} as const;

export type ReceiveAndReferenceType =
  typeof ReceiveAndReferenceType[keyof typeof ReceiveAndReferenceType];

export interface IEmployeeInformationForReceiveAndReference {
  employeeId?: string;
  departmentId?: string;
  name?: string;
  userPosition?: string;
  department?: string;
  type: ReceiveAndReferenceType;
  checked?: boolean;
}

interface IProps {
  listOfUser: IEmployeeInformationForReceiveAndReference[];
  listOfAddedUser: string[];
  handleChangeUserInfoInListOfApprovalUser: (
    payload: IEmployeeInformationForReceiveAndReference
  ) => void;
  handleAllAddedUser: (users: string[]) => void;
  handleAddedUser: (user: string) => void;
  listOfDepartment: IEmployeeInformationForReceiveAndReference[];
  listOfAddedDepartment: string[];
  handleAllAddedDepartment: (users: string[]) => void;
  handleChangeDepartmentInfoInListOfApprovalDepartment: (
    payload: IEmployeeInformationForReceiveAndReference
  ) => void;
  handleAddedDepartment: (department: string) => void;
  handleChangeTheOrderUser: (type: TPositionControl) => void;
  handleChangeTheOrderDepartment: (type: TPositionControl) => void;
}

const Container = styled.div`
  display: flex;
  flex: 3;
  gap: 10px;
  padding-top: 32px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 3;
  gap: 10px;
`;

function ListOfSelectedApprovalUserForReceiveAndReferenceForm({
  listOfUser,
  listOfAddedUser,
  handleChangeUserInfoInListOfApprovalUser,
  handleAllAddedUser,
  handleAddedUser,
  listOfDepartment,
  listOfAddedDepartment,
  handleAllAddedDepartment,
  handleChangeDepartmentInfoInListOfApprovalDepartment,
  handleAddedDepartment,
  handleChangeTheOrderUser,
  handleChangeTheOrderDepartment
}: IProps) {
  return (
    <Container>
      <Content>
        <TableContainer>
          <ListOfReceiveAndReferenceUser
            listOfUser={listOfUser}
            listOfAddedUser={listOfAddedUser}
            handleAllAddedUser={handleAllAddedUser}
            handleChangeUserInfoInListOfApprovalUser={
              handleChangeUserInfoInListOfApprovalUser
            }
            handleAddedUser={handleAddedUser}
          />
          <PositionController handleChangeTheOrder={handleChangeTheOrderUser} />
        </TableContainer>
        <TableContainer>
          <ListOfReceiveAndReferenceDepartment
            listOfDepartment={listOfDepartment}
            listOfAddedDepartment={listOfAddedDepartment}
            handleAllAddedDepartment={handleAllAddedDepartment}
            handleChangeDepartmentInfoInListOfApprovalDepartment={
              handleChangeDepartmentInfoInListOfApprovalDepartment
            }
            handleAddedDepartment={handleAddedDepartment}
          />
          <PositionController
            handleChangeTheOrder={handleChangeTheOrderDepartment}
          />
        </TableContainer>
      </Content>
    </Container>
  );
}

export default ListOfSelectedApprovalUserForReceiveAndReferenceForm;
