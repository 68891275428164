import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import BrowserPermanentStorage, { TABS_DB_SCHEME_OBJECT } from "./logics";
import { OBJECT_STORE_NAMES } from "../../../Utils/indexed-db/indexed-db-scheme";

export const useWeekPeriodStartEndDate = () => {
  const aWeekBeforeFormattedString = useMemo(() => {
    return moment().subtract(7, "d").format("YYYY-MM-DD");
  }, []);
  const todayFormattedString = useMemo(() => {
    return moment().format("YYYY-MM-DD");
  }, []);

  const firstDayOfMonthFormattedString = useMemo(() => {
    return moment().startOf("month").format("YYYY-MM-DD");
  }, []);
  const lastDayOfMonthFormattedString = useMemo(() => {
    return moment().endOf("month").format("YYYY-MM-DD");
  }, []);

  return {
    aWeekBeforeFormattedString,
    todayFormattedString,
    firstDayOfMonthFormattedString,
    lastDayOfMonthFormattedString
  };
};

export const useSearchDateRange = (
  tabType: TABS_DB_SCHEME_OBJECT,
  tabName: string
) => {
  const bps = useMemo(() => {
    return new BrowserPermanentStorage(OBJECT_STORE_NAMES.WORK_MANAGEMENT_INFO);
  }, []);

  // 오늘 날짜에서 7일 앞의 날짜를 시작 날짜로, 오늘 날짜를 끝 날짜로 설정
  const {
    // aWeekBeforeFormattedString,
    // todayFormattedString,
    firstDayOfMonthFormattedString,
    lastDayOfMonthFormattedString
  } = useWeekPeriodStartEndDate();

  // 위에서 설정한 시작 날짜와 끝 날짜를 상태로 설정
  const [startDate, setStartDate] = useState(firstDayOfMonthFormattedString);
  const [endDate, setEndDate] = useState(lastDayOfMonthFormattedString);
  // cache에 저장된 검색 날짜를 가져왔을 때 다시 렌더링 해야하는지 여부를 설정
  const [retryRender, setRetryRender] = useState<boolean>(false);

  const searchStartDate = startDate;
  const searchEndDate = endDate;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const { startDateFromDB, endDateFromDB } = await bps.getInitialData(
          tabType,
          {
            tabName: tabName,
            startDateFromDB: firstDayOfMonthFormattedString,
            endDateFromDB: lastDayOfMonthFormattedString
          }
        );
        // 필요한 경우 여기서 startDate와 endDate를 업데이트
        setStartDate(startDateFromDB || firstDayOfMonthFormattedString);
        setEndDate(endDateFromDB || lastDayOfMonthFormattedString);
        setRetryRender(true);
      } catch (err) {
        console.log(
          err instanceof Error ? err : new Error("An error occurred")
        );
      }
    };

    fetchInitialData();
  }, [bps, firstDayOfMonthFormattedString, lastDayOfMonthFormattedString]);

  const saveSearchDate = () => {
    bps.saveSearchDate(tabType, {
      tabName: tabName,
      startDateFromDB: startDate,
      endDateFromDB: endDate
    });
    setRetryRender(true);
  };

  return {
    // aWeekBeforeFormattedString,
    // todayFormattedString,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchStartDate,
    searchEndDate,
    retryRender,
    setRetryRender,
    saveSearchDate
  };
};
