import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  Annual_Accrual_Type_For_Next_Year_Of_Employment,
  Carriedforward_Range_Type,
  Carriedforward_Type,
  CompanyEntity,
  InfoNextYearOfEmploymentEntity,
  Minus_Annual_Type,
  Month_Accrual_Type,
  Month_Accrual_Type_For_Next_Year_Of_Employment,
  UpdateInfoNextYearOfEmploymentMutationVariables,
  useGetInfoNextYearOfEmploymentLazyQuery,
  useUpdateInfoNextYearOfEmploymentMutation
} from "../../generated/graphql";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import Button from "../globalComponents/Button";
import StyleInput from "../inputs/style-input";
import FormRow from "../shared/form-row/form-row";
import SubTitle from "../shared/sub-title/sub-title";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import { useForm, useWatch } from "react-hook-form";
import InfoNextYearHelp from "./info-next-year-help";

interface IProps {
  selectedCompany?: CompanyEntity;
}

const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 1200px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  min-width: 900px;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 460px;
  min-width: 460px;
  :first-child {
    select {
      min-width: 275px;
    }
  }
  :nth-child(2) {
    select {
      min-width: 200px;
    }
  }
`;

function InfoNextYearSetting({ selectedCompany }: IProps) {
  const { register, setValue, handleSubmit, control } =
    useForm<InfoNextYearOfEmploymentEntity>({
      defaultValues: {
        annualAccrualType:
          Annual_Accrual_Type_For_Next_Year_Of_Employment.ApplyAdminIndividual,
        carriedforwardRangeType:
          Carriedforward_Range_Type.MinusAndPlusAnnualLeave,
        carriedforwardType: Carriedforward_Type.Apply,
        fixCount: 1,
        minusAnnualDayCount: 1,
        minusAnnualType: Minus_Annual_Type.LimitByBasicDayWithMinusAnnual,
        monthAccrualType:
          Month_Accrual_Type_For_Next_Year_Of_Employment.TheBaseDateForJoining
      }
    });
  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const listOfAnnualAccrualType = useMemo(
    () => [
      {
        title: " 만1년 이상자에 대해 설정된 기본일수 동일 적용",
        value:
          Annual_Accrual_Type_For_Next_Year_Of_Employment.ApplyTheSameBasicDay
      },
      {
        title: "월별 발생",
        value: Annual_Accrual_Type_For_Next_Year_Of_Employment.Monthly
      },
      {
        title: "고정일 부여",
        value: Annual_Accrual_Type_For_Next_Year_Of_Employment.GiveFixedDate
      },
      {
        title: "관리자 개별적용",
        value:
          Annual_Accrual_Type_For_Next_Year_Of_Employment.ApplyAdminIndividual
      }
    ],
    []
  );

  const listOfMonthAccrualType = useMemo(
    () => [
      {
        title: "입사 당월",
        value: Month_Accrual_Type.TheMonthOfEmployment
      },
      {
        title: "입사 기준일",
        value: Month_Accrual_Type.TheBaseDateForJoining
      },
      {
        title: "입사 1개월 만근일",
        value: Month_Accrual_Type.AFullMonthsEmployment
      }
    ],
    []
  );

  const listOfMinusAnnualType = useMemo(
    () => [
      {
        title: "제한없음",
        value: Minus_Annual_Type.UnLimit
      },
      {
        title: "기본일수 + 마이너스연차 허용일수로 제한",
        value: Minus_Annual_Type.LimitByBasicDayWithMinusAnnual
      },
      {
        title: "마이너스연차 허용일수로 제한",
        value: Minus_Annual_Type.LimitByMinusAllowedAnnual
      },
      {
        title: "미사용",
        value: Minus_Annual_Type.UnUsed
      }
    ],
    []
  );

  const listOfCarriedforwardType = useMemo(
    () => [
      { title: "적용", value: Carriedforward_Type.Apply },
      { title: "미적용", value: Carriedforward_Type.NotApplied }
    ],
    []
  );

  const listOfCarriedforwardRangeType = useMemo(
    () => [
      {
        title: "마이너스 연차만 적용",
        value: Carriedforward_Range_Type.MinusAnnualLeaveOnly
      },
      {
        title: "플러스 연차만 적용",
        value: Carriedforward_Range_Type.PlusAnnualLeaveOnly
      },
      {
        title: "마이너스, 플러스연차 모두 적용",
        value: Carriedforward_Range_Type.MinusAndPlusAnnualLeave
      }
    ],
    []
  );

  const [getInfoNextYearOfEmployment] = useGetInfoNextYearOfEmploymentLazyQuery(
    {
      onError(error) {
        console.log(error);
        handleToast(
          "만 1년미만(입사차기년도) 정보를 가져올 수 없습니다.",
          MessageTypes.WARNING
        );
      },
      onCompleted(data) {
        if (
          data.getInfoNextYearOfEmployment.ok &&
          data.getInfoNextYearOfEmployment.info
        ) {
          const {
            monthAccrualType,
            minusAnnualType,
            minusAnnualDayCount,
            fixCount,
            annualAccrualType,
            carriedforwardRangeType,
            carriedforwardType
          } = data.getInfoNextYearOfEmployment.info;
          setValue("annualAccrualType", annualAccrualType);
          setValue("carriedforwardRangeType", carriedforwardRangeType);
          setValue("carriedforwardType", carriedforwardType);
          setValue("fixCount", fixCount);
          setValue("minusAnnualDayCount", minusAnnualDayCount);
          setValue("minusAnnualType", minusAnnualType);
          setValue("monthAccrualType", monthAccrualType);
        }
      }
    }
  );

  const [updateInfoNextYearOfEmployment] =
    useUpdateInfoNextYearOfEmploymentMutation({
      onError(error) {
        console.log(error);
        handleToast(
          "만 1년 미만(입사차기년도) 설정을 저장할 수 없습니다.",
          MessageTypes.WARNING
        );
      },
      update(_, { data }) {
        if (data?.updateInfoNextYearOfEmployment.ok) {
          handleToast(
            "성공적으로 만 1년 미만(입사차기년도) 설정을 저장했습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.updateInfoNextYearOfEmployment.error) {
          handleToast(
            data.updateInfoNextYearOfEmployment.error,
            MessageTypes.ERROR
          );
        }
      }
    });

  const handleUpdate = useCallback(
    (data: InfoNextYearOfEmploymentEntity) => {
      if (selectedCompany) {
        const payload: UpdateInfoNextYearOfEmploymentMutationVariables = {
          companyCode: selectedCompany.companyCode,
          annualAccrualType: data.annualAccrualType,
          monthAccrualType: data.monthAccrualType,
          fixCount: parseInt(`${data.fixCount}`),
          minusAnnualType: data.minusAnnualType,
          minusAnnualDayCount: parseInt(`${data.minusAnnualDayCount}`),
          carriedforwardType: data.carriedforwardType,
          carriedforwardRangeType: data.carriedforwardRangeType
        };
        updateInfoNextYearOfEmployment({
          variables: payload
        });
      }
    },
    [selectedCompany, updateInfoNextYearOfEmployment]
  );

  const annualAccrualType = useWatch({
    control,
    name: "annualAccrualType"
  });

  const minusAnnualType = useWatch({
    control,
    name: "minusAnnualType"
  });

  const carriedforwardType = useWatch({
    control,
    name: "carriedforwardType"
  });

  useEffect(() => {
    if (selectedCompany) {
      getInfoNextYearOfEmployment({
        variables: {
          companyCode: selectedCompany.companyCode
        }
      });
    }
  }, [getInfoNextYearOfEmployment, selectedCompany]);

  return (
    <Container onSubmit={handleSubmit(handleUpdate)}>
      <TitleBar>
        <SubTitle title="만 1년 미만(입사차기년도)" />
        <InfoNextYearHelp />
      </TitleBar>
      <Body>
        <Section>
          <FormRow title="연차 발생 방식 설정">
            <select {...register("annualAccrualType")}>
              {listOfAnnualAccrualType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
          <FormRow title="월별 발생 기준시점">
            <select
              {...register("monthAccrualType")}
              disabled={
                annualAccrualType !==
                Annual_Accrual_Type_For_Next_Year_Of_Employment.Monthly
              }
            >
              {listOfMonthAccrualType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
          <FormRow title="마이너스 연차 사용 설정">
            <select {...register("minusAnnualType")}>
              {listOfMinusAnnualType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
          <FormRow title="이월 연차 적용 설정">
            <select {...register("carriedforwardType")}>
              {listOfCarriedforwardType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
        </Section>
        <Section>
          <FormRow title=""></FormRow>
          <FormRow title="고정일 부여 일자">
            <StyleInput
              type="number"
              min={0}
              minWidth="190px"
              {...register("fixCount")}
              disabled={
                annualAccrualType !==
                Annual_Accrual_Type_For_Next_Year_Of_Employment.GiveFixedDate
              }
            />
            <span>{`일`}</span>
          </FormRow>
          <FormRow title="마이너스연차 허용일">
            <StyleInput
              type="number"
              min={0}
              minWidth="190px"
              {...register("minusAnnualDayCount")}
              disabled={
                minusAnnualType === Minus_Annual_Type.UnLimit ||
                minusAnnualType === Minus_Annual_Type.UnUsed
              }
            />
            <span>{`일`}</span>
          </FormRow>
          <FormRow title="이월 연차 적용 범위">
            <select
              {...register("carriedforwardRangeType")}
              disabled={carriedforwardType === Carriedforward_Type.NotApplied}
            >
              {listOfCarriedforwardRangeType.map(item => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </FormRow>
        </Section>
      </Body>
      <ButtonContainer>
        <Button>저장</Button>
      </ButtonContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default InfoNextYearSetting;
