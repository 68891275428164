import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

export const Select = styled.select<{ minWidth?: string }>`
  flex: 6;
  min-width: ${props => props.minWidth || ""};
`;

export const Option = styled.option``;

export const Label = styled.label<{
  labelWidth?: string;
  labelTextAlign?: string;
}>`
  font-size: 12px;
  color: ${props => props.theme.colors.darkGrey};
  min-width: max-content;
  flex: 2;
`;
