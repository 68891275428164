import * as React from "react";
import moment from "moment";

const START_DATE = "start";
const END_DATE = "end";

interface IProps {
  setQueryContent: React.Dispatch<any>;
}

const useHandleDate = (props: IProps) => {
  const { setQueryContent } = props;
  const [startDate, setStartDate] = React.useState<moment.Moment>(
    moment().subtract(7, "days")
  );
  const [endDate, setEndDate] = React.useState<moment.Moment>(moment());
  const handleStartDate = React.useCallback((payload: moment.Moment) => {
    setStartDate(payload);
  }, []);
  const handleEndDate = React.useCallback((payload: moment.Moment) => {
    setEndDate(payload);
  }, []);
  const handleDate = React.useCallback(
    (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const date = moment(event.currentTarget.value);
      if (type === START_DATE) {
        setStartDate(date);
      }
      if (type === END_DATE) {
        setEndDate(date);
      }
    },
    []
  );

  const updateDate = React.useCallback(
    (name: string) => (date: moment.Moment) => {
      let key = "start_date";
      if (name === START_DATE) {
        setStartDate(date);
        setQueryContent({
          type: key,
          [key]: date
        });
      } else if (name === END_DATE) {
        key = "end_date";
        setEndDate(date);
        setQueryContent({
          type: key,
          [key]: date
        });
      }
    },
    [setQueryContent]
  );
  return {
    startDate,
    endDate,
    updateDate,
    handleDate,
    handleStartDate,
    handleEndDate
  };
};

export default useHandleDate;
