import styled from "styled-components";
import * as React from "react";
import { IContextMenu } from "../../hooks/use-context-menu/use-context-menu";

interface IProps extends IContextMenu {
  children?: React.ReactNode;
}

const Container = styled.div<IContextMenu>`
  z-index: 10;
  font-size: 12px;
  position: fixed;
  top: ${props => props.pageY && `${props.pageY}px`};
  left: ${props => props.pageX && `${props.pageX}px`};
  background-color: white;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  width: 150px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-shadow: 7px 6px 13px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 6px 13px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 6px 13px -2px rgba(0, 0, 0, 0.75);
  visibility: ${props => (props.isContextMenu ? "visible" : "hidden")};
`;

function ContextMenu({ pageX, pageY, isContextMenu, children }: IProps) {
  return (
    <Container pageX={pageX} pageY={pageY} isContextMenu={isContextMenu}>
      {children}
    </Container>
  );
}

export default ContextMenu;
