import StdWorkHour from "./std_work_hour.tsx/std_work_hour";
import FlexibleWorkGraph from "./flexible_work_graph/flexible_work_graph";
import ChoiceWorkGraph from "./choice_work_graph/choice_work_graph";
import AutonomyWorkGraph from "./autonomy_work_graph/autonomy_work_graph";
import * as ReactTable from "react-table";
import { WorkingTemplateEntityColumn } from "../../screens/view-settings/work-schedule-settings/working_system.screen";

interface IProps {
  type: string;
  selectedRow?: ReactTable.Row<WorkingTemplateEntityColumn>;
  workingTemplateIdx?: number;
}

function WorkSystemDialog({ type, selectedRow, workingTemplateIdx }: IProps) {
  switch (type) {
    case "표준":
      return (
        <StdWorkHour
          workingTemplateIdx={
            selectedRow?.original.workingTemplateIdx ?? workingTemplateIdx
          }
        />
      );
    case "탄력":
      return (
        <FlexibleWorkGraph
          workingTemplateIdx={
            selectedRow?.original.workingTemplateIdx ?? workingTemplateIdx
          }
        />
      );
    case "선택":
      return (
        <ChoiceWorkGraph
          workingTemplateIdx={
            selectedRow?.original.workingTemplateIdx ?? workingTemplateIdx
          }
        />
      );
    default:
      return (
        <AutonomyWorkGraph
          workingTemplateIdx={
            selectedRow?.original.workingTemplateIdx ?? workingTemplateIdx
          }
        />
      );
  }
}

export default WorkSystemDialog;
