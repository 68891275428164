import * as React from "react";
import serviceStatusApi from "../../../../../api/electron-api/service-status-api";
import moment from "moment";

type DataSets = {
  backgroundColor: string;
  barThickness: number;
  minBarLength: number;
  data: number[];
  label: string;
};

type ChartData = {
  labels: string[];
  datasets: DataSets[];
};

type Props = {
  employeeId?: string;
};

const useWeeklyTotalWorkingHour = <P extends Props>({ employeeId }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [weeklyTotalWorkingHour, setWeeklyTotalWorkingHour] =
    React.useState("");
  const [regularLevDisplay, setRegularLevDisplay] = React.useState("");
  const [overTimeLevDisplay, setOverTimeLevDisplay] = React.useState("");

  const [regularMax, setRegularMax] = React.useState<number>(0);
  const [overTimeMax, setOverTimeMax] = React.useState<number>(0);

  const [regularChartData, setRegularChartData] = React.useState<ChartData>({
    labels: ["정규 근무시간"],
    datasets: [
      {
        backgroundColor: "#4C8AC8",
        barThickness: 15,
        minBarLength: 4,
        data: [0],
        label: "정규"
      },
      {
        backgroundColor: "#CFCFCF",
        barThickness: 15,
        minBarLength: 4,
        data: [0],
        label: "잔여"
      }
    ]
  });
  const [overTimeChartData, setOverTimeChartData] = React.useState<ChartData>({
    labels: ["연장 근무시간"],
    datasets: [
      {
        backgroundColor: "#043571",
        barThickness: 15,
        minBarLength: 4,
        data: [0],
        label: "연장"
      },
      {
        backgroundColor: "#CFCFCF",
        barThickness: 15,
        minBarLength: 4,
        data: [0],
        label: "잔여"
      }
    ]
  });

  const makeChartData = React.useCallback(
    ({
      time,
      LevTime,
      type
    }: {
      time: number;
      LevTime: number;
      type: string;
    }): DataSets[] => {
      const workTimeColor = type === "regular" ? "#3d78b1" : "#043571";

      const newTime = {
        backgroundColor: workTimeColor,
        barThickness: 15,
        minBarLength: 4,
        data: [time],
        label: "정규"
      };

      const newLevTime = {
        backgroundColor: "#CFCFCF",
        barThickness: 15,
        minBarLength: 4,
        data: [LevTime],
        label: "잔여"
      };
      return [newTime, newLevTime];
    },
    []
  );

  const handleData = React.useCallback(async () => {
    try {
      if (employeeId && isMount.current) {
        const params = {
          employeeId
        };
        const { data } = await serviceStatusApi.weeklyTotalWorkingHour(params);

        const {
          asext_leavemin, // 잔여 연장근무시간
          asext_maxmin, // 연장근무시간
          astotmin, // 금주 총 근무시간
          aswork_leavemin, // 잔여 정규근무시간
          aswork_maxmin // 정규근무시간
          // n_weekmax_workhour, // 금주 근무시간
          // n_weekmax_extworkhour // 금주 연장근무시간
        } = data[0];
        let Hours = 0;
        let Minutes = 0;
        if (astotmin) {
          Hours = Math.floor(moment.duration(astotmin, "minutes").asHours());
          Minutes = astotmin - Hours * 60;
        }
        setWeeklyTotalWorkingHour(`${Hours}시간 ${Minutes}분`);
        setRegularMax(aswork_maxmin);
        setOverTimeMax(asext_maxmin);

        // 각각의 차트에서 사용될 잔여시간
        const newRegularLevHour =
          aswork_maxmin > 2400 ? 0 : 2400 - aswork_maxmin;
        const newOverTimeLevHour = asext_maxmin > 720 ? 0 : 720 - asext_maxmin;

        // 차트가 아닌 실제 잔여시간을 표시하기 위한 로직
        const newRegularLev = `${Math.floor(
          moment.duration(aswork_leavemin, "minutes").asHours()
        )}h ${aswork_leavemin % 60}m`;
        const newOverTimeLev = `${Math.floor(
          moment.duration(asext_leavemin, "minutes").asHours()
        )}h ${asext_leavemin % 60}m`;

        const regularChartDatasets = makeChartData({
          time: aswork_maxmin,
          LevTime: newRegularLevHour,
          type: "regular"
        });
        const overTimeChartDatasets = makeChartData({
          time: asext_maxmin,
          LevTime: newOverTimeLevHour,
          type: "over"
        });

        const newRegularChartData = {
          datasets: regularChartDatasets,
          labels: ["정규 근무시간"]
        };

        const newOverTimeChartData = {
          datasets: overTimeChartDatasets,
          labels: ["연장 근무시간"]
        };

        setRegularLevDisplay(newRegularLev);
        setOverTimeLevDisplay(newOverTimeLev);
        setRegularChartData(newRegularChartData);
        setOverTimeChartData(newOverTimeChartData);
      }
    } catch (error) {}
  }, [employeeId, makeChartData]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    handleData();
    const timeId = setInterval(handleData, 60000);
    return () => {
      isMount.current = false;
      clearInterval(timeId);
    };
  }, [handleData]);

  return {
    weeklyTotalWorkingHour,
    regularLevDisplay,
    overTimeLevDisplay,
    regularChartData,
    overTimeChartData,
    regularMax,
    overTimeMax
  };
};

export default useWeeklyTotalWorkingHour;
