import "moment/locale/ko";
import AsonicCalendarMonthPresenter from "./asonic-calendar-month-presenter";
import useDebugging from "../../../hooks/use-debugging";
import useAuthenticateGoogleUser from "../../../hooks/link-google-hooks/use-authenticate-google-user";
import useGoogleCalendar from "../../../hooks/link-google-hooks/use-google-calendar";
import useMakeCalnedar from "../../../hooks/calendar-hooks/use-make-calendar";
import CalendarContext from "../asonic-calendar-context";
import useOpenDialog from "../../../hooks/use-open-dialog";
import useCalendar from "../../../hooks/calendar-hooks/use-calendar/use-calendar";

const AsonicCalendarMonthContainer = <P extends object>(props: P) => {
  const {
    currentMoment,
    handleCurrentMoment,
    handleMoveMonth,
    weeks
  } = useCalendar();

  const {
    authorize,
    handleSignoutClick,
    isLogin
  } = useAuthenticateGoogleUser();

  const {
    googleCalendarlist,
    handleCalendarList,
    googleEventOfList,
    listOfGoogleColor,
    handleIsRequestData
  } = useGoogleCalendar({
    isLogin,
    currentMoment
  });
  const { selectedEvent, handleSelectedEvent } = useMakeCalnedar();

  const { handleDebugging } = useDebugging();
  handleDebugging({ data: weeks, message: "Debugging weeks data" });

  const {
    dialogTitle,
    handleDialogTitle,
    isOpen,
    handleOpenDialog
  } = useOpenDialog();

  return (
    <CalendarContext.Provider
      value={{
        googleCalendarlist,
        googleEventOfList,
        listOfGoogleColor,
        isOpen,
        handleOpenDialog,
        dialogTitle,
        handleDialogTitle,
        selectedEvent,
        handleSelectedEvent,
        currentMoment,
        handleIsRequestData
      }}
    >
      <AsonicCalendarMonthPresenter
        {...props}
        currentMoment={currentMoment}
        weeks={weeks}
        handleCurrentMoment={handleCurrentMoment}
        handleMoveMonth={handleMoveMonth}
        authorize={authorize}
        handleSignoutClick={handleSignoutClick}
        isLogin={isLogin}
        googleCalendarlist={googleCalendarlist}
        handleCalendarList={handleCalendarList}
      />
    </CalendarContext.Provider>
  );
};

export default AsonicCalendarMonthContainer;
