import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MobileSettingView from "../../../moComponents/main-view/MobileSettingView";
import { signInActionCreator } from "../../../redux/modules/sign-in/signInReducer";

const mapStateProps = state => ({
  signInReducer: state.signInReducer
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...signInActionCreator
  }),
  dispatch
});

export default connect(mapStateProps, mapDispatchToProps)(MobileSettingView);
