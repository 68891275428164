import * as React from "react";
import ChartDialog from "./ChartDialog";
import moment from "moment";
import jwt from "jsonwebtoken";
import settings from "../../../config/settings";
import { getChartData } from "../../../Utils/commonAxiosCall";
import { useReactiveVar } from "@apollo/client";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import { MessageTypes } from "../../toast-message/toast-message";
import listOfApolloVar from "../../../apollo/apollo-var";

type HandleChartDialogFn = (isTrue: boolean) => void;
interface Ret {
  data: [];
}
type OnchangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => void;
interface Props {
  handleChartDialog: HandleChartDialogFn;
}

interface NewProps {
  chartInfoTypes: string[];
  chartInfoPeriods: string[];
  handlePeriodType: OnchangeFunction;
  selectedPeriodType: string;
  checkedPeriodType: string;
  handleChartData: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  date: string;
  handleDate: OnchangeFunction;
  handleSelectedInfoTypes: OnchangeFunction;
  handleUserRank: (
    type: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  topUsers: number;
  lowerUsers: number;
  handleSelectedSortedUserType: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  selectedSortedUserType: SelectedSortedUserType;
  selectedInfoTypes: number[];
  listOfEmployeeId: string[];
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  message: string;

  toastMessageType: MessageTypes;
}

export type SelectedSortedUserType = {
  top: boolean;
  low: boolean;
  total: boolean;
  topUsers?: number;
  lowUsers?: number;
};

export interface TotalProps extends Props, NewProps {}
const ChartDialogContainer = (props: Props) => {
  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const [topUsers, setTopUsers] = React.useState(0);
  const [lowerUsers, setLowerUsers] = React.useState(0);
  const [selectedPeriodType, setSelectedPeriodType] = React.useState("date");
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [checkedPeriodType, setCheckedPeriodType] = React.useState("일");
  const [selectedInfoTypes, setSelectedInfoTypes] = React.useState<number[]>([
    1, 2, 3, 4
  ]);
  const [selectedSortedUserType, setSelectedSortedUserType] =
    React.useState<SelectedSortedUserType>({
      top: false,
      low: false,
      total: true
    });

  const {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const handleSelectedSortedUserType = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const type = event.target.value;
      const checked = event.target.checked;
      switch (type) {
        case "top":
          if (checked) {
            setSelectedSortedUserType({
              ...selectedSortedUserType,
              top: true
            });
          } else {
            setSelectedSortedUserType({
              ...selectedSortedUserType,
              top: false
            });
          }
          break;
        case "low":
          if (checked) {
            setSelectedSortedUserType({
              ...selectedSortedUserType,
              low: true
            });
          } else {
            setSelectedSortedUserType({
              ...selectedSortedUserType,
              low: false
            });
          }
          break;
        case "total":
          if (checked) {
            setSelectedSortedUserType({
              ...selectedSortedUserType,
              total: true
            });
          } else {
            setSelectedSortedUserType({
              ...selectedSortedUserType,
              total: false
            });
          }
          break;
        default:
          break;
      }
    },
    [selectedSortedUserType]
  );

  const handleUserRank = React.useCallback(
    type => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (type === "top") {
        setTopUsers(parseInt(event.target.value));
      } else if (type === "low") {
        setLowerUsers(parseInt(event.target.value));
      }
    },
    []
  );

  const handleSelectedInfoTypes = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value);
      if (event.target.checked) {
        setSelectedInfoTypes([...selectedInfoTypes, value]);
      } else if (!event.target.checked && selectedInfoTypes.length > 0) {
        const newArray: number[] = selectedInfoTypes.filter(item => {
          if (item !== value) {
            return true;
          } else {
            return false;
          }
        });
        setSelectedInfoTypes([...newArray]);
      }
    },
    [selectedInfoTypes]
  );

  const handleDate = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDate(event.target.value);
    },
    [setDate]
  );

  const handleChartData = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      if (listOfEmployeeId.length > 0) {
        const multiUser = listOfEmployeeId.length > 1 ? 1 : 0;
        let parseDate = date;
        switch (selectedPeriodType) {
          case "year":
            parseDate = `${parseDate}-01-01`;
            break;
          case "week":
            const year: string = parseDate.slice(0, 4);
            const weekNumber: number = parseInt(parseDate.slice(6));
            parseDate = moment(year, "YYYY")
              .day("Sunday")
              .add(weekNumber, "weeks")
              .format("YYYY-MM-DD");
            break;
          case "month":
            parseDate = `${parseDate}-01`;
            break;
          default:
            break;
        }
        getChartData(
          selectedPeriodType === "date" ? "day" : selectedPeriodType,
          selectedInfoTypes,
          multiUser,
          listOfEmployeeId,
          parseDate
        )
          .then((ret: Ret) => {
            const chartDataKey = "chartData";
            const chartExtraDataKey = "chartExtraData";
            if (ret.data) {
              const chartData = ret.data;
              const chartExtraData = selectedSortedUserType;
              if (selectedSortedUserType.top && topUsers > 0) {
                chartExtraData.topUsers = topUsers;
              }
              if (selectedSortedUserType.low && lowerUsers > 0) {
                chartExtraData.lowUsers = lowerUsers;
              }
              const jwtChartExtraData = jwt.sign(
                JSON.stringify(chartExtraData),
                settings.secret
              );
              const jwtChartData = jwt.sign(
                JSON.stringify(chartData),
                settings.secret
              );
              if (multiUser === 1) {
                localStorage.setItem(chartExtraDataKey, jwtChartExtraData);
              } else if (multiUser === 0) {
                localStorage.removeItem(chartExtraDataKey);
              }
              localStorage.setItem(chartDataKey, jwtChartData);
              const CHART_URL = `http://${window.location.href.split("/")[2]}/${
                window.location.href.split("/")[3]
              }/chart`;
              if (
                (multiUser === 1 && selectedSortedUserType.top) ||
                selectedSortedUserType.low ||
                selectedSortedUserType.total
              ) {
                props.handleChartDialog(false);
                window.open(CHART_URL);
              } else if (multiUser === 0) {
                props.handleChartDialog(false);
                window.open(CHART_URL);
              }
            } else {
              handleMessage(
                "데이터가 존재하지 않거나 또는 각 항목을 한가지 이상 체크하지 않았습니다."
              );
              handleToastMessageType(MessageTypes.WARNING);
              handleIsOpen(true);
              localStorage.removeItem(chartDataKey);
              localStorage.removeItem(chartExtraDataKey);
            }
          })
          .catch(() => {
            handleMessage(
              "데이터가 존재하지 않거나 또는 각 항목을 한가지 이상 체크하지 않았습니다."
            );
            handleToastMessageType(MessageTypes.WARNING);
            handleIsOpen(true);
          });
      }
    },
    [
      selectedPeriodType,
      listOfEmployeeId,
      date,
      selectedInfoTypes,
      props,
      selectedSortedUserType,
      topUsers,
      lowerUsers,
      handleToastMessageType,
      handleIsOpen,
      handleMessage
    ]
  );

  const handlePeriodType = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      switch (event.target.value) {
        case "일":
          setSelectedPeriodType("date");
          setCheckedPeriodType("일");
          setDate(moment().format("YYYY-MM-DD"));
          break;
        case "주":
          setSelectedPeriodType("week");
          setCheckedPeriodType("주");
          setDate(moment().format("YYYY-[W]WW"));
          break;
        case "월":
          setSelectedPeriodType("month");
          setCheckedPeriodType("월");
          setDate(moment().format("YYYY-MM"));
          break;
        case "년":
          setSelectedPeriodType("year");
          setCheckedPeriodType("년");
          setDate(moment().format("YYYY"));
          break;
        default:
          break;
      }
    },
    []
  );

  const chartInfoTypes = React.useMemo(() => {
    const infoTypes: string[] = [
      "pc구동시간",
      "PC login/logout",
      "근무시간",
      "이석시간"
    ];
    return infoTypes;
  }, []);

  const chartInfoPeriods = React.useMemo(() => {
    const infoPeriod: string[] = ["일", "주", "월", "년"];
    return infoPeriod;
  }, []);

  const newProps: NewProps = {
    chartInfoTypes,
    chartInfoPeriods,
    selectedPeriodType,
    handlePeriodType,
    checkedPeriodType,
    listOfEmployeeId,
    handleChartData,
    date,
    handleDate,
    handleSelectedInfoTypes,
    handleUserRank,
    topUsers,
    lowerUsers,
    handleSelectedSortedUserType,
    selectedSortedUserType,
    selectedInfoTypes,
    isOpen,
    handleIsOpen,
    message,
    toastMessageType
  };

  return <ChartDialog {...props} {...newProps} />;
};

export default ChartDialogContainer;
