import api from "../api";

const PLACE_FREE_MANAGEMENT = `${api.api}/place-free-management`;
const FORM = `${PLACE_FREE_MANAGEMENT}/form`;

const placeFreeManagementRoutes = {
  placeFreeManagement: PLACE_FREE_MANAGEMENT,
  form: FORM
};

export default placeFreeManagementRoutes;
