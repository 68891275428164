import { TypePolicies } from "@apollo/client";

export class CalculatePagination {
  constructor(
    private readonly payload: {
      existing: any;
      incoming?: any;
      type?: string | null;
      take?: number | null;
      page?: number | null;
    }
  ) {}
  private isSearch() {
    const { type, page } = this.payload;
    if (type && page === 1) {
      return true;
    }
    return false;
  }

  get offset() {
    const { take, page } = this.payload;
    if (page && take) {
      return (page - 1) * take;
    }
    return 0;
  }

  public read() {
    const { existing, take } = this.payload;
    if (take) {
      return existing && existing.slice(this.offset, this.offset + take);
    }
  }

  public computedData() {
    const { existing, incoming, page, take } = this.payload;
    if (this.isSearch()) {
      return incoming;
    }

    if (page && take && incoming) {
      const offset = (page - 1) * take;
      const merged = existing ? existing.slice(0) : [];
      for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }

      return merged;
    }
  }
}

const apolloTypePolicies: TypePolicies = {
  GetListOfApprovalTemplateOutput: {
    keyFields(GetListOfApprovalTemplateOutput) {
      return `GetListOfApprovalTemplateOutput`;
    }
  },
  ApprovalTemplateEntity: {
    keyFields(ApprovalTemplateEntity) {
      return `ApprovalTemplateEntity:${ApprovalTemplateEntity.formTemplateIdx}`;
    }
  },
  GetListOfApprovalLineOutput: {
    keyFields(GetListOfApprovalLineOutput) {
      return `GetListOfApprovalLineOutput`;
    }
  },
  ApprovalLineEntity: {
    keyFields(ApprovalLineEntity) {
      return `ApprovalLineEntity:${ApprovalLineEntity.lineIdx}`;
    }
  }
};

export default apolloTypePolicies;
