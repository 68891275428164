import styled from "styled-components";
import * as ReactTable from "react-table";
import ContentContainer from "../../shared/content_container/content_container";
import { Day_Type, WtGraphWeekEntity } from "../../../generated/graphql";
import AutonomyDay from "./autonomy_day";
import { useEffect, useState } from "react";
import AutonomyHourGraph from "./autonomy_hour_graph";

interface IProps {
  weekDayOfWeekInfo: WtGraphWeekEntity[];
  workingTemplateIdx?: number;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 2px;
  border: 3px solid ${props => props.theme.colors.frameGrey};
  padding: 10px 0px;
  user-select: none;
`;

function AutonomyDayGraph({ weekDayOfWeekInfo, workingTemplateIdx }: IProps) {
  const [selectedDayName, setSelectedDayName] = useState<string>();
  const [selectedDay, setSelectedDay] = useState<WtGraphWeekEntity>();

  useEffect(() => {
    if (selectedDayName) {
      switch (selectedDayName) {
        case "월":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Mon) {
              setSelectedDay(item);
            }
          });
          break;
        case "화":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Tue) {
              setSelectedDay(item);
            }
          });
          break;
        case "수":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Wed) {
              setSelectedDay(item);
            }
          });
          break;
        case "목":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Thr) {
              setSelectedDay(item);
            }
          });
          break;
        case "금":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Fri) {
              setSelectedDay(item);
            }
          });
          break;
        case "토":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Sat) {
              setSelectedDay(item);
            }
          });
          break;
        case "일":
          weekDayOfWeekInfo.forEach(item => {
            if (item.dayOfWeek === Day_Type.Sun) {
              setSelectedDay(item);
            }
          });
          break;
      }
    }
  }, [selectedDayName, weekDayOfWeekInfo]);
  return (
    <ContentContainer>
      <Container>
        <AutonomyDay
          weekDayOfWeekInfo={weekDayOfWeekInfo}
          selectedDayName={selectedDayName}
          setSelectedDayName={setSelectedDayName}
        />
        <AutonomyHourGraph
          selectedDay={selectedDay}
          workingTemplateIdx={workingTemplateIdx}
        />
      </Container>
    </ContentContainer>
  );
}

export default AutonomyDayGraph;
