import MenuSettings from "../MenuSettings/MenuSettings";
import { MessageTypes } from "../../../../toast-message/toast-message";

export type WebMenuList = [
  {
    web_approval_flag: number;
    web_commutegps_flag: number;
    web_otmng_flag: number;
    web_report_flag: number;
    web_etc_flag: number;
    web_vehicle_flag: number;
    web_chat_flag: number;
  }
];
interface Props {
  handleIsOpen: (value: boolean) => void;
  handleMessage: (value: string) => void;
  handleToastMessageType: (value: MessageTypes) => void;
}

const title = "메뉴설정";

const MenuSettingsContainer = <P extends Props>({
  handleIsOpen,
  handleMessage,
  handleToastMessageType
}: P) => {
  return <MenuSettings title={title} />;
};

export default MenuSettingsContainer;
