import styled from "styled-components";
import colors from "../../../../style-sheet/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  isTime?: boolean;
  isMiddleSection?: boolean;
};

export const Container = styled.div<Props>`
  grid-column: 1 / span 3;
  grid-row: 1 / span 5;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background: ${colors.personalDashBoard.currentTimeBox.topBackgroundColor};
  padding: 10px;
  border-radius: 2px;
  font-size: 22px;
  color: ${colors.personalDashBoard.Info};
`;

export const BackgroundContainer = styled.div`
  z-index: 0;
  border-radius: 2px;
  background-color: ${colors.personalDashBoard.currentTimeBox
    .bottomBackgroundColor};
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    21% 45%,
    37% 51%,
    69% 29%,
    82% 50%,
    100% 28%,
    100% 100%,
    0 100%,
    0 59%
  );
`;

export const DateContainer = styled.div``;

export const Date = styled.span`
  color: ${colors.personalDashBoard.Info};
`;

export const CurrentTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const CurrentTime = styled.span`
  font-size: 22px;
`;

export const TimeTitle = styled.span`
  margin: 3.9px;
`;

export const WorkingTimeContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;

export const WorkingTimeTitle = styled.span``;

export const WorkingTimeContent = styled.span`
  margin-left: 10px;
`;

export const CommutingInfoContainer = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const CommutingInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    padding-bottom: 8px;
  }
`;

export const CommutingInfoTitle = styled.span`
  font-size: 18px;
`;

export const CommutingInfoContent = styled.span<Props>`
  color: ${props => (props.isTime ? "#F2CF0C" : "white")};
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${colors.personalDashBoard.currentTimeBox.icon};
`;
