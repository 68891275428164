import routes from "../../routes";
import API from "../api";

export type Params =
  | IAddWorkingTimeScheduleParams
  | IUpdateWorkingTimeScheduleParams
  | IDeleteWorkingTimeScheduleParams
  | IGetTempleteScheduleParams
  | IAddTempleteScheduleParams
  | IDeleteTemplateScheduleParams
  | IUpdateTempleteScheduleParams;

export interface IAddWorkingTimeScheduleParams {
  timeName: string;
}

export interface IDeleteWorkingTimeScheduleParams {
  timeTempleteIdx?: string | number;
}

interface IUpdateWorkingTimeScheduleParams
  extends IDeleteWorkingTimeScheduleParams,
    IAddWorkingTimeScheduleParams {}

interface IUserWorkingTimeScheduleParams {
  selectedEmployeeIds: string[];
  timeTemplateIdx: number | null;
}

interface IUpdateUsersWorkingTimeShceduleWithDepartmentIdParams {
  selectedDepartmentId: string;
  timeTemplateIdx: number | null;
}

interface IGetTempleteScheduleParams extends IDeleteWorkingTimeScheduleParams {}

interface IAddTempleteScheduleParams {
  timeTempleteIdx?: string | number;
  categoryId?: string;
  isMonday: number;
  isTuesday: number;
  isWednesday: number;
  isThursday: number;
  isFriday: number;
  isSaturday: number;
  isSunday: number;
  periodateStart: string;
  periodateEnd?: string;
  startTime: string;
  endTime: string;
}

interface IDeleteTemplateScheduleParams {
  timeIdx: string | number;
}

interface IUpdateTempleteScheduleParams
  extends IAddTempleteScheduleParams,
    IDeleteTemplateScheduleParams {}

// Templete
export const getWorkingTimeSchedule = () =>
  API().get(routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTemplete);

export const getWorkingTimeScheduleTemplatePersonal = () =>
  API().get(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTemplatePersonal
  );

export const addWorkingTimeSchedule = (params: Params) =>
  API().post(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTemplete,
    params
  );

export const updateWorkingTimeSchedule = (params: Params) =>
  API().put(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTemplete,
    params
  );

export const deleteWorkingTimeSchedule = (params: Params) =>
  API().delete(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTemplete,
    { data: params }
  );

// Schedule
export const getTempleteSchedule = (params: Params) =>
  API().get(routes.apiRoutes.workingTimeSchedule.workingTimeScheduleList, {
    params
  });

export const addTempleteSchedule = (params: Params) =>
  API().post(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleList,
    params
  );

export const updateTempleteSchedule = (params: Params) =>
  API().put(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleList,
    params
  );

export const deleteTempleteSchedule = (params: IDeleteTemplateScheduleParams) =>
  API().delete(routes.apiRoutes.workingTimeSchedule.workingTimeScheduleList, {
    data: params
  });

// Categories
export const getTimeTypeCategories = () =>
  API().get(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTempleteCategories
  );

// User workingTimeschedule
export const updateUserWorkingTimeSchedule = (
  params: IUserWorkingTimeScheduleParams
) =>
  API().put(
    routes.apiRoutes.workingTimeSchedule.workingTimeScheduleTemplateForUser,
    params
  );

export const updateUsersWorkingTimeShceduleWithDepartmentId = (
  params: IUpdateUsersWorkingTimeShceduleWithDepartmentIdParams
) =>
  API().put(
    routes.apiRoutes.workingTimeSchedule
      .workingTimeScheduleTemplateForDepartment,
    params
  );

export const getPersonalWorkingTimeScheduleTemplate = ({
  employeeId
}: {
  employeeId: string;
}) =>
  API().get(
    routes.apiRoutes.workingTimeSchedule.personalWorkingTimeScheduleTemplate(
      employeeId
    )
  );
