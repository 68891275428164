import SectionContainer from "../../../../../components/shared/section_container/section_container";
import AsonicTab from "../../../../../components/shared/asonic-tab";
import useAsonicTab, {
  ITabs,
  KTabs
} from "../../../../../hooks/hooks-shared/use-asonic-tab";
import WSBasicInfo from "./basic-setting-tab/w_s_basic_info";
import { useContext, useEffect, useMemo } from "react";
import MainContainer from "../../../../../components/shared/main_container/main_container";
import WidgetSetting from "../../../../../components/w_s_tabs/widget_setting/widget_setting";
import { Work_Type } from "../../../../../generated/graphql";
import WorkScheduleAdditionalSettingTab from "./additional-setting-tab/work-schedule-additional-setting-tab";
import { WS_STATE } from "../asonic-working-system.screen";

interface IProps {
  workType: Work_Type; // 근무 유형 타입
  handleWorkType: (payload: Work_Type) => void; // 근무 유형 타입 변경 시 처리
}

function TopTabs({ workType, handleWorkType }: IProps) {
  const tabs: ITabs = useMemo(() => {
    return {
      BASIC_SETTING: "기본설정",
      ADDITIONAL_SETTING: "추가설정"
    };
  }, []);

  const { list, selectedTab, handleSelectTab } = useAsonicTab<ITabs, KTabs>({
    tabs
  });

  return (
    <MainContainer flex={10}>
      <AsonicTab<string>
        list={list}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      <SectionContainer flex={10}>
        {tabs[selectedTab] === tabs.BASIC_SETTING && (
          <WSBasicInfo workType={workType} handleWorkType={handleWorkType} />
        )}
        {tabs[selectedTab] === tabs.ADDITIONAL_SETTING && (
          <WorkScheduleAdditionalSettingTab />
        )}
      </SectionContainer>
    </MainContainer>
  );
}

export default TopTabs;
